import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeRoute from "./HomeRoute";
// import MoneylinkLogin from "../Moneylink/PartnerLogin"
import ProductRoute from "./ProductRoute";
import BankingRoute from "./BankingRoute";
import PaymentRoute from "./PaymentRoute";
import FinanceRoute from "./FinanceRoute";
import ChatRoute from "./ChatRoute";
import ShopRoute from "./ShopRoute";
import BusinessRoute from "./BusinessRoute";
import BusinessBankingRoute from "./BusinessBankingRoute";
import BusinessPaymentRoute from "./BusinessPaymentRoute";
import BusinessAgencyRoute from "../components/Business/AgencyBanking/Main";
import InventoryRoute from "../components/Business/Inventory/Main";
import StoreRoute from "../components/Business/Store/Main";
import GatewayRoute from "../components/Business/Gateway/Main";
import TapPayRoute from "../components/Business/TapPay/Main";
import DuoRoute from "../components/Solutions/Duo/Overview/Main";
import DtsRoute from "../components/Solutions/Duo/Dts/Main";
import DcpRoute from "../components/Solutions/Duo/Dcp/Main";
import DlmRoute from "../components/Solutions/Duo/Dlm/Main";
import ImplementationRoute from "../components/Solutions/Duo/Implementation/Main";
import CollectionRoute from "../components/Solutions/Duo/Collections/Main";
import DwpRoute from "../components/Solutions/Duo/Dwp/Main";
import ActivationRoute from "../components/Solutions/Duo/Activation/Main";
import CustomeronboardingRoute from "../components/Solutions/Duo/Customeronboarding/Main";
import DuoappRoute from "../components/Solutions/Duo/Duoapp/Main";
import SupplychainRoute from "../components/Solutions/Supplychain/Overview/Main";
import SalesRoute from "../components/Solutions/Supplychain/Sales/Main";
import SupplychainpaymentRoute from "../components/Solutions/Supplychain/Payment/Main";
import SupplychaincollectionRoute from "../components/Solutions/Supplychain/Collection/Main";
import OrderFulfilmentRoute from "../components/Solutions/Supplychain/OrderFulfilment/Main";
import DistributionAnalyticsRoute from "../components/Solutions/Supplychain/DistributionAnalytics/Main";
import MoneylinkcipRoute from "../components/Platform/Moneylink/Cardissuing/Main";
import MoneylinkmerchantRoute from "../components/Platform/Moneylink/Merchant/Main";
import MoneylinkLoanmanagementRoute from "../components/Platform/Moneylink/Loanmanagement/Main";
import MoneylinkDebtCollectionsRoute from "../components/Platform/Moneylink/DebtCollections/Main";
import MoneylinkAccountManagementRoute from "../components/Platform/Moneylink/AccountManagement/Main";
import Verifiedauthapp from "../components/Solutions/Verified/Authapp/Main";
import Verifiedfaceauth from "../components/Solutions/Verified/Faceauth/Main";
import CompanyRoute from "../components/Company/Home/Main";
import OurstoryRoute from "../components/Company/Ourstory/Main";
import UserPinSetup from "../Moneylink/ForgotPin/UserPinSetup";
import UserOtp from "../Moneylink/ForgotPin/UserOtp";
import UserPinSetupSuccess from "../Moneylink/ForgotPin/UserPinSetupSuccess";
import Indemnity1 from "../components/Indemnity/Montra-Indemnity";
import Indemnity3 from "../components/Indemnity/Montra-Upgrade-Indemnity";
import VerifyedIndemnity1 from "../components/Indemnity/Verifyed-Indemnity";
import VerifyedIndemnity2 from "../components/Indemnity/Verifyed-Upgrade-Indemnity";
import Sitemap from "../components/Sitemap/Sitemaps";

// Moneylink-routing

import { Partner } from "../Moneylink/Partner";
import PartnerSignup from "../Moneylink/PartnerSignup";
import PrivacyPolicy from "../components/verifyed/Privacy-Policy";
import Termcondition from "../components/verifyed/terms-and-condition";
import MontraPrivacyPolicy from "../components/PrivacyPolicy/Privacy-Policy";
import MontraTnc from "../components/PrivacyPolicy/tnc";
import Business from "../Moneylink/MoneylinkBusiness/MoneylinkBusiness";
import Portfolio from "../Moneylink/MoneylinkBusiness/Portfolio";
import AdminMaker from "../Moneylink/Maker/AdminMaker";
import Profile from "../Moneylink/Maker/Profile";
import ProductMix from "../Moneylink/Maker/Product-Mix/ProductMix";
import UnderWrittingRules from "../Moneylink/Maker/CreditUnderWrittingRules/UnderWrittingRules";
import ProductGL from "../Moneylink/Maker/ProductGL/ProductGL";
import ManageUser from "../Moneylink/Maker/ManageUser/ManageUser";
import AdminChecker from "../Moneylink/Checker/AdminChecker";
import CheckerProfile from "../Moneylink/Checker/Profile/CheckerProfile";
import CheckerProductMix from "../Moneylink/Checker/CheckerProductMix/CheckerProductMix";
import CheckerWrittingRules from "../Moneylink/Checker/CheckerCreditUW/CheckerWrittingRules";
import CheckerProductGL from "../Moneylink/Checker/CheckerProductGL/CheckerProductGL";
import CheckerManageUser from "../Moneylink/Checker/CheckerManageUser/CheckerManageUser";

import CreditUW from "../Moneylink/CreditUW/CreditUW";
import LoanRequest from "../Moneylink/CreditUW/LoanRequest";
import LoanRejects from "../Moneylink/CreditUW/LoanRejects";
import LoanApproved from "../Moneylink/CreditUW/LoanApproved";
import ViewDetails from "../Moneylink/CreditUW/ViewDetails";
import ViewDetailsReject from "../Moneylink/CreditUW/ViewDetailsReject";
import Sales from "../Moneylink/Sales/Sales";
import Origination from "../Moneylink/Sales/Origination";
import NibssCollection from "../Moneylink/NibssCollection/NibssCollection";
import NibssCollectionReport from "../Moneylink/NibssCollection/NibssCollectionReport";
import HistoricalData from "../Moneylink/SalaryProcessor/HistoricalData";
import SalaryData from "../Moneylink/SalaryProcessor/SalaryData";
import SalProcessor from "../Moneylink/SalaryProcessor/SalProcessor";
import SalaryDataLender from "../Moneylink/SalProcessorLender/SalaryDataLender";
import SalProcessorLender from "../Moneylink/SalProcessorLender/SalProcessorLender";
import HistoricalDataLender from "../Moneylink/SalProcessorLender/HistoricalDataLender";
import Collection from "../Moneylink/Collection/Collection";
import CollectionReport from "../Moneylink/Collection/CollectionReport";
import Forgotpassword from "../Moneylink/ForgotPassword/Forgotpassword";
import Forgotpassword1 from "../Moneylink/ForgotPassword/Forgotpassword1";
import Forgotpassword2 from "../Moneylink/ForgotPassword/Forgotpassword2";
import Userpin from "../Moneylink/Userpin";
import Operations from "../Moneylink/Operations/Operations";
import OperationReport from "../Moneylink/Operations/OperationReport";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeRoute />} />
      <Route path="/Products/overview" element={<ProductRoute />} />
      {/* <Route path="/MoneyLink/PartnerLogin" element={<MoneylinkLogin />} /> */}
      <Route path="/Products/Banking" element={<BankingRoute />} />
      <Route path="/Products/Payment" element={<PaymentRoute />} />
      <Route path="/Products/Finance" element={<FinanceRoute />} />
      <Route path="/Products/Chat" element={<ChatRoute />} />
      <Route path="/Products/Shop" element={<ShopRoute />} />
      <Route path="/Business/overview" element={<BusinessRoute />} />
      <Route path="/Business/Banking" element={<BusinessBankingRoute />} />
      <Route path="/Business/Payment" element={<BusinessPaymentRoute />} />
      <Route path="/Business/AgencyBanking" element={<BusinessAgencyRoute />} />
      <Route path="/Business/more/Inventory" element={<InventoryRoute />} />
      <Route path="/Business/more/Store" element={<StoreRoute />} />
      <Route path="/Business/more/Gateway" element={<GatewayRoute />} />
      <Route path="/Business/more/TapPay" element={<TapPayRoute />} />
      <Route path="/Solutions/Duo/overview" element={<DuoRoute />} />
      <Route path="/Solutions/Duo/Dts" element={<DtsRoute />} />
      <Route path="/Solutions/Duo/Dcp" element={<DcpRoute />} />
      <Route path="/Solutions/Duo/Dcp" element={<DcpRoute />} />
      <Route path="/Solutions/Duo/Dwp" element={<DwpRoute />} />
      <Route path="/Solutions/Duo/Dlm" element={<DlmRoute />} />

      <Route
        path="/Solutions/Duo/Implementation"
        element={<ImplementationRoute />}
      />
      <Route path="/Solutions/Duo/Collection" element={<CollectionRoute />} />
      <Route path="/Solutions/Duo/Activation" element={<ActivationRoute />} />
      <Route
        path="/Solutions/Duo/Customeronboarding"
        element={<CustomeronboardingRoute />}
      />
      <Route path="/Solutions/Duo/Duoapp" element={<DuoappRoute />} />
      <Route
        path="/Solutions/Supplychain/overview"
        element={<SupplychainRoute />}
      />
      <Route path="/Solutions/Supplychain/Sales" element={<SalesRoute />} />
      <Route
        path="/Solutions/Supplychain/payment"
        element={<SupplychainpaymentRoute />}
      />
      <Route
        path="/Solutions/Supplychain/collection"
        element={<SupplychaincollectionRoute />}
      />
      <Route
        path="/Solutions/Supplychain/Orderfulfilment"
        element={<OrderFulfilmentRoute />}
      />
      <Route
        path="/Solutions/Supplychain/Distributionanalytics"
        element={<DistributionAnalyticsRoute />}
      />
      <Route path="/Platform/Moneylink/cip" element={<MoneylinkcipRoute />} />
      <Route
        path="/Platform/Moneylink/merchant"
        element={<MoneylinkmerchantRoute />}
      />
      <Route
        path="/Platform/Moneylink/Loanmanagement"
        element={<MoneylinkLoanmanagementRoute />}
      />
      <Route
        path="/Platform/Moneylink/Debtcollections"
        element={<MoneylinkDebtCollectionsRoute />}
      />
      <Route
        path="/Platform/Moneylink/Accountmanagement"
        element={<MoneylinkAccountManagementRoute />}
      />
      <Route path="/Solution/Verified/Authapp" element={<Verifiedauthapp />} />
      <Route
        path="/Solution/Verified/Faceauth"
        element={<Verifiedfaceauth />}
      />
      <Route path="/Company/Overview" element={<CompanyRoute />} />
      <Route path="/Company/Ourstory" element={<OurstoryRoute />} />

      {/* Moneylink-routing */}
      {/* <React.Fragment className="moneylink-project"> */}
      <Route path="/MoneyLink/PartnerLogin" element={<Partner />} />
      <Route path="/MoneyLink/PartnerSignup" element={<PartnerSignup />} />
      <Route
        path="/MoneyLink/UnderWrittingRules"
        element={<UnderWrittingRules />}
      />
      <Route path="/MoneyLink/ProductGL" element={<ProductGL />} />
      <Route path="/MoneyLink/ManageUser" element={<ManageUser />} />
      <Route
        path="/verifyed/terms-and-condition"
        element={<Termcondition />}
      />
      <Route path="/verifyed/Privacy-Policy" element={<PrivacyPolicy />} />
      <Route path="/Privacy-Policy" element={<MontraPrivacyPolicy />} />
      <Route path="/tnc" element={<MontraTnc />} />
      <Route path="/MoneylinkBusiness" element={<Business />} />
      <Route path="/Portfolio" element={<Portfolio />} />

      <Route path="/CreditUW" element={<CreditUW />} />
      <Route path="/LoanRequest" element={<LoanRequest />} />
      <Route path="/LoanRejects" element={<LoanRejects />} />
      <Route path="/LoanApproved" element={<LoanApproved />} />
      <Route path="/ViewDetails" element={<ViewDetails />} />
      <Route path="/ViewDetailsReject" element={<ViewDetailsReject />} />
      <Route path="/SalProcessorLender" element={<SalProcessorLender />} />
      <Route
        path="/HistoricalDataLender"
        element={<HistoricalDataLender />}
      />
      <Route path="/SalaryDataLender" element={<SalaryDataLender />} />

      <Route path="/Sales" element={<Sales />} />
      <Route path="/Origination" element={<Origination />} />
      <Route path="/Collection" element={<Collection />} />
      <Route path="/CollectionReport" element={<CollectionReport />} />
      <Route path="/Operations" element={<Operations />} />
      <Route path="/OperationReport" element={<OperationReport />} />
      <Route path="/SalProcessor" element={<SalProcessor />} />
      <Route path="/HistoricalData" element={<HistoricalData />} />
      <Route path="/SalaryData" element={<SalaryData />} />
      <Route path="/NibssCollection" element={<NibssCollection />} />
      <Route
        path="/NibssCollection/NibssCollectionReport"
        element={<NibssCollectionReport />}
      />
      <Route path="/MoneyLink/AdminMaker" element={<AdminMaker />} />
      <Route path="/MoneyLink/Profile" element={<Profile />} />
      <Route path="/MoneyLink/ProductMix" element={<ProductMix />} />
      <Route path="/MoneyLink/AdminChecker" element={<AdminChecker />} />
      <Route path="/MoneyLink/CheckerProfile" element={<CheckerProfile />} />
      <Route
        path="/MoneyLink/CheckerProductMix"
        element={<CheckerProductMix />}
      />
      <Route
        path="/MoneyLink/CheckerWrittingRules"
        element={<CheckerWrittingRules />}
      />
      <Route
        path="/MoneyLink/CheckerProductGL"
        element={<CheckerProductGL />}
      />
      <Route
        path="/MoneyLink/CheckerManageUser"
        element={<CheckerManageUser />}
      />
      <Route path="/Forgotpassword" element={<Forgotpassword />} />
      <Route path="/Forgotpassword1" element={<Forgotpassword1 />} />
      <Route path="/Forgotpassword2" element={<Forgotpassword2 />} />
      <Route path="/UserPin" element={<Userpin />} />

      <Route path="/MoneyLink/UserPinSetup" element={<UserPinSetup />} />
      <Route path="/MoneyLink/UserOtp" element={<UserOtp />} />
      <Route path="/MoneyLink/UserPinSetupSuccess" element={<UserPinSetupSuccess />} />
      <Route path="/Montra-Indemnity" element={<Indemnity1 />} />
      <Route path="/Montra-Upgrade-Indemnity" element={<Indemnity3 />} />
      <Route path="/Verifyed-Indemnity" element={<VerifyedIndemnity1 />} />
      <Route path="/Verifyed-Upgrade-Indemnity" element={<VerifyedIndemnity2 />} />
      <Route path="/Sitemap" element={<Sitemap />} />


      {/* </React.Fragment> */}
    </Routes>
  );
};

export default AppRoutes;
