import React from "react";

function YU4a() {
  return (
    <svg
      width="385"
      height="166"
      viewBox="0 0 385 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        d="M0 1C149.961 1 234.039 1 384 1V164.5H114.5"
        stroke="url(#paint0_radial_4716_6343)"
        stroke-width="2"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4716_6343"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-616.5 71.4998) rotate(0.20026) scale(1144.51 5801.24)"
        >
          <stop stop-color="#17D92A" />
          <stop offset="1" stop-color="#FFBB08" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU4a;
