import React from "react";
import dts1 from "../../../../assets/images/dts1.svg";
import dwp3 from "../../../../assets/images/dwp3.webp";
import "./style.css";

const Body = () => {
  return (
    <>
      <div className="dcp-body cob-body">
        <div className="row dwp-row">
          <div className="col-md-6 dts-frame ">
            <div className="dts-frame-img">
              <img src={dts1} alt="DTS" />
            </div>
            <div className="dts-frame-header">
              <div class="lineparagrapduo">
                <h6>Advantage to Issuers</h6>
              </div>
            </div>
            <div className="duo-lists dts-list cob-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Digitized process through DUO portal for Issuer’s staff to
                    upload details of customers they want DUO Onboarding
                    application to score.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DUO onboarding app is implemented with integration to
                    Issuer’s systems such that it seamlessly gathers all income,
                    account balance, debt exposure, delinquency data and further
                    gather credit bureau data to under-write the customers based
                    on the approved Risk Acceptance Criteria for this product
                    program. This avoids manual processes that are otherwise
                    used to gather such data for manual underwriting which can
                    often be erroneous.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Further DUO Onboarding app creates mirror accounts on Issuer
                    side digitally before the relevant data can be submitted for
                    onboarding the approved customers on DUO Platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 dwp-img">
            <img src={dwp3} alt="dwp3" className="dwpimgall" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
