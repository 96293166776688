/* React packages */
import React from "react";
import { NavLink } from "react-router-dom";


/**
 * Header component is render navigation in Nibss Collection section.
 * <p>There are one navigation menu will be there:</p>
 * <li>Nibss Collection Report</li>
 <br>
 * <h6> React Bootstrap Navigation ui properties :- </h6>
 * <li> <b>Navbar:- </b>A navigation bar is a navigation header that is placed at the top of the page.</li>
 * <li> <b>Navbar.Collapse:- </b>it is use for grouping and hiding navbar contents by a parent breakpoint.</li>
 * <li> <b>NavLink:- </b>it will add styling attributes to the rendered element when it matches the current URL.</li>

 @Author : Seema Kumari
 @version :1.0
 */

const Header = () => {
  return (
    <div>
      {/* <Navbar className="navbar navbar-expand-lg navbar-light bg-dark nav-header montra-sidebar" bg="light" expand="lg">

  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="">
      <Nav.Link as={NavLink} to="NibssCollectionReport" className="userlink">  Nibss Collection Report </Nav.Link>
    </Nav>
      </Navbar.Collapse>
      </Navbar> */}
      <div className="link-container">
        <NavLink to="NibssCollectionReport" className="userlink">
          Nibss Collection Report
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
