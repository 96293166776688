import React from "react";

function PaymentAuthorization() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      fill="none"
      viewBox="0 0 26 25"
    >
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M23.282 10.87l-2.337-2.347-1.356 2.462M2.423 12.955l2.637 2.711 1.313-2.48M20.936 8.519a8.705 8.705 0 01-14.234 9.588M5.059 15.666a8.705 8.705 0 0114.222-9.6"
      ></path>
      <path
        stroke="#00BAF2"
        strokeLinecap="square"
        d="M8.195 11.13h9.6M8.195 13.05h9.6M15.875 15.93h-.96l-3.84-7.68h-1.02v7.68M15.934 15.93V8.25"
      ></path>
    </svg>
  );
}

export default PaymentAuthorization;
