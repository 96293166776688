import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import CardIssuing from "../../../assets/images/uday/personal-icons/Home-icons/Business/moneylink/cardApplication.svg";
import AccountManagement from "../../../assets/images/uday/personal-icons/Home-icons/Business/moneylink/accountmanagement.svg";
import LoanManagement from "../../../assets/images/uday/personal-icons/Home-icons/Business/moneylink/loanmanagement.svg";
import POS from "../../../assets/images/uday/personal-icons/Home-icons/Business/moneylink/POS.svg";
import Moneylinkimg from "../../../assets/images/moneylinkplatform.svg";

const steps = [
  {
    title: "MoneyLink Platforms",
    image: Moneylinkimg,
    className: "direction-img",
  },
  { image: CardIssuing, description: "Card Issuing" },
  { image: AccountManagement, description: "Account Management" },
  { image: LoanManagement, description: "Loan Management" },
  { image: POS, description: "POS Acquiring" },
];

const HomeMoneylinkProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#4D148C"
        circleBorderColor="#4D148C"
        textColor="homepage-moneylink-textcolor"
        topDescriptionClass="homepersonal-moneylink-topdescription"
        downDescriptionClass="downDescriptionClass"
      />
    </div>
  );
};

export default HomeMoneylinkProcessContainer;
