import React from 'react';

/**
 CheckerCompProfile component is render  company profile for  profile in checker section.
 @Author : Thrilok Kandra
 @version :1.0
 */
class CheckerCompProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      status: '',
      data: [],
      message: '',
      timestamp: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    let fields = this.state.fields;

    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
    this.props.onchange(e);
  }


  render() {

    return (

      <div>

        <form method="post" name="userRegistrationForm" >
          <p className="signinheader">COMPANY PROFILE</p>

          <div className="form-group">
            <label htmlFor="first" className='profile-label'>Lending Company Name</label>
            <div className="input-group aligncenter profileinputgreen" style={{ backgroundColor: this.props.companyprofilefieldclasslist }}>
              <input type="text" className="form-control forminputfield mb-0 " name="companyName" onChange={this.handleChange.bind(this)} placeholder={this.props.data.companyDto.companyName} disabled />
              <label className="switchtoggle"><input type="checkbox" name="isCompanyName" checked={this.props.data.companyDto.companytoggle} onChange={this.props.toggleChangeCompanyProfile.bind(this)} disabled={this.props.companyprofilelist} /><div className="checkerslider round" style={{ backgroundColor: this.props.companyprofileclasslist }}></div></label>

            </div>

          </div>

          <div className="form-group">
            <label htmlFor="first" className='profile-label'>Lending Company ID</label>
            <input type="text" className="form-control checkerinputfield" name="lendingcompanyid" placeholder={this.props.data.companyDto.companyId} disabled />

          </div>

          <div className="form-group">
            <label htmlFor="first" className='profile-label'>Lending Company Type</label>
            <div className="input-group aligncenter profileinputgreen" style={{ backgroundColor: this.props.companytypecolorfieldclass }} >
              <input type="text" className="form-control forminputfield mb-0 " name="companyType" onChange={this.handleChange.bind(this)} placeholder={this.props.data.companyDto.companyType} disabled />
              <label className="switchtoggle"><input type="checkbox" name="isCompanyType" checked={this.props.data.companyDto.companyTypetoggle} onChange={this.props.toggleChangeCompanyProfile.bind(this)} disabled={this.props.companytypedisable} /><div className="checkerslider round" style={{ backgroundColor: this.props.companytypecolorclass }} ></div></label>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="first" className='profile-label'>Country</label>
            <div className="input-group aligncenter profileinputgreen" style={{ backgroundColor: this.props.countrycolorfieldclass }}>
              <input type="text" className="form-control forminputfield mb-0 " name="country" onChange={this.handleChange.bind(this)} placeholder={this.props.data.companyDto.country} disabled />
              <label className="switchtoggle"><input type="checkbox" name="isCountry" checked={this.props.data.companyDto.countrytoggle} onChange={this.props.toggleChangeCompanyProfile.bind(this)} disabled={this.props.countrydisable} /><div className="checkerslider round" style={{ backgroundColor: this.props.countrycolorclass }} ></div></label>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="first" className='profile-label'>Currency</label>
            <input type="text" className="form-control checkerinputfield" name="currency" placeholder={this.props.data.companyDto.currency} disabled />

          </div>

          <div className="form-group">
            <label htmlFor="first" className='profile-label'>Date of registration</label>
            <input type="text" className="form-control checkerinputfield" name="dateregistration" placeholder={this.props.data.companyDto.registrationDate} disabled />

          </div>

          <div className="form-group">
            <label htmlFor="first" className='profile-label'>Status </label>
            <div className="input-container">
              <input type="text" className="form-control  checkerinputfield" name="status" placeholder={this.props.data.companyDto.status} disabled />
            </div>

          </div>

          <div className="form-group">
            <label htmlFor="first" className='profile-label'>Insurance Partner for Credit Life Insurance </label>
            <div className="input-group aligncenter profileinputgreen" style={{ backgroundColor: this.props.insuranceclicolorfieldclass }} >
              <input type="text" className="form-control forminputfield mb-0 " name="lifeinsurance" onChange={this.handleChange.bind(this)} placeholder={this.props.data.companyDto.insPartnerForCLI} disabled />
              <label className="switchtoggle"><input type="checkbox" name="isInsPartnerForCLI" checked={this.props.data.companyDto.partnerCLItoogle} onChange={this.props.toggleChangeCompanyProfile.bind(this)} disabled={this.props.insuranceclidisable} /><div className="checkerslider round" style={{ backgroundColor: this.props.insuranceclicolorclass }}></div></label>
            </div>

          </div>

          <div className="form-group">
            <label htmlFor="first" className='profile-label'>Insurance Partner for Asset Insurance </label>
            <div className="input-group aligncenter profileinputgreen" style={{ backgroundColor: this.props.insuranceaicolorfieldclass }} >
              <input type="text" className="form-control forminputfield mb-0 " name="assetinsurance" onChange={this.handleChange.bind(this)} placeholder={this.props.data.companyDto.insPartnerForAI} disabled />
              <label className="switchtoggle"><input type="checkbox" name="isInsPartnerForAI" checked={this.props.data.companyDto.partnerAItoogle} onChange={this.props.toggleChangeCompanyProfile.bind(this)} disabled={this.props.insuranceaidisable} /><div className="checkerslider round" style={{ backgroundColor: this.props.insuranceaicolorclass }}></div></label>
            </div>

          </div>

        </form>

      </div>
    )

  }


}

export default CheckerCompProfile;
