/* React packages and components */
import React from "react";
import { Navigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import MoneyLinklogo from "../assets/paylink-images/montra/newml.svg";
import Footer from "../Moneylink/Footer/MontraFooter";
/**
 Description: This Userpin component will render userpin section of MoneyLink
 Author : Seema Kumari

 @version :1.0 */

class Userpin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      fields: {},
      errors: {},
      backerrors: [],
      email: "",
      status: "",
      password: "",
      isActive: false,
      message: "",
      errorMsg: "",
      serverErr: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    let fields = this.state.fields;
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL("https://api.montra.org/moneylinkweb/api/user/userpin");
    fetch(
      url +
        "?email=" +
        localStorage.getItem("email") +
        "&pin=" +
        this.state.fields.pin,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({
            serverErr: "Please try After Sometime",
          });
        }
      })
      .then((contents) => {
        if (200 == contents.status && "SUCCESS" == contents.message) {
          this.setState({
            role: contents.data.role,
            status: contents.status,
            message: contents.message,
          });
          localStorage.setItem("logintype", "SIGNIN");
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            message: contents.message,
          });
          this.state.backerrors.map((key) => {
            this.setState({
              errorMsg: key.message,
            });
          });
        }
      })
      .catch(() =>
        console.log("Can’t access " + url + " response. Blocked by browser?")
      );
  };

  render() {
    if (200 == this.state.status && "SUCCESS" == this.state.message) {
      return <Navigate to="Partner" />;
    } else {
      return (
        <div>
          <div className="ml-background moneybgnew">
            <div className="main-header">
              <div className="sticky-top sticky-head">
                <Navbar
                  className="navbar navbar-expand-lg navbar-light bg-light"
                  bg="light"
                  expand="lg"
                >
                  <Container className='="containerheader'>
                    <div className="toplogoml">
                      <Link to="/MoneyLink">
                        <img
                          className="toplogo"
                          src={MoneyLinklogo}
                          alt="logo"
                        />{" "}
                      </Link>
                    </div>
                    <Nav className="navdiv">
                      <div className="menu-item loginitemmenu ">
                        <Link to="" className="productmenuhover">
                          <div className="signbuttonpage">
                            Partner Sign in{" "}
                            <i className="fa fa-angle-right"></i>
                          </div>
                        </Link>
                        <div className="drop-down-menu signinmenu">
                          <ul className="partnerlistlogin">
                            <li className="loginpartnerlist">
                              {" "}
                              <a
                                href="https://www.duopay.montra.org/PartnerLogin"
                                className="loginmenu"
                              >
                                <i class="fas fa-lock"></i>DUO Login{" "}
                                <i className="fa fa-angle-right loginangle"></i>
                              </a>
                            </li>
                            <li className="loginpartnerlist">
                              {" "}
                              <NavLink
                                as={NavLink}
                                to="PartnerLogin"
                                className="loginmenu"
                              >
                                <i class="fas fa-lock"></i>MoneyLink Login{" "}
                                <i className="fa fa-angle-right loginangle"></i>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Nav>
                  </Container>
                </Navbar>
              </div>
            </div>

            <div className="forgotpasswordchange">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <form method="post" name="userpin">
                      <div className="form-group otpfield">
                        <label htmlFor="verifyotp">Type User PIN </label>

                        <input
                          type="password"
                          className="form-control"
                          name="pin"
                          id=""
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="form-group otpfield">
                        <label htmlFor="verifyotp">Retype User PIN</label>

                        <input
                          type="password"
                          className="form-control"
                          name="confirmpin"
                          id=""
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="otp">
                        <Link to="">
                          <input
                            type="submit"
                            className="btn btn-warning userpin"
                            onClick={this.handleSubmit}
                          />
                        </Link>
                      </div>
                      {(() => {
                        switch (this.state.message) {
                          case "UNSUCCESS":
                            return (
                              <div className="errorMsg">
                                {" "}
                                <p>{this.state.errorMsg}</p>
                              </div>
                            );
                          default:
                            return <p></p>;
                        }
                      })()}
                      <p>{this.state.serverErr}</p>
                    </form>
                  </div>

                  <div className="col-md-4"></div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}

          <Footer />
        </div>
      );
    }
  }
}

export default Userpin;
