/* React packages and components */
import React from "react";
// import Header from "./Header";
import Footer from "../Footer/MontraFooter";
import { Link, NavLink, Navigate } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import MoneyLinklogo from "../../assets/paylink-images/montra/newml.svg";

import "./forgotpassword.css";

/**
 * Reset the password by entering the pin.
 * <p> User allowed to enter the pin that is sent to their email</p>
 * @Author : Seema Kumari
 * @version :1.0
 * */

class Forgotpassword1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      backerrors: [],
      email: "",
      status: "",
      message: "",
      errorMsg: "",
      serverErr: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  /**
   *capyures the new password to server entered by user.
   * @param {Event} e event which calls this method.
   */
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }
  /**
   *Submit the otp to server entered by to validate.
   * @param {Event} e event occurs on clicking the submit button.
   */
  handleOtp = (e) => {
    e.preventDefault();
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL(
      "https://api.montra.org/moneylinkweb/api/user/verifyotp" +
        "?" +
        "email=" +
        localStorage.getItem("email") +
        "&otp=" +
        this.state.fields.otp
    );
    fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({
            serverErr: "Please try After Sometime",
          });
        }
      })
      .then((contents) => {
        if (200 == contents.status && "SUCCESS" == contents.message) {
          this.setState({
            role: contents.data.role,
            status: contents.status,
            message: contents.message,
          });
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            message: contents.message,
          });
          this.state.backerrors.map((key) => {
            this.setState({
              errorMsg: key.message,
            });
          });
        }
      })
      .catch(() =>
        console.log("Can’t access " + url + " response. Blocked by browser?")
      );
  };
  render() {
    if (this.state.status == 200 && "SUCCESS" == this.state.message) {
      return <Navigate to="/Forgotpassword2" />;
    }
    return (
      <div>
        <div className="ml-background moneybgnew">
          <div className="main-header">
            <div className="sticky-top sticky-head">
              <Navbar
                className="navbar navbar-expand-lg navbar-light"
                expand="lg"
              >
                <div className="toplogoml">
                  <Link to="/MoneyLink/PartnerLogin">
                    <img className="toplogo" src={MoneyLinklogo} alt="logo" />{" "}
                  </Link>
                </div>
              </Navbar>
            </div>
          </div>

          <div className="forgotpasswordchange">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  {/* title */}
                  <h5 className="headerpass"> Enter Security Code </h5>
                  <form>
                    <div className="form-group">
                      <label htmlFor="password">
                        Please check your email for a message with your code. We
                        sent your code to: {localStorage.getItem("email")}{" "}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="otp"
                        placeholder="Enter Code"
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                    {(() => {
                      switch (this.state.message) {
                        case "UNSUCCESS":
                          return (
                            <div className="errorMsg">
                              {" "}
                              <p>{this.state.errorMsg}</p>
                            </div>
                          );
                        default:
                          return <p></p>;
                      }
                    })()}
                    <div className="sign">
                      <input
                        type="submit"
                        className="btn btn-warning partnerbutton"
                        value="Submit"
                        onClick={this.handleOtp}
                      />
                    </div>
                    <p>{this.state.serverErr}</p>
                  </form>
                </div>

                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default Forgotpassword1;
