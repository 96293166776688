import React from "react";

function CashWithdrawal() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#223872"
        d="M21.5 13.5v9h-18v-9h-3V15H2v9h21v-9h1.5v-1.5h-3z"
      ></path>
      <path
        fill="#223872"
        d="M10.519 14.733c.404.111.816.188 1.233.23v3.478l-1.345-1.345-1.06 1.061 3.155 3.155 3.155-3.155-1.06-1.06-1.345 1.344v-3.477a7.506 7.506 0 006.616-8.881l-1.473.283a5.993 5.993 0 11-4.294-4.65l.4-1.445a7.5 7.5 0 10-3.982 14.462z"
      ></path>
      <path
        fill="#223872"
        d="M17.41 1.834a7.451 7.451 0 00-1.37-.943l-.708 1.32c.392.211.759.464 1.095.756l.983-1.133zM17.994 5.097l1.374-.6a7.533 7.533 0 00-.832-1.44l-1.208.89c.264.357.488.743.666 1.15zM8 19.502H6.5v-1.5H5v3h3v-1.5zM17 19.502v1.5h3v-3h-1.5v1.5H17z"
      ></path>
      <path
        stroke="#00BAF2"
        strokeLinecap="square"
        d="M9.395 7.049h6.376M9.395 8.322h6.376M14.494 10.236h-.637l-2.55-5.101h-.678v5.1M14.527 10.236V5.135"
      ></path>
    </svg>
  );
}

export default CashWithdrawal;
