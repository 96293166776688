import React from "react";

function PG2() {
  return (
    <svg
      width="92"
      height="29"
      viewBox="0 0 92 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        pathLength="1"
        d="M1 0V18C1 21.5 3.22655 28 14.0689 28C24.9112 28 62.1624 28 79.4327 28C89.0078 28 91.8005 28 92 28"
        stroke="url(#paint0_radial_4799_6616)"
        stroke-width="2"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4799_6616"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(59.5314 10.1501) rotate(90) scale(22.05 33.3617)"
        >
          <stop stop-color="#0CC2FF" />
          <stop offset="1" stop-color="#8CF2B2" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default PG2;
