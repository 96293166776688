/* React packages and components */
import React from "react";
import { Link } from "react-scroll";

import { Container } from "react-bootstrap";
import Footerlogo from "../../assets/paylink-images/moneylink/montralogo.svg";

/**
 * Renders footer section of all MoneyLink statics page.

 <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b> h3 :-</b> This HTML tag that indicates a heading on a website.</li>
 * <li> <b> p :-</b> This tag is  used to designate a paragraph</li>
 * <li> <b>img :- </b> This tags must have a defined src attribute. This defines the image to be displayed. </li>

 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>Bootstrap-fontawsome :-</b> it is use for icons.</li>

 *  @Author : Thrilok Kandra
 *  @version :1.0
 */

const MontraFooter = () => {
  return (
    <React.Fragment>
      <footer className="montrafooter">
        <Container>
          <div className="flex-container">
            <div className="flexcss">
              <div className="mlfootlogo">
                <img
                  src={Footerlogo}
                  className="footermllogo"
                  alt="Footerlogo"
                />
              </div>
              <p className="mlfooterparagraph">
                Montra introduces innovative products & solutions that promote
                Financial Inclusion across emerging economies towards transition
                from cash to digital payments & making accessibility to Credit
                easy for everyone
              </p>
              <div className="copyright">
                <p className="copyrightfooterml">
                  <span className="copysymbol">&#169;</span>Montra
                </p>
              </div>
            </div>

            <div className="flexcss1">
              <p className="mlheader">Product</p>
              <div className="borderfooter"></div>
              <ul className="mlfooterullist">
                <a href="/MoneyLink" className="footermenulink">
                  <li className="footerli">MoneyLink</li>{" "}
                </a>
                <a href="/DUO" offset={-130} className="footermenulink">
                  {" "}
                  <li className="footerli">DUO</li>{" "}
                </a>
                <a href="/Montra" className="footermenulink">
                  {" "}
                  <li className="footerli">Montra</li>
                </a>
              </ul>
            </div>

            <div className="flexcss3">
              <a href="/Montra">
                {" "}
                <p className="mlheader">Montra</p>
              </a>
              <div className="borderfooter"></div>
              <ul className="mlfooterullist">
                <a
                  href="/MoneyLink-Consumer-Payments/Pay"
                  className="footermenulink"
                >
                  {" "}
                  <li className="footerli">Montra - Personal</li>{" "}
                </a>
                <a
                  href="/MoneyLink-Business-Payments/Payments"
                  offset={-130}
                  className="footermenulink"
                >
                  {" "}
                  <li className="footerli">Montra - Business</li>{" "}
                </a>
                <a href="/PaymentGateway" className="footermenulink">
                  {" "}
                  <li className="footerli">Payment Gateway</li>
                </a>
              </ul>
            </div>

            <div className="flexcss6">
              <a href="/DUO">
                {" "}
                <p className="mlheader">DUO </p>
              </a>
              <div className="borderfooter"></div>
              <ul className="mlfooterullist">
                <a href="/DualTransactionServices" className="footermenulink">
                  {" "}
                  <li className="footerli">
                    Dual Transaction Service (DTS)
                  </li>{" "}
                </a>
                <a
                  href="/DuoCardProduct"
                  offset={-130}
                  className="footermenulink"
                >
                  {" "}
                  <li className="footerli">DUO Card Product (DCP)</li>{" "}
                </a>
                <a href="/DuoApp" offset={-130} className="footermenulink">
                  {" "}
                  <li className="footerli">DUO App</li>{" "}
                </a>
                <a href="/DuoPortal" offset={-130} className="footermenulink">
                  {" "}
                  <li className="footerli">DUO Portal</li>{" "}
                </a>
              </ul>
            </div>

            <div className="flexcss4">
              <a href="/MoneyLink">
                <p className="mlheader">MoneyLink</p>
              </a>
              <div className="borderfooter"></div>
              <ul className="mlfooterullist">
                <a href="/CardIssuingProcessing" className="footermenulink">
                  {" "}
                  <li className="footerli">Card Issuing & Processing</li>
                </a>
                <a href="/LoanManagementSystem" className="footermenulink">
                  <li className="footerli">Loan Management System</li>
                </a>
                <a href="/SalaryProcessing" className="footermenulink">
                  {" "}
                  <li className="footerli">Salary Processing</li>
                </a>
                <a href="/AccountManagement" className="footermenulink">
                  {" "}
                  <li className="footerli">Account Management</li>
                </a>
                <a href="/SupplyChainSolution" className="footermenulink">
                  {" "}
                  <li className="footerli">Supply Chain Solution</li>
                </a>
              </ul>
            </div>
            <div className="flexcss5">
              <p className="mlheader">
                {" "}
                <Link to="#">About MoneyLink </Link>
              </p>
              <div className="borderfooter"></div>
              <ul className="mlfooterullist">
                <a href="company" className="footermenulink">
                  {" "}
                  <li className="footerli">Company</li>
                </a>
                <li className="footerli">Contact</li>
              </ul>
              <p className="mlfooterparagraph duounit">
                Unit 6, First Floor, 20 The Quad, Lekki Phase 1, Admiralty Road,
                Lagos, Nigeria
              </p>
            </div>
          </div>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default MontraFooter;
