import React from "react";

function YU5() {
  return (
    <svg
      width="530"
      height="233"
      viewBox="0 0 530 233"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1H15.4957H76.9785C84.4765 1 95.9733 6.93478 95.9733 17.4348C95.9733 24.7391 96 148.609 96 214.5C95.8334 218.913 96.5 232 115.968 232C132.363 232 383.06 232 506.359 232C520.5 232 529 222.413 529 211C529 199.29 529.185 151.599 528.852 126.235C528.852 120.12 529 32.9166 529 7.5"
        stroke="url(#paint0_radial_4716_6484)"
        stroke-width="2"
        pathLength="1"
        className="yu-path"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4716_6484"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-133.463 66.58) rotate(9.00593) scale(649.326 669.33)"
        >
          <stop stop-color="#17D92A" />
          <stop offset="1" stop-color="#635BFF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU5;
