import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import "./assets/css/style.css";
import "./App.css";
import "./Moneylink/moneylinkStyle.css";
import "./Moneylink/moneylinkCentral.css";
// import './Moneylink/PaylinkStyle.css';

const App = () => {
  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default App;
