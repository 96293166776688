
/* React packages */
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

/**
 *   Render's manage user(For credit-UW users) for checker section.
 * <p>Allows maker to make the changes.</p>
 * <p>Maker allowed to submit the changes with the pin.</p>
 *  @Author : Thrilok Kandra
 *  @version :1.0
 */

class ManageUserPart2 extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         fields: {},
         fieldsnotvalid: false,
         backerrors: null,
         status: null,
         message: null,
         ErrorMessage: '',
      }
      this.addInput = this.addInput.bind(this);
   };
   addInput(e) {
      this.props.addInput(e);
   }
   handleInputBlur = (e) => {
      e.preventDefault();
      let data = e.target.value;
      if (!this.state.fieldsnotvalid) {
         axios.post('api/user/checkexistinguser', { email: data })
            .then(response => {
               const contents = response.data;
               if (response.status === 200 && contents.message === 'SUCCESS') {
               } else if (contents.message === 'UNSUCCESS' && contents.errors && contents.errors.length > 0) {
                  this.setState({
                     ErrorMessage: contents.errors[0].message
                  })
               } else {
                  this.setState({
                     backerrors: contents.errors,
                     status: contents.status,
                     message: contents.message,

                  })
               }
            })
            .catch(error => {
               console.error("There was an error making the request:", error);
            });
      }
   }

   handleInputFocus = (e) => {
      this.setState({ ErrorMessage: '' });
   }

   render() {
      let k = 0;
      return (
         <div className="managemat">
            {/* Form -: in this form section included input text, label, togglebutton  to render the data */}
            {this.props.data.map((manageUser, i) =>
               <form key={i} method="post" name="userRegistrationForm" className="formroot managemat">
                  <div key={i} className="d-flex flex-row usercuwmak">
                     <div className="p-2 managemak1">
                        <div className="form-group">
                           <label className="serial-header headmak">S. No.</label>
                           <div key={i} className="heightch heightmaker">
                              <p className="serialch">{++k}</p>
                           </div>
                        </div>
                     </div>
                     <div className="p-2 managemak2">
                        <div className="form-group">
                           <label className="usertheaderch usnamemak1">User Name</label>
                           <div key={i} className="inner-addon right-addon">
                              <textarea type="text" className="form-control formproduct desk"
                                 value={manageUser.userName}
                                 onChange={(e) => { this.props.handleChangecredituwusername(e, i) }}
                                 name="username"
                                 disabled={this.props.credituwcolor[i] || this.props.activecredituwdisable[i] || !manageUser?.isEditable}
                                 style={{
                                    backgroundColor: this.props.backGroundColorcredituw[i],
                                    borderColor: this.props.isUserNamecredituwlist[i]
                                 }} />
                           </div>
                        </div>
                     </div>
                     <div className="p-2 managemak2">
                        <div className="form-group">
                           <label className="usertheaderch desig makerd">User Designation</label>
                           <div key={i} className="inner-addon right-addon">
                              <textarea type="text" className="form-control formproduct desk"
                                 value={manageUser.designation}
                                 onChange={(e) => { this.props.handleChangecredituwuserdesgn(e, i) }}
                                 name="userdesgn1"
                                 disabled={this.props.credituwcolor[i] || this.props.activecredituwdisable[i] || !manageUser?.isEditable}
                                 style={{
                                    backgroundColor: this.props.backGroundColorcredituw[i],
                                    borderColor: this.props.isDesignationcredituwlist[i]
                                 }} />
                           </div>
                        </div>
                     </div>
                     <div className="p-2 managemak2">
                        <div className="form-group">
                           <label className="usertheadermak usname2">User Type</label>
                           <div key={i} className="inner-addon right-addon">
                              <textarea type="text" className="form-control formproduct desk"
                                 value={manageUser.userType}
                                 onChange={(e) => { this.props.handleChangecredituwusertype(e, i) }}
                                 name="userdesgn1"
                                 disabled={this.props.credituwcolor[i] || this.props.activecredituwdisable[i] || !manageUser?.isEditable}
                                 style={{
                                    backgroundColor: this.props.backGroundColorcredituw[i],
                                    borderColor: this.props.isUserTypecredituwlist[i]
                                 }} />
                           </div>
                        </div>
                     </div>
                     <div className="p-2 managemak2 managemakemail">
                        <div className="form-group">
                           <label className="usertheaderch user6">User Official Email ID</label>
                           <div key={i} className="inner-addon right-addon">
                              <textarea type="text" className="form-control formproduct" name="email1"
                                 value={manageUser.officialEmail}
                                 onChange={(e) => { this.props.handleChangecredituwemail(e, i) }}
                                 onBlur={this.handleInputBlur}
                                 onFocus={this.handleInputFocus}
                                 disabled={this.props.credituwcolor[i] || this.props.activecredituwdisable[i] || this.props.emailcredituwdisable[i]}
                                 style={{
                                    backgroundColor: this.props.backGroundColorcredituw[i],
                                    borderColor: this.props.isEmailcredituwlist[i]
                                 }} />
                           </div>
                        </div>
                        <p className='errorMsgpin'>{this.state.ErrorMessage}</p>
                     </div>
                     <div className="p-2 managemak2">
                        <div className="form-group">
                           <label className="usertheaderch user7 makernumb">User Mobile Number <br /></label>
                           <div key={i} className="inner-addon right-addon">
                              <textarea type="number" className="form-control formproduct desk"
                                 name="mobile" value={manageUser.mobile}
                                 onChange={(e) => { this.props.handleChangecredituwmobile(e, i) }}
                                 disabled={this.props.credituwcolor[i] || this.props.activecredituwdisable[i] || !manageUser?.isEditable}
                                 style={{
                                    backgroundColor: this.props.backGroundColorcredituw[i],
                                    borderColor: this.props.isMobilecredituwlist[i]
                                 }} />
                           </div>
                        </div>
                     </div>
                     {/* Activation -: here activation toggle button is used for disable the all input fields and also changed the color */}
                     <div className="p-2  managech3">
                        <div className="form-group actiflag">
                           <label className="usertheaderchsub">Activation </label>
                           <div key={i} className="form-group togglemanage flagtoggle">
                              <label className="switch toggleswitch"><input type="checkbox"
                                 name="active" checked={manageUser.active}
                                 onChange={(e => { this.props.toggleChangecredituwactive(e, i) })}
                                 disabled={this.props.credituwcolor[i] || !manageUser?.isEditable} />
                                 <div className="slider round" >
                                 </div>
                              </label>
                           </div>
                        </div>
                     </div>
                     {/* Removal -: here Removal button is used for disable the all input fields and also changed the color */}
                     <div className="p-2 managecheck4">
                        <div className="form-group actiflag">
                           <label className="usertheaderchsub">Removal</label>
                           <div key={i} className="form-group togglemanage">
                              <button className="btn btn-success removal"
                                 disabled={!manageUser?.isEditable}
                                 onClick={(e) => { this.props.handleChangecredituwremoval(e, i) }}>Remove</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="pafucmaker">
                     <p className="usertextmak7">Products assigned for Under-writing</p>
                     <div className="d-flex flex-row portable">
                        {manageUser.prodsAssignedForUWRules.map((prodsAssignedForUWRules, j) =>
                           <div key={j} className="form-group productlabelname">
                              <label className="usersubheader">{prodsAssignedForUWRules.productName}</label>
                           </div>
                        )}
                     </div>
                     <div className="d-flex flex-row portable2">
                        {manageUser.prodsAssignedForUWRules.map((prodsAssignedForUWRules, j) =>
                           <div key={j} className="form-group productlabelname">
                              <select className="form-control formselectmanage"
                                 name="status" align="center" placeholder={prodsAssignedForUWRules.prodAssigned}
                                 onChange={(e) => this.props.handleChangecredituwproducttypes(e, i, j)}
                                 disabled={this.props.activecredituwdisable[i] || !manageUser?.isEditable}
                                 style={{ backgroundColor: this.props.backGroundColorcredituw[i] }}>
                                 <option value={prodsAssignedForUWRules.prodAssigned}>{prodsAssignedForUWRules.prodAssigned}</option>
                                 <option value="NO">NO</option>
                                 <option value="YES">YES</option>
                              </select>
                           </div>
                        )}
                     </div>
                  </div>
               </form>
            )}
            {/* Add More -: here Add More button is used toadd extra row  */}
            <div className="addbutton">
               <Link to="#"><input type="submit" className="btn btn-warning addbuttonmore" value="+ Add More" onClick={this.addInput.bind(this)} /></Link>
            </div>
         </div>
      )
   }
}

export default ManageUserPart2;
