import React, { useEffect, useState } from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import FixDeposits from "../../../assets/images/uday/personal-icons/Home-icons/LoanOffers.svg";
import MutualFunds from "../../../assets/images/uday/personal-icons/Home-icons/disbursment.svg";
import loan6 from "../../../assets/images/loan6.svg";

const steps = [
  {
    title: "Investment Products",
    image: loan6,
    className: "loan6-img-img",
  },
  { image: FixDeposits, description: "Fix Deposits" },
  { image: MutualFunds, description: "Mutual Funds" },
];

const InvestmentProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#24BAF2"
        circleBorderColor="#24BAF2"
        textColor="#20336B"
        topDescriptionClass="investment-topdescription"
      />
    </div>
  );
};

export default InvestmentProcessContainer;
