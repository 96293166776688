function MontraAppIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2601_6)">
        <path
          d="M20 0H4C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0Z"
          fill="#20336B"
        />
        <path
          d="M11.2833 15.9795L11.456 15.1975L12.5601 15.0341L12.3545 15.9795H11.2833Z"
          fill="#4ABCEB"
          stroke="#4ABCEB"
        />
        <path
          d="M17.002 15.9668L17.3269 14.3794L18.4069 14.2515L18.008 15.9668H17.002Z"
          fill="#4ABCEB"
          stroke="#4ABCEB"
        />
        <path
          d="M5.00385 16.4032L7.26062 15.6408L7.0958 16.4668H5.00385V16.4032Z"
          fill="#4ABCEB"
        />
        <mask
          id="mask0_2601_6"
          maskUnits="userSpaceOnUse"
          x="5"
          y="15"
          width="3"
          height="2"
        >
          <path
            d="M5.00385 16.4032L7.26062 15.6408L7.0958 16.4668H5.00385V16.4032Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2601_6)">
          <path
            d="M5.00385 16.4032L4.68379 15.4558L4.00385 15.6855V16.4032H5.00385ZM7.26062 15.6408L8.24129 15.8365L8.57991 14.1396L6.94056 14.6934L7.26062 15.6408ZM7.0958 16.4668V17.4668H7.91597L8.07647 16.6625L7.0958 16.4668ZM5.00385 16.4668H4.00385V17.4668H5.00385V16.4668ZM5.32392 17.3506L7.58069 16.5882L6.94056 14.6934L4.68379 15.4558L5.32392 17.3506ZM6.27996 15.4451L6.11514 16.2711L8.07647 16.6625L8.24129 15.8365L6.27996 15.4451ZM7.0958 15.4668H5.00385V17.4668H7.0958V15.4668ZM6.00385 16.4668V16.4032H4.00385V16.4668H6.00385Z"
            fill="#4ABCEB"
          />
        </g>
        <mask
          id="mask1_2601_6"
          maskUnits="userSpaceOnUse"
          x="5"
          y="7"
          width="15"
          height="10"
        >
          <path
            d="M19.048 13.6953C18.3198 13.7724 17.6065 13.8533 16.9083 13.938L17.5772 10.7927C17.6485 10.4825 17.6841 10.2022 17.6841 9.95177C17.6841 9.0579 17.1437 8.61098 16.063 8.61098H14.4405L13.198 14.4466C12.4466 14.5641 11.7252 14.6874 11.0336 14.8165L12.3541 8.61098H9.28823C8.92028 8.61098 8.70067 8.78965 8.6294 9.147L7.24814 15.6791C6.39714 15.9189 5.64295 16.176 5 16.4491L6.66877 8.61098C6.78724 8.05039 6.98904 7.6415 7.27416 7.38432C7.55926 7.12811 8.00475 7 8.61062 7H16.6149C17.6013 7 18.3887 7.19698 18.9772 7.59093C19.5657 7.98392 19.86 8.59219 19.86 9.41575C19.86 9.76153 19.8359 10.0356 19.7878 10.2378L19.048 13.6953Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_2601_6)">
          <path
            d="M67.8858 22.8453H2.83986V4.71279H67.8858V22.8453Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2601_6">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MontraAppIcon;
