import React from "react";
import "../Overview/style.css";
import "./style.css";
import dcpcard from "../../../../assets/images/dcpcard.webp";

const Hero = () => {
  return (
    <>
      <div className="dts-hero dcp-hero">
        <div className="row dcp-hero-row hero-row ">
          <div className="col-md-6 dcp-col duo-col ">
            <h1>
              Enjoy DUO Card experience with both{" "}
              <span>
                Credit <span className="andfont">&</span> Debit cards in a
                single card
              </span>
            </h1>
            <p className="elevate">
              Elevate your experience with the DUO Card—featuring Dual
              Chips/Magnetic Stripes for seamless Debit and Credit transactions
              worldwide.
            </p>
            <div className="duo-list-container dcp-list-container">
              <div className="duo-lists">
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p className="dcp-list-p">
                      DUO Card Product (DCP) comes with Two Chips / Magnetic
                      stripes, one each for making a Debit & Credit transaction
                      requests from anywhere in the world.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p className="dcp-list-p">
                      Simply dip or swipe the Credit or Debit side based on
                      where you choose to pay from without carrying multiple
                      cards.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p className="dcp-list-p">
                      Similarly, you can Withdraw cash on any ATM, anywhere in
                      the world from either of your Credit & Debit accounts.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p className="dcp-list-p">
                      Use DUO for making online transactions by entering details
                      of the Credit or Debit card without having to carry
                      multiple cards.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <button>Contact Sales</button>
          </div>
          <div className="col-md-6 duo-col dcp-col dcp-hero-image-col">
            <img src={dcpcard} alt="dcpcard" className="dcpcard" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
