/* React packages  */
import React from "react";
import { Link } from "react-router-dom";

/**
  * LoanRequestView1 component is render all data in Credit-UW view-details section.
  * <p>There are four type of Data will be there :</p>
  * <li>About Customer.</li>
  * <li>Employer Business.</li>
  * <li>Customers Bank.</li>
  * <li>Credit Bureau Data.</li>

  <br>
  * <h6>Below Html elemensts are created to use for  UI</h6>
  * <li> <b> Section :- </b>it is used for represents a standalone section</li>
  * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
  * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
  * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
  <br>
  * <h6>Below Bootstrap class is use for ui responsive.</h6>
  * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
  * <li> <b>row :-</b> This class to create a structure.</li>
  * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
  * <li> <b>ReactTooltip :-</b> This tooltip is a small pop up that appears when user places the mouse pointer over an element such as link or buttons to provide hint or information about the element being hovered.</li>

  @Author : Seema Kumari
  @version :1.0
 */

class LoanRequestView1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        age: "",
        ageEligibility: "",
        amount: "",
        amountEligibility: "",
        amountRequestedELigibility: "",
        approvedInterestRate: "",
        approverDecision: "",
        avgSalary: "",
        avgSalaryInterestMult: "",
        avgSalaryTenorMult: "",
        bankAcctNumber: "",
        bankCustomerId: "",
        bankName: "",
        baseInterestRate: "",
        baseTenor: "",
        bureauReportUrl1: "",
        bureauReportUrl2: "",
        bureauReportUrl3: "",
        bureauScoreIntMult: "",
        bvn: "",
        compCat: "",
        companyCatIntMult: "",
        creditBureauDataDto: [
          {
            creditBureauName: "",
            totalLoanOutstanding: "",
            existingDebitBurden: "",
            dueAmtInXdays: "",
            dueAmtIn30DPD: "",
            dueAmtIn60DPD: "",
            dueAmtInGT90DPD: "",
            bureauScore: "",
            aggregatedPosition: "",
          },
        ],
        customerName: "",
        customerType: "",
        debitBurdenAllowedAmt: "",
        debtBurdenAllowPercent: "",
        deleiquencyStatusIntMut: "",
        emi: "",
        empOrBusinesssName: "",
        finalTenor: "",
        lastSalary: "",
        loanId: "",
        loanProdName: "",
        loanReqDate: "",
        loanStatus: "",
        maxAge: "",
        maxLoanAmtCap: "",
        minAge: "",
        minLoanAmt: "",
        mobile: "",
        numberOfYrsEmpIntMult: "",
        numofYrsWithCurrEmplyIntMult: "",
        payLinkDecision: "",
        payLinkDecisionDate: "",
        payLinkId: "",
        totalWorkExp: "",
        totalWorkExpWithEmployer: "",
      },
      fields: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }
  // componentDidMount() {
  //     console.log(this.props.loanid)
  //     // const proxyurl = "https://cors-anywhere.herokuapp.com/";
  //     let url = new URL('https://api.montra.org/moneylinkweb/api/credituw/loanapplication?loanid=' + this.props.loanid + '&email=' + localStorage.getItem("email"))
  //     fetch(url, {
  //         method: 'GET',
  //         headers: { 'Content-Type': 'application/json;charset=utf-8', 'Access-Control-Allow-Origin': '*' },
  //     })
  //         .then((response) => {
  //             if (response.ok) {
  //
  //                 return response.json();
  //             }
  //             else {
  //                 alert('server error')
  //             }
  //         })
  //         .then(contents => {
  //
  //             this.setState({ data: contents.data });
  //             console.log(this.state.data)
  //         }
  //         )
  // }

  /**
   *
   * @param {Event} e captures the value for pin on onChange event
   */
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-2 loanview1">
            <p className="viewheader">ABOUT CUSTOMER </p>

            <form className="formview1">
              <div className="form-group">
                <label className="lablename">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.customerName}
                />
              </div>

              <div className="form-group">
                <label className="lablename">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.mobile}
                />
              </div>

              <div className="form-group">
                <label className="lablename">BVN</label>
                <input
                  type="text"
                  className="form-control"
                  name="bvn"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.bvn}
                />
              </div>

              <div className="form-group">
                <label className="lablename">PayLink ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="paylinkId"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.payLinkId}
                />
              </div>
            </form>
          </div>

          <div className="col-sm-12 col-md-6 col-xl-2 loanview">
            <p className="viewheader">ABOUT EMPLOYER / BUSINESS </p>

            <form className="formview2">
              <div className="form-group">
                <label className="lablename">Customer Type</label>
                <input
                  type="text"
                  className="form-control"
                  name="customerType"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.customerType}
                />
              </div>

              <div className="form-group">
                <label className="lablename">Employer’s / Business Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="empOrBusinesssName"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.empOrBusinesssName}
                />
              </div>

              <div className="form-group">
                <label className="lablename">Company Category</label>
                <input
                  type="text"
                  className="form-control"
                  name="compCat"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.compCat}
                />
              </div>

              <div className="form-group">
                <label className="lablename">
                  Working with this employer / Business(Years)
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="totalWorkExpWithEmployer"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.totalWorkExpWithEmployer}
                />
              </div>
            </form>
          </div>

          <div className="col-sm-12 col-md-5 col-xl-2 loanview">
            <p className="viewheader">ABOUT CUSTOMER’S BANK </p>

            <form className="formview3">
              <div className="form-group">
                <label className="lablename">Bank Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="bankName"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.bankName}
                />
              </div>

              <div className="form-group">
                <label className="lablename">Bank Account Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="bankAcctNumber"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.bankAcctNumber}
                />
              </div>

              <div className="form-group">
                <label className="lablename">Bank Customer ID</label>
                <input
                  type="text"
                  className="form-control"
                  name="bankCustomerId"
                  onChange={this.handleChange.bind(this)}
                  placeholder={this.props.data.bankCustomerId}
                />
              </div>
            </form>
          </div>

          <div className="col-sm-12 col-md-7 col-xl-6 loanviewbig">
            <p className="viewheader">ABOUT CREDIT BUREAU DATA </p>
            <div className="formview4">
              <form className="formflex">
                <div className="d-flex flex-row secform">
                  <div className="p-2 childview">
                    <div className="form-group">
                      <label className="lablenameview creb">
                        Credit Bureau Name
                      </label>
                      {this.props.data.creditBureauDataDto.map(
                        (creditBureauDataDto, i) => (
                          <input
                            key={i}
                            type="text"
                            className="form-control flexinput"
                            name="lendingcompanyname"
                            placeholder={creditBureauDataDto.creditBureauName}
                          />
                        )
                      )}
                    </div>
                  </div>

                  <div className="p-2 childview">
                    <div className="form-group">
                      <label className="lablename totalloan">
                        Total Loan Outstanding Amount
                      </label>
                      {this.props.data.creditBureauDataDto.map(
                        (creditBureauDataDto, i) => (
                          <input
                            key={i}
                            type="text"
                            className="form-control flexinput"
                            name="lendingcompanyname"
                            placeholder={
                              creditBureauDataDto.totalLoanOutstanding
                            }
                          />
                        )
                      )}
                    </div>
                  </div>

                  <div className="p-2 childview">
                    <div className="form-group">
                      <label className="lable4thsec">
                        Existing Debt Burden
                      </label>
                      {this.props.data.creditBureauDataDto.map(
                        (creditBureauDataDto, i) => (
                          <input
                            key={i}
                            type="text"
                            className="form-control flexinput"
                            name="lendingcompanyname"
                            placeholder={
                              creditBureauDataDto.existingDebitBurden
                            }
                          />
                        )
                      )}
                    </div>
                  </div>

                  <div className="p-2 childview">
                    <div className="form-group">
                      <label className="lable4thsec delmounts">
                        Delinquent Amount
                      </label>
                      {this.props.data.creditBureauDataDto.map(
                        (creditBureauDataDto, i) => (
                          <input
                            key={i}
                            type="text"
                            className="form-control flexinput"
                            name="lendingcompanyname"
                            placeholder={creditBureauDataDto.dueAmtInXdays}
                          />
                        )
                      )}
                    </div>
                  </div>

                  <div className="p-2 childview">
                    <div className="form-group">
                      <label className="lable4thsec due">
                        Highest DPD on any exposure
                      </label>
                      {this.props.data.creditBureauDataDto.map(
                        (creditBureauDataDto, i) => (
                          <input
                            key={i}
                            type="text"
                            className="form-control flexinput"
                            name="lendingcompanyname"
                            placeholder={creditBureauDataDto.dueAmtIn30DPD}
                          />
                        )
                      )}
                    </div>
                  </div>

                  <div className="p-2 childview">
                    <div className="form-group">
                      <label className="lablename2 bureauscore">
                        Bureau Score
                      </label>
                      {this.props.data.creditBureauDataDto.map(
                        (creditBureauDataDto, i) => (
                          <input
                            key={i}
                            type="text"
                            className="form-control flexinput"
                            name="lendingcompanyname"
                            placeholder={creditBureauDataDto.bureauScore}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row secform">
                  <div className="p-2 childviewag2">
                    <div className="input-group">
                      <label className="lablename2"></label>
                      <label className="flexinput agreinput">
                        Agg. Bureau Position{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .totalLoanOutstandingAggrPosition
                        }
                      />
                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .existingDebitBurdenAggrPosition
                        }
                      />
                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .dueAmtInXdaysAggrPosition
                        }
                      />
                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .dueAmtIn30DPDAggrPosition
                        }
                      />

                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .dueAmtIn60DPDAggrPosition
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row secform">
                  <div className="p-2 childviewag2">
                    <div className="input-group">
                      <label className="lablename2"></label>
                      <label className="flexinput agreinput">
                        Lender’s exposure data{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .totalLoanOutstandingAggrPosition
                        }
                      />
                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .existingDebitBurdenAggrPosition
                        }
                      />
                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .dueAmtInXdaysAggrPosition
                        }
                      />
                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .dueAmtIn30DPDAggrPosition
                        }
                      />

                      <input
                        type="text"
                        className="form-control flexinput agreinput2"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .dueAmtIn60DPDAggrPosition
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row secform">
                  <div className="p-2 childviewag2">
                    <div className="input-group">
                      <label className="lablename2"></label>
                      <label className="flexinput agreinput3">
                        Final Agg. Position{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control flexinput agreinput4"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .totalLoanOutstandingAggrPosition
                        }
                      />
                      <input
                        type="text"
                        className="form-control flexinput agreinput4"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .existingDebitBurdenAggrPosition
                        }
                      />
                      <input
                        type="text"
                        className="form-control flexinput agreinput4"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .dueAmtInXdaysAggrPosition
                        }
                      />
                      <input
                        type="text"
                        className="form-control flexinput agreinput4"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .dueAmtIn30DPDAggrPosition
                        }
                      />

                      <input
                        type="text"
                        className="form-control flexinput agreinput4"
                        name="lendingcompanyname"
                        placeholder={
                          this.props.data.aggrPositionDto
                            .dueAmtIn60DPDAggrPosition
                        }
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoanRequestView1;
