import React from "react";
import Sidebar from "../Sidebar";
import CheckerWrittingPart1 from "./CheckerWrittingPart1";
import CheckerWrittingPart2 from "./CheckerWrittingPart2";
import CheckerWrittingPart3 from "./CheckerWrittingPart3";
import CheckerWrittingPart4 from "./CheckerWrittingPart4";
import CheckerWrittingPart5 from "./CheckerWrittingPart5";
import CheckerWrittingPart6 from "./CheckerWrittingPart6";
import axios from 'axios';
import "./CheckerUW.css";
import CommonHeader from "../../header/CommonHeader";


/**
 * CheckerWrittingRules component is render upper part of underwritting rules details of bank for checker section.
 * <p>Allows to approve or reject the changes from maker.</p>
 * <p>Allows to submit the changes after entering pin.</p>
 * @Author : Thrilok Kandra
 *  @version :1.0
 */

class CheckerWrittingRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isAvgSalIntRateMultBET200000n500000list: [],
      isAvgSalIntRateMultBET500001n1000000list: [],
      isAvgSalIntRateMultBET1000001n2000000list: [],
      isAvgSalIntRateMultBankstafflist: [],
      isAvgSalIntRateMultGT2000000list: [],
      isAvgSalIntRateMultLT200000list: [],
      isAvgSalIntRateMultStatuslist: [],
      isAvgSalTenorBankStaffvaluelist: [],
      isAvgSalTenorMultiplierStatuslist: [],
      isAvgSalaryMultBET200000n500000list: [],
      isAvgSalaryMultBET500001n1000000list: [],
      isAvgSalaryMultBET1000001n2000000list: [],
      isAvgSalaryMultGT2000000list: [],
      isAvgSalaryMultLT200000list: [],
      isBureauScoreIntRateMultBET600N700list: [],
      isBureauScoreIntRateMultBET700n800list: [],
      isBureauScoreIntRateMultBET800n900list: [],
      isBureauScoreIntRateMultGT900list: [],
      isBureauScoreIntRateMultlt600list: [],
      isCatMultiStatuslist: [],
      isCategoryAlist: [],
      isCategoryBlist: [],
      isCategoryClist: [],
      isCategoryDlist: [],
      isCategoryElist: [],
      isDebtBurdenAllowPercentlist: [],
      isDeliquencyIntRateMult30DPDlist: [],
      isDeliquencyIntRateMult60DPDlist: [],
      isDeliquencyIntRateMultCurrentlist: [],
      isDeliquencyIntRateMultGT90DPDlist: [],
      isDeliquencyIntRateMultXDayslist: [],
      isInstantloanDisCaplist: [],
      isLastSalarylist: [],
      isMaxAgelist: [],
      isMaxLoanAmtCaplist: [],
      isMinAgelist: [],
      isMinLoanAmtlist: [],
      isNumYrsCurCurEmplyIntRateMultBET1n3yrslist: [],
      isNumYrsCurEmplyIntRateMultBET3n5yrslist: [],
      isNumYrsCurEmplyIntRateMultBET5n10yrslist: [],
      isNumYrsCurEmplyIntRateMultGT10yrslist: [],
      isNumYrsCurEmplyIntRateMultLess1yrlist: [],
      isNumYrsEmplyIntMultRateMultBET1n3yrslist: [],
      isNumYrsEmplyIntMultRateMultBET3n5yrslist: [],
      isNumYrsEmplyIntMultRateMultBET5n10yrslist: [],
      isNumYrsEmplyIntMultRateMultLess1yrlist: [],
      isNumYrsEmplyIntRateMultGT10yrslist: [],
      isAvgSalIntRateMultBET200000n500000classlist: [],
      isAvgSalIntRateMultBET500001n1000000classlist: [],
      isAvgSalIntRateMultBET1000001n2000000classlist: [],
      isAvgSalIntRateMultBankstaffclasslist: [],
      isAvgSalIntRateMultGT2000000classlist: [],
      isAvgSalIntRateMultLT200000classlist: [],
      isAvgSalIntRateMultStatusclasslist: [],
      isAvgSalTenorBankStaffvalueclasslist: [],
      isAvgSalTenorMultiplierStatusclasslist: [],
      isAvgSalaryMultBET200000n500000classlist: [],
      isAvgSalaryMultBET500001n1000000classlist: [],
      isAvgSalaryMultBET1000001n2000000classlist: [],
      isAvgSalaryMultGT2000000classlist: [],
      isAvgSalaryMultLT200000classlist: [],
      isBureauScoreIntRateMultBET600N700classlist: [],
      isBureauScoreIntRateMultBET700n800classlist: [],
      isBureauScoreIntRateMultBET800n900classlist: [],
      isBureauScoreIntRateMultGT900classlist: [],
      isBureauScoreIntRateMultlt600classlist: [],
      isCatMultiStatusclasslist: [],
      isCategoryAclasslist: [],
      isCategoryBclasslist: [],
      isCategoryCclasslist: [],
      isCategoryDclasslist: [],
      isCategoryEclasslist: [],
      isDebtBurdenAllowPercentclasslist: [],
      isDeliquencyIntRateMult30DPDclasslist: [],
      isDeliquencyIntRateMult60DPDclasslist: [],
      isDeliquencyIntRateMultCurrentclasslist: [],
      isDeliquencyIntRateMultGT90DPDclasslist: [],
      isDeliquencyIntRateMultXDaysclasslist: [],
      isInstantloanDisCapclasslist: [],
      isLastSalaryclasslist: [],
      isMaxAgeclasslist: [],
      isMaxLoanAmtCapclasslist: [],
      isMinAgeclasslist: [],
      isMinLoanAmtclasslist: [],
      isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist: [],
      isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist: [],
      isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist: [],
      isNumYrsCurEmplyIntRateMultGT10yrsclasslist: [],
      isNumYrsCurEmplyIntRateMultLess1yrclasslist: [],
      isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist: [],
      isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist: [],
      isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist: [],
      isNumYrsEmplyIntMultRateMultLess1yrclasslist: [],
      isNumYrsEmplyIntRateMultGT10yrsclasslist: [],
      isAvgSalIntRateMultBET200000n500000fieldclasslist: [],
      isAvgSalIntRateMultBET500001n1000000fieldclasslist: [],
      isAvgSalIntRateMultBET1000001n2000000fieldclasslist: [],
      isAvgSalIntRateMultBankstafffieldclasslist: [],
      isAvgSalIntRateMultGT2000000fieldclasslist: [],
      isAvgSalIntRateMultLT200000fieldclasslist: [],
      isAvgSalIntRateMultStatusfieldclasslist: [],
      isAvgSalTenorBankStaffvaluefieldclasslist: [],
      isAvgSalTenorMultiplierStatusfieldclasslist: [],
      isAvgSalaryMultBET200000n500000fieldclasslist: [],
      isAvgSalaryMultBET500001n1000000fieldclasslist: [],
      isAvgSalaryMultBET1000001n2000000fieldclasslist: [],
      isAvgSalaryMultGT2000000fieldclasslist: [],
      isAvgSalaryMultLT200000fieldclasslist: [],
      isBureauScoreIntRateMultBET600N700fieldclasslist: [],
      isBureauScoreIntRateMultBET700n800fieldclasslist: [],
      isBureauScoreIntRateMultBET800n900fieldclasslist: [],
      isBureauScoreIntRateMultGT900fieldclasslist: [],
      isBureauScoreIntRateMultlt600fieldclasslist: [],
      isCatMultiStatusfieldclasslist: [],
      isCategoryAfieldclasslist: [],
      isCategoryBfieldclasslist: [],
      isCategoryCfieldclasslist: [],
      isCategoryDfieldclasslist: [],
      isCategoryEfieldclasslist: [],
      isDebtBurdenAllowPercentfieldclasslist: [],
      isDeliquencyIntRateMult30DPDfieldclasslist: [],
      isDeliquencyIntRateMult60DPDfieldclasslist: [],
      isDeliquencyIntRateMultCurrentfieldclasslist: [],
      isDeliquencyIntRateMultGT90DPDfieldclasslist: [],
      isDeliquencyIntRateMultXDaysfieldclasslist: [],
      isInstantloanDisCapfieldclasslist: [],
      isLastSalaryfieldclasslist: [],
      isMaxAgefieldclasslist: [],
      isMaxLoanAmtCapfieldclasslist: [],
      isMinAgefieldclasslist: [],
      isMinLoanAmtfieldclasslist: [],
      isNumYrsCurCurEmplyIntRateMultBET1n3yrsfieldclasslist: [],
      isNumYrsCurEmplyIntRateMultBET3n5yrsfieldclasslist: [],
      isNumYrsCurEmplyIntRateMultBET5n10yrsfieldclasslist: [],
      isNumYrsCurEmplyIntRateMultGT10yrsfieldclasslist: [],
      isNumYrsCurEmplyIntRateMultLess1yrfieldclasslist: [],
      isNumYrsEmplyIntMultRateMultBET1n3yrsfieldclasslist: [],
      isNumYrsEmplyIntMultRateMultBET3n5yrsfieldclasslist: [],
      isNumYrsEmplyIntMultRateMultBET5n10yrsfieldclasslist: [],
      isNumYrsEmplyIntMultRateMultLess1yrfieldclasslist: [],
      isNumYrsEmplyIntRateMultGT10yrsfieldclasslist: [],

      underWritingRulesstatus: [
        {
          catMultiStatus: false,
          avgSalIntRateMultStatus: false,
          avgSalTenorMultiplierStatus: false,
          bureauScoreIntRateMultltStatus: false,
          deliquencyIntRateMultStatus: false,
          numYrsCurEmplyIntRateMultStatus: false,
          numYrsEmplyIntRateMultStatus: false,
        },
      ],
      data: [
        {
          productName: "",
          minAge: "",
          maxAge: "",
          debtBurdenAllowPercent: "",
          minLoanAmt: "",
          maxLoanAmtCap: "",
          instantloanDisCap: "",
          lastSalary: "",
          categoryA: "",
          categoryB: "",
          categoryC: "",
          categoryD: "",
          categoryE: "",
          catMultiStatus: "",
          avgSalaryMultLT200000: "",
          avgSalaryMultBET200000n500000: "",
          avgSalaryMultBET500001n1000000: "",
          avgSalaryMultBET1000001n2000000: "",
          avgSalaryMultGT2000000: "",
          avgSalTenorMultiplierStatus: "",
          avgSalTenorBankStaffvalue: "",
          avgSalIntRateMultStatus: "",
          avgSalIntRateMultLT200000: "",
          avgSalIntRateMultBET200000n500000: "",
          avgSalIntRateMultBET500001n1000000: "",
          avgSalIntRateMultBET1000001n2000000: "",
          avgSalIntRateMultGT2000000: "",
          numYrsEmplyIntMultRateMultLess1yr: "",
          numYrsEmplyIntMultRateMultBET1n3yrs: "",
          numYrsEmplyIntMultRateMultBET3n5yrs: "",
          numYrsEmplyIntMultRateMultBET5n10yrs: "",
          numYrsEmplyIntRateMultGT10yrs: "",
          numYrsCurEmplyIntRateMultLess1yr: "",
          numYrsCurCurEmplyIntRateMultBET1n3yrs: "",
          numYrsCurEmplyIntRateMultBET3n5yrs: "",
          numYrsCurEmplyIntRateMultBET5n10yrs: "",
          numYrsCurEmplyIntRateMultGT10yrs: "",
          deliquencyIntRateMultCurrent: "",
          deliquencyIntRateMultXDays: "",
          deliquencyIntRateMult30DPD: "",
          deliquencyIntRateMult60DPD: "",
          deliquencyIntRateMultGT90DPD: "",
          bureauScoreIntRateMultGT900: "",
          bureauScoreIntRateMultBET800n900: "",
          bureauScoreIntRateMultBET700n800: "",
          bureauScoreIntRateMultBET600N700: "",
          bureauScoreIntRateMultlt600: "",
          isMinAge: "",
          isMaxAge: "",
          isDebtBurdenAllowPercent: "",
          isMinLoanAmt: "",
          isMaxLoanAmtCap: "",
          isInstantloanDisCap: "",
          isLastSalary: "",
          isCategoryA: "",
          isCategoryB: "",
          isCategoryC: "",
          isCategoryD: "",
          isCategoryE: "",
          isCatMultiStatus: "",
          isAvgSalaryMultLT200000: "",
          isAvgSalaryMultBET200000n500000: "",
          isAvgSalaryMultBET500001n1000000: "",
          isAvgSalaryMultBET1000001n2000000: "",
          isAvgSalaryMultGT2000000: "",
          isAvgSalTenorMultiplierStatus: "",
          isAvgSalTenorBankStaffvalue: "",
          isAvgSalIntRateMultStatus: "",
          isAvgSalIntRateMultLT200000: "",
          isAvgSalIntRateMultBET200000n500000: "",
          isAvgSalIntRateMultBankstaff: "",
          isAvgSalIntRateMultBET500001n1000000: "",
          isAvgSalIntRateMultBET1000001n2000000: "",
          isAvgSalIntRateMultGT2000000: "",
          isNumYrsEmplyIntMultRateMultLess1yr: "",
          isNumYrsEmplyIntMultRateMultBET1n3yrs: "",
          isNumYrsEmplyIntMultRateMultBET3n5yrs: "",
          isNumYrsEmplyIntMultRateMultBET5n10yrs: "",
          isNumYrsEmplyIntRateMultGT10yrs: "",
          isNumYrsCurEmplyIntRateMultLess1yr: "",
          isNumYrsCurCurEmplyIntRateMultBET1n3yrs: "",
          isNumYrsCurEmplyIntRateMultBET3n5yrs: "",
          isNumYrsCurEmplyIntRateMultBET5n10yrs: "",
          isNumYrsCurEmplyIntRateMultGT10yrs: "",
          isDeliquencyIntRateMultCurrent: "",
          isDeliquencyIntRateMultXDays: "",
          isDeliquencyIntRateMult30DPD: "",
          isDeliquencyIntRateMult60DPD: "",
          isDeliquencyIntRateMultGT90DPD: "",
          isBureauScoreIntRateMultGT900: "",
          avgSalIntRateMultStatus: "",
          isBureauScoreIntRateMultBET800n900: "",
          isBureauScoreIntRateMultBET700n800: "",
          isBureauScoreIntRateMultBET600N700: "",
          isBureauScoreIntRateMultlt600: "",
        },
      ],
      status: "",
      message: "",
      backerrors: [],
      errorMsg: "",
      errors: [],
      fields: [],
    };
  }
  /**
   * Sets the boolean value for status for all sections  toggle button,disable buttons and background color
   */
  setonandoffstatusvalue() {
    let underWritingRulesstatus = this.state.underWritingRulesstatus;
    this.state.data.map((underwritingrules, i) => {
      if ("ON" == underwritingrules.avgSalIntRateMultStatus) {
        underWritingRulesstatus[0].avgSalIntRateMultStatus = true;
        this.state.avgSalIntRateMultStatusdisable = false;
      } else {
        underWritingRulesstatus[0].avgSalIntRateMultStatus = false;
        this.state.avgSalIntRateMultStatusdisable = true;
      }
      if ("ON" == underwritingrules.avgSalTenorMultiplierStatus) {
        underWritingRulesstatus[0].avgSalTenorMultiplierStatus = true;
        this.state.avgSalTenorMultiplierStatusdisable = false;
      } else {
        underWritingRulesstatus[0].avgSalTenorMultiplierStatus = false;
        this.state.avgSalTenorMultiplierStatusdisable = true;
      }
      if ("ON" == underwritingrules.bureauScoreIntRateMultltStatus) {
        underWritingRulesstatus[0].bureauScoreIntRateMultltStatus = true;
        this.state.bureauScoreIntRateMultltStatusdisable = false;
      } else {
        underWritingRulesstatus[0].bureauScoreIntRateMultltStatus = false;
        this.state.bureauScoreIntRateMultltStatusdisable = true;
      }
      if ("ON" == underwritingrules.catMultiStatus) {
        underWritingRulesstatus[0].catMultiStatus = true;
        this.state.catMultiStatusdisable = false;
      } else {
        underWritingRulesstatus[0].catMultiStatus = false;
        this.state.catMultiStatusdisable = true;
      }
      if ("ON" == underwritingrules.deliquencyIntRateMultStatus) {
        underWritingRulesstatus[0].deliquencyIntRateMultStatus = true;
        this.state.deliquencyIntRateMultStatusdisable = false;
      } else {
        underWritingRulesstatus[0].deliquencyIntRateMultStatus = false;
        this.state.deliquencyIntRateMultStatusdisable = true;
      }
      if ("ON" == underwritingrules.numYrsCurEmplyIntRateMultStatus) {
        underWritingRulesstatus[0].numYrsCurEmplyIntRateMultStatus = true;
        this.state.numYrsCurEmplyIntRateMultStatusdisable = false;
      } else {
        underWritingRulesstatus[0].numYrsCurEmplyIntRateMultStatus = false;
        this.state.numYrsCurEmplyIntRateMultStatusdisable = true;
      }
      if ("ON" == underwritingrules.numYrsEmplyIntRateMultStatus) {
        underWritingRulesstatus[0].numYrsEmplyIntRateMultStatus = true;
        this.state.numYrsEmplyIntRateMultStatusdisable = false;
      } else {
        underWritingRulesstatus[0].numYrsEmplyIntRateMultStatus = false;
        this.state.numYrsEmplyIntRateMultStatusdisable = true;
      }
    });
  }
  /**
   * Captures the changes on event is raised.
   * @param {Event} e captures the value for pin on onChange event
   */
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields,
    });
  }
  /**
   * Fecthes and render the data to fields
   */
  componentDidMount() {
    this.setState({ loading: true });
    let url = 'api/product/uwrules';
    let params = {
      email: localStorage.getItem("email")
    };
    axios.get(url, {
      params: params,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((response) => {
        const contents = response.data;
        this.setState({ loading: false });
        if (response.status === 200 && contents.message === "SUCCESS") {
          this.setState({
            data: contents.data.UnderWritungRules,
            status: contents.status,
            editable: contents.data.editable
          }, () => {
            var isAnyChanges = false;
            contents.data.UnderWritungRules.map(item => {
              if (item.isEditable) {
                isAnyChanges = true;
              }
            })
            if (!isAnyChanges) {
              isDisabledSubmitButton = true;
            }
            // if (this.state.editable) {
            //   this.setNforNonEditable();
            // }
            console.log("first,", this.state.editable);
            this.setonandoffstatusvalue();
            this.onAndOffChangeisAvgSalIntRateMultBET1000001n2000000();
            this.onAndOffChangeisAvgSalIntRateMultBET200000n500000();
            this.onAndOffChangeisAvgSalIntRateMultBET500001n1000000();
            this.onAndOffChangeisAvgSalIntRateMultBankstaff();
            this.onAndOffChangeisAvgSalIntRateMultGT2000000();
            this.onAndOffChangeisAvgSalIntRateMultLT200000();
            this.onAndOffChangeisAvgSalTenorBankStaffvalue();
            this.onAndOffChangeisAvgSalaryMultBET1000001n2000000();
            this.onAndOffChangeisAvgSalaryMultBET200000n500000();
            this.onAndOffChangeisAvgSalaryMultBET500001n1000000();
            this.onAndOffChangeisAvgSalaryMultGT2000000();
            this.onAndOffChangeisAvgSalaryMultLT200000();
            this.onAndOffChangeisBureauScoreIntRateMultBET600N700();
            this.onAndOffChangeisBureauScoreIntRateMultBET700n800();
            this.onAndOffChangeisBureauScoreIntRateMultBET800n900();
            this.onAndOffChangeisBureauScoreIntRateMultGT900();
            this.onAndOffChangeisBureauScoreIntRateMultlt600();
            this.onAndOffChangeisCategoryA();
            this.onAndOffChangeisCategoryB();
            this.onAndOffChangeisCategoryC();
            this.onAndOffChangeisCategoryD();
            this.onAndOffChangeisCategoryE();
            this.onAndOffChangeisDebtBurdenAllowPercent();
            this.onAndOffChangeisDeliquencyIntRateMult30DPD();
            this.onAndOffChangeisDeliquencyIntRateMult60DPD();
            this.onAndOffChangeisDeliquencyIntRateMultCurrent();
            this.onAndOffChangeisDeliquencyIntRateMultGT90DPD();
            this.onAndOffChangeisDeliquencyIntRateMultXDays();
            this.onAndOffChangeisInstantloanDisCap();
            this.onAndOffChangeisLastSalary();
            this.onAndOffChangeisMaxAge();
            this.onAndOffChangeisMaxLoanAmtCap();
            this.onAndOffChangeisMinAge();
            this.onAndOffChangeisMinLoanAmt();
            this.onAndOffChangeisNumYrsCurCurEmplyIntRateMultBET1n3yrs();
            this.onAndOffChangeisNumYrsCurEmplyIntRateMultBET3n5yrs();
            this.onAndOffChangeisNumYrsCurEmplyIntRateMultBET5n10yrs();
            this.onAndOffChangeisNumYrsCurEmplyIntRateMultGT10yrs();
            this.onAndOffChangeisNumYrsCurEmplyIntRateMultLess1yr();
            this.onAndOffChangeisNumYrsEmplyIntMultRateMultBET1n3yrs();
            this.onAndOffChangeisNumYrsEmplyIntMultRateMultBET3n5yrs();
            this.onAndOffChangeisNumYrsEmplyIntMultRateMultBET5n10yrs();
            this.onAndOffChangeisNumYrsEmplyIntMultRateMultLess1yr();
            this.onAndOffChangeisNumYrsEmplyIntRateMultGT10yrs();
          });
        } else {
          this.setState({
            backerrors: contents.errors || [],
            status: contents.status,
            message: contents.message || "Unknown error",
            errorMsg: contents.errors.map(error => error.message || "Error message not provided").join(', ')
          });
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        alert("Failed to fetch data. Please try again later.");
      });
  }


  setNforNonEditable() {
    this.state.data.map(underWritingrules => {
      underWritingrules.isMinAge = "N"; underWritingrules.isMaxAge = "N"; underWritingrules.isDebtBurdenAllowPercent = "N"; underWritingrules.isMinLoanAmt = "N"; underWritingrules.isMaxLoanAmtCap = "N"; underWritingrules.isLastSalary = "N"; underWritingrules.isCategoryA = "N"; underWritingrules.isCategoryB = "N"; underWritingrules.isCategoryC = "N"; underWritingrules.isCategoryD = "N"; underWritingrules.isCategoryE = "N";
      underWritingrules.isAvgSalaryMultLT200000 = "N"; underWritingrules.isAvgSalaryMultBET200000n500000 = "N";
      underWritingrules.isAvgSalaryMultBET500001n1000000 = "N"; underWritingrules.isAvgSalaryMultBET1000001n2000000 = "N"; underWritingrules.isAvgSalaryMultGT2000000 = "N"; underWritingrules.isAvgSalTenorBankStaffvalue = "N"; underWritingrules.isAvgSalIntRateMultLT200000 = "N"; underWritingrules.isAvgSalIntRateMultBET200000n500000 = "N";
      underWritingrules.isAvgSalIntRateMultBET500001n1000000 = "N"; underWritingrules.isAvgSalIntRateMultBET1000001n2000000 = "N"; underWritingrules.isAvgSalIntRateMultGT2000000 = "N"; underWritingrules.isNumYrsEmplyIntMultRateMultLess1yr = "N"; underWritingrules.isNumYrsEmplyIntMultRateMultBET1n3yrs = "N"; underWritingrules.isNumYrsEmplyIntMultRateMultBET3n5yrs = "N"; underWritingrules.isNumYrsEmplyIntMultRateMultBET5n10yrs = "N"; underWritingrules.isNumYrsEmplyIntRateMultGT10yrs = "N";
      underWritingrules.isNumYrsCurEmplyIntRateMultLess1yr = "N"; underWritingrules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = "N"; underWritingrules.isNumYrsCurEmplyIntRateMultBET3n5yrs = "N"; underWritingrules.isNumYrsCurEmplyIntRateMultBET5n10yrs = "N"; underWritingrules.isNumYrsCurEmplyIntRateMultGT10yrs = "N"; underWritingrules.isDeliquencyIntRateMultCurrent = "N"; underWritingrules.isDeliquencyIntRateMultXDays = "N"; underWritingrules.isDeliquencyIntRateMult30DPD = "N"; underWritingrules.isDeliquencyIntRateMult60DPD = "N";
      underWritingrules.isDeliquencyIntRateMultGT90DPD = "N"; underWritingrules.isBureauScoreIntRateMultGT900 = "N"; underWritingrules.isBureauScoreIntRateMultBET800n900 = "N"; underWritingrules.isBureauScoreIntRateMultBET700n800 = "N"; underWritingrules.isBureauScoreIntRateMultBET600N700 = "N"; underWritingrules.isBureauScoreIntRateMultlt600 = "N";
    })
  }
  setStatus = (statusres) => {
    this.setState({ status: statusres });
  };
  changeStatusToOnAndOff() {
    const { underWritingRulesstatus } = this.state;
    console.log("underWritingRulesstatus", underWritingRulesstatus);

    // Extract the status from underWritingRulesstatus
    const catMultiStatus = underWritingRulesstatus[0]?.catMultiStatus;
    const avgSalIntRateMultStatus =
      underWritingRulesstatus[0]?.avgSalIntRateMultStatus;
    const avgSalTenorMultiplierStatus =
      underWritingRulesstatus[0]?.avgSalTenorMultiplierStatus;
    const bureauScoreIntRateMultltStatus =
      underWritingRulesstatus[0]?.bureauScoreIntRateMultltStatus;
    const deliquencyIntRateMultStatus =
      underWritingRulesstatus[0]?.deliquencyIntRateMultStatus;
    const numYrsCurEmplyIntRateMultStatus =
      underWritingRulesstatus[0]?.numYrsCurEmplyIntRateMultStatus;
    const numYrsEmplyIntRateMultStatus =
      underWritingRulesstatus[0]?.numYrsEmplyIntRateMultStatus;

    // Update the state using setState
    this.setState((prevState) => ({
      data: prevState.data.map((underWritingRules) => ({
        ...underWritingRules,
        catMultiStatus: catMultiStatus ? "ON" : "OFF",
        avgSalIntRateMultStatus: avgSalIntRateMultStatus ? "ON" : "OFF",
        avgSalTenorMultiplierStatus: avgSalTenorMultiplierStatus ? "ON" : "OFF",
        bureauScoreIntRateMultltStatus: bureauScoreIntRateMultltStatus
          ? "ON"
          : "OFF",
        deliquencyIntRateMultStatus: deliquencyIntRateMultStatus ? "ON" : "OFF",
        numYrsCurEmplyIntRateMultStatus: numYrsCurEmplyIntRateMultStatus
          ? "ON"
          : "OFF",
        numYrsEmplyIntRateMultStatus: numYrsEmplyIntRateMultStatus
          ? "ON"
          : "OFF",
      })),
    }));
  }
  handleSubmitvalidateForm() {
    let errors = this.state.errors;
    let formIsValid = true;
    if (this.state.fields.pin == "" || this.state.fields.pin == null) {
      formIsValid = false;
      errors["pin"] = "*Please enter pin.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }


  handleSubmit = (e) => {
    isDisabledSubmitButton = true;
    e.preventDefault();
    if (this.handleSubmitvalidateForm()) {
      let fields = {};
      this.setState({
        fields: fields,
        errors: []
      });
      // Execute the necessary functions before the API call
      this.setonAndOffChangeisAvgSalIntRateMultBET1000001n2000000();
      this.setonAndOffChangeisAvgSalIntRateMultBET200000n500000();
      this.setonAndOffChangeisAvgSalIntRateMultBET500001n1000000();
      this.setonAndOffChangeisAvgSalIntRateMultBankstaff();
      this.setonAndOffChangeisAvgSalIntRateMultGT2000000();
      this.setonAndOffChangeisAvgSalIntRateMultLT200000();
      this.setonAndOffChangeisAvgSalIntRateMultStatus();
      this.setonAndOffChangeisAvgSalTenorBankStaffvalue();
      this.setonAndOffChangeisAvgSalTenorMultiplierStatus();
      this.setonAndOffChangeisAvgSalaryMultBET1000001n2000000();
      this.setonAndOffChangeisAvgSalaryMultBET200000n500000();
      this.setonAndOffChangeisAvgSalaryMultBET500001n1000000();
      this.setonAndOffChangeisAvgSalaryMultGT2000000();
      this.setonAndOffChangeisAvgSalaryMultLT200000();
      this.setonAndOffChangeisBureauScoreIntRateMultBET600N700();
      this.setonAndOffChangeisBureauScoreIntRateMultBET700n800();
      this.setonAndOffChangeisBureauScoreIntRateMultBET800n900();
      this.setonAndOffChangeisBureauScoreIntRateMultGT900();
      this.setonAndOffChangeisBureauScoreIntRateMultlt600();
      this.setonAndOffChangeisCatMultiStatus();
      this.setonAndOffChangeisCategoryA();
      this.setonAndOffChangeisCategoryB();
      this.setonAndOffChangeisCategoryC();
      this.setonAndOffChangeisCategoryD();
      this.setonAndOffChangeisCategoryE();
      this.setonAndOffChangeisDebtBurdenAllowPercent();
      this.setonAndOffChangeisDeliquencyIntRateMult30DPD();
      this.setonAndOffChangeisDeliquencyIntRateMult60DPD();
      this.setonAndOffChangeisDeliquencyIntRateMultCurrent();
      this.setonAndOffChangeisDeliquencyIntRateMultGT90DPD();
      this.setonAndOffChangeisDeliquencyIntRateMultXDays();
      this.setonAndOffChangeisInstantloanDisCap();
      this.setonAndOffChangeisLastSalary();
      this.setonAndOffChangeisMaxAge();
      this.setonAndOffChangeisMaxLoanAmtCap();
      this.setonAndOffChangeisMinAge();
      this.setonAndOffChangeisMinLoanAmt();
      this.setonAndOffChangeisNumYrsCurCurEmplyIntRateMultBET1n3yrs();
      this.setonAndOffChangeisNumYrsCurEmplyIntRateMultBET3n5yrs();
      this.setonAndOffChangeisNumYrsCurEmplyIntRateMultBET5n10yrs();
      this.setonAndOffChangeisNumYrsCurEmplyIntRateMultGT10yrs();
      this.setonAndOffChangeisNumYrsCurEmplyIntRateMultLess1yr();
      this.setonAndOffChangeisNumYrsEmplyIntMultRateMultBET1n3yrs();
      this.setonAndOffChangeisNumYrsEmplyIntMultRateMultBET3n5yrs();
      this.setonAndOffChangeisNumYrsEmplyIntMultRateMultBET5n10yrs();
      this.setonAndOffChangeisNumYrsEmplyIntMultRateMultLess1yr();
      this.setonAndOffChangeisNumYrsEmplyIntRateMultGT10yrs();
      this.setState({ loading: true });

      let data = [];
      this.state.data.map(item => {
        item.isEditable && data.push(item)
      })
      let url = 'api/product/saveuwrules';
      let params = {
        email: localStorage.getItem("email"),
        pin: this.state.fields.pin
      };
      let headers = {
        "Content-Type": "application/json;charset=utf-8",
        'Access-Control-Allow-Origin': "*"
      }
      axios.post(url, data, { params: params, headers: headers })
        .then((response) => {
          const contents = response.data;
          if (response.status === 200 && contents.message === 'SUCCESS') {
            isDisabledSubmitButton = true;
            this.setState({
              isDisabled: true,
              status: contents.status,
              message: contents.message
            });
            this.setState({ loading: false });
            this.setState({
              isAvgSalIntRateMultBET1000001n2000000fieldclasslist: this.state.isAvgSalIntRateMultBET1000001n2000000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalIntRateMultBET1000001n2000000classlist: this.state.isAvgSalIntRateMultBET1000001n2000000classlist.map(() => '#bcbcbb'),
              isAvgSalIntRateMultBET200000n500000fieldclasslist: this.state.isAvgSalIntRateMultBET200000n500000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalIntRateMultBET200000n500000classlist: this.state.isAvgSalIntRateMultBET200000n500000classlist.map(() => '#bcbcbb'),
              isAvgSalIntRateMultBET500001n1000000fieldclasslist: this.state.isAvgSalIntRateMultBET500001n1000000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalIntRateMultBET500001n1000000classlist: this.state.isAvgSalIntRateMultBET500001n1000000classlist.map(() => '#bcbcbb'),
              isAvgSalIntRateMultBankstafffieldclasslist: this.state.isAvgSalIntRateMultBankstafffieldclasslist.map(() => '#e9ecef'),
              isAvgSalIntRateMultBankstaffclasslist: this.state.isAvgSalIntRateMultBankstaffclasslist.map(() => '#bcbcbb'),
              isAvgSalIntRateMultGT2000000fieldclasslist: this.state.isAvgSalIntRateMultGT2000000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalIntRateMultGT2000000classlist: this.state.isAvgSalIntRateMultGT2000000classlist.map(() => '#bcbcbb'),
              isAvgSalIntRateMultLT200000fieldclasslist: this.state.isAvgSalIntRateMultLT200000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalIntRateMultLT200000classlist: this.state.isAvgSalIntRateMultLT200000classlist.map(() => '#bcbcbb'),
              isAvgSalTenorBankStaffvaluefieldclasslist: this.state.isAvgSalTenorBankStaffvaluefieldclasslist.map(() => '#e9ecef'),
              isAvgSalTenorBankStaffvalueclasslist: this.state.isAvgSalTenorBankStaffvalueclasslist.map(() => '#bcbcbb'),
              isAvgSalaryMultBET1000001n2000000fieldclasslist: this.state.isAvgSalaryMultBET1000001n2000000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalaryMultBET1000001n2000000classlist: this.state.isAvgSalaryMultBET1000001n2000000classlist.map(() => '#bcbcbb'),
              isAvgSalaryMultBET200000n500000fieldclasslist: this.state.isAvgSalaryMultBET200000n500000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalaryMultBET200000n500000classlist: this.state.isAvgSalaryMultBET200000n500000classlist.map(() => '#bcbcbb'),
              isAvgSalaryMultBET500001n1000000fieldclasslist: this.state.isAvgSalaryMultBET500001n1000000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalaryMultBET500001n1000000classlist: this.state.isAvgSalaryMultBET500001n1000000classlist.map(() => '#bcbcbb'),
              isAvgSalaryMultGT2000000fieldclasslist: this.state.isAvgSalaryMultGT2000000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalaryMultGT2000000classlist: this.state.isAvgSalaryMultGT2000000classlist.map(() => '#bcbcbb'),
              isAvgSalaryMultLT200000fieldclasslist: this.state.isAvgSalaryMultLT200000fieldclasslist.map(() => '#e9ecef'),
              isAvgSalaryMultLT200000classlist: this.state.isAvgSalaryMultLT200000classlist.map(() => '#bcbcbb'),
              isBureauScoreIntRateMultBET600N700fieldclasslist: this.state.isBureauScoreIntRateMultBET600N700fieldclasslist.map(() => '#e9ecef'),
              isBureauScoreIntRateMultBET600N700classlist: this.state.isBureauScoreIntRateMultBET600N700classlist.map(() => '#bcbcbb'),
              isBureauScoreIntRateMultBET700n800fieldclasslist: this.state.isBureauScoreIntRateMultBET700n800fieldclasslist.map(() => '#e9ecef'),
              isBureauScoreIntRateMultBET700n800classlist: this.state.isBureauScoreIntRateMultBET700n800classlist.map(() => '#bcbcbb'),
              isBureauScoreIntRateMultBET800n900fieldclasslist: this.state.isBureauScoreIntRateMultBET800n900fieldclasslist.map(() => '#e9ecef'),
              isBureauScoreIntRateMultBET800n900classlist: this.state.isBureauScoreIntRateMultBET800n900classlist.map(() => '#bcbcbb'),
              isBureauScoreIntRateMultGT900fieldclasslist: this.state.isBureauScoreIntRateMultGT900fieldclasslist.map(() => '#e9ecef'),
              isBureauScoreIntRateMultGT900classlist: this.state.isBureauScoreIntRateMultGT900classlist.map(() => '#bcbcbb'),
              isBureauScoreIntRateMultlt600fieldclasslist: this.state.isBureauScoreIntRateMultlt600fieldclasslist.map(() => '#e9ecef'),
              isBureauScoreIntRateMultlt600classlist: this.state.isBureauScoreIntRateMultlt600classlist.map(() => '#bcbcbb'),
              isCategoryAfieldclasslist: this.state.isCategoryAfieldclasslist.map(() => '#e9ecef'),
              isCategoryAclasslist: this.state.isCategoryAclasslist.map(() => '#bcbcbb'),
              isCategoryBfieldclasslist: this.state.isCategoryBfieldclasslist.map(() => '#e9ecef'),
              isCategoryBclasslist: this.state.isCategoryBclasslist.map(() => '#bcbcbb'),
              isCategoryCfieldclasslist: this.state.isCategoryCfieldclasslist.map(() => '#e9ecef'),
              isCategoryCclasslist: this.state.isCategoryCclasslist.map(() => '#bcbcbb'),
              isCategoryDfieldclasslist: this.state.isCategoryDfieldclasslist.map(() => '#e9ecef'),
              isCategoryDclasslist: this.state.isCategoryDclasslist.map(() => '#bcbcbb'),
              isCategoryEfieldclasslist: this.state.isCategoryEfieldclasslist.map(() => '#e9ecef'),
              isCategoryEclasslist: this.state.isCategoryEclasslist.map(() => '#bcbcbb'),
              isDebtBurdenAllowPercentfieldclasslist: this.state.isDebtBurdenAllowPercentfieldclasslist.map(() => '#e9ecef'),
              isDebtBurdenAllowPercentclasslist: this.state.isDebtBurdenAllowPercentclasslist.map(() => '#bcbcbb'),
              isDeliquencyIntRateMult30DPDfieldclasslist: this.state.isDeliquencyIntRateMult30DPDfieldclasslist.map(() => '#e9ecef'),
              isDeliquencyIntRateMult30DPDclasslist: this.state.isDeliquencyIntRateMult30DPDclasslist.map(() => '#bcbcbb'),
              isDeliquencyIntRateMult60DPDfieldclasslist: this.state.isDeliquencyIntRateMult60DPDfieldclasslist.map(() => '#e9ecef'),
              isDeliquencyIntRateMult60DPDclasslist: this.state.isDeliquencyIntRateMult60DPDclasslist.map(() => '#bcbcbb'),
              isDeliquencyIntRateMultCurrentfieldclasslist: this.state.isDeliquencyIntRateMultCurrentfieldclasslist.map(() => '#e9ecef'),
              isDeliquencyIntRateMultCurrentclasslist: this.state.isDeliquencyIntRateMultCurrentclasslist.map(() => '#bcbcbb'),
              isDeliquencyIntRateMultGT90DPDfieldclasslist: this.state.isDeliquencyIntRateMultGT90DPDfieldclasslist.map(() => '#e9ecef'),
              isDeliquencyIntRateMultGT90DPDclasslist: this.state.isDeliquencyIntRateMultGT90DPDclasslist.map(() => '#bcbcbb'),
              isDeliquencyIntRateMultXDaysfieldclasslist: this.state.isDeliquencyIntRateMultXDaysfieldclasslist.map(() => '#e9ecef'),
              isDeliquencyIntRateMultXDaysclasslist: this.state.isDeliquencyIntRateMultXDaysclasslist.map(() => '#bcbcbb'),
              isInstantloanDisCapfieldclasslist: this.state.isInstantloanDisCapfieldclasslist.map(() => '#e9ecef'),
              isInstantloanDisCapclasslist: this.state.isInstantloanDisCapclasslist.map(() => '#bcbcbb'),
              isLastSalaryfieldclasslist: this.state.isLastSalaryfieldclasslist.map(() => '#e9ecef'),
              isLastSalaryclasslist: this.state.isLastSalaryclasslist.map(() => '#bcbcbb'),
              isMaxAgefieldclasslist: this.state.isMaxAgefieldclasslist.map(() => '#e9ecef'),
              isMaxAgeclasslist: this.state.isMaxAgeclasslist.map(() => '#bcbcbb'),
              isMaxLoanAmtCapfieldclasslist: this.state.isMaxLoanAmtCapfieldclasslist.map(() => '#e9ecef'),
              isMaxLoanAmtCapclasslist: this.state.isMaxLoanAmtCapclasslist.map(() => '#bcbcbb'),
              isMinAgefieldclasslist: this.state.isMinAgefieldclasslist.map(() => '#e9ecef'),
              isMinAgeclasslist: this.state.isMinAgeclasslist.map(() => '#bcbcbb'),
              isMinLoanAmtfieldclasslist: this.state.isMinLoanAmtfieldclasslist.map(() => '#e9ecef'),
              isMinLoanAmtclasslist: this.state.isMinLoanAmtclasslist.map(() => '#bcbcbb'),
              isNumYrsCurCurEmplyIntRateMultBET1n3yrsfieldclasslist: this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsfieldclasslist.map(() => '#e9ecef'),
              isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist: this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist.map(() => '#bcbcbb'),
              isNumYrsCurEmplyIntRateMultBET3n5yrsfieldclasslist: this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsfieldclasslist.map(() => '#e9ecef'),
              isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist: this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist.map(() => '#bcbcbb'),
              isNumYrsCurEmplyIntRateMultBET5n10yrsfieldclasslist: this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsfieldclasslist.map(() => '#e9ecef'),
              isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist: this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist.map(() => '#bcbcbb'),
              isNumYrsCurEmplyIntRateMultGT10yrsfieldclasslist: this.state.isNumYrsCurEmplyIntRateMultGT10yrsfieldclasslist.map(() => '#e9ecef'),
              isNumYrsCurEmplyIntRateMultGT10yrsclasslist: this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist.map(() => '#bcbcbb'),
              isNumYrsCurEmplyIntRateMultLess1yrfieldclasslist: this.state.isNumYrsCurEmplyIntRateMultLess1yrfieldclasslist.map(() => '#e9ecef'),
              isNumYrsCurEmplyIntRateMultLess1yrclasslist: this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist.map(() => '#bcbcbb'),
              isNumYrsEmplyIntMultRateMultBET1n3yrsfieldclasslist: this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsfieldclasslist.map(() => '#e9ecef'),
              isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist: this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist.map(() => '#bcbcbb'),
              isNumYrsEmplyIntMultRateMultBET3n5yrsfieldclasslist: this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsfieldclasslist.map(() => '#e9ecef'),
              isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist: this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist.map(() => '#bcbcbb'),
              isNumYrsEmplyIntMultRateMultBET5n10yrsfieldclasslist: this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsfieldclasslist.map(() => '#e9ecef'),
              isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist: this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist.map(() => '#bcbcbb'),
              isNumYrsEmplyIntMultRateMultLess1yrfieldclasslist: this.state.isNumYrsEmplyIntMultRateMultLess1yrfieldclasslist.map(() => '#e9ecef'),
              isNumYrsEmplyIntMultRateMultLess1yrclasslist: this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist.map(() => '#bcbcbb'),
              isNumYrsEmplyIntRateMultGT10yrsfieldclasslist: this.state.isNumYrsEmplyIntRateMultGT10yrsfieldclasslist.map(() => '#e9ecef'),
              isNumYrsEmplyIntRateMultGT10yrsclasslist: this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist.map(() => '#bcbcbb'),

            });

          } else {
            isDisabledSubmitButton = false;
            this.setState({ loading: false });
            this.setState({
              backerrors: contents.errors || [],
              status: contents.status,
              message: contents.message || 'Unknown error',
              errorMsg: contents.errors.map(error => error.message || 'Error message not provided').join(', ')
            });
            this.setonandoffstatusvalue();
            this.onSubmitChangeisAvgSalIntRateMultBET1000001n2000000();
            this.onSubmitChangeisAvgSalIntRateMultBET200000n500000();
            this.onSubmitChangeisAvgSalIntRateMultBET500001n1000000();
            this.onSubmitChangeisAvgSalIntRateMultGT2000000();
            this.onSubmitChangeisAvgSalIntRateMultLT200000();
            this.onSubmitChangeisAvgSalTenorBankStaffvalue();
            this.onSubmitChangeisAvgSalaryMultBET1000001n2000000();
            this.onSubmitChangeisAvgSalaryMultBET200000n500000();
            this.onSubmitChangeisAvgSalaryMultBET500001n1000000();
            this.onSubmitChangeisAvgSalaryMultGT2000000();
            this.onSubmitChangeisAvgSalaryMultLT200000();
            this.onSubmitChangeisBureauScoreIntRateMultBET600N700();
            this.onSubmitChangeisBureauScoreIntRateMultBET700n800();
            this.onSubmitChangeisBureauScoreIntRateMultBET800n900();
            this.onSubmitChangeisBureauScoreIntRateMultGT900();
            this.onSubmitChangeisBureauScoreIntRateMultlt600();
            this.onSubmitChangeisCatMultiStatus();
            this.onSubmitChangeisCategoryA();
            this.onSubmitChangeisCategoryB();
            this.onSubmitChangeisCategoryC();
            this.onSubmitChangeisCategoryD();
            this.onSubmitChangeisCategoryE();
            this.onSubmitChangeisDebtBurdenAllowPercent();
            this.onSubmitChangeisDeliquencyIntRateMult30DPD();
            this.onSubmitChangeisDeliquencyIntRateMult60DPD();
            this.onSubmitChangeisDeliquencyIntRateMultCurrent();
            this.onSubmitChangeisDeliquencyIntRateMultGT90DPD();
            this.onSubmitChangeisDeliquencyIntRateMultXDays();
            this.onSubmitChangeisInstantloanDisCap();
            this.onSubmitChangeisLastSalary();
            this.onSubmitChangeisMaxAge();
            this.onSubmitChangeisMaxLoanAmtCap();
            this.onSubmitChangeisMinAge();
            this.onSubmitChangeisMinLoanAmt();
            this.onSubmitChangeisNumYrsCurCurEmplyIntRateMultBET1n3yrs();
            this.onSubmitChangeisNumYrsCurEmplyIntRateMultBET3n5yrs();
            this.onSubmitChangeisNumYrsCurEmplyIntRateMultBET5n10yrs();
            this.onSubmitChangeisNumYrsCurEmplyIntRateMultGT10yrs();
            this.onSubmitChangeisNumYrsCurEmplyIntRateMultLess1yr();
            this.onSubmitChangeisNumYrsEmplyIntMultRateMultBET1n3yrs();
            this.onSubmitChangeisNumYrsEmplyIntMultRateMultBET3n5yrs();
            this.onSubmitChangeisNumYrsEmplyIntMultRateMultBET5n10yrs();
          }
        })
        .catch((error) => {
          // Handle network or other errors
          console.error("API error:", error);
          alert("Failed to submit data. Please try again later.");
        });
    }
  }

  /**
    * @typedef {underWritingRules} underWritingRules
    * @property {string} minAge indicates minAge of loan product type
    * @property {string} maxAge indicates maxAge of loan product type
    * @property {string} debtBurdenAllowPercent indicates debtBurdenAllowPercent of loan product type
    * @property {string} minLoanAmt indicates minLoanAmt of loan product type
    * @property {string} maxLoanAmtCap indicates maxLoanAmtCap of loan product type
    * @property {string} instantloanDisCap indicates instantloanDisCap of loan product type
    * @property {string} lastSalary indicates lastSalary of loan product type
    * @property {string} categoryA indicates categoryA of loan product type
    * @property {string} categoryB indicates categoryB of loan product type
    * @property {string} categoryC indicates categoryC of loan product type
    * @property {string} categoryD indicates categoryD of loan product type
    * @property {string} categoryE indicates categoryE of loan product type
    * @property {string} catMultiStatus indicates catMultiStatus of loan product type
    * @property {string} avgSalaryMultLT200000 indicates avgSalaryMultLT200000 of loan product type
    * @property {string} avgSalaryMultBET500001n1000000 indicates avgSalaryMultBET500001n1000000 of loan product type
    * @property {string} avgSalaryMultBET1000001n2000000 indicates avgSalaryMultBET1000001n2000000 of loan product type
    * @property {string} avgSalaryMultGT2000000 indicates avgSalaryMultGT2000000 of loan product type
    * @property {string} avgSalTenorMultiplierStatus indicates avgSalTenorMultiplierStatus of loan product type
    * @property {string} avgSalTenorBankStaffvalue indicates avgSalTenorBankStaffvalue of loan product type
    * @property {string} avgSalIntRateMultStatus indicates avgSalIntRateMultStatus of loan product type
    * @property {string} avgSalIntRateMultBET200000n500000 indicates avgSalIntRateMultBET200000n500000 of loan product type
    * @property {string} avgSalIntRateMultBET500001n1000000 indicates avgSalIntRateMultBET500001n1000000 of loan product type
    * @property {string} avgSalIntRateMultBET1000001n2000000 indicates avgSalIntRateMultBET1000001n2000000 of loan product type
    * @property {string} avgSalIntRateMultGT2000000 indicates avgSalIntRateMultGT2000000 of loan product type
    * @property {string} numYrsEmplyIntMultRateMultLess1yr indicates numYrsEmplyIntMultRateMultLess1yr of loan product type
    * @property {string} numYrsEmplyIntMultRateMultBET1n3yrs indicates numYrsEmplyIntMultRateMultBET1n3yrs of loan product type
    * @property {string} numYrsEmplyIntMultRateMultBET3n5yrs indicates numYrsEmplyIntMultRateMultBET3n5yrs of loan product type
    * @property {string} numYrsEmplyIntRateMultGT10yrs indicates numYrsEmplyIntRateMultGT10yrs of loan product type
    * @property {string} numYrsCurEmplyIntRateMultLess1yr indicates numYrsCurEmplyIntRateMultLess1yr of loan product type
    * @property {string} numYrsCurCurEmplyIntRateMultBET1n3yrs indicates numYrsCurCurEmplyIntRateMultBET1n3yrs of loan product type
    * @property {string} numYrsCurEmplyIntRateMultBET3n5yrs indicates numYrsCurEmplyIntRateMultBET3n5yrs of loan product type
    * @property {string} numYrsCurEmplyIntRateMultBET5n10yrs indicates numYrsCurEmplyIntRateMultBET5n10yrs of loan product type
    * @property {string} numYrsCurEmplyIntRateMultGT10yrs indicates numYrsCurEmplyIntRateMultGT10yrs of loan product type
    * @property {string} deliquencyIntRateMultCurrent indicates deliquencyIntRateMultCurrent of loan product type
    * @property {string} deliquencyIntRateMultXDays indicates deliquencyIntRateMultXDays of loan product type
    * @property {string} deliquencyIntRateMult60DPD indicates deliquencyIntRateMult60DPD of loan product type
    * @property {string} deliquencyIntRateMultGT90DPD indicates deliquencyIntRateMultGT90DPD of loan product type
    * @property {string} bureauScoreIntRateMultltStatus indicates bureauScoreIntRateMultltStatus of loan product type
    * @property {string} bureauScoreIntRateMultGT900 indicates bureauScoreIntRateMultGT900 of loan product type
    * @property {string} bureauScoreIntRateMultBET800n900 indicates bureauScoreIntRateMultBET800n900 of loan product type
    * @property {string} bureauScoreIntRateMultBET700n800 indicates bureauScoreIntRateMultBET700n800 of loan product type
    * @property {string} bureauScoreIntRateMultBET600N700 indicates bureauScoreIntRateMultBET600N700 of loan product type
    * @property {string} bureauScoreIntRateMultlt600 indicates bureauScoreIntRateMultlt600 of loan product type

    */
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsMinAge(e, i) {
    let data = this.state.data;
    let isMinAgeclasslist = this.state.isMinAgeclasslist;
    this.state.data[i].isMinAge = !this.state.data[i].isMinAge;
    if (true == this.state.data[i].isMinAge) {
      this.state.isMinAgeclasslist[i] = "#2ab934";
    } else {
      this.state.isMinAgeclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isMinAgeclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsMaxAge(e, i) {
    let data = this.state.data;
    let isMaxAgeclasslist = this.state.isMaxAgeclasslist;
    this.state.data[i].isMaxAge = !this.state.data[i].isMaxAge;
    if (true == this.state.data[i].isMaxAge) {
      this.state.isMaxAgeclasslist[i] = "#2ab934";
    } else {
      this.state.isMaxAgeclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isMaxAgeclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsDebtBurdenAllowPercent(e, i) {
    let data = this.state.data;
    let isDebtBurdenAllowPercentclasslist =
      this.state.isDebtBurdenAllowPercentclasslist;
    this.state.data[i].isDebtBurdenAllowPercent =
      !this.state.data[i].isDebtBurdenAllowPercent;
    if (true == this.state.data[i].isDebtBurdenAllowPercent) {
      this.state.isDebtBurdenAllowPercentclasslist[i] = "#2ab934";
    } else {
      this.state.isDebtBurdenAllowPercentclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isDebtBurdenAllowPercentclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsMinLoanAmt(e, i) {
    let data = this.state.data;
    let isMinLoanAmtclasslist = this.state.isMinLoanAmtclasslist;
    this.state.data[i].isMinLoanAmt = !this.state.data[i].isMinLoanAmt;
    if (true == this.state.data[i].isMinLoanAmt) {
      this.state.isMinLoanAmtclasslist[i] = "#2ab934";
    } else {
      this.state.isMinLoanAmtclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isMinLoanAmtclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsMaxLoanAmtCap(e, i) {
    let data = this.state.data;
    let isMaxLoanAmtCapclasslist = this.state.isMaxLoanAmtCapclasslist;
    this.state.data[i].isMaxLoanAmtCap = !this.state.data[i].isMaxLoanAmtCap;
    if (true == this.state.data[i].isMaxLoanAmtCap) {
      this.state.isMaxLoanAmtCapclasslist[i] = "#2ab934";
    } else {
      this.state.isMaxLoanAmtCapclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isMaxLoanAmtCapclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsInstantloanDisCap(e, i) {
    let data = this.state.data;
    let isInstantloanDisCapclasslist = this.state.isInstantloanDisCapclasslist;
    this.state.data[i].isInstantloanDisCap =
      !this.state.data[i].isInstantloanDisCap;
    if (true == this.state.data[i].isInstantloanDisCap) {
      this.state.isInstantloanDisCapclasslist[i] = "#2ab934";
    } else {
      this.state.isInstantloanDisCapclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isInstantloanDisCapclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsLastSalary(e, i) {
    let data = this.state.data;
    let isLastSalaryclasslist = this.state.isLastSalaryclasslist;
    this.state.data[i].isLastSalary = !this.state.data[i].isLastSalary;
    if (true == this.state.data[i].isLastSalary) {
      this.state.isLastSalaryclasslist[i] = "#2ab934";
    } else {
      this.state.isLastSalaryclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isLastSalaryclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsCategoryE(e, i) {
    let data = this.state.data;
    let isCategoryEclasslist = this.state.isCategoryEclasslist;
    this.state.data[i].isCategoryE = !this.state.data[i].isCategoryE;
    if (true == this.state.data[i].isCategoryE) {
      this.state.isCategoryEclasslist[i] = "#2ab934";
    } else {
      this.state.isCategoryEclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isCategoryEclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsCategoryA(e, i) {
    let data = this.state.data;
    let isCategoryAclasslist = this.state.isCategoryAclasslist;
    this.state.data[i].isCategoryA = !this.state.data[i].isCategoryA;
    if (true == this.state.data[i].isCategoryA) {
      this.state.isCategoryAclasslist[i] = "#2ab934";
    } else {
      this.state.isCategoryAclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isCategoryAclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsCategoryB(e, i) {
    let data = this.state.data;
    let isCategoryBclasslist = this.state.isCategoryBclasslist;
    this.state.data[i].isCategoryB = !this.state.data[i].isCategoryB;
    if (true == this.state.data[i].isCategoryB) {
      this.state.isCategoryBclasslist[i] = "#2ab934";
    } else {
      this.state.isCategoryBclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isCategoryBclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsCategoryC(e, i) {
    let data = this.state.data;
    let isCategoryCclasslist = this.state.isCategoryCclasslist;
    this.state.data[i].isCategoryC = !this.state.data[i].isCategoryC;
    if (true == this.state.data[i].isCategoryC) {
      this.state.isCategoryCclasslist[i] = "#2ab934";
    } else {
      this.state.isCategoryCclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isCategoryCclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsCategoryD(e, i) {
    let data = this.state.data;
    let isCategoryDclasslist = this.state.isCategoryDclasslist;
    this.state.data[i].isCategoryD = !this.state.data[i].isCategoryD;
    if (true == this.state.data[i].isCategoryD) {
      this.state.isCategoryDclasslist[i] = "#2ab934";
    } else {
      this.state.isCategoryDclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isCategoryDclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalIntRateMultLT200000(e, i) {
    let data = this.state.data;
    let isAvgSalIntRateMultLT200000classlist =
      this.state.isAvgSalIntRateMultLT200000classlist;
    this.state.data[i].isAvgSalIntRateMultLT200000 =
      !this.state.data[i].isAvgSalIntRateMultLT200000;
    if (true == this.state.data[i].isAvgSalIntRateMultLT200000) {
      this.state.isAvgSalIntRateMultLT200000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalIntRateMultLT200000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalIntRateMultLT200000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalaryMultBET200000n500000(e, i) {
    let data = this.state.data;
    let isAvgSalaryMultBET200000n500000classlist =
      this.state.isAvgSalaryMultBET200000n500000classlist;
    this.state.data[i].isAvgSalaryMultBET200000n500000 =
      !this.state.data[i].isAvgSalaryMultBET200000n500000;
    if (true == this.state.data[i].isAvgSalaryMultBET200000n500000) {
      this.state.isAvgSalaryMultBET200000n500000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalaryMultBET200000n500000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalaryMultBET200000n500000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalaryMultBET500001n1000000(e, i) {
    let data = this.state.data;
    let isAvgSalaryMultBET500001n1000000classlist =
      this.state.isAvgSalaryMultBET500001n1000000classlist;
    this.state.data[i].isAvgSalaryMultBET500001n1000000 =
      !this.state.data[i].isAvgSalaryMultBET500001n1000000;
    if (true == this.state.data[i].isAvgSalaryMultBET500001n1000000) {
      this.state.isAvgSalaryMultBET500001n1000000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalaryMultBET500001n1000000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalaryMultBET500001n1000000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalaryMultBET1000001n2000000(e, i) {
    let data = this.state.data;
    let isAvgSalaryMultBET1000001n2000000classlist =
      this.state.isAvgSalaryMultBET1000001n2000000classlist;
    this.state.data[i].isAvgSalaryMultBET1000001n2000000 =
      !this.state.data[i].isAvgSalaryMultBET1000001n2000000;
    if (true == this.state.data[i].isAvgSalaryMultBET1000001n2000000) {
      this.state.isAvgSalaryMultBET1000001n2000000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalaryMultBET1000001n2000000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalaryMultBET1000001n2000000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalaryMultGT2000000(e, i) {
    let data = this.state.data;
    let isAvgSalaryMultGT2000000classlist =
      this.state.isAvgSalaryMultGT2000000classlist;
    this.state.data[i].isAvgSalaryMultGT2000000 =
      !this.state.data[i].isAvgSalaryMultGT2000000;
    if (true == this.state.data[i].isAvgSalaryMultGT2000000) {
      this.state.isAvgSalaryMultGT2000000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalaryMultGT2000000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalaryMultGT2000000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalTenorBankStaffvalue(e, i) {
    let data = this.state.data;
    let isAvgSalTenorBankStaffvalueclasslist =
      this.state.isAvgSalTenorBankStaffvalueclasslist;
    this.state.data[i].isAvgSalTenorBankStaffvalue =
      !this.state.data[i].isAvgSalTenorBankStaffvalue;
    if (true == this.state.data[i].isAvgSalTenorBankStaffvalue) {
      this.state.isAvgSalTenorBankStaffvalueclasslist[i] = "#2ab934";
    } else {
      this.state.isAvgSalTenorBankStaffvalueclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalTenorBankStaffvalueclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalIntRateMultLT200000(e, i) {
    let data = this.state.data;
    let isAvgSalIntRateMultLT200000classlist =
      this.state.isAvgSalIntRateMultLT200000classlist;
    this.state.data[i].isAvgSalIntRateMultLT200000 =
      !this.state.data[i].isAvgSalIntRateMultLT200000;
    if (true == this.state.data[i].isAvgSalIntRateMultLT200000) {
      this.state.isAvgSalIntRateMultLT200000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalIntRateMultLT200000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalIntRateMultLT200000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalIntRateMultBET200000n500000(e, i) {
    let data = this.state.data;
    let isAvgSalIntRateMultBET200000n500000classlist =
      this.state.isAvgSalIntRateMultBET200000n500000classlist;
    this.state.data[i].isAvgSalIntRateMultBET200000n500000 =
      !this.state.data[i].isAvgSalIntRateMultBET200000n500000;
    if (true == this.state.data[i].isAvgSalIntRateMultBET200000n500000) {
      this.state.isAvgSalIntRateMultBET200000n500000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalIntRateMultBET200000n500000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalIntRateMultBET200000n500000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalIntRateMultBET500001n1000000(e, i) {
    let data = this.state.data;
    let isAvgSalIntRateMultBET500001n1000000classlist =
      this.state.isAvgSalIntRateMultBET500001n1000000classlist;
    this.state.data[i].isAvgSalIntRateMultBET500001n1000000 =
      !this.state.data[i].isAvgSalIntRateMultBET500001n1000000;
    if (true == this.state.data[i].isAvgSalIntRateMultBET500001n1000000) {
      this.state.isAvgSalIntRateMultBET500001n1000000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalIntRateMultBET500001n1000000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalIntRateMultBET500001n1000000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalIntRateMultBET1000001n2000000(e, i) {
    let data = this.state.data;
    let isAvgSalIntRateMultBET1000001n2000000classlist =
      this.state.isAvgSalIntRateMultBET1000001n2000000classlist;
    this.state.data[i].isAvgSalIntRateMultBET1000001n2000000 =
      !this.state.data[i].isAvgSalIntRateMultBET1000001n2000000;
    if (true == this.state.data[i].isAvgSalIntRateMultBET1000001n2000000) {
      this.state.isAvgSalIntRateMultBET1000001n2000000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalIntRateMultBET1000001n2000000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalIntRateMultBET1000001n2000000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalIntRateMultGT2000000(e, i) {
    let data = this.state.data;
    let isAvgSalIntRateMultGT2000000classlist =
      this.state.isAvgSalIntRateMultGT2000000classlist;
    this.state.data[i].isAvgSalIntRateMultGT2000000 =
      !this.state.data[i].isAvgSalIntRateMultGT2000000;
    if (true == this.state.data[i].isAvgSalIntRateMultGT2000000) {
      this.state.isAvgSalIntRateMultGT2000000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalIntRateMultGT2000000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalIntRateMultGT2000000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsAvgSalIntRateMultBankstaff(e, i) {
    let data = this.state.data;
    let isAvgSalIntRateMultBankstaffclasslist =
      this.state.isAvgSalIntRateMultBankstaffclasslist;
    this.state.data[i].isAvgSalIntRateMultBankstaff =
      !this.state.data[i].isAvgSalIntRateMultBankstaff;
    if (true == this.state.data[i].isAvgSalIntRateMultBankstaff) {
      this.state.isAvgSalIntRateMultBankstaffclasslist[i] = "#2ab934";
    } else {
      this.state.isAvgSalIntRateMultBankstaffclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalIntRateMultBankstaffclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsNumYrsEmplyIntMultRateMultLess1yr(e, i) {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultLess1yrclasslist =
      this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist;
    this.state.data[i].isNumYrsEmplyIntMultRateMultLess1yr =
      !this.state.data[i].isNumYrsEmplyIntMultRateMultLess1yr;
    if (true == this.state.data[i].isNumYrsEmplyIntMultRateMultLess1yr) {
      this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist[i] = "#2ab934";
    } else {
      this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isNumYrsEmplyIntMultRateMultLess1yrclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsNumYrsEmplyIntMultRateMultBET1n3yrs(e, i) {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist =
      this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist;
    this.state.data[i].isNumYrsEmplyIntMultRateMultBET1n3yrs =
      !this.state.data[i].isNumYrsEmplyIntMultRateMultBET1n3yrs;
    if (true == this.state.data[i].isNumYrsEmplyIntMultRateMultBET1n3yrs) {
      this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist[i] = "#2ab934";
    } else {
      this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsNumYrsEmplyIntMultRateMultBET3n5yrs(e, i) {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist =
      this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist;
    this.state.data[i].isNumYrsEmplyIntMultRateMultBET3n5yrs =
      !this.state.data[i].isNumYrsEmplyIntMultRateMultBET3n5yrs;
    if (true == this.state.data[i].isNumYrsEmplyIntMultRateMultBET3n5yrs) {
      this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist[i] = "#2ab934";
    } else {
      this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsNumYrsEmplyIntMultRateMultBET5n10yrs(e, i) {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist =
      this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist;
    this.state.data[i].isNumYrsEmplyIntMultRateMultBET5n10yrs =
      !this.state.data[i].isNumYrsEmplyIntMultRateMultBET5n10yrs;
    if (true == this.state.data[i].isNumYrsEmplyIntMultRateMultBET5n10yrs) {
      this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist[i] = "#2ab934";
    } else {
      this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsNumYrsEmplyIntRateMultGT10yrs(e, i) {
    let data = this.state.data;
    let isNumYrsEmplyIntRateMultGT10yrsclasslist =
      this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist;
    this.state.data[i].isNumYrsEmplyIntRateMultGT10yrs =
      !this.state.data[i].isNumYrsEmplyIntRateMultGT10yrs;
    if (true == this.state.data[i].isNumYrsEmplyIntRateMultGT10yrs) {
      this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist[i] = "#2ab934";
    } else {
      this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isNumYrsEmplyIntRateMultGT10yrsclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */

  toggleChangeIsAvgSalaryMultLT200000(e, i) {
    let data = this.state.data;
    let isAvgSalaryMultLT200000classlist =
      this.state.isAvgSalaryMultLT200000classlist;
    this.state.data[i].isAvgSalaryMultLT200000 =
      !this.state.data[i].isAvgSalaryMultLT200000;
    if (true == this.state.data[i].isAvgSalaryMultLT200000) {
      this.state.isAvgSalaryMultLT200000classlist[i] = "#2ab934";
    } else {
      this.state.isAvgSalaryMultLT200000classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAvgSalaryMultLT200000classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */

  toggleChangeIsDeliquencyIntRateMultCurrent(e, i) {
    let data = this.state.data;
    let isDeliquencyIntRateMultCurrentclasslist =
      this.state.isDeliquencyIntRateMultCurrentclasslist;
    this.state.data[i].isDeliquencyIntRateMultCurrent =
      !this.state.data[i].isDeliquencyIntRateMultCurrent;
    if (true == this.state.data[i].isDeliquencyIntRateMultCurrent) {
      this.state.isDeliquencyIntRateMultCurrentclasslist[i] = "#2ab934";
    } else {
      this.state.isDeliquencyIntRateMultCurrentclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isDeliquencyIntRateMultCurrentclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsDeliquencyIntRateMultXDays(e, i) {
    let data = this.state.data;
    let isDeliquencyIntRateMultXDaysclasslist =
      this.state.isDeliquencyIntRateMultXDaysclasslist;
    this.state.data[i].isDeliquencyIntRateMultXDays =
      !this.state.data[i].isDeliquencyIntRateMultXDays;
    if (true == this.state.data[i].isDeliquencyIntRateMultXDays) {
      this.state.isDeliquencyIntRateMultXDaysclasslist[i] = "#2ab934";
    } else {
      this.state.isDeliquencyIntRateMultXDaysclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isDeliquencyIntRateMultXDaysclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsDeliquencyIntRateMult30DPD(e, i) {
    let data = this.state.data;
    let isDeliquencyIntRateMult30DPDclasslist =
      this.state.isDeliquencyIntRateMult30DPDclasslist;
    this.state.data[i].isDeliquencyIntRateMult30DPD =
      !this.state.data[i].isDeliquencyIntRateMult30DPD;
    if (true == this.state.data[i].isDeliquencyIntRateMult30DPD) {
      this.state.isDeliquencyIntRateMult30DPDclasslist[i] = "#2ab934";
    } else {
      this.state.isDeliquencyIntRateMult30DPDclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isDeliquencyIntRateMult30DPDclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsDeliquencyIntRateMult60DPD(e, i) {
    let data = this.state.data;
    let isDeliquencyIntRateMult60DPDclasslist =
      this.state.isDeliquencyIntRateMult60DPDclasslist;
    this.state.data[i].isDeliquencyIntRateMult60DPD =
      !this.state.data[i].isDeliquencyIntRateMult60DPD;
    if (true == this.state.data[i].isDeliquencyIntRateMult60DPD) {
      this.state.isDeliquencyIntRateMult60DPDclasslist[i] = "#2ab934";
    } else {
      this.state.isDeliquencyIntRateMult60DPDclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isDeliquencyIntRateMult60DPDclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsDeliquencyIntRateMultGT90DPD(e, i) {
    let data = this.state.data;
    let isDeliquencyIntRateMultGT90DPDclasslist =
      this.state.isDeliquencyIntRateMultGT90DPDclasslist;
    this.state.data[i].isDeliquencyIntRateMultGT90DPD =
      !this.state.data[i].isDeliquencyIntRateMultGT90DPD;
    if (true == this.state.data[i].isDeliquencyIntRateMultGT90DPD) {
      this.state.isDeliquencyIntRateMultGT90DPDclasslist[i] = "#2ab934";
    } else {
      this.state.isDeliquencyIntRateMultGT90DPDclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isDeliquencyIntRateMultGT90DPDclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsNumYrsCurEmplyIntRateMultLess1yr(e, i) {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultLess1yrclasslist =
      this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist;
    this.state.data[i].isNumYrsCurEmplyIntRateMultLess1yr =
      !this.state.data[i].isNumYrsCurEmplyIntRateMultLess1yr;
    if (true == this.state.data[i].isNumYrsCurEmplyIntRateMultLess1yr) {
      this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist[i] = "#2ab934";
    } else {
      this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isNumYrsCurEmplyIntRateMultLess1yrclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsNumYrsCurCurEmplyIntRateMultBET1n3yrs(e, i) {
    let data = this.state.data;
    let isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist =
      this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist;
    this.state.data[i].isNumYrsCurCurEmplyIntRateMultBET1n3yrs =
      !this.state.data[i].isNumYrsCurCurEmplyIntRateMultBET1n3yrs;
    if (true == this.state.data[i].isNumYrsCurCurEmplyIntRateMultBET1n3yrs) {
      this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist[i] =
        "#2ab934";
    } else {
      this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist[i] =
        "#6c757d";
    }
    this.setState({
      data,
      isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsNumYrsCurEmplyIntRateMultBET3n5yrs(e, i) {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist =
      this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist;
    this.state.data[i].isNumYrsCurEmplyIntRateMultBET3n5yrs =
      !this.state.data[i].isNumYrsCurEmplyIntRateMultBET3n5yrs;
    if (true == this.state.data[i].isNumYrsCurEmplyIntRateMultBET3n5yrs) {
      this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist[i] = "#2ab934";
    } else {
      this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsNumYrsCurEmplyIntRateMultGT10yrs(e, i) {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultGT10yrsclasslist =
      this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist;
    this.state.data[i].isNumYrsCurEmplyIntRateMultGT10yrs =
      !this.state.data[i].isNumYrsCurEmplyIntRateMultGT10yrs;
    if (true == this.state.data[i].isNumYrsCurEmplyIntRateMultGT10yrs) {
      this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist[i] = "#2ab934";
    } else {
      this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isNumYrsCurEmplyIntRateMultGT10yrsclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */

  toggleChangeIsBureauScoreIntRateMultGT900(e, i) {
    let data = this.state.data;
    let isBureauScoreIntRateMultGT900classlist =
      this.state.isBureauScoreIntRateMultGT900classlist;
    this.state.data[i].isBureauScoreIntRateMultGT900 =
      !this.state.data[i].isBureauScoreIntRateMultGT900;
    if (true == this.state.data[i].isBureauScoreIntRateMultGT900) {
      this.state.isBureauScoreIntRateMultGT900classlist[i] = "#2ab934";
    } else {
      this.state.isBureauScoreIntRateMultGT900classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isBureauScoreIntRateMultGT900classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsBureauScoreIntRateMultBET800n900(e, i) {
    let data = this.state.data;
    let isBureauScoreIntRateMultBET800n900classlist =
      this.state.isBureauScoreIntRateMultBET800n900classlist;
    this.state.data[i].isBureauScoreIntRateMultBET800n900 =
      !this.state.data[i].isBureauScoreIntRateMultBET800n900;
    if (true == this.state.data[i].isBureauScoreIntRateMultBET800n900) {
      this.state.isBureauScoreIntRateMultBET800n900classlist[i] = "#2ab934";
    } else {
      this.state.isBureauScoreIntRateMultBET800n900classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isBureauScoreIntRateMultBET800n900classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsBureauScoreIntRateMultBET700n800(e, i) {
    let data = this.state.data;
    let isBureauScoreIntRateMultBET700n800classlist =
      this.state.isBureauScoreIntRateMultBET700n800classlist;
    this.state.data[i].isBureauScoreIntRateMultBET700n800 =
      !this.state.data[i].isBureauScoreIntRateMultBET700n800;
    if (true == this.state.data[i].isBureauScoreIntRateMultBET700n800) {
      this.state.isBureauScoreIntRateMultBET700n800classlist[i] = "#2ab934";
    } else {
      this.state.isBureauScoreIntRateMultBET700n800classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isBureauScoreIntRateMultBET700n800classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsBureauScoreIntRateMultBET600N700(e, i) {
    let data = this.state.data;
    let isBureauScoreIntRateMultBET600N700classlist =
      this.state.isBureauScoreIntRateMultBET600N700classlist;
    this.state.data[i].isBureauScoreIntRateMultBET600N700 =
      !this.state.data[i].isBureauScoreIntRateMultBET600N700;
    if (true == this.state.data[i].isBureauScoreIntRateMultBET600N700) {
      this.state.isBureauScoreIntRateMultBET600N700classlist[i] = "#2ab934";
    } else {
      this.state.isBureauScoreIntRateMultBET600N700classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isBureauScoreIntRateMultBET600N700classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {underWritingRules} underWritingRules data and index of data.
   */
  toggleChangeIsBureauScoreIntRateMultlt600(e, i) {
    let data = this.state.data;
    let isBureauScoreIntRateMultlt600classlist =
      this.state.isBureauScoreIntRateMultlt600classlist;
    this.state.data[i].isBureauScoreIntRateMultlt600 =
      !this.state.data[i].isBureauScoreIntRateMultlt600;
    if (true == this.state.data[i].isBureauScoreIntRateMultlt600) {
      this.state.isBureauScoreIntRateMultlt600classlist[i] = "#2ab934";
    } else {
      this.state.isBureauScoreIntRateMultlt600classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isBureauScoreIntRateMultlt600classlist,
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalIntRateMultBET200000n500000() {
    let data = this.state.data;
    let isAvgSalIntRateMultBET200000n500000list =
      this.state.isAvgSalIntRateMultBET200000n500000list;
    let isAvgSalIntRateMultBET200000n500000classlist =
      this.state.isAvgSalIntRateMultBET200000n500000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultBET200000n500000) {
        underWritingRules.isAvgSalIntRateMultBET200000n500000 = true;
        this.state.isAvgSalIntRateMultBET200000n500000list[i] = true;
        this.state.isAvgSalIntRateMultBET200000n500000classlist[i] = "#bcbcbb";
        this.state.isAvgSalIntRateMultBET200000n500000fieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isAvgSalIntRateMultBET200000n500000list,
          isAvgSalIntRateMultBET200000n500000classlist,
        });
      } else {
        underWritingRules.isAvgSalIntRateMultBET200000n500000 = true;
        this.state.isAvgSalIntRateMultBET200000n500000list[i] = false;
        this.state.isAvgSalIntRateMultBET200000n500000classlist[i] = "#2ab934";
        this.state.isAvgSalIntRateMultBET200000n500000fieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isAvgSalIntRateMultBET200000n500000list,
        });
      }
    });
  }

  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalIntRateMultBET500001n1000000() {
    let data = this.state.data;
    let isAvgSalIntRateMultBET500001n1000000list =
      this.state.isAvgSalIntRateMultBET500001n1000000list;
    let isAvgSalIntRateMultBET500001n1000000classlist =
      this.state.isAvgSalIntRateMultBET500001n1000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultBET500001n1000000) {
        underWritingRules.isAvgSalIntRateMultBET500001n1000000 = true;
        this.state.isAvgSalIntRateMultBET500001n1000000list[i] = true;
        this.state.isAvgSalIntRateMultBET500001n1000000classlist[i] = "#bcbcbb";
        this.state.isAvgSalIntRateMultBET500001n1000000fieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isAvgSalIntRateMultBET500001n1000000list,
          isAvgSalIntRateMultBET500001n1000000classlist,
        });
      } else {
        underWritingRules.isAvgSalIntRateMultBET500001n1000000 = true;
        this.state.isAvgSalIntRateMultBET500001n1000000list[i] = false;
        this.state.isAvgSalIntRateMultBET500001n1000000classlist[i] = "#2ab934";
        this.state.isAvgSalIntRateMultBET500001n1000000fieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isAvgSalIntRateMultBET500001n1000000list,
        });
      }
    });
  }

  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalIntRateMultBET1000001n2000000() {
    let data = this.state.data;
    let isAvgSalIntRateMultBET1000001n2000000list =
      this.state.isAvgSalIntRateMultBET1000001n2000000list;
    let isAvgSalIntRateMultBET1000001n2000000classlist =
      this.state.isAvgSalIntRateMultBET1000001n2000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultBET1000001n2000000) {
        underWritingRules.isAvgSalIntRateMultBET1000001n2000000 = true;
        this.state.isAvgSalIntRateMultBET1000001n2000000list[i] = true;
        this.state.isAvgSalIntRateMultBET1000001n2000000classlist[i] =
          "#bcbcbb";
        this.state.isAvgSalIntRateMultBET1000001n2000000fieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isAvgSalIntRateMultBET1000001n2000000list,
          isAvgSalIntRateMultBET1000001n2000000classlist,
        });
      } else {
        underWritingRules.isAvgSalIntRateMultBET1000001n2000000 = true;
        this.state.isAvgSalIntRateMultBET1000001n2000000list[i] = false;
        this.state.isAvgSalIntRateMultBET1000001n2000000classlist[i] =
          "#2ab934";
        this.state.isAvgSalIntRateMultBET1000001n2000000fieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isAvgSalIntRateMultBET1000001n2000000list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */

  onAndOffChangeisAvgSalIntRateMultBankstaff() {
    let data = this.state.data;
    let isAvgSalIntRateMultBankstafflist =
      this.state.isAvgSalIntRateMultBankstafflist;
    let isAvgSalIntRateMultBankstaffclasslist =
      this.state.isAvgSalIntRateMultBankstaffclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultBankstaff) {
        underWritingRules.isAvgSalIntRateMultBankstaff = true;
        this.state.isAvgSalIntRateMultBankstafflist[i] = true;
        this.state.isAvgSalIntRateMultBankstaffclasslist[i] = "#bcbcbb";
        this.state.isAvgSalIntRateMultBankstafffieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isAvgSalIntRateMultBankstafflist,
          isAvgSalIntRateMultBankstaffclasslist,
        });
      } else {
        underWritingRules.isAvgSalIntRateMultBankstaff = true;
        this.state.isAvgSalIntRateMultBankstafflist[i] = false;
        this.state.isAvgSalIntRateMultBankstaffclasslist[i] = "#2ab934";
        this.state.isAvgSalIntRateMultBankstafffieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isAvgSalIntRateMultBankstafflist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */

  onAndOffChangeisAvgSalIntRateMultGT2000000() {
    let data = this.state.data;
    let isAvgSalIntRateMultGT2000000list =
      this.state.isAvgSalIntRateMultGT2000000list;
    let isAvgSalIntRateMultGT2000000classlist =
      this.state.isAvgSalIntRateMultGT2000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultGT2000000) {
        underWritingRules.isAvgSalIntRateMultGT2000000 = true;
        this.state.isAvgSalIntRateMultGT2000000list[i] = true;
        this.state.isAvgSalIntRateMultGT2000000classlist[i] = "#bcbcbb";
        this.state.isAvgSalIntRateMultGT2000000fieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isAvgSalIntRateMultGT2000000list,
          isAvgSalIntRateMultGT2000000classlist,
        });
      } else {
        underWritingRules.isAvgSalIntRateMultGT2000000 = true;
        this.state.isAvgSalIntRateMultGT2000000list[i] = false;
        this.state.isAvgSalIntRateMultGT2000000classlist[i] = "#2ab934";
        this.state.isAvgSalIntRateMultGT2000000fieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isAvgSalIntRateMultGT2000000list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalIntRateMultLT200000() {
    let data = this.state.data;
    let isAvgSalIntRateMultLT200000list =
      this.state.isAvgSalIntRateMultLT200000list;
    let isAvgSalIntRateMultLT200000classlist =
      this.state.isAvgSalIntRateMultLT200000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultLT200000) {
        underWritingRules.isAvgSalIntRateMultLT200000 = true;
        this.state.isAvgSalIntRateMultLT200000list[i] = true;
        this.state.isAvgSalIntRateMultLT200000classlist[i] = "#bcbcbb";
        this.state.isAvgSalIntRateMultLT200000fieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isAvgSalIntRateMultLT200000list,
          isAvgSalIntRateMultLT200000classlist,
        });
      } else {
        underWritingRules.isAvgSalIntRateMultLT200000 = true;
        this.state.isAvgSalIntRateMultLT200000list[i] = false;
        this.state.isAvgSalIntRateMultLT200000classlist[i] = "#2ab934";
        this.state.isAvgSalIntRateMultLT200000fieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isAvgSalIntRateMultLT200000list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalTenorBankStaffvalue() {
    let data = this.state.data;
    let isAvgSalTenorBankStaffvaluelist =
      this.state.isAvgSalTenorBankStaffvaluelist;
    let isAvgSalTenorBankStaffvalueclasslist =
      this.state.isAvgSalTenorBankStaffvalueclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalTenorBankStaffvalue) {
        underWritingRules.isAvgSalTenorBankStaffvalue = true;
        this.state.isAvgSalTenorBankStaffvaluelist[i] = true;
        this.state.isAvgSalTenorBankStaffvalueclasslist[i] = "#bcbcbb";
        this.state.isAvgSalTenorBankStaffvaluefieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isAvgSalTenorBankStaffvaluelist,
          isAvgSalTenorBankStaffvalueclasslist,
        });
      } else {
        underWritingRules.isAvgSalTenorBankStaffvalue = true;
        this.state.isAvgSalTenorBankStaffvaluelist[i] = false;
        this.state.isAvgSalTenorBankStaffvalueclasslist[i] = "#2ab934";
        this.state.isAvgSalTenorBankStaffvaluefieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isAvgSalTenorBankStaffvaluelist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalaryMultBET200000n500000() {
    let data = this.state.data;
    let isAvgSalaryMultBET200000n500000list =
      this.state.isAvgSalaryMultBET200000n500000list;
    let isAvgSalaryMultBET200000n500000classlist =
      this.state.isAvgSalaryMultBET200000n500000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultBET200000n500000) {
        underWritingRules.isAvgSalaryMultBET200000n500000 = true;
        this.state.isAvgSalaryMultBET200000n500000list[i] = true;
        this.state.isAvgSalaryMultBET200000n500000classlist[i] = "#bcbcbb";
        this.state.isAvgSalaryMultBET200000n500000fieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isAvgSalaryMultBET200000n500000list,
          isAvgSalaryMultBET200000n500000classlist,
        });
      } else {
        underWritingRules.isAvgSalaryMultBET200000n500000 = true;
        this.state.isAvgSalaryMultBET200000n500000list[i] = false;
        this.state.isAvgSalaryMultBET200000n500000classlist[i] = "#2ab934";
        this.state.isAvgSalaryMultBET200000n500000fieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isAvgSalaryMultBET200000n500000list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalaryMultBET500001n1000000() {
    let data = this.state.data;
    let isAvgSalaryMultBET500001n1000000list =
      this.state.isAvgSalaryMultBET500001n1000000list;
    let isAvgSalaryMultBET500001n1000000classlist =
      this.state.isAvgSalaryMultBET500001n1000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultBET500001n1000000) {
        underWritingRules.isAvgSalaryMultBET500001n1000000 = true;
        this.state.isAvgSalaryMultBET500001n1000000list[i] = true;
        this.state.isAvgSalaryMultBET500001n1000000classlist[i] = "#bcbcbb";
        this.state.isAvgSalaryMultBET500001n1000000fieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isAvgSalaryMultBET500001n1000000list,
          isAvgSalaryMultBET500001n1000000classlist,
        });
      } else {
        underWritingRules.isAvgSalaryMultBET500001n1000000 = true;
        this.state.isAvgSalaryMultBET500001n1000000list[i] = false;
        this.state.isAvgSalaryMultBET500001n1000000classlist[i] = "#2ab934";
        this.state.isAvgSalaryMultBET500001n1000000fieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isAvgSalaryMultBET500001n1000000list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalaryMultBET1000001n2000000() {
    let data = this.state.data;
    let isAvgSalaryMultBET1000001n2000000list =
      this.state.isAvgSalaryMultBET1000001n2000000list;
    let isAvgSalaryMultBET1000001n2000000classlist =
      this.state.isAvgSalaryMultBET1000001n2000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultBET1000001n2000000) {
        underWritingRules.isAvgSalaryMultBET1000001n2000000 = true;
        this.state.isAvgSalaryMultBET1000001n2000000list[i] = true;
        this.state.isAvgSalaryMultBET1000001n2000000classlist[i] = "#bcbcbb";
        this.state.isAvgSalaryMultBET1000001n2000000fieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isAvgSalaryMultBET1000001n2000000list,
          isAvgSalaryMultBET1000001n2000000classlist,
        });
      } else {
        underWritingRules.isAvgSalaryMultBET1000001n2000000 = true;
        this.state.isAvgSalaryMultBET1000001n2000000list[i] = false;
        this.state.isAvgSalaryMultBET1000001n2000000classlist[i] = "#2ab934";
        this.state.isAvgSalaryMultBET1000001n2000000fieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isAvgSalaryMultBET1000001n2000000list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalaryMultGT2000000() {
    let data = this.state.data;
    let isAvgSalaryMultGT2000000list = this.state.isAvgSalaryMultGT2000000list;
    let isAvgSalaryMultGT2000000classlist =
      this.state.isAvgSalaryMultGT2000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultGT2000000) {
        underWritingRules.isAvgSalaryMultGT2000000 = true;
        this.state.isAvgSalaryMultGT2000000list[i] = true;
        this.state.isAvgSalaryMultGT2000000classlist[i] = "#bcbcbb";
        this.state.isAvgSalaryMultGT2000000fieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isAvgSalaryMultGT2000000list,
          isAvgSalaryMultGT2000000classlist,
        });
      } else {
        underWritingRules.isAvgSalaryMultGT2000000 = true;
        this.state.isAvgSalaryMultGT2000000list[i] = false;
        this.state.isAvgSalaryMultGT2000000classlist[i] = "#2ab934";
        this.state.isAvgSalaryMultGT2000000fieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isAvgSalaryMultGT2000000list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisAvgSalaryMultLT200000() {
    let data = this.state.data;
    let isAvgSalaryMultLT200000list = this.state.isAvgSalaryMultLT200000list;
    let isAvgSalaryMultLT200000classlist =
      this.state.isAvgSalaryMultLT200000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultLT200000) {
        underWritingRules.isAvgSalaryMultLT200000 = true;
        this.state.isAvgSalaryMultLT200000list[i] = true;
        this.state.isAvgSalaryMultLT200000classlist[i] = "#bcbcbb";
        this.state.isAvgSalaryMultLT200000fieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isAvgSalaryMultLT200000list,
          isAvgSalaryMultLT200000classlist,
        });
      } else {
        underWritingRules.isAvgSalaryMultLT200000 = true;
        this.state.isAvgSalaryMultLT200000list[i] = false;
        this.state.isAvgSalaryMultLT200000classlist[i] = "#2ab934";
        this.state.isAvgSalaryMultLT200000fieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isAvgSalaryMultLT200000list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisBureauScoreIntRateMultBET600N700() {
    let data = this.state.data;
    let isBureauScoreIntRateMultBET600N700list =
      this.state.isBureauScoreIntRateMultBET600N700list;
    let isBureauScoreIntRateMultBET600N700classlist =
      this.state.isBureauScoreIntRateMultBET600N700classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultBET600N700) {
        underWritingRules.isBureauScoreIntRateMultBET600N700 = true;
        this.state.isBureauScoreIntRateMultBET600N700list[i] = true;
        this.state.isBureauScoreIntRateMultBET600N700classlist[i] = "#bcbcbb";
        this.state.isBureauScoreIntRateMultBET600N700fieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isBureauScoreIntRateMultBET600N700list,
          isBureauScoreIntRateMultBET600N700classlist,
        });
      } else {
        underWritingRules.isBureauScoreIntRateMultBET600N700 = true;
        this.state.isBureauScoreIntRateMultBET600N700list[i] = false;
        this.state.isBureauScoreIntRateMultBET600N700classlist[i] = "#2ab934";
        this.state.isBureauScoreIntRateMultBET600N700fieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isBureauScoreIntRateMultBET600N700list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisBureauScoreIntRateMultBET700n800() {
    let data = this.state.data;
    let isBureauScoreIntRateMultBET700n800list =
      this.state.isBureauScoreIntRateMultBET700n800list;
    let isBureauScoreIntRateMultBET700n800classlist =
      this.state.isBureauScoreIntRateMultBET700n800classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultBET700n800) {
        underWritingRules.isBureauScoreIntRateMultBET700n800 = true;
        this.state.isBureauScoreIntRateMultBET700n800list[i] = true;
        this.state.isBureauScoreIntRateMultBET700n800classlist[i] = "#bcbcbb";
        this.state.isBureauScoreIntRateMultBET700n800fieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isBureauScoreIntRateMultBET700n800list,
          isBureauScoreIntRateMultBET700n800classlist,
        });
      } else {
        underWritingRules.isBureauScoreIntRateMultBET700n800 = true;
        this.state.isBureauScoreIntRateMultBET700n800list[i] = false;
        this.state.isBureauScoreIntRateMultBET700n800classlist[i] = "#2ab934";
        this.state.isBureauScoreIntRateMultBET700n800fieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isBureauScoreIntRateMultBET700n800list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisBureauScoreIntRateMultBET800n900() {
    let data = this.state.data;
    let isBureauScoreIntRateMultBET800n900list =
      this.state.isBureauScoreIntRateMultBET800n900list;
    let isBureauScoreIntRateMultBET800n900classlist =
      this.state.isBureauScoreIntRateMultBET800n900classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultBET800n900) {
        underWritingRules.isBureauScoreIntRateMultBET800n900 = true;
        this.state.isBureauScoreIntRateMultBET800n900list[i] = true;
        this.state.isBureauScoreIntRateMultBET800n900classlist[i] = "#bcbcbb";
        this.state.isBureauScoreIntRateMultBET800n900fieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isBureauScoreIntRateMultBET800n900list,
          isBureauScoreIntRateMultBET800n900classlist,
        });
      } else {
        underWritingRules.isBureauScoreIntRateMultBET800n900 = true;
        this.state.isBureauScoreIntRateMultBET800n900list[i] = false;
        this.state.isBureauScoreIntRateMultBET800n900classlist[i] = "#2ab934";
        this.state.isBureauScoreIntRateMultBET800n900fieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isBureauScoreIntRateMultBET800n900list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisBureauScoreIntRateMultGT900() {
    let data = this.state.data;
    let isBureauScoreIntRateMultGT900list =
      this.state.isBureauScoreIntRateMultGT900list;
    let isBureauScoreIntRateMultGT900classlist =
      this.state.isBureauScoreIntRateMultGT900classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultGT900) {
        underWritingRules.isBureauScoreIntRateMultGT900 = true;
        this.state.isBureauScoreIntRateMultGT900list[i] = true;
        this.state.isBureauScoreIntRateMultGT900classlist[i] = "#bcbcbb";
        this.state.isBureauScoreIntRateMultGT900fieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isBureauScoreIntRateMultGT900list,
          isBureauScoreIntRateMultGT900classlist,
        });
      } else {
        underWritingRules.isBureauScoreIntRateMultGT900 = true;
        this.state.isBureauScoreIntRateMultGT900list[i] = false;
        this.state.isBureauScoreIntRateMultGT900classlist[i] = "#2ab934";
        this.state.isBureauScoreIntRateMultGT900fieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isBureauScoreIntRateMultGT900list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisBureauScoreIntRateMultlt600() {
    let data = this.state.data;
    let isBureauScoreIntRateMultlt600list =
      this.state.isBureauScoreIntRateMultlt600list;
    let isBureauScoreIntRateMultlt600classlist =
      this.state.isBureauScoreIntRateMultlt600classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultlt600) {
        underWritingRules.isBureauScoreIntRateMultlt600 = true;
        this.state.isBureauScoreIntRateMultlt600list[i] = true;
        this.state.isBureauScoreIntRateMultlt600classlist[i] = "#bcbcbb";
        this.state.isBureauScoreIntRateMultlt600fieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isBureauScoreIntRateMultlt600list,
          isBureauScoreIntRateMultlt600classlist,
        });
      } else {
        underWritingRules.isBureauScoreIntRateMultlt600 = true;
        this.state.isBureauScoreIntRateMultlt600list[i] = false;
        this.state.isBureauScoreIntRateMultlt600classlist[i] = "#2ab934";
        this.state.isBureauScoreIntRateMultlt600fieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isBureauScoreIntRateMultlt600list,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisCategoryA() {
    let data = this.state.data;
    let isCategoryAlist = this.state.isCategoryAlist;
    let isCategoryAclasslist = this.state.isCategoryAclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryA) {
        underWritingRules.isCategoryA = true;
        this.state.isCategoryAlist[i] = true;
        this.state.isCategoryAclasslist[i] = "#bcbcbb";
        this.state.isCategoryAfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isCategoryAlist,
          isCategoryAclasslist,
        });
      } else {
        underWritingRules.isCategoryA = true;
        this.state.isCategoryAlist[i] = false;
        this.state.isCategoryAclasslist[i] = "#2ab934";
        this.state.isCategoryAfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isCategoryAlist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisCategoryB() {
    let data = this.state.data;
    let isCategoryBlist = this.state.isCategoryBlist;
    let isCategoryBclasslist = this.state.isCategoryBclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryB) {
        underWritingRules.isCategoryB = true;
        this.state.isCategoryBlist[i] = true;
        this.state.isCategoryBclasslist[i] = "#bcbcbb";
        this.state.isCategoryBfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isCategoryBlist,
          isCategoryBclasslist,
        });
      } else {
        underWritingRules.isCategoryB = true;
        this.state.isCategoryBlist[i] = false;
        this.state.isCategoryBclasslist[i] = "#2ab934";
        this.state.isCategoryBfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isCategoryBlist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */

  onAndOffChangeisCategoryC() {
    let data = this.state.data;
    let isCategoryClist = this.state.isCategoryClist;
    let isCategoryCclasslist = this.state.isCategoryCclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryC) {
        underWritingRules.isCategoryC = true;
        this.state.isCategoryClist[i] = true;
        this.state.isCategoryCclasslist[i] = "#bcbcbb";
        this.state.isCategoryCfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isCategoryClist,
          isCategoryCclasslist,
        });
      } else {
        underWritingRules.isCategoryC = true;
        this.state.isCategoryClist[i] = false;
        this.state.isCategoryCclasslist[i] = "#2ab934";
        this.state.isCategoryCfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isCategoryClist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisCategoryD() {
    let data = this.state.data;
    let isCategoryDlist = this.state.isCategoryDlist;
    let isCategoryDclasslist = this.state.isCategoryDclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryD) {
        underWritingRules.isCategoryD = true;
        this.state.isCategoryDlist[i] = true;
        this.state.isCategoryDclasslist[i] = "#bcbcbb";
        this.state.isCategoryDfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isCategoryDlist,
          isCategoryDclasslist,
        });
      } else {
        underWritingRules.isCategoryD = true;
        this.state.isCategoryDlist[i] = false;
        this.state.isCategoryDclasslist[i] = "#2ab934";
        this.state.isCategoryDfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isCategoryDlist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisCategoryE() {
    let data = this.state.data;
    let isCategoryElist = this.state.isCategoryElist;
    let isCategoryEclasslist = this.state.isCategoryEclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryE) {
        underWritingRules.isCategoryE = true;
        this.state.isCategoryElist[i] = true;
        this.state.isCategoryEclasslist[i] = "#bcbcbb";
        this.state.isCategoryEfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isCategoryElist,
          isCategoryEclasslist,
        });
      } else {
        underWritingRules.isCategoryE = true;
        this.state.isCategoryElist[i] = false;
        this.state.isCategoryEclasslist[i] = "#2ab934";
        this.state.isCategoryEfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isCategoryElist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisDebtBurdenAllowPercent() {
    let data = this.state.data;
    let isDebtBurdenAllowPercentlist = this.state.isDebtBurdenAllowPercentlist;
    let isDebtBurdenAllowPercentclasslist =
      this.state.isDebtBurdenAllowPercentclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDebtBurdenAllowPercent) {
        underWritingRules.isDebtBurdenAllowPercent = true;
        this.state.isDebtBurdenAllowPercentlist[i] = true;
        this.state.isDebtBurdenAllowPercentclasslist[i] = "#bcbcbb";
        this.state.isDebtBurdenAllowPercentfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isDebtBurdenAllowPercentlist,
          isDebtBurdenAllowPercentclasslist,
        });
      } else {
        underWritingRules.isDebtBurdenAllowPercent = true;
        this.state.isDebtBurdenAllowPercentlist[i] = false;
        this.state.isDebtBurdenAllowPercentclasslist[i] = "#2ab934";
        this.state.isDebtBurdenAllowPercentfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isDebtBurdenAllowPercentlist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisDeliquencyIntRateMult30DPD() {
    let data = this.state.data;
    let isDeliquencyIntRateMult30DPDlist =
      this.state.isDeliquencyIntRateMult30DPDlist;
    let isDeliquencyIntRateMult30DPDclasslist =
      this.state.isDeliquencyIntRateMult30DPDclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMult30DPD) {
        underWritingRules.isDeliquencyIntRateMult30DPD = true;
        this.state.isDeliquencyIntRateMult30DPDlist[i] = true;
        this.state.isDeliquencyIntRateMult30DPDclasslist[i] = "#bcbcbb";
        this.state.isDeliquencyIntRateMult30DPDfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isDeliquencyIntRateMult30DPDlist,
          isDeliquencyIntRateMult30DPDclasslist,
        });
      } else {
        underWritingRules.isDeliquencyIntRateMult30DPD = true;
        this.state.isDeliquencyIntRateMult30DPDlist[i] = false;
        this.state.isDeliquencyIntRateMult30DPDclasslist[i] = "#2ab934";
        this.state.isDeliquencyIntRateMult30DPDfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isDeliquencyIntRateMult30DPDlist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisDeliquencyIntRateMult60DPD() {
    let data = this.state.data;
    let isDeliquencyIntRateMult60DPDlist =
      this.state.isDeliquencyIntRateMult60DPDlist;
    let isDeliquencyIntRateMult60DPDclasslist =
      this.state.isDeliquencyIntRateMult60DPDclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMult60DPD) {
        underWritingRules.isDeliquencyIntRateMult60DPD = true;
        this.state.isDeliquencyIntRateMult60DPDlist[i] = true;
        this.state.isDeliquencyIntRateMult60DPDclasslist[i] = "#bcbcbb";
        this.state.isDeliquencyIntRateMult60DPDfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isDeliquencyIntRateMult60DPDlist,
          isDeliquencyIntRateMult60DPDclasslist,
        });
      } else {
        underWritingRules.isDeliquencyIntRateMult60DPD = true;
        this.state.isDeliquencyIntRateMult60DPDlist[i] = false;
        this.state.isDeliquencyIntRateMult60DPDclasslist[i] = "#2ab934";
        this.state.isDeliquencyIntRateMult60DPDfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isDeliquencyIntRateMult60DPDlist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisDeliquencyIntRateMultCurrent() {
    let data = this.state.data;
    let isDeliquencyIntRateMultCurrentlist =
      this.state.isDeliquencyIntRateMultCurrentlist;
    let isDeliquencyIntRateMultCurrentclasslist =
      this.state.isDeliquencyIntRateMultCurrentclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMultCurrent) {
        underWritingRules.isDeliquencyIntRateMultCurrent = true;
        this.state.isDeliquencyIntRateMultCurrentlist[i] = true;
        this.state.isDeliquencyIntRateMultCurrentclasslist[i] = "#bcbcbb";
        this.state.isDeliquencyIntRateMultCurrentfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isDeliquencyIntRateMultCurrentlist,
          isDeliquencyIntRateMultCurrentclasslist,
        });
      } else {
        underWritingRules.isDeliquencyIntRateMultCurrent = true;
        this.state.isDeliquencyIntRateMultCurrentlist[i] = false;
        this.state.isDeliquencyIntRateMultCurrentclasslist[i] = "#2ab934";
        this.state.isDeliquencyIntRateMultCurrentfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isDeliquencyIntRateMultCurrentlist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisDeliquencyIntRateMultGT90DPD() {
    let data = this.state.data;
    let isDeliquencyIntRateMultGT90DPDlist =
      this.state.isDeliquencyIntRateMultGT90DPDlist;
    let isDeliquencyIntRateMultGT90DPDclasslist =
      this.state.isDeliquencyIntRateMultGT90DPDclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMultGT90DPD) {
        underWritingRules.isDeliquencyIntRateMultGT90DPD = true;
        this.state.isDeliquencyIntRateMultGT90DPDlist[i] = true;
        this.state.isDeliquencyIntRateMultGT90DPDclasslist[i] = "#bcbcbb";
        this.state.isDeliquencyIntRateMultGT90DPDfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isDeliquencyIntRateMultGT90DPDlist,
          isDeliquencyIntRateMultGT90DPDclasslist,
        });
      } else {
        underWritingRules.isDeliquencyIntRateMultGT90DPD = true;
        this.state.isDeliquencyIntRateMultGT90DPDlist[i] = false;
        this.state.isDeliquencyIntRateMultGT90DPDclasslist[i] = "#2ab934";
        this.state.isDeliquencyIntRateMultGT90DPDfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isDeliquencyIntRateMultGT90DPDlist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisDeliquencyIntRateMultXDays() {
    let data = this.state.data;
    let isDeliquencyIntRateMultXDayslist =
      this.state.isDeliquencyIntRateMultXDayslist;
    let isDeliquencyIntRateMultXDaysclasslist =
      this.state.isDeliquencyIntRateMultXDaysclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMultXDays) {
        underWritingRules.isDeliquencyIntRateMultXDays = true;
        this.state.isDeliquencyIntRateMultXDayslist[i] = true;
        this.state.isDeliquencyIntRateMultXDaysclasslist[i] = "#bcbcbb";
        this.state.isDeliquencyIntRateMultXDaysfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isDeliquencyIntRateMultXDayslist,
          isDeliquencyIntRateMultXDaysclasslist,
        });
      } else {
        underWritingRules.isDeliquencyIntRateMultXDays = true;
        this.state.isDeliquencyIntRateMultXDayslist[i] = false;
        this.state.isDeliquencyIntRateMultXDaysclasslist[i] = "#2ab934";
        this.state.isDeliquencyIntRateMultXDaysfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isDeliquencyIntRateMultXDayslist,
        });
      }
      /**
       * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
       */
    });
  }

  onAndOffChangeisInstantloanDisCap() {
    let data = this.state.data;
    let isInstantloanDisCaplist = this.state.isInstantloanDisCaplist;
    let isInstantloanDisCapclasslist = this.state.isInstantloanDisCapclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isInstantloanDisCap) {
        underWritingRules.isInstantloanDisCap = true;
        this.state.isInstantloanDisCaplist[i] = true;
        this.state.isInstantloanDisCapclasslist[i] = "#bcbcbb";
        this.state.isInstantloanDisCapfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isInstantloanDisCaplist,
          isInstantloanDisCapclasslist,
        });
      } else {
        underWritingRules.isInstantloanDisCap = true;
        this.state.isInstantloanDisCaplist[i] = false;
        this.state.isInstantloanDisCapclasslist[i] = "#2ab934";
        this.state.isInstantloanDisCapfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isInstantloanDisCaplist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisLastSalary() {
    let data = this.state.data;
    let isLastSalarylist = this.state.isLastSalarylist;
    let isLastSalaryclasslist = this.state.isLastSalaryclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isLastSalary) {
        underWritingRules.isLastSalary = true;
        this.state.isLastSalarylist[i] = true;
        this.state.isLastSalaryclasslist[i] = "#bcbcbb";
        this.state.isLastSalaryfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isLastSalarylist,
          isLastSalaryclasslist,
        });
      } else {
        underWritingRules.isLastSalary = true;
        this.state.isLastSalarylist[i] = false;
        this.state.isLastSalaryclasslist[i] = "#2ab934";
        this.state.isLastSalaryfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isLastSalarylist,
        });
      }
      /**
       * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
       */
    });
  }

  onAndOffChangeisMaxAge() {
    console.log("onAndOffChangeisMaxAge ==>");
    let data = this.state.data;
    let isMaxAgelist = this.state.isMaxAgelist;
    let isMaxAgeclasslist = this.state.isMaxAgeclasslist;
    this.state.data.map((underWritingRules, i) => {
      console.log("onAndOffChangeisMaxAge ==>", typeof underWritingRules.isMaxAge);
      console.log("onAndOffChangeisMaxAge ==>", underWritingRules.isMaxAge);
      if ("N" == underWritingRules.isMaxAge) {
        console.log("onAndOffChangeisMaxAge ==>", i);

        underWritingRules.isMaxAge = true;
        this.state.isMaxAgelist[i] = true;
        this.state.isMaxAgeclasslist[i] = "#bcbcbb";
        this.state.isMaxAgefieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isMaxAgelist,
          isMaxAgeclasslist,
        });
      } else {
        console.log("onAndOffChangeisMaxAge111 ==>", i);
        console.log("onAndOffChangeisMaxAge111 ==>", underWritingRules);
        underWritingRules.isMaxAge = true;
        this.state.isMaxAgelist[i] = false;
        this.state.isMaxAgeclasslist[i] = "#2ab934";
        this.state.isMaxAgefieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isMaxAgelist,
          isMaxAgeclasslist
        });
      }
      /**
       * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
       */
    });
  }

  onAndOffChangeisMaxLoanAmtCap() {
    let data = this.state.data;
    let isMaxLoanAmtCaplist = this.state.isMaxLoanAmtCaplist;
    let isMaxLoanAmtCapclasslist = this.state.isMaxLoanAmtCapclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isMaxLoanAmtCap) {
        underWritingRules.isMaxLoanAmtCap = true;
        this.state.isMaxLoanAmtCaplist[i] = true;
        this.state.isMaxLoanAmtCapclasslist[i] = "#bcbcbb";
        this.state.isMaxLoanAmtCapfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isMaxLoanAmtCaplist,
          isMaxLoanAmtCapclasslist,
        });
      } else {
        underWritingRules.isMaxLoanAmtCap = true;
        this.state.isMaxLoanAmtCaplist[i] = false;
        this.state.isMaxLoanAmtCapclasslist[i] = "#2ab934";
        this.state.isMaxLoanAmtCapfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isMaxLoanAmtCaplist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisMinAge() {
    let data = this.state.data;
    let isMinAgelist = this.state.isMinAgelist;
    let isMinAgeclasslist = this.state.isMinAgeclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isMinAge) {
        underWritingRules.isMinAge = true;
        this.state.isMinAgelist[i] = true;
        this.state.isMinAgeclasslist[i] = "#bcbcbb";
        this.state.isMinAgefieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isMinAgelist,
          isMinAgeclasslist,
        });
      } else {
        underWritingRules.isMinAge = true;
        this.state.isMinAgelist[i] = false;
        this.state.isMinAgeclasslist[i] = "#2ab934";
        this.state.isMinAgefieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isMinAgelist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisMinLoanAmt() {
    let data = this.state.data;
    let isMinLoanAmtlist = this.state.isMinLoanAmtlist;
    let isMinLoanAmtclasslist = this.state.isMinLoanAmtclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isMinLoanAmt) {
        underWritingRules.isMinLoanAmt = true;
        this.state.isMinLoanAmtlist[i] = true;
        this.state.isMinLoanAmtclasslist[i] = "#bcbcbb";
        this.state.isMinLoanAmtfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isMinLoanAmtlist,
          isMinLoanAmtclasslist,
        });
      } else {
        underWritingRules.isMinLoanAmt = true;
        this.state.isMinLoanAmtlist[i] = false;
        this.state.isMinLoanAmtclasslist[i] = "#2ab934";
        this.state.isMinLoanAmtfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isMinLoanAmtlist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsCurCurEmplyIntRateMultBET1n3yrs() {
    let data = this.state.data;
    let isNumYrsCurCurEmplyIntRateMultBET1n3yrslist =
      this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist;
    let isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist =
      this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs) {
        underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = true;
        this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist[i] = true;
        this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist[i] =
          "#bcbcbb";
        this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsfieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isNumYrsCurCurEmplyIntRateMultBET1n3yrslist,
          isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist,
        });
      } else {
        underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = true;
        this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist[i] = false;
        this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist[i] =
          "#2ab934";
        this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsfieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isNumYrsCurCurEmplyIntRateMultBET1n3yrslist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsCurEmplyIntRateMultBET3n5yrs() {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultBET3n5yrslist =
      this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist;
    let isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist =
      this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs) {
        underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs = true;
        this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist[i] = true;
        this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist[i] = "#bcbcbb";
        this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsfieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultBET3n5yrslist,
          isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist,
        });
      } else {
        underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs = true;
        this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist[i] = false;
        this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist[i] = "#2ab934";
        this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsfieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultBET3n5yrslist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsCurEmplyIntRateMultBET5n10yrs() {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultBET5n10yrslist =
      this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist;
    let isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist =
      this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs) {
        underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs = true;
        this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist[i] = true;
        this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist[i] =
          "#bcbcbb";
        this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsfieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultBET5n10yrslist,
          isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist,
        });
      } else {
        underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs = true;
        this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist[i] = false;
        this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist[i] =
          "#2ab934";
        this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsfieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultBET5n10yrslist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsCurEmplyIntRateMultGT10yrs() {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultGT10yrslist =
      this.state.isNumYrsCurEmplyIntRateMultGT10yrslist;
    let isNumYrsCurEmplyIntRateMultGT10yrsclasslist =
      this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs) {
        underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs = true;
        this.state.isNumYrsCurEmplyIntRateMultGT10yrslist[i] = true;
        this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist[i] = "#bcbcbb";
        this.state.isNumYrsCurEmplyIntRateMultGT10yrsfieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultGT10yrslist,
          isNumYrsCurEmplyIntRateMultGT10yrsclasslist,
        });
      } else {
        underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs = true;
        this.state.isNumYrsCurEmplyIntRateMultGT10yrslist[i] = false;
        this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist[i] = "#2ab934";
        this.state.isNumYrsCurEmplyIntRateMultGT10yrsfieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultGT10yrslist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsCurEmplyIntRateMultLess1yr() {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultLess1yrlist =
      this.state.isNumYrsCurEmplyIntRateMultLess1yrlist;
    let isNumYrsCurEmplyIntRateMultLess1yrclasslist =
      this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr) {
        underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr = true;
        this.state.isNumYrsCurEmplyIntRateMultLess1yrlist[i] = true;
        this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist[i] = "#bcbcbb";
        this.state.isNumYrsCurEmplyIntRateMultLess1yrfieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultLess1yrlist,
          isNumYrsCurEmplyIntRateMultLess1yrclasslist,
        });
      } else {
        underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr = true;
        this.state.isNumYrsCurEmplyIntRateMultLess1yrlist[i] = false;
        this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist[i] = "#2ab934";
        this.state.isNumYrsCurEmplyIntRateMultLess1yrfieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultLess1yrlist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsEmplyIntMultRateMultBET1n3yrs() {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultBET1n3yrslist =
      this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist;
    let isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist =
      this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs) {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs = true;
        this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist[i] = true;
        this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist[i] =
          "#bcbcbb";
        this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsfieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultBET1n3yrslist,
          isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist,
        });
      } else {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs = true;
        this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist[i] = false;
        this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist[i] =
          "#2ab934";
        this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsfieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultBET1n3yrslist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsEmplyIntMultRateMultBET3n5yrs() {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultBET3n5yrslist =
      this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist;
    let isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist =
      this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs) {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs = true;
        this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist[i] = true;
        this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist[i] =
          "#bcbcbb";
        this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsfieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultBET3n5yrslist,
          isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist,
        });
      } else {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs = true;
        this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist[i] = false;
        this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist[i] =
          "#2ab934";
        this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsfieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultBET3n5yrslist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsEmplyIntMultRateMultBET5n10yrs() {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultBET5n10yrslist =
      this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist;
    let isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist =
      this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs) {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs = true;
        this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist[i] = true;
        this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist[i] =
          "#bcbcbb";
        this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsfieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultBET5n10yrslist,
          isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist,
        });
      } else {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs = true;
        this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist[i] = false;
        this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist[i] =
          "#2ab934";
        this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsfieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultBET5n10yrslist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsEmplyIntMultRateMultLess1yr() {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultLess1yrlist =
      this.state.isNumYrsEmplyIntMultRateMultLess1yrlist;
    let isNumYrsEmplyIntMultRateMultLess1yrclasslist =
      this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr) {
        underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr = true;
        this.state.isNumYrsEmplyIntMultRateMultLess1yrlist[i] = true;
        this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist[i] = "#bcbcbb";
        this.state.isNumYrsEmplyIntMultRateMultLess1yrfieldclasslist[i] =
          "#e9ecef";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultLess1yrlist,
          isNumYrsEmplyIntMultRateMultLess1yrclasslist,
        });
      } else {
        underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr = true;
        this.state.isNumYrsEmplyIntMultRateMultLess1yrlist[i] = false;
        this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist[i] = "#2ab934";
        this.state.isNumYrsEmplyIntMultRateMultLess1yrfieldclasslist[i] =
          "#bce394";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultLess1yrlist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color.
   */
  onAndOffChangeisNumYrsEmplyIntRateMultGT10yrs() {
    let data = this.state.data;
    let isNumYrsEmplyIntRateMultGT10yrslist =
      this.state.isNumYrsEmplyIntRateMultGT10yrslist;
    let isNumYrsEmplyIntRateMultGT10yrsclasslist =
      this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntRateMultGT10yrs) {
        underWritingRules.isNumYrsEmplyIntRateMultGT10yrs = true;
        this.state.isNumYrsEmplyIntRateMultGT10yrslist[i] = true;
        this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist[i] = "#bcbcbb";
        this.state.isNumYrsEmplyIntRateMultGT10yrsfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isNumYrsEmplyIntRateMultGT10yrslist,
          isNumYrsEmplyIntRateMultGT10yrsclasslist,
        });
      } else {
        underWritingRules.isNumYrsEmplyIntRateMultGT10yrs = true;
        this.state.isNumYrsEmplyIntRateMultGT10yrslist[i] = false;
        this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist[i] = "#2ab934";
        this.state.isNumYrsEmplyIntRateMultGT10yrsfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isNumYrsEmplyIntRateMultGT10yrslist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */
  setonAndOffChangeisAvgSalIntRateMultBET200000n500000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalIntRateMultBET200000n500000list[i]) {
        if (true == underWritingRules.isAvgSalIntRateMultBET200000n500000) {
          underWritingRules.isAvgSalIntRateMultBET200000n500000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultBET200000n500000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalIntRateMultBET200000n500000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */
  setonAndOffChangeisAvgSalIntRateMultBET500001n1000000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalIntRateMultBET500001n1000000list[i]) {
        if (true == underWritingRules.isAvgSalIntRateMultBET500001n1000000) {
          underWritingRules.isAvgSalIntRateMultBET500001n1000000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultBET500001n1000000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalIntRateMultBET500001n1000000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   *  Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */
  setonAndOffChangeisAvgSalIntRateMultBET1000001n2000000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalIntRateMultBET1000001n2000000list[i]) {
        if (true == underWritingRules.isAvgSalIntRateMultBET1000001n2000000) {
          underWritingRules.isAvgSalIntRateMultBET1000001n2000000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultBET1000001n2000000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalIntRateMultBET1000001n2000000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalIntRateMultBankstaff() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalIntRateMultBankstafflist[i]) {
        if (true == underWritingRules.isAvgSalIntRateMultBankstaff) {
          underWritingRules.isAvgSalIntRateMultBankstaff = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultBankstaff = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalIntRateMultBankstaff = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalIntRateMultGT2000000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalIntRateMultGT2000000list[i]) {
        if (true == underWritingRules.isAvgSalIntRateMultGT2000000) {
          underWritingRules.isAvgSalIntRateMultGT2000000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultGT2000000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalIntRateMultGT2000000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalIntRateMultLT200000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalIntRateMultLT200000list[i]) {
        if (true == underWritingRules.isAvgSalIntRateMultLT200000) {
          underWritingRules.isAvgSalIntRateMultLT200000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultLT200000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalIntRateMultLT200000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalIntRateMultStatus() {
    let data = this.state.data;
    this.state.data.map((underWritingRules) => {
      if (true == underWritingRules.isAvgSalIntRateMultStatus) {
        underWritingRules.isAvgSalIntRateMultStatus = "A";
        this.setState({
          data,
        });
      } else {
        underWritingRules.isAvgSalIntRateMultStatus = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalTenorBankStaffvalue() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalTenorBankStaffvaluelist[i]) {
        if (true == underWritingRules.isAvgSalTenorBankStaffvalue) {
          underWritingRules.isAvgSalTenorBankStaffvalue = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalTenorBankStaffvalue = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalTenorBankStaffvalue = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalTenorMultiplierStatus() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalTenorMultiplierStatuslist[i]) {
        if (true == underWritingRules.isAvgSalTenorMultiplierStatus) {
          underWritingRules.isAvgSalTenorMultiplierStatus = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalTenorMultiplierStatus = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalTenorMultiplierStatus = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalaryMultBET200000n500000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalaryMultBET200000n500000list[i]) {
        if (true == underWritingRules.isAvgSalaryMultBET200000n500000) {
          underWritingRules.isAvgSalaryMultBET200000n500000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalaryMultBET200000n500000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalaryMultBET200000n500000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalaryMultBET500001n1000000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalaryMultBET500001n1000000list[i]) {
        if (true == underWritingRules.isAvgSalaryMultBET500001n1000000) {
          underWritingRules.isAvgSalaryMultBET500001n1000000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalaryMultBET500001n1000000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalaryMultBET500001n1000000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalaryMultBET1000001n2000000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalaryMultBET1000001n2000000list[i]) {
        if (true == underWritingRules.isAvgSalaryMultBET1000001n2000000) {
          underWritingRules.isAvgSalaryMultBET1000001n2000000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalaryMultBET1000001n2000000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalaryMultBET1000001n2000000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalaryMultGT2000000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalaryMultGT2000000list[i]) {
        if (true == underWritingRules.isAvgSalaryMultGT2000000) {
          underWritingRules.isAvgSalaryMultGT2000000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalaryMultGT2000000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalaryMultGT2000000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisAvgSalaryMultLT200000() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isAvgSalaryMultLT200000list[i]) {
        if (true == underWritingRules.isAvgSalaryMultLT200000) {
          underWritingRules.isAvgSalaryMultLT200000 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isAvgSalaryMultLT200000 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isAvgSalaryMultLT200000 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisBureauScoreIntRateMultBET600N700() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isBureauScoreIntRateMultBET600N700list[i]) {
        if (true == underWritingRules.isBureauScoreIntRateMultBET600N700) {
          underWritingRules.isBureauScoreIntRateMultBET600N700 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultBET600N700 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isBureauScoreIntRateMultBET600N700 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisBureauScoreIntRateMultBET700n800() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isBureauScoreIntRateMultBET700n800list[i]) {
        if (true == underWritingRules.isBureauScoreIntRateMultBET700n800) {
          underWritingRules.isBureauScoreIntRateMultBET700n800 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultBET700n800 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isBureauScoreIntRateMultBET700n800 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisBureauScoreIntRateMultBET800n900() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isBureauScoreIntRateMultBET800n900list[i]) {
        if (true == underWritingRules.isBureauScoreIntRateMultBET800n900) {
          underWritingRules.isBureauScoreIntRateMultBET800n900 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultBET800n900 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isBureauScoreIntRateMultBET800n900 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisBureauScoreIntRateMultGT900() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isBureauScoreIntRateMultGT900list[i]) {
        if (true == underWritingRules.isBureauScoreIntRateMultGT900) {
          underWritingRules.isBureauScoreIntRateMultGT900 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultGT900 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isBureauScoreIntRateMultGT900 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisBureauScoreIntRateMultlt600() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isBureauScoreIntRateMultlt600list[i]) {
        if (true == underWritingRules.isBureauScoreIntRateMultlt600) {
          underWritingRules.isBureauScoreIntRateMultlt600 = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultlt600 = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isBureauScoreIntRateMultlt600 = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisCatMultiStatus() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isCatMultiStatuslist[i]) {
        if (true == underWritingRules.isCatMultiStatus) {
          underWritingRules.isCatMultiStatus = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isCatMultiStatus = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isCatMultiStatus = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisCategoryA() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isCategoryAlist[i]) {
        if (true == underWritingRules.isCategoryA) {
          underWritingRules.isCategoryA = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isCategoryA = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isCategoryA = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisCategoryB() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isCategoryBlist[i]) {
        if (true == underWritingRules.isCategoryB) {
          underWritingRules.isCategoryB = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isCategoryB = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isCategoryB = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisCategoryC() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isCategoryClist[i]) {
        if (true == underWritingRules.isCategoryC) {
          underWritingRules.isCategoryC = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isCategoryC = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isCategoryC = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisCategoryD() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isCategoryDlist[i]) {
        if (true == underWritingRules.isCategoryD) {
          underWritingRules.isCategoryD = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isCategoryD = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isCategoryD = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisCategoryE() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isCategoryElist[i]) {
        if (true == underWritingRules.isCategoryE) {
          underWritingRules.isCategoryE = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isCategoryE = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isCategoryE = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisDebtBurdenAllowPercent() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isDebtBurdenAllowPercentlist[i]) {
        if (true == underWritingRules.isDebtBurdenAllowPercent) {
          underWritingRules.isDebtBurdenAllowPercent = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isDebtBurdenAllowPercent = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isDebtBurdenAllowPercent = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisDeliquencyIntRateMult30DPD() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isDeliquencyIntRateMult30DPDlist[i]) {
        if (true == underWritingRules.isDeliquencyIntRateMult30DPD) {
          underWritingRules.isDeliquencyIntRateMult30DPD = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMult30DPD = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isDeliquencyIntRateMult30DPD = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisDeliquencyIntRateMult60DPD() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isDeliquencyIntRateMult60DPDlist[i]) {
        if (true == underWritingRules.isDeliquencyIntRateMult60DPD) {
          underWritingRules.isDeliquencyIntRateMult60DPD = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMult60DPD = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isDeliquencyIntRateMult60DPD = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisDeliquencyIntRateMultCurrent() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isDeliquencyIntRateMultCurrentlist[i]) {
        if (true == underWritingRules.isDeliquencyIntRateMultCurrent) {
          underWritingRules.isDeliquencyIntRateMultCurrent = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMultCurrent = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isDeliquencyIntRateMultCurrent = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisDeliquencyIntRateMultGT90DPD() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isDeliquencyIntRateMultGT90DPDlist[i]) {
        if (true == underWritingRules.isDeliquencyIntRateMultGT90DPD) {
          underWritingRules.isDeliquencyIntRateMultGT90DPD = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMultGT90DPD = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isDeliquencyIntRateMultGT90DPD = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisDeliquencyIntRateMultXDays() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isDeliquencyIntRateMultXDayslist[i]) {
        if (true == underWritingRules.isDeliquencyIntRateMultXDays) {
          underWritingRules.isDeliquencyIntRateMultXDays = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMultXDays = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isDeliquencyIntRateMultXDays = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisInstantloanDisCap() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isInstantloanDisCaplist[i]) {
        if (true == underWritingRules.isInstantloanDisCap) {
          underWritingRules.isInstantloanDisCap = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isInstantloanDisCap = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isInstantloanDisCap = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisLastSalary() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isLastSalarylist[i]) {
        if (true == underWritingRules.isLastSalary) {
          underWritingRules.isLastSalary = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isLastSalary = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isLastSalary = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisMaxAge() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isMaxAgelist[i]) {
        if (true == underWritingRules.isMaxAge) {
          underWritingRules.isMaxAge = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isMaxAge = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isMaxAge = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisMaxLoanAmtCap() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isMaxLoanAmtCaplist[i]) {
        if (true == underWritingRules.isMaxLoanAmtCap) {
          underWritingRules.isMaxLoanAmtCap = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isMaxLoanAmtCap = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isMaxLoanAmtCap = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisMinAge() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isMinAgelist[i]) {
        if (true == underWritingRules.isMinAge) {
          underWritingRules.isMinAge = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isMinAge = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isMinAge = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisMinLoanAmt() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isMinLoanAmtlist[i]) {
        if (true == underWritingRules.isMinLoanAmt) {
          underWritingRules.isMinLoanAmt = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isMinLoanAmt = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isMinLoanAmt = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsCurCurEmplyIntRateMultBET1n3yrs() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist[i]) {
        if (true == underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs) {
          underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsCurEmplyIntRateMultBET3n5yrs() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist[i]) {
        if (true == underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs) {
          underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsCurEmplyIntRateMultBET5n10yrs() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist[i]) {
        if (true == underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs) {
          underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsCurEmplyIntRateMultGT10yrs() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsCurEmplyIntRateMultGT10yrslist[i]) {
        if (true == underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs) {
          underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsCurEmplyIntRateMultLess1yr() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsCurEmplyIntRateMultLess1yrlist[i]) {
        if (true == underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr) {
          underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsEmplyIntMultRateMultBET1n3yrs() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist[i]) {
        if (true == underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs) {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsEmplyIntMultRateMultBET3n5yrs() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist[i]) {
        if (true == underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs) {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsEmplyIntMultRateMultBET5n10yrs() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist[i]) {
        if (true == underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs) {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsEmplyIntMultRateMultLess1yr() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsEmplyIntMultRateMultLess1yrlist[i]) {
        if (true == underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr) {
          underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr = "N";
        this.setState({
          data,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feeline1 toggle button,disable buttons and background color
   */ setonAndOffChangeisNumYrsEmplyIntRateMultGT10yrs() {
    let data = this.state.data;
    this.state.data.map((underWritingRules, i) => {
      if (false == this.state.isNumYrsEmplyIntRateMultGT10yrslist[i]) {
        if (true == underWritingRules.isNumYrsEmplyIntRateMultGT10yrs) {
          underWritingRules.isNumYrsEmplyIntRateMultGT10yrs = "A";
          this.setState({
            data,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntRateMultGT10yrs = "R";
          this.setState({
            data,
          });
        }
      } else {
        underWritingRules.isNumYrsEmplyIntRateMultGT10yrs = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisMinAge() {
    let data = this.state.data;
    let isMinAgelist = this.state.isMinAgelist;
    let isMinAgeclasslist = this.state.isMinAgeclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isMinAge) {
        underWritingRules.isMinAge = true;
        this.state.isMinAgelist[i] = true;
        this.state.isMinAgeclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isMinAgelist,
          isMinAgeclasslist,
        });
      } else {
        if ("A" == underWritingRules.isMinAge) {
          underWritingRules.isMinAge = true;
          this.state.isMinAgelist[i] = false;
          this.state.isMinAgeclasslist[i] = "#2ab934";
          this.setState({
            data,
            isMinAgelist,
            isMinAgeclasslist,
          });
        } else {
          underWritingRules.isMinAge = false;
          this.state.isMinAgelist[i] = false;
          this.state.isMinAgeclasslist[i] = "#6c757d";
          this.setState({
            data,
            isMinAgelist,
            isMinAgeclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisMaxAge() {
    let data = this.state.data;
    let isMaxAgelist = this.state.isMaxAgelist;
    let isMaxAgeclasslist = this.state.isMaxAgeclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isMaxAge) {
        underWritingRules.isMaxAge = true;
        this.state.isMaxAgelist[i] = true;
        this.state.isMaxAgeclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isMaxAgelist,
          isMaxAgeclasslist,
        });
      } else {
        if ("A" == underWritingRules.isMaxAge) {
          underWritingRules.isMaxAge = true;
          this.state.isMaxAgelist[i] = false;
          this.state.isMaxAgeclasslist[i] = "#2ab934";
          this.setState({
            data,
            isMaxAgelist,
            isMaxAgeclasslist,
          });
        } else {
          underWritingRules.isMaxAge = false;
          this.state.isMaxAgelist[i] = false;
          this.state.isMaxAgeclasslist[i] = "#6c757d";
          this.setState({
            data,
            isMaxAgelist,
            isMaxAgeclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisDebtBurdenAllowPercent() {
    let data = this.state.data;
    let isDebtBurdenAllowPercentlist = this.state.isDebtBurdenAllowPercentlist;
    let isDebtBurdenAllowPercentclasslist =
      this.state.isDebtBurdenAllowPercentclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDebtBurdenAllowPercent) {
        underWritingRules.isDebtBurdenAllowPercent = true;
        this.state.isDebtBurdenAllowPercentlist[i] = true;
        this.state.isDebtBurdenAllowPercentclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isDebtBurdenAllowPercentlist,
          isDebtBurdenAllowPercentclasslist,
        });
      } else {
        if ("A" == underWritingRules.isDebtBurdenAllowPercent) {
          underWritingRules.isDebtBurdenAllowPercent = true;
          this.state.isDebtBurdenAllowPercentlist[i] = false;
          this.state.isDebtBurdenAllowPercentclasslist[i] = "#2ab934";
          this.setState({
            data,
            isDebtBurdenAllowPercentlist,
            isDebtBurdenAllowPercentclasslist,
          });
        } else {
          underWritingRules.isDebtBurdenAllowPercent = false;
          this.state.isDebtBurdenAllowPercentlist[i] = false;
          this.state.isDebtBurdenAllowPercentclasslist[i] = "#6c757d";
          this.setState({
            data,
            isDebtBurdenAllowPercentlist,
            isDebtBurdenAllowPercentclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisMinLoanAmt() {
    let data = this.state.data;
    let isMinLoanAmtlist = this.state.isMinLoanAmtlist;
    let isMinLoanAmtclasslist = this.state.isMinLoanAmtclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isMinLoanAmt) {
        underWritingRules.isMinLoanAmt = true;
        this.state.isMinLoanAmtlist[i] = true;
        this.state.isMinLoanAmtclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isMinLoanAmtlist,
          isMinLoanAmtclasslist,
        });
      } else {
        if ("A" == underWritingRules.isMinLoanAmt) {
          underWritingRules.isMinLoanAmt = true;
          this.state.isMinLoanAmtlist[i] = false;
          this.state.isMinLoanAmtclasslist[i] = "#2ab934";
          this.setState({
            data,
            isMinLoanAmtlist,
            isMinLoanAmtclasslist,
          });
        } else {
          underWritingRules.isMinLoanAmt = false;
          this.state.isMinLoanAmtlist[i] = false;
          this.state.isMinLoanAmtclasslist[i] = "#6c757d";
          this.setState({
            data,
            isMinLoanAmtlist,
            isMinLoanAmtclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisMaxLoanAmtCap() {
    let data = this.state.data;
    let isMaxLoanAmtCaplist = this.state.isMaxLoanAmtCaplist;
    let isMaxLoanAmtCapclasslist = this.state.isMaxLoanAmtCapclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isMaxLoanAmtCap) {
        underWritingRules.isMaxLoanAmtCap = true;
        this.state.isMaxLoanAmtCaplist[i] = true;
        this.state.isMaxLoanAmtCapclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isMaxLoanAmtCaplist,
          isMaxLoanAmtCapclasslist,
        });
      } else {
        if ("A" == underWritingRules.isMaxLoanAmtCap) {
          underWritingRules.isMaxLoanAmtCap = true;
          this.state.isMaxLoanAmtCaplist[i] = false;
          this.state.isMaxLoanAmtCapclasslist[i] = "#2ab934";
          this.setState({
            data,
            isMaxLoanAmtCaplist,
            isMaxLoanAmtCapclasslist,
          });
        } else {
          underWritingRules.isMaxLoanAmtCap = false;
          this.state.isMaxLoanAmtCaplist[i] = false;
          this.state.isMaxLoanAmtCapclasslist[i] = "#6c757d";
          this.setState({
            data,
            isMaxLoanAmtCaplist,
            isMaxLoanAmtCapclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisInstantloanDisCap() {
    let data = this.state.data;
    let isInstantloanDisCaplist = this.state.isInstantloanDisCaplist;
    let isInstantloanDisCapclasslist = this.state.isInstantloanDisCapclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isInstantloanDisCap) {
        underWritingRules.isInstantloanDisCap = true;
        this.state.isInstantloanDisCaplist[i] = true;
        this.state.isInstantloanDisCapclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isInstantloanDisCaplist,
          isInstantloanDisCapclasslist,
        });
      } else {
        if ("A" == underWritingRules.isInstantloanDisCap) {
          underWritingRules.isInstantloanDisCap = true;
          this.state.isInstantloanDisCaplist[i] = false;
          this.state.isInstantloanDisCapclasslist[i] = "#2ab934";
          this.setState({
            data,
            isInstantloanDisCaplist,
            isInstantloanDisCapclasslist,
          });
        } else {
          underWritingRules.isInstantloanDisCap = false;
          this.state.isInstantloanDisCaplist[i] = false;
          this.state.isInstantloanDisCapclasslist[i] = "#6c757d";
          this.setState({
            data,
            isInstantloanDisCaplist,
            isInstantloanDisCapclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisLastSalary() {
    let data = this.state.data;
    let isLastSalarylist = this.state.isLastSalarylist;
    let isLastSalaryclasslist = this.state.isLastSalaryclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isLastSalary) {
        underWritingRules.isLastSalary = true;
        this.state.isLastSalarylist[i] = true;
        this.state.isLastSalaryclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isLastSalarylist,
          isLastSalaryclasslist,
        });
      } else {
        if ("A" == underWritingRules.isLastSalary) {
          underWritingRules.isLastSalary = true;
          this.state.isLastSalarylist[i] = false;
          this.state.isLastSalaryclasslist[i] = "#2ab934";
          this.setState({
            data,
            isLastSalarylist,
            isLastSalaryclasslist,
          });
        } else {
          underWritingRules.isLastSalary = false;
          this.state.isLastSalarylist[i] = false;
          this.state.isLastSalaryclasslist[i] = "#6c757d";
          this.setState({
            data,
            isLastSalarylist,
            isLastSalaryclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisCategoryA() {
    let data = this.state.data;
    let isCategoryAlist = this.state.isCategoryAlist;
    let isCategoryAclasslist = this.state.isCategoryAclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryA) {
        underWritingRules.isCategoryA = true;
        this.state.isCategoryAlist[i] = true;
        this.state.isCategoryAclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isCategoryAlist,
          isCategoryAclasslist,
        });
      } else {
        if ("A" == underWritingRules.isCategoryA) {
          underWritingRules.isCategoryA = true;
          this.state.isCategoryAlist[i] = false;
          this.state.isCategoryAclasslist[i] = "#2ab934";
          this.setState({
            data,
            isCategoryAlist,
            isCategoryAclasslist,
          });
        } else {
          underWritingRules.isCategoryA = false;
          this.state.isCategoryAlist[i] = false;
          this.state.isCategoryAclasslist[i] = "#6c757d";
          this.setState({
            data,
            isCategoryAlist,
            isCategoryAclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisCategoryB() {
    let data = this.state.data;
    let isCategoryBlist = this.state.isCategoryBlist;
    let isCategoryBclasslist = this.state.isCategoryBclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryB) {
        underWritingRules.isCategoryB = true;
        this.state.isCategoryBlist[i] = true;
        this.state.isCategoryBclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isCategoryBlist,
          isCategoryBclasslist,
        });
      } else {
        if ("A" == underWritingRules.isCategoryB) {
          underWritingRules.isCategoryB = true;
          this.state.isCategoryBlist[i] = false;
          this.state.isCategoryBclasslist[i] = "#2ab934";
          this.setState({
            data,
            isCategoryBlist,
            isCategoryBclasslist,
          });
        } else {
          underWritingRules.isCategoryB = false;
          this.state.isCategoryBlist[i] = false;
          this.state.isCategoryBclasslist[i] = "#6c757d";
          this.setState({
            data,
            isCategoryBlist,
            isCategoryBclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisCategoryC() {
    let data = this.state.data;
    let isCategoryClist = this.state.isCategoryClist;
    let isCategoryCclasslist = this.state.isCategoryCclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryC) {
        underWritingRules.isCategoryC = true;
        this.state.isCategoryClist[i] = true;
        this.state.isCategoryCclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isCategoryClist,
          isCategoryCclasslist,
        });
      } else {
        if ("A" == underWritingRules.isCategoryC) {
          underWritingRules.isCategoryC = true;
          this.state.isCategoryClist[i] = false;
          this.state.isCategoryCclasslist[i] = "#2ab934";
          this.setState({
            data,
            isCategoryClist,
            isCategoryCclasslist,
          });
        } else {
          underWritingRules.isCategoryC = false;
          this.state.isCategoryClist[i] = false;
          this.state.isCategoryCclasslist[i] = "#6c757d";
          this.setState({
            data,
            isCategoryClist,
            isCategoryCclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisCategoryD() {
    let data = this.state.data;
    let isCategoryDlist = this.state.isCategoryDlist;
    let isCategoryDclasslist = this.state.isCategoryDclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryD) {
        underWritingRules.isCategoryD = true;
        this.state.isCategoryDlist[i] = true;
        this.state.isCategoryDclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isCategoryDlist,
          isCategoryDclasslist,
        });
      } else {
        if ("A" == underWritingRules.isCategoryD) {
          underWritingRules.isCategoryD = true;
          this.state.isCategoryDlist[i] = false;
          this.state.isCategoryDclasslist[i] = "#2ab934";
          this.setState({
            data,
            isCategoryDlist,
            isCategoryDclasslist,
          });
        } else {
          underWritingRules.isCategoryD = false;
          this.state.isCategoryDlist[i] = false;
          this.state.isCategoryDclasslist[i] = "#6c757d";
          this.setState({
            data,
            isCategoryDlist,
            isCategoryDclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisCategoryE() {
    let data = this.state.data;
    let isCategoryElist = this.state.isCategoryElist;
    let isCategoryEclasslist = this.state.isCategoryEclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCategoryE) {
        underWritingRules.isCategoryE = true;
        this.state.isCategoryElist[i] = true;
        this.state.isCategoryEclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isCategoryElist,
          isCategoryEclasslist,
        });
      } else {
        if ("A" == underWritingRules.isCategoryE) {
          underWritingRules.isCategoryE = true;
          this.state.isCategoryElist[i] = false;
          this.state.isCategoryEclasslist[i] = "#2ab934";
          this.setState({
            data,
            isCategoryElist,
            isCategoryEclasslist,
          });
        } else {
          underWritingRules.isCategoryE = false;
          this.state.isCategoryElist[i] = false;
          this.state.isCategoryEclasslist[i] = "#6c757d";
          this.setState({
            data,
            isCategoryElist,
            isCategoryEclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisCatMultiStatus() {
    let data = this.state.data;
    let isCatMultiStatuslist = this.state.isCatMultiStatuslist;
    let isCatMultiStatusclasslist = this.state.isCatMultiStatusclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isCatMultiStatus) {
        underWritingRules.isCatMultiStatus = true;
        this.state.isCatMultiStatuslist[i] = true;
        this.state.isCatMultiStatusclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isCatMultiStatuslist,
          isCatMultiStatusclasslist,
        });
      } else {
        if ("A" == underWritingRules.isCatMultiStatus) {
          underWritingRules.isCatMultiStatus = true;
          this.state.isCatMultiStatuslist[i] = false;
          this.state.isCatMultiStatusclasslist[i] = "#2ab934";
          this.setState({
            data,
            isCatMultiStatuslist,
            isCatMultiStatusclasslist,
          });
        } else {
          underWritingRules.isCatMultiStatus = false;
          this.state.isCatMultiStatuslist[i] = false;
          this.state.isCatMultiStatusclasslist[i] = "#6c757d";
          this.setState({
            data,
            isCatMultiStatuslist,
            isCatMultiStatusclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalaryMultLT200000() {
    let data = this.state.data;
    let isAvgSalaryMultLT200000list = this.state.isAvgSalaryMultLT200000list;
    let isAvgSalaryMultLT200000classlist =
      this.state.isAvgSalaryMultLT200000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultLT200000) {
        underWritingRules.isAvgSalaryMultLT200000 = true;
        this.state.isAvgSalaryMultLT200000list[i] = true;
        this.state.isAvgSalaryMultLT200000classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalaryMultLT200000list,
          isAvgSalaryMultLT200000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalaryMultLT200000) {
          underWritingRules.isAvgSalaryMultLT200000 = true;
          this.state.isAvgSalaryMultLT200000list[i] = false;
          this.state.isAvgSalaryMultLT200000classlist[i] = "#2ab934";
          this.setState({
            data,
            isAvgSalaryMultLT200000list,
            isAvgSalaryMultLT200000classlist,
          });
        } else {
          underWritingRules.isAvgSalaryMultLT200000 = false;
          this.state.isAvgSalaryMultLT200000list[i] = false;
          this.state.isAvgSalaryMultLT200000classlist[i] = "#6c757d";
          this.setState({
            data,
            isAvgSalaryMultLT200000list,
            isAvgSalaryMultLT200000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalaryMultBET200000n500000() {
    let data = this.state.data;
    let isAvgSalaryMultBET200000n500000list =
      this.state.isAvgSalaryMultBET200000n500000list;
    let isAvgSalaryMultBET200000n500000classlist =
      this.state.isAvgSalaryMultBET200000n500000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultBET200000n500000) {
        underWritingRules.isAvgSalaryMultBET200000n500000 = true;
        this.state.isAvgSalaryMultBET200000n500000list[i] = true;
        this.state.isAvgSalaryMultBET200000n500000classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalaryMultBET200000n500000list,
          isAvgSalaryMultBET200000n500000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalaryMultBET200000n500000) {
          underWritingRules.isAvgSalaryMultBET200000n500000 = true;
          this.state.isAvgSalaryMultBET200000n500000list[i] = false;
          this.state.isAvgSalaryMultBET200000n500000classlist[i] = "#2ab934";
          this.setState({
            data,
            isAvgSalaryMultBET200000n500000list,
            isAvgSalaryMultBET200000n500000classlist,
          });
        } else {
          underWritingRules.isAvgSalaryMultBET200000n500000 = false;
          this.state.isAvgSalaryMultBET200000n500000list[i] = false;
          this.state.isAvgSalaryMultBET200000n500000classlist[i] = "#6c757d";
          this.setState({
            data,
            isAvgSalaryMultBET200000n500000list,
            isAvgSalaryMultBET200000n500000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalaryMultBET500001n1000000() {
    let data = this.state.data;
    let isAvgSalaryMultBET500001n1000000list =
      this.state.isAvgSalaryMultBET500001n1000000list;
    let isAvgSalaryMultBET500001n1000000classlist =
      this.state.isAvgSalaryMultBET500001n1000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultBET500001n1000000) {
        underWritingRules.isAvgSalaryMultBET500001n1000000 = true;
        this.state.isAvgSalaryMultBET500001n1000000list[i] = true;
        this.state.isAvgSalaryMultBET500001n1000000classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalaryMultBET500001n1000000list,
          isAvgSalaryMultBET500001n1000000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalaryMultBET500001n1000000) {
          underWritingRules.isAvgSalaryMultBET500001n1000000 = true;
          this.state.isAvgSalaryMultBET500001n1000000list[i] = false;
          this.state.isAvgSalaryMultBET500001n1000000classlist[i] = "#2ab934";
          this.setState({
            data,
            isAvgSalaryMultBET500001n1000000list,
            isAvgSalaryMultBET500001n1000000classlist,
          });
        } else {
          underWritingRules.isAvgSalaryMultBET500001n1000000 = false;
          this.state.isAvgSalaryMultBET500001n1000000list[i] = false;
          this.state.isAvgSalaryMultBET500001n1000000classlist[i] = "#6c757d";
          this.setState({
            data,
            isAvgSalaryMultBET500001n1000000list,
            isAvgSalaryMultBET500001n1000000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalaryMultBET1000001n2000000() {
    let data = this.state.data;
    let isAvgSalaryMultBET1000001n2000000list =
      this.state.isAvgSalaryMultBET1000001n2000000list;
    let isAvgSalaryMultBET1000001n2000000classlist =
      this.state.isAvgSalaryMultBET1000001n2000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultBET1000001n2000000) {
        underWritingRules.isAvgSalaryMultBET1000001n2000000 = true;
        this.state.isAvgSalaryMultBET1000001n2000000list[i] = true;
        this.state.isAvgSalaryMultBET1000001n2000000classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalaryMultBET1000001n2000000list,
          isAvgSalaryMultBET1000001n2000000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalaryMultBET1000001n2000000) {
          underWritingRules.isAvgSalaryMultBET1000001n2000000 = true;
          this.state.isAvgSalaryMultBET1000001n2000000list[i] = false;
          this.state.isAvgSalaryMultBET1000001n2000000classlist[i] = "#2ab934";
          this.setState({
            data,
            isAvgSalaryMultBET1000001n2000000list,
            isAvgSalaryMultBET1000001n2000000classlist,
          });
        } else {
          underWritingRules.isAvgSalaryMultBET1000001n2000000 = false;
          this.state.isAvgSalaryMultBET1000001n2000000list[i] = false;
          this.state.isAvgSalaryMultBET1000001n2000000classlist[i] = "#6c757d";
          this.setState({
            data,
            isAvgSalaryMultBET1000001n2000000list,
            isAvgSalaryMultBET1000001n2000000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalaryMultGT2000000() {
    let data = this.state.data;
    let isAvgSalaryMultGT2000000list = this.state.isAvgSalaryMultGT2000000list;
    let isAvgSalaryMultGT2000000classlist =
      this.state.isAvgSalaryMultGT2000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalaryMultGT2000000) {
        underWritingRules.isAvgSalaryMultGT2000000 = true;
        this.state.isAvgSalaryMultGT2000000list[i] = true;
        this.state.isAvgSalaryMultGT2000000classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalaryMultGT2000000list,
          isAvgSalaryMultGT2000000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalaryMultGT2000000) {
          underWritingRules.isAvgSalaryMultGT2000000 = true;
          this.state.isAvgSalaryMultGT2000000list[i] = false;
          this.state.isAvgSalaryMultGT2000000classlist[i] = "#2ab934";
          this.setState({
            data,
            isAvgSalaryMultGT2000000list,
            isAvgSalaryMultGT2000000classlist,
          });
        } else {
          underWritingRules.isAvgSalaryMultGT2000000 = false;
          this.state.isAvgSalaryMultGT2000000list[i] = false;
          this.state.isAvgSalaryMultGT2000000classlist[i] = "#6c757d";
          this.setState({
            data,
            isAvgSalaryMultGT2000000list,
            isAvgSalaryMultGT2000000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalTenorBankStaffvalue() {
    let data = this.state.data;
    let isAvgSalTenorBankStaffvaluelist =
      this.state.isAvgSalTenorBankStaffvaluelist;
    let isAvgSalTenorBankStaffvalueclasslist =
      this.state.isAvgSalTenorBankStaffvalueclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalTenorBankStaffvalue) {
        underWritingRules.isAvgSalTenorBankStaffvalue = true;
        this.state.isAvgSalTenorBankStaffvaluelist[i] = true;
        this.state.isAvgSalTenorBankStaffvalueclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalTenorBankStaffvaluelist,
          isAvgSalTenorBankStaffvalueclasslist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalTenorBankStaffvalue) {
          underWritingRules.isAvgSalTenorBankStaffvalue = true;
          this.state.isAvgSalTenorBankStaffvaluelist[i] = false;
          this.state.isAvgSalTenorBankStaffvalueclasslist[i] = "#2ab934";
          this.setState({
            data,
            isAvgSalTenorBankStaffvaluelist,
            isAvgSalTenorBankStaffvalueclasslist,
          });
        } else {
          underWritingRules.isAvgSalTenorBankStaffvalue = false;
          this.state.isAvgSalTenorBankStaffvaluelist[i] = false;
          this.state.isAvgSalTenorBankStaffvalueclasslist[i] = "#6c757d";
          this.setState({
            data,
            isAvgSalTenorBankStaffvaluelist,
            isAvgSalTenorBankStaffvalueclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalIntRateMultLT200000() {
    let data = this.state.data;
    let isAvgSalIntRateMultLT200000list =
      this.state.isAvgSalIntRateMultLT200000list;
    let isAvgSalIntRateMultLT200000classlist =
      this.state.isAvgSalIntRateMultLT200000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultLT200000) {
        underWritingRules.isAvgSalIntRateMultLT200000 = true;
        this.state.isAvgSalIntRateMultLT200000list[i] = true;
        this.state.isAvgSalIntRateMultLT200000classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalIntRateMultLT200000list,
          isAvgSalIntRateMultLT200000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalIntRateMultLT200000) {
          underWritingRules.isAvgSalIntRateMultLT200000 = true;
          this.state.isAvgSalIntRateMultLT200000list[i] = false;
          this.state.isAvgSalIntRateMultLT200000classlist[i] = "#2ab934";
          this.setState({
            data,
            isAvgSalIntRateMultLT200000list,
            isAvgSalIntRateMultLT200000classlist,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultLT200000 = false;
          this.state.isAvgSalIntRateMultLT200000list[i] = false;
          this.state.isAvgSalIntRateMultLT200000classlist[i] = "#6c757d";
          this.setState({
            data,
            isAvgSalIntRateMultLT200000list,
            isAvgSalIntRateMultLT200000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalIntRateMultBET200000n500000() {
    let data = this.state.data;
    let isAvgSalIntRateMultBET200000n500000list =
      this.state.isAvgSalIntRateMultBET200000n500000list;
    let isAvgSalIntRateMultBET200000n500000classlist =
      this.state.isAvgSalIntRateMultBET200000n500000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultBET200000n500000) {
        underWritingRules.isAvgSalIntRateMultBET200000n500000 = true;
        this.state.isAvgSalIntRateMultBET200000n500000list[i] = true;
        this.state.isAvgSalIntRateMultBET200000n500000classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalIntRateMultBET200000n500000list,
          isAvgSalIntRateMultBET200000n500000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalIntRateMultBET200000n500000) {
          underWritingRules.isAvgSalIntRateMultBET200000n500000 = true;
          this.state.isAvgSalIntRateMultBET200000n500000list[i] = false;
          this.state.isAvgSalIntRateMultBET200000n500000classlist[i] =
            "#2ab934";
          this.setState({
            data,
            isAvgSalIntRateMultBET200000n500000list,
            isAvgSalIntRateMultBET200000n500000classlist,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultBET200000n500000 = false;
          this.state.isAvgSalIntRateMultBET200000n500000list[i] = false;
          this.state.isAvgSalIntRateMultBET200000n500000classlist[i] =
            "#6c757d";
          this.setState({
            data,
            isAvgSalIntRateMultBET200000n500000list,
            isAvgSalIntRateMultBET200000n500000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalIntRateMultBET500001n1000000() {
    let data = this.state.data;
    let isAvgSalIntRateMultBET500001n1000000list =
      this.state.isAvgSalIntRateMultBET500001n1000000list;
    let isAvgSalIntRateMultBET500001n1000000classlist =
      this.state.isAvgSalIntRateMultBET500001n1000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultBET500001n1000000) {
        underWritingRules.isAvgSalIntRateMultBET500001n1000000 = true;
        this.state.isAvgSalIntRateMultBET500001n1000000list[i] = true;
        this.state.isAvgSalIntRateMultBET500001n1000000classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalIntRateMultBET500001n1000000list,
          isAvgSalIntRateMultBET500001n1000000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalIntRateMultBET500001n1000000) {
          underWritingRules.isAvgSalIntRateMultBET500001n1000000 = true;
          this.state.isAvgSalIntRateMultBET500001n1000000list[i] = false;
          this.state.isAvgSalIntRateMultBET500001n1000000classlist[i] =
            "#2ab934";
          this.setState({
            data,
            isAvgSalIntRateMultBET500001n1000000list,
            isAvgSalIntRateMultBET500001n1000000classlist,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultBET500001n1000000 = false;
          this.state.isAvgSalIntRateMultBET500001n1000000list[i] = false;
          this.state.isAvgSalIntRateMultBET500001n1000000classlist[i] =
            "#6c757d";
          this.setState({
            data,
            isAvgSalIntRateMultBET500001n1000000list,
            isAvgSalIntRateMultBET500001n1000000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalIntRateMultBET1000001n2000000() {
    let data = this.state.data;
    let isAvgSalIntRateMultBET1000001n2000000list =
      this.state.isAvgSalIntRateMultBET1000001n2000000list;
    let isAvgSalIntRateMultBET1000001n2000000classlist =
      this.state.isAvgSalIntRateMultBET1000001n2000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultBET1000001n2000000) {
        underWritingRules.isAvgSalIntRateMultBET1000001n2000000 = true;
        this.state.isAvgSalIntRateMultBET1000001n2000000list[i] = true;
        this.state.isAvgSalIntRateMultBET1000001n2000000classlist[i] =
          "#bcbcbb";
        this.setState({
          data,
          isAvgSalIntRateMultBET1000001n2000000list,
          isAvgSalIntRateMultBET1000001n2000000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalIntRateMultBET1000001n2000000) {
          underWritingRules.isAvgSalIntRateMultBET1000001n2000000 = true;
          this.state.isAvgSalIntRateMultBET1000001n2000000list[i] = false;
          this.state.isAvgSalIntRateMultBET1000001n2000000classlist[i] =
            "#2ab934";
          this.setState({
            data,
            isAvgSalIntRateMultBET1000001n2000000list,
            isAvgSalIntRateMultBET1000001n2000000classlist,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultBET1000001n2000000 = false;
          this.state.isAvgSalIntRateMultBET1000001n2000000list[i] = false;
          this.state.isAvgSalIntRateMultBET1000001n2000000classlist[i] =
            "#6c757d";
          this.setState({
            data,
            isAvgSalIntRateMultBET1000001n2000000list,
            isAvgSalIntRateMultBET1000001n2000000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAvgSalIntRateMultGT2000000() {
    let data = this.state.data;
    let isAvgSalIntRateMultGT2000000list =
      this.state.isAvgSalIntRateMultGT2000000list;
    let isAvgSalIntRateMultGT2000000classlist =
      this.state.isAvgSalIntRateMultGT2000000classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isAvgSalIntRateMultGT2000000) {
        underWritingRules.isAvgSalIntRateMultGT2000000 = true;
        this.state.isAvgSalIntRateMultGT2000000list[i] = true;
        this.state.isAvgSalIntRateMultGT2000000classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isAvgSalIntRateMultGT2000000list,
          isAvgSalIntRateMultGT2000000classlist,
        });
      } else {
        if ("A" == underWritingRules.isAvgSalIntRateMultGT2000000) {
          underWritingRules.isAvgSalIntRateMultGT2000000 = true;
          this.state.isAvgSalIntRateMultGT2000000list[i] = false;
          this.state.isAvgSalIntRateMultGT2000000classlist[i] = "#2ab934";
          this.setState({
            data,
            isAvgSalIntRateMultGT2000000list,
            isAvgSalIntRateMultGT2000000classlist,
          });
        } else {
          underWritingRules.isAvgSalIntRateMultGT2000000 = false;
          this.state.isAvgSalIntRateMultGT2000000list[i] = false;
          this.state.isAvgSalIntRateMultGT2000000classlist[i] = "#6c757d";
          this.setState({
            data,
            isAvgSalIntRateMultGT2000000list,
            isAvgSalIntRateMultGT2000000classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsEmplyIntMultRateMultLess1yr() {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultLess1yrlist =
      this.state.isNumYrsEmplyIntMultRateMultLess1yrlist;
    let isNumYrsEmplyIntMultRateMultLess1yrclasslist =
      this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr) {
        underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr = true;
        this.state.isNumYrsEmplyIntMultRateMultLess1yrlist[i] = true;
        this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultLess1yrlist,
          isNumYrsEmplyIntMultRateMultLess1yrclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr) {
          underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr = true;
          this.state.isNumYrsEmplyIntMultRateMultLess1yrlist[i] = false;
          this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist[i] =
            "#2ab934";
          this.setState({
            data,
            isNumYrsEmplyIntMultRateMultLess1yrlist,
            isNumYrsEmplyIntMultRateMultLess1yrclasslist,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr = false;
          this.state.isNumYrsEmplyIntMultRateMultLess1yrlist[i] = false;
          this.state.isNumYrsEmplyIntMultRateMultLess1yrclasslist[i] =
            "#6c757d";
          this.setState({
            data,
            isNumYrsEmplyIntMultRateMultLess1yrlist,
            isNumYrsEmplyIntMultRateMultLess1yrclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsEmplyIntMultRateMultBET1n3yrs() {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultBET1n3yrslist =
      this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist;
    let isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist =
      this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs) {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs = true;
        this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist[i] = true;
        this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist[i] =
          "#bcbcbb";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultBET1n3yrslist,
          isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs) {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs = true;
          this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist[i] = false;
          this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist[i] =
            "#2ab934";
          this.setState({
            data,
            isNumYrsEmplyIntMultRateMultBET1n3yrslist,
            isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs = false;
          this.state.isNumYrsEmplyIntMultRateMultBET1n3yrslist[i] = false;
          this.state.isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist[i] =
            "#6c757d";
          this.setState({
            data,
            isNumYrsEmplyIntMultRateMultBET1n3yrslist,
            isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsEmplyIntMultRateMultBET3n5yrs() {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultBET3n5yrslist =
      this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist;
    let isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist =
      this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs) {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs = true;
        this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist[i] = true;
        this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist[i] =
          "#bcbcbb";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultBET3n5yrslist,
          isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs) {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs = true;
          this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist[i] = false;
          this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist[i] =
            "#2ab934";
          this.setState({
            data,
            isNumYrsEmplyIntMultRateMultBET3n5yrslist,
            isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs = false;
          this.state.isNumYrsEmplyIntMultRateMultBET3n5yrslist[i] = false;
          this.state.isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist[i] =
            "#6c757d";
          this.setState({
            data,
            isNumYrsEmplyIntMultRateMultBET3n5yrslist,
            isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsEmplyIntMultRateMultBET5n10yrs() {
    let data = this.state.data;
    let isNumYrsEmplyIntMultRateMultBET5n10yrslist =
      this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist;
    let isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist =
      this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs) {
        underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs = true;
        this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist[i] = true;
        this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist[i] =
          "#bcbcbb";
        this.setState({
          data,
          isNumYrsEmplyIntMultRateMultBET5n10yrslist,
          isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs) {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs = true;
          this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist[i] = false;
          this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist[i] =
            "#2ab934";
          this.setState({
            data,
            isNumYrsEmplyIntMultRateMultBET5n10yrslist,
            isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs = false;
          this.state.isNumYrsEmplyIntMultRateMultBET5n10yrslist[i] = false;
          this.state.isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist[i] =
            "#6c757d";
          this.setState({
            data,
            isNumYrsEmplyIntMultRateMultBET5n10yrslist,
            isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsEmplyIntRateMultGT10yrs() {
    let data = this.state.data;
    let isNumYrsEmplyIntRateMultGT10yrslist =
      this.state.isNumYrsEmplyIntRateMultGT10yrslist;
    let isNumYrsEmplyIntRateMultGT10yrsclasslist =
      this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsEmplyIntRateMultGT10yrs) {
        underWritingRules.isNumYrsEmplyIntRateMultGT10yrs = true;
        this.state.isNumYrsEmplyIntRateMultGT10yrslist[i] = true;
        this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isNumYrsEmplyIntRateMultGT10yrslist,
          isNumYrsEmplyIntRateMultGT10yrsclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsEmplyIntRateMultGT10yrs) {
          underWritingRules.isNumYrsEmplyIntRateMultGT10yrs = true;
          this.state.isNumYrsEmplyIntRateMultGT10yrslist[i] = false;
          this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist[i] = "#2ab934";
          this.setState({
            data,
            isNumYrsEmplyIntRateMultGT10yrslist,
            isNumYrsEmplyIntRateMultGT10yrsclasslist,
          });
        } else {
          underWritingRules.isNumYrsEmplyIntRateMultGT10yrs = false;
          this.state.isNumYrsEmplyIntRateMultGT10yrslist[i] = false;
          this.state.isNumYrsEmplyIntRateMultGT10yrsclasslist[i] = "#6c757d";
          this.setState({
            data,
            isNumYrsEmplyIntRateMultGT10yrslist,
            isNumYrsEmplyIntRateMultGT10yrsclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsCurEmplyIntRateMultLess1yr() {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultLess1yrlist =
      this.state.isNumYrsCurEmplyIntRateMultLess1yrlist;
    let isNumYrsCurEmplyIntRateMultLess1yrclasslist =
      this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr) {
        underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr = true;
        this.state.isNumYrsCurEmplyIntRateMultLess1yrlist[i] = true;
        this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultLess1yrlist,
          isNumYrsCurEmplyIntRateMultLess1yrclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr) {
          underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr = true;
          this.state.isNumYrsCurEmplyIntRateMultLess1yrlist[i] = false;
          this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist[i] = "#2ab934";
          this.setState({
            data,
            isNumYrsCurEmplyIntRateMultLess1yrlist,
            isNumYrsCurEmplyIntRateMultLess1yrclasslist,
          });
        } else {
          underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr = false;
          this.state.isNumYrsCurEmplyIntRateMultLess1yrlist[i] = false;
          this.state.isNumYrsCurEmplyIntRateMultLess1yrclasslist[i] = "#6c757d";
          this.setState({
            data,
            isNumYrsCurEmplyIntRateMultLess1yrlist,
            isNumYrsCurEmplyIntRateMultLess1yrclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsCurCurEmplyIntRateMultBET1n3yrs() {
    let data = this.state.data;
    let isNumYrsCurCurEmplyIntRateMultBET1n3yrslist =
      this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist;
    let isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist =
      this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs) {
        underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = true;
        this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist[i] = true;
        this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist[i] =
          "#bcbcbb";
        this.setState({
          data,
          isNumYrsCurCurEmplyIntRateMultBET1n3yrslist,
          isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs) {
          underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = true;
          this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist[i] = false;
          this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist[i] =
            "#2ab934";
          this.setState({
            data,
            isNumYrsCurCurEmplyIntRateMultBET1n3yrslist,
            isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist,
          });
        } else {
          underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs = false;
          this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist[i] = false;
          this.state.isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist[i] =
            "#6c757d";
          this.setState({
            data,
            isNumYrsCurCurEmplyIntRateMultBET1n3yrslist,
            isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsCurEmplyIntRateMultBET3n5yrs() {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultBET3n5yrslist =
      this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist;
    let isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist =
      this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs) {
        underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs = true;
        this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist[i] = true;
        this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultBET3n5yrslist,
          isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs) {
          underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs = true;
          this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist[i] = false;
          this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist[i] =
            "#2ab934";
          this.setState({
            data,
            isNumYrsCurEmplyIntRateMultBET3n5yrslist,
            isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist,
          });
        } else {
          underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs = false;
          this.state.isNumYrsCurEmplyIntRateMultBET3n5yrslist[i] = false;
          this.state.isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist[i] =
            "#6c757d";
          this.setState({
            data,
            isNumYrsCurEmplyIntRateMultBET3n5yrslist,
            isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsCurEmplyIntRateMultBET5n10yrs() {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultBET5n10yrslist =
      this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist;
    let isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist =
      this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs) {
        underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs = true;
        this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist[i] = true;
        this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist[i] =
          "#bcbcbb";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultBET5n10yrslist,
          isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs) {
          underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs = true;
          this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist[i] = false;
          this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist[i] =
            "#2ab934";
          this.setState({
            data,
            isNumYrsCurEmplyIntRateMultBET5n10yrslist,
            isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist,
          });
        } else {
          underWritingRules.isNumYrsCurEmplyIntRateMultBET5n10yrs = false;
          this.state.isNumYrsCurEmplyIntRateMultBET5n10yrslist[i] = false;
          this.state.isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist[i] =
            "#6c757d";
          this.setState({
            data,
            isNumYrsCurEmplyIntRateMultBET5n10yrslist,
            isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisNumYrsCurEmplyIntRateMultGT10yrs() {
    let data = this.state.data;
    let isNumYrsCurEmplyIntRateMultGT10yrslist =
      this.state.isNumYrsCurEmplyIntRateMultGT10yrslist;
    let isNumYrsCurEmplyIntRateMultGT10yrsclasslist =
      this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs) {
        underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs = true;
        this.state.isNumYrsCurEmplyIntRateMultGT10yrslist[i] = true;
        this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isNumYrsCurEmplyIntRateMultGT10yrslist,
          isNumYrsCurEmplyIntRateMultGT10yrsclasslist,
        });
      } else {
        if ("A" == underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs) {
          underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs = true;
          this.state.isNumYrsCurEmplyIntRateMultGT10yrslist[i] = false;
          this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist[i] = "#2ab934";
          this.setState({
            data,
            isNumYrsCurEmplyIntRateMultGT10yrslist,
            isNumYrsCurEmplyIntRateMultGT10yrsclasslist,
          });
        } else {
          underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs = false;
          this.state.isNumYrsCurEmplyIntRateMultGT10yrslist[i] = false;
          this.state.isNumYrsCurEmplyIntRateMultGT10yrsclasslist[i] = "#6c757d";
          this.setState({
            data,
            isNumYrsCurEmplyIntRateMultGT10yrslist,
            isNumYrsCurEmplyIntRateMultGT10yrsclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisDeliquencyIntRateMultCurrent() {
    let data = this.state.data;
    let isDeliquencyIntRateMultCurrentlist =
      this.state.isDeliquencyIntRateMultCurrentlist;
    let isDeliquencyIntRateMultCurrentclasslist =
      this.state.isDeliquencyIntRateMultCurrentclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMultCurrent) {
        underWritingRules.isDeliquencyIntRateMultCurrent = true;
        this.state.isDeliquencyIntRateMultCurrentlist[i] = true;
        this.state.isDeliquencyIntRateMultCurrentclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isDeliquencyIntRateMultCurrentlist,
          isDeliquencyIntRateMultCurrentclasslist,
        });
      } else {
        if ("A" == underWritingRules.isDeliquencyIntRateMultCurrent) {
          underWritingRules.isDeliquencyIntRateMultCurrent = true;
          this.state.isDeliquencyIntRateMultCurrentlist[i] = false;
          this.state.isDeliquencyIntRateMultCurrentclasslist[i] = "#2ab934";
          this.setState({
            data,
            isDeliquencyIntRateMultCurrentlist,
            isDeliquencyIntRateMultCurrentclasslist,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMultCurrent = false;
          this.state.isDeliquencyIntRateMultCurrentlist[i] = false;
          this.state.isDeliquencyIntRateMultCurrentclasslist[i] = "#6c757d";
          this.setState({
            data,
            isDeliquencyIntRateMultCurrentlist,
            isDeliquencyIntRateMultCurrentclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisDeliquencyIntRateMultXDays() {
    let data = this.state.data;
    let isDeliquencyIntRateMultXDayslist =
      this.state.isDeliquencyIntRateMultXDayslist;
    let isDeliquencyIntRateMultXDaysclasslist =
      this.state.isDeliquencyIntRateMultXDaysclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMultXDays) {
        underWritingRules.isDeliquencyIntRateMultXDays = true;
        this.state.isDeliquencyIntRateMultXDayslist[i] = true;
        this.state.isDeliquencyIntRateMultXDaysclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isDeliquencyIntRateMultXDayslist,
          isDeliquencyIntRateMultXDaysclasslist,
        });
      } else {
        if ("A" == underWritingRules.isDeliquencyIntRateMultXDays) {
          underWritingRules.isDeliquencyIntRateMultXDays = true;
          this.state.isDeliquencyIntRateMultXDayslist[i] = false;
          this.state.isDeliquencyIntRateMultXDaysclasslist[i] = "#2ab934";
          this.setState({
            data,
            isDeliquencyIntRateMultXDayslist,
            isDeliquencyIntRateMultXDaysclasslist,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMultXDays = false;
          this.state.isDeliquencyIntRateMultXDayslist[i] = false;
          this.state.isDeliquencyIntRateMultXDaysclasslist[i] = "#6c757d";
          this.setState({
            data,
            isDeliquencyIntRateMultXDayslist,
            isDeliquencyIntRateMultXDaysclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisDeliquencyIntRateMult30DPD() {
    let data = this.state.data;
    let isDeliquencyIntRateMult30DPDlist =
      this.state.isDeliquencyIntRateMult30DPDlist;
    let isDeliquencyIntRateMult30DPDclasslist =
      this.state.isDeliquencyIntRateMult30DPDclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMult30DPD) {
        underWritingRules.isDeliquencyIntRateMult30DPD = true;
        this.state.isDeliquencyIntRateMult30DPDlist[i] = true;
        this.state.isDeliquencyIntRateMult30DPDclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isDeliquencyIntRateMult30DPDlist,
          isDeliquencyIntRateMult30DPDclasslist,
        });
      } else {
        if ("A" == underWritingRules.isDeliquencyIntRateMult30DPD) {
          underWritingRules.isDeliquencyIntRateMult30DPD = true;
          this.state.isDeliquencyIntRateMult30DPDlist[i] = false;
          this.state.isDeliquencyIntRateMult30DPDclasslist[i] = "#2ab934";
          this.setState({
            data,
            isDeliquencyIntRateMult30DPDlist,
            isDeliquencyIntRateMult30DPDclasslist,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMult30DPD = false;
          this.state.isDeliquencyIntRateMult30DPDlist[i] = false;
          this.state.isDeliquencyIntRateMult30DPDclasslist[i] = "#6c757d";
          this.setState({
            data,
            isDeliquencyIntRateMult30DPDlist,
            isDeliquencyIntRateMult30DPDclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisDeliquencyIntRateMult60DPD() {
    let data = this.state.data;
    let isDeliquencyIntRateMult60DPDlist =
      this.state.isDeliquencyIntRateMult60DPDlist;
    let isDeliquencyIntRateMult60DPDclasslist =
      this.state.isDeliquencyIntRateMult60DPDclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMult60DPD) {
        underWritingRules.isDeliquencyIntRateMult60DPD = true;
        this.state.isDeliquencyIntRateMult60DPDlist[i] = true;
        this.state.isDeliquencyIntRateMult60DPDclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isDeliquencyIntRateMult60DPDlist,
          isDeliquencyIntRateMult60DPDclasslist,
        });
      } else {
        if ("A" == underWritingRules.isDeliquencyIntRateMult60DPD) {
          underWritingRules.isDeliquencyIntRateMult60DPD = true;
          this.state.isDeliquencyIntRateMult60DPDlist[i] = false;
          this.state.isDeliquencyIntRateMult60DPDclasslist[i] = "#2ab934";
          this.setState({
            data,
            isDeliquencyIntRateMult60DPDlist,
            isDeliquencyIntRateMult60DPDclasslist,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMult60DPD = false;
          this.state.isDeliquencyIntRateMult60DPDlist[i] = false;
          this.state.isDeliquencyIntRateMult60DPDclasslist[i] = "#6c757d";
          this.setState({
            data,
            isDeliquencyIntRateMult60DPDlist,
            isDeliquencyIntRateMult60DPDclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisDeliquencyIntRateMultGT90DPD() {
    let data = this.state.data;
    let isDeliquencyIntRateMultGT90DPDlist =
      this.state.isDeliquencyIntRateMultGT90DPDlist;
    let isDeliquencyIntRateMultGT90DPDclasslist =
      this.state.isDeliquencyIntRateMultGT90DPDclasslist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isDeliquencyIntRateMultGT90DPD) {
        underWritingRules.isDeliquencyIntRateMultGT90DPD = true;
        this.state.isDeliquencyIntRateMultGT90DPDlist[i] = true;
        this.state.isDeliquencyIntRateMultGT90DPDclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isDeliquencyIntRateMultGT90DPDlist,
          isDeliquencyIntRateMultGT90DPDclasslist,
        });
      } else {
        if ("A" == underWritingRules.isDeliquencyIntRateMultGT90DPD) {
          underWritingRules.isDeliquencyIntRateMultGT90DPD = true;
          this.state.isDeliquencyIntRateMultGT90DPDlist[i] = false;
          this.state.isDeliquencyIntRateMultGT90DPDclasslist[i] = "#2ab934";
          this.setState({
            data,
            isDeliquencyIntRateMultGT90DPDlist,
            isDeliquencyIntRateMultGT90DPDclasslist,
          });
        } else {
          underWritingRules.isDeliquencyIntRateMultGT90DPD = false;
          this.state.isDeliquencyIntRateMultGT90DPDlist[i] = false;
          this.state.isDeliquencyIntRateMultGT90DPDclasslist[i] = "#6c757d";
          this.setState({
            data,
            isDeliquencyIntRateMultGT90DPDlist,
            isDeliquencyIntRateMultGT90DPDclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisBureauScoreIntRateMultGT900() {
    let data = this.state.data;
    let isBureauScoreIntRateMultGT900list =
      this.state.isBureauScoreIntRateMultGT900list;
    let isBureauScoreIntRateMultGT900classlist =
      this.state.isBureauScoreIntRateMultGT900classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultGT900) {
        underWritingRules.isBureauScoreIntRateMultGT900 = true;
        this.state.isBureauScoreIntRateMultGT900list[i] = true;
        this.state.isBureauScoreIntRateMultGT900classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isBureauScoreIntRateMultGT900list,
          isBureauScoreIntRateMultGT900classlist,
        });
      } else {
        if ("A" == underWritingRules.isBureauScoreIntRateMultGT900) {
          underWritingRules.isBureauScoreIntRateMultGT900 = true;
          this.state.isBureauScoreIntRateMultGT900list[i] = false;
          this.state.isBureauScoreIntRateMultGT900classlist[i] = "#2ab934";
          this.setState({
            data,
            isBureauScoreIntRateMultGT900list,
            isBureauScoreIntRateMultGT900classlist,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultGT900 = false;
          this.state.isBureauScoreIntRateMultGT900list[i] = false;
          this.state.isBureauScoreIntRateMultGT900classlist[i] = "#6c757d";
          this.setState({
            data,
            isBureauScoreIntRateMultGT900list,
            isBureauScoreIntRateMultGT900classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisBureauScoreIntRateMultBET800n900() {
    let data = this.state.data;
    let isBureauScoreIntRateMultBET800n900list =
      this.state.isBureauScoreIntRateMultBET800n900list;
    let isBureauScoreIntRateMultBET800n900classlist =
      this.state.isBureauScoreIntRateMultBET800n900classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultBET800n900) {
        underWritingRules.isBureauScoreIntRateMultBET800n900 = true;
        this.state.isBureauScoreIntRateMultBET800n900list[i] = true;
        this.state.isBureauScoreIntRateMultBET800n900classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isBureauScoreIntRateMultBET800n900list,
          isBureauScoreIntRateMultBET800n900classlist,
        });
      } else {
        if ("A" == underWritingRules.isBureauScoreIntRateMultBET800n900) {
          underWritingRules.isBureauScoreIntRateMultBET800n900 = true;
          this.state.isBureauScoreIntRateMultBET800n900list[i] = false;
          this.state.isBureauScoreIntRateMultBET800n900classlist[i] = "#2ab934";
          this.setState({
            data,
            isBureauScoreIntRateMultBET800n900list,
            isBureauScoreIntRateMultBET800n900classlist,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultBET800n900 = false;
          this.state.isBureauScoreIntRateMultBET800n900list[i] = false;
          this.state.isBureauScoreIntRateMultBET800n900classlist[i] = "#6c757d";
          this.setState({
            data,
            isBureauScoreIntRateMultBET800n900list,
            isBureauScoreIntRateMultBET800n900classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisBureauScoreIntRateMultBET700n800() {
    let data = this.state.data;
    let isBureauScoreIntRateMultBET700n800list =
      this.state.isBureauScoreIntRateMultBET700n800list;
    let isBureauScoreIntRateMultBET700n800classlist =
      this.state.isBureauScoreIntRateMultBET700n800classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultBET700n800) {
        underWritingRules.isBureauScoreIntRateMultBET700n800 = true;
        this.state.isBureauScoreIntRateMultBET700n800list[i] = true;
        this.state.isBureauScoreIntRateMultBET700n800classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isBureauScoreIntRateMultBET700n800list,
          isBureauScoreIntRateMultBET700n800classlist,
        });
      } else {
        if ("A" == underWritingRules.isBureauScoreIntRateMultBET700n800) {
          underWritingRules.isBureauScoreIntRateMultBET700n800 = true;
          this.state.isBureauScoreIntRateMultBET700n800list[i] = false;
          this.state.isBureauScoreIntRateMultBET700n800classlist[i] = "#2ab934";
          this.setState({
            data,
            isBureauScoreIntRateMultBET700n800list,
            isBureauScoreIntRateMultBET700n800classlist,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultBET700n800 = false;
          this.state.isBureauScoreIntRateMultBET700n800list[i] = false;
          this.state.isBureauScoreIntRateMultBET700n800classlist[i] = "#6c757d";
          this.setState({
            data,
            isBureauScoreIntRateMultBET700n800list,
            isBureauScoreIntRateMultBET700n800classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisBureauScoreIntRateMultBET600N700() {
    let data = this.state.data;
    let isBureauScoreIntRateMultBET600N700list =
      this.state.isBureauScoreIntRateMultBET600N700list;
    let isBureauScoreIntRateMultBET600N700classlist =
      this.state.isBureauScoreIntRateMultBET600N700classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultBET600N700) {
        underWritingRules.isBureauScoreIntRateMultBET600N700 = true;
        this.state.isBureauScoreIntRateMultBET600N700list[i] = true;
        this.state.isBureauScoreIntRateMultBET600N700classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isBureauScoreIntRateMultBET600N700list,
          isBureauScoreIntRateMultBET600N700classlist,
        });
      } else {
        if ("A" == underWritingRules.isBureauScoreIntRateMultBET600N700) {
          underWritingRules.isBureauScoreIntRateMultBET600N700 = true;
          this.state.isBureauScoreIntRateMultBET600N700list[i] = false;
          this.state.isBureauScoreIntRateMultBET600N700classlist[i] = "#2ab934";
          this.setState({
            data,
            isBureauScoreIntRateMultBET600N700list,
            isBureauScoreIntRateMultBET600N700classlist,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultBET600N700 = false;
          this.state.isBureauScoreIntRateMultBET600N700list[i] = false;
          this.state.isBureauScoreIntRateMultBET600N700classlist[i] = "#6c757d";
          this.setState({
            data,
            isBureauScoreIntRateMultBET600N700list,
            isBureauScoreIntRateMultBET600N700classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for XXXXXField toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisBureauScoreIntRateMultlt600() {
    let data = this.state.data;
    let isBureauScoreIntRateMultlt600list =
      this.state.isBureauScoreIntRateMultlt600list;
    let isBureauScoreIntRateMultlt600classlist =
      this.state.isBureauScoreIntRateMultlt600classlist;
    this.state.data.map((underWritingRules, i) => {
      if ("N" == underWritingRules.isBureauScoreIntRateMultlt600) {
        underWritingRules.isBureauScoreIntRateMultlt600 = true;
        this.state.isBureauScoreIntRateMultlt600list[i] = true;
        this.state.isBureauScoreIntRateMultlt600classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isBureauScoreIntRateMultlt600list,
          isBureauScoreIntRateMultlt600classlist,
        });
      } else {
        if ("A" == underWritingRules.isBureauScoreIntRateMultlt600) {
          underWritingRules.isBureauScoreIntRateMultlt600 = true;
          this.state.isBureauScoreIntRateMultlt600list[i] = false;
          this.state.isBureauScoreIntRateMultlt600classlist[i] = "#2ab934";
          this.setState({
            data,
            isBureauScoreIntRateMultlt600list,
            isBureauScoreIntRateMultlt600classlist,
          });
        } else {
          underWritingRules.isBureauScoreIntRateMultlt600 = false;
          this.state.isBureauScoreIntRateMultlt600list[i] = false;
          this.state.isBureauScoreIntRateMultlt600classlist[i] = "#6c757d";
          this.setState({
            data,
            isBureauScoreIntRateMultlt600list,
            isBureauScoreIntRateMultlt600classlist,
          });
        }
      }
    });
  }
  /**
   * Captures the boolean values for all status in underwriting rules.
   * @param {Event } e event on which the method is called
   */
  toggleChange(e) {
    let underWritingRulesstatus = this.state.underWritingRulesstatus;
    if ("catMultiStatus" == e.target.name) {
      this.state.underWritingRulesstatus[0].catMultiStatus =
        !this.state.underWritingRulesstatus[0].catMultiStatus;
    } else if ("avgSalTenorMultiplierStatus" == e.target.name) {
      this.state.underWritingRulesstatus[0].avgSalTenorMultiplierStatus =
        !this.state.underWritingRulesstatus[0].avgSalTenorMultiplierStatus;
    } else if ("avgSalIntRateMultStatus" == e.target.name) {
      this.state.underWritingRulesstatus[0].avgSalIntRateMultStatus =
        !this.state.underWritingRulesstatus[0].avgSalIntRateMultStatus;
    } else if ("bureauScoreIntRateMultltStatus" == e.target.name) {
      this.state.underWritingRulesstatus[0].bureauScoreIntRateMultltStatus =
        !this.state.underWritingRulesstatus[0].bureauScoreIntRateMultltStatus;
    } else if ("deliquencyIntRateMultStatus" == e.target.name) {
      this.state.underWritingRulesstatus[0].deliquencyIntRateMultStatus =
        !this.state.underWritingRulesstatus[0].deliquencyIntRateMultStatus;
    } else if ("numYrsCurEmplyIntRateMultStatus" == e.target.name) {
      this.state.underWritingRulesstatus[0].numYrsCurEmplyIntRateMultStatus =
        !this.state.underWritingRulesstatus[0].numYrsCurEmplyIntRateMultStatus;
    } else if ("numYrsEmplyIntRateMultStatus" == e.target.name) {
      this.state.underWritingRulesstatus[0].numYrsEmplyIntRateMultStatus =
        !this.state.underWritingRulesstatus[0].numYrsEmplyIntRateMultStatus;
    }
    this.setState({
      underWritingRulesstatus,
    });
  }
  toggleChangestatus(e) {
    let data = this.state.data;
    if ("approved" == e.target.name) {
      this.state.data[0].status = "approved";
      this.state.data[1].status = "approved";
      this.state.data[2].status = "approved";
      this.state.data[3].status = "approved";
      this.state.data[4].status = "approved";
      this.state.data[5].status = "approved";
      this.state.data[6].status = "approved";
      this.state.data[7].status = "approved";
    } else {
      this.state.data[0].status = "rejected";
      this.state.data[1].status = "rejected";
      this.state.data[2].status = "rejected";
      this.state.data[3].status = "rejected";
      this.state.data[4].status = "rejected";
      this.state.data[5].status = "rejected";
      this.state.data[6].status = "rejected";
      this.state.data[7].status = "rejected";
    }
  }
  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "CHECKER" == localStorage.getItem("role")
    ) {
      if (this.state.data.length > 0) {
        return (
          <div>
            <CommonHeader />

            <div id="wrapper">
              <Sidebar />
              {/* {(() => {
                if (this.state.editable) {
                  return (
                    <div className="errorMsg">
                      {" "}
                      <p className="toperror">
                        There are no Changes Request from Maker. A new request
                        can be submitted once Maker does the changes
                      </p>
                    </div>
                  );
                }
              })()} */}
              <div id="maincontent" className="content checkerWrittingRule">
                <section id="content-wrapper">
                  <div className="backloan">
                    <div className="container-fluid undercontainer">
                      <p className="signinheader">UNDER-WRITING RULES</p>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 underone checkunone">
                              <CheckerWrittingPart1
                                isDebtBurdenAllowPercentlist={
                                  this.state.isDebtBurdenAllowPercentlist
                                }
                                isInstantloanDisCaplist={
                                  this.state.isInstantloanDisCaplist
                                }
                                isLastSalarylist={this.state.isLastSalarylist}
                                isMaxAgelist={this.state.isMaxAgelist}
                                isMinLoanAmtlist={this.state.isMinLoanAmtlist}
                                isMinAgelist={this.state.isMinAgelist}
                                isMaxLoanAmtCaplist={
                                  this.state.isMaxLoanAmtCaplist
                                }
                                isDebtBurdenAllowPercentclasslist={
                                  this.state.isDebtBurdenAllowPercentclasslist
                                }
                                isInstantloanDisCapclasslist={
                                  this.state.isInstantloanDisCapclasslist
                                }
                                isLastSalaryclasslist={
                                  this.state.isLastSalaryclasslist
                                }
                                isMaxAgeclasslist={this.state.isMaxAgeclasslist}
                                isMinLoanAmtclasslist={
                                  this.state.isMinLoanAmtclasslist
                                }
                                isMinAgeclasslist={this.state.isMinAgeclasslist}
                                isMaxLoanAmtCapclasslist={
                                  this.state.isMaxLoanAmtCapclasslist
                                }
                                isDebtBurdenAllowPercentfieldclasslist={
                                  this.state
                                    .isDebtBurdenAllowPercentfieldclasslist
                                }
                                isInstantloanDisCapfieldclasslist={
                                  this.state.isInstantloanDisCapfieldclasslist
                                }
                                isLastSalaryfieldclasslist={
                                  this.state.isLastSalaryfieldclasslist
                                }
                                isMaxAgefieldclasslist={
                                  this.state.isMaxAgefieldclasslist
                                }
                                isMinLoanAmtfieldclasslist={
                                  this.state.isMinLoanAmtfieldclasslist
                                }
                                isMinAgefieldclasslist={
                                  this.state.isMinAgefieldclasslist
                                }
                                isMaxLoanAmtCapfieldclasslist={
                                  this.state.isMaxLoanAmtCapfieldclasslist
                                }
                                handleChange={(e) => this.handleChange(e)}
                                toggleChangeIsMinAge={(e, i) =>
                                  this.toggleChangeIsMinAge(e, i)
                                }
                                toggleChangeIsMaxAge={(e, i) =>
                                  this.toggleChangeIsMaxAge(e, i)
                                }
                                toggleChangeIsDebtBurdenAllowPercent={(e, i) =>
                                  this.toggleChangeIsDebtBurdenAllowPercent(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsMinLoanAmt={(e, i) =>
                                  this.toggleChangeIsMinLoanAmt(e, i)
                                }
                                toggleChangeIsMaxLoanAmtCap={(e, i) =>
                                  this.toggleChangeIsMaxLoanAmtCap(e, i)
                                }
                                toggleChangeIsInstantloanDisCap={(e, i) =>
                                  this.toggleChangeIsInstantloanDisCap(e, i)
                                }
                                toggleChangeIsLastSalary={(e, i) =>
                                  this.toggleChangeIsLastSalary(e, i)
                                }
                                data={this.state.data}
                              />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 undertwo">
                              <CheckerWrittingPart2
                                underWritingRulesstatus={
                                  this.state.underWritingRulesstatus
                                }
                                toggleChange={(e) => this.toggleChange(e)}
                                isCategoryAclasslist={
                                  this.state.isCategoryAclasslist
                                }
                                isCategoryBclasslist={
                                  this.state.isCategoryBclasslist
                                }
                                isCategoryCclasslist={
                                  this.state.isCategoryCclasslist
                                }
                                isCategoryDclasslist={
                                  this.state.isCategoryDclasslist
                                }
                                isCategoryEclasslist={
                                  this.state.isCategoryEclasslist
                                }
                                isCategoryElist={this.state.isCategoryElist}
                                isCategoryDlist={this.state.isCategoryDlist}
                                isCategoryClist={this.state.isCategoryClist}
                                isCategoryBlist={this.state.isCategoryBlist}
                                isCategoryAlist={this.state.isCategoryAlist}
                                isCategoryAfieldclasslist={
                                  this.state.isCategoryAfieldclasslist
                                }
                                isCategoryBfieldclasslist={
                                  this.state.isCategoryBfieldclasslist
                                }
                                isCategoryCfieldclasslist={
                                  this.state.isCategoryCfieldclasslist
                                }
                                isCategoryDfieldclasslist={
                                  this.state.isCategoryDfieldclasslist
                                }
                                isCategoryEfieldclasslist={
                                  this.state.isCategoryEfieldclasslist
                                }
                                handleChange={(e) => this.handleChange(e)}
                                toggleChangeIsCategoryD={(e, i) =>
                                  this.toggleChangeIsCategoryD(e, i)
                                }
                                toggleChangeIsCategoryE={(e, i) =>
                                  this.toggleChangeIsCategoryE(e, i)
                                }
                                toggleChangeIsCategoryC={(e, i) =>
                                  this.toggleChangeIsCategoryC(e, i)
                                }
                                toggleChangeIsCategoryB={(e, i) =>
                                  this.toggleChangeIsCategoryB(e, i)
                                }
                                toggleChangeIsCategoryA={(e, i) =>
                                  this.toggleChangeIsCategoryA(e, i)
                                }
                                data={this.state.data}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 underthreech">
                              <CheckerWrittingPart3
                                underWritingRulesstatus={
                                  this.state.underWritingRulesstatus
                                }
                                toggleChange={(e) => this.toggleChange(e)}
                                isAvgSalTenorBankStaffvaluelist={
                                  this.state.isAvgSalTenorBankStaffvaluelist
                                }
                                isAvgSalaryMultBET200000n500000list={
                                  this.state.isAvgSalaryMultBET200000n500000list
                                }
                                isAvgSalaryMultBET500001n1000000list={
                                  this.state
                                    .isAvgSalaryMultBET500001n1000000list
                                }
                                isAvgSalaryMultBET1000001n2000000list={
                                  this.state
                                    .isAvgSalaryMultBET1000001n2000000list
                                }
                                isAvgSalaryMultGT2000000list={
                                  this.state.isAvgSalaryMultGT2000000list
                                }
                                isAvgSalaryMultLT200000list={
                                  this.state.isAvgSalaryMultLT200000list
                                }
                                isAvgSalTenorBankStaffvalueclasslist={
                                  this.state
                                    .isAvgSalTenorBankStaffvalueclasslist
                                }
                                isAvgSalaryMultBET200000n500000classlist={
                                  this.state
                                    .isAvgSalaryMultBET200000n500000classlist
                                }
                                isAvgSalaryMultBET500001n1000000classlist={
                                  this.state
                                    .isAvgSalaryMultBET500001n1000000classlist
                                }
                                isAvgSalaryMultBET1000001n2000000classlist={
                                  this.state
                                    .isAvgSalaryMultBET1000001n2000000classlist
                                }
                                isAvgSalaryMultGT2000000classlist={
                                  this.state.isAvgSalaryMultGT2000000classlist
                                }
                                isAvgSalaryMultLT200000classlist={
                                  this.state.isAvgSalaryMultLT200000classlist
                                }
                                isAvgSalTenorBankStaffvaluefieldclasslist={
                                  this.state
                                    .isAvgSalTenorBankStaffvaluefieldclasslist
                                }
                                isAvgSalaryMultBET200000n500000fieldclasslist={
                                  this.state
                                    .isAvgSalaryMultBET200000n500000fieldclasslist
                                }
                                isAvgSalaryMultBET500001n1000000fieldclasslist={
                                  this.state
                                    .isAvgSalaryMultBET500001n1000000fieldclasslist
                                }
                                isAvgSalaryMultBET1000001n2000000fieldclasslist={
                                  this.state
                                    .isAvgSalaryMultBET1000001n2000000fieldclasslist
                                }
                                isAvgSalaryMultGT2000000fieldclasslist={
                                  this.state
                                    .isAvgSalaryMultGT2000000fieldclasslist
                                }
                                isAvgSalaryMultLT200000fieldclasslist={
                                  this.state
                                    .isAvgSalaryMultLT200000fieldclasslist
                                }
                                handleChange={(e) => this.handleChange(e)}
                                toggleChangeIsAvgSalaryMultLT200000={(e, i) =>
                                  this.toggleChangeIsAvgSalaryMultLT200000(e, i)
                                }
                                toggleChangeIsAvgSalaryMultBET200000n500000={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalaryMultBET200000n500000(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsAvgSalaryMultBET500001n1000000={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalaryMultBET500001n1000000(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsAvgSalaryMultBET1000001n2000000={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalaryMultBET1000001n2000000(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsAvgSalaryMultGT2000000={(e, i) =>
                                  this.toggleChangeIsAvgSalaryMultGT2000000(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsAvgSalTenorBankStaffvalue={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalTenorBankStaffvalue(
                                    e,
                                    i
                                  )
                                }
                                data={this.state.data}
                              />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 underfourch">
                              <CheckerWrittingPart4
                                underWritingRulesstatus={
                                  this.state.underWritingRulesstatus
                                }
                                toggleChange={(e) => this.toggleChange(e)}
                                isAvgSalIntRateMultBET500001n1000000classlist={
                                  this.state
                                    .isAvgSalIntRateMultBET500001n1000000classlist
                                }
                                isAvgSalIntRateMultBET1000001n2000000classlist={
                                  this.state
                                    .isAvgSalIntRateMultBET1000001n2000000classlist
                                }
                                isAvgSalIntRateMultBankstaffclasslist={
                                  this.state
                                    .isAvgSalIntRateMultBankstaffclasslist
                                }
                                isAvgSalIntRateMultGT2000000classlist={
                                  this.state
                                    .isAvgSalIntRateMultGT2000000classlist
                                }
                                isAvgSalIntRateMultLT200000classlist={
                                  this.state
                                    .isAvgSalIntRateMultLT200000classlist
                                }
                                isAvgSalIntRateMultBET200000n500000classlist={
                                  this.state
                                    .isAvgSalIntRateMultBET200000n500000classlist
                                }
                                isAvgSalIntRateMultBET500001n1000000fieldclasslist={
                                  this.state
                                    .isAvgSalIntRateMultBET500001n1000000fieldclasslist
                                }
                                isAvgSalIntRateMultBET1000001n2000000fieldclasslist={
                                  this.state
                                    .isAvgSalIntRateMultBET1000001n2000000fieldclasslist
                                }
                                isAvgSalIntRateMultBankstafffieldclasslist={
                                  this.state
                                    .isAvgSalIntRateMultBankstafffieldclasslist
                                }
                                isAvgSalIntRateMultGT2000000fieldclasslist={
                                  this.state
                                    .isAvgSalIntRateMultGT2000000fieldclasslist
                                }
                                isAvgSalIntRateMultLT200000fieldclasslist={
                                  this.state
                                    .isAvgSalIntRateMultLT200000fieldclasslist
                                }
                                isAvgSalIntRateMultBET200000n500000fieldclasslist={
                                  this.state
                                    .isAvgSalIntRateMultBET200000n500000fieldclasslist
                                }
                                isAvgSalIntRateMultBET500001n1000000list={
                                  this.state
                                    .isAvgSalIntRateMultBET500001n1000000list
                                }
                                isAvgSalIntRateMultBET1000001n2000000list={
                                  this.state
                                    .isAvgSalIntRateMultBET1000001n2000000list
                                }
                                isAvgSalIntRateMultBankstafflist={
                                  this.state.isAvgSalIntRateMultBankstafflist
                                }
                                isAvgSalIntRateMultGT2000000list={
                                  this.state.isAvgSalIntRateMultGT2000000list
                                }
                                isAvgSalIntRateMultLT200000list={
                                  this.state.isAvgSalIntRateMultLT200000list
                                }
                                isAvgSalIntRateMultBET200000n500000list={
                                  this.state
                                    .isAvgSalIntRateMultBET200000n500000list
                                }
                                handleChange={(e) => this.handleChange(e)}
                                toggleChangeIsAvgSalIntRateMultLT200000={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalIntRateMultLT200000(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsAvgSalIntRateMultBET200000n500000={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalIntRateMultBET200000n500000(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsAvgSalIntRateMultBET500001n1000000={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalIntRateMultBET500001n1000000(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsAvgSalIntRateMultBET1000001n2000000={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalIntRateMultBET1000001n2000000(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsAvgSalIntRateMultGT2000000={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalIntRateMultGT2000000(
                                    e,
                                    i
                                  )
                                }
                                toggleChangeIsAvgSalIntRateMultBankstaff={(
                                  e,
                                  i
                                ) =>
                                  this.toggleChangeIsAvgSalIntRateMultBankstaff(
                                    e,
                                    i
                                  )
                                }
                                data={this.state.data}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 ">
                          <CheckerWrittingPart5
                            underWritingRulesstatus={
                              this.state.underWritingRulesstatus
                            }
                            toggleChange={(e) => this.toggleChange(e)}
                            isDeliquencyIntRateMult30DPDlist={
                              this.state.isDeliquencyIntRateMult30DPDlist
                            }
                            isDeliquencyIntRateMultGT90DPDlist={
                              this.state.isDeliquencyIntRateMultGT90DPDlist
                            }
                            isDeliquencyIntRateMultCurrentlist={
                              this.state.isDeliquencyIntRateMultCurrentlist
                            }
                            isDeliquencyIntRateMult60DPDlist={
                              this.state.isDeliquencyIntRateMult60DPDlist
                            }
                            isDeliquencyIntRateMultXDayslist={
                              this.state.isDeliquencyIntRateMultXDayslist
                            }
                            isNumYrsEmplyIntMultRateMultBET1n3yrslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultBET1n3yrslist
                            }
                            isNumYrsEmplyIntMultRateMultBET5n10yrslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultBET5n10yrslist
                            }
                            isNumYrsEmplyIntMultRateMultBET3n5yrslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultBET3n5yrslist
                            }
                            isNumYrsEmplyIntMultRateMultLess1yrlist={
                              this.state.isNumYrsEmplyIntMultRateMultLess1yrlist
                            }
                            isNumYrsEmplyIntRateMultGT10yrslist={
                              this.state.isNumYrsEmplyIntRateMultGT10yrslist
                            }
                            isDeliquencyIntRateMult30DPDclasslist={
                              this.state.isDeliquencyIntRateMult30DPDclasslist
                            }
                            isDeliquencyIntRateMultGT90DPDclasslist={
                              this.state.isDeliquencyIntRateMultGT90DPDclasslist
                            }
                            isDeliquencyIntRateMultCurrentclasslist={
                              this.state.isDeliquencyIntRateMultCurrentclasslist
                            }
                            isDeliquencyIntRateMult60DPDclasslist={
                              this.state.isDeliquencyIntRateMult60DPDclasslist
                            }
                            isDeliquencyIntRateMultXDaysclasslist={
                              this.state.isDeliquencyIntRateMultXDaysclasslist
                            }
                            isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist
                            }
                            isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist
                            }
                            isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist
                            }
                            isNumYrsEmplyIntMultRateMultLess1yrclasslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultLess1yrclasslist
                            }
                            isNumYrsEmplyIntRateMultGT10yrsclasslist={
                              this.state
                                .isNumYrsEmplyIntRateMultGT10yrsclasslist
                            }
                            isDeliquencyIntRateMult30DPDfieldclasslist={
                              this.state
                                .isDeliquencyIntRateMult30DPDfieldclasslist
                            }
                            isDeliquencyIntRateMultGT90DPDfieldclasslist={
                              this.state
                                .isDeliquencyIntRateMultGT90DPDfieldclasslist
                            }
                            isDeliquencyIntRateMultCurrentfieldclasslist={
                              this.state
                                .isDeliquencyIntRateMultCurrentfieldclasslist
                            }
                            isDeliquencyIntRateMult60DPDfieldclasslist={
                              this.state
                                .isDeliquencyIntRateMult60DPDfieldclasslist
                            }
                            isDeliquencyIntRateMultXDaysfieldclasslist={
                              this.state
                                .isDeliquencyIntRateMultXDaysfieldclasslist
                            }
                            isNumYrsEmplyIntMultRateMultBET1n3yrsfieldclasslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultBET1n3yrsfieldclasslist
                            }
                            isNumYrsEmplyIntMultRateMultBET5n10yrsfieldclasslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultBET5n10yrsfieldclasslist
                            }
                            isNumYrsEmplyIntMultRateMultBET3n5yrsfieldclasslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultBET3n5yrsfieldclasslist
                            }
                            isNumYrsEmplyIntMultRateMultLess1yrfieldclasslist={
                              this.state
                                .isNumYrsEmplyIntMultRateMultLess1yrfieldclasslist
                            }
                            isNumYrsEmplyIntRateMultGT10yrsfieldclasslist={
                              this.state
                                .isNumYrsEmplyIntRateMultGT10yrsfieldclasslist
                            }
                            handleChange={(e) => this.handleChange(e)}
                            toggleChangeIsNumYrsEmplyIntMultRateMultLess1yr={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsNumYrsEmplyIntMultRateMultLess1yr(
                                e,
                                i
                              )
                            }
                            toggleChangeIsNumYrsEmplyIntMultRateMultBET1n3yrs={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsNumYrsEmplyIntMultRateMultBET1n3yrs(
                                e,
                                i
                              )
                            }
                            toggleChangeIsNumYrsEmplyIntMultRateMultBET3n5yrs={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsNumYrsEmplyIntMultRateMultBET3n5yrs(
                                e,
                                i
                              )
                            }
                            toggleChangeIsNumYrsEmplyIntMultRateMultBET5n10yrs={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsNumYrsEmplyIntMultRateMultBET5n10yrs(
                                e,
                                i
                              )
                            }
                            toggleChangeIsNumYrsEmplyIntRateMultGT10yrs={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsNumYrsEmplyIntRateMultGT10yrs(
                                e,
                                i
                              )
                            }
                            toggleChangeIsDeliquencyIntRateMultCurrent={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsDeliquencyIntRateMultCurrent(
                                e,
                                i
                              )
                            }
                            toggleChangeIsDeliquencyIntRateMultXDays={(e, i) =>
                              this.toggleChangeIsDeliquencyIntRateMultXDays(
                                e,
                                i
                              )
                            }
                            toggleChangeIsDeliquencyIntRateMult30DPD={(e, i) =>
                              this.toggleChangeIsDeliquencyIntRateMult30DPD(
                                e,
                                i
                              )
                            }
                            toggleChangeIsDeliquencyIntRateMult60DPD={(e, i) =>
                              this.toggleChangeIsDeliquencyIntRateMult60DPD(
                                e,
                                i
                              )
                            }
                            toggleChangeIsDeliquencyIntRateMultGT90DPD={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsDeliquencyIntRateMultGT90DPD(
                                e,
                                i
                              )
                            }
                            data={this.state.data}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 ">
                          <CheckerWrittingPart6
                            underWritingRulesstatus={
                              this.state.underWritingRulesstatus
                            }
                            toggleChange={(e) => this.toggleChange(e)}
                            buttoncolor={this.state.buttoncolor}
                            isBureauScoreIntRateMultBET600N700list={
                              this.state.isBureauScoreIntRateMultBET600N700list
                            }
                            isBureauScoreIntRateMultBET700n800list={
                              this.state.isBureauScoreIntRateMultBET700n800list
                            }
                            isBureauScoreIntRateMultBET800n900list={
                              this.state.isBureauScoreIntRateMultBET800n900list
                            }
                            isBureauScoreIntRateMultGT900list={
                              this.state.isBureauScoreIntRateMultGT900list
                            }
                            isBureauScoreIntRateMultlt600list={
                              this.state.isBureauScoreIntRateMultlt600list
                            }
                            isNumYrsCurCurEmplyIntRateMultBET1n3yrslist={
                              this.state
                                .isNumYrsCurCurEmplyIntRateMultBET1n3yrslist
                            }
                            isNumYrsCurEmplyIntRateMultBET3n5yrslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultBET3n5yrslist
                            }
                            isNumYrsCurEmplyIntRateMultBET5n10yrslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultBET5n10yrslist
                            }
                            isNumYrsCurEmplyIntRateMultGT10yrslist={
                              this.state.isNumYrsCurEmplyIntRateMultGT10yrslist
                            }
                            isNumYrsCurEmplyIntRateMultLess1yrlist={
                              this.state.isNumYrsCurEmplyIntRateMultLess1yrlist
                            }
                            isBureauScoreIntRateMultBET600N700classlist={
                              this.state
                                .isBureauScoreIntRateMultBET600N700classlist
                            }
                            isBureauScoreIntRateMultBET700n800classlist={
                              this.state
                                .isBureauScoreIntRateMultBET700n800classlist
                            }
                            isBureauScoreIntRateMultBET800n900classlist={
                              this.state
                                .isBureauScoreIntRateMultBET800n900classlist
                            }
                            isBureauScoreIntRateMultGT900classlist={
                              this.state.isBureauScoreIntRateMultGT900classlist
                            }
                            isBureauScoreIntRateMultlt600classlist={
                              this.state.isBureauScoreIntRateMultlt600classlist
                            }
                            isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist={
                              this.state
                                .isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist
                            }
                            isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist
                            }
                            isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultBET5n10yrsclasslist
                            }
                            isNumYrsCurEmplyIntRateMultGT10yrsclasslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultGT10yrsclasslist
                            }
                            isNumYrsCurEmplyIntRateMultLess1yrclasslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultLess1yrclasslist
                            }
                            isBureauScoreIntRateMultBET600N700fieldclasslist={
                              this.state
                                .isBureauScoreIntRateMultBET600N700fieldclasslist
                            }
                            isBureauScoreIntRateMultBET700n800fieldclasslist={
                              this.state
                                .isBureauScoreIntRateMultBET700n800fieldclasslist
                            }
                            isBureauScoreIntRateMultBET800n900fieldclasslist={
                              this.state
                                .isBureauScoreIntRateMultBET800n900fieldclasslist
                            }
                            isBureauScoreIntRateMultGT900fieldclasslist={
                              this.state
                                .isBureauScoreIntRateMultGT900fieldclasslist
                            }
                            isBureauScoreIntRateMultlt600fieldclasslist={
                              this.state
                                .isBureauScoreIntRateMultlt600fieldclasslist
                            }
                            isNumYrsCurCurEmplyIntRateMultBET1n3yrsfieldclasslist={
                              this.state
                                .isNumYrsCurCurEmplyIntRateMultBET1n3yrsfieldclasslist
                            }
                            isNumYrsCurEmplyIntRateMultBET3n5yrsfieldclasslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultBET3n5yrsfieldclasslist
                            }
                            isNumYrsCurEmplyIntRateMultBET5n10yrsfieldclasslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultBET5n10yrsfieldclasslist
                            }
                            isNumYrsCurEmplyIntRateMultGT10yrsfieldclasslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultGT10yrsfieldclasslist
                            }
                            isNumYrsCurEmplyIntRateMultLess1yrfieldclasslist={
                              this.state
                                .isNumYrsCurEmplyIntRateMultLess1yrfieldclasslist
                            }
                            toggleChangeIsNumYrsCurEmplyIntRateMultGT10yrs={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsNumYrsCurEmplyIntRateMultGT10yrs(
                                e,
                                i
                              )
                            }
                            toggleChangeIsNumYrsCurEmplyIntRateMultBET3n5yrs={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsNumYrsCurEmplyIntRateMultBET3n5yrs(
                                e,
                                i
                              )
                            }
                            toggleChangeIsNumYrsCurCurEmplyIntRateMultBET1n3yrs={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsNumYrsCurCurEmplyIntRateMultBET1n3yrs(
                                e,
                                i
                              )
                            }
                            toggleChangeIsNumYrsCurEmplyIntRateMultLess1yr={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsNumYrsCurEmplyIntRateMultLess1yr(
                                e,
                                i
                              )
                            }
                            data={this.state.data}
                            handlestatus={(status) => this.setStatus(status)}
                            toggleChangeIsBureauScoreIntRateMultlt600={(e, i) =>
                              this.toggleChangeIsBureauScoreIntRateMultlt600(
                                e,
                                i
                              )
                            }
                            toggleChangeIsBureauScoreIntRateMultBET600N700={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsBureauScoreIntRateMultBET600N700(
                                e,
                                i
                              )
                            }
                            toggleChangeIsBureauScoreIntRateMultBET700n800={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsBureauScoreIntRateMultBET700n800(
                                e,
                                i
                              )
                            }
                            toggleChangeIsBureauScoreIntRateMultBET800n900={(
                              e,
                              i
                            ) =>
                              this.toggleChangeIsBureauScoreIntRateMultBET800n900(
                                e,
                                i
                              )
                            }
                            toggleChangeIsBureauScoreIntRateMultGT900={(e, i) =>
                              this.toggleChangeIsBureauScoreIntRateMultGT900(
                                e,
                                i
                              )
                            }
                            isDisabled={this.state.isDisabled}
                            toggleChangestatus={(e) =>
                              this.toggleChangestatus(e)
                            }
                            editable={this.state.editable}
                            handleSubmit={(e) => this.handleSubmit(e)}
                            isDisabledSubmitButton={isDisabledSubmitButton}
                            message={this.state.message}
                            errors={this.state.errors}
                            handleChange={(e) => this.handleChange(e)}
                            errorMsg={this.state.errorMsg}
                            loader={this.state.loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        );
      } else {
        return <p>No Data Found</p>;
      }
    } else {
      return <p>Please Login AS AdminChecker</p>;
    }
  }
}
let isDisabledSubmitButton = false;
export default CheckerWrittingRules;
