import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import personalhero1 from "../../../assets/images/personalhero1.png";
import personalhero2 from "../../../assets/images/personalhero2.png";
import dashboard from "../../../assets/images/dashboardnew.webp";
import "./style.css";
const Hero = () => {
  return (
    <>
      <div className="personal-overview-hero personal-hero">
        <div className="row personalb">
          <div className="col-md-6 personal-hero-left personalherocontent">
            <h1 className="personal-overview-hero-h1">
              Powering{" "}
              <span className="personal-overview-hero-h1-span">
                Digital Payments{" "}
                <span className="personal-overview-hero-h1-span andfont">
                  &
                </span>{" "}
              </span>
              <span className="personal-overview-hero-h1-span">Lending</span>{" "}
              for Everyone
            </h1>
            <p className="personal-hero-p">
              Marketplaces for multiparty payments & Lending Products.
            </p>
            <div className="app-download personal-hero-app-download-image">
              <img src={appstore} alt="appstore" />
              <img src={playstore} alt="playstore" />
            </div>
          </div>
          <div className="col-md-6 personal-hero-right personalheroimg">
            <img src={dashboard} alt="dashboard" className="PersonalheroImg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
