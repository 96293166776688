import React from 'react'
import Navbar from '../../Navbar/Businessnav'
import Footer from '../../Footer/Productfooter'
import Hero from './Hero'
import Body from './Body'
import './style.css'

const Main = () => {
  return (
    <>
      <div id="Banking">
        <Navbar />
        <Hero />
        <Body />
        <div className="busfooter">
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Main