import React from 'react'
import Navbar from '../../../Navbar/Solution'
import Footer from '../../../Footer/Solfooter'
import Hero from './Hero'
import './style.css';
import Body from './Body';

const Main = () => {
  return (
    <>
      <div id="DTS">
        <Navbar />
        <Hero />
        <Body />
        <div className='dtsfooter'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Main