import React from "react";
import ForgotPin from "../../ForgotPin/ForgotPin";
import Loader from '../Loader';

/**
 *   CheckerWrittingPart6 component is render upper part of underwriting rules(No. of years with current employment - Interest Rate Multiplier and Bureau Score- Interest Rate Multiplier details) of bank for checker section.
 *   <p>Allows checker to approve or reject by binding </p>
 *  @Author : Thrilok Kandra
 *  @version :1.0
 */

class CheckerWrittingPart6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      dataarray: [],
      status: "",
      isDisabled: false,
      message: "",
      errors: [],
    };
  }

  componentDidMount() {
    console.log("disable", this.props.isDisabledSubmitButton)
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  render() {
    console.log("props from checkwritting part6", this.props);
    return (
      <div>
        <form method="post" name="userRegistrationForm" className="formroot">
          <div className="unwrite">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 groupfour ">
                <div className="d-flex flex-row mt-3 ">
                  <div className="p-2 headerbuttons">
                    <div className="input-group Multiplier2">
                      <p className="text2part">
                        Multiplier <br /> Status
                      </p>
                      <div className="form-group aligncenter inputgraygl">
                        <label
                          type="text"
                          className="form-control formpprofilecuw"
                        >
                          {this.props.data[0]?.numYrsCurEmplyIntRateMultStatus}{" "}
                        </label>
                        <label className="switchtoggle">
                          <input
                            type="checkbox"
                            name="numYrsCurEmplyIntRateMultStatus"
                            checked={
                              this.props?.underWritingRulesstatus[0]
                                .numYrsCurEmplyIntRateMultStatus
                            }
                            onChange={(e) => this.props.toggleChange(e)}
                          />
                          <div className="checkerslider round"></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="p-2 headertext">
                    <p className="textposition">
                      No. of years with current employment - Interest Rate
                      Multiplier
                    </p>
                    <p className="textlabel">No. of Years</p>
                  </div>
                </div>

                <div className="d-flex flex-row mt-3 padmar">
                  <div className="p-2 underwritepart6">
                    <div className="form-group">
                      <label className="labelpt-ch">Product</label>
                      {this.props.data.map((underWritingRules, i) => (
                        <div key={i}>
                          <label
                            className="form-control loanlabelcheck"
                            name="paydayloan"
                            id="field1"
                            disabled
                          >
                            {" "}
                            {underWritingRules.productName}{" "}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        {"<"}1 Year
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isNumYrsCurEmplyIntRateMultLess1yrfieldclasslist[
                              i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="numYrsCurEmplyIntRateMultLess1yrsa"
                            onChange={this.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.numYrsCurEmplyIntRateMultLess1yr
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isNumYrsCurEmplyIntRateMultLess1yr"
                              onChange={(e) => {
                                this.props.toggleChangeIsNumYrsCurEmplyIntRateMultLess1yr(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isNumYrsCurEmplyIntRateMultLess1yr
                              }
                              disabled={
                                this.props
                                  .isNumYrsCurEmplyIntRateMultLess1yrlist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isNumYrsCurEmplyIntRateMultLess1yrclasslist[
                                  i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        1 – 3 Years
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isNumYrsCurCurEmplyIntRateMultBET1n3yrsfieldclasslist[
                              i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="numYrsCurCurEmplyIntRateMultBET1n3yrssa"
                            onChange={this.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.numYrsCurCurEmplyIntRateMultBET1n3yrs
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isNumYrsCurCurEmplyIntRateMultBET1n3yrs"
                              onChange={(e) => {
                                this.props.toggleChangeIsNumYrsCurCurEmplyIntRateMultBET1n3yrs(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isNumYrsCurCurEmplyIntRateMultBET1n3yrs
                              }
                              disabled={
                                this.props
                                  .isNumYrsCurCurEmplyIntRateMultBET1n3yrslist[
                                i
                                ]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isNumYrsCurCurEmplyIntRateMultBET1n3yrsclasslist[
                                  i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        3 - 5 Years
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isNumYrsCurEmplyIntRateMultBET3n5yrsfieldclasslist[
                              i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="numYrsCurEmplyIntRateMultBET3n5yrssa"
                            onChange={this.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.numYrsCurEmplyIntRateMultBET3n5yrs
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isNumYrsCurEmplyIntRateMultBET3n5yrs"
                              onChange={(e) => {
                                this.props.toggleChangeIsNumYrsCurEmplyIntRateMultBET3n5yrs(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isNumYrsCurEmplyIntRateMultBET3n5yrs
                              }
                              disabled={
                                this.props
                                  .isNumYrsCurEmplyIntRateMultBET3n5yrslist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isNumYrsCurEmplyIntRateMultBET3n5yrsclasslist[
                                  i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        {">"} 5 Years
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isNumYrsCurEmplyIntRateMultGT10yrsfieldclasslist[
                              i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="numYrsCurEmplyIntRateMultGT10yrssa"
                            onChange={this.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.numYrsCurEmplyIntRateMultGT10yrs
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isNumYrsCurEmplyIntRateMultGT10yrs"
                              onChange={(e) => {
                                this.props.toggleChangeIsNumYrsCurEmplyIntRateMultGT10yrs(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isNumYrsCurEmplyIntRateMultGT10yrs
                              }
                              disabled={
                                this.props
                                  .isNumYrsCurEmplyIntRateMultGT10yrslist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isNumYrsCurEmplyIntRateMultGT10yrsclasslist[
                                  i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 groupfour ">
                <div className="d-flex flex-row mt-3 ">
                  <div className="p-2 headerbuttons">
                    <div className="input-group Multiplier2">
                      <p className="text2part">
                        Multiplier <br /> Status
                      </p>
                      <div className="form-group aligncenter inputgraygl">
                        <label
                          type="text"
                          className="form-control formpprofilecuw"
                        >
                          {this.props.data[0]?.bureauScoreIntRateMultltStatus}
                        </label>
                        <label className="switchtoggle">
                          <input
                            type="checkbox"
                            name="bureauScoreIntRateMultltStatus"
                            checked={
                              this.props.underWritingRulesstatus[0]
                                ?.bureauScoreIntRateMultltStatus
                            }
                            onChange={(e) => this.props.toggleChange(e)}
                          />
                          <div className="checkerslider round"></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="p-2 headertext">
                    <p className="textposition">
                      Bureau Score- Interest Rate Multiplier
                    </p>
                    <p className="textlabel">Bureau Score</p>
                  </div>
                </div>

                <div className="d-flex flex-row mt-3 padmar">
                  <div className="p-2 underwritepart6">
                    <div className="form-group">
                      <label className="labelpt-ch">Product</label>
                      {this.props.data.map((underWritingRules, i) => (
                        <div key={i}>
                          <label
                            className="form-control loanlabelcheck"
                            name="paydayloan"
                            id="field1"
                            disabled
                          >
                            {" "}
                            {underWritingRules.productName}{" "}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        {">"} 900
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isBureauScoreIntRateMultGT900fieldclasslist[i],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="bureauScoreIntRateMultGT900sa"
                            onChange={this.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {underWritingRules.bureauScoreIntRateMultGT900}{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isBureauScoreIntRateMultGT900"
                              onChange={(e) => {
                                this.props.toggleChangeIsBureauScoreIntRateMultGT900(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isBureauScoreIntRateMultGT900
                              }
                              disabled={
                                this.props.isBureauScoreIntRateMultGT900list[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isBureauScoreIntRateMultGT900classlist[i],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        800 - 900{" "}
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isBureauScoreIntRateMultBET800n900fieldclasslist[
                              i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="bureauScoreIntRateMultBET800n900sa"
                            onChange={this.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.bureauScoreIntRateMultBET800n900
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isBureauScoreIntRateMultBET800n900sa"
                              onChange={(e) => {
                                this.props.toggleChangeIsBureauScoreIntRateMultBET800n900(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isBureauScoreIntRateMultBET800n900
                              }
                              disabled={
                                this.props
                                  .isBureauScoreIntRateMultBET800n900list[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isBureauScoreIntRateMultBET800n900classlist[
                                  i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        700 - 800{" "}
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isBureauScoreIntRateMultBET700n800fieldclasslist[
                              i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="bureauScoreIntRateMultBET700n800sa"
                            onChange={this.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.bureauScoreIntRateMultBET700n800
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isBureauScoreIntRateMultBET700n800"
                              onChange={(e) => {
                                this.props.toggleChangeIsBureauScoreIntRateMultBET700n800(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isBureauScoreIntRateMultBET700n800
                              }
                              disabled={
                                this.props
                                  .isBureauScoreIntRateMultBET700n800list[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isBureauScoreIntRateMultBET700n800classlist[
                                  i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        600 - 700{" "}
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isBureauScoreIntRateMultBET600N700fieldclasslist[
                              i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="bureauScoreIntRateMultBET600N700sa"
                            onChange={this.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.bureauScoreIntRateMultBET600N700
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isBureauScoreIntRateMultBET600N700"
                              onChange={(e) => {
                                this.props.toggleChangeIsBureauScoreIntRateMultBET600N700(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isBureauScoreIntRateMultBET600N700
                              }
                              disabled={
                                this.props
                                  .isBureauScoreIntRateMultBET600N700list[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isBureauScoreIntRateMultBET600N700classlist[
                                  i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        {"< "} 600
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isBureauScoreIntRateMultlt600fieldclasslist[i],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="bureauScoreIntRateMultlt600sa"
                            onChange={this.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {underWritingRules.bureauScoreIntRateMultlt600}{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isBureauScoreIntRateMultlt600"
                              onChange={(e) => {
                                this.props.toggleChangeIsBureauScoreIntRateMultlt600(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isBureauScoreIntRateMultlt600
                              }
                              disabled={
                                this.props.isBureauScoreIntRateMultlt600list[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isBureauScoreIntRateMultlt600classlist[i],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {this.props.loader ? (
                  <div>
                    <Loader />
                    <div className="cardformuw">
                      <div className="form-group">
                        <label>Type Your User PIN </label>
                        <input
                          type="password"
                          className="form-control cardinputfield"
                          name="pin"
                          value={this.state.fields.message}
                          onChange={(e) => this.props.handleChange(e)}
                          placeholder="xxxxx "
                        />
                        <div className="sign">
                          <input
                            type="submit"
                            className="btn btn-warning cardbutton"
                            style={{ backgroundColor: this.props.buttoncolor }}
                            onClick={(e) => this.props.handleSubmit(e)}
                            value="Submit changes for approval"
                            disabled={this.props.isDisabledSubmitButton}
                          />
                        </div>
                      </div>
                      <ForgotPin />
                    </div>
                  </div>
                ) : (
                  <div className="cardformuw">
                    <div className="form-group">
                      <label>Type Your User PIN </label>
                      <input
                        type="password"
                        className="form-control cardinputfield"
                        name="pin"
                        value={this.state.fields.message}
                        onChange={(e) => this.props.handleChange(e)}
                        placeholder="xxxxx "
                      />
                      <div className="sign">
                        <input
                          type="submit"
                          className="btn btn-warning cardbutton"
                          style={{ backgroundColor: this.props.buttoncolor }}
                          onClick={(e) => this.props.handleSubmit(e)}
                          value="Submit changes for approval"
                          disabled={this.props.isDisabledSubmitButton}
                        />
                      </div>
                    </div>
                    <ForgotPin />
                  </div>
                )}

                <div className="errorMsgpin">{this.props.errors.pin}</div>
                {(() => {
                  switch (this.props.message) {
                    case "SUCCESS":
                      return (
                        <p className="submittedpin">Submitted Succesfully</p>
                      );
                    default:
                      return <div></div>;
                  }
                })()}
                {(() => {
                  switch (this.props.message) {
                    case "UNSUCCESS":
                      return (
                        <div className="errorMsgpin">
                          {this.props.errorMsg}
                        </div>
                      );
                    default:
                      return <div></div>;
                  }
                })()}
                <div className="gridbuttonchecker">
                  <div className="form-group card1">
                    <label className="cardtext">Approved </label>
                    <br />
                    <img
                      className="toogle-img"
                      src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle1.png?alt=media&token=a825c329-274e-4a1c-831d-554cd94b2c88"
                      alt="Approved"
                    />
                  </div>
                  <div className="form-group card1">
                    <label className="cardtext">Rejected </label>
                    <br />
                    <img
                      className="toogle-img"
                      src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle2.png?alt=media&token=2a0da633-8a16-4e7b-9140-71afbb43e86e"
                      alt="Rejected"
                    />
                  </div>

                  <div className="form-group  card1">
                    <label className="cardtext">
                      No changes requested for approval{" "}
                    </label>
                    <br />
                    <div className="img-toole">
                      <img
                        className="toogle-img2"
                        src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle3.png?alt=media&token=0fe3a799-1b79-4d79-87bb-68f4459f2b70"
                        alt=" No changes requested for approval"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CheckerWrittingPart6;
