import React from "react";
// import verified1 from "../../../../assets/images/verified1.svg";
import verified2 from "../../../../assets/images/verified2.webp";
import AuthProcessContainer from "./AuthProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="auth-app">
        <div className="row">
          <div className="col softtokenheader">
            <h1>
              Soft Token <div>158 107</div>
            </h1>
            <p>
              With Verifyed Authenticator, turn on 2-Step Verification. When you
              enable 2-Step Verification (also known as two-factor
              authentication), you add an extra layer of security to your
              account.
            </p>

            {/* <img className="auth-app-img" src={verified1} alt="image" /> */}
            <AuthProcessContainer hometopDescription="home-verified-auth-topdescription" />
            <div>
              <button className="verified-btn1">Contact Sales</button>
            </div>
          </div>
          <div className="col auth-app-img-2">
            <img src={verified2} alt=" Soft Token " />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
