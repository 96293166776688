import React from "react";
import faceauth1 from "../../../../assets/images/faceauth1.webp";
import FaceProcessContainer from "./FaceProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="face-auth-hero">
        <div className="row face-auth-row ">
          <div className="col verfaceauth">
            <h1>
              Digital KYC <span className="andfont">&</span> ID Verification{" "}
            </h1>
            <div className="duo-list-container duo-dwp verdwp">
              <div className="duo-lists">
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Confirm Liveness before you save profile picture of your
                      users.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Match Liveness verified users with their photo ID for KYC
                      & ID verification.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Onboard use cases for identifying the user for continued
                      access.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <img className="face-auth-img2" src={faceauth2} alt="" /> */}
            <FaceProcessContainer />
            <div className="face-auth-button">
              <button>Contact Sales</button>
            </div>
          </div>
          <div className="col faceauth">
            <img src={faceauth1} alt=" Digital KYC" className="faceauthvery" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
