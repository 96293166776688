import React from "react";

function PG3() {
  return (
    <svg
      width="92"
      height="66"
      viewBox="0 0 92 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        pathLength="1"
        d="M1 66V16.0517C1.48403 9.10485 3.22655 1 14.0689 1C24.9112 1 62.1624 1 79.4327 1C89.0078 1 91.8005 1 92 1"
        stroke="url(#paint0_radial_4799_7102)"
        stroke-width="2"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4799_7102"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.92937 33.6244) rotate(-90) scale(32.6156 4.92937)"
        >
          <stop stop-color="#0048E5" />
          <stop offset="1" stop-color="#7A73FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default PG3;
