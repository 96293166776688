import React from "react";

function LoanAuthorization() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#20336B"
        d="M21.5 6h-3a.5.5 0 010-1H21V2.5a.5.5 0 011 0v3a.5.5 0 01-.5.5z"
      ></path>
      <path
        fill="#20336B"
        d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0c3.8 0 7.409 1.83 9.653 4.895a.5.5 0 01-.807.591A11 11 0 0012 1C5.935 1 1 5.935 1 12s4.935 11 11 11 11-4.935 11-11c0-.516-.031-.983-.095-1.429a.501.501 0 01.99-.142c.071.493.105 1.007.105 1.571 0 6.617-5.383 12-12 12z"
      ></path>
      <path
        fill="#24BAF2"
        d="M12 20a.487.487 0 01-.194-.04C11.568 19.861 6 17.454 6 12.536V8.357c0-.214.137-.405.34-.474l5.5-1.857a.5.5 0 01.32 0l5.5 1.857c.203.07.34.26.34.474v4.18c0 4.917-5.568 7.324-5.806 7.424a.503.503 0 01-.194.04zM7 8.716v3.82c0 3.785 4.055 5.958 5 6.414.944-.457 5-2.638 5-6.414v-3.82l-5-1.688-5 1.688z"
      ></path>
      <path
        fill="#20336B"
        d="M11.499 15h-.028a.502.502 0 01-.363-.188l-2-2.5a.501.501 0 01.78-.625l1.652 2.064 3.105-3.105a.5.5 0 01.707.707l-3.5 3.5a.497.497 0 01-.353.147z"
      ></path>
    </svg>
  );
}

export default LoanAuthorization;
