import React, { useEffect, useState } from "react";
import dlm1 from "../../../../assets/images/dlm1.svg";
import "./style.css";
import ppl1 from "../../../../assets/images/ppl1.png";
import ppl2 from "../../../../assets/images/ppl2.png";
import ppl3 from "../../../../assets/images/ppl3.png";
import ppl4 from "../../../../assets/images/ppl4.png";
import ppl5 from "../../../../assets/images/ppl5.png";
import ppl6 from "../../../../assets/images/ppl6.png";
import DlmGif from "../../../../assets/images/uday/gifs/dlm.gif";

const Hero = () => {
  const [showDuo2, setShowDuo2] = useState(true);
  const [showMagnet, setShowMagnet] = useState(false);
  const [showPeople, setShowPeople] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const peopleImages = [ppl1, ppl2, ppl3, ppl4, ppl5, ppl6];

  useEffect(() => {
    const showImages = (index) => {
      setShowPeople((prevState) => {
        const nextState = [...prevState];
        nextState[index] = true;
        return nextState;
      });
      if (index < 5) {
        setTimeout(() => showImages(index + 1), 1000); // Adjust the delay between each image appearance
      } else {
        setTimeout(() => {
          setShowPeople([false, false, false, false, false, false]); // Reset the showPeople state
          setTimeout(() => showImages(0), 2000); // Start the animation loop again
        }, 2000); // Adjust the delay before restarting the animation loop
      }
    };

    setTimeout(() => {
      showImages(0);
    }, 2000);

    let timeout1;
    if (showDuo2) {
      timeout1 = setTimeout(() => {
        setShowMagnet(true);
      }, 1000);
    }

    // return () => {
    //   clearInterval(interval);
    //   clearInterval(resetTimeout);
    // };
    return () => clearTimeout();
  }, []);

  return (
    <>
      <div className=" dml-hero dts-hero ">
        <div className="row hero-row ">
          <div className="col">
            <div className="dwp-sm-btn dlm-sm-btn">
              <img
                src={dlm1}
                alt="dlm-sm-btn-image"
                className="dlm-sm-btn-image"
              />
              <p>Dynamic Limit Management</p>
            </div>
            <h1 className="dlm-hero-h1">
              <span className="dlm-hero-h1-span">
                Dynamic data <span className="andfont"> & </span> AI-driven
                credit limit management
              </span>
            </h1>
            {/* <p>
              Our Dynamic Limit Management module helps Issuers manage Risk in
              the following steps:
            </p> */}

            <button>Contact Sales</button>
          </div>
          <div className="col">
            <img src={DlmGif} className="dlm-gif" alt="dlm gif" />
          </div>
        </div>

        <div className="dlm-boxes">
          <div className="dlm-box">
            <div className="border-line">
              <p>
                Dynamic Limit Management (DLM) application assesses each monthly
                customer onboarding, determining eligibility for Credit Card
                activation. Customers not activating the card may be flagged as
                NOT ELIGIBLE based on the latest results. This process is
                repeated monthly for all customers onboarded on the DUO Platform
                by the Issuer.
              </p>
            </div>
          </div>
          <div className="dlm-box">
            <div className="border-line">
              <p>
                Where the revised Credit Limit has reduced, DLM asks Credit
                Processor to reduce the credit limit for such DUO Credit Cards.
                Where DLM finds that revised Credit Limit is higher than the
                existing limit, the same is queued to RIS user for approval on
                DUO Web portal.
              </p>
            </div>
          </div>
          <div className="dlm-box">
            <div className="border-line">
              <p>
                For INELIGIBLE cards, Credit processor may be required to block
                the card instantly, however, the card will be un-blocked if
                found ELIGIBLE the following month.
              </p>
            </div>
          </div>

          <div className="dlm-box">
            <div className="border-line">
              <p>
                Ultimately, DLM helps Issuers manage risk dynamically by taking
                rule-based decisions seamlessly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;