/* React packages  */
import React from 'react';
import ReactTooltip from "react-tooltip";
import axios from 'axios';
import ForgotPin from "../../ForgotPin/ForgotPin"


/**
* Renders part of underwritting rules  section of bank for maker section.
* <p>Maker is allowed make the changes</p>
* <p>Maker can submit the changes.</p>
@Author : Seema Kumari
@version :1.0
*/

class UndWrittingPart6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      isDisabled: false,
      message: '',
      status: '',
      backerrors: [],
      errorMsg: ''
    };
  }
  /**
    *captures the pin entred by user.
    * @param {Event} e event which calls this method.
    */
  handleChange(e) {
    isDisabledSubmitButton = false;
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });

  }
  /**
   * Sets "ON and OFF " based on boolean values.
   */
  changeStatusToOnAndOff() {
    if (this.props.data[0].catMultiStatus) {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.catMultiStatus = "ON"
      })
    } else {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.catMultiStatus = "OFF"
      })
    }
    if (this.props.data[0].avgSalIntRateMultStatus) {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.avgSalIntRateMultStatus = "ON"
      })
    } else {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.avgSalIntRateMultStatus = "OFF"
      })
    }
    if (this.props.data[0].avgSalTenorMultiplierStatus) {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.avgSalTenorMultiplierStatus = "ON"
      })
    } else {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.avgSalTenorMultiplierStatus = "OFF"
      })
    }
    if (this.props.data[0].bureauScoreIntRateMultltStatus) {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.bureauScoreIntRateMultltStatus = "ON"
      })
    } else {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.bureauScoreIntRateMultltStatus = "OFF"
      })
    }
    if (this.props.data[0]?.deliquencyIntRateMultStatus) {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.deliquencyIntRateMultStatus = "ON"
      })
    } else {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.deliquencyIntRateMultStatus = "OFF"
      })
    }
    if (this.props.data[0].numYrsCurEmplyIntRateMultStatus) {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.numYrsCurEmplyIntRateMultStatus = "ON"
      })
    } else {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.numYrsCurEmplyIntRateMultStatus = "OFF"
      })
    }
    if (this.props.data[0].numYrsEmplyIntRateMultStatus) {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.numYrsEmplyIntRateMultStatus = "ON"
      })
    } else {
      this.props.data.map((underWritingRules, i) => {
        underWritingRules.numYrsEmplyIntRateMultStatus = "OFF"
      })
    }
  }
  setonandoffstatusvalue() {
    this.props.data.map((underwritingrules, i) => {
      if ("ON" == underwritingrules.avgSalIntRateMultStatus) {
        underwritingrules.avgSalIntRateMultStatus = true
      } else {
        underwritingrules.avgSalIntRateMultStatus = false
      }
      if ("ON" == underwritingrules.avgSalTenorMultiplierStatus) {
        underwritingrules.avgSalTenorMultiplierStatus = true
      } else {
        underwritingrules.avgSalTenorMultiplierStatus = false
      }
      if ("ON" == underwritingrules.bureauScoreIntRateMultltStatus) {
        underwritingrules.bureauScoreIntRateMultltStatus = true
      } else {
        underwritingrules.bureauScoreIntRateMultltStatus = false
      }
      if ("ON" == underwritingrules.catMultiStatus) {
        underwritingrules.catMultiStatus = true
      } else {
        underwritingrules.catMultiStatus = false
      }
      if ("ON" == underwritingrules.deliquencyIntRateMultStatus) {
        underwritingrules.deliquencyIntRateMultStatus = true
      } else {
        underwritingrules.deliquencyIntRateMultStatus = false
      }
      if ("ON" == underwritingrules.numYrsCurEmplyIntRateMultStatus) {
        underwritingrules.numYrsCurEmplyIntRateMultStatus = true
      } else {
        underwritingrules.numYrsCurEmplyIntRateMultStatus = false
      }
      if ("ON" == underwritingrules.numYrsEmplyIntRateMultStatus) {
        underwritingrules.numYrsEmplyIntRateMultStatus = true
      } else {
        underwritingrules.numYrsEmplyIntRateMultStatus = false
      }

    })
  }
  /**
   *Submits the changes to server for approval.
   * @param {Event} e event which calls this method.
   */
  compareObjects(obj1, obj2) {
    let changes = [];
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        changes.push({
          key: key,
          originalValue: obj1[key],
          modifiedValue: obj2[key]
        });
      }
    }
    return changes;
  }

  handleSubmit = (e) => {
    isDisabledSubmitButton = false;
    e.preventDefault();
    this.changeStatusToOnAndOff();

    let data = [];
    const dataarray = this.props.data || [];
    const oldDataArray = this.props.oldData || [];
    dataarray.forEach(input => {
      const oldinputData = oldDataArray.find(obj => obj.sequenceId === input.sequenceId);
      const changes = this.compareObjects(oldinputData, input);
      console.log("changes", changes);
      console.log("iseditable", input?.isEditable);
      if (changes.length > 0 && input?.isEditable) {
        console.log("OldData inside ", oldinputData);
        console.log("NewData inside ", input);
        data.push(input);  // Only push changed data
      }
    });
    console.log('dataarray:', dataarray);
    console.log('oldDataArray:', oldDataArray);
    console.log('changedataarray:', data);
    if (data.length > 0) {
      const url = `api/product/saveuwrules?email=${localStorage.getItem("email")}&pin=${this.state.fields.pin}`;
      axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        }
      })
        .then((response) => {
          if (response.status === 200) {
            return response.data;
          } else {
            return 'server error';
          }
        })
        .then((contents) => {
          if (contents.status === 200 && contents.message === 'SUCCESS') {
            isDisabledSubmitButton = true;
            this.setonandoffstatusvalue();
            this.setState({
              isDisabled: true,
              status: contents.status,
              message: contents.message
            });
          } else {
            isDisabledSubmitButton = true;
            this.setonandoffstatusvalue();
            this.setState({
              backerrors: contents.errors,
              status: contents.status,
              message: contents.message
            });
            this.state.backerrors.forEach((key) => {
              this.setState({
                errorMsg: key.message
              });
            });
          }
          this.props.handlestatus(this.state.status);
        })
        .catch((error) => {
          console.error('There was an error!', error);
        });
    }
  }

  render() {

    return (

      <div>

        {/* Form -: in this form section included input text, label, togglebutton and dropdown to render the data */}

        <form method="post" name="userRegistrationForm" className="formroot">
          <div className="unwrite">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 underfifth">

                <div className="d-flex flex-row mt-3 ">

                  <div className="p-2 headerbuttonsright">
                    <div className="input-group Multiplier2">
                      <p className="text2part">Multiplier <br /> Status</p>
                      <label className="switch"><input type="checkbox" name="numYrsCurEmplyIntRateMultStatus" onChange={this.props.toggleChange.bind(this)} checked={this.props.data[0]?.numYrsCurEmplyIntRateMultStatus} /><div className="slider round" ></div></label>
                    </div>
                  </div>

                  <div className="p-2 headertext">
                    <p className="textposition">No. of years with current employment - Interest Rate Multiplier</p>
                    <p className="textlabel">No. of Years</p>
                  </div>
                </div>

                <div className="d-flex flex-row mt-3 padmar ">
                  <div className="p-2 underwritepart6">
                    <div className="form-group">
                      <label className="labelpt-8 maklabuw">Product</label>
                      {this.props.data.map((underWritingRules, i) => <div key={i}><label className="form-control loanlabelmak" name="paydayloan" id="field1" disabled> {underWritingRules.productName} </label></div>)}
                    </div>
                  </div>


                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> {"<"}1 Year</label>


                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isNumYrsCurEmplyIntRateMultLess1yrlist[i] }} name="numYrsCurEmplyIntRateMultLess1yr" onChange={(e) => { this.props.handleChangenumYrsCurEmplyIntRateMultLess1yr(e, i) }} placeholder={underWritingRules.numYrsCurEmplyIntRateMultLess1yr} disabled={this.props.numYrsCurEmplyIntRateMultStatusdisable}>
                          <option value={underWritingRules.numYrsCurEmplyIntRateMultLess1yr}>{underWritingRules.numYrsCurEmplyIntRateMultLess1yr}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                        </ReactTooltip> */}

                      </div>)}
                    </div>
                  </div>
                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> 1 –  3 Years</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isNumYrsCurCurEmplyIntRateMultBET1n3yrslist[i] }} name="numYrsCurCurEmplyIntRateMultBET1n3yrs" onChange={(e) => { this.props.handleChangenumYrsCurCurEmplyIntRateMultBET1n3yrs(e, i) }} placeholder={underWritingRules.numYrsCurCurEmplyIntRateMultBET1n3yrs} disabled={this.props.numYrsCurEmplyIntRateMultStatusdisable}>
                          <option value={underWritingRules.numYrsCurCurEmplyIntRateMultBET1n3yrs}>{underWritingRules.numYrsCurCurEmplyIntRateMultBET1n3yrs}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                        </ReactTooltip> */}

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> 3 - 5 Years</label>

                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isNumYrsCurEmplyIntRateMultBET3n5yrslist[i] }} name="numYrsCurEmplyIntRateMultBET3n5yrs" onChange={(e) => { this.props.handleChangenumYrsCurEmplyIntRateMultBET3n5yrs(e, i) }} placeholder={underWritingRules.numYrsCurEmplyIntRateMultBET3n5yrs} disabled={this.props.numYrsCurEmplyIntRateMultStatusdisable}>
                          <option value={underWritingRules.numYrsCurEmplyIntRateMultBET3n5yrs}>{underWritingRules.numYrsCurEmplyIntRateMultBET3n5yrs}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                        </ReactTooltip> */}

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite6">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> {">"} 5 Years</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isNumYrsCurEmplyIntRateMultGT10yrslist[i] }} name="numYrsCurEmplyIntRateMultGT10yrs" onChange={(e) => { this.props.handleChangenumYrsCurEmplyIntRateMultGT10yrs(e, i) }} placeholder={underWritingRules.numYrsCurEmplyIntRateMultGT10yrs} disabled={this.props.numYrsCurEmplyIntRateMultStatusdisable}>
                          <option value={underWritingRules.numYrsCurEmplyIntRateMultGT10yrs}>{underWritingRules.numYrsCurEmplyIntRateMultGT10yrs}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                        </ReactTooltip> */}

                      </div>)}

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 underfifth ">

                <div className="d-flex flex-row mt-3 ">

                  <div className="p-2 headerbuttons">
                    <div className="input-group Multiplier2">
                      <p className="text2part">Multiplier <br /> Status</p>
                      <label className="switch"><input type="checkbox" name="bureauScoreIntRateMultltStatus" onChange={this.props.toggleChange.bind(this)} checked={this.props.data[0]?.bureauScoreIntRateMultltStatus} /><div className="slider round" ></div></label>

                    </div>
                  </div>

                  <div className="p-2 headertext">
                    <p className="textposition">Bureau Score- Interest Rate Multiplier</p>
                    <p className="textlabel">Bureau Score</p>
                  </div>
                </div>

                <div className="d-flex flex-row mt-3 padmar ">
                  <div className="p-2 underwritepart5">
                    <div className="form-group">
                      <label className="labelpt-8 underprolab6">Product</label>
                      {this.props.data.map((underWritingRules, i) => <div key={i}><label className="form-control loanlabelmak" name="paydayloan" id="field1" disabled >{underWritingRules.productName}</label></div>)}
                    </div>
                  </div>


                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> {">"} 900</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isBureauScoreIntRateMultGT900list[i] }} name="bureauScoreIntRateMultGT900" onChange={(e) => { this.props.handleChangebureauScoreIntRateMultGT900(e, i) }} placeholder={underWritingRules.bureauScoreIntRateMultGT900} disabled={this.props.bureauScoreIntRateMultltStatusdisable}>
                          <option value={underWritingRules.bureauScoreIntRateMultGT900}>{underWritingRules.bureauScoreIntRateMultGT900}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                        </ReactTooltip> */}

                      </div>)}

                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> 800 - 900 </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isBureauScoreIntRateMultBET800n900list[i] }} name="bureau   ScoreIntRateMultBET800n900" onChange={(e) => { this.props.handleChangebureauScoreIntRateMultBET800n900(e, i) }} placeholder={underWritingRules.bureauScoreIntRateMultBET800n900} disabled={this.props.bureauScoreIntRateMultltStatusdisable}>
                          <option value={underWritingRules.bureauScoreIntRateMultBET800n900}>{underWritingRules.bureauScoreIntRateMultBET800n900}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                        </ReactTooltip> */}

                      </div>)}

                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> 700 - 800 </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isBureauScoreIntRateMultBET700n800list[i] }} name="bureau   ScoreIntRateMultBET700n800" onChange={(e) => { this.props.handleChangebureauScoreIntRateMultBET700n800(e, i) }} placeholder={underWritingRules.bureauScoreIntRateMultBET700n800} disabled={this.props.bureauScoreIntRateMultltStatusdisable}>
                          <option value={underWritingRules.bureauScoreIntRateMultBET700n800}>{underWritingRules.bureauScoreIntRateMultBET700n800}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                        </ReactTooltip> */}

                      </div>)}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> 600 - 700 </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isBureauScoreIntRateMultBET600N700list[i] }} name="bureau   ScoreIntRateMultBET600N700" onChange={(e) => { this.props.handleChangebureauScoreIntRateMultBET600N700(e, i) }} placeholder={underWritingRules.bureauScoreIntRateMultBET600N700} disabled={this.props.bureauScoreIntRateMultltStatusdisable}>
                          <option value={underWritingRules.bureauScoreIntRateMultBET600N700}>{underWritingRules.bureauScoreIntRateMultBET600N700}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                        </ReactTooltip> */}

                      </div>)}

                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label className="form-control formprules loanlabelmak7" name="" id="field1" disabled> {"< "} 600</label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => <div key={i} className="input-group aligncenter inputblue margn">
                        <select className="form-control formselect" data-tip data-tip-disable={!this.props.editable} data-for="noneditable" style={{ borderColor: this.props.isBureauScoreIntRateMultlt600list[i] }} name="bureau   ScoreIntRateMultlt600" onChange={(e) => { this.props.handleChangebureauScoreIntRateMultlt600(e, i) }} placeholder={underWritingRules.bureauScoreIntRateMultlt600} disabled={this.props.bureauScoreIntRateMultltStatusdisable}>
                          <option value={underWritingRules.bureauScoreIntRateMultlt600}>{underWritingRules.bureauScoreIntRateMultlt600}</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                        {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                          Non Editable
                        </ReactTooltip> */}

                      </div>)}
                    </div>
                  </div>
                </div>

                {/* Type Your User PIN  :- user can submit the changes request approval after entering user pin */}

                <div className="cardformuw">
                  <div className="form-group">
                    <label htmlFor="first">Type Your User PIN </label>
                    <input type="password" className="form-control cardinputfield" name="pin" value={this.state.fields.message} onChange={this.handleChange.bind(this)} placeholder="xxxxx " />
                    <div className="sign">
                      <input type="submit" className="btn btn-warning cardbutton" onClick={this.handleSubmit} value="Submit changes for approval" style={{ backgroundColor: this.props.buttoncolor }} disabled={isDisabledSubmitButton} />
                    </div>
                  </div>
                  <ForgotPin />
                </div>
                {(() => {
                  switch (this.props.fieldsvalid) {
                    case true: return <p className="errorMsgpin">All Fields are Mandatory</p>
                    default: return <div></div>
                  }
                })()}
                {(() => {
                  switch (this.state.message) {
                    case "SUCCESS": return <p className="submittedpin">Submitted Succesfully</p>
                    default: return <div></div>
                  }
                })()}
                {(() => {
                  switch (this.state.message) {
                    case "UNSUCCESS": return <div className="errorMsgpin">{this.state.errorMsg}</div>
                    default: return <div></div>
                  }
                })()}
              </div>
            </div>
          </div>
        </form>
      </div>
    )

  }

}
let isDisabledSubmitButton = false;
export default UndWrittingPart6;
