import "./style.css";
import badge from "../../../../assets/images/badge.svg";
import handshake from "../../../../assets/images/handshake.svg";
import flag from "../../../../assets/images/flag.svg";
import fingersnap from "../../../../assets/images/snap-fingers.svg";
import growth from "../../../../assets/images/growth.svg";

const Body = () => {
  return (
    <section className="imp-body">
      <h2>DUO Advantages</h2>
      <div className="grid">
        <div className="grid-item">
          <img src={badge} alt="Patented Technology" className="list-images" />
          <div>
            <h5>Patented Technology</h5>
            <p className="list-para">
              DUAL TRANSACTION SERVICE, an innovation enabling Issuers offer
              Instant Credit Cards use through existing Debit Cards.
            </p>
          </div>
        </div>
        <div className="grid-item">
          <img src={handshake} alt="Key Partnerships" className="list-images" />
          <div>
            <h5>Key Partnerships &amp; Connections</h5>
            <p className="list-para">
              With partners like MASTERCARD & leading Bank & PROCESSORS, DUO is
              looking to help market grow over 100 times in terms of Credit Card
              penetration.
            </p>
          </div>
        </div>
        <div className="grid-item">
          <img src={flag} alt="First to Market" className="list-images" />
          <div>
            <h5>First to Market</h5>
            <p className="list-para">
              Dual Transaction Service (DTS) is the first ever service that
              enables issuer to offer usage of a Credit Card from existing Debit
              CARDS.
            </p>
          </div>
        </div>
        <div className="grid-item">
          <img src={fingersnap} alt="Easy to Use" className="list-images" />
          <div>
            <h5>Easy to Use</h5>
            <p className="list-para">
              DUO comes with easy to use & scalable concept such that existing
              Debit Cards can be used to access DUO Credit Cards.
            </p>
          </div>
        </div>
        <div className="grid-item">
          <img src={growth} alt="Growth Catalyst" className="list-images" />
          <div>
            <h5>Growth Catalyst</h5>
            <p className="list-para">
              We enable the Financial Institutions to increase penetration of
              Credit Cards across Africa.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Body;
