import React from 'react';
import { Spinner } from 'react-bootstrap';


const Loader = () => {
  return (
    <div className='spinner'>
      <Spinner animation="border" role="status" size='20'>
        {/* <span className="visually-hidden">Loading...</span> */}
      </Spinner>
    </div>
  );
}

export default Loader;