import React from 'react';
import './Indemnity.css';

const Indemnity = () => {
    return (
        <div className="indemnity-container">
            <h1>Indemnity</h1>
            <p>
                The User shall indemnify and hold Montra harmless from and against all actions, proceedings, costs, claims, demands, charges, expenses (including legal expenses), liabilities, fines, levies, losses, and damages whether arising in tort, contract, or common law which Montra may suffer or incur to the extent arising out of or in consequence of or in connection with:
            </p>
            <ul>
                <li>Your use or misuse of the Montra Services or of the Montra Platform;</li>
                <li>Any violation by You of this Agreement or the SSOID Agreement; or</li>
                <li>Any claim brought against Montra by a customer, cardholder, card scheme, card issuer, other financial institution, acquirer, or other third party arising from a transaction whether or not previously remitted to the User;</li>
                <li>Any claim brought against Montra arising from any aspect of this Agreement (including but not limited to and in connection with any security breach as described in this Agreement, compromise, or theft of data held by the User or on behalf of the User irrespective of whether such security breach, compromise, or theft of data was within or outside the User’s control);</li>
                <li>The enforcement or attempted enforcement of this Agreement (which includes the recovery or attempted recovery of any sum owing to Montra under this Agreement);</li>
                <li>The protection of Montra’s interest in connection with any aspect of the parties’ relationship under this Agreement (including the cost of any third parties nominated by Montra or instructed by Montra for this purpose);</li>
                <li>A breach by the User of any of the provisions of this Agreement;</li>
                <li>Any transaction (including a transaction which is subsequently discovered to be fraudulent);</li>
                <li>Respect to any negligent act or omission by or willful misconduct of the User, its representatives, or its agents;</li>
                <li>Any modification of or addition to Montra not provided or approved by Montra; or</li>
                <li>Any content, information, material, listings, images, and/or communications it publishes or causes to be published/transmitted via Montra or any related site/system.</li>
                <li>
                    Any breach of the representations, warranties, and covenants made by You herein. Montra reserves the right, at Your expense, to assume the exclusive defense and control of any matter for which You are required to indemnify Montra, including rights to settle, and You agree to cooperate with Montra’s defense and settlement of these claims. Montra will use reasonable efforts to notify You of any claim, action, or proceeding brought by a third party that is subject to the foregoing indemnification upon becoming aware of it. This paragraph shall survive termination of this Agreement.
                </li>
            </ul>

            <p>
                If the User is a partnership, each partner shall be jointly and severally liable under this Agreement.
            </p>
            <p>
                In the event of the occurrence of an event that leads to any damage, loss, liability, or expense to Montra, the User hereby agrees and authorizes Montra to immediately debit its account at settlement without recourse to the User.
            </p>
            <p>
                The User agrees that if a fraudulent activity is associated with the operation of its accounts, Montra shall have the right to apply restrictions to such account and report to appropriate law enforcement agencies.
            </p>

        </div>
    );
};

export default Indemnity;
