import React from "react";

function YU10() {
  return (
    <svg
      width="457"
      height="292"
      viewBox="0 0 457 292"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        d="M0 58H22C32 58 35 49.9 35 41.5C35 33.1 35 23.3333 35 19.5C35 14 35 1.5 54 1.99996C65.1961 2.29457 200.5 1.5 269 1.5C280 1.50004 286 3.2 286 14C286 24.8 286 49.8333 286 61H436C446.5 61 455.5 68.7 455.5 79.5C455.5 90.3 456 224.333 456 292"
        stroke="url(#paint0_radial_5218_6710)"
        stroke-width="2"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5218_6710"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(228 146.75) rotate(90) scale(145.25 228)"
        >
          <stop stop-color="#17D92A" />
          <stop offset="1" stop-color="#635BFF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU10;

// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   width="453"
//   height="283"
//   fill="none"
//   viewBox="0 0 453 283"
// >
//   <path
//     className="yu-path"
//     stroke="url(#paint0_radial_3809_12304)"
//     strokeWidth="2"
//     d="M0 49h28.557c3.006-.457 9.119-3.29 9.52-10.97.4-7.678.167-21.177 0-26.966-.167-3.352 1.403-10.055 9.019-10.055h142.297c3.174-.153 9.52 1.645 9.52 10.055v26.967C198.412 41.687 200.016 49 210.437 49h29.562l96 6h97.5c6 .667 18 5.3 18 18.5V283"
//     pathLength="1"
//   ></path>
//   <path
//     className="yu-path"
//     stroke="url(#paint1_radial_3809_12304)"
//     strokeWidth="2"
//     d="M0 61h28.559c3.006.457 9.119 3.29 9.52 10.97.4 7.678.167 21.177 0 26.966-.167 3.352 1.402 10.055 9.018 10.055h142.298c3.173.153 9.52-1.645 9.52-10.055V71.969C198.414 68.313 200.017 61 210.439 61H250.5l81.5-6"
//     pathLength="1"
//   ></path>
//   <defs>
//     <radialGradient
//       id="paint0_radial_3809_12304"
//       cx="0"
//       cy="0"
//       r="1"
//       gradientTransform="matrix(0 141 -225.75 0 225.75 142)"
//       gradientUnits="userSpaceOnUse"
//     >
//       <stop stopColor="#17D92A"></stop>
//       <stop offset="1" stopColor="#635BFF"></stop>
//     </radialGradient>
//     <radialGradient
//       id="paint1_radial_3809_12304"
//       cx="0"
//       cy="0"
//       r="1"
//       gradientTransform="matrix(0 -24 120 0 120 85)"
//       gradientUnits="userSpaceOnUse"
//     >
//       <stop stopColor="#17D92A"></stop>
//       <stop offset="1" stopColor="#635BFF"></stop>
//     </radialGradient>
//   </defs>
// </svg>
