import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import QRScan from "../../../assets/images/uday/Business-icons/payments/qrscan.svg";
import TapPay from "../../../assets/images/uday/Business-icons/payments/Tap&pay.svg";
import POS from "../../../assets/images/uday/Business-icons/payments/POS.svg";
import BankTransfer from "../../../assets/images/uday/Business-icons/payments/bankTransfer.svg";
import businesspaymenthero1 from "../../../assets/images/businesspaymenthero1.svg";

const steps = [
  {
    title: "Accept Payments Using",
    image: businesspaymenthero1,
    className: "businesspaymenticon",
  },
  { image: QRScan, description: "QR Scan" },
  { image: TapPay, description: "Tap & Pay" },
  { image: POS, description: "POS" },
  { image: BankTransfer, description: "Bank Transfer" },
];

const PaymentProcessContainer = () => {
  return (
    <div className="payment-process-container">
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="business-overview-topdescription"
      />
    </div>
  );
};

export default PaymentProcessContainer;
