/* React packages */
import React from "react";
import { NavLink } from "react-router-dom";


/**
 * Header component is render navigation in salary processor Lender section.
 * <p>There are two navigation menu will be there:</p>
 * <li>Upload Historical data</li>
 * <li>Upload Salary data for processing</li>
 <br>
 * <h6> React Bootstrap Navigation ui properties :- </h6>
 * <li> <b>Navbar:- </b>A navigation bar is a navigation header that is placed at the top of the page.</li>
 * <li> <b>Navbar.Collapse:- </b>it is use for grouping and hiding navbar contents by a parent breakpoint.</li>
 * <li> <b>NavLink:- </b>it will add styling attributes to the rendered element when it matches the current URL.</li>

 @Author : Seema Kumari
 @version :1.0
 */

const Header = () => {
  return (
    <div>
      <div className="link-container">
        <NavLink to="/HistoricalDataLender" className="userlink">
          Upload Historical data
        </NavLink>
        <NavLink to="/SalaryDataLender" className="userlink">
          Upload Salary data for processing
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
