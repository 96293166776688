import React from "react";

const YU1 = ({ className }) => {
  return (
    <svg
      width="531"
      height="198"
      viewBox="0 0 531 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className && className}
    >
      {/* <path
        className="yu-path"
        d="M2 151L1.5 91.5C1.5 87 6.1 77 16.5 77C26.9 77 75.8333 77 99 77M147.5 31V14C147.5 9.5 153 1.5 165 1.5C177 1.5 222 1.5 243 1.5M339.5 1.5H419.5C424.667 1.83333 435.1 5.6 435.5 18C435.9 30.4 435.667 111.5 435.5 150.5M483 196.5H494C497.333 196.167 504 193.5 504 185.5C504 177.5 504 118.5 504 90C504.333 87.1667 507.5 81.5 517.5 81.5C527.5 81.5 530.667 81.5 531 81.5"
        stroke="url(#paint0_linear_3285_7840)"
        strokeWidth="2"
        pathLength="1"
      />
      <defs> */}
      <path
        className="yu-path"
        d="M2 151L1.5 91.5C1.5 87 6.1 77 16.5 77C26.9 77 75.8333 77 99 77H148.5V32V15C148.5 10.5 154 2.5 166 2.5C178 2.5 223 2.5 244 2.5H340.5H420.5C425.667 2.83333 436.1 6.6 436.5 19C436.9 31.4 436.667 112.5 436.5 151.5V197.5H484H495C498.333 197.167 505 194.5 505 186.5C505 178.5 505 119.5 505 91C505.333 88.1667 508.5 82.5 518.5 82.5C528.5 82.5 531.667 82.5 532 82.5"
        stroke="url(#paint0_linear_3285_7840)"
        stroke-width="2"
        pathLength="1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3285_7840"
          x1="266.25"
          y1="1.5"
          x2="266.25"
          y2="196.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9B66FF" />
          <stop offset="1" stopColor="#13ECE2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default YU1;
