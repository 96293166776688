import React from "react";

function ERP() {
  return (
    <svg
      width="36"
      height="44"
      viewBox="0 0 36 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.09419 26.5785L2.50226 31.3493V34.3632"
        stroke="#223872"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.9058 26.5785L33.4977 31.3493V34.3632"
        stroke="#223872"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.60772 37.2491C3.49564 37.2491 4.21544 36.5764 4.21544 35.7466C4.21544 34.9168 3.49564 34.244 2.60772 34.244C1.7198 34.244 1 34.9168 1 35.7466C1 36.5764 1.7198 37.2491 2.60772 37.2491Z"
        stroke="#223872"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.3923 37.2491C32.5044 37.2491 31.7846 36.5764 31.7846 35.7466C31.7846 34.9168 32.5044 34.244 33.3923 34.244C34.2802 34.244 35 34.9168 35 35.7466C35 36.5764 34.2802 37.2491 33.3923 37.2491Z"
        stroke="#223872"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.8095 42.5181C18.6974 42.5181 19.4172 41.8453 19.4172 41.0155C19.4172 40.1857 18.6974 39.513 17.8095 39.513C16.9216 39.513 16.2018 40.1857 16.2018 41.0155C16.2018 41.8453 16.9216 42.5181 17.8095 42.5181Z"
        stroke="#223872"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.7937 29.3126L17.8131 38.99"
        stroke="#223872"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9281 3.92807C22.0261 4.11109 22.9411 4.66009 23.8561 5.20912L25.3201 4.2941C25.8692 3.92807 26.7842 3.92807 27.3332 4.4771L28.9802 6.12414C29.5293 6.67317 29.5293 7.40519 29.1634 8.13721L28.2482 9.60124C28.7973 10.5163 29.1634 11.4313 29.5293 12.5293L31.1763 12.8953C31.9083 13.0784 32.4574 13.6274 32.4574 14.3594V16.5554C32.4574 17.2875 31.9083 18.0195 31.1763 18.0195L29.5293 18.3855C29.3463 19.4835 28.7973 20.3985 28.2482 21.3137L29.1634 22.7775C29.5293 23.3266 29.5293 24.2416 28.9802 24.7906L27.3332 26.4376C26.7842 26.9867 26.0522 26.9867 25.3201 26.6208L23.8561 25.7056C22.9411 26.2547 22.0261 26.6208 20.9281 26.9867L20.5621 28.4508C20.3791 29.1828 19.8301 29.7318 19.0981 29.7318H16.902C16.17 29.7318 15.4379 29.1828 15.4379 28.4508L15.0719 26.8037C13.7909 26.6208 12.8759 26.2547 11.9608 25.7056L10.4968 26.6208C9.94779 26.9867 9.03275 26.9867 8.48375 26.4376L7.0197 24.7906C6.47069 24.2416 6.47069 23.5096 6.8367 22.7775L7.75172 21.3137C7.2027 20.3985 6.8367 19.4835 6.47069 18.3855L4.82364 18.0195C4.09162 17.8365 3.5426 17.2875 3.5426 16.5554V14.3594C3.5426 13.6274 4.09162 12.8953 4.82364 12.8953L6.47069 12.5293C6.65369 11.4313 7.2027 10.5163 7.75172 9.60125L6.8367 8.13721C6.47069 7.58818 6.47069 6.67317 7.0197 6.12414L8.66674 4.4771C9.21577 3.92809 9.94779 3.92809 10.6798 4.2941L12.1438 5.20912C13.0589 4.66011 13.9739 4.2941 15.0719 3.92809L15.4379 2.28104C15.621 1.54902 16.17 1 16.902 1H19.0981C19.8301 1 20.5621 1.54901 20.5621 2.28104L20.9281 3.92807ZM19.098 10.3333C15.4379 9.60125 12.3268 12.7123 13.0589 16.3725C13.4249 18.3855 15.0719 20.0326 17.085 20.3985C20.7451 21.1305 23.8561 18.0195 23.1241 14.3594C22.7581 12.3463 21.1111 10.6993 19.098 10.3333Z"
        stroke="#3FAFE9"
        stroke-width="2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1439 15.4575C12.1439 12.2232 14.7658 9.60127 18 9.60127C21.2343 9.60127 23.8561 12.2232 23.8561 15.4575C23.8561 18.6917 21.2343 21.3136 18 21.3136C14.7658 21.3136 12.1439 18.6917 12.1439 15.4575Z"
        stroke="#3FAFE9"
        stroke-width="2"
      />
    </svg>
  );
}

export default ERP;
