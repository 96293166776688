import React from "react";
import Navbar from "../../Navbar/Productnav";
import Footer from "../../Footer/Productfooter";
import Hero from "../../Personal/Payment/Hero";
import Body from "../../Personal/Payment/Body";
import arrow1 from "../../../assets/images/arrow1.svg";
import arrow2 from "../../../assets/images/arrow2.png";

const Main = () => {
  return (
    <>
      <div id="Payments">
        <Navbar />
        <Hero />
        <div className="payment-page">
          <div className="arrow paymentarrow">
            <img src={arrow1} alt="arrow" />
          </div>
        </div>
        <Body />
      </div>

      <Footer />
    </>
  );
};

export default Main;
