import React, { useEffect, useState } from "react";
import ProcessSteps from "../../../../utilities/ProcessSteps";

import Payments from "../../../../assets/images/uday/supplychain-icons/payments.svg";
import Collections from "../../../../assets/images/uday/supplychain-icons/collections.svg";
import SalesPromotions from "../../../../assets/images/uday/supplychain-icons/salesPromotions.svg";
import orderFullfilment from "../../../../assets/images/uday/supplychain-icons/orderFulfilment.svg";
import Analytics from "../../../../assets/images/uday/supplychain-icons/analytics.svg";
import supplychainimg from "../../../../assets/images/supplychainimg.svg";

const steps = [
  {
    title: "Montra supply chain solution",
    image: supplychainimg,
    className: "supplychain-lastimg",
  },
  { image: Payments, description: "Payments" },
  { image: Collections, description: "Collections" },
  { image: SalesPromotions, description: "Sales Promotions" },
  { image: orderFullfilment, description: "Order Fulfillment" },
  { image: Analytics, description: "Distribution Analytics" },
];

const SupplyProcessContainer = ({ hometopDescription }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="supply-topdescription"
        downDescriptionClass="supply-bottomdescription"
      />
    </div>
  );
};

export default SupplyProcessContainer;
