export default function anybankaccount() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49219 10.0982C6.72254 10.0982 6.87612 9.94464 6.87612 9.71429V8.94643C6.87612 8.71607 6.72254 8.5625 6.49219 8.5625H4.18862C3.95826 8.5625 3.80469 8.71607 3.80469 8.94643V9.71429C3.80469 9.94464 3.95826 10.0982 4.18862 10.0982H4.57254V15.8571H4.18862C3.95826 15.8571 3.80469 16.0107 3.80469 16.2411V17.0089C3.80469 17.2393 3.95826 17.3929 4.18862 17.3929H6.49219C6.72254 17.3929 6.87612 17.2393 6.87612 17.0089V16.2411C6.87612 16.0107 6.72254 15.8571 6.49219 15.8571H6.10826V10.0982H6.49219Z"
        fill="#20336B"
      />
      <path
        d="M20.3125 10.0982C20.5429 10.0982 20.6964 9.94464 20.6964 9.71429V8.94643C20.6964 8.71607 20.5429 8.5625 20.3125 8.5625H18.0089C17.7786 8.5625 17.625 8.71607 17.625 8.94643V9.71429C17.625 9.94464 17.7786 10.0982 18.0089 10.0982H18.3929V15.8571H18.0089C17.7786 15.8571 17.625 16.0107 17.625 16.2411V17.0089C17.625 17.2393 17.7786 17.3929 18.0089 17.3929H20.3125C20.5429 17.3929 20.6964 17.2393 20.6964 17.0089V16.2411C20.6964 16.0107 20.5429 15.8571 20.3125 15.8571H19.9286V10.0982H20.3125Z"
        fill="#20336B"
      />
      <path
        d="M12.25 0.5L1.5 6.25893V7.79464H23V6.25893L12.25 0.5ZM12.25 5.49107C11.4054 5.49107 10.7143 4.8 10.7143 3.95536C10.7143 3.11071 11.4054 2.41964 12.25 2.41964C13.0946 2.41964 13.7857 3.11071 13.7857 3.95536C13.7857 4.8 13.0946 5.49107 12.25 5.49107Z"
        fill="#20336B"
      />
      <path
        d="M12.2489 13.5527C9.4846 13.5527 7.25781 15.7795 7.25781 18.5438C7.25781 21.3081 9.4846 23.5349 12.2489 23.5349C15.0132 23.5349 17.24 21.3081 17.24 18.5438C17.24 15.7795 15.0132 13.5527 12.2489 13.5527ZM12.2489 22.767C9.90692 22.767 8.02567 20.8858 8.02567 18.5438C8.02567 16.2018 9.90692 14.3206 12.2489 14.3206C14.5908 14.3206 16.4721 16.2018 16.4721 18.5438C16.4721 20.8858 14.5908 22.767 12.2489 22.767Z"
        fill="#20336B"
      />
      <path
        d="M6.83661 20.4648H1.5V22.0006H7.64286C7.29732 21.5398 7.02857 21.0023 6.83661 20.4648Z"
        fill="#20336B"
      />
      <path
        d="M17.6617 20.4648C17.4698 21.0023 17.201 21.5398 16.8555 22.0006H22.9983V20.4648H17.6617Z"
        fill="#20336B"
      />
      <path
        d="M6.49163 18.5441C6.49163 18.4289 6.49163 18.2753 6.53002 18.1602H2.65234V19.6959H6.60681C6.53002 19.3119 6.49163 18.928 6.49163 18.5441Z"
        fill="#20336B"
      />
      <path
        d="M17.9713 18.1602C17.9713 18.2753 18.0097 18.4289 18.0097 18.5441C18.0097 18.928 17.9713 19.3119 17.8945 19.6959H21.849V18.1602H17.9713Z"
        fill="#20336B"
      />
      <path
        d="M15.707 8.5625H13.4035C13.1731 8.5625 13.0195 8.71607 13.0195 8.94643V9.71429C13.0195 9.94464 13.1731 10.0982 13.4035 10.0982H13.7874V13.0161C14.3249 13.1696 14.8624 13.4 15.3231 13.6688V10.0982H15.707C15.9374 10.0982 16.091 9.94464 16.091 9.71429V8.94643C16.091 8.71607 15.9374 8.5625 15.707 8.5625Z"
        fill="#20336B"
      />
      <path
        d="M11.0977 8.5625H8.79408C8.56373 8.5625 8.41016 8.71607 8.41016 8.94643V9.71429C8.41016 9.94464 8.56373 10.0982 8.79408 10.0982H9.17801V13.6688C9.63873 13.4 10.1762 13.1696 10.7137 13.0161V10.0982H11.0977C11.328 10.0982 11.4816 9.94464 11.4816 9.71429V8.94643C11.4816 8.71607 11.328 8.5625 11.0977 8.5625Z"
        fill="#20336B"
      />
      <path
        d="M12.1727 15.4726C11.5584 15.511 11.0977 16.0485 11.0977 16.7012V17.0467C11.0977 17.2771 11.136 17.5459 11.2128 17.7762L11.4048 18.3905C11.4432 18.5057 11.52 18.6209 11.5968 18.6976L11.7503 18.8512C11.8271 18.928 11.9039 18.9664 12.0191 18.9664H12.4798C12.595 18.9664 12.6718 18.928 12.7485 18.8512L12.9021 18.6976C12.9789 18.6209 13.0557 18.5057 13.0941 18.3905L13.286 17.7762C13.3628 17.5459 13.4012 17.2771 13.4012 17.0467V16.6628C13.4012 15.9717 12.8637 15.4342 12.1727 15.4726Z"
        fill="#20336B"
      />
      <path
        d="M12.9811 18.3916L14.1713 18.1613C13.4802 16.28 13.7874 15.0898 12.2517 15.0898C10.7544 15.0898 11.0231 16.28 10.332 18.1613L11.5222 18.3916C11.5222 18.6604 11.4838 18.8907 11.407 19.1595L12.2517 20.2345L13.0963 19.1595C12.9811 18.8907 12.9427 18.6604 12.9811 18.3916Z"
        fill="#3FAFE9"
      />
      <path
        d="M15.0903 20.0811C14.9751 19.8507 14.7831 19.6588 14.5144 19.6204L13.8233 19.4668L12.2492 21.4632L10.6751 19.4668L9.98404 19.6204C9.71529 19.6588 9.52333 19.8507 9.40815 20.0811L9.29297 20.3498C9.90725 21.348 10.9823 22.0007 12.2492 22.0007C13.5162 22.0007 14.5912 21.348 15.2055 20.3498L15.0903 20.0811Z"
        fill="#3FAFE9"
      />
    </svg>
  );
}
