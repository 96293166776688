import React from "react";

/**
 *  CheckerWrittingPart5 component is render upper part of underwriting rules(No. of years in employment- Interest Rate Multiplier and Delinquency Status - Interest Rate Multiplier) of bank for checker section.
 *   <p>Allows checker to approve or reject by binding </p>
 * @Author : Thrilok Kandra
 *  @version :1.0
 */

class CheckerWrittingPart5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
    };
  }
  render() {
    return (
      <div>
        <form method="post" name="userRegistrationForm" className="formroot">
          <div className="unwrite">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7 groupfour">
                <div className="d-flex flex-row mt-3 ">
                  <div className="p-2 headerbuttons">
                    <div className="input-group Multiplier2">
                      <p className="text2part">
                        Multiplier <br /> Status
                      </p>
                      <div className="form-group aligncenter inputgraygl">
                        <label
                          type="text"
                          className="form-control formpprofilecuw"
                        >
                          {this.props.data[0]?.numYrsEmplyIntRateMultStatus}{" "}
                        </label>
                        <label className="switchtoggle">
                          <input
                            type="checkbox"
                            name="numYrsEmplyIntRateMultStatus"
                            checked={
                              this.props.underWritingRulesstatus[0]
                                ?.numYrsEmplyIntRateMultStatus
                            }
                            onChange={(e) => this.props.toggleChange(e)}
                          />
                          <div className="checkerslider round"></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="p-2 headertext">
                    <p className="textposition">
                      No. of years in employment- Interest Rate Multiplier
                    </p>
                    <p className="textlabel">No. of Years</p>
                  </div>
                </div>

                <div className="d-flex flex-row mt-3 padmar">
                  <div className="p-2 underwritepart5">
                    <div className="form-group">
                      <label className="labelpt-ch proch">Product</label>
                      {this.props.data.map((underWritingRules, i) => (
                        <div key={i}>
                          <label
                            className="form-control loanlabelcheck"
                            name="paydayloan"
                            id="field1"
                            disabled
                          >
                            {" "}
                            {underWritingRules.productName}{" "}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        {"<"}1 Year
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isNumYrsEmplyIntMultRateMultLess1yrfieldclasslist[
                                i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="numYrsEmplyIntMultRateMultLess1yrsa"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.numYrsEmplyIntMultRateMultLess1yr
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isNumYrsEmplyIntMultRateMultLess1yr"
                              onChange={(e) => {
                                this.props.toggleChangeIsNumYrsEmplyIntMultRateMultLess1yr(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isNumYrsEmplyIntMultRateMultLess1yr
                              }
                              disabled={
                                this.props
                                  .isNumYrsEmplyIntMultRateMultLess1yrlist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isNumYrsEmplyIntMultRateMultLess1yrclasslist[
                                    i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        1 – 3 Years
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isNumYrsEmplyIntMultRateMultBET1n3yrsfieldclasslist[
                                i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="numYrsEmplyIntMultRateMultBET1n3yrs"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.numYrsEmplyIntMultRateMultBET1n3yrs
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isNumYrsEmplyIntMultRateMultBET1n3yrs"
                              onChange={(e) => {
                                this.props.toggleChangeIsNumYrsEmplyIntMultRateMultBET1n3yrs(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isNumYrsEmplyIntMultRateMultBET1n3yrs
                              }
                              disabled={
                                this.props
                                  .isNumYrsEmplyIntMultRateMultBET1n3yrslist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isNumYrsEmplyIntMultRateMultBET1n3yrsclasslist[
                                    i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        3 – 5 Years
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isNumYrsEmplyIntMultRateMultBET3n5yrsfieldclasslist[
                                i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="numYrsEmplyIntMultRateMultBET3n5yrs"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.numYrsEmplyIntMultRateMultBET3n5yrs
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isnumYrsEmplyIntMultRateMultBET3n5yrs"
                              onChange={(e) => {
                                this.props.toggleChangeIsNumYrsEmplyIntMultRateMultBET3n5yrs(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isNumYrsEmplyIntMultRateMultBET3n5yrs
                              }
                              disabled={
                                this.props
                                  .isNumYrsEmplyIntMultRateMultBET3n5yrslist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isNumYrsEmplyIntMultRateMultBET3n5yrsclasslist[
                                    i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        5 – 10 Years
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isNumYrsEmplyIntMultRateMultBET5n10yrsfieldclasslist[
                                i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="numYrsEmplyIntMultRateMultBET5n10yrs"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.numYrsEmplyIntMultRateMultBET5n10yrs
                            }
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isNumYrsEmplyIntMultRateMultBET5n10yrs"
                              onChange={(e) => {
                                this.props.toggleChangeIsNumYrsEmplyIntMultRateMultBET5n10yrs(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isNumYrsEmplyIntMultRateMultBET5n10yrs
                              }
                              disabled={
                                this.props
                                  .isNumYrsEmplyIntMultRateMultBET5n10yrslist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isNumYrsEmplyIntMultRateMultBET5n10yrsclasslist[
                                    i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        {">"} 10 Years"
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isNumYrsEmplyIntRateMultGT10yrsfieldclasslist[
                                i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="numYrsEmplyIntRateMultGT10yrs"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.numYrsEmplyIntRateMultGT10yrs
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isNumYrsEmplyIntRateMultGT10yrs"
                              onChange={(e) => {
                                this.props.toggleChangeIsNumYrsEmplyIntRateMultGT10yrs(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isNumYrsEmplyIntRateMultGT10yrs
                              }
                              disabled={
                                this.props.isNumYrsEmplyIntRateMultGT10yrslist[
                                  i
                                ]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isNumYrsEmplyIntRateMultGT10yrsclasslist[
                                    i
                                  ],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5 groupfour">
                <div className="d-flex flex-row mt-3 ">
                  <div className="p-2 headerbuttons">
                    <div className="input-group Multiplier2">
                      <p className="text2part">
                        Multiplier <br /> Status
                      </p>
                      <div className="form-group aligncenter inputgraygl">
                        <label
                          type="text"
                          className="form-control formpprofilecuw"
                        >
                          {this.props.data[0]?.deliquencyIntRateMultStatus}{" "}
                        </label>
                        <label className="switchtoggle">
                          <input
                            type="checkbox"
                            name="deliquencyIntRateMultStatus"
                            checked={
                              this.props.underWritingRulesstatus[0]
                                ?.deliquencyIntRateMultStatus
                            }
                            onChange={(e) => this.props.toggleChange(e)}
                          />
                          <div className="checkerslider round"></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="p-2 headertext">
                    <p className="textposition">
                      Delinquency Status - Interest Rate Multiplier
                    </p>
                    <p className="textlabel">Days Past Due (DPD)</p>
                  </div>
                </div>

                <div className="d-flex flex-row mt-3 padmar">
                  <div className="p-2 underwritepart5">
                    <div className="form-group">
                      <label className="labelpt-ch2">Product</label>
                      {this.props.data.map((underWritingRules, i) => (
                        <div key={i}>
                          <label
                            className="form-control loanlabelcheck"
                            name="paydayloan"
                            id="field1"
                            disabled
                          >
                            {" "}
                            {underWritingRules.productName}{" "}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        Current
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isDeliquencyIntRateMultCurrentfieldclasslist[
                                i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="deliquencyIntRateMultCurrentsa"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.deliquencyIntRateMultCurrent
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isDeliquencyIntRateMultCurrent"
                              onChange={(e) => {
                                this.props.toggleChangeIsDeliquencyIntRateMultCurrent(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isDeliquencyIntRateMultCurrent
                              }
                              disabled={
                                this.props.isDeliquencyIntRateMultCurrentlist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isDeliquencyIntRateMultCurrentclasslist[i],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        X - Days
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isDeliquencyIntRateMultXDaysfieldclasslist[i],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="deliquencyIntRateMultXDayssa"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {underWritingRules.deliquencyIntRateMultXDays}{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isDeliquencyIntRateMultXDays"
                              onChange={(e) => {
                                this.props.toggleChangeIsDeliquencyIntRateMultXDays(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isDeliquencyIntRateMultXDays
                              }
                              disabled={
                                this.props.isDeliquencyIntRateMultXDayslist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isDeliquencyIntRateMultXDaysclasslist[i],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        30 DPD
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isDeliquencyIntRateMult30DPDfieldclasslist[i],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="deliquencyIntRateMult30DPDsa"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {underWritingRules.deliquencyIntRateMult30DPD}{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isDeliquencyIntRateMult30DPD"
                              onChange={(e) => {
                                this.props.toggleChangeIsDeliquencyIntRateMult30DPD(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isDeliquencyIntRateMult30DPD
                              }
                              disabled={
                                this.props.isDeliquencyIntRateMult30DPDlist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isDeliquencyIntRateMult30DPDclasslist[i],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        60 DPD
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isDeliquencyIntRateMult60DPDfieldclasslist[i],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="deliquencyIntRateMult60DPDsa"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {underWritingRules.deliquencyIntRateMult60DPD}{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isDeliquencyIntRateMult60DPD"
                              onChange={(e) => {
                                this.props.toggleChangeIsDeliquencyIntRateMult60DPD(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isDeliquencyIntRateMult60DPD
                              }
                              disabled={
                                this.props.isDeliquencyIntRateMult60DPDlist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isDeliquencyIntRateMult60DPDclasslist[i],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="p-2 underwrite5">
                    <div className="form-group">
                      <label className="labelpt-9"></label>
                      <label
                        className="form-control formprulesch loanlabelmak7"
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        {">"}90 DPD
                      </label>
                    </div>
                    <div className="form-group">
                      {this.props.data.map((underWritingRules, i) => (
                        <div
                          key={i}
                          className="form-group aligncenter inputgrayfield"
                          style={{
                            backgroundColor:
                              this.props
                                .isDeliquencyIntRateMultGT90DPDfieldclasslist[
                                i
                              ],
                          }}
                        >
                          <label
                            type="text"
                            className="form-control formpprofilefield"
                            name="deliquencyIntRateMultGT90DPDsa"
                            onChange={this.props.handleChange.bind(this)}
                            disabled
                          >
                            {" "}
                            {
                              underWritingRules.deliquencyIntRateMultGT90DPD
                            }{" "}
                          </label>
                          <label className="switchtoggle">
                            <input
                              type="checkbox"
                              name="isDeliquencyIntRateMultGT90DPD"
                              onChange={(e) => {
                                this.props.toggleChangeIsDeliquencyIntRateMultGT90DPD(
                                  e,
                                  i
                                );
                              }}
                              checked={
                                underWritingRules.isDeliquencyIntRateMultGT90DPD
                              }
                              disabled={
                                this.props.isDeliquencyIntRateMultGT90DPDlist[i]
                              }
                            />
                            <div
                              className="checkerslider round"
                              style={{
                                backgroundColor:
                                  this.props
                                    .isDeliquencyIntRateMultGT90DPDclasslist[i],
                              }}
                            ></div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CheckerWrittingPart5;
