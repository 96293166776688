import React from "react";

function MontraApp() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1096_200)">
        <path
          d="M32 0.5H4C2.067 0.5 0.5 2.067 0.5 4V32C0.5 33.933 2.067 35.5 4 35.5H32C33.933 35.5 35.5 33.933 35.5 32V4C35.5 2.067 33.933 0.5 32 0.5Z"
          fill="#223872"
          stroke="#223872"
        />
        <path
          d="M16.61 24.2183L16.9733 22.5732L19.1574 22.25L18.7295 24.2183H16.61Z"
          fill="#3FAFE9"
          stroke="#3FAFE9"
        />
        <path
          d="M25.1959 24.1994L25.7809 21.3414L27.9338 21.0864L27.2097 24.1994H25.1959Z"
          fill="#3FAFE9"
          stroke="#3FAFE9"
        />
        <mask id="path-4-inside-1_1096_200" fill="white">
          <path d="M7.50078 24.6044L10.8859 23.4608L10.6387 24.6997H7.50078V24.6044Z" />
        </mask>
        <path
          d="M7.50078 24.6044L10.8859 23.4608L10.6387 24.6997H7.50078V24.6044Z"
          fill="#3FAFE9"
        />
        <path
          d="M7.50078 24.6044L7.18072 23.657L6.50078 23.8867V24.6044H7.50078ZM10.8859 23.4608L11.8666 23.6565L12.2051 21.9596L10.5658 22.5134L10.8859 23.4608ZM10.6387 24.6997V25.6997H11.4589L11.6194 24.8954L10.6387 24.6997ZM7.50078 24.6997H6.50078V25.6997H7.50078V24.6997ZM7.82084 25.5518L11.206 24.4082L10.5658 22.5134L7.18072 23.657L7.82084 25.5518ZM9.90523 23.2651L9.65803 24.504L11.6194 24.8954L11.8666 23.6565L9.90523 23.2651ZM10.6387 23.6997H7.50078V25.6997H10.6387V23.6997ZM8.50078 24.6997V24.6044H6.50078V24.6997H8.50078Z"
          fill="#3FAFE9"
          mask="url(#path-4-inside-1_1096_200)"
        />
        <mask
          id="mask0_1096_200"
          maskUnits="userSpaceOnUse"
          x="7"
          y="23"
          width="4"
          height="2"
        >
          <path
            d="M7.50078 24.6044L10.8859 23.4608L10.6387 24.6997H7.50078V24.6044Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1096_200)">
          <path
            d="M7.50078 24.6044L7.18071 23.657L6.50078 23.8867V24.6044H7.50078ZM10.8859 23.4608L11.8666 23.6564L12.2052 21.9595L10.5659 22.5134L10.8859 23.4608ZM10.6387 24.6997V25.6997H11.4589L11.6194 24.8954L10.6387 24.6997ZM7.50078 24.6997H6.50078V25.6997H7.50078V24.6997ZM7.82084 25.5518L11.206 24.4081L10.5659 22.5134L7.18071 23.657L7.82084 25.5518ZM9.90527 23.2651L9.65804 24.504L11.6194 24.8954L11.8666 23.6564L9.90527 23.2651ZM10.6387 23.6997H7.50078V25.6997H10.6387V23.6997ZM8.50078 24.6997V24.6044H6.50078V24.6997H8.50078Z"
            fill="#3FAFE9"
          />
        </g>
        <mask
          id="mask1_1096_200"
          maskUnits="userSpaceOnUse"
          x="7"
          y="10"
          width="23"
          height="15"
        >
          <path
            d="M28.5603 20.5415C27.468 20.6571 26.3981 20.7784 25.3507 20.9056L26.3541 16.1875C26.461 15.7223 26.5144 15.3018 26.5144 14.9262C26.5144 13.5854 25.7039 12.915 24.0828 12.915H21.649L19.7852 21.6685C18.6582 21.8447 17.576 22.0297 16.5387 22.2233L18.5195 12.915H13.9206C13.3687 12.915 13.0393 13.183 12.9324 13.719L10.8605 23.5172C9.58399 23.8769 8.4527 24.2626 7.48828 24.6722L9.99144 12.915C10.1691 12.0741 10.4718 11.4608 10.8995 11.075C11.3272 10.6907 11.9954 10.4985 12.9042 10.4985H24.9107C26.3902 10.4985 27.5713 10.794 28.4541 11.3849C29.3368 11.9744 29.7783 12.8868 29.7783 14.1222C29.7783 14.6408 29.7421 15.0519 29.6699 15.3553L28.5603 20.5415Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_1096_200)">
          <path d="M33 34H4V7H33V34Z" fill="white" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1096_200">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MontraApp;
