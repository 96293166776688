import React from "react";
import dts1 from "../../../../assets/images/dts1.svg";
import dwp3 from "../../../../assets/images/dwp3.webp";

const Body = () => {
  return (
    <>
      <div className="collection-body act-body">
        <div className="row dwp-row">
          <div className="col-md-6 dts-frame ">
            <div className="dts-frame-img">
              <img src={dts1} alt="Advantage to Issuers" />
            </div>
            <div className="dts-frame-header">
              <div class="lineparagrapduo">
                <h6>Advantage to Issuers</h6>
              </div>
            </div>
            <div className="collection-duo-lists dts-list">
              <div className="duo-list duo-list-collection">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DUO Collection application is deployed in Issuer’s
                    environment to help Issuer manage collection for the Due &
                    Delinquent amounts.
                  </p>
                </div>
              </div>
              <div className="duo-list duo-list-collection">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    AI powered DUO platform tracks the due & delinquent amounts
                    for each customer and facilitates the process of voluntarily
                    repayment requested by customer from the DUO App, collection
                    of balance against the minimum due amount for recent cycle
                    on the last date of repayment as well as collection of
                    delinquent amounts from all the accounts customer has in the
                    bank.
                  </p>
                </div>
              </div>
              <div className="duo-list duo-list-collection">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Duo collection application can be optimally programmed for
                    the Issuer such that any amount made available in customer’s
                    account can be considered dynamically for collection of
                    delinquent amount.
                  </p>
                </div>
              </div>
              <div className="duo-list duo-list-collection">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DUO collection application reduces the dependance on
                    expensive collection channels that may be otherwise required
                    for collection.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 dwp-img">
            <img src={dwp3} alt="dwp3" className="dwpimgall" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
