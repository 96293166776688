import React from "react";

function MontraAccount() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0625 5.8125H2.5C1.67157 5.8125 1 6.48407 1 7.3125V20.5625C1 21.3909 1.67157 22.0625 2.5 22.0625H20.0625C20.8909 22.0625 21.5625 21.3909 21.5625 20.5625V7.3125C21.5625 6.48407 20.8909 5.8125 20.0625 5.8125Z"
        stroke="#20336B"
      />
      <path
        d="M6.72913 5.79168L13.9295 1.29145C14.1795 1.13517 14.5098 1.2281 14.6417 1.49184L15.3541 2.91668"
        stroke="#20336B"
      />
      <path
        d="M9.60413 5.79168L17.2593 2.18924C17.5236 2.06486 17.8378 2.19258 17.9404 2.46609L19.1875 5.79168"
        stroke="#20336B"
      />
      <mask
        id="mask0_2131_338"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="13"
        y="11"
        width="11"
        height="6"
      >
        <path
          d="M13.9166 13.0625C13.9166 11.9579 14.8121 11.0625 15.9166 11.0625H23C23.2761 11.0625 23.5 11.2864 23.5 11.5625V16.3125C23.5 16.5886 23.2761 16.8125 23 16.8125H15.9166C14.8121 16.8125 13.9166 15.9171 13.9166 14.8125V13.0625Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2131_338)">
        <path
          d="M13.9166 13.0625C13.9166 11.9579 14.8121 11.0625 15.9166 11.0625H23C23.2761 11.0625 23.5 11.2864 23.5 11.5625V16.3125C23.5 16.5886 23.2761 16.8125 23 16.8125H15.9166C14.8121 16.8125 13.9166 15.9171 13.9166 14.8125V13.0625Z"
          stroke="#20336B"
          stroke-width="2"
        />
      </g>
      <path
        d="M17.0313 14.7375C17.4996 14.7375 17.8792 14.3578 17.8792 13.8895C17.8792 13.4213 17.4996 13.0416 17.0313 13.0416C16.563 13.0416 16.1833 13.4213 16.1833 13.8895C16.1833 14.3578 16.563 14.7375 17.0313 14.7375Z"
        stroke="#20336B"
        stroke-width="0.7"
      />
      <path
        d="M17.2584 3.77917H15.9417C15.776 3.77917 15.6417 3.91349 15.6417 4.07917V4.43751C15.6417 4.60319 15.776 4.73751 15.9417 4.73751H17.2584C17.4241 4.73751 17.5584 4.60319 17.5584 4.43751V4.07917C17.5584 3.91349 17.4241 3.77917 17.2584 3.77917Z"
        fill="#20336B"
      />
      <path
        d="M21.5834 9.625H22.7209C22.8866 9.625 23.0209 9.75931 23.0209 9.925V11.5417"
        stroke="#20336B"
      />
      <path
        d="M7.64819 15.7175L7.68551 15.5485L8.02247 15.4987L7.97489 15.7175H7.64819Z"
        fill="#4ABCEB"
        stroke="#4ABCEB"
      />
      <path
        d="M11.3326 15.7092L11.4728 15.0243L11.7908 14.9867L11.6228 15.7092H11.3326Z"
        fill="#4ABCEB"
        stroke="#4ABCEB"
      />
      <path
        d="M3.37744 16.1683L4.83285 15.6766L4.72656 16.2093H3.37744V16.1683Z"
        fill="#4ABCEB"
      />
      <mask
        id="mask1_2131_338"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="3"
        y="15"
        width="2"
        height="2"
      >
        <path
          d="M3.37744 16.1683L4.83285 15.6766L4.72656 16.2093H3.37744V16.1683Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2131_338)">
        <path
          d="M3.37744 16.1683L3.05738 15.2209L2.37744 15.4506V16.1683H3.37744ZM4.83285 15.6766L5.81352 15.8723L6.15214 14.1754L4.51279 14.7292L4.83285 15.6766ZM4.72656 16.2093V17.2093H5.54672L5.70722 16.405L4.72656 16.2093ZM3.37744 16.2093H2.37744V17.2093H3.37744V16.2093ZM3.69751 17.1157L5.15292 16.624L4.51279 14.7292L3.05738 15.2209L3.69751 17.1157ZM3.85219 15.4809L3.74589 16.0136L5.70722 16.405L5.81352 15.8723L3.85219 15.4809ZM4.72656 15.2093H3.37744V17.2093H4.72656V15.2093ZM4.37744 16.2093V16.1683H2.37744V16.2093H4.37744Z"
          fill="#4ABCEB"
        />
      </g>
      <mask
        id="mask2_2131_338"
        // style="mask-type:luminance"
        maskUnits="userSpaceOnUse"
        x="3"
        y="10"
        width="10"
        height="7"
      >
        <path
          d="M12.4347 14.422C11.9651 14.4717 11.5051 14.5239 11.0547 14.5785L11.4861 12.55C11.5321 12.35 11.5551 12.1693 11.5551 12.0077C11.5551 11.4313 11.2066 11.1431 10.5096 11.1431H9.46322L8.66193 14.9065C8.17738 14.9823 7.71212 15.0618 7.2661 15.145L8.11775 11.1431H6.14052C5.90322 11.1431 5.76159 11.2583 5.71563 11.4887L4.82485 15.7013C4.27603 15.856 3.78964 16.0218 3.375 16.1979L4.4512 11.1431C4.52761 10.7815 4.65775 10.5178 4.84162 10.352C5.02549 10.1867 5.31279 10.1041 5.70352 10.1041H10.8656C11.5017 10.1041 12.0095 10.2312 12.389 10.4852C12.7685 10.7387 12.9583 11.1309 12.9583 11.6621C12.9583 11.8851 12.9428 12.0618 12.9117 12.1922L12.4347 14.422Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_2131_338)">
        <path
          d="M43.9306 20.3228H1.98193V8.62903H43.9306V20.3228Z"
          fill="#20336B"
        />
      </g>
    </svg>
  );
}

export default MontraAccount;

{
  /* <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <rect
        width="20.563"
        height="16.25"
        x="1"
        y="5.813"
        stroke="#20336B"
        rx="1.5"
      ></rect>
      <path
        stroke="#20336B"
        d="M6.73 5.792l7.2-4.5a.5.5 0 01.713.2l.712 1.425M9.605 5.792l7.656-3.602a.5.5 0 01.68.277l1.248 3.325"
      ></path>
      <mask id="path-4-inside-1_3288_8228" fill="#fff">
        <path d="M13.918 13.063a2 2 0 012-2h7.083a.5.5 0 01.5.5v4.75a.5.5 0 01-.5.5h-7.083a2 2 0 01-2-2v-1.75z"></path>
      </mask>
      <path
        stroke="#20336B"
        strokeWidth="2"
        d="M13.918 13.063a2 2 0 012-2h7.083a.5.5 0 01.5.5v4.75a.5.5 0 01-.5.5h-7.083a2 2 0 01-2-2v-1.75z"
        mask="url(#path-4-inside-1_3288_8228)"
      ></path>
      <circle
        cx="17.03"
        cy="13.889"
        r="0.848"
        stroke="#20336B"
        strokeWidth="0.7"
      ></circle>
      <rect
        width="1.917"
        height="0.958"
        x="15.641"
        y="3.779"
        fill="#20336B"
        rx="0.3"
      ></rect>
      <path stroke="#20336B" d="M21.582 9.625h1.137a.3.3 0 01.3.3v1.617"></path>
      <path
        fill="#4ABCEB"
        stroke="#4ABCEB"
        d="M7.65 15.718l.037-.17.337-.05-.047.22H7.65zM11.335 15.71l.14-.686.319-.037-.168.722h-.29z"
      ></path>
      <mask id="path-10-inside-2_3288_8228" fill="#fff">
        <path d="M3.379 16.168l1.455-.492-.106.532h-1.35v-.04z"></path>
      </mask>
      <path
        fill="#4ABCEB"
        d="M3.379 16.168l1.455-.492-.106.532h-1.35v-.04z"
      ></path>
      <path
        fill="#4ABCEB"
        d="M3.379 16.168l-.32-.948-.68.23v.718h1zm1.455-.492l.981.195.339-1.696-1.64.553.32.948zm-.106.532v1h.82l.16-.804-.98-.196zm-1.35 0h-1v1h1v-1zm.321.907l1.455-.492-.64-1.895-1.455.492.64 1.895zm.155-1.635l-.107.533 1.962.391.106-.533-1.961-.39zm.874-.272h-1.35v2h1.35v-2zm-.35 1v-.04h-2v.04h2z"
        mask="url(#path-10-inside-2_3288_8228)"
      ></path>
      <mask
        id="mask0_3288_8228"
        style={{ maskType: "luminance" }}
        width="10"
        height="7"
        x="3"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M12.435 14.421c-.47.05-.93.102-1.38.157l.431-2.029c.046-.2.07-.38.07-.542 0-.576-.35-.865-1.046-.865H9.463l-.801 3.764c-.485.076-.95.155-1.396.238l.852-4.002H6.14c-.238 0-.38.116-.425.346l-.891 4.213c-.549.154-1.035.32-1.45.496l1.076-5.055c.077-.361.207-.625.39-.79.184-.166.472-.248.863-.248h5.162c.636 0 1.143.127 1.523.38.38.254.57.646.57 1.177 0 .223-.016.4-.047.53l-.477 2.23z"
        ></path>
        <path
          stroke="#fff"
          d="M12.435 14.421c-.47.05-.93.102-1.38.157l.431-2.029c.046-.2.07-.38.07-.542 0-.576-.35-.865-1.046-.865H9.463l-.801 3.764c-.485.076-.95.155-1.396.238l.852-4.002H6.14c-.238 0-.38.116-.425.346l-.891 4.213c-.549.154-1.035.32-1.45.496l1.076-5.055c.077-.361.207-.625.39-.79.184-.166.472-.248.863-.248h5.162c.636 0 1.143.127 1.523.38.38.254.57.646.57 1.177 0 .223-.016.4-.047.53l-.477 2.23z"
        ></path>
      </mask>
      <g mask="url(#mask0_3288_8228)">
        <path fill="#20336B" d="M43.93 20.323H1.98V8.629h41.95v11.694z"></path>
        <path
          stroke="#4ABCEB"
          d="M43.93 20.323H1.98V8.629h41.95v11.694z"
        ></path>
      </g>
    </svg> */
}
