import React from 'react';
import Navbar from '../../../Navbar/Supplychain';
import Footer from '../../../Footer/Productfooter';
import Hero from './Hero'
import Body from './Body'
import './style.css'

const Main = () => {
  return (
    <>
      <div id="salespromotions">
        <Navbar />
        <Hero />
        <Body />
        <Footer />
      </div>
    </>
  )
}

export default Main