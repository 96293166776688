import "./style.css";
// import hero from "../../../assets/images/gateway-hero.svg";
import hero from "../../../assets/images/paymentss_gatway.webp";
// import hero2 from "../../../assets/images/gateway-hero-2.svg";
import paymentgatewayhero from "../../../assets/images/uday/pg-banner-image.webp";
import loan1 from "../../../assets/images/paymentg.svg";
// import Shop1 from "../../../assets/images/uday/personal-icons/shop/sho1.svg";
// import Shop2 from "../../../assets/images/uday/personal-icons/shop/sho2.svg";
// import Shop3 from "../../../assets/images/uday/personal-icons/shop/sho3.svg";
// import Shop4 from "../../../assets/images/uday/personal-icons/shop/sho4.svg";
import GatewayProcessContainer from "./GatewayProcessContainer";

const Hero = () => {
  return (
    <section className="gateway">
      <div className="row personalb">
        <div className="col personal-hero-left businessleft responsivebusiness-col">
          <div className="inventory-sm-btn paybtn">
            <img
              src={loan1}
              alt="dlm-sm-btn-image"
              className="dlm-sm-btn-image"
            />
            <p>Payment Gateway</p>
          </div>
          <h1 className="title">Power your business with MontraPay</h1>
          <p>
            MontraPay is a payment gateway that you can simply intergrate with
            your website or Mobile App for your customers to make payments using
            their:
          </p>
          <ul className="list">
            <li>Debit &amp; Credit Cards.</li>
            <li>
              Bank Accounts by scanning QR code using their Mobile Banking Apps.
            </li>
            <li>
              Montra App where they can make payments using Montra account and
              even using Installments.
            </li>
            <li>USSD to make payment using USSD app.</li>
          </ul>
          {/* <img className="diagram" src={hero2} alt="hero2" /> */}
          <GatewayProcessContainer />
          <button className="cta paygatepayment">Contact Sales</button>
        </div>

        <div className="col personal-hero-right gatwayright responsivebusiness-col">
          <img src={hero} alt="hero" className="payment-gateway-hero" />
        </div>
      </div>
      {/* <img src={paymentgatewayhero} alt="paymentgatewayhero-image" /> */}
    </section>
  );
};

export default Hero;
