import React from "react";

function TapAndPay() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="27"
      fill="none"
      viewBox="0 0 46 27"
    >
      <rect
        width="6.04"
        height="8.779"
        x="26.521"
        y="12.457"
        stroke="#20336B"
        strokeWidth="0.5"
        rx="0.55"
        transform="rotate(-45.62 26.521 12.457)"
      ></rect>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeWidth="0.5"
        d="M38.051 22.57c-.044 1.265 2.015 1.75 4.816 2.709M34.743 21.066c-.3 1.806 3.611 2.709 3.912.301"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeWidth="0.5"
        d="M38.349 20.767c-3.01 1.204-3.611 0-3.611 0s-1.806-1.806-1.505-2.708"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeWidth="0.5"
        d="M39.856 17.154s-6.32-3.31-6.921-3.61c-.602-.302-.903.902-.602 1.805.3.903 2.708 2.558 3.912 2.558 1.204 0 3.01 4.363 3.01 4.363M36.844 13.545c3.31-.301 4.213.903 8.727 8.727M36.848 13.543h-.602M33.84 17.453c1.204 1.204.903 2.408 3.912 2.107"
      ></path>
      <path
        fill="#20336B"
        fillRule="evenodd"
        d="M33.025 22.192a16.05 16.05 0 001.63-1.277l.336.369c-3.52 3.16-9.064 5.198-15.301 5.198-10.637 0-19.26-5.928-19.26-13.24C.43 5.927 9.053 0 19.69 0c10.637 0 19.26 5.928 19.26 13.241 0 .054 0 .108-.002.162l-.499-.093v-.069c0-3.444-2.03-6.617-5.424-8.95C29.633 1.957 24.92.5 19.69.5S9.746 1.958 6.354 4.29C2.96 6.624.93 9.797.93 13.241c0 3.445 2.03 6.618 5.424 8.951 3.392 2.332 8.105 3.79 13.336 3.79 5.23 0 9.943-1.458 13.335-3.79z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#3FAFE9"
        d="M22.05 23.39c-.316-.093-.64-.47-.71-.831-.045-.236.008-.398.345-1.052 1.364-2.638 1.956-5.065 1.956-8.007 0-2.845-.523-5.061-1.826-7.716-.28-.568-.507-1.1-.507-1.177 0-.284.235-.694.49-.852.35-.22.938-.179 1.222.085.11.102.39.564.625 1.027.934 1.83 1.571 3.84 1.888 5.93.194 1.294.235 3.746.077 4.93-.288 2.184-.865 4.137-1.79 6.044-.56 1.153-.91 1.628-1.201 1.628a.952.952 0 00-.212.032c-.056.017-.219 0-.357-.04zm-3.794-1.984c-.488-.142-.84-.743-.699-1.19.033-.109.264-.608.512-1.111 1.043-2.131 1.477-4.412 1.266-6.652-.174-1.843-.503-3.004-1.335-4.7-.556-1.14-.585-1.315-.26-1.778.309-.438 1.08-.507 1.481-.138.28.256 1.153 2.078 1.49 3.109 1.112 3.41.942 7.074-.483 10.423-.735 1.716-1.25 2.248-1.973 2.037zm-3.913-2.013a1.19 1.19 0 01-.633-.775c-.045-.195.032-.414.39-1.137 1.314-2.662 1.327-5.223.044-7.825-.564-1.14-.576-1.368-.101-1.818.235-.223.336-.26.665-.26.556 0 .844.28 1.34 1.299.763 1.566 1.075 2.926 1.071 4.667-.004 1.79-.296 3.02-1.108 4.672-.467.946-.698 1.197-1.165 1.258-.142.012-.37-.02-.503-.081zm-3.653-1.875c-.24-.098-.511-.422-.592-.706-.09-.317-.017-.569.34-1.157.463-.76.569-1.165.569-2.155 0-.99-.106-1.4-.569-2.155-.158-.256-.312-.565-.349-.686-.15-.5.268-1.112.828-1.218.475-.09.8.122 1.238.804 1.263 1.972 1.263 4.602 0 6.559-.446.685-.941.929-1.465.714z"
      ></path>
    </svg>
  );
}

export default TapAndPay;
