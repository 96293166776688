/* React packages */
import React from 'react';
import ReactTooltip from "react-tooltip";
import NumberFormat from 'react-number-format';
import ForgotPin from "../ForgotPin/ForgotPin";
import axios from 'axios';

/**
 * Render premium comission of bank for maker section in profile.
 * <p>Maker able to do changes to their comission send it for approval to checker</p>

 <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>

 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
 * <li> <b>d-flex  :- </b> it is use for manage the layout, alignment, and sizing of grid columns, navigation, components, and more with a full suite of responsive flexbox utilities.</li>

 * @Author : Seema Kumari
 * @version :1.0
 */

class Premium extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: '',
      data: false,
      isDisabled: false,
      status: '',
      message: '',
      errors: [],
      errorMsg: '',
      symbol: '%',
      backerrors: [],
      fields: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }
  /**
   * validates the pin entered by maker.
   */
  handleSubmitvalidateForm() {
    let fields = this.state.fields;
    let errors = this.state.errors;
    let formIsValid = true;
    if (this.state.fields.pin == '' || this.state.fields.pin == null) {
      formIsValid = false;
      errors['pin'] = '*Please enter pin.';
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }
  /**
    *captures the pin entred by user.
    * @param {Event} e event which calls this method.
    */
  handleChange(e) {
    isDisabledSubmitButton = false;
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });

  }
  compareObjects(obj1, obj2) {
    let changes = [];
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        changes.push({
          key: key,
          originalValue: obj1[key],
          modifiedValue: obj2[key]
        });
      }
    }
    return changes;
  }

  /**
    *Submits the changes to server.
    * @param {Event} e event which calls this method.
    */

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.handleSubmitvalidateForm()) {
      this.removepercentile();
      this.setState({ errors: [] });

      let data = [];
      let companyDto = this.props.data?.companyDto || {};
      let oldDataCompanyDto = this.props.ModifiedData?.companyDto || {};

      // Retrieve current and old data for creditLifeAndAssetInsPremCheckers
      let dataarray = this.props.data?.creditLifeAndAssetInsPremCheckers || [];
      let oldDataArray = this.props.ModifiedData?.creditLifeAndAssetInsPremCheckers || [];

      console.log("Old Data (credit checkers):", oldDataArray);
      console.log("New Data (credit checkers):", dataarray);

      // Compare companyDto with oldDataCompanyDto
      const companyDtoChanges = this.compareObjects(oldDataCompanyDto, companyDto);

      // If there are changes, set isModified to true
      if (companyDtoChanges.length > 0) {
        companyDto.isModified = true;
        console.log("companyDto has changes:", companyDtoChanges);
      } else {
        companyDto.isModified = false;
        console.log("No changes in companyDto.");
      }

      // Iterate over creditLifeAndAssetInsPremCheckers to check for changes
      dataarray.forEach(input => {
        const oldinputData = oldDataArray.find(obj => obj.sequenceId === input.sequenceId);
        const changes = this.compareObjects(oldinputData, input);

        console.log("Changes (credit checkers):", changes);
        console.log("isEditable:", input?.isEditable);

        // Push to data only if changes exist and input is editable
        if (changes.length > 0 && input?.isEditable) {
          data.push(input);
        }
      });

      // Construct the payload
      let payload = {
        companyDto: companyDto,  // Send companyDto with updated isModified flag
        creditLifeAndAssetInsPremCheckers: data.length > 0 ? data : undefined // Send only if there are changes
      };

      console.log('Payload:', payload);
      let url = 'api/user/createprofile';
      const params = {
        email: localStorage.getItem("email"),
        userpin: this.state.fields.pin
      };

      axios.post(url, payload, {
        params: params,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
      })
        .then(response => {
          console.log('Server response:', response);
          const contents = response.data;

          if (response.status === 200 && contents.message === 'SUCCESS') {
            this.setState({
              isDisabled: true,
              status: contents.status,
              message: contents.message
            });
          } else {
            this.setState({
              backerrors: contents.errors || [],
              status: contents.status,
              message: contents.message
            });

            // Log errors
            this.state.backerrors.forEach(key => {
              console.log('Error:', key.message);
              this.setState({ errorMsg: key.message });
            });
          }

          // Check if handlestatus is a function before calling it
          if (typeof this.props.handlestatus === 'function') {
            this.props.handlestatus(this.state.status);
          } else {
            console.error('handlestatus is not a function');
          }
        })
        .catch(error => {
          console.error('Server error:', error.response || error.message);
          alert('Server error: ' + (error.response?.data?.message || error.message));
        });
    }
  }


  removepercentile() {
    this.props.data.creditLifeAndAssetInsPremCheckers.map((creditLifeAndAssetInsPremCheckers, i) => {
      creditLifeAndAssetInsPremCheckers.cliPremPercent = parseFloat(creditLifeAndAssetInsPremCheckers.cliPremPercent)
      creditLifeAndAssetInsPremCheckers.aiPremPercent = parseFloat(creditLifeAndAssetInsPremCheckers.aiPremPercent)
    })
  }

  render() {
    if (this.props.data.creditLifeAndAssetInsPremCheckers.length > 0) {
      return (
        <div>
          <div className="container-fluid premiumcont">
            <p className="signinheader inshead">CREDIT LIFE& ASSET INSURANCE PREMIUM</p>
            <form method="post" name="userRegistrationForm" onSubmit={this.handleSubmit} className="formroot">
              <div className="row">
                <div className="col-4 coltable ">
                  <label className="product3rd makpro profile-label">Product</label>
                  {this.props.data.creditLifeAndAssetInsPremCheckers.map((creditLifeAndAssetInsPremCheckers, i) => <div key={i}>
                    <label type="text" className="form-control loanlabelmak2 "
                      id="field1" disabled >{creditLifeAndAssetInsPremCheckers.productType}</label></div>)}
                </div>
                <div className="col-4 coltable">
                  <label className="productpre profile-label">CLI as % of <br />Premium </label>
                  <div className="inner-addon right-addon" key="">
                    {this.props.data.creditLifeAndAssetInsPremCheckers.map((creditLifeAndAssetInsPremCheckers, i) => <div key={i}>
                      <NumberFormat
                        className="form-control formpprofile"
                        data-tip
                        data-tip-disable={!this.props.editable}
                        data-for="noneditable"
                        name="cliPremPercentsa"
                        value={creditLifeAndAssetInsPremCheckers.cliPremPercent}
                        onChange={(e) => { this.props.handleChangecliPremPercent(e, i) }}
                        suffix={'%'}
                        style={{ borderColor: this.props.isCLIPremPercentlist[i] }}
                        disabled={!creditLifeAndAssetInsPremCheckers?.isEditable}
                      />
                      {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                        Non Editable
                      </ReactTooltip> */}
                    </div>)}
                  </div>
                </div>

                <div className="col-4 coltable">
                  <label className="productcomm2 setpre profile-label">Asset Insurance as % of Premium</label>
                  <div className="inner-addon right-addon" key="">
                    {this.props.data.creditLifeAndAssetInsPremCheckers.map((creditLifeAndAssetInsPremCheckers, i) => <div key={i}>
                      <NumberFormat className="form-control formpprofile2"
                        data-tip data-tip-disable={!this.props.editable}
                        data-for="noneditable" name="aiPremPercent"
                        //  value={Number} 
                        value={creditLifeAndAssetInsPremCheckers.aiPremPercent}
                        onChange={(e) => { this.props.handleChangeaiPremPercent(e, i) }}
                        suffix={'%'}
                        style={{ borderColor: this.props.isAIPremPercentlist[i] }}
                        disabled={!creditLifeAndAssetInsPremCheckers?.isEditable}
                      />
                      {/* <ReactTooltip id="noneditable" place="top" effect="solid">
                        Non Editable
                      </ReactTooltip> */}
                    </div>)}
                  </div>
                </div>
              </div>
              {/* Type Your User PIN  :- user can submit the changes request approval after entering user pin */}
              <div className="cardform">
                <label htmlFor="pin">Type Your User PIN </label>
                <input type="password" className="form-control cardinputfield" name="pin" onChange={this.handleChange.bind(this)} placeholder="xxxxx " />
                <div className="sign">
                  <input
                    type="submit"
                    className="btn btn-warning cardbutton"
                    style={{ backgroundColor: this.props.buttoncolor }}
                    onClick={this.handleSubmit}
                    value="Submit changes for approval"
                    disabled={isDisabledSubmitButton}
                  />
                </div>
                <ForgotPin />
              </div>
              <div className="errorMsgpin">{this.state.errors.pin}</div>
              {(() => {
                switch (this.props.fieldsvalid) {
                  case true: return <p className="errorMsgpin">All Fields are Mandatory</p>
                  default: return <div></div>
                }
              })()}
              {(() => {
                switch (this.state.message) {
                  case "SUCCESS": return <p className="submittedpin">Submitted Succesfully</p>
                  case "UNSUCCESS": return <div className="errorMsg"> <p className="invalidpin">{this.state.errorMsg}</p></div>;
                  default: return <div></div>
                }
              })()}
            </form>
            {/* user can recognise the fields/columns/rows functionality based on the label information */}
            <div className="third-Editable">
              <input type="submit" className=" editable-button" value="Editable" disabled />
              <input type="submit" className=" noteditable-button" value="Not Editable" disabled />
            </div>
          </div>
        </div>
      );
    }
    else {
      return (<p>Data is Empty</p>)
    }
  }
}
let isDisabledSubmitButton = false;
export default Premium;
