import React from "react";
import ForgotPin from "../../ForgotPin/ForgotPin";
import Loader from '../Loader';

/**
  CheckerPremium component  render's premium comission of bank for checker section in profile.
 @Author : Thrilok Kandra
 @version :1.0
 */

class CheckerPremium extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      status: "",
      errors: [],
      isDisabled: false,
    };
  }
  render() {
    return (
      <div>
        <div className="container-fluid prechecker">
          <p className="signinheader preasscheck">
            CREDIT LIFE & ASSET INSURANCE PREMIUM
          </p>
          <form method="post" name="userRegistrationForm" className="formroot">
            <div className="row">
              <div className="col-4 coltable">
                <label className="product3rd prepro">Product</label>
                {this.props.data.creditLifeAndAssetInsPremCheckers.map(
                  (creditLifeAndAssetInsPremCheckers, i) => (
                    <div key={i}>
                      <label
                        className="form-control loanlabelcheck "
                        name=""
                        id="field1"
                        disabled
                      >
                        {" "}
                        {creditLifeAndAssetInsPremCheckers.productType}{" "}
                      </label>
                    </div>
                  )
                )}
              </div>
              <div className="col-4 coltable">
                <label className="productpre">
                  CLI as % of <br />
                  Premium
                </label>
                {this.props.data.creditLifeAndAssetInsPremCheckers.map(
                  (checker, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isCLIPremPercentfieldclasslist[i],
                      }} >
                      <label
                        className="form-control formpprofilefield"
                        disabled
                      >
                        {checker.cliPremPercent + "%"}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isCLIPremPercent"
                          onChange={(e) =>
                            this.props.toggleChangeIsCLIPremPercent(e, i)
                          }
                          checked={checker.isCLIPremPercent}
                          disabled={this.props.isCLIPremPercentlist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isCLIPremPercentclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  )
                )}
              </div>

              <div className="col-4 coltable">
                <label className="productcomm2 checkprem">
                  Asset Insurance as % of Premium
                </label>
                {this.props.data.creditLifeAndAssetInsPremCheckers.map(
                  (creditLifeAndAssetInsPremCheckers, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isAIPremPercentfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="aiPremPercentsa"
                        disabled
                      >
                        {" "}
                        {creditLifeAndAssetInsPremCheckers.aiPremPercent +
                          "%"}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAIPremPercent"
                          onChange={(e) => {
                            this.props.toggleChangeIsAIPremPercent(e, i);
                          }}
                          checked={
                            creditLifeAndAssetInsPremCheckers.isAIPremPercent
                          }
                          disabled={this.props.isAIPremPercentlist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isAIPremPercentclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  )
                )}
              </div>
            </div>
          </form>
          {this.props.loader ? (
            <div>
              <Loader />
              <div className="cardform">
                <form method="post" name="userRegistrationForm">
                  <div className="form-group">
                    <label htmlFor="first">Type Your User PIN </label>
                    <input
                      type="password"
                      className="form-control cardinputfield"
                      name="pin"
                      value={this.state.fields.message}
                      onChange={(e) => this.props.handleChange(e)}
                      placeholder="xxxxx "
                    />
                    <div className="sign">
                      <input
                        type="submit"
                        className="btn btn-warning cardbutton"
                        style={{ backgroundColor: this.props.buttoncolor }}
                        value="Submit changes for approval"
                        onClick={(e) => this.props.handleSubmit(e)}
                      // disabled={this.props.isDisabled || this.props.editable}
                      />
                    </div>
                  </div>
                </form>
                <ForgotPin />
              </div>
            </div>
          ) : (
            <div className="cardform">
              <form method="post" name="userRegistrationForm">
                <div className="form-group">
                  <label htmlFor="first">Type Your User PIN </label>
                  <input
                    type="password"
                    className="form-control cardinputfield"
                    name="pin"
                    value={this.state.fields.message}
                    onChange={(e) => this.props.handleChange(e)}
                    placeholder="xxxxx "
                  />
                  <div className="sign">
                    <input
                      type="submit"
                      className="btn btn-warning cardbutton"
                      style={{ backgroundColor: this.props.buttoncolor }}
                      value="Submit changes for approval"
                      onClick={(e) => this.props.handleSubmit(e)}
                    // disabled={this.props.isDisabled || this.props.editable}
                    />
                  </div>
                </div>
              </form>
              <ForgotPin />
            </div>
          )}

          <div className="errorMsgpin">{this.props.pin}</div>
          {(() => {
            switch (this.props.message) {
              case "SUCCESS":
                return <p className="submittedpin">Submitted Succesfully</p>;
              case "UNSUCCESS":
                return (
                  <div className="errorMsgpin">
                    {this.props.errorMsg}
                  </div>
                );
              default:
                return <div></div>;
            }
          })()}

          <div className="checkerside-gridbutton">
            <div className="form-group card1">
              <label className="cardtext">Approved </label>
              <br />
              <img
                className="toogle-img"
                src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle1.png?alt=media&token=a825c329-274e-4a1c-831d-554cd94b2c88"
                alt="Approved"
              />
            </div>
            <div className="form-group card1">
              <label className="cardtext">Rejected </label>
              <br />
              <img
                className="toogle-img"
                src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle2.png?alt=media&token=2a0da633-8a16-4e7b-9140-71afbb43e86e"
                alt="Rejected"
              />
            </div>

            <div className="form-group  card1">
              <label className="cardtext">
                No changes requested for approval{" "}
              </label>
              <br />
              <div className="img-toole">
                <img
                  className="toogle-img2"
                  src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle3.png?alt=media&token=0fe3a799-1b79-4d79-87bb-68f4459f2b70"
                  alt="No changes requested for approval"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckerPremium;
