import React, { useState, useEffect } from "react";
import "./style.css";

import MontraSupplyChainSKE from "../../../../assets/images/montra-supply-chain-sdk.svg";
import ERPSKE from "../../../../assets/images/erp.svg";
import DistributionCompanySKE from "../../../../assets/images/distribution-company-hand.svg";
import DistributorsSKE from "../../../../assets/images/distributors.svg";
import OutletsUnderDirectSKE from "../../../../assets/images/outlets-under-direct.svg";
import MontraAppSKE from "../../../../assets/images/montra-app.svg";
import OutlestUnderIndirectSKE from "../../../../assets/images/outlets-under-indirect.svg";

import DistributionCompany from "../../../../assets/svg/distribution-company";
import Distributors from "../../../../assets/svg/distributors";
import OutletsUnderDirect from "../../../../assets/svg/outlets-under-direct";
import MontraApp from "../../../../assets/svg/montra-app";
import OutletsUnderIndirect from "../../../../assets/svg/outlets-under-indirect";
import ERP from "../../../../assets/svg/erp";
import SupplyChainSDK from "../../../../assets/svg/supply-chain-sdk";

import YU14 from "../../../../assets/svg/yu14";
import YU15 from "../../../../assets/svg/yu15";
import YU16 from "../../../../assets/svg/yu16";
import YU17 from "../../../../assets/svg/yu17";
import YU18 from "../../../../assets/svg/yu18";

const Main = ({ index, onMouseEnter, onMouseLeave }) => {
  const [showS, setShowS] = useState(false);
  const [isAnimationPaused, setAnimationPaused] = useState(false);

  const handleAnimMouseEnter = () => {
    setAnimationPaused(true);
    console.log("animation paused");
  };

  const handleAnimMouseLeave = () => {
    setAnimationPaused(false);
    console.log("animation started");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationPaused) {
        setShowS((prev) => {
          return !prev;
        });
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isAnimationPaused]);

  return (
    <div className="yu-relative">
      <div className="yu-flex gap-24 ml40">
        <div className="qwerty">
          <div className="ske">
            <img src={MontraSupplyChainSKE} alt="Montra Supply Chain SDK" />
            <p className="skep">Montra Supply Chain SDK</p>
          </div>
        </div>
        <div className="qwerty">
          <div className="ske">
            <img src={ERPSKE} alt="ERP SDK" />
            <p className="skep">ERP</p>
          </div>
          <div className="ske mt36">
            <img src={DistributionCompanySKE} alt="Distribution Company" />
            <p className="skep">Distribution Company Handheld/App</p>
          </div>
          <div className="ske mt82">
            <img src={DistributorsSKE} alt="Distributors" />
            <p className="skep">Distributors</p>
          </div>
        </div>
        <div className="qwerty">
          <div className="ske">
            <img src={OutletsUnderDirectSKE} alt="Outlets Under Direct" />
            <p className="skep">Outlets Under Direct</p>
          </div>
          <div className="ske ske-long-p ske-paddingTop mt94">
            <img src={MontraAppSKE} alt="Montra App Distributors" />
            <p className="skep ske-ske-long-p">
              Montra App Distributors, Direct & Indirect Outlets profited as
              Users
            </p>
          </div>
        </div>
        <div className="qwerty">
          <div className="ske outlets">
            <img src={OutlestUnderIndirectSKE} alt="Outlets Under Indirect Coverage" />
            <p className="skep">Outlets Under Indirect Coverage</p>
          </div>
        </div>
      </div>
      {showS && (
        <div
          className="yu-flex gap-24 yu-absolute mt24 font12 ml40"
          onMouseEnter={handleAnimMouseEnter}
          onMouseLeave={handleAnimMouseLeave}
        >
          {index === 1 ? (
            <>
              <div className="qwerty">
                <div className="ske-sc">
                  <SupplyChainSDK />
                  <p>Montra Supply Chain SDK</p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <ERP />
                  <p>ERP</p>
                </div>
                <div className="ske-sc mt36 ske-sc-heightchange">
                  <DistributionCompany />
                  <p>Distribution Company Handheld/App</p>
                </div>
                <div className="ske-sc mt82">
                  <Distributors />
                  <p>Distributors</p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <OutletsUnderDirect />
                  <p>Outlets Under Direct</p>
                </div>
                <div className="ske-long-p ske-sc mt94 ske-paddingTop ske-long-p">
                  <MontraApp />
                  <p className="long-p">
                    Montra App Distributors, Direct & Indirect Outlets profited
                    as Users
                  </p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc outlets">
                  <OutletsUnderIndirect />
                  <p className="short-long-p">
                    Outlets Under Indirect Coverage
                  </p>
                </div>
              </div>
            </>
          ) : index === 2 ? (
            <>
              <div className="qwerty">
                <div className="ske-sc">
                  <SupplyChainSDK />
                  <p>Montra Supply Chain SDK</p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <ERP />
                  <p>ERP</p>
                </div>
                <div className="ske-sc mt36 ske-sc-heightchange">
                  <DistributionCompany />
                  <p>Distribution Company Handheld/App</p>
                </div>
                <div className="ske-sc mt82">
                  <Distributors />
                  <p>Distributors</p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <OutletsUnderDirect />
                  <p>Outlets Under Direct</p>
                </div>
                <div className="ske-long-p ske-sc mt94 ske-paddingTop">
                  <MontraApp />
                  <p className="long-p">
                    Montra App Distributors, Direct & Indirect Outlets profited
                    as Users
                  </p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc outlets ske-sc-heightchange">
                  <OutletsUnderIndirect />
                  <p className="short-long-p">
                    Outlets Under Indirect Coverage
                  </p>
                </div>
              </div>
            </>
          ) : index === 3 ? (
            <>
              <div className="qwerty">
                <div className="ske-sc">
                  <SupplyChainSDK />
                  <p>Montra Supply Chain SDK</p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <ERP />
                  <p>ERP</p>
                </div>
                <div className="ske-sc mt36 ske-sc-heightchange">
                  <DistributionCompany />
                  <p>Distribution Company Handheld/App</p>
                </div>
                <div className="ske-sc mt82">
                  <Distributors />
                  <p>Distributors</p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <OutletsUnderDirect />
                  <p>Outlets Under Direct</p>
                </div>
                <div className="ske-long-p ske-sc mt94 ske-paddingTop">
                  <MontraApp />
                  <p className="long-p">
                    Montra App Distributors, Direct & Indirect Outlets profited
                    as Users
                  </p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc outlets ske-sc-heightchange">
                  <OutletsUnderIndirect />
                  <p className="short-long-p">
                    Outlets Under Indirect Coverage
                  </p>
                </div>
              </div>
            </>
          ) : index === 4 ? (
            <>
              <div className="qwerty">
                <div className="ske-sc">
                  <SupplyChainSDK />
                  <p>Montra Supply Chain SDK</p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <ERP />
                  <p>ERP</p>
                </div>
                <div className="ske-sc mt36 ske-sc-heightchange">
                  <DistributionCompany />
                  <p>Distribution Company Handheld/App</p>
                </div>
                <div className="ske-sc mt82">
                  <Distributors />
                  <p>Distributors</p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <OutletsUnderDirect />
                  <p>Outlets Under Direct</p>
                </div>
                <div className="ske-long-p ske-sc mt94 ske-paddingTop">
                  <MontraApp />
                  <p className="long-p">
                    Montra App Distributors, Direct & Indirect Outlets profited
                    as Users
                  </p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc outlets ske-sc-heightchange">
                  <OutletsUnderIndirect />
                  <p className="short-long-p">
                    Outlets Under Indirect Coverage
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="qwerty">
                <div className="ske-sc">
                  <SupplyChainSDK />
                  <p>Montra Supply Chain SDK</p>
                </div>
                <div className="left-image">
                  <div className="left-image-top"></div>
                  <div className="left-image-bottom"></div>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <ERP />
                  <p>ERP</p>
                </div>
                <div className="ske-sc mt36 ske-sc-heightchange">
                  <DistributionCompany />
                  <p>Distribution Company Handheld/App</p>
                </div>
                <div className="ske-sc mt82">
                  <Distributors />
                  <p>Distributors</p>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc">
                  <OutletsUnderDirect />
                  <p>Outlets Under Direct</p>
                </div>
                <div className="ske-long-p ske-sc mt94 ske-paddingTop">
                  <MontraApp />
                  <p className="long-p">
                    Montra App Distributors, Direct & Indirect Outlets profited
                    as Users
                  </p>
                </div>

                <div className="center-image">
                  <div className="center-image-bottom"></div>
                </div>
              </div>
              <div className="qwerty">
                <div className="ske-sc outlets ske-sc-heightchange">
                  <OutletsUnderIndirect />
                  <p>Outlets Under Indirect Coverage</p>
                </div>
              </div>
            </>
          )}

          <div
            className={
              index === 1 ? "yu-absolute ml-15 z-back" : "yu-absolute z-back"
            }
          >
            {index === 1 ? (
              <YU14 />
            ) : index === 2 ? (
              <YU15 />
            ) : index === 3 ? (
              <YU16 />
            ) : index === 4 ? (
              <YU18 />
            ) : (
              <YU17 />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
