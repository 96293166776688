import React from 'react';
import Sidebar from './Sidebar';
import CheckerHeader from './CheckerHeader';
import Accesslogo from "../../assets/images/logo7.svg"



/**
 * This AdminChecker component is render home page for checker section,There are no methods in this class.
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>img :- </b> This tags must have a defined src attribute. This defines the image to be displayed. </li>

 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 @Author : Thrilok Kandra
 @Date : 31 August 2020
 @version :1.0
*/

class AdminMaker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        if (localStorage.getItem("isLoggedIn") && "CHECKER" == localStorage.getItem("role").trim()) {
            return (
                <div>
                    { /* top-Header */}
                    <CheckerHeader />
                    <div id="wrapper">

                        { /* header menu */}
                        <Sidebar />
                        <section id="content-wrapper">
                            <div className="profile-content-div">
                                <img className="profile-i" src={Accesslogo} alt="profile" />
                                <p className="profile-content">Name : <span style={{ color: "#fbb40a" }}> {localStorage.getItem("name")}  </span> <br />
                                    User Type : <span style={{ color: "#fbb40a" }}>{localStorage.getItem("role")}</span>
                                </p>
                                <p className="profile-content1">Please click the left menu to upload / access information based on the user rights.
                                    If the menu is in grey instead of black font, it means that you do not have rights to access /operate the functionality.
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
            )

        }
        else {
            return (<p>Please Login AS AdminChecker</p>)

        }
    }
}
export default AdminMaker;
