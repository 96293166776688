import React from "react";

function YU12() {
  return (
    <svg
      width="511"
      height="65"
      viewBox="0 0 511 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        d="M509.502 50C511.002 54.6667 510.002 64 494.002 64C478.002 64 341.002 64 274.502 64C268.168 64.1667 255.602 60.9 256.002 46.5C256.402 32.1 256.168 20.8333 256.002 17C256.501 11.8333 254 1.5 240 1.5C226 1.5 85.5 1.5 17 1.5C11.5 1.49976 1.5 2.4 1.5 12"
        stroke="url(#paint0_radial_5220_7111)"
        stroke-width="2"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5220_7111"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(255.751 32.753) rotate(90) scale(31.253 254.251)"
        >
          <stop stop-color="#17D92A" />
          <stop offset="1" stop-color="#635BFF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU12;
// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   width="455"
//   height="111"
//   fill="none"
//   viewBox="0 0 455 111"
// >
//   <path
//     className="yu-path"
//     stroke="url(#paint0_radial_3806_11530)"
//     strokeWidth="2"
//     d="M0 52h25.501c4.667 0 14-2.898 14-14.491V10.673c.167-3.22 3-9.66 13-9.66h136c3.5-.18 10.5 1.502 10.5 9.66V37.51c.166 4.83 3.1 14.491 13.5 14.491H287"
//     pathLength="1"
//   ></path>
//   <path
//     className="yu-path"
//     stroke="url(#paint1_radial_3806_11530)"
//     strokeWidth="2"
//     d="M0 61.25h25.53c4.671 0 14.015 2.762 14.015 13.805v25.564c.167 3.068 3.003 9.203 13.014 9.203h136.15c3.504.171 10.512-1.431 10.512-9.203V75.055c.166-4.601 3.103-13.804 13.514-13.804H281L294.5 56H441c4.333.5 13-.2 13-7v-3"
//     pathLength="1"
//   ></path>
//   <defs>
//     <radialGradient
//       id="paint0_radial_3806_11530"
//       cx="0"
//       cy="0"
//       r="1"
//       gradientTransform="matrix(0 25.5 -202 0 202 26.5)"
//       gradientUnits="userSpaceOnUse"
//     >
//       <stop stopColor="#17D92A"></stop>
//       <stop offset="1" stopColor="#FFBB08"></stop>
//     </radialGradient>
//     <radialGradient
//       id="paint1_radial_3806_11530"
//       cx="0"
//       cy="0"
//       r="1"
//       gradientTransform="matrix(0 -53 227 0 227 57)"
//       gradientUnits="userSpaceOnUse"
//     >
//       <stop stopColor="#17D92A"></stop>
//       <stop offset="1" stopColor="#FFBB08"></stop>
//     </radialGradient>
//   </defs>
// </svg>
