import React from "react";
// import inventory1 from "../../../assets/images/inventory1.svg";
import inventory2 from "../../../assets/images/inventory2.webp";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import loan1 from "../../../assets/images/managein.svg";
import InventoryProcessContainer from "./InventoryProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="inventory">
        <div className="row personalb">
          <div className=" col-md-7 col-xl-7 col inventory-content-col responsivebusiness-col">
            <div className="inventory-sm-btn">
              <img
                src={loan1}
                alt="dlm-sm-btn-image"
                className="dlm-sm-btn-image"
              />
              <p>Inventory Management</p>
            </div>
            <h1>
              <span>
                Buy, Sell <span className="andfont"> & </span> Manage
              </span>{" "}
              your Inventories <span className="andfont"> & </span> even{" "}
              <span>Place Orders</span> to the linked Distributors
            </h1>
            <ul>
              <li>
                You can buy inventories from other Businesses on Montra for
                which they can send invoices to you so that you can make payment
                using your Montra Business Account. Similarly, you can send
                invoices to other buyers on Montra App and get paid instantly.
              </li>
              <li>
                You can also place Orders for the products you want to buy from
                the Distributors you have already linked to your Montra App.{" "}
              </li>
            </ul>
            {/* <img className="inventory1" src={inventory1} alt="" /> */}
            <InventoryProcessContainer />
            <div className="app-download agencyapp">
              <img src={appstore} alt="appstore" />
              <img src={playstore} alt="playstore" />
            </div>
          </div>
          <div className="col-md-5 col-xl-5 inventory-hero2 responsivebusiness-col">
            <img src={inventory2} alt="inventory" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
