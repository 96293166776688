import React from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import UserHeader from "./userHeader";


/**
 * Render field to enter otp.
 * <p>Captures the otp enterer during login and sends it to server for verification.</p>
 * @Author : Seema Kumari
 * @version :1.0
 */


class Otp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      isActive: false,
      status: '',
      serverErr: ''
    }

    this.handleChange = this.handleChange.bind(this);

  };

  handleShow = () => {
    this.setState({
      isActive: true
    })
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }

  handleChangePartnerType(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let fields = this.state.fields;
      let url = 'https://api.montra.org/moneylinkweb/api/user/userpin';
      let params = {
        email: localStorage.getItem("email"),
        pin: this.state.fields.registerpassword,
      };
      axios.post(url, {}, {
        params: params,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*',
        }
      })
        .then((response) => {
          if (response.status === 200 && response.data.message === 'SUCCESS') {
            this.setState({
              role: response.data.data.role,
              status: response.data.status,
              message: response.data.message,
              forgotPin: true
            });
            localStorage.setItem("logintype", "SIGNIN");
          } else {
            this.setState({
              backerrors: response.data.errors,
              status: response.data.status,
              message: response.data.message,
              serverErr: response.data.errors[0].message
            });
            this.state.backerrors.map(key => {
              this.setState({
                errorMsg: key.message
              });
            });
          }
        })
        .catch(() => console.log("Can’t access " + url + " response. Blocked by browser?"));
    };
  };




  validateForm() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["registerpassword"]) {
      formIsValid = false;
      errors["registerpassword"] = "*Please enter your pin.";
    }
    if (((fields["registerconfirmpassword"] && fields["registerpassword"]) !== "undefined") && (fields["registerconfirmpassword"] !== fields["registerpassword"])) {
      formIsValid = false;
      errors["registerconfirmpassword"] = "*Pin doesn't match!!.";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }


  render() {
    if (this.state.forgotPin == true) {
      return (
        <Navigate to="/MoneyLink/UserPinSetupSuccess" />
      )
    } else {

      return (
        <div>
          <UserHeader />
          <div className="partner-height">
            <div className="forgotpinchange">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4">
                  </div>
                  <div className="col-md-4">
                    <h5 className="headerpass">  Reset Your Pin </h5>
                    <form>
                      <div className="form-group">
                        <label htmlFor="number" className='newpinfiled'>New Pin </label>
                        <input type="number" name="registerpassword" className="form-control" onChange={this.handleChange.bind(this)} />
                        <div className="errorMsgpinset">{this.state.errors.registerpassword}</div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="number" className='newpinfiled'> Retype Pin </label>
                        <input type="number" name="registerconfirmpassword" className="form-control" onChange={this.handleChange.bind(this)} />
                        <div className="errorMsgpinset">{this.state.errors.registerconfirmpassword}</div>
                      </div>
                      <br />
                      <div className="sign">
                        <input type="submit" className="btn btn-warning partnerbutton" value="Submit" onClick={this.handleSubmit.bind(this)} />
                        <p className="download">{this.state.successMessage}</p>
                        <div className="errorMsg">{this.state.serverErr}</div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-4">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      );
    }
  }
}




export default Otp;
