/* React packages */
import React from "react";
import Sidebar from "./Sidebar";
import CompanyProfile from "./CompanyProfile";
import Premium from "./Premium";
import PaylinkComission from "./PaylinkComission";
import CommonHeader from "../header/CommonHeader";
import axios from 'axios';

/**
* Renders profile  section of bank for maker section.
* <p>Maker is allowed make the changes</p>
* <p>Maker can submit the changes.</p>
@Author : Seema Kumari
@version :1.0
*/

class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      oldData: [],
      editable: false,
      buttoncolor: "",
      isCLIPremPercentlist: [],
      isAIPremPercentlist: [],
      companyDtoBordercolor: {
        companyName: "",
        companyType: "",
        country: "",
        cliPremPercent: "",
        aiPremPercent: "",
      },
      data: {
        companyDto: {
          companyName: "",
          companyId: "",
          companyType: "",
          country: "",
          currency: "",
          registrationDate: "",
          status: "",
          insPartnerForCLI: "",
          insPartnerForAI: "",
          isCompanyName: "",
          isInsPartnerForCLI: "",
          isInsPartnerForAI: "",
          isCompanyType: "",
          isCountry: "",
        },
        paylinkCommission: [
          { originationComm: "", loanMgtComm: "", loanRepayComm: "" },
        ],
        collectionBuckets: [
          {
            collXdays: "",
            coll30DPD: "",
            coll60DPD: "",
            coll90DPD: "",
            coll120DPD: "",
            collBET180n360DPD: "",
            collGT360DPD: "",
          },
        ],
        insuranceCommissions: [{ premCollectedPercent: "" }],
        creditLifeAndAssetInsPremCheckers: [
          {
            productType: "",
            cliPremPercent: "",
            aiPremPercent: "",
            isCLIPremPercent: "",
            isAIPremPercent: "",
          },
        ],
      },
    };
  }


  /**
   * fetch data from server and set to fields
   */

  componentDidMount() {
    const url = 'api/user/profile';
    const params = {
      email: localStorage.getItem("email"),
    };
    axios.get(url, {
      params: params,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    })
      .then(response => {
        const contents = response.data;
        if (contents.status === 200 && contents.message === "SUCCESS") {
          let a = contents.data.Profile;
          let b = JSON.parse(JSON.stringify(a));
          this.setState({
            oldData: b,
            data: a,
            // editable: contents.data.editable
          }, () => {
            this.setBackgroundColor();
            // if (!this.state.editable) {
            //   this.setBorderColor();
            // }
            // this.setNvalue();
          });
        } else {
          alert("Server error");
        }
      })
      .catch(error => {
        console.error('Error fetching profile data:', error);
        alert('Failed to fetch data. Please try again later.');
      });
  }


  setBackgroundColor() {
    let dataarray = Array.isArray(this.state.data) ? [...this.state.data] : [];
    let backgroundColor = [...(this.state.backGroundColor || [])];
    dataarray.forEach((profile, i) => {
      if (profile?.isEditable) {
        backgroundColor[i] = "#dadada";
      }
      console.log("color", profile?.isEditable);
    });
    this.setState({
      backgroundColor,
      dataarray
    });
  }





  /**
   * Set dafault for new fresh enalbe request after approval from checker
   */
  setNvalue() {
    this.state.data.creditLifeAndAssetInsPremCheckers.map((CheckerProfile, i) => {
      CheckerProfile.isAIPremPercent = "N";
      CheckerProfile.isCLIPremPercent = "N";
    }
    );
    this.state.data.companyDto.isInsPartnerForCLI = "N";
    this.state.data.companyDto.isInsPartnerForAI = "N";
    this.state.data.companyDto.isCompanyName = "N";
    this.state.data.companyDto.isCompanyType = "N";
    this.state.data.companyDto.isCountry = "N";
  }
  setBorderColor() {
    const newIsAIPremPercentlist = [...this.state.isAIPremPercentlist];
    const newIsCLIPremPercentlist = [...this.state.isCLIPremPercentlist];
    const newCompanyDtoBordercolor = { ...this.state.companyDtoBordercolor };


    this.state.data.creditLifeAndAssetInsPremCheckers.forEach(
      (checkerProfile, i) => {
        if (checkerProfile.isAIPremPercent === "A") {
          newIsAIPremPercentlist[i] = "#e90505";
        }
        if (checkerProfile.isCLIPremPercent === "A") {
          newIsCLIPremPercentlist[i] = "#e90505";
        }
      }
    );

    if (this.state.data.companyDto.isInsPartnerForCLI === "A") {
      newCompanyDtoBordercolor.cliPremPercent = "#e90505";
    }
    if (this.state.data.companyDto.isInsPartnerForAI === "A") {
      newCompanyDtoBordercolor.aiPremPercent = "#e90505";
    }
    if (this.state.data.companyDto.isCompanyName === "A") {
      newCompanyDtoBordercolor.companyName = "#e90505";
    }
    if (this.state.data.companyDto.isCompanyType === "A") {
      newCompanyDtoBordercolor.companyType = "#e90505";
    }
    if (this.state.data.companyDto.isCountry === "A") {
      newCompanyDtoBordercolor.country = "#e90505";
    }
    this.setState({
      companyDtoBordercolor: newCompanyDtoBordercolor,
      isCLIPremPercentlist: newIsCLIPremPercentlist,
      isAIPremPercentlist: newIsAIPremPercentlist,
    });
  }

  /**
   * Capture the change made by maker,stores into data object for company details.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangeCompanyProfile(e, i) {
    let data = { ...this.state.data };
    const oldData = this.state.oldData;
    const updateField = (fieldName, isFieldName, oldFieldName) => {
      data.companyDto[fieldName] = e.target.value;
      data.companyDto[isFieldName] = "A";
      if (oldData.length > i && oldData[i] && e.target.value === oldData[i][oldFieldName]) {
        data.companyDto[isFieldName] = "N";
      }
      this.setState({
        data,
        fieldsvalid: data.companyDto[fieldName] === "",
      });
    };
    switch (e.target.name) {
      case "companyName":
        updateField("companyName", "isCompanyName", "companyName");
        break;
      case "companyType":
        updateField("companyType", "isCompanyType", "companyType");
        break;
      case "country":
        updateField("country", "isCountry", "country");
        break;
      case "lifeinsurance":
        updateField("insPartnerForCLI", "isInsPartnerForCLI", "insPartnerForCLI");
        break;
      case "assetinsurance":
        updateField("insPartnerForAI", "isInsPartnerForAI", "insPartnerForAI");
        break;
      default:
        break;
    }
  }

  /**
   * Capture the change made by maker,stores into data object for aiprempercent.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData 
   */
  handleChangeaiPremPercent(e, i) {
    let data = { ...this.state.data };
    const oldData = this.state.oldData;
    data.creditLifeAndAssetInsPremCheckers[i].aiPremPercent = e.target.value;
    data.creditLifeAndAssetInsPremCheckers[i].isAIPremPercent = "A";
    if (oldData.length > i && oldData[i] && e.target.value === oldData[i].aiPremPercent) {
      data.creditLifeAndAssetInsPremCheckers[i].isAIPremPercent = "N";
    }
    this.setState({
      data: data,
      fieldsvalid: data.creditLifeAndAssetInsPremCheckers[i].aiPremPercent === "",
    });
  }
  /**
   * Capture the change made by maker,stores into data object for cliprempercent.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangecliPremPercent(e, i) {
    let data = { ...this.state.data };
    const oldData = this.state.oldData;
    data.creditLifeAndAssetInsPremCheckers[i].cliPremPercent = e.target.value;
    data.creditLifeAndAssetInsPremCheckers[i].isCLIPremPercent = "A";
    if (oldData.length > i && oldData[i] && e.target.value === oldData[i].cliPremPercent) {
      data.creditLifeAndAssetInsPremCheckers[i].isCLIPremPercent = "N";
    }
    this.setState({
      data: data,
      fieldsvalid: data.creditLifeAndAssetInsPremCheckers[i].cliPremPercent === "",
    });
  }


  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "MAKER" === localStorage.getItem("role").trim()
    ) {
      return (
        <div>
          <CommonHeader />
          <Sidebar />
          <div id="maincontent" className="content">
            <section id="content-wrapper">
              <div className="backloan">
                <div className="container-fluid containermaker">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 profilediv">
                      <CompanyProfile
                        companyDtoBordercolor={this.state.companyDtoBordercolor}
                        data={this.state.data}
                        editable={this.state.editable}
                        handleChangeCompanyProfile={(e) =>
                          this.handleChangeCompanyProfile(e)
                        }
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5 profilesecdiv">
                      <PaylinkComission data={this.state.data} />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <Premium
                        isAIPremPercentlist={this.state.isAIPremPercentlist}
                        isCLIPremPercentlist={this.state.isCLIPremPercentlist}
                        buttoncolor={this.state.buttoncolor}
                        editable={this.state.editable}
                        ModifiedData={this.state.oldData}

                        handleChangeaiPremPercent={(e, i) =>
                          this.handleChangeaiPremPercent(e, i)
                        }
                        handleChangecliPremPercent={(e, i) =>
                          this.handleChangecliPremPercent(e, i)
                        }
                        data={this.state.data}
                        fieldsvalid={this.state.fieldsvalid}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      );
    } else {
      return <p>Please Login As a AdminMaker</p>;
    }
  }
}
export default Profile;
