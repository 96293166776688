import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import Delivery from "../../../assets/images/uday/personal-icons/Home-icons/Business/store/delivery-blue.svg";
import Dinein from "../../../assets/images/uday/personal-icons/Home-icons/Business/store/dine-in-blue.svg";
import HomeServices from "../../../assets/images/uday/personal-icons/Home-icons/Business/store/homeservice-blue.svg";
import Appointments from "../../../assets/images/uday/personal-icons/Home-icons/Business/store/appointment-blue.svg";
import businessanimg3 from "../../../assets/images/businessimg3.svg";

const steps = [
  {
    title: "Montra Store Merchants",
    image: businessanimg3,
    className: "direction-img",
  },
  { image: Delivery, description: "Delivery/ Takeout/ Rentals" },
  { image: Dinein, description: "Dine-in" },
  { image: HomeServices, description: "Home Services" },
  { image: Appointments, description: "Appointments" },
];

const StoreProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#FFFFFF"
        circleBorderColor="#FFFFFF"
        textColor="#FFFFFF"
        circelBg="#FFFFFF"
        topDescriptionClass="homebusiness-store-topdescription"
      />
    </div>
  );
};

export default StoreProcessContainer;
