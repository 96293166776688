import React from "react";

function MontraCustomers() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#223872"
        fillRule="evenodd"
        d="M12.555 1.761a3.153 3.153 0 100 6.307 3.153 3.153 0 000-6.307zM8.141 4.915a4.415 4.415 0 118.83 0 4.415 4.415 0 01-8.83 0zM9.416 9.904a.63.63 0 000 1.262h4.587a6.938 6.938 0 016.791 5.522l.285 1.367a3.153 3.153 0 01-3.087 3.797H9.16v1.261h8.832c2.8 0 4.893-2.574 4.322-5.315l-.285-1.368a8.199 8.199 0 00-8.026-6.526H9.416z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#3FAFE9"
        strokeWidth="1.5"
        d="M4.998 13.406c-3.747 7.078 0 9.16 3.077 9.081M5 13.407l.416-.833"
      ></path>
      <path
        fill="#3FAFE9"
        stroke="#3FAFE9"
        d="M3.544 10.922l4.107-.048-.34 3.715-3.767-3.667z"
      ></path>
    </svg>
  );
}

export default MontraCustomers;
