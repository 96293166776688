export default function billerIcon() {
  return (
    <svg
      width="37"
      height="29"
      viewBox="0 0 37 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2569 16.5147C16.8067 15.695 15.3725 14.6758 15.3725 14.6758L15.257 14.7558C14.2324 15.4572 13.0992 15.8696 12.0669 15.8696H11.9695C10.9372 15.8696 9.804 15.4572 8.77939 14.7558L8.66386 14.6758C8.66386 14.6758 7.22978 15.695 3.77953 16.5147C-1.31364 17.8984 0.215651 23.5299 0.214178 23.5736C0.37459 24.2007 0.468088 24.4163 0.549552 24.4405C5.66421 26.0966 18.3723 26.0966 23.487 24.4405C23.5685 24.4163 23.662 24.2007 23.8224 23.5736C23.8209 23.5299 25.3706 17.9208 20.2569 16.5147Z"
        fill="#20336B"
      />
      <path
        d="M17.6146 7.30905L17.5713 7.26361C17.2806 7.00638 17.3033 7.02112 17.3033 7.02112C17.3033 7.02112 17.8103 4.54849 17.4037 3.3792C16.7655 1.5118 12.9528 0.260229 12.1881 0.114759C12.1883 0.113375 11.723 0.0233964 11.6933 0.0232158C11.6933 0.0232158 11.0918 -0.0943277 10.3947 0.199561C9.94067 0.368384 7.56703 1.4087 6.89331 3.3792C6.4868 4.54849 6.99382 7.02112 6.99382 7.02112C6.99382 7.02112 7.01655 7.00638 6.7258 7.26361L6.68252 7.30905C6.48586 7.52488 6.53444 8.20017 6.7494 8.90272C6.94419 9.6066 7.19807 9.79576 7.25891 9.96061C7.87559 12.7591 10.0199 15.1485 12.2051 15.1485C14.3903 15.1485 16.4214 12.7591 17.0381 9.96061C17.099 9.79582 17.3529 9.6066 17.5477 8.90272C17.7626 8.20011 17.8169 7.47848 17.6146 7.30905Z"
        fill="#20336B"
      />
      <path d="M28.0547 18.6465V21.9563" stroke="#20336B" stroke-width="0.5" />
      <path
        d="M29.3125 18.6465V21.3701C29.3125 21.4806 29.402 21.5701 29.5125 21.5701H30.5464"
        stroke="#20336B"
        stroke-width="0.5"
      />
      <path
        d="M31.3281 18.6465V21.3701C31.3281 21.4806 31.4177 21.5701 31.5281 21.5701H32.5621"
        stroke="#20336B"
        stroke-width="0.5"
      />
      <path
        d="M33.7837 21.6936V16.559C33.7837 16.3779 33.5622 16.29 33.4381 16.4219L32.6025 17.3097C32.5235 17.3936 32.3902 17.3936 32.3112 17.3097L31.2926 16.2275C31.2077 16.1372 31.0618 16.1452 30.9872 16.2443L30.2112 17.2748C30.1366 17.3739 29.9908 17.3819 29.9058 17.2916L28.9043 16.2275C28.8193 16.1372 28.6734 16.1452 28.5989 16.2443L27.8399 17.2522C27.7599 17.3585 27.6003 17.3585 27.5203 17.2522L26.7614 16.2443C26.6868 16.1452 26.5409 16.1372 26.4559 16.2275L25.4374 17.3097C25.3584 17.3936 25.2251 17.3936 25.1461 17.3097L24.3105 16.4219C24.1863 16.29 23.9648 16.3779 23.9648 16.559V28.1631C23.9648 28.3531 24.2049 28.4361 24.3222 28.2865L25.1344 27.2511C25.2144 27.149 25.369 27.149 25.4491 27.2511L26.443 28.5183C26.5285 28.6273 26.6962 28.6185 26.7698 28.5012L27.5107 27.3205C27.5891 27.1955 27.7711 27.1955 27.8495 27.3205L28.604 28.5229C28.6736 28.6338 28.8293 28.649 28.919 28.5536L30.3339 27.0505"
        stroke="#20336B"
        stroke-width="0.5"
        stroke-linecap="round"
      />
      <mask id="path-7-inside-1_4720_5407" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.2266 19.4465C25.2266 19.0047 25.5847 18.6465 26.0266 18.6465H26.4722C26.914 18.6465 27.2722 19.0047 27.2722 19.4465V21.2114C27.2722 21.6532 26.914 22.0114 26.4722 22.0114H26.0266C25.5847 22.0114 25.2266 21.6533 25.2266 21.2114V19.4465ZM25.7344 19.1113H26.7572V20.1456H25.7344V19.1113ZM26.7572 20.5469H25.7344V21.5812H26.7572V20.5469Z"
        />
      </mask>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.2266 19.4465C25.2266 19.0047 25.5847 18.6465 26.0266 18.6465H26.4722C26.914 18.6465 27.2722 19.0047 27.2722 19.4465V21.2114C27.2722 21.6532 26.914 22.0114 26.4722 22.0114H26.0266C25.5847 22.0114 25.2266 21.6533 25.2266 21.2114V19.4465ZM25.7344 19.1113H26.7572V20.1456H25.7344V19.1113ZM26.7572 20.5469H25.7344V21.5812H26.7572V20.5469Z"
        fill="#20336B"
      />
      <path
        d="M26.7572 19.1113H27.2572V18.6113H26.7572V19.1113ZM25.7344 19.1113V18.6113H25.2344V19.1113H25.7344ZM26.7572 20.1456V20.6456H27.2572V20.1456H26.7572ZM25.7344 20.1456H25.2344V20.6456H25.7344V20.1456ZM25.7344 20.5469V20.0469H25.2344V20.5469H25.7344ZM26.7572 20.5469H27.2572V20.0469H26.7572V20.5469ZM25.7344 21.5812H25.2344V22.0812H25.7344V21.5812ZM26.7572 21.5812V22.0812H27.2572V21.5812H26.7572ZM26.0266 18.1465C25.3086 18.1465 24.7266 18.7285 24.7266 19.4465H25.7266C25.7266 19.2808 25.8609 19.1465 26.0266 19.1465V18.1465ZM26.4722 18.1465H26.0266V19.1465H26.4722V18.1465ZM27.7722 19.4465C27.7722 18.7285 27.1901 18.1465 26.4722 18.1465V19.1465C26.6379 19.1465 26.7722 19.2808 26.7722 19.4465H27.7722ZM27.7722 21.2114V19.4465H26.7722V21.2114H27.7722ZM26.4722 22.5114C27.1901 22.5114 27.7722 21.9294 27.7722 21.2114H26.7722C26.7722 21.3771 26.6379 21.5114 26.4722 21.5114V22.5114ZM26.0266 22.5114H26.4722V21.5114H26.0266V22.5114ZM24.7266 21.2114C24.7266 21.9294 25.3086 22.5114 26.0266 22.5114V21.5114C25.8609 21.5114 25.7266 21.3771 25.7266 21.2114H24.7266ZM24.7266 19.4465V21.2114H25.7266V19.4465H24.7266ZM26.7572 18.6113H25.7344V19.6113H26.7572V18.6113ZM27.2572 20.1456V19.1113H26.2572V20.1456H27.2572ZM25.7344 20.6456H26.7572V19.6456H25.7344V20.6456ZM25.2344 19.1113V20.1456H26.2344V19.1113H25.2344ZM25.7344 21.0469H26.7572V20.0469H25.7344V21.0469ZM26.2344 21.5812V20.5469H25.2344V21.5812H26.2344ZM26.7572 21.0812H25.7344V22.0812H26.7572V21.0812ZM26.2572 20.5469V21.5812H27.2572V20.5469H26.2572Z"
        fill="#20336B"
        mask="url(#path-7-inside-1_4720_5407)"
      />
      <path
        d="M25.6562 23.2246H28.9292"
        stroke="#20336B"
        stroke-width="0.5"
        stroke-linecap="round"
      />
      <path
        d="M25.6562 24.8789H28.9292"
        stroke="#20336B"
        stroke-width="0.5"
        stroke-linecap="round"
      />
      <path
        d="M35.9873 25.4074C35.9873 27.0999 34.6313 28.4672 32.9644 28.4672C31.2975 28.4672 29.9414 27.0999 29.9414 25.4074C29.9414 23.7149 31.2975 22.3477 32.9644 22.3477C34.6313 22.3477 35.9873 23.7149 35.9873 25.4074Z"
        stroke="#20336B"
        stroke-width="0.5"
      />
      <path
        d="M31.4883 25.761L32.4371 26.7205C32.5153 26.7996 32.6432 26.7996 32.7215 26.7205L34.4885 24.9336"
        stroke="#00BAF2"
        stroke-width="0.5"
        stroke-linecap="round"
      />
    </svg>
  );
}
