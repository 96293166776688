import React from "react";

function YU9() {
  return (
    // <svg
    //   width="388"
    //   height="316"
    //   viewBox="0 0 388 316"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     className="yu-path-business-anim1"
    //     pathLength="1"
    //     d="M338 0.5V284C338.001 295 335.8 314.5 317 314.5C298.2 314.5 223.833 315 187.999 315H92C90.5 315 83 315 65 315C47 315 40 306.5 40 300C40 297.667 40 289.2 40 274C39.6667 264.833 35.1 246.5 19.5 246.5M324.5 0.5V151.5C324.5 159 319.9 173 305.5 173C291.1 173 220.167 173 187 173H91.5H65C48.5 173 40 177.3 40 190.5C40 203.7 40 213.167 40 219.5C40 231.5 40.5 246.5 19.5 246.5M19.5 246.5C5.1 246.5 0.333333 246.5 0 246.5"
    //     stroke="url(#paint0_radial_3450_10973)"
    //     stroke-width="1.5"
    //   />
    //   <defs>
    //     <radialGradient
    //       id="paint0_radial_3450_10973"
    //       cx="0"
    //       cy="0"
    //       r="1"
    //       gradientUnits="userSpaceOnUse"
    //       gradientTransform="translate(366.091 157.172) rotate(90) scale(157.833 20.9092)"
    //     >
    //       <stop stop-color="#0048E5" />
    //       <stop offset="1" stop-color="#7A73FF" />
    //     </radialGradient>
    //   </defs>
    // </svg>
    // <svg
    //   width="339"
    //   height="316"
    //   viewBox="0 0 339 316"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     className="yu-path"
    //     pathLength="1"
    //     d="M338 0.5V284C338.001 295 335.8 314.5 317 314.5C298.2 314.5 223.833 315 187.999 315H92C90.5 315 83 315 65 315C47 315 40 306.5 40 300C40 297.667 40 289.2 40 274C39.6667 264.833 35.1 246.5 19.5 246.5M324.5 0.5V151.5C324.5 159 319.9 173 305.5 173C291.1 173 220.167 173 187 173H91.5H65C48.5 173 40 177.3 40 190.5C40 203.7 40 213.167 40 219.5C40 231.5 40.5 246.5 19.5 246.5M19.5 246.5C5.1 246.5 0.333333 246.5 0 246.5"
    //     stroke="url(#paint0_radial_3450_10973)"
    //     stroke-width="2"
    //   />
    //   <defs>
    //     <radialGradient
    //       id="paint0_radial_3450_10973"
    //       cx="0"
    //       cy="0"
    //       r="1"
    //       gradientUnits="userSpaceOnUse"
    //       gradientTransform="translate(317.091 157.172) rotate(90) scale(157.833 20.9092)"
    //     >
    //       <stop stop-color="#0048E5" />
    //       <stop offset="1" stop-color="#7A73FF" />
    //     </radialGradient>
    //   </defs>
    // </svg>
    <svg
      width="336"
      height="324"
      viewBox="0 0 336 324"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        d="M0 238.761H27.5C37.5 238.761 48.5 234.773 48.5 222.81C48.5 210.847 48.5 195.229 48.5 188.915C48.8333 183.931 52.5 174.061 64.5 174.46C76.5 174.859 221.667 175.124 288.5 174.958C304 174.92 316 161.998 316 150.035C316 138.073 316 48.6826 316 0M0 253.714H27.5C34.5 253.548 48.5 257.104 48.5 272.656C48.5 288.208 48.5 302.065 48.5 307.049C49.1667 312.366 53.3 323 64.5 323C75.7 323 234.5 319.677 312.5 318.015C322.5 318.015 335 307.049 335 296.582C335 286.605 333.167 97.3652 333 0"
        stroke="url(#paint0_radial_5220_7447)"
        stroke-width="2"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5220_7447"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.6135 160.907) rotate(90) scale(162.099 20.6135)"
        >
          <stop stop-color="#0048E5" />
          <stop offset="1" stop-color="#7A73FF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU9;

//uday changes
// {/* <svg
// xmlns="http://www.w3.org/2000/svg"
// width="388"
// height="316"
// fill="none"
// viewBox="0 0 388 316"
// >
// <path
//   className="yu-path"
//   stroke="url(#paint0_radial_3450_10973)"
//   strokeWidth="2"
//   d="M1 .5V284c0 11 2.2 30.5 21 30.5s93.167.5 129.001.5H351.5c5.167.333 15.5-.9 15.5-8.5V220v1c0"
//   pathLength="1"
// ></path>
// <path
//   className="yu-path"
//   stroke="url(#paint0_radial_3450_10973)"
//   strokeWidth="1.5"
//   // d="M20 .5V140c0 11 2.2 30.5 21 30.5s93.167.5 129.001.5H371v55c100.167.333  15.5-.9 15.5-8.5V200v1c0"
//   // pathLength="1"
//   d="M20 .5V140c0 11 2.2 30.5 21 30.5s93.167.5 129.001.5H371v30c5.167.333 21.5 0h223c5-.266 15 1.559 15 10.98V28.6"
//   pathLength="1"
// ></path>
// {/* M0 64.968h38c5.833.395 17.4-2.723 17-18.354-.4-15.63-.167-23.683 0-29.012 0-4.737 4.291-16.578 15.5-16.578 11.5 0 19 .001 21.5 0h223c5-.266 15 1.559 15 10.98V28.6 */}
// {/* 10.055h220.297c3.174-.153 9.52 1.645 9.52 10.055v75.967C198.412  49h29.562l96 6h97.5c6 .667 18 5.3 18 18.5V283 */}
// {/* <path
//   className="yu-path"
//   stroke="url(#paint0_radial_3450_10973)"
//   strokeWidth="1.5"
//   d="M100 .5V284c0 11 2.2 30.5 21 30.5s93.167.5 129.001.5H371.5c5.167.333 15.5-.9 15.5-8.5V200v-15c0-9.6-9.833-12-15-12H33.5c-14.4 0-19-14-19-21.5V.5"
//   pathLength="1"
// ></path> */}

// <defs>
//   <radialGradient
//     id="paint0_radial_3450_10973"
//     cx="0"
//     cy="0"
//     r="1"
//     gradientTransform="matrix(0 157.833 -20.9092 0 21.91 157.172)"
//     gradientUnits="userSpaceOnUse"
//   >
//     <stop stopColor="#0048E5"></stop>
//     <stop offset="1" stopColor="#7A73FF"></stop>
//   </radialGradient>
// </defs>
// </svg> */}

//nigeria team changes
// <svg
//   xmlns="http://www.w3.org/2000/svg"
//   width="388"
//   height="316"
//   fill="none"
//   viewBox="0 0 388 316"
// >
//   <path
//     className="yu-path"
//     stroke="url(#paint0_radial_3450_10973)"
//     strokeWidth="1.5"
//     d="M1 .5V284c0 11 2.2 30.5 21 30.5s93.167.5 129.001.5H371.5c5.167.333 15.5-.9 15.5-8.5V200v-15c0-9.6-9.833-12-15-12H33.5c-14.4 0-19-14-19-21.5V.5"
//     pathLength="1"
//   ></path>
//   <defs>
//     <radialGradient
//       id="paint0_radial_3450_10973"
//       cx="0"
//       cy="0"
//       r="1"
//       gradientTransform="matrix(0 157.833 -20.9092 0 21.91 157.172)"
//       gradientUnits="userSpaceOnUse"
//     >
//       <stop stopColor="#0048E5"></stop>
//       <stop offset="1" stopColor="#7A73FF"></stop>
//     </radialGradient>
//   </defs>
// </svg>
