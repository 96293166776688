/* React packages  */
import React from "react";
import { Link } from "react-router-dom";

/**
  * LoanRequestView1 component is render all data in Credit-UW view-details section.
  * <p>There are four type of Data will be there :</p>
  * <li>About Income Details.</li>
  * <li>Loan Under-writting Details.</li>
  * <li>Multipliers Applied to Interest Rate & Tenors.</li>
  * <li>Approval Request Summary.</li>

  <br>
  * <h6>Below Html elemensts are created to use for  UI</h6>
  * <li> <b> Section :- </b>it is used for represents a standalone section</li>
  * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
  * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
  * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
  <br>
  * <h6>Below Bootstrap class is use for ui responsive.</h6>
  * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
  * <li> <b>row :-</b> This class to create a structure.</li>
  * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
  * <li> <b>ReactTooltip :-</b> This tooltip is a small pop up that appears when user places the mouse pointer over an element such as link or buttons to provide hint or information about the element being hovered.</li>
* <li> <b>checkbox :- </b> use the checkbox class if you want the user to select any number of options from a list</li>

  @Author : Seema Kumari
  @version :1.0

 */

class LoanRequestView1 extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {
        age: "",
        ageEligibility: "",
        amount: "",
        amountEligibility: "",
        amountRequestedELigibility: "",
        approvedInterestRate: "",
        approverDecision: "",
        avgSalary: "",
        avgSalaryInterestMult: "",
        avgSalaryTenorMult: "",
        bankAcctNumber: "",
        bankCustomerId: "",
        bankName: "",
        baseInterestRate: "",
        baseTenor: "",
        bureauReportUrl1: "",
        bureauReportUrl2: "",
        bureauReportUrl3: "",
        bureauScoreIntMult: "",
        bvn: "",
        compCat: "",
        companyCatIntMult: "",
        creditBureauDataDto: "",
        customerName: "",
        customerType: "",
        debitBurdenAllowedAmt: "",
        debtBurdenAllowPercent: "",
        deleiquencyStatusIntMut: "",
        emi: "",
        empOrBusinesssName: "",
        finalTenor: "",
        lastSalary: "",
        loanId: "",
        loanProdName: "",
        loanReqDate: "",
        loanStatus: "",
        maxAge: "",
        maxLoanAmtCap: "",
        minAge: "",
        minLoanAmt: "",
        mobile: "",
        numberOfYrsEmpIntMult: "",
        numofYrsWithCurrEmplyIntMult: "",
        payLinkDecision: "",
        payLinkDecisionDate: "",
        payLinkId: "",
        totalWorkExp: "",
        totalWorkExpWithEmployer: "",
      },
      fields: {},
      mounted: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  /**
   * This method is used to fetch the details before the page is loaded by giving hit to the server
   */
  componentDidMount() {
    this.mounted = true;
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL(
      "https://api.montra.org/moneylinkweb/api/credituw/loanapplication?loanid=" +
        localStorage.getItem("loanid") +
        "&email=" +
        localStorage.getItem("email")
    );
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          alert("server error");
        }
      })
      .then((contents) => {
        if (this.mounted) {
          this.setState({ data: contents.data });
        }
      });
  }

  /**
   *
   * @param {Event} e captures the value for pin on onChange event
   */
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "CREDIT-UW" == localStorage.getItem("role")
    ) {
      return (
        <div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-xl-2 loanviewpart2">
              <p className="viewheader">INCOME DETAILS</p>

              <form className="formview6">
                <div className="form-group">
                  <label className="lablename">Average Salary / Income</label>
                  <input
                    type="text"
                    className="form-control"
                    name="avgSalary"
                    onChange={this.handleChange.bind(this)}
                    placeholder={this.state.data.avgSalary}
                  />
                </div>

                <div className="form-group">
                  <label className="lablename1st">
                    Last Salary / inflow Credited in salary account ( in months)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastSalary"
                    onChange={this.handleChange.bind(this)}
                    placeholder={this.state.data.lastSalary}
                  />
                </div>
              </form>
            </div>

            <div className="col-sm-12 col-md-6 col-xl-3 loanviewbig2">
              <p className="viewheader">LOAN UNDER-WRITING DETAILS </p>
              <div className="formview7">
                <form className="formflex">
                  <div className="d-flex flex-row secform">
                    <div className="p-2 childview2">
                      <div className="form-group">
                        <label className="lablenameview3">Min. Age</label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="minAge"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.minAge}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenameview2 ">DB allowed %</label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="debtBurdenAllowPercent"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.debtBurdenAllowPercent}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenameview2">Min Loan Amt.</label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="minLoanAmt"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.minLoanAmt}
                        />
                      </div>
                    </div>

                    <div className="p-2 childview2">
                      <div className="form-group">
                        <label className="lablenameview3">Max Age</label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="maxAge"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.maxAge}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenameview2 dballow">
                          DB allowed Amt.
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="debitBurdenAllowedAmt"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.debitBurdenAllowedAmt}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenameview2">Max Loan Amt.</label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="maxLoanAmtCap"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.maxLoanAmtCap}
                        />
                      </div>
                    </div>

                    <div className="p-2 childview2">
                      <div className="form-group">
                        <label className="lablenameview2 cusrage">
                          Customer’s Age
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput tencolor"
                          name="age"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.age}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenameview2">
                          Amt. Eligibility
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput tencolor"
                          name="amountEligibility"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.data.amountEligibility}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenameview2 amtrec">
                          Amt. requested
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput tencolor"
                          name="amountRequestedELigibility"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.data.amountRequestedELigibility}
                        />
                      </div>
                    </div>

                    <div className="p-2 checkboxv">
                      <div className="form-group">
                        <label className="lablename3">Eligibility</label>
                        <div className="">
                          <div className="round">
                            <input
                              type="checkbox"
                              id="checker1"
                              name="productName"
                              value=""
                              checked={this.state.data.amountEligibility}
                              disabled={true}
                            />
                            <label
                              className="checkeligblity"
                              htmlFor="checker1"
                            >
                              <span className="checktext"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="round2">
                          <input
                            type="checkbox"
                            id="checker2"
                            name="productName"
                            value=""
                            checked={this.state.data.ageEligibility}
                            disabled={true}
                          />
                          <label className="checkeligblity" htmlFor="checker2">
                            <span className="checktext"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* Form -: in this form section includes input field is render all data about MULTIPLIERS APPLIED TO INTEREST RATE & TENORS */}
            <div className="col-sm-12 col-md-6 col-xl-5 loanbig">
              <p className="viewheader">
                MULTIPLIERS APPLIED TO INTEREST RATE & TENORS{" "}
              </p>
              <div className="formview5">
                <form className="formflex">
                  <div className="d-flex flex-row secform">
                    <div className="p-2 grid3">
                      <div className="form-group">
                        <label className="lablenamegrid3">Base Tenor</label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="baseTenor"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.baseTenor}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenamegrid1 baseintrest">
                          Base Interest Rate
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="baseInterestRate"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.baseInterestRate}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenamegrid4 ">
                          Delinquency Status – Interest Rate Mult.
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="deleiquencyStatusIntMut"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.deleiquencyStatusIntMut}
                        />
                      </div>
                    </div>

                    <div className="p-2 grid3">
                      <div className="form-group">
                        <label className="lablenamegrid5">
                          Avg. Salary–Tenor Mult.
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="avgSalaryTenorMult"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.avgSalaryTenorMult}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenamegrid5 baseintrest">
                          Avg. Salary–Interest Rate Mult.
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="avgSalaryInterestMult"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.avgSalaryInterestMult}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenamegrid5 score">
                          Bureau Score – Interest Rate Mult.
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="bureauScoreIntMult"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.bureauScoreIntMult}
                        />
                      </div>
                    </div>

                    <div className="p-2 childview2">
                      <div className="form-group">
                        <label className="lablenamegrid5 tenoer">
                          Final Tenor (Months)
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput tencolor"
                          name="finalTenor"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.finalTenor}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenamegrid5">
                          Company Category – Interest Mult.
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="companyCatIntMult"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.companyCatIntMult}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenamegrid5">
                          No. of years with current employer Int. Rate Mult.
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="numofYrsWithCurrEmplyIntMult"
                          onChange={this.handleChange.bind(this)}
                          placeholder={
                            this.state.data.numofYrsWithCurrEmplyIntMult
                          }
                        />
                      </div>
                    </div>

                    <div className="p-2 grid3">
                      <div className="form-group">
                        <label className="lablenamegrid">
                          No. of years in Emp. Int.Rate Mult.
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput"
                          name="numberOfYrsEmpIntMult"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.numberOfYrsEmpIntMult}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablenamegrid2 ">
                          Approved Interest Rate
                        </label>
                        <input
                          type="text"
                          className="form-control flexinput tencolor"
                          name="approvedInterestRate"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.approvedInterestRate}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* Form -: in this form section includes input field is render all data about APPROVAL REQUEST SUMMARY */}
            <div className="col-sm-12 col-md-6 col-xl-2 loanviewbig5">
              <p className="viewheader">APPROVAL REQUEST SUMMARY </p>
              <div className="formview9">
                <form className="formflex">
                  <div className="form-group lastgrid">
                    <label className="lablename">Loan Product</label>
                    <input
                      type="text"
                      className="form-control flexinput apprequest"
                      name="loanProdName"
                      onChange={this.handleChange.bind(this)}
                      placeholder={this.state.data.loanProdName}
                    />
                  </div>
                  <div className="d-flex flex-row secform">
                    <div className="p-2 childview3">
                      <div className="form-group">
                        <label className="lablename">Loan Amount</label>
                        <input
                          type="text"
                          className="form-control flexinput apprequest"
                          name="amount"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.amount}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">Loan Tenor</label>
                        <input
                          type="text"
                          className="form-control flexinput apprequest"
                          name="finalTenor"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.finalTenor}
                        />
                      </div>
                    </div>

                    <div className="p-2 childview3">
                      <div className="form-group">
                        <label className="lablename">EMI</label>
                        <input
                          type="text"
                          className="form-control flexinput apprequest"
                          name="emi"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.emi}
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">Interest Rate</label>
                        <input
                          type="text"
                          className="form-control flexinput apprequest"
                          name="approvedInterestRate"
                          onChange={this.handleChange.bind(this)}
                          placeholder={this.state.data.approvedInterestRate}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <p>Please Login AS CREDITUW</p>;
    }
  }
}

export default LoanRequestView1;
