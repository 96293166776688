import React from 'react';
import axios from 'axios';
import { Navigate } from "react-router-dom";

/**
 * Render field to send OTP for PIN reset.
 * @Author : Seema Kumari
 * @version :1.0
 */
class ForgotPin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forgotPin: false,
            fields: {}, // Initialize fields here
            serverErr: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }
    handleSubmit(e) {
        e.preventDefault();
        const url = "https://api.montra.org/moneylinkweb/api/user/sendotp";
        const params = {
            email: localStorage.getItem("email")
        };

        axios.get(url, {
            params: params, // Pass query parameters here
            headers: { "Content-Type": "application/json;charset=utf-8" }
        })
            .then((response) => {
                if (response.status === 200 && response.data.message === "SUCCESS") {
                    this.setState({
                        status: response.data.status,
                        otpmessage: response.data.message,
                        forgotPin: true
                    });
                } else {
                    this.setState({
                        backerrors: response.data.errors,
                        status: response.data.status,
                        otpmessage: response.data.message,
                    });
                    response.data.errors.forEach((key) => {
                        this.setState({
                            errorMsg: key.message,
                        });
                    });
                }
            })
            .catch((error) => {
                console.error("Error accessing the API:", error);
            });
    }


    render() {
        if (this.state.forgotPin) {
            return <Navigate to="/MoneyLink/UserOtp" />;
        } else {
            return (
                <div>
                    <button className='forgototppin' onClick={this.handleSubmit}>Forgot PIN?</button>
                    {/* {this.state.serverErr && <p className="errorMsg">{this.state.serverErr}</p>} */}
                </div>
            );
        }
    }
}

export default ForgotPin;
