import React from "react";

function YU8() {
  return (
    <svg
      width="331"
      height="150"
      viewBox="0 0 331 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 64.9682H6.24169C12.7095 65.3629 25.5341 62.2446 25.0906 46.6139C24.6471 30.9831 24.9058 22.9307 25.0906 17.602C25.0906 12.8654 29.8486 1.02381 42.2764 1.02406C55.0272 1.02432 63.3429 1.02498 66.1148 1.02406H172.556H313.369C318.912 0.758445 330 2.58259 330 12.0041C330 21.4256 330 39.8941 330 41.5"
        stroke="url(#paint0_radial_7007_4960)"
        stroke-width="2"
        className="yu-path"
        pathLength="1"
      />
      <path
        d="M0 85.0318H6.24169C12.7095 84.6371 25.5341 87.7554 25.0906 103.386C24.6471 119.017 24.9058 127.069 25.0906 132.398C25.0906 137.135 29.8486 148.976 42.2764 148.976C55.0272 148.976 63.3429 148.975 66.1148 148.976H172.556H313.369C318.912 149.242 330 147.417 330 137.996C330 128.574 330 120.606 330 119"
        stroke="url(#paint1_radial_7007_4960)"
        stroke-width="2"
        className="yu-path"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_7007_4960"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.1118 32.8824) rotate(90) scale(32.1187 51.003)"
        >
          <stop stop-color="#9966FF" />
          <stop offset="1" stop-color="#13EAE2" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_7007_4960"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.1118 117.118) rotate(-90) scale(32.1187 51.003)"
        >
          <stop stop-color="#9966FF" />
          <stop offset="1" stop-color="#13EAE2" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU8;
