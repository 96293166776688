import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import Card from "../../../assets/images/uday/Business-icons/payment-gateway/montra-card.svg";
import QrScan from "../../../assets/images/uday/Business-icons/payment-gateway/qrscan.svg";
import MontaApp from "../../../assets/images/uday/Business-icons/payment-gateway/montraapp.svg";
import USSD from "../../../assets/images/uday/Business-icons/payment-gateway/ussd.svg";
import hero2 from "../../../assets/images/gateway-hero-2.svg";

const steps = [
  { title: "Payment Methods Available", image: hero2, className: "diagram" },
  { image: Card, description: "New or Saved Cards" },
  { image: QrScan, description: "QR Scan" },
  { image: MontaApp, description: "Pay using Montra App" },
  { image: USSD, description: "USSD" },
];

const GatewayProcessContainer = () => {
  return (
    <div className="gateway-process-container">
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="business-overview-topdescription"
      />
    </div>
  );
};

export default GatewayProcessContainer;
