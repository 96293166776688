/* React packages */
import React from 'react';
import { NavLink } from 'react-router-dom';


/**
 * Header component is render navigation in Admin Checker section.
 * <p>Shows all sections</p>
 * <li>Profile</li>
 * <li>Product-Mix</li>
 * <li>UnderWriting Rules</li>
 * <li>Product-GL</li>
 * <li>Manage Users</li>
 <br>
 * <h6> React Bootstrap Navigation ui properties :- </h6>
 * <li> <b>Navbar:- </b>A navigation bar is a navigation header that is placed at the top of the page.</li>
 * <li> <b>Navbar.Collapse:- </b>it is use for grouping and hiding navbar contents by a parent breakpoint.</li>
 * <li> <b>NavLink:- </b>it will add styling attributes to the rendered element when it matches the current URL.</li>

 @Author : Seema Kumari
 @version :1.0
 */

const Header = () => {

  return (

    <div className="link-container">
      <NavLink to="/MoneyLink/CheckerProfile" className="userlink" activeClassName="active-link">Profile</NavLink>
      <NavLink to="/MoneyLink/CheckerProductMix" className="userlink" activeClassName="active-link">Product-mix</NavLink>
      <NavLink to="/MoneyLink/CheckerWrittingRules" className="userlink" activeClassName="active-link">Under-writing Rules</NavLink>
      <NavLink to="/MoneyLink/CheckerProductGL" className="userlink" activeClassName="active-link">Product GL</NavLink>
      <NavLink to="/MoneyLink/CheckerManageUser" className="userlink" activeClassName="active-link">Manage Users</NavLink>
    </div>

  )

}

export default Header;
