import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import LoanOffers from "../../../assets/images/uday/personal-icons/payments/wallet.svg";
import InstantLoanDisbursement from "../../../assets/images/uday/personal-icons/payments/linkedAccount.svg";
import LoanRepayments from "../../../assets/images/uday/personal-icons/payments/linkCards.svg";
import LoanAccountStatement from "../../../assets/images/uday/personal-icons/payments/installments.svg";
import personalimg2 from "../../../assets/images/personalimg2.svg";

const steps = [
  {
    title: "Loan products & Services",
    image: personalimg2,
    className: "direction-img",
  },
  { image: LoanOffers, description: "Loan offers" },
  { image: InstantLoanDisbursement, description: "Instant Loan Disbursement" },
  { image: LoanRepayments, description: "Loan Repayments" },
  { image: LoanAccountStatement, description: "Loan Account Statement" },
];

const CompareLoanProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="homepersonal-compare-topdescription"
      />
    </div>
  );
};

export default CompareLoanProcessContainer;
