import React from "react";
import verifiedlogo from "../../../assets/images/verylogo.svg";
import verifiedbar from "../../../assets/images/verifiedbar.svg";
import verifiedicon1 from "../../../assets/images/verifiedicon1.svg";
import verifiedicon2 from "../../../assets/images/verifiedicon2.svg";
import verifiedicon3 from "../../../assets/images/verifiedicon3.svg";
import verifiedicon4 from "../../../assets/images/verifiedicon4.svg";
// import facescan from "../../../assets/images/verifyimg.png";
import facescan from "../../../assets/images/verifyimg.webp";
import "./style.css";
import FaceProcessContainer from "../../Solutions/Verified/Faceauth/FaceProcessContainer";
import AuthProcessContainer from "../../Solutions/Verified/Authapp/AuthProcessContainer";

const Main = () => {
  return (
    <>
      <div className="home-verified verified">
        <div className="row">
          <div className="col duo-col">
            <div className="sol-logo">
              <img src={verifiedlogo} alt="verifiedlogo" />
            </div>
            <h1>
              Soft Token <span>158 107</span>
            </h1>
            <p className="veri-p1">
              With Verifyed Authenticator, turn on 2-Step Verification. When you
              enable 2-Step Verification (also known as two-factor
              authentication), you add an extra layer of security to your
              account.
            </p>
            <a href="/Solution/Verified/Authapp">
              <button className="verified-btn1">
                Explore Verifyed Soft Token Solution{" "}
                <i class="bi bi-chevron-right"></i>
              </button>
            </a>
            {/* <img className="verifiedimg-1" src={verifiedimg1} alt="image" /> */}
            <div className="verifiedimg-1">
              <AuthProcessContainer hometopDescription="home-verified-auth-topdescription" />
            </div>
          </div>
          <div className="col veryfiedcolimg">
            <div className="row ">
              <div className="col duo-col ">
                <div className="verified-part ">
                  <div className="">
                    <img src={verifiedbar} alt="verifiedbar" />
                  </div>
                  <div className="verified-part-text homeveryfied">
                    <img src={verifiedicon1} alt="verifiedicon1" />
                    <p>Setup</p>
                    <p className="veryeasy">
                      <span>
                        Easy & self-service onboarding to setup 2FA for any
                        account.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col duo-col">
                <div className="verified-part">
                  <div className="">
                    <img src={verifiedbar} alt="verified bar" />
                  </div>
                  <div className="verified-part-text homeveryfied">
                    <img src={verifiedicon2} alt="verifiedicon2" />
                    <p>Secure</p>
                    <p className="veryeasy">
                      {" "}
                      <span>
                        Face-ID, TouchID, Encrypted Backups and more to keep you
                        secure.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row verifiedhome">
              <div className="col duo-col">
                <div className="verified-part">
                  <div className="">
                    <img src={verifiedbar} alt="verifiedbar" />
                  </div>
                  <div className="verified-part-text homeveryfied">
                    <img src={verifiedicon3} alt="verifiedicon3" />
                    <p>Backup</p>
                    <p className="veryeasy">
                      {" "}
                      <span>
                        Prevents account from getting locked when you lose your
                        phone.
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col duo-col">
                <div className="verified-part">
                  <div className="">
                    <img src={verifiedbar} alt="iverifiedbarmage" />
                  </div>
                  <div className="verified-part-text homeveryfied">
                    <img src={verifiedicon4} alt="verifiedicon4" />
                    <p>Multiple Devices</p>
                    <p className="veryeasy">
                      {" "}
                      <span>
                        Sync Verifyed 2FA across mobile, tablet and desktop.
                      </span>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row digital-kyc-row">
          <div className="col duo-col">
            <h1>Digital KYC & ID Verification</h1>
            <p className="veri-p1">
              With Verifyed Authenticator, Turn on 2-Step Verification when you
              enable 2-Step Verification (also known as two-factor
              authentication), you add an extra layer of security to your
              account.
            </p>
            <a href="/Solution/Verified/Faceauth">
              <button className="verifiedbtn-2">
                Explore Verifyed KYC solution{" "}
                <i class="bi bi-chevron-right"></i>
              </button>
            </a>
            {/* <img className="verifiedimg-2" src={verifiedimg2} alt="" /> */}
            <FaceProcessContainer hometopDescription="home-verified-auth-topdescription" />
          </div>
          <div className="col">
            <img
              src={facescan}
              className="face-scan-img verified-home-face-scan-img"
              alt="facescan"
              width="600" height="400"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
