import React from "react";

function YU20() {
  return (
    <svg
      width="511"
      height="230"
      viewBox="0 0 511 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        d="M510 229.499V21.9991C510.5 15.1658 506.5 1.49914 486.5 1.49914C466.5 1.49914 337.167 1.49914 275 1.49914C269 1.33248 257 4.09914 257 16.4991C257 28.8991 257 37.1664 257 39C257 47.5 252.5 56 240.5 56C228.5 56 89.3333 57 20 57C10.5 57 1 56.4 1 48"
        stroke="url(#paint0_radial_5218_6914)"
        stroke-width="2"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5218_6914"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(255.52 115.496) rotate(90) scale(114.003 254.52)"
        >
          <stop stop-color="#17D92A" />
          <stop offset="1" stop-color="#635BFF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU20;
