/* React packages and components */
import React from "react";
import Footer from "../Footer/MontraFooter";
import { Navigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import MoneyLinklogo from "../../assets/paylink-images/montra/newml.svg";
import "./forgotpassword.css";

/**
 *  User can reset the password.
 * <p> User allowed to enter email and reset password.</p>
 * @Author : Seema Kumari
 * @version :1.0 */

class Forgotpassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      backerrors: [],
      email: "",
      status: "",
      message: "",
      errorMsg: "",
      serverErr: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  /**
   *capyures the new password to server entered by user.
   * @param {Event} e event which calls this method.
   */
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }
  /**
   *Submit the email to server and generate the pin and sent to their entered email.
   * @param {Event} e event occurs on clicking the submit button.
   */
  handleSubmit = (e) => {
    e.preventDefault();
    let fields = this.state.fields;
    localStorage.setItem("email", this.state.fields.email);
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL("https://api.montra.org/moneylinkweb/api/user/sendotp");
    fetch(url + "?email=" + this.state.fields.email, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({
            serverErr: "Please try After Sometime",
          });
        }
      })
      .then((contents) => {
        if (200 == contents.status && "SUCCESS" == contents.message) {
          this.setState({
            status: contents.status,
            message: contents.message,
          });
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            message: contents.message,
          });
          this.state.backerrors.map((key) => {
            this.setState({
              errorMsg: key.message,
            });
          });
        }
      })
      .catch(() =>
        console.log("Can’t access " + url + " response. Blocked by browser?")
      );
  };

  render() {
    if (this.state.status == 200 && "SUCCESS" == this.state.message) {
      return <Navigate to="/Forgotpassword1" />;
    } else {
      return (
        <div>
          <div className="ml-background moneybgnew">
            <div className="main-header">
              <div className="sticky-top sticky-head">
                <Navbar
                  className="navbar navbar-expand-lg navbar-light"
                  expand="lg"
                >
                  <div className="toplogoml">
                    <Link to="/MoneyLink/PartnerLogin">
                      <img className="toplogo" src={MoneyLinklogo} alt="logo" />{" "}
                    </Link>
                  </div>
                </Navbar>
              </div>
            </div>

            <div className="forgotpasswordchange">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <h5 className="headerpass"> Reset Your Password </h5>
                    <form>
                      <div className="form-group">
                        <label htmlFor="password">
                          Please enter your email address for your Reset the
                          password{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          onChange={this.handleChange.bind(this)}
                        />
                      </div>
                      {(() => {
                        switch (this.state.message) {
                          case "UNSUCCESS":
                            return (
                              <div className="errorMsg">
                                {" "}
                                <p>{this.state.errorMsg}</p>
                              </div>
                            );
                          default:
                            return <p></p>;
                        }
                      })()}
                      <br />

                      <div className="sign">
                        <input
                          type="submit"
                          className="btn btn-warning partnerbutton"
                          value="Submit"
                          onClick={this.handleSubmit}
                        />
                      </div>
                    </form>
                  </div>

                  <div className="col-md-4"></div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      );
    }
  }
}

export default Forgotpassword;
