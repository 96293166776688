import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import CashDeposit from "../../../assets/images/uday/personal-icons/Home-icons/Business/section2/cashdeposit-blue.svg";
import CashWithdrawal from "../../../assets/images/uday/personal-icons/Home-icons/Business/section2/Cashwithdrawal-blue.svg";
import BillPayments from "../../../assets/images/uday/personal-icons/Home-icons/Business/section2/rechargeBillPayment-blue.svg";
import BankTransfer from "../../../assets/images/uday/personal-icons/Home-icons/Business/section1/bankTransfer-blue.svg";
import businessanimg2 from "../../../assets/images/businessimg2.svg";

const steps = [
  {
    title: "Accept Payments Using",
    image: businessanimg2,
    className: "direction-img",
  },
  { image: CashDeposit, description: "Cash Deposit" },
  { image: CashWithdrawal, description: "Cash Withdrawal" },
  { image: BillPayments, description: "Recharge & Bills Payment" },
  { image: BankTransfer, description: "Bank Transfers" },
];

const AgencyBankingProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#FFFFFF"
        circleBorderColor="#FFFFFF"
        textColor="#FFFFFF"
        circelBg="#FFFFFF"
        // topDescriptionClass="homepersonal-payment-topdescription"
        topDescriptionClass="homebusiness-store-topdescription"
      />
    </div>
  );
};

export default AgencyBankingProcessContainer;
