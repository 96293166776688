/* React packages */
import React, { useState } from "react";
import Montralogo from "../../assets/images/Moneylink-logo.svg";
import { Link, NavLink } from "react-router-dom";
import Accesslogo from "../../assets/images/logo7.svg";
import { FaBars, FaTimes } from "react-icons/fa";
import "./commonHeader.css";

const CommonHeader = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <div className="montra-main-header">
      <div className="makerheader">
        <div className="toplogoml">
          <img className="toplogo" src={Montralogo} alt="logo" />
        </div>

        <div className="hamburger-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <div className={`montra-logout-user ${isMobileMenuOpen ? "open" : ""}`}>
          <div className="mr-auto montralendingheader">
            <ul className="maker-top-header">
              <li className="navbarlink">
                Name &nbsp; : &nbsp;{" "}
                <span className="titlename">
                  {localStorage.getItem("name")}
                </span>
              </li>
              <li className="navbarlink">
                User Type &nbsp; : &nbsp;{" "}
                <span className="titlename">{"Credit UW"}</span>
              </li>
              <li className="navbarlink2">
                Partner
                <img
                  className="imagepartner"
                  src={Accesslogo}
                  alt="Partner-image"
                />
              </li>
            </ul>
          </div>
          <Link
            to="/MoneyLink/PartnerLogin"
            type=""
            className="btn btn-outline-danger logout"
          >
            Log Out
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CommonHeader;
