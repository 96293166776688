import React from "react";
import Productnav from "../../Navbar/Productnav";
import Productfooter from "../../Footer/Productfooter";
import Hero from "./Hero";
import Body from "./Body";
import arrow1 from "../../../assets/images/arrow1.svg";
import arrow2 from "../../../assets/images/arrow2.png";

const Main = () => {
  return (
    <>
      <div id="Finance">
        <Productnav />
        <Hero />
        <div className="payment-page">
          <div className="arrow">
            <img src={arrow1} alt="arrow" />
          </div>

          <Body />
        </div>

        <Productfooter />

      </div>
    </>
  );
};

export default Main;
