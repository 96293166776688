import React from "react";
import Navbar from "../../../Navbar/Solution";
import Footer from "../../../Footer/Productfooter";
import Hero from "./Hero";
import Body from "./Body";

const Main = () => {
  return (
    <>
      <div id="LimitManagement">
        <Navbar />
        <Hero />
        <Body />
        <Footer />
      </div>
    </>
  );
};

export default Main;
