import React from "react";

function YU11() {
  return (
    // <svg
    //   width="461"
    //   height="239"
    //   viewBox="0 0 461 239"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     className="yu-path-yu11"
    //     // d="M0 60.0004H187C192.5 59.6671 203.5 62.2004 203.5 75.0004C203.5 91.0004 200.5 105 217 105C230.2 105 310.833 105 349.5 105C358.5 105 366 106.4 366 126C366 145.6 366 197.5 366 221C366.333 226.5 369.4 237.5 379 237.5C388.6 237.5 429 237.5 448 237.5C452 236.5 460 232.7 460 225.5C460 218.3 460 213.167 460 211.5"
    //     // d="M0 53.0613H89.5M89.5 53.0613C67.5 53.0613 182.356 45.9529 182.356 33.5383C182.356 21.1237 182.356 13.0142 182.356 10.5112C183.433 7.3408 187.528 1 195.287 1C203.046 1 360.879 1 438.826 1C445.292 1 458.331 3.50295 458.762 13.5147C459.193 23.5265 458.942 86.4336 458.762 116.136M89.5 53.0613C89.5 53.0613 182.356 61.8717 182.356 75.0873C182.356 91.6067 180.739 104.622 196.903 105.123C209.835 105.523 295.325 105.289 336.454 105.123C343.997 104.622 358.868 107.425 358.006 122.643C357.144 137.861 357.646 190.723 358.006 215.252C356.928 222.594 358.329 237.378 372.553 237.779C386.778 238.179 426.613 237.946 444.753 237.779C451.219 237.719 458.762 233.073 458.762 226.265C458.762 219.457 458.762 214.084 458.762 212.249"
    //     // d="M0 53H190.5M190.5 53C194.833 53 203.5 45.9 203.5 33.5C203.5 21.1 203.5 13 203.5 10.5C204.5 7.33333 208.3 1 215.5 1C222.7 1 369.167 1 441.5 1C447.5 1 459.6 3.5 460 13.5C460.4 23.5 460.167 86.333 460 116M190.5 53C194.833 54.8333 203.5 61.8 203.5 75C203.5 91.5 202 104.5 217 105C229 105.4 308.333 105.167 346.5 105C353.5 104.5 367.3 107.3 366.5 122.5C365.7 137.7 366.167 190.5 366.5 215C365.5 222.333 366.8 237.1 380 237.5C393.2 237.9 430.167 237.667 447 237.5C453 237.441 460 232.8 460 226C460 219.2 460 213.833 460 212"
    //     d="M0.5 53.5002H119.5M119.5 53.5002L168.5 45.5002H195.5C198.5 45.3335 204.4 42.9002 204 34.5002C203.6 26.1002 203.833 15.3335 204 11.0002C204.667 7.83349 207.7 1.40016 214.5 1.00016C221.3 0.600158 369 0.833491 442 1.00016C448.5 1.00011 461.3 4.6 460.5 19C459.7 33.4 460.167 89.6667 460.5 116M119.5 53.5002L168.5 68.5H195.5C198.333 68.3333 204 70.3 204 79.5C204 88.7 204 94 204 95.5C203.667 99.6667 205.3 107.9 214.5 107.5C223.7 107.1 313.667 107.333 357.5 107.5C361.167 107.667 368.4 111.4 368 125C367.6 138.6 367.833 194 368 220C368 227 376.6 239.9 391 239.5C405.4 239.1 433 239.333 445 239.5C454.5 239.632 460.5 237 460.5 231C460.5 225 460.5 215.833 460.5 212"
    //     stroke="url(#paint0_linear_6001_4974)"
    //     stroke-width="2"
    //     pathLength="1"
    //   />
    //   {/* <path
    //   className="yu-path"
    //     d="M0 49H190.5C194.833 49 203.5 45.9 203.5 33.5C203.5 21.1 203.5 13 203.5 10.5C204.5 7.33333 208.3 1 215.5 1C222.7 1 369.167 1 441.5 1C447.5 1 459.6 3.5 460 13.5C460.4 23.5 460.167 86.333 460 116"
    //     stroke="url(#paint1_linear_6001_4974)"
    //     stroke-width="2"
    //     pathLength="1"
    //   /> */}
    //   <defs>
    //     <linearGradient
    //       id="paint0_linear_6001_4974"
    //       x1="230"
    //       y1="59.9727"
    //       x2="230"
    //       y2="185.264"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stop-color="#9B66FF" />
    //       <stop offset="1" stop-color="#13ECE2" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint1_linear_6001_4974"
    //       x1="230.11"
    //       y1="1"
    //       x2="230.11"
    //       y2="168"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stop-color="#9B66FF" />
    //       <stop offset="1" stop-color="#13ECE2" />
    //     </linearGradient>
    //   </defs>
    // </svg>
    <svg
      width="462"
      height="244"
      viewBox="0 0 462 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 54.1627H119.5M119.5 54.1627L168.5 46.0622H195.5C198.5 45.8934 204.4 43.4295 204 34.9239C203.6 26.4184 203.833 15.5164 204 11.1286C204.667 7.92214 207.7 1.40796 214.5 1.00293C221.3 0.597906 369 0.834172 442 1.00293C448.5 1.00288 461.3 4.64802 460.5 19.229C459.7 33.81 460.167 90.7838 460.5 117.448M119.5 54.1627L168.5 69.3511H195.5C198.333 69.1823 204 71.1737 204 80.4893C204 89.8049 204 95.1715 204 96.6904C203.667 100.909 205.3 109.246 214.5 108.841C223.7 108.436 313.667 108.672 357.5 108.841C361.167 109.01 368.4 112.79 368 126.561C367.6 140.332 367.833 196.428 368 222.755C368 229.843 376.6 242.905 391 242.5C405.4 242.095 433 242.331 445 242.5C454.5 242.634 460.5 239.969 460.5 233.893C460.5 227.818 460.5 206.882 460.5 203"
        stroke="url(#paint0_linear_5164_4974)"
        stroke-width="2"
        pathLength="2"
        className="yu-path"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5164_4974"
          x1="230.518"
          y1="0.779297"
          x2="230.518"
          y2="83.0858"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9B66FF" />
          <stop offset="1" stop-color="#13ECE2" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default YU11;

// {/* <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="461"
//       height="239"
//       fill="none"
//       viewBox="0 0 461 239"
//     >
//       {/* <path
//         className="yu-path"
//         stroke="url(#paint0_linear_3806_10705)"
//         strokeWidth="1.5"
//         d="M0 60l190.5.001c4 0 13.4 1.4 13 15v17c0 4.333 2.4 13 12 13L356 105c6.5 0 10 5.5 10 13.5V219c-.333 6.167 2.3 18.5 15.5 18.5H438c7.333.667 22-1 22-13V116c.167-29.667.4-92.5 0-102.5S447.5 1 441.5 1h-226c-7.2 0-11 6.333-12 9.5v23c0 12.4-8.667 15.5-13 15.5H0"
//         pathLength="1"
//       ></path> */}

//       <path
//         className="yu-path"
//         stroke="url(#paint0_radial_3809_12304)"
//         strokeWidth="2"
//         // d="M0 49l190.5 0.001 9.119-3.29  9.52-10.97.4-7.678.167-21.177 0-26.966-.167-3.352 1.403-10.055 9.019-10.055h142.297c3.174-.153 9.52 1.645 9.52 10.055v26.967C198.412 41.687 200.016 49 210.437 49h29.562l96 6h97.5c6 .667 18 5.3 18 18.5V283"
//         // pathLength="1"
//         // d="M0 49h190.557c3.006-.457 9.119-3.29 9.52-10.97.4-7.678.167-21.177 0-26.966-.167-3.352 1.403-10.055 9.019-10.055h142.297c3.174-.153 9.52 1.645 10.104 10.0905v52.967C198.412"
//         // pathLength="1"
//         // d="M0 49h190.557c3.006-.457 9.119-3.29 9.52-10.97.4-7.678.167-21.177 0-26.966-.167-3.352 1.403-10.055 9.019-10.055h142.297c3.174-.153 9.52 1.645 10.104 10.055v26.967C198.412 41.687 800.016  15.5-.9 15.5-8.5V200v1c0-9"
//         // pathLength="1"
//         d="M0 49h190.557c3.006-.457 9.119-3.29 9.52-10.97.4-7.678.167-21.177 0-26.966-.167-3.352 1.403-10.055 9.019-10.055h242.297c3.174-.153 9.52 1.645 9.52 10.055v140.967C198.412  49h29.562l96 6h97.5c6 .667 18 5.3 18 18.5V283"
//         pathLength="1"
//       ></path>

//       <path
//         className="yu-path"
//         stroke="url(#paint1_radial_3809_12304)"
//         strokeWidth="2"
//         // d="M0 61l190.5 0.001 9.119-3.29  9.52 10.97.4 7.678.167 21.177 0 26.966-.167 3.352 1.402 10.055 9.018 10.055h142.298c3.173.153 9.52-1.645 9.52-10.055V71.969C198.414 68.313 200.017 61 210.439 61H250.5l81.5-6"
//         // pathLength="1"
//         d="M0 61h190.559c3.006.457 9.119 3.29 9.52 10.97.4 7.678.167 21.177 0 26.966-.167 3.352 1.402 10.055 9.018 10.055h220.297c3.174-.153 9.52 1.645 9.52 10.055v75.967C198.412  49h29.562l96 6h97.5c6 .667 18 5.3 18 18.5V283"
//         pathLength="1"
//       ></path>

//       {/* <defs>
//         <linearGradient
//           id="paint0_radial_3809_12304"
//           x1="230.11"
//           x2="230.11"
//           y1="1"
//           y2="168"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#9B66FF"></stop>
//           <stop offset="1" stopColor="#13ECE2"></stop>
//         </linearGradient>
//       </defs> */}
//       <defs>
//         <radialGradient
//           id="paint0_radial_3809_12304"
//           cx="0"
//           cy="0"
//           r="1"
//           gradientTransform="matrix(0 141 -225.75 0 225.75 142)"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#17D92A"></stop>
//           <stop offset="1" stopColor="#635BFF"></stop>
//         </radialGradient>
//         <radialGradient
//           id="paint1_radial_3809_12304"
//           cx="0"
//           cy="0"
//           r="1"
//           gradientTransform="matrix(0 -24 120 0 120 85)"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop stopColor="#17D92A"></stop>
//           <stop offset="1" stopColor="#635BFF"></stop>
//         </radialGradient>
//       </defs>
//     </svg> */}
