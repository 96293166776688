import React, { useEffect, useState } from "react";
import ProcessSteps from "../../../utilities/ProcessSteps.js";

import onboarding from "../../../assets/images/uday/customer-onboarding-icon.svg";
import duocardissure from "../../../assets/images/uday/duo-card-issuance-icon.svg";
import activation from "../../../assets/images/uday/activation-icon.svg";
import transactionSwitching from "../../../assets/images/uday/transaction-switching-icon.svg";
import transactionProcessing from "../../../assets/images/uday/transaction-processing-icon.svg";
import transactionMirroring from "../../../assets/images/uday/transaction-mirroring-icon.svg";
import duoapp from "../../../assets/images/uday/duo-app.svg";
import duowebPortal from "../../../assets/images/uday/duo-web-portal-icon.svg";
import duoCollection from "../../../assets/images/uday/duo-collection.svg";
import dynamicManagement from "../../../assets/images/uday/dlm-icon.svg";
import duohero2 from "../../../assets/images/duohero2.svg";

const steps = [
  { title: "DUO Services", image: duohero2, className: "duoheroimg2" },
  { image: onboarding, description: "Onboarding" },
  { image: duocardissure, description: "DUO Card Issuance" },
  {
    image: activation,
    description: "Activation",
    className: "activation-icon",
  },
  { image: transactionSwitching, description: "Transaction Switching" },
  { image: transactionProcessing, description: "Transaction Processing" },
  { image: transactionMirroring, description: "Transaction Mirroring" },
  { image: duoapp, description: "DUO App", className: "duo-app-icon" },
  { image: duowebPortal, description: "DUO Web Portal" },
  { image: duoCollection, description: "DUO Collection" },
  { image: dynamicManagement, description: "Dynamic Limit Management" },
];

const DuoProcessContainer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 1439;

  const title = steps[0].title;
  const mobileImage = steps[0].image;
  const className = steps[0].className;
  const processSteps = steps.slice(1);

  let arrowColor = "#000000";
  let circleBorderColor = "#000000";
  let textColor = "#000000";

  return (
    <div>
      {/* {!isMobile ? (
        <div className="process-flow duo-process-flow">
          <p className="top-description duo-top-description">{title}</p>
          <div className="top-step">
            <div className="util-down-arrow duo-util-down-arrow">
              <svg width="50" height="50">
                <line
                  x1="25"
                  y1="0"
                  x2="25"
                  y2="50"
                  style={{ stroke: arrowColor, strokeWidth: 1 }}
                />
                <polygon
                  points="20,40 25,50 30,40"
                  style={{ fill: arrowColor }}
                />
              </svg>
            </div>
          </div>
          <div className="util-process-steps">
            {processSteps.map((step, index) => (
              <div className="step-container" key={index}>
                <div
                  className="util-step"
                  style={
                    step.description === "DUO Card Issuance"
                      ? { marginLeft: "10px", marginRight: "15px" }
                      : step.description === "Activation"
                      ? { marginLeft: "25px", marginRight: "35px" }
                      : step.description === "Transaction Switching"
                      ? { marginLeft: "10px", marginRight: "15px" }
                      : step.description === "Transaction Processing"
                      ? { marginLeft: "10px", marginRight: "15px" }
                      : step.description === "Transaction Mirroring"
                      ? { marginLeft: "10px", marginRight: "15px" }
                      : step.description === "DUO App"
                      ? { marginLeft: "27px", marginRight: "35px" }
                      : step.description === "DUO Web Portal"
                      ? { marginLeft: "10px", marginRight: "15px" }
                      : step.description === "DUO Collection"
                      ? { marginLeft: "10px", marginRight: "15px" }
                      : step.description === "Dynamic Limit Management"
                      ? { marginLeft: "10px", marginRight: "15px" }
                      : {}
                  }
                >
                  <div
                    className="circleimg"
                    style={{ border: `2px solid ${circleBorderColor} ` }}
                  >
                    <img
                      src={step.image}
                      alt={step.description}
                      className={
                        step.className ? `${step.className}` : "duo-icons"
                      }
                    />
                  </div>
                  <p
                    className="down-description duo-down-description"
                    style={{ color: textColor }}
                  >
                    {step.description}
                  </p>
                </div>
                {index > 0 && (
                  <div className="arrow duo-arrow">
                    <svg width="50" height="50">
                      <line
                        x1="0"
                        y1="25"
                        x2="50"
                        y2="25"
                        style={{ stroke: arrowColor, strokeWidth: 1 }}
                      />
                      <polygon
                        points="40,20 50,25 40,30"
                        style={{ fill: arrowColor }}
                      />
                    </svg>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <img
          src={mobileImage}
          alt="hero2"
          className={className ? className : ""}
        />
      )} */}
      <div className="duo-process-flow">
        <ProcessSteps
          steps={steps}
          arrowColor="#000000"
          circleBorderColor="#000000"
          textColor="#000000"
          topDescriptionClass="duo-topdescription"
        />
      </div>
    </div>
  );
};

export default DuoProcessContainer;
