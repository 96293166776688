import React from "react";
import Navbar from "../Navbar/Main";
import Footer from "../Footer/Main";
import Hero from "./Hero/Main";
import Moneylink from "./Moneylink/Main";
import Supplychain from "./Supplychain/Main";
import Verified from "./Verified/Main";
import Duo from "./Duo/Main";
import Business from "./Business/Main";
import Personal from "./Personal/Main";
import "./main.css";

const Main = () => {
  return (
    <div id="home-page">
      <Navbar />
      <Hero />
      <Personal />
      <Business id="home-business" businessPG={true} />
      <Duo />
      <Verified />
      <Supplychain />
      <Moneylink />
      <Footer />
    </div>
  );
};

export default Main;
