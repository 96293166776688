import "./style.css";
import arrow from "../../../assets/images/arrow1.svg";
import tapPay1 from "../../../assets/images/tap-pay-3.svg";
import tapPay2 from "../../../assets/images/tap-pay-4.svg";
import tapPay3 from "../../../assets/images/tap-pay-5.svg";
import tapPay4 from "../../../assets/images/tap-pay-6.svg";
import tapPay5 from "../../../assets/images/tap-pay-7.svg";
import tapPay6 from "../../../assets/images/tap-pay-8.svg";
import tapPay7 from "../../../assets/images/tap-pay-9.svg";
import tapPay8 from "../../../assets/images/tap-pay-10.svg";

const Body = () => {
  return (
    <section className="home-business">
      <div className="arrow">
        <img src={arrow} alt="arrow" />
      </div>
      <div className="business-payment-body-1 Tapandbody">
        <h2>Make your phone your POS using Tap & Pay on Montra App</h2>
        <br />
        <div className="container grids tapgrid">
          <div className="row personalb tap-pay-body-row">
            <div className="col-md-6 col-xl-3 tap-pay-body-grid">
              <div className="gap">
                <div className="svgimg">
                  <img src={tapPay1} alt="tapPay1" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Accept payments from any Physical Contactless Card</h6>
                  </div>
                  <p>
                    With Tap to Pay solution, you can accept contactless
                    payments from any physical cards—no extra hardware required.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 tap-pay-body-grid">
              <div className="gap">
                <div className="svgimg">
                  <img src={tapPay2} alt="tapPay2" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Secure revenue growth</h6>
                  </div>
                  <p>
                    MontraPay offers secure payments system that protects your
                    money and data from fraud as you grow revenue.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3 tap-pay-body-grid">
              <div className="gap">
                <div className="svgimg">
                  <img src={tapPay3} alt="tapPay3" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Go live in minutes</h6>
                  </div>
                  <p>
                    Create a free Montra Business Account in minutes and start
                    accepting payments securely today by integrating to
                    MontraPay.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 tap-pay-body-grid">
              <div className="gap">
                <div className="svgimg">
                  <img src={tapPay4} alt="tapPay4" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Powerful Dashboard</h6>
                  </div>
                  <p>
                    Get reports and detailed statistics on payments,
                    settlements, refunds and much more for you to take better
                    business decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2>Montra, a more secure way to pay</h2>
        <br />
        <div className="container grids tapgrid">
          <div className="row personalb tap-pay-body-row">
            <div className="col-md-6 col-xl-3 tap-pay-body-grid">
              <div className="gap">
                <div className="svgimg">
                  <img src={tapPay5} alt="tapPay5" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Secure from the start</h6>
                  </div>
                  <p>
                    Access to your Montra App is secured with the Password that
                    you set-up. You can also access the app using your Face-ID
                    or Biometric-ID that you have set-up on your phone.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 tap-pay-body-grid">
              <div className="gap">
                <div className="svgimg">
                  <img src={tapPay6} alt="tapPay6" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Built-in protection before you pay</h6>
                  </div>
                  <p>
                    Every payment request is processed only upon successful
                    authentication of the Montra PIN you have set-up.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-xl-3 tap-pay-body-grid">
              <div className="gap">
                <div className="svgimg">
                  <img src={tapPay7} alt="tapPay7" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Secure with 2FA</h6>
                  </div>
                  <p>
                    Our Fraud & Risk Assessment tool would assess the risk for
                    every transaction and would ask for your Soft Token from
                    time to time. Montra offers access to Free Soft Token on
                    VERIFYED app for every Montra Customer.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3 tap-pay-body-grid">
              <div className="gap">
                <div className="svgimg">
                  <img src={tapPay8} alt="tapPay8" />
                </div>
                <div>
                  <div className="lineparagrapproducts">
                    <h6>Payment Privacy</h6>
                  </div>
                  <p>
                    Only you and the person you are transacting with will be
                    able to see the details of your payment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing tap-pay-pricing">
        <h2>Simple, Transparent &amp; Fair Pricing</h2>
        <br />
        <div className="tags">
          <div className="tag">Zero setup fees</div>
          <div className="tag">Zero maintenance fees</div>
          <div className="tag">No hidden charges</div>
        </div>
        <br />
        <div className="pricing-container tap-pay-pricing-container">
          <div className="icon"></div>
          <div>
            <h3>0.5%</h3>
            <label>Per Transaction</label>
          </div>
          <ul className="taplist">
            <li>
              {" "}
              <span className="spanlist">No Set-up Cost</span>
            </li>
            <li>Accept MasterCard, Visa and Verve Payments</li>
            <li>0.5% . Fee is capped at N 2,000</li>
          </ul>
        </div>
        <button className="cta-blue tappaybelow">Contact Sales</button>
      </div>
    </section>
  );
};

export default Body;
