import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

/**
 *  CheckerWrittingPart1 component is render upper part of underwriting rules(product details) of bank for checker section.
 * <p>Allows checker to approve or reject by binding </p>
 *  @Author : Thrilok Kandra
 *  @version :1.0
 */
class CheckerWrittingPart1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
    };
  }
  render() {
    return (
      <div>
        <form method="post" name="userRegistrationForm" className="formroot">
          <div className="unwrite">
            <div className="d-flex flex-row mt-3 ">
              <div className="p-2 underwriteone">
                <div className="form-group">
                  <label className="labelch-7">Product</label>
                  {this.props.data.map((underWritingRules, i) => (
                    <div key={i}>
                      <label
                        className="form-control loanlabelcheck"
                        name="paydayloan"
                        disabled
                      >
                        {underWritingRules.productName}{" "}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite">
                <div className="toolinfopro">
                  <p className="headertextcheck a4 ttip">Minimum Age</p>
                  <Tooltip title="Years" placement="top">
                    <IconButton>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor: this.props.isMinAgefieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="minAgesa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.minAge}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isMinAge"
                          onChange={(e) => {
                            this.props.toggleChangeIsMinAge(e, i);
                          }}
                          checked={underWritingRules.isMinAge}
                          disabled={this.props.isMinAgelist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor: this.props.isMinAgeclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="p-2 underwrite">
                <div className="toolinfopro">
                  <p className="headertextcheck a3 ttip">Maximum Age</p>

                  <Tooltip title="Years" placement="top">
                    <IconButton>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>

                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor: this.props.isMaxAgefieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="maxAgesa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.maxAge}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isMaxAge"
                          onChange={(e) => {
                            this.props.toggleChangeIsMaxAge(e, i);
                          }}
                          checked={underWritingRules.isMaxAge}
                          disabled={this.props.isMaxAgelist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor: this.props.isMaxAgeclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite">
                <div className="toolinfopro">
                  <p className="headertextcheck ttip">Debt Burden Allowed</p>
                  <Tooltip
                    title=" % of Average Monthly Salary / Income"
                    placement="top"
                  >
                    <IconButton>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isDebtBurdenAllowPercentfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="debtBurdenAllowPercentsa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.debtBurdenAllowPercent}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isDebtBurdenAllowPercent"
                          onChange={(e) => {
                            this.props.toggleChangeIsDebtBurdenAllowPercent(
                              e,
                              i
                            );
                          }}
                          checked={underWritingRules.isDebtBurdenAllowPercent}
                          disabled={this.props.isDebtBurdenAllowPercentlist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isDebtBurdenAllowPercentclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite">
                <div className="toolinfopro">
                  <p className="headertextcheck a2 ttip">Min. Loan Amount</p>
                  <Tooltip title="Amount" placement="top">
                    <IconButton>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isMinLoanAmtfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="maxAgesa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.minLoanAmt}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isMaxAge"
                          onChange={(e) => {
                            this.props.toggleChangeIsMinLoanAmt(e, i);
                          }}
                          checked={underWritingRules.isMinLoanAmt}
                          disabled={this.props.isMinLoanAmtlist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isMinLoanAmtclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite">
                <div className="toolinfopro">
                  <p className="headertextcheck ttip">Max Loan Amount Cap</p>
                  <Tooltip title="Amount" placement="top">
                    <IconButton>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isMaxLoanAmtCapfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="maxLoanAmtCapsa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.maxLoanAmtCap}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="ismaxLoanAmtCap"
                          onChange={(e) => {
                            this.props.toggleChangeIsMaxLoanAmtCap(e, i);
                          }}
                          checked={underWritingRules.isMaxLoanAmtCap}
                          disabled={this.props.isMaxLoanAmtCaplist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isMaxLoanAmtCapclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite">
                <div className="toolinfopro">
                  <p className="checkerunderwritting ttip">
                    Instant Loan Disbursement Cap
                  </p>
                  <Tooltip
                    title=" Amount allowed for digital disbursement"
                    placement="top"
                  >
                    <IconButton>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isInstantloanDisCapfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="instantloanDisCapsa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.instantloanDisCap}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isInstantloanDisCap"
                          onChange={(e) => {
                            this.props.toggleChangeIsInstantloanDisCap(e, i);
                          }}
                          checked={underWritingRules.isInstantloanDisCap}
                          disabled={this.props.isInstantloanDisCaplist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isInstantloanDisCapclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite">
                <div className="toolinfopro">
                  <p className="headertextcheck a1 ttip">
                    Last Salary / Inflow
                  </p>

                  <Tooltip
                    title="Last Salary / Inflow must be there in last number of months"
                    placement="top"
                  >
                    <IconButton>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isLastSalaryfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="lastSalarysa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.lastSalary}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isLastSalary"
                          onChange={(e) => {
                            this.props.toggleChangeIsLastSalary(e, i);
                          }}
                          checked={underWritingRules.isLastSalary}
                          disabled={this.props.isLastSalarylist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isLastSalaryclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CheckerWrittingPart1;
