import React from "react";

function YU19({ path }) {
  return (
    // <svg
    //   width="454"
    //   height="411"
    //   viewBox="0 0 454 411"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     className="yu-path"
    //     d="M453 0.5V332.5C453.167 338.333 449.2 349.9 432 349.5C414.8 349.1 329.167 349.333 288.5 349.5M288.5 349.5L240 364H218.5C209 364 199.5 372.6 199.5 381C199.5 389.4 199.5 396.5 199.5 399C199.5 402.5 194.9 409.9 184.5 409.5C174.1 409.1 57.1667 409.333 0 409.5M288.5 349.5L240 341.5H218.5C212.167 341.667 199.5 339.6 199.5 330C199.5 320.4 199.5 317.833 199.5 316.5C200.5 310.833 198.9 297.1 184.5 297.5C170.1 297.9 55.5 297.667 0 297.5"
    //     stroke="url(#paint0_radial_5164_5126)"
    //     stroke-width="2"
    //     pathLength="1"
    //   />
    //   <defs>
    //     <radialGradient
    //       id="paint0_radial_5164_5126"
    //       cx="0"
    //       cy="0"
    //       r="1"
    //       gradientUnits="userSpaceOnUse"
    //       gradientTransform="translate(230.593 203.056) rotate(90) scale(254.056 230.593)"
    //     >
    //       <stop stop-color="#0CC2FF" />
    //       <stop offset="1" stop-color="#8CF2B2" />
    //     </radialGradient>
    //   </defs>
    // </svg>
    <svg
      width="166"
      height="351"
      viewBox="0 0 166 351"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        d="M165 0.5V332.5C165.167 338.333 161.2 349.9 144 349.5C126.8 349.1 41.1667 349.333 0.5 349.5"
        stroke="url(#paint0_radial_5164_5737)"
        stroke-width="2"
        pathLength={path === 0 ? "0" : "1"}
      />
      <defs>
        <radialGradient
          id="paint0_radial_5164_5737"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-57.4071 203.056) rotate(90) scale(254.056 230.593)"
        >
          <stop stop-color="#0CC2FF" />
          <stop offset="1" stop-color="#8CF2B2" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU19;
