import React from 'react';
import './Indemnity.css';

const Indemnity = () => {
    return (
        <div className="indemnity-container">
            <p className='readcarefully'>Read Carefully	</p>
            <p>
                PS: To upgrade your account tier and /or increase your transfer limit to the maximum permissible for your business, click here
            </p>
            <p>
                By clicking the continue button below, I/We agree to indemnify Montra for upgrading my/our account tier and/or increasing my transfer limit to the maximum permissible for my/our business in a day.
            </p>
            <p className='optionsare'>Options are:</p>
            <p className='iagree'>1. I/We agree. </p>
            <p >2. Not interested</p>
            <div className='notinterested'></div>
            <h1>Indemnity</h1>
            <p>WHEREAS:</p>
            <ul className='indemnity-list'>
                <li>I/We agree, that Nirdesh Malik may require Montra to upgrade my/our account tier and/or increase the maximum daily transfer limit in respect of my/own account(s)
                    with Montra pursuant to my/our request (“the request”).</li>
                <li>I/We recognize that Montra acceding to the Request may pose a great risk and attract fraudsters; I/We, therefore,
                    accept my/our obligation to secure my/our device(s), password, and PIN against fraudulent activities.</li>
                <li>Montra has requested that I/We issue this indemnity as a condition for acceding to the request.</li>

            </ul>
            <p className='nowin'>NOW IN CONSIDERATION OF Montra agreeing to accede to the Request.</p>
            <ul className='indemnity-list'>
                <li>I/We will at all times hereafter keep Montra, its successors-in-title, and assigns indemnified against all claims, actions, proceedings, liability, losses, penalty,
                    and expenses in relation to or arising out of Montra acceding to the Request.</li>
                <li>I/We hereby indemnify Montra and hold it harmless from all claims, liability, losses, penalty, and expenses of whatsoever nature which may be brought against Montra or which it may suffer or incur, arising from its acting or for
                    reasonable cause, not acting on any instruction in relation to the Request.</li>
                <li>I/We agree to reimburse Montra on demand, for any charges, costs, and expenses including attorney fees incurred by Montra
                    in relation to /or arising out of the Request.</li>
                <li>This indemnity shall be a continuing obligation and shall not be withdrawn by me/us so long as any
                    liability shall remain upon Montra in respect of the Request.</li>
                <li>I/We confirm that I/We have read and understood and hereby accept the terms of this indemnity.</li>
            </ul>
            <br />

        </div>
    );
};

export default Indemnity;
