import React, { useState, useEffect } from "react";
import tap from "../../../assets/images/tap.svg";
import cash from "../../../assets/images/cash.svg";
import arrow1 from "../../../assets/images/arrow1.svg";
import billpayment from "../../../assets/images/bill-payment.svg";
import P2P from "../../../assets/images/P2P.svg";
import P2S from "../../../assets/images/P2S.svg";
import qr from "../../../assets/images/qr.svg";
import POS1 from "../../../assets/images/POS1.svg";
import anym from "../../../assets/images/anym.svg";
import withdraw from "../../../assets/images/withdraw.svg";
import montracard from "../../../assets/images/montracard.svg";
import BT from "../../../assets/images/Group 89.svg";
import Payment from "../../../assets/images/Payment2.svg";
import DebitedFromMontraIcon from "../../../assets/images/uday/Montra-icon-gray.svg";
import {
  Animation1,
  Animation10,
  Animation11,
  Animation2,
  Animation3,
  Animation4,
  Animation4a,
  Animation5,
  Animation6,
  Animation6a,
  Animation7,
  Animation7a,
  Animation7b,
  Animation8,
  Animation9,
} from "./_animations";
import MSP1 from "../../../assets/images/uday/Montra store/Montra store 1.svg";
import MSP2 from "../../../assets/images/uday/Montra store/dine-in.svg";
import MSP3 from "../../../assets/images/uday/Montra store/home-service.svg";
import MSP4 from "../../../assets/images/uday/Montra store/appointment.svg";
import Biller from "../../../assets/images/uday/biller.svg";
import AnyBankAccount from "../../../assets/images/uday/anybankaccount.svg";
import montrPgSkeleton from "../../../assets/images/uday/montra-PG/payment_gateway.webp";
import PGSKE1 from "../../../assets/images/uday/montra-PG/savedcardskeletonicon.svg";
import PGSKE2 from "../../../assets/images/uday/montra-PG/qrscanskeletonicon.svg";
import PGSKE3 from "../../../assets/images/uday/montra-PG/montrappskeleton.svg";
import PGSKE4 from "../../../assets/images/uday/montra-PG/ussdskeletonicon.svg";
import BusinessSection1Gif from "../../../assets/images/uday/gifs/business-section1-gif.gif";
import BusinessSection2Gif from "../../../assets/images/uday/gifs/business-section2-gif.gif";
import BusinessSectionStoreGif from "../../../assets/images/uday/gifs/business-store.gif";
import PaymentGatewayGif from "../../../assets/images/uday/gifs/payment-gateway.gif";
import "./style.css";
import BusinessAccountProcessContainer from "./BusinessAccountProcessContainer.js";
import AgencyBankingProcessContainer from "./AgencyBankingProcessContainer.js";
import StoreProcessContainer from "./StoreProcessContainer.js";
import PGProcessContainer from "./PGProcessContainer.js";

const preloadImage = (src) => {
  const img = new Image();
  img.src = src;
};

const Main = ({ businessPG }) => {
  const [anim, setAnim] = useState(1);
  const [anim2, setAnim2] = useState(1);
  const [anim3, setAnim3] = useState(1);
  const [isAnimationPaused, setIsAnimationPaused] = useState(false);

  const [ms, setMs] = useState(1);
  const [showS, setShowS] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 1024;
  const shopMobile = windowWidth <= 1024;
  const desktopWidth = windowWidth >= 1600;
  const pgAnimGif = PaymentGatewayGif;
  const handleMouseEnter = (imageNumber) => {
    setMs(imageNumber);
    setIsAnimationPaused(true);
    console.log("animation image paused");
  };
  const handleMouseLeave = () => {
    setIsAnimationPaused(false);
    const newIndex = ms === 4 ? 1 : ms + 1;
    setMs(newIndex);
  };

  const handleAnimMouseEnter = () => {
    setIsAnimationPaused(true);
    console.log("animation paused");
  };

  const handleAnimMouseLeave = () => {
    setIsAnimationPaused(false);
    console.log("animation started");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationPaused) {
        setAnim((prev) => (prev === 3 ? 1 : prev + 1));
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isAnimationPaused]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationPaused) {
        setAnim2((prev) => {
          return prev === 8 ? 1 : prev + 1;
        });
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isAnimationPaused]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationPaused) {
        setAnim3((prev) => {
          return prev === 4 ? 1 : prev + 1;
        });
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isAnimationPaused]);

  const images = [
    { active: MSP1, skeleton: MSP1 },
    { active: MSP2, skeleton: MSP2 },
    { active: MSP3, skeleton: MSP3 },
    { active: MSP4, skeleton: MSP4 },
  ];

  useEffect(() => {
    images.forEach((image) => {
      preloadImage(image.active);
      preloadImage(image.skeleton);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationPaused) {
        setMs((prev) => {
          return prev === 4 ? 1 : prev + 1;
        });
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isAnimationPaused]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationPaused) {
        setShowS((prev) => {
          return !prev;
        });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [isAnimationPaused]);

  return (
    <>
      <div className="home-business">
        <div className="arrow">
          <img src={arrow1} alt="arrow" />
        </div>

        <div className="home-business-body">
          <div className="row businessAccount businessaccounthomes">
            <div className="col duo-col businesshomecol">
              <div className="home-business-left">
                <div className="left-1 ">
                  <p className="busnesshomeheader">Montra - Business</p>
                  <h1>
                    <span>Grow</span> your business with{" "}
                    <span>Montra Business Account</span>
                    <span className="andfont"> & </span> start accepting
                    Payments from everyone
                  </h1>
                  <p>
                    Elevate your business with Montra's tailored solutions,
                    propelling you toward your goals with unmatched support and
                    innovation.
                  </p>
                  <a href="/Business/Payment">
                    <button>
                      Explore Payments Acceptance{" "}
                      <i class="bi bi-chevron-right"></i>
                    </button>
                  </a>
                  <div>
                    {/* <img
                      className="direction-img"
                      src={businessanimg1}
                      alt=""
                    /> */}
                    <BusinessAccountProcessContainer />
                  </div>
                </div>
              </div>
            </div>
            <div className="col BusinessAnimation duo-col">
              <div className="yu-flex gap-92 yu-relative">
                {!isMobile ? (
                  <React.Fragment className="normal-layout">
                    <div className="yu-mr-3">
                      <div className="ske">
                        <img src={P2S} alt="P2S" />
                        <p className="skep">Montra Customers</p>
                      </div>
                      <div className="ske mt95">
                        <img src={montracard} alt="montracard" />
                        <p className="skep">Any Debit or Credit Card</p>
                      </div>
                      <div className="ske mt165">
                        <img src={anym} alt="anym" />
                        <p className="skep">Any Mobile Banking App</p>
                      </div>
                    </div>

                    <div className="yu-mr-3">
                      <div className="ske">
                        <img src={qr} alt="qr" />
                        <p className="skep">QR Scan</p>
                      </div>
                      <div className="ske mt30">
                        <img src={tap} alt="tap" />
                        <p className="skep">Tap & Pay</p>
                      </div>
                      <div className="ske mt38">
                        <img src={POS1} alt="POS1" />
                        <p className="skep">POS</p>
                      </div>
                      <div className="ske mt30">
                        <img src={qr} alt="qr" />
                        <p className="skep">QR Scan</p>
                      </div>
                      <div className="ske mt38">
                        <img src={BT} alt="BT" />
                        <p className="skep">Bank Transfer</p>
                      </div>
                    </div>
                    <div className="yu-mr-3">
                      <div className="ske mt-2">
                        <merchantGrey />
                        {/* <img src={merchantGrey} alt="merchantGrey" /> */}
                        <p className="skep">Montra Merchants</p>
                      </div>
                    </div>

                    <div>
                      {anim === 1 && (
                        <Animation1
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                          className="businessAnimation"
                        />
                      )}
                      {anim === 2 && (
                        <Animation2
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                          className="businessAnimation"
                        />
                      )}
                      {anim === 3 && (
                        <Animation3
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                          className="businessAnimation"
                        />
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="gif-layout">
                    <img
                      src={BusinessSection1Gif}
                      alt="business-section gif"
                      className="business-section1-gif"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="AgentModule-wrapper businessaccounthomes">
            <div className="row AgentModule">
              <div className="col duo-col businesshomecol">
                <div className="home-business-left">
                  <div className="left-1 left">
                    <h1 className="home-business-left-h1">
                      Earn more by opening a{" "}
                      <span className="home-business-left-h1-span">
                        Montra Agent Account
                      </span>{" "}
                      <span className="andfont home-business-left-h1-span">
                        {" "}
                        &{" "}
                      </span>
                      start offering{" "}
                      <span className="home-business-left-h1-span">
                        Agency Banking Services
                      </span>
                    </h1>
                    <p>
                      Expand your financial horizon and provide essential
                      banking services to your community. Montra makes it easy
                      for you to boost your income while offering valuable
                      services to those around you.
                    </p>
                    <a href="/Business/AgencyBanking">
                      <button>
                        Explore Agency Banking Services{" "}
                        <i class="bi bi-chevron-right"></i>
                      </button>
                    </a>
                    <div>
                      {/* <img
                      className="direction-img"
                      src={businessanimg2}
                      alt="businessanimg2"
                    /> */}
                      <AgencyBankingProcessContainer />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col business-2 duo-col">
                <div className="yu-flex gap-72 yu-relative mt24">
                  {!isMobile ? (
                    <React.Fragment className="normal-layout">
                      <div className="yu-mr-4">
                        <div className="ske">
                          <img src={P2S} alt="P2S" />
                          <p className="skep">Montra Customers</p>
                        </div>
                        <div className="ske mt72">
                          <img src={montracard} alt="montracard" />
                          <p className="skep">Any Debit or Credit Card</p>
                        </div>
                        <div className="ske mt72">
                          <img src={anym} alt="anym" />
                          <p className="skep">Any Mobile Banking App</p>
                        </div>
                        <div className="ske mt68">
                          <img src={Payment} alt="Payment" />
                          <p className="skep">Cash Payments</p>
                        </div>
                        <div className="ske mt24">
                          <img src={Biller} alt="Biller" />
                          <p className="skep">Biller</p>
                        </div>
                        <div className="ske mt24">
                          <img src={AnyBankAccount} alt="AnyBankAccount" />
                          <p className="skep">Any Bank Account</p>
                        </div>
                      </div>
                      <div className="yu-mr-4">
                        <div className="ske">
                          <img src={withdraw} alt="withdraw" />

                          <p className="skep">Cash Withdrawal</p>
                        </div>
                        <div className="ske mt8">
                          <img src={cash} alt="cash" />
                          <p className="skep">Cash Deposit</p>
                        </div>
                        <div className="ske mt20">
                          <img src={withdraw} alt="withdraw" />
                          <p className="skep">Cash Withdrawal</p>
                        </div>
                        <div className="ske mt20">
                          <img src={withdraw} alt="withdraw" />
                          <p className="skep">Cash Withdrawal</p>
                        </div>
                        <div className="ske mt8">
                          <img src={cash} alt="cash" />
                          <p className="skep">Cash Deposit</p>
                        </div>
                        <div className="ske mt145">
                          <img src={billpayment} alt="billpayment" />
                          <p className="skep">Recharge & Bills Payment</p>
                        </div>
                        <div className="ske mt20">
                          <img src={BT} alt="BT" />
                          <p className="skep">Bank Transfer</p>
                        </div>
                      </div>
                      <div className="yu-mr-4">
                        <div className="ske">
                          <img src={qr} alt="qr" />
                          <p className="skep">QR Scan</p>
                        </div>
                        <div className="ske mt20">
                          <img src={tap} alt="tap" />
                          <p className="skep">Tap & Pay</p>
                        </div>
                        <div className="ske mt8">
                          <img src={POS1} alt="POS1" />
                          <p className="skep">POS</p>
                        </div>
                        <div className="ske mt20">
                          <img src={qr} alt="qr" />
                          <p className="skep">QR Scan</p>
                        </div>
                        <div className="ske mt248">
                          <img src={DebitedFromMontraIcon} alt="DebitedFromMontraIcon" />
                          <p className="skep">Montra Agent Account</p>
                        </div>
                      </div>
                      <div
                        className={
                          anim2 == 4
                            ? "yu-mr-4 animation7-montraagent"
                            : anim2 === 5
                              ? "yu-mr-4 mt330"
                              : "yu-mr-4"
                        }
                      >
                        <div className="ske">
                          <img src={P2P} alt="P2P" />
                          <p className="skep">Montra Agent</p>
                        </div>
                      </div>
                      <div>
                        {anim2 === 1 && (
                          <Animation4
                            onMouseEnter={handleAnimMouseEnter}
                            onMouseLeave={handleAnimMouseLeave}
                            className="businessAnimation"
                          />
                        )}
                        {anim2 === 2 && (
                          <Animation4a
                            onMouseEnter={handleAnimMouseEnter}
                            onMouseLeave={handleAnimMouseLeave}
                            className="businessAnimation"
                            anim2={anim2}
                          />
                        )}
                        {anim2 === 3 && (
                          <Animation5
                            onMouseEnter={handleAnimMouseEnter}
                            onMouseLeave={handleAnimMouseLeave}
                            className="businessAnimation"
                          />
                        )}
                        {anim2 === 4 && (
                          <Animation6
                            onMouseEnter={handleAnimMouseEnter}
                            onMouseLeave={handleAnimMouseLeave}
                            className="businessAnimation"
                          />
                        )}
                        {anim2 === 5 && (
                          <Animation6a
                            onMouseEnter={handleAnimMouseEnter}
                            onMouseLeave={handleAnimMouseLeave}
                            className="businessAnimation"
                            anim2={anim2}
                          />
                        )}
                        {anim2 === 6 && (
                          <Animation7
                            onMouseEnter={handleAnimMouseEnter}
                            onMouseLeave={handleAnimMouseLeave}
                            className="businessAnimation"
                            anim2={anim2}
                          />
                        )}
                        {anim2 === 7 && (
                          <Animation7a
                            onMouseEnter={handleAnimMouseEnter}
                            onMouseLeave={handleAnimMouseLeave}
                            className="businessAnimation"
                            anim2={anim2}
                          />
                        )}
                        {anim2 === 8 && (
                          <Animation7b
                            onMouseEnter={handleAnimMouseEnter}
                            onMouseLeave={handleAnimMouseLeave}
                            className="businessAnimation"
                            anim2={anim2}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="gif-layout">
                      <img
                        src={BusinessSection2Gif}
                        alt="business-section gif"
                        className="business-section1-gif"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row MontraStoreModule businessaccounthomes">
            <div className="col home-business-left duo-col">
              <div className="left-3 left">
                <p>Don’t have a website for your business?</p>
                <h1>
                  Start selling online on Montra App by setting up your{" "}
                  <span className="home-business-left-h1-span">
                    Montra Store
                  </span>
                </h1>
                <p>
                  Create a free online customized Montra store for your business
                  category that helps you find customers, accept payments from
                  anyone, and grow your business.
                </p>
                <a href="/Business/more/Store">
                  <button>
                    Explore Montra Store offering{" "}
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </a>
                <div>
                  {/* <img className="direction-img" src={businessanimg3} alt="" /> */}
                  <StoreProcessContainer />
                </div>
              </div>
            </div>
            <div className="col MontraStoreAnimation">
              {!shopMobile ? (
                <div className="yu-flex-c gap-16 mt24 montrastore-animation-flex">
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={ms === index + 1 ? image.active : image.skeleton}
                      alt={`Montra Store Image ${index + 1}`}
                      className={`e-com-content-image ${ms === index + 1 ? "show" : "hide"
                        }`}
                      onMouseEnter={() => handleMouseEnter(index + 1)}
                      onMouseLeave={handleMouseLeave}
                    />
                  ))}
                </div>
              ) : (
                <div className="gif-layout">
                  <img
                    src={BusinessSectionStoreGif}
                    alt="business-section-store gif"
                    className="business-section1-gif"
                  />
                </div>
              )}
            </div>
          </div>
          {businessPG && (
            <div
              className={
                // businessPG ? "home-businesspg-section" : "home-business-body"
                "home-business-body"
              }
              style={{ paddingTop: "0px" }}
            >
              <div
              // style={{
              //   backgroundColor: `${bgChange}`,
              //   paddingBottom: SectionPadding,
              // }}
              >
                <div
                  className="row nowrap montrapg-wrapper"
                // style={{ paddingTop: SectionPadding }}
                >
                  <div className="col duo-col">
                    <div className="home-business-left home-business-left-pg">
                      <div className="left-4 left">
                        <p
                        //  style={{ color: businessPgColor }}
                        >
                          Have an existing website or Mobile App?
                        </p>
                        <h1
                          className="home-montra-pg-h1"
                        // style={{ color: businessPgColor }}
                        >
                          Power your business with{" "}
                          <span
                            className="home-montra-pg-h1-span"
                          // style={{ color: businessPgColor }}
                          >
                            Montra Payment Gateway
                          </span>
                        </h1>
                        <p
                        // style={{ color: businessPgColor }}
                        >
                          Simply integrate Montra Payment Gateway with your
                          website or Mobile App for your customers to make
                          payments using their :
                        </p>
                        <ul className="business-list">
                          <li
                            className="home-bussineslist"
                          // style={{ color: businessPgColor }}
                          >
                            Debit & Credit Cards
                          </li>
                          <li
                            className="home-bussineslist"
                          // style={{ color: businessPgColor }}
                          >
                            Bank Accounts by scanning QR code using their Mobile
                            Banking Apps
                          </li>
                          <li
                            className="home-bussineslist"
                          // style={{ color: businessPgColor }}
                          >
                            Montra App where they can make payments using Montra
                            account and even using Installments
                          </li>
                          <li
                            className="home-bussineslist"
                          // style={{ color: businessPgColor }}
                          >
                            USSD to make payment using USSD app
                          </li>
                        </ul>
                        <a href="/Business/more/Gateway">
                          <button
                          // style={{ color: businessPgColor }}
                          >
                            Explore Montra Payment Gateway{" "}
                            <i class="bi bi-chevron-right"></i>
                          </button>
                        </a>
                        <div>
                          {/* <img
                      className="direction-img"
                      src={businessanimg4}
                      alt=""
                    /> */}
                          <PGProcessContainer />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col PaymentGatewayAnimations duo-col">
                    {!isMobile ? (
                      <React.Fragment className="normal-layout">
                        <div
                          className={
                            desktopWidth
                              ? "mt40 pg-animation-items"
                              : "mt24 pg-animation-items"
                          }
                        >
                          <div className="pg-animation-groups">
                            <div className="pg-animation">
                              <img src={PGSKE1} alt="New and Saved Cards" />
                              <p className="pganim-text">New and Saved Cards</p>
                            </div>
                            <div className="pg-animation">
                              <img src={PGSKE2} alt="QR SCAN" />
                              <p className="pganim-text">QR SCAN</p>
                            </div>
                          </div>
                          <div className="pg-animation-groups">
                            <div className="pg-animation">
                              <img
                                src={PGSKE3}
                                alt="Montra App"
                                className="montrapp-ussd"
                              />
                              <p className="pganim-text">Montra App</p>
                            </div>
                            <div className="pg-animation">
                              <img
                                src={PGSKE4}
                                alt="USSD"
                                className="ussd-montrapp "
                              />
                              <p className="pganim-text">USSD</p>
                            </div>
                          </div>
                        </div>
                        <div className="pg-animation-rightimage">
                          <div className="pg-animation">
                            <img
                              src={montrPgSkeleton}
                              alt="QR SCAN"
                              className="pg-right-image"
                            />
                            <p className="pganim-text">QR SCAN</p>
                          </div>
                        </div>
                        <div>
                          {anim3 === 1 && (
                            <Animation8
                              onMouseEnter={handleAnimMouseEnter}
                              onMouseLeave={handleAnimMouseLeave}
                              desktopWidth={desktopWidth}
                            />
                          )}
                          {anim3 === 2 && (
                            <Animation9
                              onMouseEnter={handleAnimMouseEnter}
                              onMouseLeave={handleAnimMouseLeave}
                              desktopWidth={desktopWidth}
                            />
                          )}
                          {anim3 === 3 && (
                            <Animation10
                              onMouseEnter={handleAnimMouseEnter}
                              onMouseLeave={handleAnimMouseLeave}
                              desktopWidth={desktopWidth}
                            />
                          )}
                          {anim3 === 4 && (
                            <Animation11
                              onMouseEnter={handleAnimMouseEnter}
                              onMouseLeave={handleAnimMouseLeave}
                              desktopWidth={desktopWidth}
                            />
                          )}
                        </div>
                      </React.Fragment>
                    ) : (
                      <div className="gif-layout">
                        <img
                          src={pgAnimGif}
                          alt="business-section gif"
                          className="business-section1-gif"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default React.memo(Main);
