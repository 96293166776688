import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import Messaging from "../../../assets/images/uday/personal-icons/chat/messaging.svg";
import GroupMessaging from "../../../assets/images/uday/personal-icons/chat/group.svg";
import VoiceCalls from "../../../assets/images/uday/personal-icons/chat/voiceCall.svg";
import VideoCalls from "../../../assets/images/uday/personal-icons/chat/videoCall.svg";
import MakePayments from "../../../assets/images/uday/personal-icons/chat/makePayment.svg";
import RequestPayments from "../../../assets/images/uday/personal-icons/chat/requestPayment.svg";
import chathero2 from "../../../assets/images/chathero2.svg";

const steps = [
  { title: "Montra Chat", image: chathero2, className: "chat-img" },
  { image: Messaging, description: "Messaging" },
  { image: GroupMessaging, description: "Group Messaging" },
  { image: VoiceCalls, description: "Voice Calls" },
  { image: VideoCalls, description: "Video Calls" },
  { image: MakePayments, description: "Make Payments" },
  { image: RequestPayments, description: "Request Payments" },
];

const ChatProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="personal-finance-topdescription"
      />
    </div>
  );
};

export default ChatProcessContainer;
