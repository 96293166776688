import { useContext, useEffect, useRef, useState } from "react";
import { ActiveStoryContext } from "./Main";
import stories from "./CompanyStories.js";
import "./style.css";
import Slider from "react-slick";

const Hero = () => {
  const [active, setActive] = useContext(ActiveStoryContext);
  const activeStory = stories[active - 1];
  const [activeYear, setActiveYear] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sliderRef = useRef(null);

  useEffect(() => {
    setActiveYear(active - 1);
  }, [active]);

  const handlePreviousClick = () => {
    if (activeYear > 0) {
      setActive(active - 1);
      setActiveYear(activeYear - 1);
    }
  };

  const handleNextClick = () => {
    if (activeYear < stories.length - 1) {
      setActive(active + 1);
      setActiveYear(activeYear + 1);
    }
  };

  console.log("activeStory", activeStory);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth <= 1023;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  console.log("activeStory=====", activeStory);

  return (
    <>
      <div className="story-hero">
        <img
          src={activeStory?.bannerImage}
          alt={`Banner for ${activeStory.year}`}
          className="banner-image"
        />
        {!isMobile ? (
          <>
            <div className="image-grid" key={active}>
              {activeStory.imagesGrid.map((image, index) => (
                <div
                  key={index}
                  className={`grid-image-wrapper grid-image-wrapper-${index + 1
                    }`}
                >
                  <img
                    src={image}
                    alt={`Image ${index + 1} for ${activeStory.year}`}
                    className="grid-image"
                  />
                </div>
              ))}
            </div>

            <div className="year-btns">
              {stories.map((item) => (
                <div className="year" key={item.id}>
                  <div className="connect-group">
                    <input
                      name="currentYear"
                      className="year-radio"
                      onChange={() => setActive(item.id)}
                      type="radio"
                      checked={active === item.id}
                    />
                    <div className="connector"></div>
                  </div>
                  <label>{item.year}</label>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="year-arrows-container">
            {stories.map((story, index) => (
              <div
                key={index}
                className="year-arrows"
                style={{ display: index === activeYear ? "flex" : "none" }}
              >
                {activeYear > 0 && (
                  <div
                    className="prev-arrow"
                    onClick={handlePreviousClick}
                  ></div>
                )}
                <h2>{story.year}</h2>
                {activeYear < stories.length - 1 && (
                  <div className="next-arrow" onClick={handleNextClick}></div>
                )}
              </div>
            ))}

            <Slider
              ref={sliderRef} // Assign ref to Slider component
              {...settings}
              afterChange={(index) => {
                setActive(index + 1);
                console.log("indexxxxx", index);
              }}
            >
              {stories.map((story, index) => (
                <div key={index} className="event-slide">
                  <div className="list-div">
                    {activeStory.events.title !== "" && (
                      <h6>{activeStory.events.title}</h6>
                    )}
                    <ul>
                      {activeStory?.events.Firstsubevents &&
                        activeStory.events.Firstsubevents.length > 0 &&
                        activeStory.events.Firstsubevents.map(
                          (subevent, subIdx) => <li key={subIdx}>{subevent}</li>
                        )}
                    </ul>
                    <ul>
                      {activeStory?.events.firstEvent &&
                        activeStory.events.firstEvent.length > 0 &&
                        activeStory.events.firstEvent.map(
                          (subevent, subIdx) => <li key={subIdx}>{subevent}</li>
                        )}
                    </ul>
                    <ul>
                      {activeStory?.events.subevents &&
                        activeStory.events.subevents.length > 0 &&
                        activeStory.events.subevents.map((subevent, subIdx) => (
                          <li key={subIdx}>{subevent}</li>
                        ))}
                    </ul>

                    {activeStory.events.Secondtitle !== "" && (
                      <h6>{activeStory.events.Secondtitle}</h6>
                    )}

                    <ul>
                      {activeStory?.events.Secondsubevents &&
                        activeStory.events.Secondsubevents.length > 0 &&
                        activeStory.events.Secondsubevents.map(
                          (subevent, subIdx) => <li key={subIdx}>{subevent}</li>
                        )}
                    </ul>
                    {activeStory.events.Thirdtitle !== "" && (
                      <h6>{activeStory.events.Thirdtitle}</h6>
                    )}

                    <ul>
                      {activeStory?.events.Thirdsubevents &&
                        activeStory.events.Thirdsubevents.length > 0 &&
                        activeStory.events.Thirdsubevents.map(
                          (subevent, subIdx) => <li key={subIdx}>{subevent}</li>
                        )}
                    </ul>
                    {activeStory.events.Fourthtitle !== "" && (
                      <h6>{activeStory.events.Fourthtitle}</h6>
                    )}

                    <ul>
                      {activeStory?.events.Fourthsubevents &&
                        activeStory.events.Fourthsubevents.length > 0 &&
                        activeStory.events.Fourthsubevents.map(
                          (subevent, subIdx) => <li key={subIdx}>{subevent}</li>
                        )}
                    </ul>
                    <>
                      {activeStory?.events.secondEvent &&
                        activeStory.events.secondEvent.length > 0 &&
                        activeStory.events.secondEvent.map(
                          (subevent, subIdx) => <h6 key={subIdx}>{subevent}</h6>
                        )}
                    </>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </>
  );
};

export default Hero;
