import React from "react";
import "../Overview/style.css";
import "./style.css";
import duoapp1 from "../../../../assets/images/duoapp1.svg";
import duoapp2 from "../../../../assets/images/duoapp2.svg";

const Hero = () => {
  return (
    <>
      <div className="dts-hero duo-app-hero ">
        <div className="dts-row row hero-row ">
          <div className="col-md-7 duo-col">
            <div className="duo-sm-btn duop">
              <img
                src={duoapp2}
                alt="duo small mobile"
                className="duo-small-image"
              />
              <p>Duo App</p>
            </div>
            <h1 className="duo-app-h1">
              Save time, stay informed.{" "}
              <span>Get the DUO Mobile app introduced by your bank</span>
            </h1>
            <p className="geteasy">
              Get easy access to your Virtual Credit Card details for requesting
              online transactions, making repayments & access your transaction
              history, debit & credit card statements on DUO app.
            </p>
            <div className="duo-list-container duo-app-list-container">
              <div className="duo-lists duo-lists-app">
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Simply register on DUO App to Activate Dual Transaction
                      Service (DTS) being offered by your bank.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Access all details related to your Credit Limit, Available
                      balance, Minimum repayment due, Repayment due date, Card &
                      Payment Status.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      Get your Virtual Credit Card details for making online
                      payment.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>
                      View your transaction history for all the transactions you
                      have made using your Virtual Credit Card through your
                      existing Debit Card or DUO App.
                    </p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>View your recent Credit & Debit card statements.</p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>Make repayments against your credit card outstanding.</p>
                  </div>
                </div>
                <div className="duo-list">
                  <div className="duo-list-box"></div>
                  <div>
                    <p>Place requests to upgrade to Duo Card Product (DCP).</p>
                  </div>
                </div>
              </div>
            </div>

            <button>Contact Sales</button>
          </div>
          <div className="col-md-5 duo-col">
            <img src={duoapp1} className="duo-app-hero-img" alt="Duo App" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
