import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
// import chathero1 from "../../../assets/images/chathero1.svg";
import chathero1 from "../../../assets/images/uday/chathero1.webp";
// import chathero2 from "../../../assets/images/chathero2.svg";
import ChatProcessContainer from "./ChatProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="chat-hero">
        <div className="row personalb">
          <div className="col-md-6 finance-hero-left">
            <h1>
              <span>Free, secure calls and messages</span> to anyone, anywhere
            </h1>
            <p>
              Simple, reliable, private messaging and calls for free. Montra
              Chat comes with CHAT PAY feature to enable you to make payments or
              request payments while you are chatting with someone.
            </p>
            {/* <img className="chat-img" src={chathero2} alt="Chatimage" /> */}
            <ChatProcessContainer />
            <div className="app-download financedownload">
              <img src={appstore} alt="appstore" />
              <img src={playstore} alt="playstore" />
            </div>
          </div>
          <div className="col-md-6 chat-hero-right">
            <img src={chathero1} alt="Chatimage" className="chatimg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
