import React, { useState } from "react";
import arrow from "../../assets/images/back-arrow.svg";
import icon1 from "../../assets/images/responsive1.svg";
import icon2 from "../../assets/images/responsive2.svg";
import icon3 from "../../assets/images/responsive3.svg";
import icon4 from "../../assets/images/responsive4.svg";
import icon5 from "../../assets/images/responsive5.svg";
import icon6 from "../../assets/images/responsive6.svg";
import logo from "../../assets/images/montralogo.svg";
import "./mobilemenu.css";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

const Mobilemenu = ({ setOpen, onMenuSelect }) => {
  const [showNewUI, setShowNewUI] = useState(false);
  const [showDuoUI, setShowDuoUI] = useState(false);
  const [showPlatformUI, setShowPlatformUI] = useState(false);
  const [showCompanyUI, setShowCompanyUI] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [animating, setAnimating] = useState(false);
  const [animationDirection, setAnimationDirection] = useState("");

  const handleArrowClick = (item) => {
    if (animating) return; // Prevent multiple rapid clicks during animation

    setAnimating(true);

    if (item === "Global Payments") {
      setShowNewUI(true);
      setShowDuoUI(false);
      setShowPlatformUI(false);
      setShowCompanyUI(false);
    } else if (item === "Banking-as-a-Service") {
      setShowNewUI(false);
      setShowDuoUI(true);
      setShowPlatformUI(false);
      setShowCompanyUI(false);
    } else if (item === "platforms") {
      setShowNewUI(false);
      setShowDuoUI(false);
      setShowPlatformUI(true);
      setShowCompanyUI(false);
    } else if (item === "company") {
      setShowNewUI(false);
      setShowDuoUI(false);
      setShowPlatformUI(false);
      setShowCompanyUI(true);
    }
    setAnimationDirection("right");
    setTimeout(() => {
      setActiveItem(item);
      setAnimating(false);
    }, 500); // Animation duration
  };

  const handleBackClick = () => {
    if (animating) return; // Prevent multiple rapid clicks during animation

    setAnimating(true);
    setShowNewUI(false);
    setShowDuoUI(false);
    setShowPlatformUI(false);
    setShowCompanyUI(false);
    setAnimationDirection("left");
    setTimeout(() => {
      setActiveItem(null);
      setAnimating(false);
    }, 500); // Animation duration
  };

  return (
    <div className="mobile-menu">
      <div className="App">
        <div className="sidebar">
          <div className="header">
            {!showNewUI && !showDuoUI && !showPlatformUI && !showCompanyUI ? (
              <Link to="/" className="navbar-brand">
                <img src={logo} className="mobile-logo" alt="Logo" />
              </Link>
            ) : (
              <button className="back-btn" onClick={handleBackClick}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="50"
                  viewBox="0 0 1024 1024"
                  height="0.9em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                </svg>
                <span>Back</span>
              </button>
            )}
            <button className="close-btn" onClick={() => setOpen(false)}>
              <i className="bi bi-x"></i>
            </button>
          </div>
          <div className="mobileheader"></div>
          <div className="section section1">
            <div
              className={`current-ui ${animating && animationDirection === "right"
                ? "slide-out-left"
                : animating && animationDirection === "left"
                  ? "slide-in-left"
                  : ""
                }`}
            >
              {!showNewUI &&
                !showDuoUI &&
                !showPlatformUI &&
                !showCompanyUI && (
                  <div
                    className="menu-item"
                    onClick={() => handleArrowClick("Global Payments")}
                  >
                    <h2>Products</h2>
                    <div
                      className="backarrow-icon"
                      onClick={() => handleArrowClick("Global Payments")}
                    >
                      {activeItem === "Global Payments" ? (
                        <img src={arrow} className="arrowimg" alt="arrow" />
                      ) : (
                        <img src={arrow} className="arrowimg" alt="arrow" />
                      )}
                    </div>
                  </div>
                )}
            </div>
            <div className={`new-ui ${showNewUI ? "slide-in-right" : ""}`}>
              {showNewUI && (
                <div>
                  <div className="navbarmobicon">
                    <div className="responsiveiconwidth">
                      <img src={icon1} alt="icon1" className="mobileicon1" />
                    </div>
                    <div>
                      <h2>Personal</h2>
                      <div className="details">
                        <div className="header-responsive">
                          <ul
                            className="moblielist"
                            onClick={() => handleArrowClick("Global Payments")}
                          >
                            <Link
                              to="/Products/overview#OverView"
                              className="nav-link main-active"
                            >
                              <li> Overview </li>
                            </Link>
                            <Link
                              to="/Products/Banking#Banking"
                              className="nav-link main-active"
                            >
                              <li>Banking</li>
                            </Link>
                            <Link
                              to="/Products/Payment#Payments"
                              className="nav-link main-active"
                            >
                              <li>Payment</li>
                            </Link>
                            <Link
                              to="/Products/Finance#Finance"
                              className="nav-link main-active"
                            >
                              <li>Finance</li>
                            </Link>
                            <Link
                              to="/Products/Chat#Chat"
                              className="nav-link main-active"
                            >
                              <li>Chat</li>
                            </Link>
                            <Link
                              to="/Products/Shop#Shopping"
                              className="nav-link main-active"
                            >
                              <li>Shopping</li>
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="navbarmobicon">
                    <div className="responsiveiconwidth">
                      <img src={icon2} alt="icon2" className="mobileicon1" />
                    </div>
                    <div>
                      <h2>Business</h2>
                      <div className="details">
                        <div className="header-responsive">
                          <ul
                            className="moblielist"
                            onClick={() =>
                              handleArrowClick("Revenue and Finance Automation")
                            }
                          >
                            <Link
                              to="/Business/overview"
                              className="nav-link main-active"
                            >
                              <li>Overview</li>
                            </Link>

                            <Link
                              to="/Business/Payment#Payments"
                              className="nav-link main-active"
                            >
                              <li>Payment</li>
                            </Link>
                            <Link
                              to="/Business/Banking#Banking"
                              className="nav-link main-active"
                            >
                              <li>Banking</li>
                            </Link>

                            <Link
                              to="/Business/AgencyBanking#AgencyBanking"
                              className="nav-link main-active"
                            >
                              <li>Agency Banking </li>
                            </Link>
                            <Link
                              to="/Business/more/TapPay#TapPay"
                              className="nav-link main-active"
                            >
                              <li>Tap & Pay </li>
                            </Link>
                            <Link
                              to="/Business/more/Inventory#Inventory"
                              className="nav-link main-active"
                            >
                              <li>Inventory Management</li>
                            </Link>
                            <Link
                              to="/Business/more/Store#Montra-Store"
                              className="nav-link main-active"
                            >
                              <li>Montra Store</li>
                            </Link>
                            <Link
                              to="/Business/more/Gateway#PaymentGateway"
                              className="nav-link main-active"
                            >
                              <li>Payment Gateway</li>
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="signin-button">
                    <button>sign-in</button>
                  </div> */}
                </div>
              )}
            </div>
          </div>

          <div className="section section2">
            <div
              className={`current-ui ${animating && animationDirection === "right"
                ? "slide-out-left"
                : animating && animationDirection === "left"
                  ? "slide-in-left"
                  : ""
                }`}
            >
              {!showDuoUI &&
                !showNewUI &&
                !showPlatformUI &&
                !showCompanyUI && (
                  <div>
                    <div className="mobileheader1"></div>
                    <div
                      className="menu-item"
                      onClick={() => handleArrowClick("Banking-as-a-Service")}
                    >
                      <div className="menu-content">
                        <h2>Solutions</h2>
                      </div>
                      <div
                        className="backarrow-icon"
                        onClick={() => handleArrowClick("Banking-as-a-Service")}
                      >
                        {activeItem === "Banking-as-a-Service" ? (
                          <img src={arrow} className="arrowimg" alt="arrow" />
                        ) : (
                          <img src={arrow} className="arrowimg" alt="arrow" />
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className={`new-ui ${showDuoUI ? "slide-in-right" : ""}`}>
              {showDuoUI && (
                <div>
                  <div className="navbarmobicon">
                    <div className="responsiveiconwidth">
                      <img src={icon3} alt="icon3" className="mobileicon1" />
                    </div>
                    <div>
                      <h2>DUO</h2>
                      <div className="details">
                        <div className="header-responsive">
                          <ul
                            className="moblielist"
                            onClick={() =>
                              handleArrowClick("Banking-as-a-Service")
                            }
                          >
                            <Link
                              to="/Solutions/Duo/overview"
                              className="nav-link main-active"
                            >
                              <li>Overview</li>
                            </Link>
                            <Link
                              to="/Solutions/Duo/Dts#DTS"
                              className="nav-link main-active"
                            >
                              <li>Dual Transaction Service - DTS</li>
                            </Link>
                            <Link
                              to="/Solutions/Duo/Dcp#DCP"
                              className="nav-link main-active"
                            >
                              <li>Duo Card Product - DCP</li>
                            </Link>
                            <Link
                              to="/Solutions/Duo/Duoapp#DuoApp"
                              className="nav-link main-active"
                            >
                              <li>DUO App</li>
                            </Link>
                            <Link
                              to="/Solutions/Duo/Dwp#DuoWebPortal"
                              className="nav-link main-active"
                            >
                              <li>DUO Web Portal</li>
                            </Link>
                            <Link
                              to="/Solutions/Duo/Customeronboarding#DuoCustomerOnboarding"
                              className="nav-link main-active"
                            >
                              <li>DUO Customer Onboarding</li>
                            </Link>

                            <Link
                              to="/Solutions/Duo/Activation#Activation"
                              className="nav-link main-active"
                            >
                              <li>Activation</li>
                            </Link>
                            <Link
                              to="/Solutions/Duo/Dlm#LimitManagement"
                              className="nav-link main-active"
                            >
                              <li>Dynamic Limit Management</li>
                            </Link>
                            <Link
                              to="/Solutions/Duo/Collection#Collections"
                              className="nav-link main-active"
                            >
                              <li>Collections</li>
                            </Link>
                          </ul>

                          <NavLink
                            to="https://www.duopay.montra.org/PartnerLogin"
                            target="_blank"
                            className="respon-btn-a"
                          >
                            <li className="nav-item">
                              <button className="sol-button respon-btn">
                                Partner Sign in{" "}
                                <i className="bi bi-chevron-right"></i>
                              </button>
                            </li>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="navbarmobicon">
                    <div className="responsiveiconwidth">
                      <img src={icon4} alt="icon4" className="mobileicon1" />
                    </div>
                    <div>
                      <h2>Verifyed</h2>
                      <div className="details">
                        <div className="header-responsive">
                          <ul
                            className="moblielist"
                            onClick={() => handleArrowClick("veryfied")}
                          >
                            <Link
                              to="/Solution/Verified/Authapp#AuthenticatorApp"
                              className="nav-link main-active"
                            >
                              <li>Authenticator App</li>
                            </Link>
                            <Link
                              to="/Solution/Verified/Faceauth#FaceAuthentication"
                              className="nav-link main-active"
                            >
                              <li>Face Authentication</li>
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="navbarmobicon">
                    <div className="responsiveiconwidth">
                      <img src={icon5} alt="icon5" className="mobileicon1" />
                    </div>
                    <div>
                      <h2>Montra Supply Chain</h2>
                      <div className="details">
                        <div className="header-responsive">
                          <ul
                            className="moblielist"
                            onClick={() => handleArrowClick("SupplyChain")}
                          >
                            <Link
                              to="/Solutions/Supplychain/overview"
                              className="nav-link main-active"
                            >
                              <li>Overview</li>
                            </Link>
                            <Link
                              to="/Solutions/Supplychain/payment"
                              className="nav-link main-active"
                            >
                              <li>Payments</li>
                            </Link>
                            <Link
                              to="/Solutions/Supplychain/collection"
                              className="nav-link main-active"
                            >
                              <li>Collections</li>
                            </Link>
                            <Link
                              to="/Solutions/Supplychain/Sales"
                              className="nav-link main-active"
                            >
                              <li>Sales Promotions</li>
                            </Link>
                            <Link
                              to="/Solutions/Supplychain/Orderfulfilment"
                              className="nav-link main-active"
                            >
                              <li> Order Fulfilment</li>
                            </Link>
                            <Link
                              to="/Solutions/Supplychain/Distributionanalytics"
                              className="nav-link main-active"
                            >
                              <li>Distribution Analytics</li>
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="section section2">
            <div
              className={`current-ui ${animating && animationDirection === "right"
                ? "slide-out-left"
                : animating && animationDirection === "left"
                  ? "slide-in-left"
                  : ""
                }`}
            >
              {!showPlatformUI &&
                !showNewUI &&
                !showDuoUI &&
                !showCompanyUI && (
                  <div>
                    <div className="mobileheader1"></div>
                    <div
                      className="menu-item"
                      onClick={() => handleArrowClick("platforms")}
                    >
                      <h2>Platform</h2>
                      <div
                        className="backarrow-icon"
                        onClick={() => handleArrowClick("platforms")}
                      >
                        {activeItem === "platforms" ? (
                          <img src={arrow} className="arrowimg" alt="arrow" />
                        ) : (
                          <img src={arrow} className="arrowimg" alt="arrow" />
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className={`new-ui ${showPlatformUI ? "slide-in-right" : ""}`}>
              {showPlatformUI && (
                <div>
                  <div className="navbarmobicon">
                    <div className="responsiveiconwidth">
                      <img src={icon6} alt="icon6" className="mobileicon1" />
                    </div>
                    <div>
                      <h2>Moneylink</h2>
                      <div className="details">
                        <div className="header-responsive">
                          <ul
                            className="moblielist"
                            onClick={() => handleArrowClick("Platform")}
                          >
                            <Link
                              to="/Platform/Moneylink/cip#CardIssuing"
                              className="nav-link main-active"
                            >
                              <li>Card Issuing</li>
                            </Link>
                            <Link
                              to="/Platform/Moneylink/merchant"
                              className="nav-link main-active"
                            >
                              <li>Merchant Services</li>
                            </Link>
                            <Link
                              to="/Platform/Moneylink/Loanmanagement"
                              className="nav-link main-active"
                            >
                              <li>Loan Management System</li>
                            </Link>
                            <Link
                              to="/Platform/Moneylink/Debtcollections"
                              className="nav-link main-active"
                            >
                              <li>Debt Collection</li>
                            </Link>
                            <Link
                              to="/Platform/Moneylink/Accountmanagement"
                              className="nav-link main-active"
                            >
                              <li>Account Management System</li>
                            </Link>
                          </ul>
                          <NavLink
                            to="https://www.montra.org/PartnerLogin"
                            target="_blank"
                            className="respon-btn-a"
                          >
                            <li className="nav-item">
                              <button className="plat-button respon-btn">
                                Partner Sign in{" "}
                                <i className="bi bi-chevron-right"></i>
                              </button>
                            </li>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="section section2">
            <div
              className={`current-ui ${animating && animationDirection === "right"
                ? "slide-out-left"
                : animating && animationDirection === "left"
                  ? "slide-in-left"
                  : ""
                }`}
            >
              {!showCompanyUI &&
                !showNewUI &&
                !showDuoUI &&
                !showPlatformUI && (
                  <div>
                    <div className="mobileheader1"></div>
                    <div
                      className="menu-item"
                      onClick={() => handleArrowClick("company")}
                    >
                      <h2>Company</h2>
                      <div
                        className="backarrow-icon"
                        onClick={() => handleArrowClick("company")}
                      >
                        {activeItem === "company" ? (
                          <img src={arrow} className="arrowimg" alt="arrow" />
                        ) : (
                          <img src={arrow} className="arrowimg" alt="arrow" />
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className={`new-ui ${showCompanyUI ? "slide-in-right" : ""}`}>
              {showCompanyUI && (
                <div>
                  <div className="details">
                    <div className="header-responsive">
                      <ul
                        className="moblielist"
                        onClick={() => handleArrowClick("Company")}
                      >
                        <Link
                          to="/Company/Overview"
                          className="nav-link main-active"
                        >
                          <li>Overview</li>
                        </Link>
                        <Link
                          to="/Company/Ourstory"
                          className="nav-link main-active"
                        >
                          <li>Our Story</li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobilemenu;
