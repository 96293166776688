import React from 'react'
import Navbar from '../../../Navbar/Solution';
import Footer from '../../../Footer/Solfooter';
import Hero from './Hero';
import Body from './Body';

const Main = () => {
  return (
    <>
      <div id="DCP">
        <Navbar />
        <Hero />
        <Body />
        <div className='dcpfooter'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Main