import React from 'react';
import Navbar from '../../Navbar/Businessnav'
import './style.css';
import Productfooter from '../../Footer/Productfooter';
import Hero from './Hero';
import Body from './Body';

const Main = () => {
  return (
    <>
      <div id="AgencyBanking">
        <Navbar />
        <Hero />
        <Body />
        <div className="">
          <Productfooter />
        </div>
      </div>
    </>
  )
}

export default Main