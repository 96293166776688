import React from "react";

function YU21() {
  return (
    <svg
      width="511"
      height="72"
      viewBox="0 0 511 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        d="M509.5 1C510.5 4.66667 508.3 12.1 491.5 12.5C474.7 12.9 385.833 12.6667 343.5 12.5V61.5C343.5 64.6667 339.9 70.9 325.5 70.5C311.1 70.1 113.5 70.3333 16.5 70.5C11.3333 70.6667 1 68.6 1 59"
        stroke="url(#paint0_radial_5220_7444)"
        stroke-width="2"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5220_7444"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(359.042 35.7592) rotate(90) scale(34.7592 358.042)"
        >
          <stop stop-color="#17D92A" />
          <stop offset="1" stop-color="#FFBB08" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU21;
