import React from "react";

function CashPayment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#20336B"
        d="M8.088 18.371a.489.489 0 00-.128-.24.448.448 0 00-.152-.101.461.461 0 00-.44.043.449.449 0 00-.172.21.454.454 0 00-.026.271.44.44 0 00.07.169.445.445 0 00.21.172.454.454 0 00.27.027.44.44 0 00.17-.07.448.448 0 00.172-.21.473.473 0 00.026-.27z"
      ></path>
      <path
        fill="#20336B"
        d="M21.564 18.516l-.005-.014c-.432-1.01-1.444-1.383-2.641-.974l-3.266.904c-.19-.572-.66-1.059-1.326-1.306a.612.612 0 00-.013-.004l-3.59-1.219c-.299-.386-1.235-1.375-2.987-1.375-1.225 0-2.308.84-2.928 1.757H.468a.469.469 0 00-.468.468v5.91c0 .26.21.47.469.47h2.787c.259 0 .469-.21.469-.47v-.404h1.307c.259 0 .469-.21.469-.469v-.135c.762.067 1.012.211 1.712.615.538.31 1.352.78 2.817 1.438a4.21 4.21 0 001.529.293c.471 0 1.005-.084 1.553-.333l7.219-2.584a.478.478 0 00.038-.016c.69-.317 1.718-1.212 1.195-2.552zM2.787 22.195H.937v-4.973h1.85v4.973zm1.776-.874h-.838v-4.099h.838v4.1zm15.43-1.113l-7.215 2.584a.532.532 0 00-.04.016c-1.005.462-1.96.185-2.335.04-1.414-.637-2.17-1.072-2.721-1.39-.768-.443-1.137-.656-2.181-.742V16.94c.444-.728 1.306-1.474 2.235-1.474 1.593 0 2.268 1.036 2.295 1.078a.469.469 0 00.249.199l3.725 1.264c.595.223.91.733.765 1.241a.907.907 0 01-.434.548.908.908 0 01-.688.083l-4.2-1.331a.469.469 0 10-.283.894l4.207 1.333a1.86 1.86 0 00.522.075c.312 0 .62-.08.899-.235a1.839 1.839 0 00.908-1.224l3.52-.975c.508-.174 1.175-.237 1.473.448.307.803-.492 1.245-.7 1.344zm-3.16-14.042a.456.456 0 00-.07-.169.442.442 0 00-.13-.129.477.477 0 00-.352-.07.441.441 0 00-.168.07.45.45 0 00-.173.21.452.452 0 00-.027.271.44.44 0 00.07.169.453.453 0 00.21.172.473.473 0 00.44-.043.451.451 0 00.172-.21.441.441 0 00.028-.27z"
      ></path>
      <path
        fill="#20336B"
        d="M23.528 1.588h-2.787a.469.469 0 00-.469.469v.404h-1.307a.469.469 0 00-.469.47v.134c-.762-.067-1.012-.211-1.712-.615a28.35 28.35 0 00-2.837-1.446c-.477-.188-1.727-.557-3.061.048l-1.887.675L7.41.137a.469.469 0 00-.663 0l-5.22 5.22a.465.465 0 000 .664l8.92 8.92c.143.143.477.186.663 0l4.766-4.766c.124.011.253.017.386.017 1.225 0 2.308-.84 2.928-1.756h4.34c.258 0 .468-.21.468-.469v-5.91a.469.469 0 00-.469-.47zm-16.45-.456L8.146 2.2a2.024 2.024 0 01-1.068.302c-.384 0-.751-.105-1.069-.302l1.069-1.068zm-3.49 5.626L2.52 5.689 3.59 4.621c.196.317.301.684.301 1.068s-.105.751-.301 1.069zm7.19 7.189L9.71 12.879a2.04 2.04 0 012.136 0l-1.068 1.068zm1.744-1.744a2.98 2.98 0 00-3.488 0l-4.77-4.77a2.951 2.951 0 00.564-1.744 2.95 2.95 0 00-.564-1.744l1.07-1.07a2.951 2.951 0 001.744.564c.635 0 1.24-.197 1.744-.563L9.836 3.89A1.85 1.85 0 008.44 4.912a2.671 2.671 0 103.072 3.307l1.583.538a2.993 2.993 0 00.496 2.376l-1.07 1.07zm-2.85-4.609a.57.57 0 00.012.005l.937.318a1.737 1.737 0 01-2.92.85 1.737 1.737 0 01.566-2.832c.086.72.605 1.362 1.404 1.66zm4.596 2.863a2.052 2.052 0 01-.3-.975c.255.184.533.335.827.448l-.527.527zm4.228-2.677c-.444.729-1.306 1.474-2.235 1.474-.174 0-.337-.012-.489-.034h-.002c-1.012-.148-1.557-.723-1.736-.95l-.057-.077a.467.467 0 00-.258-.215L9.992 6.713c-.595-.223-.91-.733-.765-1.24V5.47a.907.907 0 01.434-.547.907.907 0 01.688-.083l4.2 1.331a.469.469 0 10.283-.893l-3.34-1.059-.03-.03-1.73-1.73 1.487-.532a.563.563 0 00.016-.006l.005-.002a.398.398 0 00.019-.008c1.005-.462 1.96-.185 2.335-.04 1.415.637 2.17 1.072 2.722 1.39.767.443 1.136.656 2.18.742V7.78zm1.776-.282h-.838v-4.1h.838v4.1zm2.788 0h-1.85V2.525h1.85v4.973z"
      ></path>
      <path
        fill="#4ABCEB"
        d="M12.522 12.203a2.98 2.98 0 00-3.488 0l-4.77-4.77a2.951 2.951 0 00.564-1.744 2.95 2.95 0 00-.564-1.744l1.07-1.07a2.951 2.951 0 001.744.564c.635 0 1.24-.197 1.744-.563L9.836 3.89A1.85 1.85 0 008.44 4.912a2.671 2.671 0 103.072 3.307l1.583.538a2.993 2.993 0 00.496 2.376l-1.07 1.07z"
      ></path>
    </svg>
  );
}

export default CashPayment;
