import React from "react";

function LoanStatement() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <mask
        id="mask0_3447_7603"
        style={{ maskType: "luminance" }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M24 0H0v24h24V0z"></path>
      </mask>
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#mask0_3447_7603)"
      >
        <path
          stroke="#20336B"
          d="M19.5.5h-13A2.5 2.5 0 004 3v16.636a4.73 4.73 0 01-1.891 3.782L2 23.5h13l.109-.082A4.73 4.73 0 0017 19.636V3.143a2.6 2.6 0 012.242-2.63A2.5 2.5 0 0122 3v3.5a1 1 0 01-1 1h-4"
        ></path>
        <path
          stroke="#00BAF2"
          d="M6.5 4.5H9M8.5 7.5H14M6.5 10.5H14M6.5 13.5H14M6.5 16.5H11M6.5 16.5H14M6.5 19.5H11"
        ></path>
      </g>
    </svg>
  );
}

export default LoanStatement;
