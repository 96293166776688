//2019
import Banner2019 from "../../../assets/images/uday/ourstory/2019/2019.svg";
import Duo2019 from "../../../assets/images/uday/ourstory/2019/duo.svg";
import accessbank2019 from "../../../assets/images/uday/ourstory/2019/accessbank.svg";
import montra2019 from "../../../assets/images/uday/ourstory/2019/montra.svg";
//2020
import Banner2020 from "../../../assets/images/uday/ourstory/2020/2020.svg";
import Duo2020 from "../../../assets/images/uday/ourstory/2020/duo.svg";
import masterCard2020 from "../../../assets/images/uday/ourstory/2020/mastercard.svg";
import montra2020 from "../../../assets/images/uday/ourstory/2020/montra.svg";
//2021
import Banner2021 from "../../../assets/images/uday/ourstory/2021/2021.svg";
import Duo2021 from "../../../assets/images/uday/ourstory/2021/duo.svg";
import moneylink2021 from "../../../assets/images/uday/ourstory/2021/moneylink.svg";
import montra2021 from "../../../assets/images/uday/ourstory/2021/montrapp.svg";
//2022
import Banner2022 from "../../../assets/images/uday/ourstory/2022/2022.svg";
import montra2022 from "../../../assets/images/uday/ourstory/2022/montra.svg";
import aip2022 from "../../../assets/images/uday/ourstory/2022/aip.svg";
import lms2022 from "../../../assets/images/uday/ourstory/2022/lms.svg";
import nibbs2022 from "../../../assets/images/uday/ourstory/2022/nibbs.svg";
import verified2022 from "../../../assets/images/uday/ourstory/2022/verified.svg";
import Duo2022 from "../../../assets/images/uday/ourstory/2022/duo.svg";
//2023
import Banner2023 from "../../../assets/images/uday/ourstory/2023/2023.svg";
import montra2023 from "../../../assets/images/uday/ourstory/2023/montraapp.svg";
import cards2023 from "../../../assets/images/uday/ourstory/2023/cards.svg";
import Duo2023 from "../../../assets/images/uday/ourstory/2023/duo.svg";
import moneylink2023 from "../../../assets/images/uday/ourstory/2023/moneylink.svg";
import pssp2023 from "../../../assets/images/uday/ourstory/2023/PSSP.svg";
import supplychain2023 from "../../../assets/images/uday/ourstory/2023/supplychain.svg";
import verified2023 from "../../../assets/images/uday/ourstory/2023/verified.svg";
//2024
import Banner2024 from "../../../assets/images/uday/ourstory/2024/2024.svg";
import Duo2024 from "../../../assets/images/uday/ourstory/2024/duo.svg";
import moneylink2024 from "../../../assets/images/uday/ourstory/2024/moneylink.svg";
import partnership2024 from "../../../assets/images/uday/ourstory/2024/partnership.svg";
import montra2024 from "../../../assets/images/uday/ourstory/2024/montrapp.svg";

const stories = [
  {
    id: 1,
    year: "2019",
    bannerImage: Banner2019,
    imagesGrid: [Duo2019, accessbank2019, montra2019],
    events: {
      title: "",
      subevents: [
        "Montra Technology Solutions Limited was founded in Nigeria.",
        "Dual Transaction Service (DTS) was Patented.",

        "DTS Conceptualized to offer usage of Credit Card from existing Debit Cards",

        "DTS platform was created for Credit Card Issuance Activation Transaction switching & mirroring services.",
        "Access Bank one of the leading banks in Africa signed the MOU to launch DTS.",
        "Partnered with Unified Payments for processing DTS cards.",
      ],
      secondEvent: [],
    },
  },
  {
    id: 2,
    year: "2020",
    bannerImage: Banner2020,
    imagesGrid: [Duo2020, masterCard2020, montra2020],
    events: {
      title: "",
      subevents: [
        "DTS launched.",
        "Over 300,000 DTS Credit Cards Issued.",
        "Over 75% of market share of Credit Cards issued in Nigeria.",
        "Partnership with Mastercard for issuance of DTS credit cards.",
        "Access Bank, one of the leading banks in Africa signed the MOU to launch DTS.",
        "Partnered with Unified Payments for processing DTS cards.",
      ],
      secondEvent: [],
    },
  },
  {
    id: 3,
    year: "2021",
    bannerImage: Banner2021,
    imagesGrid: [Duo2021, moneylink2021, montra2021],
    events: {
      title:
        "Achieved milestones on the development of DUO Solution including:",
      subevents: [
        "DUO App & DUO Web Portal",
        "Over 500000 DTS Credit Cards Issued.",
        "Over 90% of market share of Credit Cards issued in Nigeria.",
      ],
      secondEvent: [
        "Achieved milestones on the development of Montra App & Platform",
        "Cloud based Loan Management System (LMS) was developed for Digital Lending.",
      ],
    },
  },
  {
    id: 4,
    year: "2022",
    bannerImage: Banner2022,
    imagesGrid: [
      aip2022,
      Duo2022,
      nibbs2022,
      lms2022,
      verified2022,
      montra2022,
    ],
    events: {
      firstEvent: [
        "Developed Verifyed Authentication App.",
        "AIP (Approval in Principle) as PSSP & Super-agent received from CBN (Central Bank of Nigeria).",
        "Obtained PCI DSS certification for global data security standard for accepting card payments.",
        "Partnership with NIBSS (Nigeria Inter-Bank Settlement System) for Payment processing.",
      ],
      Secondtitle: "Montra App & Platform development milestones achieved:",
      Secondsubevents: [
        "Payment Gateway Application.",
        "Integration with Mastercard Visa & NIBSS.",
      ],
      Thirdtitle: "Achieved more milestones for the development DUO Solution",
      Thirdsubevents: ["DUO Collections application."],
      secondEvent: [
        "Over 800000 DTS Credit Cards Issued.",
        "Over 95% of market share of Credit Cards issued in Nigeria.",
        "Cloud based Loan Management System (LMS) was developed for Digital Lending.",
      ],
    },
  },
  {
    id: 5,
    year: "2023",
    bannerImage: Banner2023,
    imagesGrid: [
      pssp2023,
      Duo2023,
      cards2023,
      verified2023,
      moneylink2023,
      supplychain2023,
      montra2023,
    ],
    events: {
      title: "Introduction of the following to the Verifyed App",
      Firstsubevents: [
        "Liveness Verification capability.",
        "Face match capability.",
        "KYC verifications.",
      ],
      // firstEvent: [
      //   "Completed Development of Montra Supply Chain Solution.",
      //   "Obtained PSSP & Super-agent approval from CBN (Central Bank of Nigeria).",
      // ],
      Secondtitle: "DUO Solution development milestones achieved",
      Secondsubevents: [
        "DUO customer onboarding & Dynamic Limit Management Modules launched.",
      ],
      Thirdtitle: "Completed Development of:",
      Thirdsubevents: [
        "Account Management System.",
        "Card Issuing Management & Processing Platform.",
        "POS Acquiring platform.",
      ],
      Fourthtitle: "Montra App & Platform development milestones achieved",
      Fourthsubevents: [
        "Integration with Interswitch.",
        "Audio Notification Delivery.",
        "Payment Link.",
        "Bill Payments Airtime Data Cable TV recharge.",
        "P2P P2B & B2B Payments.",
        "Montra Accounts.",
        "Montra Store & Shopping features.",
        "Tap & Pay capability.",
        "KYC module.",
      ],
      secondEvent: [
        "Completed Development of Montra Supply Chain Solution.",
        "Obtained PSSP & Super-agent approval from CBN (Central Bank of Nigeria).",
        "Over 1000000 DTS Credit Cards Issued.",
        "Over 97% of market share of Credit Cards issued in Nigeria.",
        "Partnership with Mastercard Visa & Interswitch.",
      ],
    },
  },
  {
    id: 6,
    year: "2024",
    bannerImage: Banner2024,
    imagesGrid: [Duo2024, moneylink2024, partnership2024, montra2024],
    events: {
      title: "Montra App & Platform development milestones achieved",
      subevents: ["Chat & Social Feeds.", "Chat Pay capability."],
      secondEvent: [
        "Over 2000000 DTS Credit Cards Issued.",
        "Over 98% of market share of Credit Cards issued in Nigeria.",
        "Partnership with Prophius ETranzact.",
        "Completed development of Debt collection System for Moneylink.",
      ],
    },
  },
];

export default stories;
