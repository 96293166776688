import React from 'react'
import Navbar from "../Navbar/Main";
import Footer from '../Footer/Main';
import "./style.css";
import arrow1 from "../../assets/images/arrow1.svg";


const Sitemap = () => {

  return (
    <>
      <div className="sitemap-page">
        <Navbar />
        <div className="sitemap-wrapper">
          <h1 className="sitemap-title">Sitemap</h1>

          <div className='sitemappage2'>
            <div className="sitemap-grid">
              {/* Section 1: Home */}
              <div className="sitemap-section sitemap1">
                <h4 className='sitemapheader'>Products</h4>
              </div>

              {/* Section 2: Products */}
              <div className="sitemap-section sitemap2">
                <h4 className='sitemapheader'>Personal</h4>
                <ul>
                  <li><a href="/Products/overview"> Overview</a></li>
                  <li><a href="/Products/Banking#Banking">Banking</a></li>
                  <li><a href="/products/Payment">Payments</a></li>
                  <li><a href="/products/Finance">Finance</a></li>
                  <li><a href="/products/Chat">Chat</a></li>
                  <li><a href="/products/Shop">Shop</a></li>
                </ul>
              </div>

              {/* Section 3: Services */}
              <div className="sitemap-section sitemap3">
                <h4 className='sitemapheader'>Business</h4>
                <ul>
                  <li><a href="/Business/overview"> Overview</a></li>
                  <li><a href="/Business/Banking">Banking</a></li>
                  <li><a href="/Business/Payment">Payments</a></li>
                  <li><a href="/Business/AgencyBanking">Agency Banking</a></li>
                  <li><a href="/Business/more/TapPay">Tap & Pay</a></li>
                  <li><a href="/Business/more/Inventory">Inventory Management</a></li>
                  <li><a href="/Business/more/Store">Montra Store</a></li>
                  <li><a href="/Business/more/Gateway">Payment Gateway</a></li>
                </ul>
              </div>

              <div className="sitemap-section sitemap4">
              </div>
            </div>
            <div className="sitemap-grid">
              {/* Section 1: Home */}
              <div className="sitemap-section sitemap1">
                <h4 className='sitemapheader'>Solutions</h4>
              </div>

              {/* Section 2: Products */}
              <div className="sitemap-section sitemap2">
                <h4 className='sitemapheader'>DUO Card</h4>
                <ul>
                  <li><a href="/Solutions/Duo/Dts#DTS"> Dual Transaction Service - DTS</a></li>
                  <li><a href="/Solutions/Duo/Dcp#DCP">Duo Card Product - DCP</a></li>
                  <li><a href="/Solutions/Duo/Duoapp#DuoApp">DUO App</a></li>
                  <li><a href="/Solutions/Duo/Dwp#DuoWebPortal">DUO Web Portal</a></li>
                  <li><a href="/Solutions/Duo/Customeronboarding#DuoCustomerOnboarding">DUO Customer Onboarding</a></li>
                  <li><a href="/Solutions/Duo/Dlm#LimitManagement">Limit Management</a></li>
                  <li><a href="/Solutions/Duo/Collection#Collections">Collections</a></li>
                </ul>
              </div>
              {/* Section 3: Services */}
              <div className="sitemap-section sitemap3">
                <h4 className='sitemapheader'>Verifyed</h4>
                <ul>
                  <li><a href="/Solution/Verified/Authapp#AuthenticatorApp"> Authenticator App</a></li>
                  <li><a href="/Solution/Verified/Faceauth#FaceAuthentication">Face Authentication</a></li>
                  <li><a href="/Solution/Verified/Faceauth#FaceAuthentication">KYC ID Verification</a></li>
                  <li><a href="/Solution/Verified/Faceauth#FaceAuthentication">Video KYC Solution</a></li>

                </ul>
              </div>

              <div className="sitemap-section sitemap4">
                <h4 className='sitemapheader'>Montra Supply Chain</h4>
                <ul>
                  <li><a href="/Solutions/Supplychain/payment#supplypayments"> Payments</a></li>
                  <li><a href="/Solutions/Supplychain/collection#supplycollections">Collections</a></li>
                  <li><a href="/Solutions/Supplychain/Sales#salespromotions">Sales Promotions</a></li>
                  <li><a href="/Solutions/Supplychain/Orderfulfilment#OrderFulfilment">Order Fulfilment</a></li>
                  <li><a href="/Solutions/Supplychain/Distributionanalytics#Distributionanalytics">Distribution Analytics</a></li>

                </ul>
              </div>
            </div>
            <div className="sitemap-grid">
              {/* Section 1: Home */}
              <div className="sitemap-section sitemap1">
                <h4 className='sitemapheader'>Platform</h4>
              </div>
              {/* Section 2: Products */}
              <div className="sitemap-section sitemap2">
                <h4 className='sitemapheader'>MoneyLink</h4>
                <ul>
                  <li><a href="/Platform/Moneylink/cip#CardIssuing">Card Issuing</a></li>
                  <li><a href="/Platform/Moneylink/merchant#merchant">Merchant Services</a></li>
                  <li><a href="/Platform/Moneylink/Accountmanagement#AccountManagement">Account Management</a></li>
                  <li><a href="/Platform/Moneylink/Loanmanagement#LoanManagementSystem">Loan Management System</a></li>
                  <li><a href="/Platform/Moneylink/Debtcollections#collection">Debt Collections</a></li>

                </ul>
              </div>
              <div className="sitemap-section sitemap3">
              </div>
              <div className="sitemap-section sitemap4">
              </div>
            </div>

            <div className="sitemap-grid">
              {/* Section 1: Home */}
              <div className="sitemap-section sitemap1">
                <h4 className='sitemapheader'>Company</h4>
              </div>
              {/* Section 2: Products */}
              <div className="sitemap-section sitemap2">
                <ul>
                  <li><a href="/Company/Overview">About Montra</a></li>
                  <li><a href="/Company/Ourstory">Our Story</a></li>
                </ul>
              </div>
              <div className="sitemap-section sitemap3">
              </div>
              <div className="sitemap-section sitemap4">
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />


    </>
  )
}

export default Sitemap