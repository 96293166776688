import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Sidebarview from "./Sidebarview";
import Sidebar from "./Sidebar";
import LoanRequestView1 from "./LoanRequestView1";
import LoanRequestView2 from "./LoanRequestView2";
import LoanRequestView3 from "./LoanRequestView3";
import Header2 from "./Header2";
import FooterAdmin from "../Footer/FooterAdmin";
import CommonHeader from "../header/CommonHeader";
import withRouter from "./withrouter";

class ViewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: true,
      data: {
        age: "",
        ageEligibility: "",
        amount: "",
        amountEligibility: "",
        amountRequestedELigibility: "",
        approvedInterestRate: "",
        approverDecision: "",
        avgSalary: "",
        avgSalaryInterestMult: "",
        avgSalaryTenorMult: "",
        bankAcctNumber: "",
        bankCustomerId: "",
        bankName: "",
        baseInterestRate: "",
        baseTenor: "",
        bureauReportUrl1: "",
        bureauReportUrl2: "",
        bureauReportUrl3: "",
        bureauScoreIntMult: "",
        bvn: "",
        compCat: "",
        companyCatIntMult: "",
        creditBureauDataDto: [
          {
            creditBureauName: "",
            totalLoanOutstanding: "",
            existingDebitBurden: "",
            dueAmtInXdays: "",
            dueAmtIn30DPD: "",
            dueAmtIn60DPD: "",
            dueAmtInGT90DPD: "",
            bureauScore: "",
          },
        ],
        aggrPositionDto: {},
        customerName: "",
        customerType: "",
        debitBurdenAllowedAmt: "",
        debtBurdenAllowPercent: "",
        deleiquencyStatusIntMut: "",
        emi: "",
        empOrBusinesssName: "",
        finalTenor: "",
        lastSalary: "",
        loanId: "",
        loanProdName: "",
        loanReqDate: "",
        loanStatus: "",
        maxAge: "",
        maxLoanAmtCap: "",
        minAge: "",
        minLoanAmt: "",
        mobile: "",
        numberOfYrsEmpIntMult: "",
        numofYrsWithCurrEmplyIntMult: "",
        payLinkDecision: "",
        payLinkDecisionDate: "",
        payLinkId: "",
        totalWorkExp: "",
        totalWorkExpWithEmployer: "",
      },
      fields: [],
      mounted: false,
      message: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangepin = this.handleChangepin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangepin(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  handleChange(e) {
    let status = this.state.status;
    status = !status;
    this.setState({ status });
    console.log("toggled button:", status);
  }

  handleSubmit(e) {
    e.preventDefault();
    let loanid = this.props.location?.state?.loanId;
    let loanapproval = "";
    if (this.state.status) {
      loanapproval = "rejected";
    } else {
      loanapproval = "approved";
    }

    let url = `https://api.montra.org/moneylinkweb/api/credituw/loanstatusupdate?loanid=${loanid}&email=${localStorage.getItem(
      "email"
    )}&status=${loanapproval}&pin=${this.state.fields.pin}`;

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return "server error";
        }
      })
      .then((contents) => {
        this.setState({ message: contents.message });
      })
      .catch(() =>
        console.log("Can’t access " + url + " response. Blocked by browser?")
      );
  }

  componentDidMount() {
    this.mounted = true;
    if (
      this.props.location?.state?.loantype &&
      this.props.location?.state?.loanId
    ) {
      localStorage.setItem("loanid", this.props.location.state.loanId);
      console.log("loan id: " + localStorage.getItem("loanid"));
    }

    let url = new URL(
      `https://api.montra.org/moneylinkweb/api/credituw/loanapplication?loanid=${localStorage.getItem(
        "loanid"
      )}&email=${localStorage.getItem("email")}`
    );

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          alert("server error");
        }
      })
      .then((contents) => {
        if (this.mounted) {
          this.setState({ data: contents.data });
        }
      });
  }

  render() {
    const { location } = this.props;
    const { state } = location || {};
    const { data, status, message } = this.state;

    if (state?.loantype && state?.loanId) {
      localStorage.setItem("path", state.loantype);
      localStorage.setItem("loanid", state.loanId);
    }

    let path = localStorage.getItem("path");
    console.log("path:", path);

    console.log("Status:", this.state.status);

    if (state && data.creditBureauDataDto?.length > 0) {
      return (
        <div>
          <CommonHeader />
          <div id="wrapper">
            <Sidebarview path={path} />

            <div id="maincontent" className="content">
              <section id="content-wrapperss">
                <div className="backloan">
                  <div className="container-fluid cuwritting">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          View Details
                        </li>
                      </ol>
                    </nav>
                    <div className="row">
                      <div className="col-md-12 viewdiv">
                        <LoanRequestView1 data={data} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 viewdiv">
                        <LoanRequestView2 loanid={state.loanId} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 viewdiv">
                        {(() => {
                          switch (path) {
                            case "LoanApproved":
                              this.state.status = true;
                            default:
                              return <p></p>;
                          }
                        })()}
                        <LoanRequestView3
                          statusapproval={state.statusapproval}
                          status={status}
                          statusdisable={state.statusdisable}
                          loanid={state.loanId}
                          path={path}
                          onsubmit={this.handleSubmit}
                          onchange={this.handleChange}
                          message={message}
                          handleChangepin={this.handleChangepin}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* <FooterAdmin /> */}
        </div>
      );
    }
    //else {
    //   return <Navigate to="/CreditUW" />;
    // }
  }
}

export default withRouter(ViewDetails);
