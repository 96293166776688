import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import Shop1 from "../../../assets/images/uday/personal-icons/shop/sho1.svg";
import Shop2 from "../../../assets/images/uday/personal-icons/shop/sho2.svg";
import Shop3 from "../../../assets/images/uday/personal-icons/shop/sho3.svg";
import Shop4 from "../../../assets/images/uday/personal-icons/shop/sho4.svg";
import shophero2 from "../../../assets/images/shophero2.svg";

const steps = [
  {
    title: "Product & Services Categories",
    image: shophero2,
    className: "hero2-img",
  },
  { image: Shop1, description: "Delivery/ Takeout/ Rentals" },
  { image: Shop2, description: "Dine-in" },
  { image: Shop3, description: "Home Services" },
  { image: Shop4, description: "Appointments" },
];

const ShopProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="personal-shop-topdescription  "
      />
    </div>
  );
};

export default ShopProcessContainer;
