import React from 'react'
import Hero from './Hero'
import Navbar from '../../../Navbar/Solution'
import Footer from '../../../Footer/Productfooter'
import './style.css';
import Body from './Body';

const Main = () => {
  return (
    <>
      <div id="Implementation">
        <Navbar />
        <Hero />
        <Body />
        <Footer />
      </div>
    </>
  )
}

export default Main;