import React from 'react';
import ReactDOM from 'react-dom/client'; // Correct import for React 18
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import axios from 'axios';


axios.defaults.baseURL = 'https://api-qa.montra.org/moneylinkweb/';
// axios.defaults.baseURL = 'https://duowebapi.duocard.org:443/duowebportal/';

const root = ReactDOM.createRoot(document.getElementById('root')); // Create a root

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
