import React from "react";
// import faceauth5 from "../../../../assets/images/faceauth5.png";
import faceauth5 from "../../../../assets/images/uday/verified/verified-face-subsection.webp";
import verifiedicon1 from "../../../../assets/images/verifiedicon1.svg";
import verifiedicon2 from "../../../../assets/images/verifiedicon2.svg";
import verifiedicon3 from "../../../../assets/images/verifiedicon3.svg";
import verifiedicon4 from "../../../../assets/images/verifiedicon4.svg";

const Body = () => {
  return (
    <>
      <div className="face-auth-body">
        <h1 className="face-auth-body-h1">Fast & Safe onboarding in seconds</h1>

        <div className="row">
          <div className="col">
            <div className="veri-part">
              <div className="col ">
                <div className="verified-part">
                  <div className="verified-part-text verified-part-text-text faceauth">
                    <div>
                      <img src={verifiedicon1} alt="Liveness Verification" />
                    </div>

                    <div>
                      <p className="list-heading">Liveness Verification</p>
                      <p><span>
                        Confirm Liveness before you save profile picture of your
                        users.
                      </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="verified-part">
                  <div className="verified-part-text verified-part-text-text">
                    <div>
                      <img src={verifiedicon2} alt="Face match" />
                    </div>

                    <div>
                      <p className="list-heading">Face match</p>
                      <p> <span>
                        Onboard use cases for identifying the user for continued
                        access.
                      </span> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="veri-part">
              <div className="col ">
                <div className="verified-part">
                  <div className="verified-part-text verified-part-text-text">
                    <div>
                      <img src={verifiedicon3} alt="OCR" />
                    </div>

                    <div>
                      <p className="list-heading">OCR</p>
                      <p> <span>
                        Extract text from the submitted ID for verification.
                      </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="verified-part">
                  <div className="verified-part-text verified-part-text-text">
                    <div>
                      <img src={verifiedicon4} alt="ID verification" />
                    </div>

                    <div>
                      <p className="list-heading">ID verification</p>
                      <p><span>
                        Match Liveness verified users with their photo ID for
                        KYC & ID verification.
                      </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <img src={faceauth5} alt="Fast & Safe onboarding" className="faceauth-image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
