import React from "react";

function LinkedBanks() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path fill="#fff" d="M24.5 0v24H.5V0h24z"></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.355 22.5L2 20.64l.464-.886c.036-.068.136-.113.248-.113h9.575l1.213.003"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 9.084h6v10.56H4V9.084z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16 22.523H1M7.004 9.084v10.56M10.004 9.084v10.56M15.004 9.084v5M18.004 9.084v3.417M20.5 9.709v3.5"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.871 6.577a.472.472 0 00-.226-.568L12.75.576a.518.518 0 00-.5 0l-9.9 5.429a.472.472 0 00-.226.568l.764 2.182a.499.499 0 00.474.329h18.28a.499.499 0 00.474-.329l.756-2.178z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.831 6.203H2.161"
      ></path>
      <path
        stroke="#00BAF2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.463 17.823l.024.023a.525.525 0 00.743 0l1.04-1.041a.79.79 0 000-1.114l-.461-.461a.79.79 0 00-1.114 0l-1.041 1.04a.525.525 0 000 .743l.023.023.786.787zM17.537 18.177l-.023-.023a.526.526 0 00-.743 0l-1.041 1.041a.79.79 0 000 1.114l.461.461a.79.79 0 001.114 0l1.041-1.04a.524.524 0 000-.743l-.023-.023-.786-.787z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#00BAF2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.5 19l2-2"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 18a5 5 0 1110 0 5 5 0 01-10 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LinkedBanks;
