/* React packages and components */
import React from "react";
import Tooltip from "@mui/material/Tooltip";
import NumberFormat from "react-number-format";
import ForgotPin from "../../ForgotPin/ForgotPin";
import axios from "axios";
import Info from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";

/**
 * Renders lower section of PRODUCT-MIX in maker section
 * <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>


 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
 * <li> <b>d-flex  :- </b> it is use for manage the layout, alignment, and sizing of grid columns, navigation, components, and more with a full suite of responsive flexbox utilities.</li>
 * <li> <b>Tooltip :-</b> This tooltip is a small pop up that appears when user places the mouse pointer over an element such as link or buttons to provide hint or information about the element being hovered.</li>
 *  @Author : Seema Kumari
 *  @version :1.0
 * */

class CollectionLoan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      isActive: false,
      isDisabled: false,
      status: "",
      message: "",
      backerrors: [],
      errorMsg: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  /**
   * Captures the pin entered by maker in product-mix section.
   *
   * @param {Event} e Event which calls this method
   */
  handleChange(e) {
    isDisabledSubmitButton = false;
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  compareObjects(obj1, obj2) {
    let changes = [];
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        changes.push({
          key: key,
          originalValue: obj1[key],
          modifiedValue: obj2[key],
        });
      }
    }
    return changes;
  }

  /**
   * Submits product-mix data to server.
   *
   * @param {Event} e Event which calls this method
   */
  handleSubmit = (e) => {
    isDisabledSubmitButton = true;
    e.preventDefault();
    this.setState({
      message: "",
      status: "",
      backerrors: [],
      errorMsg: "",
    });
    this.submitChange();
    this.removepercentile();

    let data = [];
    const dataarray = this.props.loaddata || [];
    const oldDataArray = this.props.oldData || [];

    dataarray.forEach((input) => {
      const oldinputData = oldDataArray.find(
        (obj) => obj.sequenceId === input.sequenceId
      );
      const changes = this.compareObjects(oldinputData, input);
      console.log("changes", changes);
      console.log("newdata", input);
      console.log("olddata", oldinputData);
      console.log("iseditable", input?.isEditable);
      if (changes.length > 0 && input?.isEditable) {
        console.log("OldData inside ", oldinputData);
        console.log("NewData inside ", input);
        data.push(input); // Only push changed data
      }
    });
    console.log("dataarray:", dataarray);
    console.log("oldDataArray:", oldDataArray);
    console.log("changedataarray:", data);

    const url = `api/product/saveprodmix?email=${localStorage.getItem(
      "email"
    )}&pin=${this.state.fields.pin}`;
    const requestData = this.props.loaddata;
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      })
      .then((response) => {
        const contents = response.data;
        if (response.status === 200 && contents.message === "SUCCESS") {
          isDisabledSubmitButton = true;
          this.setState({
            isDisabled: true,
            status: contents.status,
            message: contents.message,
          });
        } else {
          isDisabledSubmitButton = true;
          this.setState(
            {
              backerrors: contents.errors || [],
              status: contents.status,
              message: contents.message,
              errorMsg: "",
            },
            () => {
              this.state.backerrors.forEach((error) => {
                this.setState((prevState) => ({
                  errorMsg: `${prevState.errorMsg} ${error.message}`,
                }));
              });
            }
          );
        }
        this.props.handlestatus(this.state.status);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        this.setState({
          errorMsg: "Server Error: Please Try After Sometime",
        });
      });
  };

  removepercentile() {
    this.props.loaddata.map((productmix, i) => {
      productmix.feeline1 = parseFloat(productmix.feeline1);
      productmix.feeline2 = parseFloat(productmix.feeline2);
      productmix.lossPoolContrPercent = parseFloat(
        productmix.lossPoolContrPercent
      );
      productmix.penalChanrgesPercent = parseFloat(
        productmix.penalChanrgesPercent
      );
      productmix.baseInterestRate = parseFloat(productmix.baseInterestRate);
      productmix.baseTenors = parseFloat(productmix.baseTenors);
    });
  }
  /**
   * Changes boolean values to ON and OFF.
   */
  submitChange() {
    this.props.loaddata.map((productmix, i) => {
      if (true == productmix.activeProds) {
        productmix.activeProds = "ON";
      } else {
        productmix.activeProds = "OFF";
      }
    });
  }
  myFormat(num) {
    return num + "%";
  }
  render() {
    console.log(this.props.data);
    return (
      <div className="productmixcollection">
        <form method="post" name="userRegistrationForm" className="formroot">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-xl-4 reduce">
              <div className="d-flex flex-row mt-3 ">
                <div className="p-2 productformcol">
                  <div className="form-group">
                    <label className="productcodeloanmak">Product</label>
                    {this.props.loaddata.map((productmix, i) => (
                      <div key={i}>
                        <label
                          className="form-control loanlabelmak"
                          name="prodName"
                          id="field1"
                          disabled
                        >
                          {" "}
                          {productmix.prodName}{" "}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="p-2 productinputcollection">
                  <div className="toolinfopro">
                    <p className="productheader procoll ttip">
                      Collection for internal loans
                    </p>

                    <Tooltip
                      title="Origination OR Origination + Anniversary"
                      placement="top"
                    >
                      <IconButton>
                        <Info fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="form-group">
                    {this.props.loaddata.map((productmix, i) => (
                      <div key={i}>
                        <div className="input-group aligncenter inputblue margn">
                          <select
                            className="form-control formselect"
                            data-tip
                            data-tip-disable={!this.props.editable}
                            data-for="noneditable"
                            name="collectionForInternLoans"
                            placeholder={productmix.collectionForInternLoans}
                            onChange={(e) => {
                              this.props.handleChangecollectionForInternLoans(
                                e,
                                i
                              );
                            }}
                            disabled={
                              this.props.productdisable[i] ||
                              !productmix?.isEditable
                            }
                          >
                            <option value={productmix.collectionForInternLoans}>
                              {productmix.collectionForInternLoans}
                            </option>
                            <option value="NO">NO</option>
                            <option value="YES">YES</option>
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="p-2 productinputcollection">
                  <div className="toolinfopro">
                    <p className="productheadermak mak4 ttip">
                      Base Interest Rate
                    </p>

                    <Tooltip
                      title="Loans being managed on Bank / FC’s LMS"
                      placement="top"
                    >
                      <IconButton>
                        <Info fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="form-group">
                    {this.props.loaddata.map((productmix, i) => (
                      <div key={i}>
                        <div className="inner-addon right-addon">
                          <NumberFormat
                            className="form-control formproduct margn"
                            data-tip
                            data-tip-disable={!this.props.editable}
                            data-for="noneditable"
                            name="baseInterestRate"
                            // value={Number}
                            value={productmix.baseInterestRate}
                            onChange={(e) => {
                              this.props.handleChangebaseInterestRate(e, i);
                            }}
                            suffix={"%"}
                            disabled={
                              this.props.productdisable[i] ||
                              !productmix?.isEditable
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="p-2 productinputcollection">
                  <div className="toolinfopro">
                    <p className="basetenor ttip">Base Tenors</p>

                    <Tooltip title="Annualized Rate" placement="top">
                      <IconButton>
                        <Info fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="form-group">
                    {this.props.loaddata.map((productmix, i) => (
                      <div key={i}>
                        <div className="inner-addon right-addon">
                          <NumberFormat
                            className="form-control formproduct margn"
                            data-tip
                            data-tip-disable={!this.props.editable}
                            data-for="noneditable"
                            name="baseTenors"
                            // value={Number}
                            value={productmix.baseTenors}
                            onChange={(e) => {
                              this.props.handleChangebaseTenors(e, i);
                            }}
                            suffix={"%"}
                            disabled={
                              this.props.productdisable[i] ||
                              !productmix?.isEditable
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-xl-4 reduce">
              <div className="d-flex flex-row mt-3 ">
                <div className="p-2  collectionpt">
                  <div className="collectioncard">
                    {/* <form method="post" name="userRegistrationForm"> */}

                    <div className="form-group ">
                      <label htmlFor="first">Type Your User PIN </label>

                      <input
                        type="password"
                        className="form-control cardinputfield"
                        name="pin"
                        value={this.state.fields.message}
                        onChange={this.handleChange}
                        placeholder="xxxxx "
                      />
                      <div className="sign">
                        <input
                          type="submit"
                          className="btn btn-warning cardbutton"
                          style={{ backgroundColor: this.props.buttoncolor }}
                          onClick={this.handleSubmit}
                          value="Submit changes for approval"
                          disabled={isDisabledSubmitButton}
                        />
                      </div>
                    </div>
                    <ForgotPin />
                  </div>
                  {(() => {
                    switch (this.props.fieldsvalid) {
                      case true:
                        return (
                          <p className="errorMsgpin">
                            All Fields are Mandatory
                          </p>
                        );
                      default:
                        return <div></div>;
                    }
                  })()}
                  {(() => {
                    switch (this.state.message) {
                      case "SUCCESS":
                        return (
                          <p className="submittedpin">Submitted Succesfully</p>
                        );
                      default:
                        return <div></div>;
                    }
                  })()}
                  {(() => {
                    switch (this.state.message) {
                      case "UNSUCCESS":
                        return (
                          <div className="errorMsgpin">
                            {this.state.errorMsg}
                          </div>
                        );
                      default:
                        return <div></div>;
                    }
                  })()}
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-3 col-xl-3 reduce"></div>
          </div>
        </form>
      </div>
    );
  }
}

let isDisabledSubmitButton = false;
export default CollectionLoan;
