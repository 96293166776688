import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import CashDeposit from "../../../assets/images/uday/Business-icons/AgencyBanking/cashDeposit.svg";
import CashWithdrawal from "../../../assets/images/uday/Business-icons/AgencyBanking/cashWithdrawal.svg";
import BillPayments from "../../../assets/images/uday/Business-icons/AgencyBanking/bill-payment.svg";
import BankTransfer from "../../../assets/images/uday/Business-icons/payments/bankTransfer.svg";
import agencyhero1 from "../../../assets/images/agencyhero1.svg";

const steps = [
  {
    title: "Agency banking Services",
    image: agencyhero1,
    className: "agencyhero1",
  },
  { image: CashDeposit, description: "Cash Deposit" },
  { image: CashWithdrawal, description: "Cash Withdrawal" },
  { image: BillPayments, description: "Recharge & Bills Payment" },
  { image: BankTransfer, description: "Bank Transfers" },
];

const AgencyProcessContainer = () => {
  return (
    <div className="agency-process-container">
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="business-overview-topdescription"
      />
    </div>
  );
};

export default AgencyProcessContainer;
