import React, { useEffect, useState } from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import Loans from "../../../assets/images/uday/personal-icons/finance/loans.svg";
import Insurance from "../../../assets/images/uday/personal-icons/finance/insurance.svg";
import Investments from "../../../assets/images/uday/personal-icons/finance/investments.svg";
import financehero1 from "../../../assets/images/financehero1.svg";

const steps = [
  {
    title: "Financial Products",
    image: financehero1,
    className: "finance-hero-img",
  },
  { image: Loans, description: "Loans" },
  { image: Insurance, description: "Insurance" },
  { image: Investments, description: "Investments" },
];

const FinanceProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="personal-finance-topdescription"
      />
    </div>
  );
};

export default FinanceProcessContainer;
