import React from "react";
import Navbar from "../../Navbar/Productnav";
import Productfooter from "../../Footer/Productfooter";
import Hero from "../Home/Hero";
import Personal from "../../Home/Personal/Main";
import arrow1 from "../../../assets/images/arrow1.svg";
import arrow2 from "../../../assets/images/arrow2.png";

const Main = () => {
  return (
    <>
      <div id="Overview">
        <Navbar />
        <Hero />
        <div className="personal-page">
          <div className="arrow">
            <img src={arrow1} alt="arrow" />
          </div>
          <Personal />
        </div>

        <Productfooter />
      </div>
    </>
  );
};

export default Main;
