import React from "react";
import Overview from "../Overview/Body";

const Body = () => {
  return (
    <>
      <Overview />
    </>
  );
};

export default Body;
