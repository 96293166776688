import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import BuyInventory from "../../../assets/images/uday/Business-icons/Inventory/inventory.svg";
import SellInventory from "../../../assets/images/uday/Business-icons/Inventory/sell-inventory.svg";
import ManageInventory from "../../../assets/images/uday/Business-icons/Inventory/ManageInventory.svg";
import PlaceOrders from "../../../assets/images/uday/Business-icons/Inventory/placeOrder.svg";
import inventory1 from "../../../assets/images/inventory1.svg";

const steps = [
  { title: "Inventory Management", image: inventory1, className: "inventory1" },
  { image: BuyInventory, description: "Buy Inventory" },
  { image: SellInventory, description: "Sell Inventory" },
  { image: ManageInventory, description: "Manage Inventory" },
  { image: PlaceOrders, description: "Place Orders" },
];

const InventoryProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="business-overview-topdescription"
      />
    </div>
  );
};

export default InventoryProcessContainer;
