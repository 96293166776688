import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar/Main";
import { NavLink, useLocation } from "react-router-dom";
import icon2 from "../../assets/images/responsive2.svg";
import "./product.css";

const Productnav = () => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(true);
  const [isMoreActive, setMoreActive] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [disableLink, setDisableLink] = useState(false);
  const [showMerchantLink, setShowMerchantLink] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const timeoutIdRef = useRef(null);
  const location = useLocation();

  const handleButtonClick = () => {
    setSubMenuVisible(isSubMenuVisible);
    setMoreActive(!isSubMenuVisible);
  };

  const handleSubMenuClick = (e) => {
    e.stopPropagation();
    setMoreActive(true);
    setSubMenuVisible(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth > 920) {
        setSubMenuVisible(false);
        setMoreActive(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseLeave = () => {
    timeoutIdRef.current = setTimeout(() => {
      setSubMenuVisible(false);
      // setMoreActive(false);
    }, 1000);
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutIdRef.current);
    setSubMenuVisible(true);
  };

  const handleSubLinkActivation = (link) => {
    setMoreActive(true);
    setActiveLink(link);
    setDisableLink(true);
  };

  const handleLinkClick = () => {
    setMoreActive(false);
  };

  useEffect(() => {
    setSubMenuVisible(window.innerWidth <= 768);
    console.log("activeLinkActivation", location);

    if (location?.pathname == "/Business/more/Store") {
      setShowMerchantLink(true);
    }
  }, []);

  useEffect(() => {
    const subLinks = [
      "/Business/more/Inventory",
      "/Business/more/Store",
      "/Business/more/Gateway",
      "/Business/more/TapPay",
    ];
    if (subLinks.includes(location.pathname)) {
      setMoreActive(true);
      setDisableLink(true);
    } else {
      setMoreActive(false);
      setDisableLink(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div className="product-nav">
        <Navbar />
      </div>
      {screenWidth > 920 ? (
        <div className="second-navbar navbar ">
          <div className="swtich-btn">
            <div className="">
              <NavLink to="/Products/overview" className="nav-link">
                <button className="nonactive-swtich-btn">Personal</button>
              </NavLink>
            </div>
            <div className="">
              <button className="active-swtich-btn">Business</button>
            </div>
          </div>

          <div className="nav-link">
            <ul className="navbar-nav ml-auto">
              {showMerchantLink && (
                <li className="nav-item">
                  <NavLink
                    to="https://order.montra.org/user/login"
                    // className={`nav-link ${isMoreActive ? "active" : ""}`}
                    // target="_blank"
                    // activeClassName="active"
                    // onClick={() => setMoreActive(true)}
                    target="_blank"
                    className="nav-link business"
                    activeClassName="active"
                  >
                    Manage your store
                  </NavLink>
                </li>
              )}
              <li className="nav-item">
                <NavLink
                  to="/Business/overview"
                  className="nav-link business"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  Overview
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Business/Banking"
                  className="nav-link business"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  Banking
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Business/Payment"
                  className="nav-link business"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  Payments
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Business/AgencyBanking"
                  className="nav-link business"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  Agency Banking
                </NavLink>
              </li>

              <li className="nav-item">
                <button
                  onClick={(e) => {
                    if (disableLink) {
                      e.preventDefault();
                    } else {
                      handleButtonClick();
                    }
                  }}
                  aria-current="false"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={`nav-link more ${isMoreActive ? "active" : " "} `}
                  activeClassName="active"
                >
                  More
                </button>

                {isSubMenuVisible && (
                  <div
                    className="submenu"
                    style={{ position: "absolute" }}
                    onClick={handleSubMenuClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <NavLink
                      to="/Business/more/Inventory"
                      className="nav-link business"
                      activeClassName="active"
                      onClick={() =>
                        handleSubLinkActivation("/Business/more/Inventory")
                      }
                    >
                      Inventory Management
                    </NavLink>
                    <NavLink
                      to="/Business/more/Store"
                      className="nav-link business"
                      activeClassName="active"
                      onClick={() =>
                        handleSubLinkActivation("/Business/more/Store")
                      }
                    >
                      Montra Store
                    </NavLink>
                    <NavLink
                      to="/Business/more/Gateway"
                      className="nav-link business"
                      activeClassName="active"
                      onClick={() =>
                        handleSubLinkActivation("/Business/more/Gateway")
                      }
                    >
                      Payment Gateway
                    </NavLink>
                    <NavLink
                      to="/Business/more/TapPay"
                      className="nav-link business"
                      activeClassName="active"
                      onClick={() =>
                        handleSubLinkActivation("/Business/more/TapPay")
                      }
                    >
                      Tap & Pay
                    </NavLink>
                  </div>
                )}
              </li>

              {/* <li className="nav-item">
                <button className="product-btn produvt-sign">
                  Sign In <i className="bi bi-chevron-right"></i>
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      ) : (
        <div className="horizontal-navbar business-horizontal-navbar">
          <div className="second-navbar navbar ">
            <div className="flexdiv-parent">
              <div className=" flexdiv-menu">
                <div className="flexdiv-menu-items">
                  <img src={icon2} className="productnav_icon" alt="icon2" />
                  <h2 className="product_nav-title ">Business</h2>
                </div>
              </div>
              <div className="horizontal-flow">
                <div className="horizontal-flow-track">
                  <div className="nav-link horizontal-flow-items">
                    <ul className="navbar-nav ml-auto">
                      {showMerchantLink && (
                        <li className="nav-item">
                          <NavLink
                            to="https://order.montra.org/user/login"
                            target="_blank"
                            className="nav-link business"
                            activeClassName="active"
                          >
                            Manage your store
                          </NavLink>
                        </li>
                      )}
                      <li className="nav-item">
                        <NavLink
                          to="/Business/overview"
                          className="nav-link business"
                          activeClassName="active"
                          onClick={handleLinkClick}
                        >
                          Overview
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Business/Banking"
                          className="nav-link business"
                          activeClassName="active"
                          onClick={handleLinkClick}
                        >
                          Banking
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Business/Payment"
                          className="nav-link business"
                          activeClassName="active"
                          onClick={handleLinkClick}
                        >
                          Payments
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Business/AgencyBanking"
                          className="nav-link business"
                          activeClassName="active"
                          onClick={handleLinkClick}
                        >
                          Agency Banking
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          to="/Business/more/Inventory"
                          className="nav-link business"
                          activeClassName="active"
                          onClick={handleLinkClick}
                        >
                          Inventory Management
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Business/more/Store"
                          className="nav-link business"
                          activeClassName="active"
                          onClick={handleLinkClick}
                        >
                          Montra Store
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Business/more/Gateway"
                          className="nav-link business"
                          activeClassName="active"
                          onClick={handleLinkClick}
                        >
                          Payment Gateway
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Business/more/TapPay"
                          className="nav-link business"
                          activeClassName="active"
                          onClick={handleLinkClick}
                        >
                          Tap & Pay
                        </NavLink>
                      </li>

                      {/* <li className="nav-item">
                        <button className="product-btn produvt-sign">
                          Sign In <i className="bi bi-chevron-right"></i>
                        </button>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Productnav;
