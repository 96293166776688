/* React packages and components */
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

/**
  * Renders footer section of all MoneyLink statics page.
  <br>
  * <h6>Below Html elemensts are created to use for  UI</h6>
  * <li> <b> Section :- </b>it is used for represents a standalone section</li>
  * <li> <b> h3 :-</b> This HTML tag that indicates a heading on a website.</li>
  * <li> <b> p :-</b> This tag is  used to designate a paragraph</li>
  * <li> <b>img :- </b> This tags must have a defined src attribute. This defines the image to be displayed. </li>

  <br>
  * <h6>Below Bootstrap class is use for ui responsive.</h6>
  * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
  * <li> <b>row :-</b> This class to create a structure.</li>
  * <li> <b>Bootstrap-fontawsome :-</b> it is use for icons.</li>

  *  @Author : Thrilok Kandra
  *  @version :1.0
  */


const Footer = () => {

    return (
        <div>
       

        </div>
    )

}

export default Footer;
