import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";
import TermLife from "../../../assets/images/uday/Business-icons/finance/termInsurance.svg";
import EndowmentPlans from "../../../assets/images/uday/Business-icons/finance/endowment.svg";
import HealthInsurance from "../../../assets/images/uday/Business-icons/finance/healthInsurance.svg";
import CarInsurance from "../../../assets/images/uday/Business-icons/finance/carInsurance.svg";
import BikeInsurance from "../../../assets/images/uday/Business-icons/finance/bikeInsurance.svg";
import TravelInsurance from "../../../assets/images/uday/Business-icons/finance/TravelInsurance.svg";
import finance2 from "../../../assets/images/finance2.svg";

const steps = [
  {
    title: "Insurance Products",
    image: finance2,
    // className: "finance-hero-img",
  },
  { image: TermLife, description: "Term Life" },
  { image: EndowmentPlans, description: "Endowment Plans" },
  { image: HealthInsurance, description: "Health Insurance" },
  { image: CarInsurance, description: "Car Insurance" },
  { image: BikeInsurance, description: "2-wheeler Insurance" },
  { image: TravelInsurance, description: "Travel Insurance" },
];

const InsuranceProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#4ABCEB"
        circleBorderColor="#4ABCEB"
        textColor="#20336B"
        topDescriptionClass="insurance-top-description"
      />
    </div>
  );
};

export default InsuranceProcessContainer;
