import { useState } from "react";
import dts1 from "../../../../assets/images/dts1.svg";
import dts2 from "../../../../assets/images/dts2.svg";
import dtsyoutube from "../../../../assets/images/dtsyoutube.svg";
import "../Dts/style.css";

const Body = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleImageClick = () => {
    setIsVideoPlaying(true);
  };
  return (
    <>
      <div className="dcp-body">
        <div className="row personalb">
          <div className="col-md-6 dts-frame dcp-frame-1">
            <div className="dts-frame-img">
              <img src={dts1} alt="dts1" />
            </div>
            <div className="dts-frame-header dcp-frame-header">
              <div class="lineparagrapduo">
                <h6>Advantage to Customers</h6>
              </div>
            </div>
            <div className="duo-lists dts-list dcp-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Don’t have to carry Credit Card separately DUO card comes
                    with a Debit & Credit card in Single Card.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Don’t have to fill application forms or submit documents for
                    credit evaluation.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Customers can now transact Internationally both on Debit &
                    Credit side of DUO card.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Easy up-grade from DTS (Dual Transaction Service) to DCP
                    (DUO Card Product) without having to fill application form
                    or submit documents.
                  </p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DCP comes with combined statements for both Debit & Credit
                    spends.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 dts-frame dcp-frame dcp-frame-2">
            <div className="dts-frame-img">
              <img src={dts2} alt="Advantage to Issuers" />
            </div>
            <div className="dts-frame-header dcp-frame-header">
              <div class="lineparagrapduo">
                <h6>Advantage to Issuers</h6>
              </div>
            </div>
            <div className="duo-lists dts-list dcp-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Banks / Issuers can now acquire customers on both Debit &
                    Credit side in one go.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Easy upgrade for customers already on DTS to DCP without
                    changing the credit Card Account.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Easy for Banks / Issuers to retain customers & manage
                    loyalty program.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Easy to build usage on Credit Card with existing customers
                    already using Debit Cards under DCP proposition.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dts-body2 dcp-body2">
        <div className="row ">
          <div className="col">
            <div
              style={{ position: "relative", width: "100%", height: "200px" }}
            >
              {!isVideoPlaying && (
                <img
                  src={dtsyoutube}
                  alt="Description of the image"
                  className="video-image"
                  onClick={handleImageClick}
                />
              )}

              {isVideoPlaying && (
                <iframe
                  title="YouTube Video"
                  width="463px"
                  height="299px"
                  src="//www.youtube.com/embed/iUUEmaMutM0?rel=0&autoplay=1&mute=1" // Muted
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  style={{
                    position: "relative",
                    zIndex: 0,
                    borderRadius: "20px",
                  }}
                />
              )}
            </div>
          </div>
          <div className="col">
            <div className="how-to-use-dts-dcp dts-content">
              <div className="dtsvideocontent">
                <h6>How to use DTS & DCP?</h6>
              </div>
              <p>
                Get to know how you can start using Dual Transaction Service
                (DTS)
              </p>
              <a
                href="https://www.youtube.com/embed/iUUEmaMutM0?rel=0&autoplay=1&mute=1"
                target="_blank"
              >
                <button>Watch Video</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
