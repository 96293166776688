import React from "react";
import duocard from "../../../../assets/images/duocard.svg";
// import duohero2 from "../../../../assets/images/duohero2.svg";
import ProcessContainer from "../DuoProcessContainer";
import "./style.css";

const Hero = () => {
  return (
    <>
      <div className="duo-overview-hero">
        <div className="row">
          <div className="col duo-overview-text">
            <h1 className="duo-overview-h1">
              <span className="duo-overview-span">
                Enjoy DUO card experience
              </span>{" "}
              with both Credit <span className="andfont">&</span> Debit offering
              in a single card
            </h1>
            <p className="duo-overview-p">
              DUO offers end to end solutions for Issuers to launch, manage &
              scale Credit Card issuance, activations, usage & collections
              towards enabling them create a profitable portfolio.
            </p>
          </div>
          <div className="col duo-overview-image-col">
            <img className="duo-hero-img" src={duocard} alt="duocard" />
          </div>
        </div>

        {/* <div className="duohero2"> */}
        {/* <img src={duohero2} alt="duohero2" className="duoheroimg2" /> */}
        <ProcessContainer />
        <div>
          <button className="duo-hero-button">Contact Sales</button>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Hero;
