import React, { useEffect, useState } from "react";
import Animation from "../Animation/Main";
import paymentCollectionImage1 from "../../../../assets/images/uday/New-Images/image/supply-chain.svg";
import paymentCollectionImage2 from "../../../../assets/images/uday/supplychain/cap9.svg";
import paymentCollectionImage3 from "../../../../assets/images/uday/supplychain/buy-inventory.webp";
import paymentGif from "../../../../assets/images/uday/gifs/payment.gif";
import "./style.css";

const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 768;

  return (
    <>
      <div className="sup-payment-hero">
        <div className="row supplychain-row">
          <div className="col">
            <h1>
              Effortlessly send invoices to Distributors and Outlets{" "}
              <span>
                directly from your handheld with Montra Supply Chain SDK:
              </span>{" "}
            </h1>

            <div className="capabilities">
              <div className="sup-point subparagraph">
                <div className="square-point"></div>
                <div>
                  <p>
                    Offer credit period on the Invoice that you send to
                    Distributor or Outlets under Distributor’s direct coverage.
                  </p>
                </div>
              </div>
              <div className="sup-point subparagraph">
                <div className="square-point"></div>
                <div>
                  <p>
                    Distributors & Outlets can simply pay during the Credit
                    Period or on the last day using their Montra Business
                    Accounts or Linked Bank Accounts.
                  </p>
                </div>
              </div>
              <div className="sup-point subparagraph">
                <div className="square-point"></div>
                <div>
                  <p>
                    Montra provides Dashboard to both Distribution Company /
                    Distributor & Outlet owner to view all payments done against
                    a Credit based invoice.
                  </p>
                </div>
              </div>
            </div>
            <button>Contact Sales</button>
          </div>
          {!isMobile ? (
            <>
              <div className="col right-payment-col">
                <div className="left-image-payment">
                  <div className="left-image-top">
                    <img
                      src={paymentCollectionImage1}
                      alt="paymentCollectionImage1"
                    />
                  </div>
                  <div className="left-image-bottom">
                    <img
                      src={paymentCollectionImage2}
                      alt="paymentCollectionImage2"
                    />
                  </div>
                </div>

                <div className="animation-class">
                  <Animation index={1} />
                </div>
                <div className="center-image-payment">
                  <div className="center-image-bottom">
                    <img
                      src={paymentCollectionImage3}
                      alt="paymentCollectionImage3"
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="gif-layout supplychain-gif-layout">
              <img
                src={paymentGif}
                alt="business-section gif"
                className="business-section1-gif"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Hero;
