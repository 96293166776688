import React from "react";

function YU19b() {
  return (
    <svg
      width="240"
      height="115"
      viewBox="0 0 240 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        d="M240 68.0007H218.5C209 68.0007 199.5 76.6007 199.5 85.0007C199.5 93.4007 199.5 100.501 199.5 103.001C199.5 106.501 194.9 113.901 184.5 113.501C174.1 113.101 57.1667 113.334 0 113.501M240 45.5007H218.5C212.167 45.6674 199.5 43.6007 199.5 34.0007C199.5 24.4007 199.5 21.8341 199.5 20.5007C200.5 14.8341 198.9 1.10074 184.5 1.50074C170.1 1.90074 55.5 1.66741 0 1.50074"
        stroke="url(#paint0_radial_5164_5737)"
        stroke-width="2"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5164_5737"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(230.593 -92.9437) rotate(90) scale(254.056 230.593)"
        >
          <stop stop-color="#0CC2FF" />
          <stop offset="1" stop-color="#8CF2B2" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU19b;
