/* React packages and components */
import React from 'react';
import Sidebar from '../Sidebar';
import ManageUserPart1 from './ManageUserPart1';
import ManageUserPart2 from './ManageUserPart2';
import * as EmailValidator from 'email-validator';
import MakerHeader from '../MakerHeader';
import "./ManageUser.css";
import axios from 'axios';
import ForgotPin from "../../ForgotPin/ForgotPin";


/**
 * Renders  data for both Users(Non-Credit-UW users and Credit-UW Users)  in maker Manage User section.
 * <p> Maker is allowed to make the user inactive/active</p>
 * <p> Maker is allowed to remove user</p>
 * <p> Maker is allowed to assign the products for credit-uw user</p>
 * <p> Maker is allowed  to change the roles of users</p>.
 * @Author : Seema Kumari
 * @version :1.0
 */

class ManageUser extends React.Component {
  oldData = [];
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      isUserNamelist: [],
      isEmaillist: [],
      isUserTypelist: [],
      isDesignationlist: [],
      isMobilelist: [],
      isUserNamecredituwlist: [],
      isEmailcredituwlist: [],
      isUserTypecredituwlist: [],
      isDesignationcredituwlist: [],
      isMobilecredituwlist: [],
      buttoncolor: '',
      fieldsnotvalid: false,
      rolesAndProductsNotAssigned: {
        MissingProds: [],
        MissingRoles: []
      },
      errors: [],
      editable: false,
      emaildisable: [],
      emailcredituwdisable: [],
      activedisable: [],
      activecredituwdisable: [],
      color: [],
      credituwcolor: [],
      backGroundColor: [],
      backGroundColorcredituw: [],
      isassignedarray: [[], []],
      prodsAssignedForUWRules: [{
        CompanyId: '',
        email: '',
        isAssgined: '',
        lastUpdaDateTime: '',
        productName: '',
        requestId: '',
        status: '',
      }],
      inputs: [{
        officialEmail: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [], active: '', userName: ''
      }],
      inputscredituw: [{
        officialEmail: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [{
          CompanyId: '',
          email: '',
          isAssgined: '',
          lastUpdaDateTime: '',
          productName: '',
          requestId: '',
          status: ''
        }], active: '', userName: ''
      }],
      dataarraycredituw:
        [{
          officialEmail: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [{
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          },
          {
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          }, {
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          }, {
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          }], active: '', userName: ''
        }],
      dataarray:
        [{
          officialEmail: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [{
            CompanyId: '', email: '', isAssgined: '', lastUpdaDateTime: '', productName: '', requestId: '', status: ''
          }], active: '', userName: ''
        },
        ],
      status: '',
      isDisabled: false,
      backerrors: [],
      message: '',
      errorMsg: ''
    };
  }

  /**
   * Captures the pin entered by maker before submitting the changes.
   * @param {Event} e event which calls this method.
   */

  handleChange(e) {
    isDisabledSubmitButton = false;
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields: fields
    });
  }

  /**
   * @typedef {data} data
   * @property {string} userName indicates username
   * @property {String} email  indicates email of user
   * @property {string} userType indicates role of user
   * @property {String} mobile indicates mobile number of user
   * @property {String} designation indicates designation of user
   */

  /**
   * Creates one more object of data and shows the input fields to enter the details.
   *<p> For other than credit-uw users.</p>
   * @param {data} data object which is added
   * @param {Event} e event to add one more user
   */
  addInput(e) {
    let input = {
      officialEmail: '', isEditable: true, designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [], active: true, userName: ''
    }
    let dataarray = this.state.dataarray;
    dataarray.push(input);
    this.setState({ dataarray })
  }
  /**
   * Creates one more object of data and shows the input fields to enter the details.
   *<p> For credit-uw users.</p>
   * @param {data} data object which is added
   * @param {Event} e event to add one more user
   */
  addInputcredituw(e) {
    let isassignedarray = this.state.isassignedarray;
    let dataarraycredituw = this.state.dataarraycredituw;
    let itemcredituw = {
      officialEmail: '', isEditable: true, designation: '', userType: 'CREDIT-UW', isUserType: 'A', mobile: '', prodsAssignedForUWRules: [{
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'PayDay Loan', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Salary Advance', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'STPL', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'CD Loans', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Personal Loan', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Auto Loan', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Home Loan', requestId: '', status: ''
      }, {
        CompanyId: '', email: '', isAssgined: 'R', prodAssigned: 'NO', lastUpdaDateTime: '', productName: 'Loan Against Property', requestId: '', status: ''
      }
      ], active: true, userName: ''
    }
    dataarraycredituw.push(itemcredituw);
    this.setState({
      dataarraycredituw: dataarraycredituw,
      isassignedarray
    })
  }

  /**
   * Captures the value of designation of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwuserdesgn(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].designation = e.target.value;
    this.state.dataarraycredituw[i].isDesignation = "A";
    if (this.oldData.length > i && e.target.value === this.oldData[i].designation) {
      dataarraycredituw[i].isDesignation = "N";
    }
    if ("" == this.state.dataarraycredituw[i].designation) {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of username of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwusername(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].userName = e.target.value;
    this.state.dataarraycredituw[i].isUserName = "A";
    if (this.oldData.length > i && e.target.value === this.oldData[i].userName) {
      dataarraycredituw[i].isUserName = "N";
    }
    if ("" == this.state.dataarraycredituw[i].userName) {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }
  /**
     * Captures the value of product assigned of data(For Credit-UW users).
     * @param {Event} e event that calls and stores the value entered by maker
     * @param {data} data  index of data to store the value.
     */
  // handleChangecredituwproducttypes(e, i, j) {
  //   let dataarraycredituw = this.state.dataarraycredituw;

  //   if ('YES' == e.target.value) {
  //     dataarraycredituw[i].prodsAssignedForUWRules[j].isAssgined = "A";
  //     dataarraycredituw[i].prodsAssignedForUWRules[j].prodAssigned = "YES";
  //   } else {
  //     dataarraycredituw[i].prodsAssignedForUWRules[j].isAssgined = "A";
  //     dataarraycredituw[i].prodsAssignedForUWRules[j].prodAssigned = "NO";
  //   }

  //   if (this.oldData.length > i && e.target.value === this.oldData[i].isAssgined) {
  //     dataarraycredituw[i].isUserName = "N";
  //   }
  //   this.setState({
  //     dataarraycredituw: dataarraycredituw
  //   });
  // }


  handleChangecredituwproducttypes(e, i, j) {
    let dataarraycredituw = [...this.state.dataarraycredituw]; // Copy the state array for immutability

    // Update the value based on the selection
    if (e.target.value === 'YES') {
      dataarraycredituw[i].prodsAssignedForUWRules[j].isAssgined = "A";
      dataarraycredituw[i].prodsAssignedForUWRules[j].prodAssigned = "YES";
    } else {
      dataarraycredituw[i].prodsAssignedForUWRules[j].isAssgined = "A";
      dataarraycredituw[i].prodsAssignedForUWRules[j].prodAssigned = "NO";
    }
    if (this.oldData?.length > i && this.oldData[i]?.prodsAssignedForUWRules?.length > j) {
      const oldAssignedValue = this.oldData[i].prodsAssignedForUWRules[j].isAssgined;
      if (e.target.value === oldAssignedValue) {
        dataarraycredituw[i].isAssgined = "N";
      } else {
        dataarraycredituw[i].isAssgined = "Y";
      }
    }
    this.setState({
      dataarraycredituw
    });
  }


  /**
   * Captures the value of usertype of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwusertype(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].userType = e.target.value;
    this.state.dataarraycredituw[i].isUserType = "A";
    if (this.oldData.length > i && e.target.value === this.oldData[i].userType) {
      dataarraycredituw[i].isUserType = "N";
    }
    if ("" == this.state.dataarraycredituw[i].userType) {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of email of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwemail(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].officialEmail = e.target.value;
    this.state.dataarraycredituw[i].isEmail = "A";
    if ("" == this.state.dataarraycredituw[i].officialEmail || !EmailValidator.validate(this.state.dataarraycredituw[i].officialEmail)) {
      this.state.isEmailcredituwlist[i] = "#e90505";
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.state.isEmailcredituwlist[i] = "";
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of mobile of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangecredituwmobile(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].mobile = e.target.value;
    this.state.dataarraycredituw[i].isMobile = "A";
    if (this.oldData.length > i && e.target.value === this.oldData[i].mobile) {
      dataarraycredituw[i].isMobile = "N";
    }
    if ("" == this.state.dataarraycredituw[i].mobile || !Number(this.state.dataarraycredituw[i].mobile) || 10 != this.state.dataarraycredituw[i].mobile.length) {
      this.state.isMobilecredituwlist[i] = "#e90505";
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: true
      });
    }
    else {
      this.state.isMobilecredituwlist[i] = "";
      this.setState({
        dataarraycredituw,
        fieldsnotvalid: false
      });
    }
  }

  /**
   * Captures the value of designation of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeuserdesgn(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].designation = e.target.value;
    this.state.dataarray[i].isDesignation = "A";
    if (this.oldData.length > i && e.target.value === this.oldData[i].designation) {
      dataarray[i].isDesignation = "N";
    }
    if ("" == this.state.dataarray[i].designation) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of username of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeusername(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].userName = e.target.value;
    this.state.dataarray[i].isUserName = "A";
    if (this.oldData.length > i && e.target.value === this.oldData[i].userName) {
      dataarray[i].isUserName = "N";
    }
    if ("" == this.state.dataarray[i].userName) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of usertype of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeusertype(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].userType = e.target.value;
    this.state.dataarray[i].isUserType = "A";
    if (this.oldData.length > i && e.target.value === this.oldData[i].userType) {
      dataarray[i].isUserType = "N";
    }
    if ("" == this.state.dataarray[i].userType) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of email of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeemail(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].officialEmail = e.target.value;
    this.state.dataarray[i].isEmail = "A";
    if ("" == this.state.dataarray[i].officialEmail || !EmailValidator.validate(this.state.dataarray[i].officialEmail)) {
      this.state.isEmaillist[i] = "#e90505";
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.state.isEmaillist[i] = "";
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of mobile of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangemobile(e, i) {
    let dataarray = this.state.dataarray;
    dataarray[i].mobile = e.target.value;
    dataarray[i].isMobile = "A";
    if (this.oldData.length > i && e.target.value === this.oldData[i].mobile) {
      dataarray[i].isMobile = "N";
    }
    if ("" == this.state.dataarray[i].mobile || !Number(this.state.dataarray[i].mobile) || 10 != this.state.dataarray[i].mobile.length) {
      this.state.isMobilelist[i] = "#e90505";
      this.setState({
        dataarray: dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.state.isMobilelist[i] = "";
      this.setState({
        dataarray: dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * Captures the value of remove of data(For other than Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeremoval(e, i) {
    e.preventDefault();
    let color = this.state.color;
    let dataarray = this.state.dataarray;
    if (this.state.dataarray[i].isRemoved === "A") {
      this.state.dataarray[i].isRemoved = "N";
    } else {
      this.state.dataarray[i].isRemoved = "A";
    }
    if (!this.state.color[i] || '' == this.state.color[i]) {
      this.state.color[i] = true;
      this.state.backGroundColor[i] = "#fdc9a9"
    }
    else {
      this.state.color[i] = false;
      this.state.backGroundColor[i] = "#dcf4fb"
    }
    this.setState({
      color,
      dataarray
    })

  }
  /**
   * Captures the value of remove of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */

  handleChangecredituwremoval(e, i) {
    e.preventDefault();
    let credituwcolor = this.state.credituwcolor;
    let dataarraycredituw = this.state.dataarraycredituw;
    if (this.state.dataarraycredituw[i].isRemoved === "A") {
      this.state.dataarraycredituw[i].isRemoved = "N";
    } else {
      this.state.dataarraycredituw[i].isRemoved = "A";
    }
    if (!this.state.credituwcolor[i] || '' == this.state.credituwcolor[i]) {
      this.state.credituwcolor[i] = true;
      this.state.backGroundColorcredituw[i] = "#fdc9a9"
    }
    else {
      this.state.credituwcolor[i] = false;
      this.state.backGroundColorcredituw[i] = "#dcf4fb"
    } this.setState({
      credituwcolor,
      dataarraycredituw
    })
  }

  /**
   * Captures the value of product assigned of data(For Credit-UW users).
   * @param {Event} e event that calls and stores the value entered by maker
   * @param {data} data  index of data to store the value.
   */
  handleChangeprodsAssignedForUWRules(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].prodsAssignedForUWRules = e.target.value;
    if ("" == this.state.dataarray[i].prodsAssignedForUWRules) {
      this.setState({
        dataarray,
        fieldsnotvalid: true
      });
    }
    else {
      this.setState({
        dataarray,
        fieldsnotvalid: false
      });
    }
  }
  /**
   * This will validate the pin.
   * No Parameters for this method.
   */
  handleSubmitvalidateForm() {
    let errors = this.state.errors;
    let formIsValid = true;
    if (this.state.fields.pin == '' || this.state.fields.pin == null || this.state.fields.pin.length != 4) {
      formIsValid = false;
      errors['pin'] = '*Please enter 4 digit pin.';
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  compareObjects(obj1, obj2) {
    let changes = [];
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && obj1[key] !== obj2[key]) {
        console.log("KeysComparison ==>", obj1[key]);
        console.log("Keys2Comparison ==>", obj2[key]);
        changes.push({
          key: key,
          originalValue: obj1[key],
          modifiedValue: obj2[key]
        });
      }
    }
    return changes;
  }

  compareObjectsCW(obj1, obj2) {
    let changes = [];
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        const value1 = obj1[key];
        const value2 = obj2 ? obj2[key] : undefined; // Safely handle if obj2 is null or undefined

        if (key === "prodsAssignedForUWRules") {
          // Handle prodsAssignedForUWRules comparison separately
          let prodChanges = this.compareProdsAssignedForUWRules(value1, value2);
          changes = changes.concat(prodChanges);
          continue;
        }

        // Compare values, including null/undefined handling
        if (value1 !== value2) {
          console.log("KeysComparison ==>", value1);
          console.log("Keys2Comparison ==>", value2);

          // Push the changes only if they differ and neither value is null/undefined
          changes.push({
            key: key,
            originalValue: value1 !== undefined ? value1 : null,
            modifiedValue: value2 !== undefined ? value2 : null
          });
        }
      }
    }
    return changes;
  }

  // Comparison for prodsAssignedForUWRules array
  compareProdsAssignedForUWRules(arr1, arr2) {
    let changes = [];

    // Handle cases where one of the arrays is missing
    if (!arr1 || !arr2) {
      if (arr1) {
        changes.push({ key: 'prodsAssignedForUWRules', originalValue: arr1, modifiedValue: null });
      }
      if (arr2) {
        changes.push({ key: 'prodsAssignedForUWRules', originalValue: null, modifiedValue: arr2 });
      }
      return changes;
    }

    // Compare each product assignment
    for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
      const prod1 = arr1[i];
      const prod2 = arr2[i];

      if (!prod1 || !prod2 || JSON.stringify(prod1) !== JSON.stringify(prod2)) {
        changes.push({
          key: `prodsAssignedForUWRules[${i}]`,
          originalValue: prod1 || null,
          modifiedValue: prod2 || null
        });
      }
    }

    return changes;
  }



  /**
   *Submit the changes done by the maker to Server
   * @param {Event} e event that will check user pin and submits.
   */

  handleSubmit = (e) => {
    isDisabledSubmitButton = true;
    e.preventDefault();
    if (this.handleSubmitvalidateForm()) {
      this.setState({ errors: [] });
      this.setOnAndOffActivevalue();
      this.setOnAndOffActivevaluecredituw();
      let data = [];
      let isValid = true;
      console.log("top data:", this.state.dataarray);
      console.log("down data:", this.state?.dataarraycredituw);
      let dataarray = this.state.dataarray || [];
      let dataarraycredituw = this.state?.dataarraycredituw;
      let oldDataArray = this.oldData?.regularUsers || [];
      let oldDataArraycredituw = this.oldData?.creditUWUsers;
      // Process regular users
      dataarray.forEach(input => {
        if (
          input.mobile === "" || !Number(input.mobile) || input.mobile.length !== 10 ||
          input.designation === "" || input.officialEmail === "" ||
          !EmailValidator.validate(input.officialEmail)
        ) {
          isValid = false;
          this.setState({ fieldsnotvalid: true });
        } else if (input.isEditable) { // Only process if isEditable is true
          if (!input.hasOwnProperty("userSequenceNum")) {
            data.push(input); // Push new users
          } else {
            const oldinputData = oldDataArray.find(obj => obj.userSequenceNum === input.userSequenceNum);
            const changes = this.compareObjects(oldinputData, input);
            console.log("changes1", changes);
            if (changes.length > 0) {
              data.push(input); // Push modified data
            }
          }
        }
      });

      // Process credit UW users
      dataarraycredituw.forEach(input => {
        if (
          input.mobile === "" || !Number(input.mobile) || input.mobile.length !== 10 ||
          input.designation === "" || input.officialEmail === "" ||
          !EmailValidator.validate(input.officialEmail)
        ) {
          isValid = false;
          this.setState({ fieldsnotvalid: true });
        }
        else if (input.isEditable) {
          if (!input.hasOwnProperty("userSequenceNum")) {
            data.push(input); // Push new users
          }
          else {
            const oldinputDatacredituw = oldDataArraycredituw.find(obj => obj.userSequenceNum === input.userSequenceNum);
            const changes = this.compareObjectsCW(oldinputDatacredituw, input);
            console.log("changes", changes);
            if (changes.length > 0) {
              data.push(input); // Push modified data
            }
          }
        }
      });

      console.log("Final data to submit:", data);
      const url = `api/user/updateusers?email=${localStorage.getItem("email")}&pin=${this.state.fields.pin}`;
      axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        }
      })
        .then(response => {
          const contents = response.data;
          if (contents.status === 200 && contents.message === 'SUCCESS') {
            isDisabledSubmitButton = true;
            this.setState({
              isDisabled: true,
              status: contents.status,
              message: contents.message
            });
            this.setTrueOrFalseActivevalue();
            this.setTrueOrFalseActivevaluecredituw();
          } else {
            isDisabledSubmitButton = true;
            this.setTrueOrFalseActivevalue();
            this.setTrueOrFalseActivevaluecredituw();
            this.setState({
              backerrors: contents.errors,
              status: contents.status,
              message: contents.message,
              rolesAndProductsNotAssigned: contents.data
            });
            this.setState({
              errorMsg: contents.errors.map(error => error.message).join(', ')
            });
          }
        })
        .catch(error => {
          console.error('Error during submission:', error); // Log error for debugging
          this.setState({
            errorMsg: 'Server Error Please Try Again'
          });
        });
    }
  }
  /**
   * set boolean based on fetch details in componentdidmount.
   */
  setTrueOrFalseActivevalue() {
    // Create copies of the state arrays to avoid direct mutation
    let dataarray = [...this.state.dataarray];
    let activedisable = [...this.state.activedisable];
    let backGroundColor = [...this.state.backGroundColor];
    let emaildisable = [...this.state.emaildisable];

    dataarray.forEach((ManageUser, i) => {
      emaildisable[i] = true;
      backGroundColor[i] = "#dcf4fb";
      if (ManageUser.active === "YES" || ManageUser.active === "yes") {
        ManageUser.active = true;
        activedisable[i] = false;
        backGroundColor[i] = "#dcf4fb";
      } else {
        ManageUser.active = false;
        activedisable[i] = true;
        backGroundColor[i] = "#dadada";
      }
      if (ManageUser.isRemoved === 'A') {
        this.state.backGroundColor[i] = "#fdc9a9";
      }
      if (!ManageUser?.isEditable) {
        backGroundColor[i] = "#dadada";
      }
    });
    this.setState({
      dataarray,
      activedisable,
      backGroundColor,
      emaildisable
    });
  }
  /**
   * Sets boolean values for fetched details in componentdidmount.
   */
  setTrueOrFalseActivevaluecredituw() {
    const dataarraycredituw = this.state.dataarraycredituw.map((ManageUser, i) => {
      // Initial state settings for each user
      let activecredituwdisable = true;
      let backGroundColorcredituw = "#dcf4fb";
      let emailcredituwdisable = true;

      // Set the active state and background color based on `active` value
      if (ManageUser.active === "YES" || ManageUser.active === "yes") {
        ManageUser.active = true;
        activecredituwdisable = false;
        backGroundColorcredituw = "#dcf4fb";
      } else {
        ManageUser.active = false;
        activecredituwdisable = true;
        backGroundColorcredituw = "#dadada";
      }

      // Check if the user is removed
      if (ManageUser.isRemoved === "A") {
        backGroundColorcredituw = "#fdc9a9";
      }

      // Check if the user is editable
      if (!ManageUser.isEditable) {
        backGroundColorcredituw = "#dadada";
      }

      // Update the corresponding index in `emailcredituwdisable` and `backGroundColorcredituw`
      this.state.emailcredituwdisable[i] = emailcredituwdisable;
      this.state.activecredituwdisable[i] = activecredituwdisable;
      this.state.backGroundColorcredituw[i] = backGroundColorcredituw;

      return ManageUser;
    });

    // Update the state with the modified data
    this.setState({
      dataarraycredituw: dataarraycredituw
    });
  }


  setOnAndOffActivevalue() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((manageuser, i) => {
      if (manageuser.active) {
        manageuser.active = "YES"
      }
      else {
        manageuser.active = "NO"
      }
    })
    this.setState({
      dataarray: dataarray
    })
  }
  setOnAndOffActivevaluecredituw() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((ManageUser, i) => {
      if (ManageUser.active) {
        ManageUser.active = "YES"
      }
      else {
        ManageUser.active = "NO"
      }
    })
    this.setState({ dataarraycredituw: dataarraycredituw })
  }
  /**
   * sets border color based on changed values.
   */
  setBorderColor() {
    let isUserNamelist = this.state.isUserNamelist
    let isEmaillist = this.state.isEmaillist
    let isUserTypelist = this.state.isUserTypelist
    let isDesignationlist = this.state.isDesignationlist
    let isMobilelist = this.state.isMobilelist
    let isUserNamecredituwlist = this.state.isUserNamecredituwlist
    let isEmailcredituwlist = this.state.isEmailcredituwlist
    let isUserTypecredituwlist = this.state.isUserTypecredituwlist
    let isDesignationcredituwlist = this.state.isDesignationcredituwlist
    let isMobilecredituwlist = this.state.isMobilecredituwlist
    this.state.dataarray.map((ManageUser, i) => {
      if (ManageUser.isDesignation == "A") { this.state.isDesignationlist[i] = "#e90505" }
      if (ManageUser.isEmail == "A") { this.state.isEmaillist[i] = "#e90505" }
      if (ManageUser.isMobile == "A") { this.state.isMobilelist[i] = "#e90505" }
      if (ManageUser.isUserName == "A") { this.state.isUserNamelist[i] = "#e90505" }
      if (ManageUser.isUserType == "A") { this.state.isUserTypelist[i] = "#e90505" }
    })
    this.state.dataarraycredituw.map((ManageUser, i) => {
      if (ManageUser.isDesignation == "A") { this.state.isDesignationcredituwlist[i] = "#e90505" }
      if (ManageUser.isEmail == "A") { this.state.isEmailcredituwlist[i] = "#e90505" }
      if (ManageUser.isMobile == "A") { this.state.isMobilecredituwlist[i] = "#e90505" }
      if (ManageUser.isUserName == "A") { this.state.isUserNamecredituwlist[i] = "#e90505" }
      if (ManageUser.isUserType == "A") { this.state.isUserNamecredituwlist[i] = "#e90505" }
    })
  }
  /**
   * For new editable request once the checker approved sets defauklt values.
   */
  setNvalue() {
    this.state.dataarray.map((ManageUser, i) => {
      ManageUser.isDesignation = "N"
      ManageUser.isEmail = "N"
      ManageUser.isMobile = "N"
      ManageUser.isUserName = "N"
      ManageUser.isUserType = "N"
      ManageUser.isActive = "N"
      ManageUser.isRemoved = "N"
    })
  }
  setNvaluecredituw() {
    this.state.dataarraycredituw.map((ManageUser, i) => {
      ManageUser.isDesignation = "N"
      ManageUser.isEmail = "N"
      ManageUser.isMobile = "N"
      ManageUser.isUserName = "N"
      ManageUser.isUserType = "N"
      ManageUser.isActive = "N"
      ManageUser.isRemoved = "N"
      ManageUser.prodsAssignedForUWRules.map(prodsAssignedForUWRules => {
        prodsAssignedForUWRules.isAssgined = "N"
      })
    })
  }
  setNprodsvaluecredituw() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((ManageUser, i) => {
      ManageUser.prodsAssignedForUWRules.map((prodsAssignedForUWRules, j) => {
        prodsAssignedForUWRules.isAssgined = "N"
      })
    })
    this.setState({
      dataarraycredituw
    })
  }

  /**
   * Fetches data from server and render all fields
   */

  componentDidMount() {
    const url = `api/user/users?email=${localStorage.getItem("email")}`;
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(response => {
        const contents = response.data;
        console.log('Fetched contents:', contents); // Log fetched contents for debugging
        if (contents.status === 200 && contents.message === 'SUCCESS') {
          // this.OnAndOffChangecredituwarray(contents.data.CreditUWUsers.length);
          let a = contents.data.RegularUsers;
          let b = contents.data.CreditUWUsers;
          this.oldData = {
            regularUsers: JSON.parse(JSON.stringify(a)),
            creditUWUsers: JSON.parse(JSON.stringify(b))
          };
          this.setState({
            dataarray: a,
            dataarraycredituw: b,
            status: contents.status,
            editable: contents.data.editable
          }, () => {
            this.setTrueOrFalseActivevalue();
            this.setTrueOrFalseActivevaluecredituw();
            this.setNprodsvaluecredituw();
            this.setNvaluecredituw();
          });
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            errorMsg: contents.errors.map(error => error.message).join(', ')
          });
          console.error('Errors:', contents.errors); // Log errors for debugging
        }
      })
      .catch(error => {
        console.error('Fetch error:', error); // Log fetch error for debugging
        this.setState({
          errorMsg: 'Server Error Please Try Again'
        });
      });
  }
  /**
   * Captures the boolean value for active toggle button
   *
   * @param {Event} e  event to call this method
   * @param {data[]} data index of data array
   */

  toggleChange(e, i) {
    let dataarray = this.state.dataarray;
    let activedisable = this.state.activedisable;

    if (!this.state.activedisable[i] || '' == this.state.activedisable[i]) {
      this.state.activedisable[i] = true;
      this.state.backGroundColor[i] = "#dadada"
    }
    else {
      this.state.activedisable[i] = false;
      this.state.backGroundColor[i] = "#dcf4fb"
    }
    this.state.dataarray[i].active = !this.state.dataarray[i].active
    if (this.oldData.length > i) {
      if (dataarray[i].isActive === "N") {
        dataarray[i].isActive = "A";
      } else if (dataarray[i].isActive === "A") {
        dataarray[i].isActive = "N";
      }
    } else {
      dataarray[i].isActive = "A";
    }
    this.setState({
      dataarray,
      activedisable
    });
  }
  /**
   * Captures the changed active value for Credit-UW users
   *
   * @param {Event} e captures the changed value and calls this method
   * @param {data} data index of data and stores into object
   */
  toggleChangecredituwactive(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    let activecredituwdisable = this.state.activecredituwdisable;
    if (!this.state.activecredituwdisable[i] || '' == this.state.activecredituwdisable[i]) {
      this.state.activecredituwdisable[i] = true;
      this.state.backGroundColorcredituw[i] = "#dadada"
    }
    else {
      this.state.activecredituwdisable[i] = false;
      this.state.backGroundColorcredituw[i] = "#dcf4fb"
    }
    this.state.dataarraycredituw[i].active = !this.state.dataarraycredituw[i].active
    if (this.oldData.length > i) {
      if (dataarraycredituw[i].isActive === "N") {
        dataarraycredituw[i].isActive = "A";
      } else if (dataarraycredituw[i].isActive === "A") {
        dataarraycredituw[i].isActive = "N";
      }
    } else {
      dataarraycredituw[i].isActive = "A";
    }
    this.setState({
      dataarraycredituw,
      activecredituwdisable
    });
  }
  OnAndOffChangecredituwarray(n) {
    let isassignedarray = this.state.isassignedarray;
    for (let i = 0; i < n; i++) {
      isassignedarray.push([]);
      this.setState({
        isassignedarray
      });
    }
  }
  render() {
    if (localStorage.getItem("isLoggedIn") && "MAKER" == localStorage.getItem("role")) {
      if (this.state.dataarray.length > 0) {
        return (

          <div>
            { /* top-Header */}
            <MakerHeader />

            <div id="wrapper">
              { /* header menu */}
              <Sidebar />
              {/* {(() => {
                if (this.state.editable) {
                  return <div className="errorMsg"> <p className="toperror">There is Change Request yet to be approved by checker. A new request can be submitted once checker approves the request</p></div>
                }
              })()} */}
              <div id="maincontent" className="content">
                <section className='usersectionmange' >
                  <div className="backloan">
                    <div className="container-fluid undercontainer">
                      <p className="manageheader">MANAGE USER</p>
                      <p className="usertextmak">Users other than Credit-UW</p>
                      <div className="row">
                        <div className="col-md-12 reducepaddingfirst">
                          <ManageUserPart1 backGroundColor={this.state.backGroundColor} activedisableBackGroundColor={this.state.activedisableBackGroundColor} colorBackGroundColor={this.state.colorBackGroundColor} activedisable={this.state.activedisable} isUserNamelist={this.state.isUserNamelist} isEmaillist={this.state.isEmaillist} isUserTypelist={this.state.isUserTypelist} isDesignationlist={this.state.isDesignationlist} isMobilelist={this.state.isMobilelist}
                            emaildisable={this.state.emaildisable} handleChangeremoval={(e, i) => this.handleChangeremoval(e, i)} color={this.state.color} disablebutton={this.state.isDisabled} handleChange={(e) => this.handleChange(e)} handleChangeuserdesgn={(e, i) => this.handleChangeuserdesgn(e, i)} handleChangeusername={(e, i) => this.handleChangeusername(e, i)} handleChangeusertype={(e, i) => this.handleChangeusertype(e, i)} handleChangeemail={(e, i) => this.handleChangeemail(e, i)} handleChangemobile={(e, i) => this.handleChangemobile(e, i)} data={this.state.dataarray} addInput={(e) => this.addInput(e)} toggleChange={(e, i) => this.toggleChange(e, i)} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 reducepaddingfirst">
                          <p className="usertextmak2">Credit-UW Users</p>
                          <ManageUserPart2 backGroundColorcredituw={this.state.backGroundColorcredituw} activecredituwdisableBackGroundColor={this.state.activecredituwdisableBackGroundColor} credituwcolorBackGroundColor={this.state.credituwcolorBackGroundColor} activecredituwdisable={this.state.activecredituwdisable} isUserNamecredituwlist={this.state.isUserNamecredituwlist} isEmailcredituwlist={this.state.isEmailcredituwlist} isUserTypecredituwlist={this.state.isUserTypecredituwlist} isDesignationcredituwlist={this.state.isDesignationcredituwlist} isMobilecredituwlist={this.state.isMobilecredituwlist}
                            emailcredituwdisable={this.state.emailcredituwdisable} toggleChangecredituwactive={(e, i) => { this.toggleChangecredituwactive(e, i) }} disablebutton={this.state.isDisabled} handleChangecredituwuserdesgn={(e, i) => this.handleChangecredituwuserdesgn(e, i)} handleChangecredituwusername={(e, i) => this.handleChangecredituwusername(e, i)} handleChangecredituwusertype={(e, i) => this.handleChangecredituwusertype(e, i)} handleChangecredituwemail={(e, i) => this.handleChangecredituwemail(e, i)} handleChangecredituwmobile={(e, i) => this.handleChangecredituwmobile(e, i)}
                            isassignedarray={this.state.isassignedarray} credituwcolor={this.state.credituwcolor} handleChangecredituwremoval={(e, i) => this.handleChangecredituwremoval(e, i)} handleChangeprodsAssignedForUWRules={(e, i) => this.handleChangeprodsAssignedForUWRules(e, i)} data={this.state.dataarraycredituw} prodsAssignedForUWRules={this.state.dataarraycredituw.prodsAssignedForUWRules} addInput={(e) => this.addInputcredituw(e)} handleChangecredituwproducttypes={(e, i, j) => this.handleChangecredituwproducttypes(e, i, j)} />
                        </div>
                      </div>


                      {/* Type Your User PIN  :- user can submit the changes request approval after entering user pin */}

                      <div className="row">
                        <div className="managebox">
                          <form method="post" name="" >
                            <div className="form-group ">
                              <label htmlFor="first" className='typepin'>Type Your User PIN </label>
                              <input type="password" className="form-control cardinputfield" name="pin" onChange={this.handleChange.bind(this)} placeholder="xxxxx " />
                              <div className="sign">
                                <input type="submit" className="btn btn-warning cardbutton" onClick={this.handleSubmit} value="Submit changes for approval" style={{ backgroundColor: this.state.buttoncolor }} disabled={isDisabledSubmitButton} />
                              </div>
                              <ForgotPin />
                            </div>

                          </form>
                        </div>
                        <div className="errorMsgpin">{this.state.errors.pin}</div>
                        {(() => {
                          switch (this.state.fieldsnotvalid) {
                            case true: return <div className="errorMsgpin">All Fields are Mandatory</div>
                            default: return <div></div>
                          }
                        })()}
                        {(() => {
                          switch (this.state.message) {
                            case "SUCCESS": return <p className="submittedpin">Submitted Succesfully</p>
                            default: return <div></div>
                          }
                        })()}
                        {(() => {
                          switch (this.state.message) {
                            case "UNSUCCESS": return <div>
                              <div className="errorMsgpin">{this.state.errorMsg}<div>{this.state.rolesAndProductsNotAssigned.MissingProds}</div><div>{this.state.rolesAndProductsNotAssigned.MissingRoles}</div></div></div>
                            default: return <div></div>
                          }
                        })()}
                      </div>

                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )
      }
      else {
        return (
          <p>
            No data Found
          </p>
        )
      }
    }
    else {
      return (<p>Please Login</p>)

    }
  }
}
let isDisabledSubmitButton = false;
export default ManageUser;
