import React from "react";
import Navbar from "../../Navbar/Productnav";
import Productfooter from "../../Footer/Productfooter";
import Hero from "../Banking/Hero";
import Body from "../Banking/Body";
import arrow1 from "../../../assets/images/arrow1.svg";
import './style.css';

const Main = () => {
  return (
    <>
      <div id="Banking">
        <Navbar />
        <Hero />
        <div className="banking-page">
          <div className="arrow">
            <img src={arrow1} alt="arrow" />
          </div>
          <Body />
        </div>
      </div>
      <Productfooter />
    </>
  );
};

export default Main;
