import React from "react";

function YU7() {
  return (
    <svg
      width="365"
      height="161"
      viewBox="0 0 365 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        d="M0 1C18.3849 1 113.957 1 143.945 1H338.59C352.397 1 363.59 12.1929 363.59 26V161"
        stroke="url(#paint0_radial_3450_10409)"
        stroke-width="2"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_3450_10409"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(166.801 80.7538) rotate(-136.585) scale(156.657 322.665)"
        >
          <stop stop-color="#FF7600" />
          <stop offset="1" stop-color="#635BFF" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU7;

{
  /* <svg
  xmlns="http://www.w3.org/2000/svg"
  width="330"
  height="161"
  fill="none"
  viewBox="0 0 330 161"
>
  <path
    className="yu-path"
    stroke="url(#paint0_radial_3450_10409)"
    strokeWidth="2"
    d="M1 161V21.5C1.5 14.667 7.2 1 26 1h304"
    pathLength="1"
  ></path>
  <defs>
    <radialGradient
      id="paint0_radial_3450_10409"
      cx="0"
      cy="0"
      r="1"
      gradientTransform="rotate(-137.291 98.585 8.146) scale(159.226 327.409)"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#FF7600"></stop>
      <stop offset="1" stopColor="#635BFF"></stop>
    </radialGradient>
  </defs>
</svg>; */
}
