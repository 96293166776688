/* React packages */
import React from "react";
import { NavLink } from "react-router-dom";
import "../moneylinkCentral.css";

/**
 * Header component is render navigation  of business section
 * <h6>There are one navigation menu will be there:</h6>
 * <li>Loan Portfolio dashboard</li>

 <br>
 * <h6> React Bootstrap Navigation ui properties :- </h6>
 * <li> <b>Navbar:- </b>A navigation bar is a navigation header that is placed at the top of the page.</li>
 * <li> <b>Navbar.Collapse:- </b>it is use for grouping and hiding navbar contents by a parent breakpoint.</li>
 * <li> <b>NavLink:- </b>it will add styling attributes to the rendered element when it matches the current URL.</li>

 @Author : Seema Kumari
 @version :1.0

 */

const Header = () => {
  return (
    <div className="link-container">
      <NavLink to="/Portfolio" className="userlink">
        Loan Portfolio dashboard
      </NavLink>
    </div>
  );
};

export default Header;
