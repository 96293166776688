import React from "react";

function YU6() {
  return (
    <svg
      width="576"
      height="168"
      viewBox="0 0 576 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00498 0V142.867C0.790297 151.085 7.44536 167.412 35.783 166.984C56.5909 166.669 223.588 166.72 368.746 166.84C421.275 166.884 470.944 166.936 509.79 166.984L541 166.84C562.5 166.984 574.5 158.54 574.5 146.498C574.5 136.463 574.5 48.3023 574.5 9.5M285.5 56V158"
        stroke="url(#paint0_radial_4716_6835)"
        stroke-width="2"
        pathLength="1"
        className="yu-path"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4716_6835"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(287.75 95.4922) rotate(90) scale(71.5 286.75)"
        >
          <stop stop-color="#0CC2FF" />
          <stop offset="1" stop-color="#8CF2B2" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YU6;
