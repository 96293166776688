import React from "react";
import "../Cardissuing/style.css";

const Body = () => {
  return (
    <>
      <div className="why-moneylink merchant-body">
        <h1>Why MoneyLink?</h1>
        <div className="prepaid-card why-moneylink-body merchant-card">
          <div className="cip-cards cardissu">
            <div className="cip-card-1">
              <div className="cip-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="38"
                  viewBox="0 0 28 38"
                  fill="none"
                >
                  <path
                    d="M17.8339 16.7414L19.8014 14.9925M19.8014 14.9925C19.8014 14.9925 23.9552 11.0573 26.1414 8.21523C28.3277 5.37316 25.2669 3.62419 23.0807 5.37315C20.8945 7.12211 8.87039 20.0207 7.99591 20.0207C7.12143 20.0207 6.03103 15.6484 4.71675 13.4621C3.40248 11.2759 1 11.7132 1 14.118C1 16.5228 1.00005 23.0814 1.00005 27.2352C1.00005 31.389 9.96349 38.074 14.3359 36.8545C18.7083 35.6351 21.3376 33.5718 25.2669 28.9842C26.9862 26.9769 27.3534 24.6115 25.3055 24.0115C24.7927 23.8613 24.377 22.8417 24.6573 22.3868C25.5783 20.8923 25.0712 19.4114 23.0437 19.466C22.4999 19.4806 21.9215 18.5828 22.163 18.0952C22.8497 16.7087 22.4948 15.4414 19.8014 14.9925Z"
                    stroke="#4D148C"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M17.1784 3.62305L14.7734 7.99546"
                    stroke="#FF5900"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M3.625 6.24609L7.34155 9.96264"
                    stroke="#FF5900"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M9.3125 1L10.8427 6.02827"
                    stroke="#FF5900"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
                <div className="lineparagrap">
                  <h6>Easy to Use</h6>
                </div>
                <p>
                  Easy to register and gain access. Accepts all Debit & Credit
                  Cards. Both contactless, Card Dipping & card swiping options
                  available.
                </p>
              </div>
              <div className="cip-card cardissu">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="38"
                  viewBox="0 0 37 38"
                  fill="none"
                >
                  <path
                    d="M3.01079 9.52857L17.8842 1.52408C18.4901 1.19796 19.2212 1.20628 19.8196 1.5461L33.9114 9.5486C35.2052 10.2833 35.2723 12.1232 34.0354 12.9503L19.9404 22.3755C19.2823 22.8155 18.4267 22.826 17.7581 22.4023L2.88805 12.9791C1.5961 12.1604 1.66393 10.2534 3.01079 9.52857Z"
                    stroke="#FF5900"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M5.79992 15.0156L2.89041 16.9131C1.66035 17.7153 1.68545 19.5254 2.93728 20.2932L17.3265 29.1186C17.9644 29.5099 18.7675 29.5122 19.4077 29.1247L34.0684 20.2511C35.3139 19.4973 35.3624 17.7078 34.1595 16.8877L31.4138 15.0156"
                    stroke="#4D148C"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M5.79992 22.0234L2.87963 23.928C1.65225 24.7284 1.67396 26.5335 2.92023 27.3042L17.5549 36.3546C18.1996 36.7533 19.0142 36.7533 19.6588 36.3546L34.1792 27.3749C35.4533 26.587 35.4408 24.7296 34.1563 23.9589L30.9305 22.0234"
                    stroke="#4D148C"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
                <div className="lineparagrap">
                  <h6>State-of-the-art tech stack</h6>
                </div>
                <p>
                  Software updates can be pushed remotely to quickly upgrade your device software.
                </p>
              </div>
              <div className="cip-card cardissu">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="37"
                  viewBox="0 0 29 37"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.8233 0.255827L27.6086 6.94605L27.739 7.01428L27.8442 7.11718C27.8483 7.12125 27.8542 7.12668 27.8614 7.13344C27.9703 7.23504 28.4025 7.63834 28.4025 8.27297V17.5948C28.4025 25.2517 23.7065 32.1637 16.5108 35.0859C16.5103 35.0861 16.5098 35.0863 16.5093 35.0865L14.6256 35.8591C14.3265 36.0448 14.0353 36.0424 13.9543 36.0418C13.9502 36.0418 13.9467 36.0417 13.9437 36.0417C13.9407 36.0417 13.9371 36.0418 13.933 36.0418C13.8527 36.0424 13.5648 36.0448 13.2679 35.8629L11.6416 35.2149L12.0118 34.2859L11.622 35.2068C4.55714 32.2169 0 25.3052 0 17.7838V8.27297C0 7.63835 0.432235 7.23504 0.541127 7.13344C0.548377 7.12668 0.554194 7.12125 0.558354 7.11718L0.658955 7.01878L0.782805 6.95197L13.249 0.226933C13.6541 -0.0173422 14.2908 -0.145061 14.8233 0.255827ZM12.392 33.3609L13.9411 33.9782L15.7537 33.2347L15.757 33.2334C22.2125 30.6125 26.4025 24.424 26.4025 17.5948V8.57222L14.0113 2.08818L2 8.5678V17.7838C2 24.4936 6.0679 30.68 12.392 33.3609ZM14.0728 1.47023L14.3094 1.59556L26.6735 8.14604L26.9394 8.28692V8.58786V17.6577C26.9394 24.733 22.6244 31.0217 15.938 33.7413L15.929 33.7449L15.9289 33.7448L14.1258 34.4377L13.9317 34.5123L13.742 34.4272L12.202 33.7368C5.71291 31.0141 1.46875 24.7273 1.46875 17.7837V8.58786H1.96875L1.73081 8.1481L13.8373 1.59763L14.0728 1.47023ZM2.46875 8.88582V17.7837C2.46875 24.317 6.46484 30.2476 12.5943 32.817L12.6056 32.8217L12.6055 32.8218L13.9613 33.4296L15.5657 32.8131C21.8848 30.241 25.9394 24.3118 25.9394 17.6577V8.8888L14.0778 2.60454L2.46875 8.88582Z"
                    fill="#FF5900"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.141 22.32L12.5274 22.005L20.7701 15.5175L20.1906 14.7617L12.2698 20.9973L8.8568 17.0922L8.14844 17.722L11.819 21.942L12.141 22.32Z"
                    fill="#2C007D"
                    stroke="#2C007D"
                  />
                </svg>
                <div className="lineparagrap">
                  <h6>Security</h6>
                </div>
                <p>
                  Secured by end-to-end encryption offering the highest level of
                  protection both for you as well as your customer.
                </p>
              </div>
            </div>
            <div className="cip-card-2">
              <div className="cip-card cardissu">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="36"
                  viewBox="0 0 42 36"
                  fill="none"
                >
                  <path
                    d="M4.36364 9.81641L5.34333 12.8316H8.5137L5.94882 14.6951L6.92852 17.7103L4.36364 15.8468L1.79876 17.7103L2.77845 14.6951L0.213572 12.8316H3.38394L4.36364 9.81641Z"
                    fill="#FF5900"
                  />
                  <path
                    d="M11.1839 2.45312L12.1636 5.46832H15.334L12.7691 7.33182L13.7488 10.347L11.1839 8.48352L8.61907 10.347L9.59877 7.33182L7.03388 5.46832H10.2043L11.1839 2.45312Z"
                    fill="#FF5900"
                  />
                  <path
                    d="M20.9964 0L21.9761 3.0152H25.1465L22.5816 4.87869L23.5613 7.89389L20.9964 6.0304L18.4316 7.89389L19.4113 4.87869L16.8464 3.0152H20.0168L20.9964 0Z"
                    fill="#FF5900"
                  />
                  <path
                    d="M30.8168 2.45312L31.7965 5.46832H34.9668L32.4019 7.33182L33.3816 10.347L30.8168 8.48352L28.2519 10.347L29.2316 7.33182L26.6667 5.46832H29.8371L30.8168 2.45312Z"
                    fill="#FF5900"
                  />
                  <path
                    d="M37.3636 9.81641L38.3433 12.8316H41.5137L38.9488 14.6951L39.9285 17.7103L37.3636 15.8468L34.7988 17.7103L35.7785 14.6951L33.2136 12.8316H36.3839L37.3636 9.81641Z"
                    fill="#FF5900"
                  />
                  <circle
                    cx="21.0057"
                    cy="23.1815"
                    r="11.8182"
                    stroke="#4D148C"
                    stroke-width="2"
                  />
                  <path
                    d="M12 31.637C18.4707 21.9897 30.2727 29.1824 29.1818 31.637C28.0909 34.0915 18.0356 37.5947 12 31.637Z"
                    fill="#4D148C"
                    stroke="#4D148C"
                    stroke-linecap="round"
                  />
                  <circle
                    cx="21.1321"
                    cy="19.4993"
                    r="4.77273"
                    fill="#4D148C"
                  />
                </svg>
                <div className="lineparagrap">
                  <h6>Superior Customer Experience</h6>
                </div>
                <p>
                  Simple & Customer friendly screen flow. Customer gets a
                  print-out receipt upon completion of transaction.
                </p>
              </div>
              <div className="cip-card cardissu">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    d="M18.0002 4.82297L18.0002 10.9303"
                    stroke="#4D148C"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M16.904 0.828794L13.5646 4.39081C13.1598 4.82253 13.1338 5.48604 13.5035 5.94814C13.8721 6.4089 14.5227 6.52997 15.0324 6.23265L17.9497 4.53085C17.9821 4.51199 18.0222 4.51276 18.0538 4.53286L20.6688 6.19697C21.1634 6.51171 21.8157 6.40332 22.1819 5.94554C22.5474 5.48866 22.511 4.83002 22.0972 4.41629L18.4843 0.803313C18.0449 0.363908 17.329 0.375451 16.904 0.828794Z"
                    fill="#4D148C"
                    stroke="#4D148C"
                    stroke-width="0.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M18.0002 31.1751L18.0002 25.0677"
                    stroke="#4D148C"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M16.904 35.1693L13.5646 31.6072C13.1598 31.1755 13.1338 30.512 13.5035 30.0499C13.8721 29.5891 14.5227 29.4681 15.0324 29.7654L17.9497 31.4672C17.9821 31.4861 18.0222 31.4853 18.0538 31.4652L20.6688 29.8011C21.1634 29.4863 21.8157 29.5947 22.1819 30.0525C22.5474 30.5094 22.511 31.168 22.0972 31.5818L18.4843 35.1947C18.0449 35.6341 17.329 35.6226 16.904 35.1693Z"
                    fill="#4D148C"
                    stroke="#4D148C"
                    stroke-width="0.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M30.8821 18.2913L24.7749 18.2535"
                    stroke="#4D148C"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M34.8806 17.2191L31.3393 13.8577C30.9101 13.4503 30.2467 13.4201 29.7824 13.7869C29.3193 14.1527 29.1942 14.8025 29.4884 15.314L31.1721 18.2419C31.1908 18.2744 31.1897 18.3145 31.1694 18.346L29.4892 20.9506C29.1714 21.4433 29.2757 22.0962 29.7312 22.4653C30.1858 22.8336 30.8447 22.8012 31.261 22.39L34.8963 18.7995C35.3384 18.3628 35.3313 17.6469 34.8806 17.2191Z"
                    fill="#4D148C"
                    stroke="#4D148C"
                    stroke-width="0.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M4.8523 18.2913L10.9595 18.2535"
                    stroke="#4D148C"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    d="M0.853818 17.2191L4.3951 13.8577C4.8243 13.4503 5.48764 13.4201 5.95201 13.7869C6.41505 14.1527 6.54014 14.8025 6.24599 15.314L4.56228 18.2419C4.54361 18.2744 4.54464 18.3145 4.56493 18.346L6.2452 20.9506C6.563 21.4433 6.45865 22.0962 6.00314 22.4653C5.54853 22.8336 4.88967 22.8012 4.4734 22.39L0.83812 18.7995C0.396004 18.3628 0.403114 17.6469 0.853818 17.2191Z"
                    fill="#4D148C"
                    stroke="#4D148C"
                    stroke-width="0.5"
                    stroke-linecap="round"
                  />
                  <ellipse
                    cx="18.0034"
                    cy="18.3218"
                    rx="3.21438"
                    ry="3.21438"
                    fill="#FF5900"
                  />
                </svg>
                <div className="lineparagrap">
                  <h6>Handy POS device</h6>
                </div>
                <p>
                  Compact device that you can carry along to customers for
                  accepting payments. Suitable for all small & big businesses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
