function QrScanIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5006 17.8692H1.96484V1.86914H17.9649V12.7983"
        stroke="#21366E"
        stroke-linecap="square"
      />
      <path
        d="M20 16L17.9027 19.2486C17.8082 19.3943 17.6639 19.4854 17.5071 19.4984C17.3503 19.5114 17.1963 19.4451 17.0848 19.3165L16 18.0584"
        stroke="#3FAFE9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 18C13 15.2386 15.2386 13 18 13C20.7614 13 23 15.2386 23 18C23 20.7614 20.7614 23 18 23C15.2386 23 13 20.7614 13 18Z"
        stroke="#21366E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.20312 13.8867V14.7071C5.20312 14.9372 5.38967 15.1238 5.61979 15.1238H6.44021"
        stroke="#21366E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.44021 5.20703H5.61979C5.38967 5.20703 5.20312 5.39358 5.20312 5.6237V6.44412"
        stroke="#21366E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.7879 6.44412V5.6237C14.7879 5.39358 14.6013 5.20703 14.3712 5.20703H13.5508"
        stroke="#21366E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.28516 7.45703H9.53516V9.70703H7.28516V7.45703Z"
        stroke="#21366E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4531 7.45703H13.7031V9.70703H11.4531V7.45703Z"
        stroke="#21366E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.28516 11.623H9.53516V13.873H7.28516V11.623Z"
        stroke="#21366E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.4961 11.498V11.998"
        stroke="#21366E"
        stroke-linecap="square"
      />
      <path
        d="M11.4961 13.498V13.998"
        stroke="#21366E"
        stroke-linecap="square"
      />
      <path
        d="M13.4961 13.498V13.998"
        stroke="#21366E"
        stroke-linecap="square"
      />
      <path
        d="M13.4961 11.498V11.998"
        stroke="#21366E"
        stroke-linecap="square"
      />
      <path
        d="M12.4961 12.498V12.998"
        stroke="#21366E"
        stroke-linecap="square"
      />
    </svg>
  );
}

export default QrScanIcon;
