import React from "react";
import "./style.css";
import Navbar from "../../../Navbar/Solution";
import Footer from "../../../Footer/Productfooter";
import Hero from "./Hero";
import Body from "./Body";

const Main = () => {
  return (
    <div id="OverView">
      <Navbar />
      <Hero />
      <Body />
      <Footer />
    </div>
  );
};

export default Main;
