import React from "react";

function ScanAndPay() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="31"
      fill="none"
      viewBox="0 0 38 31"
    >
      <path
        stroke="#20336B"
        strokeLinecap="square"
        d="M18.324 21.133H.79h3.247L4.136 1h13.989l.2 20.783v-.65z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.523 6.328h2.124v2.123H6.523V6.328zM13.598 6.328h2.123v2.123h-2.123V6.328zM6.523 13.406h2.124v2.123H6.523v-2.123z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.598 13.406v2.123h2.123v-2.123h-.708M10.059 8.097V6.328h2.123M12.184 7.744v2.477M6.523 11.99h3.54v-1.77M10.059 13.406v2.123h2.123M11.48 13.406h.708M6.523 9.867h2.124M15.72 9.867h-2.122v.708M11.48 11.99h4.247M37.212 30.054l-2.676-3.747V21.49c.045-1.904-2.228-3.6-4.282-5.353M30.584 23.63l-2.88-2.963a1.927 1.927 0 00-2.16 0 1.134 1.134 0 000 1.606l3.414 3.498v1.606c0 1.03 1.671 2.677 1.671 2.677"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.433 24.701h-7.599c-.322 0-.584-.24-.584-.535v-18.2c0-.295.262-.534.584-.534h10.522c.323 0 .584.24.584.535v13.382"
      ></path>
      <path
        stroke="#00BAF2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.852 11.36h1.443v1.443h-1.443v-1.444zM20.852 16.41h1.443v1.443h-1.443V16.41zM25.898 11.36h1.444v1.443h-1.444v-1.444z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#00BAF2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.852 14.967h2.886v.721M25.176 15.69v2.164h2.165V15.69h-.722M23.738 17.133v.721M23.738 11.36v2.164h.722M25.898 14.246h1.444"
      ></path>
    </svg>
  );
}

export default ScanAndPay;
