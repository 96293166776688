import React from "react";

function BillPayment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        stroke="#223872"
        d="M9.383 5.607v5.88M11.633 5.607v4.994c0 .11.09.2.2.2h2.017M15.262 5.607v4.994c0 .11.09.2.2.2h2.017"
      ></path>
      <path
        stroke="#223872"
        strokeLinecap="round"
        d="M19.671 11.02V1.502a.2.2 0 00-.345-.138l-1.894 1.99a.2.2 0 01-.29 0l-2.076-2.182a.2.2 0 00-.305.017l-1.622 2.13a.2.2 0 01-.304.017l-2.06-2.164a.2.2 0 00-.304.017L8.865 3.297a.2.2 0 01-.318 0L6.94 1.19a.2.2 0 00-.304-.017L4.56 3.354a.2.2 0 01-.29 0l-1.894-1.99a.2.2 0 00-.345.138v21.466a.2.2 0 00.357.124l1.87-2.358a.2.2 0 01.314 0l2.052 2.587a.2.2 0 00.326-.017l1.587-2.501a.2.2 0 01.338 0l1.6 2.523a.2.2 0 00.314.03l2.684-2.82"
      ></path>
      <mask id="path-5-inside-1_3806_11488" fill="#fff">
        <path
          fillRule="evenodd"
          d="M4.281 6.407a.8.8 0 01.8-.8h2.075a.8.8 0 01.8.8v4.378a.8.8 0 01-.8.8H5.081a.8.8 0 01-.8-.8V6.407zm.91.026h1.837V8.27H5.19V6.433zm1.837 2.552H5.19v1.837h1.838V8.985z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="#223872"
        fillRule="evenodd"
        d="M4.281 6.407a.8.8 0 01.8-.8h2.075a.8.8 0 01.8.8v4.378a.8.8 0 01-.8.8H5.081a.8.8 0 01-.8-.8V6.407zm.91.026h1.837V8.27H5.19V6.433zm1.837 2.552H5.19v1.837h1.838V8.985z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#223872"
        d="M7.028 6.433h1v-1h-1v1zm-1.838 0v-1h-1v1h1zM7.028 8.27v1h1v-1h-1zm-1.838 0h-1v1h1v-1zm0 .715v-1h-1v1h1zm1.838 0h1v-1h-1v1zM5.19 10.822h-1v1h1v-1zm1.838 0v1h1v-1h-1zM5.08 4.607a1.8 1.8 0 00-1.8 1.8h2a.2.2 0 01-.2.2v-2zm2.075 0H5.081v2h2.075v-2zm1.8 1.8a1.8 1.8 0 00-1.8-1.8v2a.2.2 0 01-.2-.2h2zm0 4.378V6.407h-2v4.378h2zm-1.8 1.8a1.8 1.8 0 001.8-1.8h-2c0-.11.09-.2.2-.2v2zm-2.075 0h2.075v-2H5.081v2zm-1.8-1.8a1.8 1.8 0 001.8 1.8v-2c.11 0 .2.09.2.2h-2zm0-4.378v4.378h2V6.407h-2zm3.747-.974H5.19v2h1.838v-2zm1 2.837V6.433h-2V8.27h2zm-2.838 1h1.838v-2H5.19v2zm-1-2.837V8.27h2V6.433h-2zm1 3.552h1.838v-2H5.19v2zm1 .837V8.985h-2v1.837h2zm.838-1H5.19v2h1.838v-2zm-1-.837v1.837h2V8.985h-2z"
        mask="url(#path-5-inside-1_3806_11488)"
      ></path>
      <path
        stroke="#223872"
        strokeLinecap="round"
        d="M5.07 13.742h5.88M5.07 16.682h5.88"
      ></path>
      <circle cx="18.2" cy="17.62" r="5.38" stroke="#223872"></circle>
      <path
        stroke="#00BAF2"
        strokeLinecap="round"
        d="M15.559 18.251l1.818 1.819a.2.2 0 00.283 0l3.289-3.289"
      ></path>
    </svg>
  );
}

export default BillPayment;
