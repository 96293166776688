/* React packages  */
import React from "react";
import { Link } from "react-router-dom";
import CreditUW from "./CreditUW";
import ViewDetails from "./ViewDetails";

/**
  * LoanRequestView1 component is render all data in Credit-UW view-details section.
  * <p>There are three type of Data will be there :</p>
  * <li>Moneylink Approval status.</li>
  * <li>Bureau Reports Attachment.</li>
  * <li>Approvers Decision.</li>

  <br>
  * <h6>Below Html elemensts are created to use for  UI</h6>
  * <li> <b> Section :- </b>it is used for represents a standalone section</li>
  * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
  * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
  * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
  <br>
  * <h6>Below Bootstrap class is use for ui responsive.</h6>
  * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
  * <li> <b>row :-</b> This class to create a structure.</li>
  * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
  * <li> <b>ReactTooltip :-</b> This tooltip is a small pop up that appears when user places the mouse pointer over an element such as link or buttons to provide hint or information about the element being hovered.</li>

  @Author : Seema Kumari
  @version :1.0
 */

class LoanRequestView1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        age: "",
        ageEligibility: "",
        amount: "",
        amountEligibility: "",
        amountRequestedELigibility: "",
        approvedInterestRate: "",
        approverDecision: "",
        avgSalary: "",
        avgSalaryInterestMult: "",
        avgSalaryTenorMult: "",
        bankAcctNumber: "",
        bankCustomerId: "",
        bankName: "",
        baseInterestRate: "",
        baseTenor: "",
        bureauReportUrl1: "",
        bureauReportUrl2: "",
        bureauReportUrl3: "",
        bureauScoreIntMult: "",
        bvn: "",
        compCat: "",
        companyCatIntMult: "",
        creditBureauDataDto: "",
        customerName: "",
        customerType: "",
        debitBurdenAllowedAmt: "",
        debtBurdenAllowPercent: "",
        deleiquencyStatusIntMut: "",
        emi: "",
        empOrBusinesssName: "",
        finalTenor: "",
        lastSalary: "",
        loanId: "",
        loanProdName: "",
        loanReqDate: "",
        loanStatus: "",
        maxAge: "",
        maxLoanAmtCap: "",
        minAge: "",
        minLoanAmt: "",
        mobile: "",
        numberOfYrsEmpIntMult: "",
        numofYrsWithCurrEmplyIntMult: "",
        payLinkDecision: "",
        payLinkDecisionDate: "",
        payLinkId: "",
        totalWorkExp: "",
        totalWorkExpWithEmployer: "",
      },
      fields: {},
      reqstatus: "",
      isDisabled: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  /**
   * This method is used to fetch the details before the page is loaded by giving hit to the server
   */
  componentDidMount() {
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL(
      "https://api.montra.org/moneylinkweb/api/credituw/loanapplication?loanid=" +
        localStorage.getItem("loanid") +
        "&email=" +
        localStorage.getItem("email")
    );
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          alert("server error");
        }
      })
      .then((contents) => {
        this.setState({ data: contents.data });
      });
  }

  handleChangepin(e) {
    this.props.handleChangepin(e);
  }

  /**
   *
   * @param {Event} e to capture rea
   */

  handleChange(e) {
    this.props.onchange(e);
    console.log(this.props.statusapproval);
  }

  /**
   *
   * @param {Event} e Submits data to server on click of submit in credit-UW section
   */
  handleSubmit(e) {
    e.preventDefault();
    this.props.onsubmit(e);
    console.log("props log from loanrequest3:", this.props);
  }

  /**
   *
   * @param {Event} e captures the value for submit the request to server and download the report.
   */
  downloadreport(e) {
    e.preventDefault();
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = new URL(
      "https://api.montra.org/moneylinkweb/api/loanportfolio/downloadbureaureport?email=" +
        localStorage.getItem("email") +
        "&filename=APPS_stacklabs.pdf"
    );
    fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          alert("server error");
        }
      })
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "&filename=APPS_stacklabs.pdf";
        a.click();
      })
      .catch(() =>
        console.log("Can’t access " + url + " response. Blocked by browser?")
      );
  }

  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "CREDIT-UW" == localStorage.getItem("role")
    ) {
      return (
        <div>
          <div className="row">
            <div className="col-sm-12 col-md-4 col-xl-2 viewlrequest1">
              <p className="viewheader">MONEYLINK APPROVAL STATUS</p>

              <form className="gridview3">
                <div className="form-group">
                  <label className="lablename">Loan Request Date</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lendingcompanyname"
                    placeholder={this.state.data.loanReqDate}
                  />
                </div>

                <div className="form-group">
                  <label className="lablename">MoneyLink Decision Date</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lendingcompanyname"
                    placeholder={this.state.data.payLinkDecisionDate}
                  />
                </div>

                <div className="form-group">
                  <label className="lablename">MoneyLink Decision </label>
                  <p to="" className="btn btn-primary Approved">
                    {" "}
                    {this.state.data.payLinkDecision}{" "}
                  </p>
                </div>
              </form>
            </div>

            <div className="col-sm-12 col-md-4  col-xl-2 viewlrequest2">
              <p className="viewheader">BUREAU REPORTS - ATTACHMENTS </p>
              <div className="gridview4">
                <form className="formpdf">
                  <div className="d-flex flex-row secform2">
                    <div className="p-2 viewgridrequest">
                      <div className="form-group">
                        <button className="pdfview">
                          <i
                            className="far fa-file-pdf"
                            onClick={this.downloadreport}
                          ></i>{" "}
                        </button>{" "}
                        <br />
                        <p className="lablename pdfdown">
                          Credit <br /> Bureau <br /> Name
                        </p>
                      </div>
                    </div>

                    <div className="p-2 viewgridrequest">
                      <div className="form-group">
                        <button className="pdfview">
                          {" "}
                          <i
                            className="far fa-file-pdf"
                            onClick={this.downloadreport}
                          ></i>{" "}
                        </button>{" "}
                        <br />
                        <p className="lablename pdfdown">
                          Credit <br /> Bureau <br />
                          Name
                        </p>
                      </div>
                    </div>

                    <div className="p-2 viewgridrequest">
                      <div className="form-group">
                        <button className="pdfview">
                          {" "}
                          <i
                            className="far fa-file-pdf"
                            onClick={this.downloadreport}
                          ></i>{" "}
                        </button>{" "}
                        <br />
                        <p className="lablename pdfdown">
                          Credit <br /> Bureau <br /> Name
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-sm-12 col-md-4 col-xl-2 viewlrequest3">
              <p className="viewheader" name="status">
                APPROVER’S DECISION{" "}
              </p>
              <div className="gridview5">
                <form className="formflex">
                  <div className="form-group toggleview">
                    {(() => {
                      switch (this.props.path) {
                        case "LoanRequest":
                          return (
                            <label className="switchloan ">
                              <input
                                type="checkbox"
                                id="togBtn"
                                onChange={this.handleChange.bind(this)}
                                checked={this.props.status}
                                disabled={this.props.statusdisable}
                              />
                              <div className="sliderview round"></div>
                            </label>
                          );
                        default:
                          return (
                            <label className="switchloan ">
                              <input
                                type="checkbox"
                                id="togBtn"
                                onChange={this.handleChange.bind(this)}
                                checked={this.props.statusapproval}
                                disabled={this.props.statusdisable}
                              />
                              <div className="sliderview round"></div>
                            </label>
                          );
                      }
                    })()}{" "}
                  </div>
                </form>
              </div>
            </div>

            {(() => {
              switch (this.props.path) {
                case "LoanRequest":
                  return (
                    <div className="col-sm-12 col-md-3  viewlrequest4">
                      <div className="collectioncardchecker">
                        <form method="post" name="userRegistrationForm">
                          <div className="form-group ">
                            <label>Type Your User PIN </label>
                            <input
                              type="password"
                              className="form-control cardinputfield"
                              name="pin"
                              onChange={this.handleChangepin.bind(this)}
                              placeholder="xxxxx "
                            />
                            <div className="sign">
                              <input
                                type="submit"
                                onClick={this.handleSubmit.bind(this)}
                                className="btn btn-warning creditbutton"
                                value="Submit changes for approval"
                                disabled={this.state.isDisabled}
                              />
                              {(() => {
                                switch (this.props.message) {
                                  case "SUCCESS":
                                    return (
                                      <p className="submitted">
                                        {" "}
                                        Submitted Succesfully
                                      </p>
                                    );
                                  default:
                                    return <p></p>;
                                }
                              })()}
                              {(() => {
                                switch (this.props.message) {
                                  case "UNSUCCESS":
                                    return (
                                      <p className="invalidpin">Invalid Pin</p>
                                    );
                                  default:
                                    return <p></p>;
                                }
                              })()}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  );
                default:
                  return <p></p>;
              }
            })()}
          </div>
        </div>
      );
    } else {
      return <p>Please Login AS CREDITUW</p>;
    }
  }
}

export default LoanRequestView1;
