import React from "react";
import supplychain from "../../../assets/images/uday/supplychain.webp";
import montralogosupplychain from "../../../assets/images/supplylogo.webp";
import "./style.css";
import SupplyProcessContainer from "../../Solutions/Supplychain/Overview/SupplyProcessContainer";

const Main = () => {
  return (
    <>
      <div className="home-supplychain supplychain ">
        <div className="row">
          <div className="col duo-col">
            <div className="sol-logo">
              <img
                src={montralogosupplychain}
                alt="supplylogo"
                className="homesupplychain"
              />
            </div>
            <h2 className="home-supplychain-h3">
              Streamline B2B Payouts With Dedicated Supply Chain & Payment
              Processing Solution
            </h2>

            <p>
              Unlock powerful supply chain payment capabilities on a fully
              digital platform that enables:
            </p>
            <ul>
              <li>Collection of B2B payments digitally</li>
              <li>Collection of Credit based sales</li>
              <li>Tracking of  Due & Collected payments</li>
              <li>
                Launching & managing digital sales promotions for Primary,
                Secondary as well as Tertiary sales
              </li>
              <li>
                Distribution analytics thereby  helping in assessing SKU level
                stock availability in the outlets under direct distribution as
                well as those where Montra App is being used for selling the
                SKUs to outlets under indirect distribution
              </li>
            </ul>
            <a href="/Solutions/Supplychain/overview">
              <button>
                Explore Montra Supply Chain Solutions{" "}
                <i class="bi bi-chevron-right"></i>
              </button>
            </a>
            {/* <img
              className="supplychain-lastimg"
              src={supplychainimg}
              alt="image"
            /> */}
            <div className="supplychain-lastimg">
              <SupplyProcessContainer hometopDescription="supply-topdescription" />
            </div>
          </div>
          <div className="col supplychain-right">
            <img src={supplychain} alt="supplychain" width="600" height="400" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
