import React from "react";

/**
 *  CollectionCheckerLoan component is render upper part of underwriting rules(Average Salary - Tenor Multiplier) of bank for checker section.
 *   <p>Allows checker to approve or reject by binding </p>
 *  @Author : Thrilok Kandra
 *  @version :1.0
 */

class CheckerWrittingPart3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
    };
  }
  render() {
    console.log("props from checkwritting part3", this.props);
    return (
      <div>
        <form method="post" name="userRegistrationForm" className="formroot">
          <div className="unwrite">
            <div className="d-flex flex-row mt-3 ">
              <div className="p-2 headerbuttons">
                <div className="input-group Multiplier2">
                  <p className="text2part">
                    Multiplier <br /> Status
                  </p>
                  <div className="form-group aligncenter inputgraygl">
                    <label type="text" className="form-control formpprofilecuw">
                      {this.props.data[0]?.avgSalTenorMultiplierStatus}{" "}
                    </label>
                    <label className="switchtoggle">
                      <input
                        type="checkbox"
                        name="avgSalTenorMultiplierStatus"
                        checked={
                          this.props.underWritingRulesstatus[0]
                            ?.avgSalTenorMultiplierStatus
                        }
                        onChange={(e) => this.props.toggleChange(e)}
                      />
                      <div className="checkerslider round"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div className="p-2 headertext">
                <p className="textposition">
                  Average Salary - Tenor Multiplier
                </p>
                <p className="textlabel">Average Monthly Salary Bands</p>
              </div>
            </div>

            <div className="d-flex flex-row mt-3 padmar ">
              <div className="p-2 underwritepart3">
                <div className="form-group">
                  <label className="labelpt-ch">Product</label>
                  {this.props.data.map((underWritingRules, i) => (
                    <div key={i}>
                      <label
                        className="form-control loanlabelcheck"
                        name="paydayloan"
                        id="field1"
                        disabled
                      >
                        {underWritingRules.productName}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    {" "}
                    {"<"}200K
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isAvgSalaryMultLT200000fieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalaryMultLT200000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalaryMultLT200000}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalaryMultLT200000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalaryMultLT200000(
                              e,
                              i
                            );
                          }}
                          checked={underWritingRules.isAvgSalaryMultLT200000}
                          disabled={this.props.isAvgSalaryMultLT200000list[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isAvgSalaryMultLT200000classlist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    200K – 500K
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props
                            .isAvgSalaryMultBET200000n500000fieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalaryMultBET200000n500000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalaryMultBET200000n500000}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalaryMultBET200000n500000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalaryMultBET200000n500000(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalaryMultBET200000n500000
                          }
                          disabled={
                            this.props.isAvgSalaryMultBET200000n500000list[i]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props
                                .isAvgSalaryMultBET200000n500000classlist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    500K – 1M
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props
                            .isAvgSalaryMultBET500001n1000000fieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalaryMultBET500001n1000000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalaryMultBET500001n1000000}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalaryMultBET500001n1000000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalaryMultBET500001n1000000(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalaryMultBET500001n1000000
                          }
                          disabled={
                            this.props.isAvgSalaryMultBET500001n1000000list[i]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props
                                .isAvgSalaryMultBET500001n1000000classlist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    1M - 2M
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props
                            .isAvgSalaryMultBET1000001n2000000fieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalaryMultBET1000001n2000000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalaryMultBET1000001n2000000}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalaryMultBET1000001n2000000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalaryMultBET1000001n2000000(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalaryMultBET1000001n2000000
                          }
                          disabled={
                            this.props.isAvgSalaryMultBET1000001n2000000list[i]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props
                                .isAvgSalaryMultBET1000001n2000000classlist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    {" "}
                    {">"}2M{" "}
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isAvgSalaryMultGT2000000fieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalaryMultGT2000000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalaryMultGT2000000}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalaryMultGT2000000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalaryMultGT2000000(
                              e,
                              i
                            );
                          }}
                          checked={underWritingRules.isAvgSalaryMultGT2000000}
                          disabled={this.props.isAvgSalaryMultGT2000000list[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isAvgSalaryMultGT2000000classlist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    {" "}
                    Bank Staff{" "}
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isAvgSalTenorBankStaffvaluefieldclasslist[
                            i
                          ],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalTenorBankStaffvaluesa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalTenorBankStaffvalue}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalTenorBankStaffvalue"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalTenorBankStaffvalue(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalTenorBankStaffvalue
                          }
                          disabled={
                            this.props.isAvgSalTenorBankStaffvaluelist[i]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isAvgSalTenorBankStaffvalueclasslist[
                                i
                              ],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CheckerWrittingPart3;
