/* React packages */
import React from "react";
import { Form, FormControl } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import "../moneylinkCentral.css";
import "../Maker/MakerStyle.css";

/**
 * Header component is render navigation in Credit-UW section.
 * <p>There are three navigation menu will be there:</p>
 * <li>Loan Requests</li>
 * <li>Loan Approved</li>
 * <li>Loan Rejects</li>
 <br>
 * <h6> React Bootstrap Navigation ui properties :- </h6>
 * <li> <b>Navbar:- </b>A navigation bar is a navigation header that is placed at the top of the page.</li>
 * <li> <b>Navbar.Collapse:- </b>it is use for grouping and hiding navbar contents by a parent breakpoint.</li>
 * <li> <b>NavLink:- </b>it will add styling attributes to the rendered element when it matches the current URL.</li>

 @Author : Seema Kumari
 @version :1.0
 */

const Header = () => {
  return (
    // <div className="montra-sidebar-header">
    //   <Navbar
    //     className="navbar navbar-expand-lg navbar-light bg-dark nav-header montra-sidebar"
    //     bg="light"
    //     expand="lg"
    //   >
    //     <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //     <Navbar.Collapse id="basic-navbar-nav">
    //       <Nav className="link-container">
    //         <Nav.Link as={NavLink} to="/LoanRequest" className="userlink">
    //           {" "}
    //           Loan Requests
    //         </Nav.Link>
    //         <Nav.Link as={NavLink} to="LoanApproved" className="userlink">
    //           Loan Approved
    //         </Nav.Link>
    //         <Nav.Link as={NavLink} to="LoanRejects" className="userlink">
    //           Loan Rejects{" "}
    //         </Nav.Link>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Navbar>
    // </div>
    <div className="link-container">
      <NavLink to="/LoanRequest" className="userlink">
        Loan Requests
      </NavLink>
      <NavLink to="/LoanApproved" className="userlink">
        Loan Approved
      </NavLink>
      <NavLink to="/LoanRejects" className="userlink">
        Loan Rejects
      </NavLink>
    </div>
  );
};

export default Header;
