/* React packages and components */
import React, { Component } from "react";
import Sidebar from "./Sidebar";
import "./SalesStyle.css";
import bsCustomFileInput from "bs-custom-file-input";
import CommonHeader from "../header/CommonHeader";
import { api } from "../../utilities/api";
import ForgotPin from "../ForgotPin/ForgotPin";
import { Navigate } from "react-router-dom";

/**
 * Description:This Origination component is rendering all data for Sales Origination section.
 * <li>"Sales" can Click on "Loan Origination Request" section and click on "BROWSE" and selects the Historical salary data file &
 submits it after entering the User PIN and clicking on "UPLOAD" </li>
 * <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>


 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
 * <li> <b>checkbox :- </b> use the checkbox class if you want the user to select any number of options from a list</li>

 * @Author : Seema Kumari
 * @version :1.0
 */

class Origination extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      fields: {
        customerId: "",
        accountNumber: "",
        termsAndConditions: "",
        productName: "",
      },
      file: null,
      fileuploaded: false,
      errorMsg: "",
      errorMsgotp: "",
      errors: {},
      backerrors: [],
      eligblityMessage: "",
      eligblitystatus: "",
      isplchecked: false,
      issachecked: false,
      isstplchecked: false,
      ispslchecked: false,
      isalcheked: false,
      ishlchecked: false,
      islapchecked: false,
      istacAccepted: false,
      resendOtpStatus: "",
      resendOtpMessage: "",
      data: {
        data: {
          customerId: "",
          accountNumber: "",
          productName: "",
          eligibilityAmount: "",
          disbursementProcess: "",
          amountForDisbursement: "",
          loanTenor: "",
          feeToBank: "",
          creditLifeInsurance: "",
          interest: "",
          latePaymentFee: "",
          payLinkFee: "",
        },
      },
      status: "",
      lasterrorMsg: "",
      filemessage: "",
      lastmsg: "",
      fileupload: true,
      attempts: 0,
      redirect: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangesa = this.handleChangesa.bind(this);
    this.handleChangepl = this.handleChangepl.bind(this);
    this.handleChangestpl = this.handleChangestpl.bind(this);
    this.handleChangepsl = this.handleChangepsl.bind(this);
    this.handleChangeal = this.handleChangeal.bind(this);
    this.handleChangehl = this.handleChangehl.bind(this);
    this.handleChangelap = this.handleChangelap.bind(this);
    this.accepttermAndConditions = this.accepttermAndConditions.bind(this);
    this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
  }

  /**
   *
   * @param {Event} e captures the value for pin on onChange event
   */
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }
  /**
   *
   * @param {Event} e accept the terms and Conditions.
   */
  accepttermAndConditions(e) {
    let fields = this.state.fields;
    let istacAccepted = this.state.istacAccepted;
    this.state.istacAccepted = !this.state.istacAccepted;
    if (this.state.istacAccepted) {
      fields[e.target.name] = "accepted";
      this.setState({
        istacAccepted: true,
        errors: {
          termsAndConditions: "",
        },
      });
    } else {
      fields[e.target.name] = "";
      this.setState({
        istacAccepted: false,
        errors: {
          termsAndConditions: "",
        },
      });
    }
  }

  /**
   * This will validate the fields.
   * No Parameters for this method.
   */
  validateForm() {
    let fields = this.state.fields;

    let errors = {};
    let formIsValid = true;

    if (fields.customerId == null || fields.customerId == "") {
      formIsValid = false;
      errors["customerId"] = "*Please enter customerId.";
    }
    if (fields.accountNumber == "" || fields.accountNumber == null) {
      formIsValid = false;
      errors["accountNumber"] = "*Please enter accountNumber.";
    }
    if (fields.productName == "" || fields.accountNumber == null) {
      formIsValid = false;
      errors["productName"] = "*Please select productName.";
    }
    let regex = /^[1-9]{1}[0-9]{9}$/;
    if (
      fields.mobile == "" ||
      fields.mobile == null ||
      !regex.test(fields.mobile) ||
      10 != fields.mobile.length
    ) {
      formIsValid = false;
      errors["mobile"] = "*Please enter valid Phone Number.";
    }
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (
      fields.email == "" ||
      fields.email == null ||
      !pattern.test(fields.email)
    ) {
      formIsValid = false;
      errors["email"] = "*Please enter valid email.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  /**
   * This will validate the otp field.
   * No Parameters for this method.
   */
  otpSubmitvalidateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (fields.otp == "" || fields.otp == null) {
      formIsValid = false;
      errors["otp"] = "*Please enter otp.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  /**
   * This will validate the terms and conditions fields.
   * No Parameters for this method.
   */
  termsAndConditionsSubmitvalidateForm() {
    let fields = this.state.fields;

    let errors = {};
    let formIsValid = true;

    if (fields.termsAndConditions == null || fields.termsAndConditions == "") {
      formIsValid = false;
      errors["termsAndConditions"] = "*Please accept termsAndConditions.";
    }
    if (!this.state.fileuploaded) {
      formIsValid = false;
      errors["fileuplod"] = "*Please upload the file.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  /**
   *
   * @param {Event} e captures the value for resend the otp
   */
  resendOtp(e) {
    e.preventDefault();
    if (this.termsAndConditionsSubmitvalidateForm()) {
      // const proxyurl = "https://cors-anywhere.herokuapp.com/";
      let url = "api/user/sendotp";
      let params = {
        email: localStorage.getItem("email"),
      };
      api
        .get(url, {
          headers: { "Content-Type": "application/json;charset=utf-8" },
          params: params,
        })
        .then((response) => {
          if (response.status === 200 && response.data.message === "SUCCESS") {
            this.setState({
              resendOtpStatus: response.data.status,
              resendOtpMessage: response.data.message,
            });
          } else {
            this.setState({
              backerrors: response.data.errors,
              status: response.data.status,
              otpmessage: response.data.message,
            });
            this.state.backerrors.map((key) => {
              this.setState({
                errorMsg: key.message,
              });
            });
          }
        })
        .catch(() =>
          console.log("Can’t access " + url + " response. Blocked by browser?")
        );
    }
  }

  /**
   * This will validate the fields of loan Eligiblity section in sales section.
   * No Parameters for this method.
   */
  loanEligiblitySubmitvalidateForm() {
    let fields = this.state.fields;

    let errors = {};
    let formIsValid = true;

    if (fields.lastotp == "" || fields.lastotp == null) {
      formIsValid = false;
      errors["lastotp"] = "*Please enter otp.";
    }
    if (fields.pin == "" || fields.pin == null) {
      formIsValid = false;
      errors["pin"] = "*Please enter pin.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  /**
   *
   * @param {Event} e Submits data to server on click of submit in sales  section
   */
  handleSubmit = (e) => {
    e.preventDefault();
    let fields = this.state.fields;
    if (this.validateForm()) {
      let proxyurl = "https://cors-anywhere.herokuapp.com/";
      let url = "api/loanorigination/loanoriginationreq";

      let params = {
        email: localStorage.getItem("email"),
      };

      api
        .post(url, fields, {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          params: params,
        })
        .then((response) => {
          if (response.status === 200 && response.data.message === "SUCCESS") {
            this.setState({
              eligblityMessage: response.data.message,
              eligblitystatus: response.data.status,
            });
          } else return "server error";
        })
        .catch(() =>
          console.log("Can’t access " + url + " response. Blocked by browser?")
        );
      // alert('Form submitted');
    }
  };

  /**
   *
   *
   * @param {Event} e  Captures the boolean value for products value.
   */
  handleChangesa(e) {
    let fields = this.state.fields;
    fields[e.target.name] = "salaryadvance";
    this.setState({
      isalchecked: false,
      ishlchecked: false,
      islapchecked: false,
      isplchecked: false,
      ispslchecked: false,
      isstplchecked: false,
      issachecked: true,
      fields,
    });
  }
  /**
   *
   *
   * @param {Event} e  Captures the boolean value for products value.
   */

  handleChangepl(e) {
    let fields = this.state.fields;
    fields[e.target.name] = "paydayloan";
    this.setState({
      isalchecked: false,
      ishlchecked: false,
      islapchecked: false,
      isplchecked: true,
      ispslchecked: false,
      isstplchecked: false,
      issachecked: false,
      fields,
    });
  }
  /**
   *
   *
   * @param {Event} e  Captures the boolean value for products value.
   */

  handleChangestpl(e) {
    let fields = this.state.fields;
    fields[e.target.name] = "stpl";

    this.setState({
      isalchecked: false,
      ishlchecked: false,
      islapchecked: false,
      isplchecked: false,
      ispslchecked: false,
      isstplchecked: true,
      issachecked: false,
      fields,
    });
  }
  /**
   *
   *
   * @param {Event} e  Captures the boolean value for products value.
   */

  handleChangepsl(e) {
    let fields = this.state.fields;
    fields[e.target.name] = "personalloan";
    this.setState({
      isalchecked: false,
      ishlchecked: false,
      islapchecked: false,
      isplchecked: false,
      ispslchecked: true,
      isstplchecked: false,
      issachecked: false,
      fields,
    });
  }
  /**
   *
   *
   * @param {Event} e  Captures the boolean value for products value.
   */

  handleChangeal(e) {
    let fields = this.state.fields;
    fields[e.target.name] = "autoloan";
    this.setState({
      isalchecked: true,
      ishlchecked: false,
      islapchecked: false,
      isplchecked: false,
      ispslchecked: false,
      isstplchecked: false,
      issachecked: false,
      fields,
    });
  }
  /**
   *
   *
   * @param {Event} e  Captures the boolean value for products value.
   */

  handleChangehl(e) {
    let fields = this.state.fields;
    fields[e.target.name] = "homeloan";
    this.setState({
      isalchecked: false,
      ishlchecked: true,
      islapchecked: false,
      isplchecked: false,
      ispslchecked: false,
      isstplchecked: false,
      issachecked: false,
      fields,
    });
  }
  /**
   *
   *
   * @param {Event} e  Captures the boolean value for products value.
   */

  handleChangelap(e) {
    let fields = this.state.fields;
    fields[e.target.name] = "loanagainstpro";
    this.setState({
      isalchecked: false,
      ishlchecked: false,
      islapchecked: true,
      isplchecked: false,
      ispslchecked: false,
      isstplchecked: false,
      issachecked: false,
      fields,
    });
  }
  /**
   * This method is used to fetch the details before the page is loaded by giving hit to the server
   */
  componentDidMount() {
    bsCustomFileInput.init();
  }
  /**
   *
   * @param {Event} e Submits otp data to server on click of submit otp in sales section
   */

  handleOtpSubmit = (e) => {
    e.preventDefault();
    if (this.otpSubmitvalidateForm()) {
      let fields = this.state.fields;

      let proxyurl = "https://cors-anywhere.herokuapp.com/";
      let url = "api/loanorigination/verifyotp";

      let params = {
        email: localStorage.getItem("email"),
        otp: this.state.fields.otp,
      };
      api
        .post(url, fields, {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          params: params,
        })
        .then((response) => {
          if (response.status === 200 && response.data.message === "SUCCESS") {
            this.setState({
              data: response.data.data,
              status: response.data.status,
              message: response.data.message,
            });
          } else {
            this.setState({
              backerrors: response.data.errors,
              status: response.data.status,
              message: response.data.message,
            });
            this.state.backerrors.map((key) => {
              this.setState({
                errorMsgotp: key.message,
              });
            });
          }
        })
        .catch(() =>
          console.log("Can’t access " + url + " response. Blocked by browser?")
        );
      // alert('Form submitted');
    }
  };

  onFileChangeHandler(e) {
    e.preventDefault();
    this.setState({
      fileuploaded: true,
      errors: [],
    });

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    let proxyurl = "https://cors-anywhere.herokuapp.com/";

    let url = "api/loanorigination/uploadfile";
    let params = {
      customerid: this.state.fields.customerId,
    };

    api
      .post(url, formData, { params: params })
      .then((response) => {
        if (response.status === 200 && response.data.message === "SUCCESS") {
          this.setState({
            status: response.data.status,
            filemessage: response.data.message,
            fileupload: false,
          });
        } else {
          this.setState({
            backerrors: response.data.errors,
            status: response.data.status,
            filemessage: response.data.message,
          });

          this.state.backerrors.forEach((key) => {
            this.setState({
              errorMsg: key.message,
            });
          });
        }
      })
      .catch(() =>
        console.log("Can’t access " + url + " response. Blocked by browser?")
      );
  }
  /**
   *
   * @param {Event} e Submits  data to server on click of submit loan Eligiblity data in sales section
   */

  loanEligiblitySubmit = (e) => {
    e.preventDefault();
    if (this.loanEligiblitySubmitvalidateForm()) {
      let fields = this.state.fields;

      let proxyurl = "https://cors-anywhere.herokuapp.com/";
      let url = "api/loanorigination/loandisbursement";
      let params = {
        email: localStorage.getItem("email"),
        otp: this.state.fields.lastotp,
        userpin: this.state.fields.pin,
      };
      api
        .post(url, fields, {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          params: params,
        })
        .then((response) => {
          if (response.status === 200 && response.data.message === "SUCCESS") {
            this.setState({
              loanstatus: response.data.status,
              lastmsg: response.data.message,
            });
          } else {
            this.setState({
              backerrors: response.data.errors,
              status: response.data.status,
              lastmsg: response.data.message,
            });
            const errorMessages = this.state.backerrors
              .map((key) => key.message)
              .join(", ");
            this.setState({ lasterrorMsg: errorMessages });
            if (response.data.errors[0].errorCode === 400) {
              this.setState((prevState) => ({
                attempts: prevState.attempts + 1,
              }));
            }

            if (this.state.attempts >= 2) {
              this.setState({ redirect: true });
            }
          }
        })

        .catch(() =>
          console.log("Can’t access " + url + " response. Blocked by browser?")
        );
      // alert('Form submitted');
    }
  };

  handleFocus = () => {
    let fileUploadMessage = "Please upload file";
    return fileUploadMessage;
  };
  render() {
    if (this.state.redirect === true) {
      return <Navigate to="/MoneyLink/PartnerLogin/" replace={true} />;
    }
    if (
      localStorage.getItem("isLoggedIn") &&
      "SALES" == localStorage.getItem("role")
    ) {
      return (
        <div>
          {/* <Header2 /> */}
          <CommonHeader />
          <div id="wrapper">
            <Sidebar />
            <div id="maincontent" className="content originationcontent">
              <section id="content-wrapper">
                <div className="backloan">
                  <div className="container-fluid undercontainer">
                    <div className="row origin">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="salesform">
                          <p className="salestext">LOAN ORIGINATION REQUEST </p>

                          <form>
                            {(() => {
                              switch (this.state.message) {
                                case "UNSUCCESS":
                                  return (
                                    <div className="errorMsg">
                                      {" "}
                                      <p>{this.state.errorMsg}</p>
                                    </div>
                                  );
                                default:
                                  return <p></p>;
                              }
                            })()}
                            <div className="form-group">
                              <label className="lablename">Customer ID</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder={this.state.data.data.customerId}
                                name="customerId"
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">
                                {this.state.errors.customerId}
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                Salary / Business Account Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={this.state.data.data.accountNumber}
                                name="accountNumber"
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">
                                {this.state.errors.accountNumber}
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="lablename">Phone Number</label>
                              <input
                                type="Number"
                                className="form-control"
                                name="mobile"
                                placeholder={this.state.data.data.mobile}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">
                                {this.state.errors.mobile}
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="lablename">Email-ID</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder={this.state.data.data.email}
                                onChange={this.handleChange}
                              />
                              <div className="errorMsg">
                                {this.state.errors.email}
                              </div>
                            </div>

                            <p className="lablechecktext">Select Product</p>
                            <div className="form-group">
                              <label className="lablecheck">PayDay Loan</label>

                              <input
                                type="checkbox"
                                id="check"
                                name="productName"
                                value=""
                                onChange={this.handleChangepl}
                                checked={this.state.isplchecked}
                              />
                              <label className="checksales" htmlFor="check">
                                <span className="checktext"></span>
                              </label>
                            </div>

                            <div className="form-group">
                              <label className="lablecheck2">
                                Salary Advance
                              </label>

                              <input
                                type="checkbox"
                                id="check2"
                                name="productName"
                                value=""
                                onChange={this.handleChangesa}
                                checked={this.state.issachecked}
                              />
                              <label className="checksales" htmlFor="check2">
                                <span className="checktext"></span>
                              </label>
                            </div>

                            <div className="form-group">
                              <label className="lablecheck3">STPL</label>

                              <input
                                type="checkbox"
                                id="check3"
                                name="productName"
                                value=""
                                onChange={this.handleChangestpl}
                                checked={this.state.isstplchecked}
                              />
                              <label className="checksales" htmlFor="check3">
                                <span className="checktext"></span>
                              </label>
                            </div>

                            <div className="form-group">
                              <label className="lablecheck4">
                                Personal Loan
                              </label>

                              <input
                                type="checkbox"
                                id="check4"
                                name="productName"
                                value=""
                                onChange={this.handleChangepsl}
                                checked={this.state.ispslchecked}
                              />
                              <label className="checksales" htmlFor="check4">
                                <span className="checktext"></span>
                              </label>
                            </div>

                            <div className="form-group">
                              <label className="lablecheck5">Auto Loan</label>

                              <input
                                type="checkbox"
                                id="check5"
                                name="productName"
                                value=""
                                onChange={this.handleChangeal}
                                checked={this.state.isalchecked}
                              />
                              <label className="checksales" htmlFor="check5">
                                <span className="checktext"></span>
                              </label>
                            </div>

                            <div className="form-group">
                              <label className="lablecheck6">Home Loan</label>

                              <input
                                type="checkbox"
                                id="check6"
                                name="productName"
                                value=""
                                onChange={this.handleChangehl}
                                checked={this.state.ishlchecked}
                              />
                              <label className="checksales" htmlFor="check6">
                                <span className="checktext"></span>
                              </label>
                            </div>

                            <div className="form-group">
                              <label className="lablecheck7">LAP</label>

                              <input
                                type="checkbox"
                                id="check7"
                                name="productName"
                                value=""
                                onChange={this.handleChangelap}
                                checked={this.state.islapchecked}
                              />
                              <label className="checksales" htmlFor="check7">
                                <span className="checktext"></span>
                              </label>
                            </div>
                            <div className="errorMsg">
                              {this.state.errors.productName}
                            </div>

                            <button
                              className="btn btn-primary checkbutton"
                              onClick={this.handleSubmit}
                            >
                              {" "}
                              SUBMIT TO CHECK ELIGIBILITY{" "}
                            </button>
                            {(() => {
                              switch (this.state.eligblityMessage) {
                                case "SUCCESS":
                                  return (
                                    <p className="eligreen">
                                      Please enter otp to check the Eligibility
                                    </p>
                                  );
                                case "UNSUCCESS":
                                  return (
                                    <p>
                                      Server error please try after sometime
                                    </p>
                                  );
                                default:
                                  return <p></p>;
                              }
                            })()}
                          </form>

                          {(() => {
                            if (
                              200 == this.state.eligblitystatus &&
                              "SUCCESS" == this.state.eligblityMessage
                            ) {
                              return (
                                <form>
                                  <div className="form-group otplabel">
                                    <label className="lablename">
                                      Type OTP sent to customer’s registered
                                      mobile
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="otp"
                                      placeholder=""
                                      onChange={this.handleChange.bind(this)}
                                    />
                                  </div>
                                  <div className="errorMsg">
                                    {this.state.errors.otp}
                                  </div>
                                  {(() => {
                                    switch (this.state.message) {
                                      case "UNSUCCESS":
                                        return (
                                          <div className="errorMsg">
                                            {" "}
                                            <p className="invalidpin">
                                              {this.state.errorMsgotp}
                                            </p>
                                          </div>
                                        );
                                      default:
                                        return <p></p>;
                                    }
                                  })()}
                                  <button
                                    className="btn btn-primary checkbutton"
                                    onClick={this.handleOtpSubmit.bind(this)}
                                  >
                                    {" "}
                                    SUBMIT OTP{" "}
                                  </button>
                                </form>
                              );
                            } else {
                              return <p> </p>;
                            }
                          })()}
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                        <div className="salesform2">
                          <p className="salestext2">
                            LOAN ELIGIBILITY & REQUEST FOR DISBURSAL{" "}
                          </p>

                          <form className="formsale">
                            <div className="form-group">
                              <label className="lablename">
                                Eligibility Amount
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="eligibilityAmount"
                                placeholder={
                                  this.state.data.data.eligibilityAmount
                                }
                                onChange={this.handleChange.bind(this)}
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                Disbursement process (Digital / Manual)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="disbursementProcess"
                                placeholder={
                                  this.state.data.data.disbursementProcess
                                }
                                onChange={this.handleChange.bind(this)}
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                Enter the amount for disbursal request
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="amountForDisbursement"
                                placeholder={
                                  this.state.data.data.amountForDisbursement
                                }
                                onChange={this.handleChange.bind(this)}
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                Upload Loan Application Form
                              </label>
                              <div className="custom-file">
                                <input
                                  id="inputGroupFile01"
                                  type="file"
                                  className="custom-file-input"
                                  name="file"
                                  onChange={this.onFileChangeHandler}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="inputGroupFile01"
                                >
                                  Choose file
                                </label>
                                {(() => {
                                  switch (this.state.filemessage) {
                                    case "SUCCESS":
                                      return (
                                        <p className="submittedsales">
                                          File Uploaded Sucessfully
                                        </p>
                                      );
                                    case "UNSUCCESS":
                                      return (
                                        <div className="errorMsg">
                                          {" "}
                                          <p className="invalidpin">
                                            {this.state.errorMsg}
                                          </p>
                                        </div>
                                      );
                                    default:
                                      return <p></p>;
                                  }
                                })()}
                                <div className="errorMsg">
                                  {this.state.errors.fileuplod}
                                </div>
                              </div>
                            </div>
                          </form>

                          <div className="terms">
                            <p className="termstext">
                              Please agree following Terms & Conditions{" "}
                            </p>

                            <div>
                              <ul className="loantenor">
                                <li className="loantenortext"> Loan Tenor </li>
                                <li
                                  type=""
                                  className="btn btn-primary loantenorbutton"
                                >
                                  {" "}
                                  Up to 30 days{" "}
                                </li>
                              </ul>
                            </div>

                            <div>
                              <p className="charge">charges </p>
                              <ul className="loantenor2">
                                <li className="loantenortext2">
                                  Fee to Bank-
                                  <br />{" "}
                                  <span className="spant">
                                    Charged upfront on amount disbursed
                                  </span>
                                </li>
                                <li
                                  type=""
                                  className="btn btn-primary loantenorbutton2"
                                >
                                  {" "}
                                  {this.state.data.data.feeToBank}%{" "}
                                </li>
                              </ul>

                              <ul className="loantenor2">
                                <li className="loantenortext2">
                                  Credit Life insurance-
                                  <br />{" "}
                                  <span className="spant">
                                    Charged upfront on amount disbursed
                                  </span>
                                </li>
                                <li
                                  type=""
                                  className="btn btn-primary loantenorbutton2"
                                >
                                  {" "}
                                  {
                                    this.state.data.data.creditLifeInsurance
                                  }%{" "}
                                </li>
                              </ul>

                              <ul className="loantenor2">
                                <li className="loantenortext2">
                                  Interest-
                                  <br />{" "}
                                  <span className="spant">
                                    Charged upfront on amount disbursed
                                  </span>
                                </li>
                                <li
                                  type=""
                                  className="btn btn-primary loantenorbutton2"
                                >
                                  {" "}
                                  {this.state.data.data.interest}%{" "}
                                </li>
                              </ul>

                              <ul className="loantenor2">
                                <li className="loantenortext2">
                                  Late Payment Fee-
                                  <br />{" "}
                                  <span className="spant">
                                    Charged upfront on amount disbursed
                                  </span>
                                </li>
                                <li
                                  type=""
                                  className="btn btn-primary loantenorbutton2"
                                >
                                  {" "}
                                  {this.state.data.data.latePaymentFee}%{" "}
                                </li>
                              </ul>

                              <ul className="loantenor2">
                                <li className="loantenortext2">
                                  Paylink
                                  <br />{" "}
                                  <span className="spant">
                                    Charged upfront on amount disbursed
                                  </span>
                                </li>
                                <li
                                  type=""
                                  className="btn btn-primary loantenorbutton2"
                                >
                                  {" "}
                                  {this.state.data.data.payLinkFee}%{" "}
                                </li>
                              </ul>

                              <ul className="listoriginul">
                                <li className="listorigin">
                                  Your RePayments would be collected from your
                                  salary payment towards paying them on the due
                                  date every month.
                                </li>
                                <li className="listorigin">
                                  RePayments would be debited to your account
                                  every month
                                </li>
                              </ul>

                              <form action="">
                                <div className="custom-control custom-radio">
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="termsAndConditions"
                                      onChange={this.accepttermAndConditions.bind(
                                        this
                                      )}
                                      checked={this.state.istacAccepted}
                                    />{" "}
                                    I Agree on the above terms & conditions,
                                    Please disburse the loan amount I have
                                    requested.
                                  </label>
                                  <div className="errorMsg">
                                    {this.state.errors.termsAndConditions}
                                  </div>
                                </div>
                              </form>

                              <div className="sign">
                                <button
                                  className="btn btn-warning agreesubmit"
                                  onClick={this.resendOtp.bind(this)}
                                >
                                  Submit{" "}
                                </button>
                                {(() => {
                                  switch (this.state.resendOtpMessage) {
                                    case "SUCCESS":
                                      return (
                                        <p>
                                          Please enter otp sent to your
                                          email/mobile
                                        </p>
                                      );
                                    case "UNSUCCESS":
                                      return (
                                        <p>
                                          Server error please try after sometime
                                        </p>
                                      );
                                    default:
                                      return <p></p>;
                                  }
                                })()}
                              </div>
                            </div>
                          </div>

                          {(() => {
                            if (
                              200 == this.state.resendOtpStatus &&
                              "SUCCESS" == this.state.resendOtpMessage
                            ) {
                              return (
                                <form className="formsale">
                                  <div className="form-group">
                                    <label className="lablesit">
                                      Type OTP sent to customer’s registered
                                      mobile to consent to the above terms &
                                      conditions{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="lastotp"
                                      placeholder=""
                                      onChange={this.handleChange.bind(this)}
                                    />
                                    <div className="errorMsg">
                                      {this.state.errors.lastotp}
                                    </div>
                                  </div>
                                </form>
                              );
                            } else {
                              return <p> </p>;
                            }
                          })()}

                          {(() => {
                            if (
                              200 == this.state.resendOtpStatus &&
                              "SUCCESS" == this.state.resendOtpMessage
                            ) {
                              return (
                                <div className="collectionptchecker">
                                  <div className="salepin">
                                    <form
                                      method="post"
                                      name="userRegistrationForm"
                                    >
                                      <div className="form-group ">
                                        <label htmlFor="first">
                                          Type Your User PIN{" "}
                                        </label>
                                        <input
                                          type="password"
                                          className="form-control cardinputfield"
                                          name="pin"
                                          onChange={this.handleChange.bind(
                                            this
                                          )}
                                          placeholder="xxxxx "
                                        />
                                        <div className="sign">
                                          <button
                                            className="btn btn-warning cardbuttonsales"
                                            onClick={this.loanEligiblitySubmit}
                                            disabled={this.state.fileupload}
                                            onFocus={this.handleFocus}
                                          >
                                            Submit changes for approval{" "}
                                          </button>
                                        </div>
                                        {this.state.errors ? (
                                          <div className="errorMsg">
                                            {" "}
                                            {
                                              <p className="invalidpin">
                                                {this.state.errors.pin ||
                                                  this.state.lasterrorMsg}
                                              </p>
                                            }
                                          </div>
                                        ) : (
                                          <p className="submitted ">
                                            Submitted Succesfully
                                          </p>
                                        )}
                                        <ForgotPin />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              );
                            } else {
                              return <p> </p>;
                            }
                          })()}
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-12 col-lg-4">
                        <div className="salesform3">
                          {(() => {
                            switch (this.state.lastmsg) {
                              case "SUCCESS":
                                return (
                                  <div>
                                    <div className="salestext3">
                                      {" "}
                                      LOAN APPROVAL STATUS
                                    </div>
                                    <div className="salestext4">
                                      Loan Disbursed in Customer’s{" "}
                                      {this.state.data.data.accountNumber}{" "}
                                      account
                                    </div>
                                  </div>
                                );
                              default:
                                return (
                                  <div className="salestext3">
                                    {" "}
                                    LOAN APPROVAL STATUS
                                  </div>
                                );
                            }
                          })()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* <FooterAdmin /> */}
        </div>
      );
    } else {
      return <p>Please Login AS SALES</p>;
    }
  }
}

export default Origination;
