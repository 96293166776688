import React from "react";
import Business from "../../Home/Business/Main";
import PaymentGateway from "./PaymentGateway";

const Body = () => {
  return (
    <>
      <div className="business-page">
        <Business businessPG={false} />
        <PaymentGateway businessPgColor="#20336b" businessPG={true} />
      </div>
    </>
  );
};

export default Body;
