import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import appstore from "../../assets/images/appstoreblack.png";
import playstore from "../../assets/images/playstore.webp";
import logo from "../../assets/images/montrafooter.svg";

const Main = () => {
  return (
    <>
      <section className="footer">
        {/* <div className="footer-box">
          <div className="row footer-boxes">
            <div className="col footer-box1">
              <div className="download-montra">
                <p>Download Montra</p>
                <span>Using any of your preferred app store</span>

                <div className="app-download">
                  <img src={playstore} alt="image" />
                  <img src={appstore} alt="image" />
                </div>
              </div>
            </div>
            <div className="col footer-box2">
              <p>Start Innovating with Montra as your partner today</p>
              <span>Select on preferred app device to download Montra</span>
              <button>Contact us</button>
            </div>
          </div>
        </div> */}


        <div className="footer-box">
          <div className="row footer-boxes">
            <div className="col footerdown">
              <div className="download-montra">
                <p>Download Montra <br />
                  <span> Using any of your preferred app store</span></p>

                <div className="app-download ">
                  <img src={playstore} alt="playstore" width="600" height="400" />
                  <img src={appstore} alt="appstore" width="600" height="400" />
                </div>
              </div>
            </div>
            {/* <div className="col footer-box2">
              <p>Start Innovating with Montra as your partner today</p>
              <span>Select on preferred app device to download Montra</span>
              <button>Contact us</button>
            </div> */}
          </div>
        </div>
        <div className="footer-container footer-links">

          <div className="footer-first-section footer-section">
            <Link to="/" > <img src={logo} alt="logo" />
            </Link>
            <p> <i class="bi bi-cursor-fill"></i> Montra, Inc. (United States) </p>
            <p>
              Montra introduces innovative products & solutions that promote
              Financial Inclusion across emerging economies towards transition
              from cash to digital payments & making accessibility to Credit
              easy for everyone
            </p>
          </div>
          <div className="footersecRow footer-section">
            <h6>Products</h6>
            <p className="highlightfooter"></p>
            <span>Personal</span>
            <ul>
              <li>
                <Link to="/Products/Banking#Banking" className="nav-link main-active">
                  Banking
                </Link>
              </li>
              <li>
                <Link to="/Products/Payment#Payments" className="nav-link main-active">
                  Payment
                </Link>
              </li>
              <li>
                <Link to="/Products/Finance#Finance" className="nav-link main-active">
                  Finance
                </Link>
              </li>
              <li>
                <Link to="/Products/Chat#Chat" className="nav-link main-active">
                  Chat
                </Link>
              </li>
              <li>
                <Link to="/Products/Shop#Shopping" className="nav-link main-active">
                  Shopping
                </Link>
              </li>
              <li>
                <Link to="/Products/overview#Overview" className="nav-link main-active">
                  Lending
                </Link>
              </li>
            </ul>

            <p className="highlightfooter"></p>
            <span>Business</span>
            <ul>
              <li>
                <Link to="/Business/Payment#Payments" className="nav-link main-active">
                  Payment
                </Link>
              </li>
              <li>
                <Link to="/Business/Banking#Banking" className="nav-link main-active">
                  Banking
                </Link>
              </li>
              <li>
                <Link to="/Business/AgencyBanking#AgencyBanking" className="nav-link main-active">
                  Agency Banking
                </Link>
              </li>

              <li>
                <Link to="/Business/more/TapPay#TapPay" className="nav-link main-active">
                  Tap & Pay
                </Link>
              </li>
              <li>
                <Link to="/Business/more/Inventory#Inventory" className="nav-link main-active">
                  Inventory Management
                </Link>
              </li>
              <li>
                <Link to="/Business/more/Store#Montra-Store" className="nav-link main-active">
                  Montra Store
                </Link>
              </li>
              <li>
                <Link to="/Business/more/Gateway#PaymentGateway" className="nav-link main-active">
                  Payment Gateway
                </Link>
              </li>
            </ul>

            <div className="responsivefourthfootermenu">
              <h1>Platforms</h1>
              <p className="highlightfooter"></p>
              <span>MoneyLink</span>
              <ul>
                <li>
                  <Link to="/Platform/Moneylink/cip#CardIssuing" className="nav-link main-active">
                    Card Issuing
                  </Link>
                </li>
                <li>
                  <Link to="/Platform/Moneylink/merchant#merchant" className="nav-link main-active">
                    Merchant Services
                  </Link>
                </li>
                <li>
                  <Link to="/Platform/Moneylink/Accountmanagement#AccountManagement" className="nav-link main-active">
                    Account Management
                  </Link>
                </li>
                <li>
                  <Link to="/Platform/Moneylink/Loanmanagement#LoanManagementSystem" className="nav-link main-active">
                    Loan Management System
                  </Link>
                </li>
                <li>
                  <Link to="/Platform/Moneylink/Debtcollections#collection" className="nav-link main-active">
                    Debt Collections
                  </Link>
                </li>
              </ul>

              <h6 className="businessfooterspan">Company</h6>
              <ul>
                <li>
                  <Link to="/Company/Overview" className="nav-link main-active">
                    About Montra
                  </Link>
                </li>
                <li>
                  <Link to="/Company/Ourstory" className="nav-link main-active">
                    Our Story
                  </Link>
                </li>
              </ul>

            </div>

          </div>
          <div className="footerthirdRow footer-section">
            <h6>Solutions</h6>
            <p className="highlightfooter"></p>
            <span className="highlightmenu">Duo</span>
            <ul>
              <li>
                <Link to="/Solutions/Duo/Dts#DTS" className="nav-link main-active">
                  Dual Transaction Service - DTS
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Duo/Dcp#DCP" className="nav-link main-active">
                  Duo Card Product - DCP
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Duo/Duoapp#DuoApp" className="nav-link main-active">
                  DUO App
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Duo/Dwp#DuoWebPortal" className="nav-link main-active">
                  DUO Web Portal
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Duo/Customeronboarding#DuoCustomerOnboarding" className="nav-link main-active">
                  DUO Customer Onboarding
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Duo/Dlm#LimitManagement" className="nav-link main-active">
                  Limit Management
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Duo/Collection#Collections" className="nav-link main-active">
                  Collections
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Duo/Implementation#Implementation" className="nav-link main-active">
                  DUO Implementation
                </Link>
              </li>
            </ul>

            <p className="highlightfooter"></p>
            <span>Verifyed</span>
            <ul>
              <li>
                <Link to="/Solution/Verified/Authapp#AuthenticatorApp" className="nav-link main-active">
                  Authenticator App
                </Link>
              </li>
              <li>
                <Link to="/Solution/Verified/Faceauth#FaceAuthentication" className="nav-link main-active">
                  Face Authentication
                </Link>
              </li>
              <li>
                <Link to="/Solution/Verified/KYC#KYCVerification" className="nav-link main-active">
                  KYC ID Verification
                </Link>
              </li>
              <li>
                <Link to="/Solution/Verified/VideoKYC#VideoKYCSolution" className="nav-link main-active">
                  Video KYC Solution
                </Link>
              </li>
            </ul>

            <p className="highlightfooter"></p>
            <span>Supply Chain</span>
            <ul>
              <li>
                <Link to="/Solutions/Supplychain/payment#supplypayments" className="nav-link main-active">
                  Payments
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Supplychain/collection#supplycollections" className="nav-link main-active">
                  Collections
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Supplychain/Sales#salespromotions" className="nav-link main-active">
                  Sales Promotions
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Supplychain/Orderfulfilment#OrderFulfilment" className="nav-link main-active">
                  Order Fulfilment
                </Link>
              </li>
              <li>
                <Link to="/Solutions/Supplychain/Distributionanalytics#Distributionanalytics" className="nav-link main-active">
                  Distribution Analytics
                </Link>
              </li>
            </ul>

          </div>
          <div className="footerFourthRow footer-section responsivefourthfooter">
            <h6>Platforms</h6>
            <p className="highlightfooter"></p>
            <span>MoneyLink</span>
            <ul>
              <li>
                <Link to="/Platform/Moneylink/cip#CardIssuing" className="nav-link main-active">
                  Card Issuing
                </Link>
              </li>
              <li>
                <Link to="/Platform/Moneylink/merchant#merchant" className="nav-link main-active">
                  Merchant Services
                </Link>
              </li>
              <li>
                <Link to="/Platform/Moneylink/Accountmanagement#AccountManagement" className="nav-link main-active">
                  Account Management
                </Link>
              </li>
              <li>
                <Link to="/Platform/Moneylink/Loanmanagement#LoanManagementSystem" className="nav-link main-active">
                  Loan Management System
                </Link>
              </li>
              <li>
                <Link to="/Platform/Moneylink/Debtcollections#collection" className="nav-link main-active">
                  Debt Collections
                </Link>
              </li>
            </ul>

            <h6 className="companyfootertext">Company</h6>
            <ul>
              <li>
                <Link to="/Company/Overview" className="nav-link main-active">
                  About Montra
                </Link>
              </li>
              <li>
                <Link to="/Company/Ourstory" className="nav-link main-active">
                  Our Story
                </Link>
              </li>
            </ul>

            {/* <h6>Resources</h6>
              <ul>
                <li>Demo Montra App</li>
                <li>Explainer Video</li>
                <li>Developers</li>
              </ul>

              <h6>Help</h6>
              <ul>
                <li>FAQ</li>
                <li>Support</li>
              </ul>

              <h6>Contact</h6>
              <ul>
                <li>hi@montra.ng</li>
              </ul> */}

          </div>
        </div>


      </section>
    </>
  );
};

export default Main;
