import React from "react";
// import businesspaymenthero1 from "../../../assets/images/businesspaymenthero1.svg";
import businesspaymenthero2 from "../../../assets/images/businesspaymenthero2.webp";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import PaymentProcessContainer from "./PaymentProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="business-payment">
        <div className="row">
          <div className="col responsivebusiness-col">
            <h1>
              <span>Grow your business</span> with Montra Business Account{" "}
              <span className="andfont"> & </span>{" "}
              <span>start accepting Payments from everyone</span>
            </h1>
            <p>
              Upgrade your business and let Montra help you achieve your
              business goals.
            </p>
            {/* <img
              src={businesspaymenthero1}
              alt=""
              className="businesspaymenticon"
            /> */}
            <PaymentProcessContainer />
            <div className="app-download">
              <img src={appstore} alt="appstore" />
              <img src={playstore} alt="playstore" />
            </div>
          </div>
          <div className="col businesspaymentimg responsivebusiness-col">
            <img
              src={businesspaymenthero2}
              alt=""
              className="businesspaymenticon"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
