import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import QRScan from "../../../assets/images/uday/personal-icons/Home-icons/Business/section1/qrscan-blue.svg";
import TapPay from "../../../assets/images/uday/personal-icons/Home-icons/Business/section1/TapPay-blue.svg";
import POS from "../../../assets/images/uday/personal-icons/Home-icons/Business/section1/POS-blue.svg";
import BankTransfer from "../../../assets/images/uday/personal-icons/Home-icons/Business/section1/bankTransfer-blue.svg";
import businessanimg1 from "../../../assets/images/businessimg1.svg";

const steps = [
  {
    title: "Accept Payments Using",
    image: businessanimg1,
    className: "direction-img",
  },
  { image: QRScan, description: "QR Scan" },
  { image: TapPay, description: "Tap & Pay" },
  { image: POS, description: "POS" },
  { image: BankTransfer, description: "Bank Transfer" },
];

const BusinessAccountProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#FFFFFF"
        circleBorderColor="#FFFFFF"
        textColor="#FFFFFF"
        circelBg="#FFFFFF"
        // topDescriptionClass="homepersonal-payment-topdescription"
        topDescriptionClass="homebusiness-store-topdescription"
      />
    </div>
  );
};

export default BusinessAccountProcessContainer;
