import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Main";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import icon1 from "../../assets/images/responsive1.svg";
import "./product.css";

const Productnav = ({ activeItem }) => {
  const [showMerchantLink, setShowMerchantLink] = useState(false);
  const [isMoreActive, setMoreActive] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname == "/Products/Shop") {
      setShowMerchantLink(true);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="product-nav">
        <Navbar />
      </div>
      {screenWidth > 920 ? (
        <div className="second-navbar navbar personal-business-navbar">
          <div className="swtich-btn">
            <div className="personal-btn">
              <button className="active-swtich-btn">Personal</button>
            </div>
            <div className="">
              <NavLink to="/Business/overview" className="nav-link">
                <button className="nonactive-swtich-btn">Business</button>
              </NavLink>
            </div>
          </div>

          <div className="nav-link">
            <ul className="navbar-nav ml-auto">
              {showMerchantLink && (
                <li className="nav-item">
                  <NavLink
                    to="https://order.montra.org/"
                    target="_blank"
                    className="nav-link product"
                    activeClassName="active"
                  >
                    Shop Now
                  </NavLink>
                </li>
              )}
              <li className="nav-item">
                <NavLink
                  to="/Products/overview"
                  className="nav-link product"
                  activeClassName="active"
                >
                  Overview
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Products/Banking"
                  className="nav-link product"
                  activeClassName="active"
                >
                  Banking
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Products/Payment"
                  className="nav-link product"
                  activeClassName="active"
                >
                  Payments
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Products/Finance"
                  className="nav-link product"
                  activeClassName="active"
                >
                  Finance
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Products/Chat"
                  className="nav-link product"
                  activeClassName="active"
                >
                  Chat
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Products/Shop"
                  className="nav-link product"
                  activeClassName="active"
                >
                  Shop
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <button className="product-btn produvt-sign">
                  Sign In <i className="bi bi-chevron-right"></i>
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      ) : (
        <div className="horizontal-navbar">
          <div className="second-navbar navbar personal-business-navbar ">
            <div className="flexdiv-parent">
              <div className=" flexdiv-menu">
                <div className="flexdiv-menu-items">
                  <img src={icon1} className="productnav_icon" alt="icon" />
                  <h2 className="product_nav-title ">Personal</h2>
                </div>
              </div>
              <div className="horizontal-flow">
                <div className="horizontal-flow-track">
                  <div className="nav-link horizontal-flow-items">
                    <ul className="navbar-nav ml-auto">
                      {showMerchantLink && (
                        <li className="nav-item">
                          <NavLink
                            to="https://order.montra.org/"
                            target="_blank"
                            className="nav-link product"
                            activeClassName="active"
                          >
                            Shop Now
                          </NavLink>
                        </li>
                      )}
                      <li className="nav-item">
                        <NavLink
                          to="/Products/overview"
                          className="nav-link product"
                          activeClassName="active"
                        >
                          Overview
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Products/Banking"
                          className="nav-link product"
                          activeClassName="active"
                        >
                          Banking
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Products/Payment"
                          className="nav-link product"
                          activeClassName="active"
                        >
                          Payments
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Products/Finance"
                          className="nav-link product"
                          activeClassName="active"
                        >
                          Finance
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Products/Chat"
                          className="nav-link product"
                          activeClassName="active"
                        >
                          Chat
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Products/Shop"
                          className="nav-link product"
                          activeClassName="active"
                        >
                          Shop
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                        <button className="product-btn produvt-sign">
                          Sign In <i className="bi bi-chevron-right"></i>
                        </button>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Productnav;
