import React from 'react';


const termcondition = () => {

    return (
        <div className="container-fluid ">
            <div className="privacypl">
                <h5 className="header-tag">  VERIFYED Terms and Conditions </h5>
                <p className="paragraph"> Please read this Verifyed Application Terms and Condition carefully before downloading or using the platform or Mobile Application.  </p>
                <p className="paragraph">The Verifyed Mobile Application are owned and operated by Montra Technology Solution Limited ("<span className='spanterms'>Montra</span>", “Montra Technology”,
                    which expression shall, wherever the context permits, admits or requires, be deemed to mean and include its successors
                    in interest and permitted assigns), which allows You to access Verifyed SoftToken Mobile Application (“TOTP”, “2FA App”)
                    from Your mobile device. This Agreement forms a binding legal agreement between you (and any other entity on
                    whose behalf you accept these terms) (collectively “<span className='spanterms'>You</span>” or “<span className='spanterms'>Your</span>”) and Montra Technology Limited (each separately a “<span className='spanterms'>Party</span>” and collectively the “<span className='spanterms'>Parties</span>”) as of the date you download the Mobile App.</p>
                <p className="paragraph"> By using, installing, or accessing the Verifyed Application (“<span className='spanterms1 italic'>Verifyed App </span>”), or reading the Terms and Conditions set forth in the Montra Verifyed App Terms (“<span className='spanterms1 italic'>Terms and Conditions </span>”), you Agree to the Terms and Conditions . Any reference to “you”, or “your” will refer to you for purposes of these App Terms.  </p>
                <h5 className="header-tag"> 1.  &nbsp;  Use of the Verifyed App </h5>
                <p className="paragraph  terms-span">You will use the Verifyed App in accordance with these App Terms and Conditions and applicable law or regulation.</p>
               
                <h5 className="header-tag"> 2.  &nbsp; IP Rights and Licenses </h5>
                <p className="paragraph terms-span"> Montra owns all right, title, and interest in and to the Verifyed App. Subject to all terms and conditions of these App Terms, Montra grants you a limited, non-exclusive, non-transferable license to install and use one (1) object code copy of the Verifyed App. </p>

                    <h5 className="header-tag"> 3.  &nbsp; Data Privacy and Acknowledgement of Use </h5>
                    <p className="paragraph terms-span">Any data, including your personal information, collected, transferred, stored or otherwise processed through your use of the Verifyed App will be processed in accordance with the Verifyed App Privacy Notice( Privacy policy Link). </p>

                    <h5 className="header-tag"> 4.  &nbsp; Availability of the Verifyed App </h5>
                    <p className="paragraph terms-span"> You understand that use and availability of the Verifyed App may be interrupted, including for maintenance, upgrades, or network or equipment failures. Montra may discontinue the Verifyed App, any of its features, and/or support for the Verifyed App, including its use on certain devices and platforms, at any time. </p>

                    <h5 className="header-tag"> 5.  &nbsp; Feedback </h5>
                    <p className="paragraph terms-span"> Montra welcomes any recommendations, suggestions, ideas, or feedback you have about the Verifyed App (collectively, “<span className='spanterms1 italic'>Feedback </span>”). You understand that Montra owns all Feedback that you provide and you are not entitled to any compensation or reimbursement of any kind for providing Feedback to Montra or in connection with Montra’s use of Feedback. </p>

                    <h5 className="header-tag"> 6.  &nbsp;  Terms Applicable to Verifyed App Use on iOS Devices </h5>
                    <p className="paragraph terms-span"> This Section 6 only applies to the extent you are using the Verifyed App on an
                        iOS device. You acknowledge that these App Terms are between you and Montra only, not with Apple Inc. ("<span className='spanterms1 italic'>Apple</span>"),
                        and Apple is not responsible for the Verifyed App or the content thereof. Apple has no obligation to furnish any
                        maintenance and support services with respect to the Verifyed App. If the Verifyed App fails to conform to any
                        applicable warranty, you may notify Apple and Apple will refund any applicable purchase price for the Verifyed App to
                        you; and, to the maximum extent permitted by applicable law, Apple has no other warranty obligation with respect to the Verifyed App. Apple is not responsible for addressing any claims by you or any third party relating to the Verifyed App or your possession and/or use of the Verifyed App, including: (a) product liability claims; (b) any claim that the Verifyed App fails to conform to any applicable legal or regulatory requirement; or (c) claims arising under consumer protection or similar legislation. Apple is not responsible for the investigation, defense, settlement and discharge of any third-party claim that the Verifyed App and/or your possession and use of the Verifyed App infringe a third party’s intellectual property rights. You agree to comply with any applicable third-party terms when using the Verifyed App. Apple and Apple’s subsidiaries are third-party beneficiaries of these App Terms, and upon your acceptance of these App Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these App Terms against you as a third-party beneficiary of these App Terms. You hereby represent and warrant that: (i) you are not located in a country that is subject to a U.S. Government embargo, or that is on Title 15, Part 740 Supplement 1 Country Group E of the U.S. Code of Federal
                        Regulations and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties. </p>

                    <h5 className="header-tag"> 7.  &nbsp; Fees and payment </h5>
                    <p className="paragraph terms-span"> The Subscription Fee for the Services will be presented during Account Activation.  </p>

                    <h5 className="header-tag"> 8.  &nbsp; <span className='spanterms'> Warranty Disclaimer</span> </h5>
                    <p className="paragraph terms-span"><span className='spanterms'> THE VERIFYED APP IS PROVIDED “AS IS,” AND MONTRA MAKES NO WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, AND MONTRA SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT TO THE FULLEST EXTENT PERMITTED BY LAW. </span> </p>

                    <h5 className="header-tag"> 9.  &nbsp;  Indemnification </h5>
                    <p className="paragraph terms-span"> You will defend Montra and Montra’s officers, directors, employees, and affiliated entities (collectively, “ <span className='spanterms1 italic'>Montra Indemnified Parties </span>”) from and against any claim, demand, suit, or proceeding made or brought against a Montra Indemnified Party by a third party alleging or arising out of your use of the Verifyed App (“ <span className='spanterms1 italic'>Claim</span>”). You will indemnify Montra from any damages, fines or penalties imposed by a government or regulatory body, attorneys’ fees, and costs awarded against a Montra Indemnified Party or for settlement amounts approved by you for a Claim. </p>
                    <p className="paragraph terms-span">Montra reserves the right, at its own expense, to participate in the defence of any Claim. You will not do the following without Montra’s prior written consent: </p>
                    <p className="paragraph terms-span">(a) settle any Claims for which you have an obligation to indemnify pursuant to this Section 9 or</p>
                    <p className="paragraph terms-span">(b) admit to liability or fault or create any obligation on behalf of Montra as part of a settlement of a Claim.</p>

                    <h5 className="header-tag"> 10.  &nbsp; Limitation of Liability </h5>
                    <p className="paragraph terms-span"> To the maximum extent mandated by Law, In no Event will Montra Technology and its associates be liable for any Fraud, Transactional Loss, Loss of profits / business, Loss of use, Loss of Data or any indirect, special, incidental or consequential damages under any theory of liability.  </p>
                    <p className="paragraph terms-span"> Soft Token is only a 2nd Factor of Authentication for login and Transactions. Montra will not be responsible or liable for any Loss ( Personal / Financial) to user on account of Malware attack, Fraud, Unauthorised access of Phone / Applications or any mode of misuse. </p>
                   
                    <h5 className="header-tag"> 11.  &nbsp;  Third Party Beneficiaries  </h5>
                    <p className="paragraph terms-span">This Agreement is executed between you and Montra Technology and not between you and any other party, including Apple for iOS users and Google for Android users. You agree that any claims brought by you arising out of this Agreement or your use of the Mobile App will not be made against Apple or Google, as applicable. Notwithstanding the foregoing, upon your acceptance of this Agreement, allows Apple or Google, as applicable, to enforce this agreement against you as a third party beneficiary thereof. Montra is not responsible for any applicable third-party agreement between you and any third-party, including your wireless provider. </p>

                    <h5 className="header-tag"> 12.  &nbsp; Term Termination </h5>
                    <p className="paragraph terms-span">These App Terms, as may be updated from time to time, will commence on the date you  </p>
                    <p className="paragraph terms-span">(a) begin using, installing, or accessing the Verifyed App, or  </p>
                    <p className="paragraph terms-span">(b) Reading Terms and Conditions will continue until terminated in accordance with this Section 12. </p>
                    <p className="paragraph terms-span">You may terminate these App Terms at any time, for any or no reason, by immediately ceasing your use of the Verifyed App. If you commence using the Verifyed App again, you are consenting to these App Terms. Montra may, in its sole discretion, suspend or terminate these App Terms immediately  </p>
                    <p className="paragraph terms-span">(a) if Montra suspects you are in breach of these App Terms. </p>
                    <p className="paragraph terms-span">(b) if Montra determines that you are engaged in activity that is suspected or actually fraudulent, illegal or otherwise malicious or fails to comply with applicable law. </p>
                    <p className="paragraph terms-span">(c) causes a significant risk of reputational harm to Montra. or  </p>
                    <p className="paragraph terms-span">(d) to prevent harm to the security, stability, availability, or integrity of Montra. </p>

                    <h5 className="header-tag"> 2.  &nbsp;  Modification Conflict </h5>
                    <p className="paragraph terms-span"> Montra may update or modify these App Terms (including any terms or policies incorporated by reference) from time to time by posting a revised version on Montra’s website and in the Verifyed App. Additionally, Montra will notify the Montra customer that authorized your access to the Verifyed App of any such updates and modifications via email to the email address designated in that Montra customer’s account or provided via that Montra customer’s account portal not less than thirty (30) days prior to the effective date of any material amendments or modifications. Except as otherwise specified by Montra, updates will be effective upon the effective date indicated at the top of these App Terms. The updated version of these App Terms will supersede all prior versions. Your continued access or use of the Verifyed App on or after the effective date of the changes to these App Terms (including any terms or policies incorporated by reference) constitutes your acceptance of any updates. If you do not agree to any updates, you should stop using the Verifyed App. Montra may not be able to provide at least thirty (30) days prior written notice of updates to these App Terms that result from changes in the law or requirements from telecommunications providers. </p>

                    <h5 className="header-tag"> 2.  &nbsp;  Governing Law </h5>
                    <p className="paragraph terms-span"> This Terms and Condition shall be governed by Nigerian Law.  </p>
                
            </div>
        </div>
    )

}

export default termcondition;