/* React packages and components */
import React from "react";
import Otp from "./Otp";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

/**
 * Render Login screen of MoneyLink.
 * <p>It is used to capture the details of user who is trying to login.</p>
 * <p>Validates all the fields.</p>
 * <p>By clicking on login button it sends all data to server.</p>
 * <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
 * <li> <b>select :-</b> This tag is use for element is used to create a drop-down list. </li>

 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>

 * @Author : Seema Kumari
 * @version :1.0
 */

class PartnerLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      fields: {
        email: "",
        password: "",
      },
      errors: {},
      backerrors: [],
      email: "",
      status: "",
      password: "",
      isActive: false,
      message: "",
      errorMsg: "",
      serverErr: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleShow = () => {
    this.setState({
      isActive: true,
    });
    //for development
  };
  /**
   *captures the otp entered by user.
   * @param {Event} e event which calls this method.
   */
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }
  /**
   *Submits the email and password entered by user to login.
   * @param {Event} e event which calls this method.
   */
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      const { email, password } = this.state;
      if (email && password) {
        // this.props.login(email, password);
      }
      this.setState({
        isActive: true,
        serverErr: "",
      });

      let fields = this.state.fields;
      // const proxyurl = "https://cors-anywhere.herokuapp.com/"
      let url = new URL("https://api.montra.org/moneylinkweb/api/user/signin");

      fetch(url + "?email=" + email + "&password=" + password, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.setState({
              serverErr: "Please try After Sometime",
            });
          }
        })
        .then((contents) => {
          if (200 == contents.status && "SUCCESS" == contents.message) {
            this.setState({
              role: contents.data.role,
              status: contents.status,
              message: contents.message,
            });
            localStorage.setItem("logintype", "SIGNIN");
            localStorage.setItem("role", contents.data.role);
            localStorage.setItem("email", email);
            localStorage.setItem("name", contents.data.Username);
            localStorage.setItem("isLoggedIn", true);
          } else {
            this.setState({
              backerrors: contents.errors,
              status: contents.status,
              message: contents.message,
            });
            this.state.backerrors.map((key) => {
              this.setState({
                errorMsg: key.message,
              });
            });
          }
        })
        .catch(() => {
          console.log("Can’t access " + url + " response. Blocked by browser?");
          this.setState({
            serverErr: "Please try After Sometime",
          });
        });
      // alert('Form submitted');
      this.handleShow();
    }
  };

  /**  validates the email and password entered by user. */
  validateForm() {
    let fields = this.state.fields;

    let errors = {};
    let formIsValid = true;

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your official Email Address";
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid official Email Address";
      }
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }

    if (typeof fields["password"] !== "undefined") {
      if (
        !fields["password"].match(
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
        )
      ) {
        formIsValid = false;
        errors["password"] = "Email Address/Password does not match";
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  handlerole(role) {
    this.props.handlerole(this.state.role);
  }
  render() {
    if (this.state.status == 200 && "SUCCESS" == this.state.message) {
      return (
        <div>
          {/* Form -: in this form section including input text and dropdown to access the user profile */}
          <form
            method="post"
            name="userRegistrationForm"
            onSubmit={this.handleSubmit}
          >
            <p className="para4">Existing Users / Issuer Partners</p>
            <div className="form-group">
              <label className="signin-label" htmlFor="name">
                Partner Name
              </label>
              <select className="form-control leftalign">
                <option value="Select Partner">Select Partner</option>
                <option value="Acess Bank">Access Bank</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="email" className="signin-label">
                Your Official Email Address
              </label>
              <input
                type="text"
                className="form-control partners-input"
                id="email"
                name="email"
                value={this.state.fields.email}
                onChange={this.handleChange}
              />
              <div className="errorMsg">{this.state.errors.email}</div>
            </div>
            <div className="form-group">
              <label htmlFor="password" className="signin-label">
                Password{" "}
              </label>
              <label htmlFor="password" className="forgot">
                <NavLink to="#" className="forpas">
                  Forgot Password?{" "}
                </NavLink>
              </label>
              <input
                type="password"
                className="form-control partners-input"
                name="password"
                id="password"
                value={this.state.fields.password}
                onChange={this.handleChange}
              />
              <div className="errorMsg">{this.state.errors.password}</div>
            </div>
            <div className="sign">
              <input
                type="submit"
                className="btn btn-warning partnerbutton  signmllogin"
                onClick={this.handleShow}
                value="Sign-in"
              />
            </div>
          </form>
          <Otp
            email={this.state.fields.email}
            role={this.state.role}
            rolefw={() => this.handlerole()}
          />
        </div>
      );
    } else {
      return (
        <div>
          <form
            method="post"
            name="userRegistrationForm"
            onSubmit={this.handleSubmit}
          >
            <p className="para4">Existing Users / Issuer Partners</p>
            <div className="form-group ">
              <label signin-label className="signin-label">
                Partner Name
              </label>
              <select className="form-control leftalign">
                <option value="Select Partner">Select Partner</option>
                <option value="Acess Bank">Access Bank</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="email" className="signin-label">
                Your Official Email Address
              </label>
              <input
                type="text"
                className="form-control partners-input"
                id="email"
                name="email"
                value={this.state.fields.email}
                onChange={this.handleChange}
              />

              <div className="errorMsg">{this.state.errors.email}</div>
            </div>

            <div className="form-group">
              <label htmlFor="password" className="signin-label">
                Password{" "}
              </label>
              <label htmlFor="password" className="forgot">
                <NavLink to="/Forgotpassword" className="forpas">
                  Forgot Password?{" "}
                </NavLink>
              </label>
              <input
                type="password"
                className="form-control partners-input"
                name="password"
                id="password"
                value={this.state.fields.password}
                onChange={this.handleChange}
              />

              <div className="errorMsg">{this.state.errors.password}</div>
              {(() => {
                switch (this.state.message) {
                  case "UNSUCCESS":
                    return (
                      <div className="errorMsg">
                        {" "}
                        <p>{this.state.errorMsg}</p>
                      </div>
                    );
                  default:
                    return <p></p>;
                }
              })()}
            </div>

            <div className="sign">
              <input
                type="submit"
                className="btn btn-warning partnerbutton"
                onClick={this.handleShow}
                value="Sign-in"
              />
            </div>
            <p>{this.state.serverErr}</p>
          </form>
        </div>
      );
    }
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  // login: userActions.login,
  // logout: userActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(PartnerLogin);
export { connectedLoginPage as PartnerLogin };
