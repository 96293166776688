import React from "react";
// import payment from "../../../assets/images/uday/company/overview-hero.webp";
import payment from "../../../assets/images/uday/New-Images/image/company.svg";
import dpb from "../../../assets/images/dpb.svg";
import api from "../../../assets/images/api.svg";
import lending from "../../../assets/images/lending2.svg";
import investment from "../../../assets/images/investment.svg";
import insurtech from "../../../assets/images/insurtech.svg";
import digital from "../../../assets/images/digital.svg";

const Hero = () => {
  return (
    <>
      <div className="company-hero">
        <div className="company-hero-first-part">
          <div className="col-lg-7 company-child-flex">
            <div className="company-hero-1">
              <h1 className="company-hero-1-h1">
                Building future of an Inclusive Financial Ecosystem
              </h1>
            </div>
            <div className="service-parent">
              <div className="service">
                <div className="service-icon">
                  <img src={dpb} alt="dpb" />
                </div>
                <div className="border-left">
                  <p className="overview-hero-p">Digital Payment & Banking</p>
                </div>
                <div className="service-box service-box-hero">
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        P2P, P2B & B2B payments
                      </p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Merchant acquiring </p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        Merchant Acquiring System
                      </p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        Dual Transaction Service
                      </p>
                    </div>
                  </div>
                  <div className="service-text">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        Digital Banking & Agency Banking
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service">
                <div className="service-icon">
                  <img src={api} alt="api" />
                </div>
                <div className="border-left">
                  <p className="overview-hero-p">API Platforms</p>
                </div>
                <div className="service-box service-box-hero">
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        Card Issuing & Processing
                      </p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        Account Management System
                      </p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        Merchant Acquiring System
                      </p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        Loan Management System
                      </p>
                    </div>
                  </div>
                  <div className="service-text">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        Debt Collection system
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service">
                <div className="service-icon">
                  <img src={lending} alt="lending" />
                </div>
                <div className="border-left">
                  <p className="overview-hero-p">Lending</p>
                </div>
                <div className="service-box service-box-hero">
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Loan aggregator</p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Loan Origination</p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Loan Collections</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Loan Management</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service">
                <div className="service-icon">
                  <img src={investment} alt="investment" />
                </div>
                <div className="border-left">
                  <p className="overview-hero-p">Investment</p>
                </div>
                <div className="service-box service-box-hero">
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Fixed Deposit</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Mutual Funds</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service">
                <div className="service-icon">
                  <img src={insurtech} alt="insurtech" />
                </div>
                <div className="border-left">
                  <p className="overview-hero-p">Insurance</p>
                </div>
                <div className="service-box service-box-hero">
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Term Life Plans​</p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Health Insurance​</p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Endowment Plans​</p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Car Insurance</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Travel Insurance</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="service">
                <div className="service-icon">
                  <img src={digital} alt="digital" />
                </div>
                <div className="border-left">
                  <p className="overview-hero-p">Digital Verification</p>
                </div>
                <div className="service-box service-box-hero">
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">Authenticator App</p>
                    </div>
                  </div>
                  <div className="service-text content">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">
                        Liveness and Facematch
                      </p>
                    </div>
                  </div>
                  <div className="service-text">
                    <div className="service-box-box"></div>
                    <div className="service-box-text">
                      <p className="service-box-text-p">KYC Verification</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 company-hero-second-part">
            <div className="company-hero-2">
              <img src={payment} alt="payment" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
