import React from 'react';
import axios from 'axios';


/**
 CheckerComission component is render  Moneylink comission for profile in checker section.
 @Author : Thrilok Kandra
 @version :1.0
 */

class CheckerComission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      status: '',
      paylinkCommission: [
        { productType: '', originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { productType: '', originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { productType: '', originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { productType: '', originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { productType: '', originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { productType: '', originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { productType: '', originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { productType: '', originationComm: '', loanMgtComm: '', loanRepayComm: '', }
      ],
      collectionBuckets: [{
        collXdays: '', coll30DPD: '', coll60DPD: '', coll90DPD: '', coll120DPD: '', collBET180n360DPD: '', collGT360DPD: '',
      }],
      insuranceCommissions: [
        { premCollectedPercent: '' }
      ]
    };
  }
  componentDidMount() {
    const url = `api/user/profile?email=${localStorage.getItem("email")}`;
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
      .then(response => {
        if (response.status === 200) {
          const contents = response.data;
          console.log('data for 2nd compoennt ===>', contents);
          this.setState({
            data: contents,
            paylinkCommission: contents.data.Profile.paylinkCommission,
            collectionBuckets: contents.data.Profile.collectionBuckets,
            insuranceCommissions: contents.data.Profile.insuranceCommissions
          });
        } else {
          alert('Server error');
        }
      })
      .catch(error => {
        console.error('Server error:', error);
        alert('Server error');
      });
  }

  render() {

    return (
      <div>
        <div className="">
          <p className="signinheader commheader">MONEYLINK COMMISSION</p>
          <form method="post" name="userRegistrationForm" className="formroot">
            <div className="d-flex flex-row mt-3">

              <div className="p-2 payl">
                <div className="form-group">
                  <label className="product">Product</label>
                  {this.state.paylinkCommission.map((paylinkCommission, i) => <div key={i}><label className="form-control loanlabelmak" id="field1" disabled > {paylinkCommission.productType} </label></div>)}

                </div>

              </div>
              <div className="p-2 payl">
                <div className="form-group">
                  <label className="product2">Origination <br /> Commission</label>
                  {this.state.paylinkCommission.map((paylinkCommission, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={paylinkCommission.originationComm + "%"} disabled /></div>)}
                </div>
              </div>
              <div className="p-2 payl">
                <div className="form-group">
                  <label className="product2 loanmanage">Loan Management <br /> Commission</label>
                  {this.state.paylinkCommission.map((paylinkCommission, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={paylinkCommission.loanMgtComm + "%"} disabled /></div>)}
                </div>
              </div>


              <div className="p-2 payl">
                <div className="form-group">
                  <label className="product-re" htmlFor="first">Loan Repayment Collection Commission</label>
                  {this.state.paylinkCommission.map((paylinkCommission, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={paylinkCommission.loanRepayComm + "%"} disabled /></div>)}
                </div>
              </div>
            </div>
          </form>


          <p className="collectionheader" >Collection Buckets</p>
          <form method="post" name="userRegistrationForm" className="formroot">


            <div className="d-flex flex-row mt-3 collmak ">

              <div className="p-2 flexnowrap4">
                <label type="text" className="loanlabelmak3 ebcd" name="" disabled ></label>
              </div>
              <div className="p-2 flexnowrap2">
                <label type="text" className="form-control loanlabelmak5" name="" id="field2" placeholder="X Days" disabled >X Days </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" name="" id="field2" placeholder="30 DPD" disabled > 30 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" name="" id="field2" placeholder="60 DPD" disabled > 60 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" name="" id="field2" placeholder="90 DPD" disabled > 90 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" name="" id="field2" placeholder="120 DPD" disabled > 120 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" name="" id="field2" placeholder="180-360 DPD" disabled > 180-360 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" name="" id="field2" placeholder="360 DPD" disabled > 360 DPD </label>
              </div>
            </div>





            <div className="d-flex flex-nowrap " >
              <div className="p-2 flexnowrapmak">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><label type="text" className="form-control loanlabelmak4" name="" id="field2" disabled>Collection Commission</label></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.collXdays + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.coll30DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.coll60DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.coll90DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.coll120DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.collBET180n360DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.collGT360DPD + "%"} disabled /></div>)}
              </div>
            </div>

          </form>


          <form method="post" name="userRegistrationForm" className="formroot">


            <div className="d-flex flex-nowrap ">
              <div className="p-2 flexnowrapmaker">
                <label className="collpay checkpay"></label>
                <label className="form-control loanlabelmak6" name="" id="field2" >Insurance Commission </label>
              </div>

              <div className="p-2 flexnowrapmak2">
                <label>% of premium collected</label>
                <input type="text" className="form-control comissionfield2" name="" placeholder={this.state.insuranceCommissions[0].premCollectedPercent} disabled />

              </div>





            </div>

          </form>




        </div>
      </div>


    );
  }

}


export default CheckerComission;
