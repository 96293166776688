import React from "react";
import Sidebar from "../Sidebar";
import Header2 from "../CheckerHeader";
import ForgotPin from "../../ForgotPin/ForgotPin";
import axios from 'axios';
import Loader from '../Loader';

/**
 * CheckerProductGL component will render entire product-gl  section of bank for checker section.
 * <p>This allows you to verify the changes made by maker,checker can either approve or reject the changes.</p>
 * <p>Once the changes are done cheecker allowed to submit the changes by entering their user pin which was setup during sign-up.</p>
 * @Author : Thrilok Kandra
 * @version :1.0
 */

class CheckerProductGL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          prodName: "",
          loanGL: "",
          feeGL1: "",
          feeGL2: "",
          interestGL: "",
          otherChargesGL: "",
          losspoolGL: "",
          cliGL: "",
          assetInsurnceGL: "",
          feeGL2Required: "",
          interestGLRequired: "",
          otherChargesGLRequired: "",
          losspoolGLRequired: "",
          cliGLRequired: "",
          assetInsuranceGLRequired: "",
          payLinkIncomeGL: "",
          IsAssetInsuranceGLRequired: "",
          isAssetInsurnceGL: "",
          isCliGL: "",
          isCliGLRequired: "",
          isFeeGL1: "",
          isFeeGL2: "",
          isFeeGL2Required: "",
          isInterestGL: "",
          isInterestGLRequired: "",
          isLoanGL: "",
          isLosspoolGL: "",
          isLosspoolGLRequired: "",
          isOtherChargesGL: "",
          isOtherChargesGLRequired: "",
          isPayLinkIncomeGL: "",
        },
      ],
      status: "",
      editable: false,
      productglrequired: [
        {
          feeGL2Required: false,
          otherChargesGLRequired: false,
          interestGLRequired: false,
          losspoolGLRequired: false,
          cliGLRequired: false,
          assetInsuranceGLRequired: false,
          assetInsuranceGLRequired: false,
          payLinkIncomeGLRequired: false,
        },
      ],
      isAssetInsurnceGLlist: [],
      isCliGLlist: [],
      isFeeGL1list: [],
      isFeeGL2list: [],
      isInterestGLlist: [],
      isLoanGLlist: [],
      isLosspoolGLlist: [],
      isOtherChargesGLlist: [],
      isPayLinkIncomeGLlist: [],
      isAssetInsurnceGLclasslist: [],
      isCliGLclasslist: [],
      isFeeGL1classlist: [],
      isFeeGL2classlist: [],
      isInterestGLclasslist: [],
      isLoanGLclasslist: [],
      isLosspoolGLclasslist: [],
      isOtherChargesGLclasslist: [],
      isPayLinkIncomeGLclasslist: [],
      isAssetInsurnceGLfieldclasslist: [],
      isCliGLfieldclasslist: [],
      isFeeGL1fieldclasslist: [],
      isFeeGL2fieldclasslist: [],
      isInterestGLfieldclasslist: [],
      isLoanGLfieldclasslist: [],
      isLosspoolGLfieldclasslist: [],
      isOtherChargesGLfieldclasslist: [],
      isPayLinkIncomeGLfieldclasslist: [],

      isDisabled: false,
      message: "",
      errorMsg: "",
      backerrors: "",
      errors: [],
    };
    this.toggleChange = this.toggleChange.bind(this);
  }

  handleChange(e) {
    let data = this.state.data;
    data[e.target.name] = e.target.value;
    this.setState({
      data,
    });
  }
  /**
   * This will validate the pin.
   * No Parameters for this method.
   */
  handleSubmitvalidateForm() {
    let errors = this.state.errors;
    let formIsValid = true;
    if (
      this.state.data.pin == "" ||
      this.state.data.pin == null ||
      this.state.data.pin.length != 4
    ) {
      formIsValid = false;
      errors["pin"] = "*Please enter pin.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  /**
   *Submit the changes to server.
   * @param {Event} e event occurs on clicking the submit button.
   */
  handleSubmit = (e) => {
    isDisabledSubmitButton = true;
    e.preventDefault();
    if (this.handleSubmitvalidateForm()) {
      this.setState({ loading: true });
      this.setState({
        errors: [],
      });
      this.setOnAndOffChangeisAssetInsurnceGL();
      this.setOnAndOffChangeisCliGL();
      this.setOnAndOffChangeisFeeGL1();
      this.setOnAndOffChangeisFeeGL2();
      this.setOnAndOffChangeisInterestGL();
      this.setOnAndOffChangeisLoanGL();
      this.setOnAndOffChangeisLosspoolGL();
      this.setOnAndOffChangeisOtherChargesGL();
      this.setOnAndOffChangeisPayLinkIncomeGL();
      this.setOnAndOffsubmitvalue();

      let data = [];
      this.state.data.map(item => {
        item.isEditable && data.push(item)
      })
      const email = localStorage.getItem("email");
      const pin = this.state.data.pin;
      const url = `api/product/saveprodgl?email=${email}&pin=${pin}`;
      axios.post(url, data, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      })
        .then((response) => {
          this.setState({ loading: false });
          const contents = response.data;
          console.log("API response:", contents); // Log the response for debugging
          if (contents.status === 200 && contents.message === "SUCCESS") {
            isDisabledSubmitButton = true;
            this.setState({
              isDisabled: true,
              status: contents.status,
              message: contents.message,
            });
            this.setState({
              isAssetInsurnceGLfieldclasslist: this.state.isAssetInsurnceGLfieldclasslist.map(() => '#e9ecef'),
              isAssetInsurnceGLclasslist: this.state.isAssetInsurnceGLclasslist.map(() => '#bcbcbb'),
              isPayLinkIncomeGLfieldclasslist: this.state.isPayLinkIncomeGLfieldclasslist.map(() => '#e9ecef'),
              isPayLinkIncomeGLclasslist: this.state.isPayLinkIncomeGLclasslist.map(() => '#bcbcbb'),
              isOtherChargesGLfieldclasslist: this.state.isOtherChargesGLfieldclasslist.map(() => '#e9ecef'),
              isOtherChargesGLclasslist: this.state.isOtherChargesGLclasslist.map(() => '#bcbcbb'),
              isLosspoolGLfieldclasslist: this.state.isLosspoolGLfieldclasslist.map(() => '#e9ecef'),
              isLosspoolGLclasslist: this.state.isLosspoolGLclasslist.map(() => '#bcbcbb'),
              isInterestGLfieldclasslist: this.state.isInterestGLfieldclasslist.map(() => '#e9ecef'),
              isInterestGLclasslist: this.state.isInterestGLclasslist.map(() => '#bcbcbb'),
              isFeeGL2fieldclasslist: this.state.isFeeGL2fieldclasslist.map(() => '#e9ecef'),
              isFeeGL2classlist: this.state.isFeeGL2classlist.map(() => '#bcbcbb'),
              isLoanGLfieldclasslist: this.state.isLoanGLfieldclasslist.map(() => '#e9ecef'),
              isLoanGLclasslist: this.state.isLoanGLclasslist.map(() => '#bcbcbb'),
              isCliGLfieldclasslist: this.state.isCliGLfieldclasslist.map(() => '#e9ecef'),
              isCliGLclasslist: this.state.isCliGLclasslist.map(() => '#bcbcbb'),
              isFeeGL1fieldclasslist: this.state.isFeeGL1fieldclasslist.map(() => '#e9ecef'),
              isFeeGL1classlist: this.state.isFeeGL1classlist.map(() => '#bcbcbb'),
            });
            // Call onSubmitChange* functions

          } else {
            isDisabledSubmitButton = false;
            this.setState({
              backerrors: contents.errors,
              status: contents.status,
              message: contents.message,
            });
            this.setonandoffstatusvalue();
            this.onSubmitChangeisAssetInsurnceGL();
            this.onSubmitChangeisCliGL();
            this.onSubmitChangeisFeeGL1();
            this.onSubmitChangeisFeeGL2();
            this.onSubmitChangeisInterestGL();
            this.onSubmitChangeisLoanGL();
            this.onSubmitChangeisLosspoolGL();
            this.onSubmitChangeisOtherChargesGL();
            this.onSubmitChangeisPayLinkIncomeGL();
            contents.errors.forEach((error) => {
              this.setState({
                errorMsg: error.message,
              });
            });
            // this.setonandoffstatusvalue();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          alert("Error fetching data");
        });
    }
  };

  /**
   * Non editable request sets all fields to disabled.
   */

  setNforNonEditable() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      ProductGL.isInterestGL = "N";
      ProductGL.isAssetInsurnceGL = "N";
      ProductGL.isCliGL = "N";
      ProductGL.isFeeGL1 = "N";
      ProductGL.isFeeGL2 = "N";
      ProductGL.isLoanGL = "N";
      ProductGL.isLosspoolGL = "N";
      ProductGL.isOtherChargesGL = "N";
      ProductGL.isPayLinkIncomeGL = "N";
    });
    this.setState({
      data,
    });
  }

  /**
   * Fecthes the data from server
   */

  componentDidMount() {
    this.setState({ loading: true });
    const url = `api/product/productgl?email=${localStorage.getItem("email")}`;
    axios.get(url, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        // Removed 'Access-Control-Allow-Origin' as it's not needed here
      },
    })
      .then((response) => {
        const contents = response.data;
        console.log("API Response:", contents); // Debugging: log the API response
        this.setState({ loading: false });
        if (contents && contents.data && contents.data.ProductGls) {
          this.setState(
            {
              data: contents.data.ProductGls,
              editable: contents.data.editable,
            },
            () => {
              // Call methods to handle additional logic
              // if (this.state.editable) {
              //   this.setNforNonEditable();
              // }
              this.onAndOffChangeisAssetInsurnceGL();
              this.onAndOffChangeisCliGL();
              this.onAndOffChangeisFeeGL1();
              this.onAndOffChangeisFeeGL2();
              this.onAndOffChangeisInterestGL();
              this.onAndOffChangeisLoanGL();
              this.onAndOffChangeisLosspoolGL();
              this.onAndOffChangeisOtherChargesGL();
              this.onAndOffChangeisPayLinkIncomeGL();
              this.setonandoffstatusvalue();
            }
          );
          var isAnyChanges = false;
          contents.data.ProductGls.map(item => {
            if (item.isEditable) {
              isAnyChanges = true;
            }
          })
          if (!isAnyChanges) {
            isDisabledSubmitButton = true;
          }
          console.log("productgldata", this.state.data);
        } else {
          console.error("Invalid API response structure:", contents); // Debugging: log invalid response
          this.setState({
            errorMsg: "Invalid API response structure",
          });
        }
      })
      .catch((error) => {
        // Handle errors from the axios operation
        console.error("Fetch error:", error); // Debugging: log the error
        this.setState({
          errorMsg: "Failed to fetch data. Please try again later.",
        });
      });
  }






  /**
   * Sets the boolean value for assetinsurancegl toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisAssetInsurnceGL() {
    let data = this.state.data;
    let isAssetInsurnceGLlist = this.state.isAssetInsurnceGLlist;
    let isAssetInsurnceGLclasslist = this.state.isAssetInsurnceGLclasslist;
    this.state.data.map((productGl, i) => {
      if ("N" == productGl.isAssetInsurnceGL) {
        productGl.isAssetInsurnceGL = true;
        this.state.isAssetInsurnceGLlist[i] = true;
        this.state.isAssetInsurnceGLclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isAssetInsurnceGLlist,
          isAssetInsurnceGLclasslist,
        });
      } else {
        if ("A" == productGl.isAssetInsurnceGL) {
          productGl.isAssetInsurnceGL = true;
          this.state.isAssetInsurnceGLlist[i] = false;
          this.state.isAssetInsurnceGLclasslist[i] = "#2ab934";
          this.setState({
            data,
            isAssetInsurnceGLlist,
            isAssetInsurnceGLclasslist,
          });
        } else {
          productGl.isAssetInsurnceGL = false;
          this.state.isAssetInsurnceGLlist[i] = false;
          this.state.isAssetInsurnceGLclasslist[i] = "#6c757d";
          this.setState({
            data,
            isAssetInsurnceGLlist,
            isAssetInsurnceGLclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for cligl toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisCliGL() {
    let data = this.state.data;
    let isCliGLlist = this.state.isCliGLlist;
    let isCliGLclasslist = this.state.isCliGLclasslist;
    this.state.data.map((productGl, i) => {
      if ("N" == productGl.isCliGL) {
        productGl.isCliGL = true;
        this.state.isCliGLlist[i] = true;
        this.state.isCliGLclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isCliGLlist,
          isCliGLclasslist,
        });
      } else {
        if ("A" == productGl.isCliGL) {
          productGl.isCliGL = true;
          this.state.isCliGLlist[i] = false;
          this.state.isCliGLclasslist[i] = "#2ab934";
          this.setState({
            data,
            isCliGLlist,
            isCliGLclasslist,
          });
        } else {
          productGl.isCliGL = false;
          this.state.isCliGLlist[i] = false;
          this.state.isCliGLclasslist[i] = "#6c757d";
          this.setState({
            data,
            isCliGLlist,
            isCliGLclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets boolean values based "ON and OFF" from server
   */
  setonandoffstatusvalue() {
    let data = this.state.data;
    let productglrequired = this.state.productglrequired;
    this.state.data.map((productgl, i) => {
      if ("ON" == productgl.feeGL2Required) {
        productglrequired[0].feeGL2Required = true;
        this.state.feeGL2Requireddisable = false;
      } else {
        productglrequired[0].feeGL2Required = false;
        this.state.feeGL2Requireddisable = true;
      }
      if ("ON" == productgl.loanGLRequired) {
        productglrequired[0].loanGLRequired = true;
        this.state.loanGLRequireddisable = false;
      } else {
        productglrequired[0].loanGLRequired = false;
        this.state.loanGLRequireddisable = true;
      }
      if ("ON" == productgl.feeGL1Required) {
        productglrequired[0].feeGL1Required = true;
        this.state.feeGL1Requireddisable = false;
      } else {
        productglrequired[0].feeGL1Required = false;
        this.state.feeGL1Requireddisable = true;
      }
      if ("ON" == productgl.interestGLRequired) {
        productglrequired[0].interestGLRequired = true;
        this.state.interestGLRequireddisable = false;
      } else {
        productglrequired[0].interestGLRequired = false;
        this.state.interestGLRequireddisable = true;
      }
      if ("ON" == productgl.otherChargesGLRequired) {
        productglrequired[0].otherChargesGLRequired = true;
        this.state.otherChargesGLRequireddisable = false;
      } else {
        productglrequired[0].otherChargesGLRequired = false;
        this.state.otherChargesGLRequireddisable = true;
      }
      if ("ON" == productgl.losspoolGLRequired) {
        productglrequired[0].losspoolGLRequired = true;
        this.state.losspoolGLRequireddisable = false;
      } else {
        productglrequired[0].losspoolGLRequired = false;
        this.state.losspoolGLRequireddisable = true;
      }
      if ("ON" == productgl.cliGLRequired) {
        productglrequired[0].cliGLRequired = true;
        this.state.cliGLRequireddisable = false;
      } else {
        productglrequired[0].cliGLRequired = false;
        this.state.cliGLRequireddisable = true;
      }
      if ("ON" == productgl.assetInsuranceGLRequired) {
        productglrequired[0].assetInsuranceGLRequired = true;
        this.state.assetInsuranceGLRequireddisable = false;
      } else {
        productglrequired[0].assetInsuranceGLRequired = false;
        this.state.assetInsuranceGLRequireddisable = true;
      }
      if ("ON" == productgl.payLinkIncomeGLRequired) {
        productglrequired[0].payLinkIncomeGLRequired = true;
        this.state.payLinkIncomeGLRequireddisable = false;
      } else {
        productglrequired[0].payLinkIncomeGLRequired = false;
        this.state.payLinkIncomeGLRequireddisable = true;
      }
    });
    this.setState({
      data,
    });
  }
  /**
   * Captures the changed value for GL Required
   * @param {Event} e event that will call this method
   */
  toggleChange(e) {
    let data = this.state.data;
    let productglrequired = this.state.productglrequired;
    if ("feeGL2Required" == e.target.name) {
      this.state.productglrequired[0].feeGL2Required =
        !this.state.productglrequired[0].feeGL2Required;
    } else if ("interestGLRequired" == e.target.name) {
      this.state.productglrequired[0].interestGLRequired =
        !this.state.productglrequired[0].interestGLRequired;
    } else if ("otherChargesGLRequired" == e.target.name) {
      this.state.productglrequired[0].otherChargesGLRequired =
        !this.state.productglrequired[0].otherChargesGLRequired;
    } else if ("losspoolGLRequired" == e.target.name) {
      this.state.productglrequired[0].losspoolGLRequired =
        !this.state.productglrequired[0].losspoolGLRequired;
    } else if ("cliGLRequired" == e.target.name) {
      this.state.productglrequired[0].cliGLRequired =
        !this.state.productglrequired[0].cliGLRequired;
    } else if ("assetInsuranceGLRequired" == e.target.name) {
      this.state.productglrequired[0].assetInsuranceGLRequired =
        !this.state.productglrequired[0].assetInsuranceGLRequired;
    } else if ("loanGLRequired" == e.target.name) {
      this.state.productglrequired[0].loanGLRequired =
        !this.state.productglrequired[0].loanGLRequired;
    } else {
    }
    this.setState({
      productglrequired,
    });
  }
  /**
   * Sets "ON and OFF" based on boolean values while submitting to server
   */
  setOnAndOffsubmitvalue() {
    let productglrequired = this.state.productglrequired;
    this.state.data.map((productgl, i) => {
      if (true == productglrequired[0].feeGL2Required) {
        productgl.feeGL2Required = "ON";
      } else {
        productgl.feeGL2Required = "OFF";
      }
      if (true == productglrequired[0].loanGLRequired) {
        productgl.loanGLRequired = "ON";
      } else {
        productgl.loanGLRequired = "OFF";
      }
      if (true == productglrequired[0].feeGL1Required) {
        productgl.feeGL1Required = "ON";
      } else {
        productgl.feeGL1Required = "OFF";
      }
      if (true == productglrequired[0].interestGLRequired) {
        productgl.interestGLRequired = "ON";
      } else {
        productgl.interestGLRequired = "OFF";
      }
      if (true == productglrequired[0].otherChargesGLRequired) {
        productgl.otherChargesGLRequired = "ON";
      } else {
        productgl.otherChargesGLRequired = "OFF";
      }
      if (true == productglrequired[0].losspoolGLRequired) {
        productgl.losspoolGLRequired = "ON";
      } else {
        productgl.losspoolGLRequired = "OFF";
      }
      if (true == productglrequired[0].cliGLRequired) {
        productgl.cliGLRequired = "ON";
      } else {
        productgl.cliGLRequired = "OFF";
      }
      if (true == productglrequired[0].assetInsuranceGLRequired) {
        productgl.assetInsuranceGLRequired = "ON";
      } else {
        productgl.assetInsuranceGLRequired = "OFF";
      }
      if (true == productglrequired[0].payLinkIncomeGLRequired) {
        productgl.payLinkIncomeGLRequired = "ON";
      } else {
        productgl.payLinkIncomeGLRequired = "OFF";
      }
    });
  }
  /**
   * Sets the boolean value for feegl1 toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisFeeGL1() {
    let data = this.state.data;
    let isFeeGL1list = this.state.isFeeGL1list;
    let isFeeGL1classlist = this.state.isFeeGL1classlist;
    this.state.data.map((productGl, i) => {
      if ("N" == productGl.isFeeGL1) {
        productGl.isFeeGL1 = true;
        this.state.isFeeGL1list[i] = true;
        this.state.isFeeGL1classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isFeeGL1list,
          isFeeGL1classlist,
        });
      } else {
        if ("A" == productGl.isFeeGL1) {
          productGl.isFeeGL1 = true;
          this.state.isFeeGL1list[i] = false;
          this.state.isFeeGL1classlist[i] = "#2ab934";
          this.setState({
            data,
            isFeeGL1list,
            isFeeGL1classlist,
          });
        } else {
          productGl.isFeeGL1 = false;
          this.state.isFeeGL1list[i] = false;
          this.state.isFeeGL1classlist[i] = "#6c757d";
          this.setState({
            data,
            isFeeGL1list,
            isFeeGL1classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for feegl2 toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisFeeGL2() {
    let data = this.state.data;
    let isFeeGL2list = this.state.isFeeGL2list;
    let isFeeGL2classlist = this.state.isFeeGL2classlist;
    this.state.data.map((productGl, i) => {
      if ("N" == productGl.isFeeGL2) {
        productGl.isFeeGL2 = true;
        this.state.isFeeGL2list[i] = true;
        this.state.isFeeGL2classlist[i] = "#bcbcbb";
        this.setState({
          data,
          isFeeGL2list,
          isFeeGL2classlist,
        });
      } else {
        if ("A" == productGl.isFeeGL2) {
          productGl.isFeeGL2 = true;
          this.state.isFeeGL2list[i] = false;
          this.state.isFeeGL2classlist[i] = "#2ab934";
          this.setState({
            data,
            isFeeGL2list,
            isFeeGL2classlist,
          });
        } else {
          productGl.isFeeGL2 = false;
          this.state.isFeeGL2list[i] = false;
          this.state.isFeeGL2classlist[i] = "#6c757d";
          this.setState({
            data,
            isFeeGL2list,
            isFeeGL2classlist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for interestgl toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisInterestGL() {
    let data = this.state.data;
    let isInterestGLlist = this.state.isInterestGLlist;
    let isInterestGLclasslist = this.state.isInterestGLclasslist;
    this.state.data.map((productGl, i) => {
      if ("N" == productGl.isInterestGL) {
        productGl.isInterestGL = true;
        this.state.isInterestGLlist[i] = true;
        this.state.isInterestGLclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isInterestGLlist,
          isInterestGLclasslist,
        });
      } else {
        if ("A" == productGl.isInterestGL) {
          productGl.isInterestGL = true;
          this.state.isInterestGLlist[i] = false;
          this.state.isInterestGLclasslist[i] = "#2ab934";
          this.setState({
            data,
            isInterestGLlist,
            isInterestGLclasslist,
          });
        } else {
          productGl.isInterestGL = false;
          this.state.isInterestGLlist[i] = false;
          this.state.isInterestGLclasslist[i] = "#6c757d";
          this.setState({
            data,
            isInterestGLlist,
            isInterestGLclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for loangl toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisLoanGL() {
    let data = this.state.data;
    let isLoanGLlist = this.state.isLoanGLlist;
    let isLoanGLclasslist = this.state.isLoanGLclasslist;
    this.state.data.map((productGl, i) => {
      if ("N" == productGl.isLoanGL) {
        productGl.isLoanGL = true;
        this.state.isLoanGLlist[i] = true;
        this.state.isLoanGLclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isLoanGLlist,
          isLoanGLclasslist,
        });
      } else {
        if ("A" == productGl.isLoanGL) {
          productGl.isLoanGL = true;
          this.state.isLoanGLlist[i] = false;
          this.state.isLoanGLclasslist[i] = "#2ab934";
          this.setState({
            data,
            isLoanGLlist,
            isLoanGLclasslist,
          });
        } else {
          productGl.isLoanGL = false;
          this.state.isLoanGLlist[i] = false;
          this.state.isLoanGLclasslist[i] = "#6c757d";
          this.setState({
            data,
            isLoanGLlist,
            isLoanGLclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for LosspollGL toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisLosspoolGL() {
    let data = this.state.data;
    let isLosspoolGLlist = this.state.isLosspoolGLlist;
    let isLosspoolGLclasslist = this.state.isLosspoolGLclasslist;
    this.state.data.map((productGl, i) => {
      if ("N" == productGl.isLosspoolGL) {
        productGl.isLosspoolGL = true;
        this.state.isLosspoolGLlist[i] = true;
        this.state.isLosspoolGLclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isLosspoolGLlist,
          isLosspoolGLclasslist,
        });
      } else {
        if ("A" == productGl.isLosspoolGL) {
          productGl.isLosspoolGL = true;
          this.state.isLosspoolGLlist[i] = false;
          this.state.isLosspoolGLclasslist[i] = "#2ab934";
          this.setState({
            data,
            isLosspoolGLlist,
            isLosspoolGLclasslist,
          });
        } else {
          productGl.isLosspoolGL = false;
          this.state.isLosspoolGLlist[i] = false;
          this.state.isLosspoolGLclasslist[i] = "#6c757d";
          this.setState({
            data,
            isLosspoolGLlist,
            isLosspoolGLclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for Otherchargesgl toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisOtherChargesGL() {
    let data = this.state.data;
    let isOtherChargesGLlist = this.state.isOtherChargesGLlist;
    let isOtherChargesGLclasslist = this.state.isOtherChargesGLclasslist;
    this.state.data.map((productGl, i) => {
      if ("N" == productGl.isOtherChargesGL) {
        productGl.isOtherChargesGL = true;
        this.state.isOtherChargesGLlist[i] = true;
        this.state.isOtherChargesGLclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isOtherChargesGLlist,
          isOtherChargesGLclasslist,
        });
      } else {
        if ("A" == productGl.isOtherChargesGL) {
          productGl.isOtherChargesGL = true;
          this.state.isOtherChargesGLlist[i] = false;
          this.state.isOtherChargesGLclasslist[i] = "#2ab934";
          this.setState({
            data,
            isOtherChargesGLlist,
            isOtherChargesGLclasslist,
          });
        } else {
          productGl.isOtherChargesGL = false;
          this.state.isOtherChargesGLlist[i] = false;
          this.state.isOtherChargesGLclasslist[i] = "#6c757d";
          this.setState({
            data,
            isOtherChargesGLlist,
            isOtherChargesGLclasslist,
          });
        }
      }
    });
  }
  /**
   * Sets the boolean value for paylinkincomegl toggle button,disable buttons and background color after submitting data to server.
   */
  onSubmitChangeisPayLinkIncomeGL() {
    let data = this.state.data;
    let isPayLinkIncomeGLlist = this.state.isPayLinkIncomeGLlist;
    let isPayLinkIncomeGLclasslist = this.state.isPayLinkIncomeGLclasslist;
    this.state.data.map((productGl, i) => {
      if ("N" == productGl.isPayLinkIncomeGL) {
        productGl.isPayLinkIncomeGL = true;
        this.state.isPayLinkIncomeGLlist[i] = true;
        this.state.isPayLinkIncomeGLclasslist[i] = "#bcbcbb";
        this.setState({
          data,
          isPayLinkIncomeGLlist,
          isPayLinkIncomeGLclasslist,
        });
      } else {
        if ("A" == productGl.isPayLinkIncomeGL) {
          productGl.isPayLinkIncomeGL = true;
          this.state.isPayLinkIncomeGLlist[i] = false;
          this.state.isPayLinkIncomeGLclasslist[i] = "#2ab934";
          this.setState({
            data,
            isPayLinkIncomeGLlist,
            isPayLinkIncomeGLclasslist,
          });
        } else {
          productGl.isPayLinkIncomeGL = false;
          this.state.isPayLinkIncomeGLlist[i] = false;
          this.state.isPayLinkIncomeGLclasslist[i] = "#6c757d";
          this.setState({
            data,
            isPayLinkIncomeGLlist,
            isPayLinkIncomeGLclasslist,
          });
        }
      }
    });
  }

  /**
   * Sets the boolean value for feegl1 toggle button,disable buttons and background color
   */

  setOnAndOffChangeisFeeGL1() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      if (false == this.state.isFeeGL1list[i]) {
        if (true == ProductGL.isFeeGL1) {
          ProductGL.isFeeGL1 = "A";
          this.setState({
            data,
          });
        } else {
          ProductGL.isFeeGL1 = "R";
          this.setState({
            data,
          });
        }
      } else {
        ProductGL.isFeeGL1 = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * Sets the boolean value for cligl toggle button,disable buttons and background color
   */
  setOnAndOffChangeisCliGL() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      if (false == this.state.isCliGLlist[i]) {
        if (true == ProductGL.isCliGL) {
          ProductGL.isCliGL = "A";
          this.setState({
            data,
          });
        } else {
          ProductGL.isCliGL = "R";
          this.setState({
            data,
          });
        }
      } else {
        ProductGL.isCliGL = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * Sets the boolean value for feegl2 toggle button,disable buttons and background color
   */
  setOnAndOffChangeisFeeGL2() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      if (false == this.state.isFeeGL2list[i]) {
        if (true == ProductGL.isFeeGL2) {
          ProductGL.isFeeGL2 = "A";
          this.setState({
            data,
          });
        } else {
          ProductGL.isFeeGL2 = "R";
          this.setState({
            data,
          });
        }
      } else {
        ProductGL.isFeeGL2 = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * Sets the boolean value for interestgl toggle button,disable buttons and background color
   */
  setOnAndOffChangeisInterestGL() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      if (false == this.state.isInterestGLlist[i]) {
        if (true == ProductGL.isInterestGL) {
          ProductGL.isInterestGL = "A";
          this.setState({
            data,
          });
        } else {
          ProductGL.isInterestGL = "R";
          this.setState({
            data,
          });
        }
      } else {
        ProductGL.isInterestGL = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * Sets the boolean value for loangl toggle button,disable buttons and background color
   */
  setOnAndOffChangeisLoanGL() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      if (false == this.state.isLoanGLlist[i]) {
        if (true == ProductGL.isLoanGL) {
          ProductGL.isLoanGL = "A";
          this.setState({
            data,
          });
        } else {
          ProductGL.isLoanGL = "R";
          this.setState({
            data,
          });
        }
      } else {
        ProductGL.isLoanGL = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * Sets the boolean value for losspoolgl toggle button,disable buttons and background color
   */
  setOnAndOffChangeisLosspoolGL() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      if (false == this.state.isLosspoolGLlist[i]) {
        if (true == ProductGL.isLosspoolGL) {
          ProductGL.isLosspoolGL = "A";
          this.setState({
            data,
          });
        } else {
          ProductGL.isLosspoolGL = "R";
          this.setState({
            data,
          });
        }
      } else {
        ProductGL.isLosspoolGL = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * Sets the boolean value for otherchargesgl toggle button,disable buttons and background color
   */
  setOnAndOffChangeisOtherChargesGL() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      if (false == this.state.isOtherChargesGLlist[i]) {
        if (true == ProductGL.isOtherChargesGL) {
          ProductGL.isOtherChargesGL = "A";
          this.setState({
            data,
          });
        } else {
          ProductGL.isOtherChargesGL = "R";
          this.setState({
            data,
          });
        }
      } else {
        ProductGL.isOtherChargesGL = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * Sets the boolean value for paylinkincomegl toggle button,disable buttons and background color
   */
  setOnAndOffChangeisPayLinkIncomeGL() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      if (false == this.state.isPayLinkIncomeGLlist[i]) {
        if (true == ProductGL.isPayLinkIncomeGL) {
          ProductGL.isPayLinkIncomeGL = "A";
          this.setState({
            data,
          });
        } else {
          ProductGL.isPayLinkIncomeGL = "R";
          this.setState({
            data,
          });
        }
      } else {
        ProductGL.isPayLinkIncomeGL = "N";
        this.setState({
          data,
        });
      }
    });
  }
  /**
   * @typedef {productGlData} productGlData
   * @property {string} feeGL1 indicates feeGL1 of loan product type
   * @property {String} feeGL2  indicates feeGL2 of loan product type
   * @property {string} cliGL indicates cliGL of loan product type
   * @property {String} interestGL indicates interestGL of loan product type
   * @property {String} otherChargesGL indicates otherchargesGL of loan product type
   * @property {String} payLinkIncomeGL indicates payLinkIncomeGL of loan product type
   * @property {String} assetInsurnceGL indicates assetInsuranceGL of loan product type
   * @property {String} loanGL indicates loanGL of loan product type
   * @property {String} losspoolGL indicates losspoolGL of loan product type
   */
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {productGlData} productGlData data and index of data.
   */
  toggleChangeIsLoangl(e, i) {
    let data = this.state.data;
    let isLoanGLclasslist = this.state.isLoanGLclasslist;
    this.state.data[i].isLoanGL = !this.state.data[i].isLoanGL;
    if (true == this.state.data[i].isLoanGL) {
      this.state.isLoanGLclasslist[i] = "#2ab934";
    } else {
      this.state.isLoanGLclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isLoanGLclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {productGlData} productGlData data and index of data.
   */
  toggleChangeIsFeeGL1(e, i) {
    let data = this.state.data;
    let isFeeGL1classlist = this.state.isFeeGL1classlist;
    this.state.data[i].isFeeGL1 = !this.state.data[i].isFeeGL1;
    if (true == this.state.data[i].isFeeGL1) {
      this.state.isFeeGL1classlist[i] = "#2ab934";
    } else {
      this.state.isFeeGL1classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isFeeGL1classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {productGlData} productGlData data and index of data.
   */
  toggleChangeIsFeeGL2(e, i) {
    let data = this.state.data;

    let isFeeGL2classlist = this.state.isFeeGL2classlist;
    this.state.data[i].isFeeGL2 = !this.state.data[i].isFeeGL2;
    if (true == this.state.data[i].isFeeGL2) {
      this.state.isFeeGL2classlist[i] = "#2ab934";
    } else {
      this.state.isFeeGL2classlist[i] = "#6c757d";
    }
    this.setState({
      data,
      isFeeGL2classlist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {productGlData} productGlData data and index of data.
   */
  toggleChangeIsInterestGL(e, i) {
    let data = this.state.data;
    let isInterestGLclasslist = this.state.isInterestGLclasslist;
    this.state.data[i].isInterestGL = !this.state.data[i].isInterestGL;
    if (true == this.state.data[i].isInterestGL) {
      this.state.isInterestGLclasslist[i] = "#2ab934";
    } else {
      this.state.isInterestGLclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isInterestGLclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {productGlData} productGlData data and index of data.
   */
  toggleChangeIsOtherChargesGL(e, i) {
    let data = this.state.data;
    let isOtherChargesGLclasslist = this.state.isOtherChargesGLclasslist;
    this.state.data[i].isOtherChargesGL = !this.state.data[i].isOtherChargesGL;
    if (true == this.state.data[i].isOtherChargesGL) {
      this.state.isOtherChargesGLclasslist[i] = "#2ab934";
    } else {
      this.state.isOtherChargesGLclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isOtherChargesGLclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {productGlData} productGlData data and index of data.
   */
  toggleChangeIsLosspoolGL(e, i) {
    let data = this.state.data;
    let isLosspoolGLclasslist = this.state.isLosspoolGLclasslist;
    this.state.data[i].isLosspoolGL = !this.state.data[i].isLosspoolGL;
    if (true == this.state.data[i].isLosspoolGL) {
      this.state.isLosspoolGLclasslist[i] = "#2ab934";
    } else {
      this.state.isLosspoolGLclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isLosspoolGLclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {productGlData} productGlData data and index of data.
   */
  toggleChangeIsCliGL(e, i) {
    let data = this.state.data;
    let isCliGLclasslist = this.state.isCliGLclasslist;
    this.state.data[i].isCliGL = !this.state.data[i].isCliGL;
    if (true == this.state.data[i].isCliGL) {
      this.state.isCliGLclasslist[i] = "#2ab934";
    } else {
      this.state.isCliGLclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isCliGLclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {productGlData} productGlData data and index of data.
   */
  toggleChangeIsAssetInsurnceGL(e, i) {
    let data = this.state.data;
    let isAssetInsurnceGLclasslist = this.state.isAssetInsurnceGLclasslist;
    this.state.data[i].isAssetInsurnceGL =
      !this.state.data[i].isAssetInsurnceGL;
    if (true == this.state.data[i].isAssetInsurnceGL) {
      this.state.isAssetInsurnceGLclasslist[i] = "#2ab934";
    } else {
      this.state.isAssetInsurnceGLclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isAssetInsurnceGLclasslist,
    });
  }
  /**
   * Captures the changes in data array on event(onChange) e
   *
   * @param {Event} e Event on which the method is called.
   * @param {productGlData} productGlData data and index of data.
   */
  toggleChangeIsPayLinkIncomeGL(e, i) {
    let data = this.state.data;
    let isPayLinkIncomeGLclasslist = this.state.isPayLinkIncomeGLclasslist;
    this.state.data[i].isPayLinkIncomeGL =
      !this.state.data[i].isPayLinkIncomeGL;
    if (true == this.state.data[i].isPayLinkIncomeGL) {
      this.state.isPayLinkIncomeGLclasslist[i] = "#2ab934";
    } else {
      this.state.isPayLinkIncomeGLclasslist[i] = "#6c757d";
    }
    this.setState({
      data,
      isPayLinkIncomeGLclasslist,
    });
  }
  /**
   * Sets the boolean value for assetinsurancegl toggle button,disable buttons and background color.
   */
  onAndOffChangeisAssetInsurnceGL() {
    let data = this.state.data;
    let isAssetInsurnceGLlist = this.state.isAssetInsurnceGLlist;
    let isAssetInsurnceGLclasslist = this.state.isAssetInsurnceGLclasslist;
    this.state.data.map((ProductGL, i) => {
      if ("N" == ProductGL.isAssetInsurnceGL) {
        ProductGL.isAssetInsurnceGL = true;
        this.state.isAssetInsurnceGLlist[i] = true;
        this.state.isAssetInsurnceGLclasslist[i] = "#bcbcbb";
        this.state.isAssetInsurnceGLfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isAssetInsurnceGLlist,
          isAssetInsurnceGLclasslist,
        });
      } else {
        ProductGL.isAssetInsurnceGL = true;
        this.state.isAssetInsurnceGLlist[i] = false;
        this.state.isAssetInsurnceGLclasslist[i] = "#2ab934";
        this.state.isAssetInsurnceGLfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isAssetInsurnceGLlist,
          isAssetInsurnceGLclasslist,
        });
      }
    });
  }

  toggleChangestatus(e) {
    let data = this.state.data;
    if ("approved" == e.target.name) {
      this.state.data.map((ProductGL) => {
        ProductGL.status = "approved";
      });
    } else {
      this.state.data.map((ProductGL) => {
        ProductGL.status = "rejected";
      });
    }
  }
  /**
   * Sets the boolean value for cligl toggle button,disable buttons and background color.
   */
  onAndOffChangeisCliGL() {
    let data = this.state.data;
    let isCliGLlist = this.state.isCliGLlist;
    let isCliGLclasslist = this.state.isCliGLclasslist;
    this.state.data.map((ProductGL, i) => {
      if ("N" == ProductGL.isCliGL) {
        ProductGL.isCliGL = true;
        this.state.isCliGLlist[i] = true;
        this.state.isCliGLclasslist[i] = "#bcbcbb";
        this.state.isCliGLfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isCliGLlist,
          isCliGLclasslist,
        });
      } else {
        ProductGL.isCliGL = true;
        this.state.isCliGLlist[i] = false;
        this.state.isCliGLclasslist[i] = "#2ab934";
        this.state.isCliGLfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isCliGLlist,
          isCliGLclasslist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for feegl1 toggle button,disable buttons and background color.
   */

  onAndOffChangeisFeeGL1() {
    let data = this.state.data;
    let isFeeGL1list = this.state.isFeeGL1list;
    let isFeeGL1classlist = this.state.isFeeGL1classlist;
    this.state.data.map((ProductGL, i) => {
      if ("N" == ProductGL.isFeeGL1) {
        ProductGL.isFeeGL1 = true;
        this.state.isFeeGL1list[i] = true;
        this.state.isFeeGL1classlist[i] = "#bcbcbb";
        this.state.isFeeGL1fieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isFeeGL1list,
          isFeeGL1classlist,
        });
      } else {
        ProductGL.isFeeGL1 = true;
        this.state.isFeeGL1list[i] = false;
        this.state.isFeeGL1classlist[i] = "#2ab934";
        this.state.isFeeGL1fieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isFeeGL1list,
          isFeeGL1classlist,
        });
      }
    });
  }
  /**
   * Sets the boolean value for feegl2 toggle button,disable buttons and background color.
   */
  onAndOffChangeisFeeGL2() {
    let data = this.state.data;
    let isFeeGL2list = this.state.isFeeGL2list;
    let isFeeGL2classlist = this.state.isFeeGL2classlist;
    this.state.data.map((ProductGL, i) => {
      if ("N" == ProductGL.isFeeGL2) {
        ProductGL.isFeeGL2 = true;
        this.state.isFeeGL2list[i] = true;
        this.state.isFeeGL2classlist[i] = "#bcbcbb";
        this.state.isFeeGL2fieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isFeeGL2list,
          isFeeGL2classlist,
        });
      } else {
        ProductGL.isFeeGL2 = true;
        this.state.isFeeGL2list[i] = false;
        this.state.isFeeGL2classlist[i] = "#2ab934";
        this.state.isFeeGL2fieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isFeeGL2list,
          isFeeGL2classlist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for interestgl toggle button,disable buttons and background color.
   */

  onAndOffChangeisInterestGL() {
    let data = this.state.data;
    let isInterestGLlist = this.state.isInterestGLlist;
    let isInterestGLclasslist = this.state.isInterestGLclasslist;
    this.state.data.map((ProductGL, i) => {
      if ("N" == ProductGL.isInterestGL) {
        ProductGL.isInterestGL = true;
        this.state.isInterestGLlist[i] = true;
        this.state.isInterestGLclasslist[i] = "#bcbcbb";
        this.state.isInterestGLfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isInterestGLlist,
          isInterestGLclasslist,
        });
      } else {
        ProductGL.isInterestGL = true;
        this.state.isInterestGLlist[i] = false;
        this.state.isInterestGLclasslist[i] = "#2ab934";
        this.state.isInterestGLfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isInterestGLlist,
          isInterestGLclasslist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for loangl toggle button,disable buttons and background color.
   */

  onAndOffChangeisLoanGL() {
    let data = this.state.data;
    let isLoanGLlist = this.state.isLoanGLlist;
    let isLoanGLclasslist = this.state.isLoanGLclasslist;
    this.state.data.map((ProductGL, i) => {
      if ("N" == ProductGL.isLoanGL) {
        ProductGL.isLoanGL = true;
        this.state.isLoanGLlist[i] = true;
        this.state.isLoanGLclasslist[i] = "#bcbcbb";
        this.state.isLoanGLfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isLoanGLlist,
          isLoanGLclasslist,
        });
      } else {
        ProductGL.isLoanGL = true;
        this.state.isLoanGLlist[i] = false;
        this.state.isLoanGLclasslist[i] = "#2ab934";
        this.state.isLoanGLfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isLoanGLlist,
          isLoanGLclasslist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for losspoolgl toggle button,disable buttons and background color.
   */
  onAndOffChangeisLosspoolGL() {
    let data = this.state.data;
    let isLosspoolGLlist = this.state.isLosspoolGLlist;
    let isLosspoolGLclasslist = this.state.isLosspoolGLclasslist;
    this.state.data.map((ProductGL, i) => {
      if ("N" == ProductGL.isLosspoolGL) {
        ProductGL.isLosspoolGL = true;
        this.state.isLosspoolGLlist[i] = true;
        this.state.isLosspoolGLclasslist[i] = "#bcbcbb";
        this.state.isLosspoolGLfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isLosspoolGLlist,
          isLosspoolGLclasslist,
        });
      } else {
        ProductGL.isLosspoolGL = true;
        this.state.isLosspoolGLlist[i] = false;
        this.state.isLosspoolGLclasslist[i] = "#2ab934";
        this.state.isLosspoolGLfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isLosspoolGLlist,
          isLosspoolGLclasslist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for otherchargesgl toggle button,disable buttons and background color.
   */

  onAndOffChangeisOtherChargesGL() {
    let data = this.state.data;
    let isOtherChargesGLlist = this.state.isOtherChargesGLlist;
    let isOtherChargesGLclasslist = this.state.isOtherChargesGLclasslist;
    this.state.data.map((ProductGL, i) => {
      if ("N" == ProductGL.isOtherChargesGL) {
        ProductGL.isOtherChargesGL = true;
        this.state.isOtherChargesGLlist[i] = true;
        this.state.isOtherChargesGLclasslist[i] = "#bcbcbb";
        this.state.isOtherChargesGLfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isOtherChargesGLlist,
          isOtherChargesGLclasslist,
        });
      } else {
        ProductGL.isOtherChargesGL = true;
        this.state.isOtherChargesGLlist[i] = false;
        this.state.isOtherChargesGLclasslist[i] = "#2ab934";
        this.state.isOtherChargesGLfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isOtherChargesGLlist,
          isOtherChargesGLclasslist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for paylinkincomegl toggle button,disable buttons and background color.
   */
  onAndOffChangeisPayLinkIncomeGL() {
    let data = this.state.data;
    let isPayLinkIncomeGLlist = this.state.isPayLinkIncomeGLlist;
    let isPayLinkIncomeGLclasslist = this.state.isPayLinkIncomeGLclasslist;
    this.state.data.map((ProductGL, i) => {
      if ("N" == ProductGL.isPayLinkIncomeGL) {
        ProductGL.isPayLinkIncomeGL = true;
        this.state.isPayLinkIncomeGLlist[i] = true;
        this.state.isPayLinkIncomeGLclasslist[i] = "#bcbcbb";
        this.state.isPayLinkIncomeGLfieldclasslist[i] = "#e9ecef";
        this.setState({
          data,
          isPayLinkIncomeGLlist,
          isPayLinkIncomeGLclasslist,
        });
      } else {
        ProductGL.isPayLinkIncomeGL = true;
        this.state.isPayLinkIncomeGLlist[i] = false;
        this.state.isPayLinkIncomeGLclasslist[i] = "#2ab934";
        this.state.isPayLinkIncomeGLfieldclasslist[i] = "#bce394";
        this.setState({
          data,
          isPayLinkIncomeGLlist,
          isPayLinkIncomeGLclasslist,
        });
      }
    });
  }

  /**
   * Sets the boolean value for assetinsurancegl toggle button,disable buttons and background color
   */

  setOnAndOffChangeisAssetInsurnceGL() {
    let data = this.state.data;
    this.state.data.map((ProductGL, i) => {
      if (false == this.state.isAssetInsurnceGLlist[i]) {
        if (true == ProductGL.isAssetInsurnceGL) {
          ProductGL.isAssetInsurnceGL = "A";
          this.setState({
            data,
          });
        } else {
          ProductGL.isAssetInsurnceGL = "R";
          this.setState({
            data,
          });
        }
      } else {
        ProductGL.isAssetInsurnceGL = "N";
        this.setState({
          data,
        });
      }
    });
  }

  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "CHECKER" == localStorage.getItem("role")
    ) {
      return (
        <div>
          <Header2 />
          <div id="wrapper">
            <Sidebar />
            {/* {(() => {
              if (this.state.editable) {
                return (
                  <div className="errorMsg">
                    {" "}
                    <p className="toperror">
                      There are no Changes Request from Maker. A new request can
                      be submitted once Maker does the changes
                    </p>
                  </div>
                );
              }
            })()} */}
            <div id="maincontent" className="content">
              <section>
                <div className="backloan">
                  <div className="container-fluid promix">
                    <p className="Productgl">PRODUCT GL</p>
                    <p className="subtext">Account Numbers referring to GLs</p>
                    <form
                      method="post"
                      name="userRegistrationForm"
                      className="formgls"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-xl-7 reduce">
                          <div className="d-flex flex-row mt-3 ">
                            <div className="p-2 productglcoloms">
                              <div className="form-group">
                                <label className="labelglch">Product</label>
                                {this.state.data.map((ProductGL, i) => (
                                  <div key={i}>
                                    <label
                                      className="form-control loanlabelcheck"
                                      name="prodName"
                                      id="field1"
                                      disabled
                                    >
                                      {" "}
                                      {ProductGL.prodName}{" "}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="p-2 progl">
                              <div className="form-group">
                                <label className="labelglrowchecker"></label>
                                <label
                                  className="form-control formprulesch loanlabelmak7"
                                  name=""
                                  id="field1"
                                  disabled
                                >
                                  {" "}
                                  Loan GL
                                </label>
                              </div>
                              <div className="form-group">
                                {this.state.data.map((ProductGL, i) => (
                                  <div
                                    key={i}
                                    className="form-group aligncenter inputgrayfield"
                                    style={{
                                      backgroundColor:
                                        this.state.isLoanGLfieldclasslist[i],
                                    }}
                                  >
                                    <label
                                      type="text"
                                      className="form-control formpprofilefield"
                                      name="loangl"
                                      onChange={this.handleChange.bind(this)}
                                      disabled
                                    >
                                      {" "}
                                      {ProductGL.loanGL}
                                    </label>
                                    <label className="switchtoggle">
                                      <input
                                        type="checkbox"
                                        name="isLoangl"
                                        onChange={(e) => {
                                          this.toggleChangeIsLoangl(e, i);
                                        }}
                                        checked={ProductGL.isLoanGL}
                                        disabled={this.state.isLoanGLlist[i]}
                                      />
                                      <div
                                        className="checkerslider round"
                                        style={{
                                          backgroundColor:
                                            this.state.isLoanGLclasslist[i],
                                        }}
                                      ></div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="p-2 progl">
                              <div className="form-group">
                                <label className="labelglrowchecker"></label>
                                <label
                                  className="form-control formprulesch loanlabelmak7"
                                  name=""
                                  id="field1"
                                  disabled
                                >
                                  {" "}
                                  Fee GL 1
                                </label>
                              </div>
                              <div className="form-group">
                                {this.state.data.map((ProductGL, i) => (
                                  <div
                                    key={i}
                                    className="form-group aligncenter inputgrayfield"
                                    style={{
                                      backgroundColor:
                                        this.state.isFeeGL1fieldclasslist[i],
                                    }}
                                  >
                                    <label
                                      type="text"
                                      className="form-control formpprofilefield"
                                      name="feegl1"
                                      onChange={this.handleChange.bind(this)}
                                      disabled
                                    >
                                      {" "}
                                      {ProductGL.feeGL1}
                                    </label>
                                    <label className="switchtoggle">
                                      <input
                                        type="checkbox"
                                        name="isFeeGL1"
                                        onChange={(e) => {
                                          this.toggleChangeIsFeeGL1(e, i);
                                        }}
                                        checked={ProductGL.isFeeGL1}
                                        disabled={this.state.isFeeGL1list[i]}
                                      />
                                      <div
                                        className="checkerslider round"
                                        style={{
                                          backgroundColor:
                                            this.state.isFeeGL1classlist[i],
                                        }}
                                      ></div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="p-2 progl">
                              <div className="form-group">
                                <div className="form-group aligncenter inputgraygl">
                                  <label
                                    type="text"
                                    className="form-control formpprofilegl"
                                  >
                                    {this.state.data[0].feeGL2Required}{" "}
                                  </label>
                                  <label className="switchtoggle">
                                    <input
                                      type="checkbox"
                                      name="feeGL2Required"
                                      checked={
                                        this.state.productglrequired[0]
                                          .feeGL2Required
                                      }
                                      onChange={this.toggleChange.bind(this)}
                                    />
                                    <div className="checkerslider round"></div>
                                  </label>
                                </div>
                                <label className="labelgl">GL Required </label>
                                <label
                                  className="form-control formprulesch loanlabelmak7"
                                  name=""
                                  id="field1"
                                  disabled
                                >
                                  {" "}
                                  Fee GL 2
                                </label>
                              </div>

                              <div className="form-group">
                                {this.state.data.map((ProductGL, i) => (
                                  <div
                                    key={i}
                                    className="form-group aligncenter inputgrayfield"
                                    style={{
                                      backgroundColor:
                                        this.state.isFeeGL2fieldclasslist[i],
                                    }}
                                  >
                                    <label
                                      type="text"
                                      className="form-control formpprofilefield"
                                      name="feegl2"
                                      onChange={this.handleChange.bind(this)}
                                      disabled
                                    >
                                      {ProductGL.feeGL2}
                                    </label>
                                    <label className="switchtoggle">
                                      <input
                                        type="checkbox"
                                        name="isFeeGL2"
                                        onChange={(e) => {
                                          this.toggleChangeIsFeeGL2(e, i);
                                        }}
                                        checked={ProductGL.isFeeGL2}
                                        disabled={this.state.isFeeGL2list[i]}
                                      />
                                      <div
                                        className="checkerslider round"
                                        style={{
                                          backgroundColor:
                                            this.state.isFeeGL2classlist[i],
                                        }}
                                      ></div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="p-2 progl">
                              <div className="form-group">
                                <div className="form-group aligncenter inputgraygl">
                                  <label
                                    type="text"
                                    className="form-control formpprofilegl"
                                  >
                                    {this.state.data[0].interestGLRequired}{" "}
                                  </label>
                                  <label className="switchtoggle">
                                    <input
                                      type="checkbox"
                                      name="interestGLRequired"
                                      checked={
                                        this.state.productglrequired[0]
                                          .interestGLRequired
                                      }
                                      onChange={this.toggleChange.bind(this)}
                                    />
                                    <div className="checkerslider round"></div>
                                  </label>
                                </div>

                                <label className="labelgl">GL Required </label>
                                <label
                                  className="form-control formprulesch loanlabelmak7"
                                  name=""
                                  id="field1"
                                  disabled
                                >
                                  {" "}
                                  Interest GL
                                </label>
                              </div>
                              <div className="form-group">
                                {this.state.data.map((ProductGL, i) => (
                                  <div
                                    key={i}
                                    className="form-group aligncenter inputgrayfield"
                                    style={{
                                      backgroundColor:
                                        this.state.isInterestGLfieldclasslist[
                                        i
                                        ],
                                    }}
                                  >
                                    <label
                                      type="text"
                                      className="form-control formpprofilefield"
                                      name="interestglsa"
                                      onChange={this.handleChange.bind(this)}
                                      disabled
                                    >
                                      {" "}
                                      {ProductGL.interestGL}{" "}
                                    </label>
                                    <label className="switchtoggle">
                                      <input
                                        type="checkbox"
                                        name="isInterestGLsa"
                                        onChange={(e) => {
                                          this.toggleChangeIsInterestGL(e, i);
                                        }}
                                        checked={ProductGL.isInterestGL}
                                        disabled={
                                          this.state.isInterestGLlist[i]
                                        }
                                      />
                                      <div
                                        className="checkerslider round"
                                        style={{
                                          backgroundColor:
                                            this.state.isInterestGLclasslist[i],
                                        }}
                                      ></div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="p-2 progl">
                              <div className="form-group">
                                <div className="form-group aligncenter inputgraygl">
                                  <label
                                    type="text"
                                    className="form-control formpprofilegl"
                                  >
                                    {this.state.data[0].otherChargesGLRequired}{" "}
                                  </label>
                                  <label className="switchtoggle">
                                    <input
                                      type="checkbox"
                                      name="otherChargesGLRequired"
                                      checked={
                                        this.state.productglrequired[0]
                                          .otherChargesGLRequired
                                      }
                                      onChange={this.toggleChange.bind(this)}
                                    />
                                    <div className="checkerslider round"></div>
                                  </label>
                                </div>
                                <label className="labelgl">GL Required </label>
                                <label
                                  className="form-control formprulesch loanlabelmak7"
                                  name=""
                                  id="field1"
                                  disabled
                                >
                                  {" "}
                                  Other Charges GL
                                </label>
                              </div>
                              <div className="form-group">
                                {this.state.data.map((ProductGL, i) => (
                                  <div
                                    key={i}
                                    className="form-group aligncenter inputgrayfield"
                                    style={{
                                      backgroundColor:
                                        this.state.isOtherChargesGLfieldclasslist[i],
                                    }}
                                  >
                                    <label
                                      type="text"
                                      className="form-control formpprofilefield"
                                      name="interestglsa"
                                      onChange={this.handleChange.bind(this)}
                                      disabled
                                    >
                                      {ProductGL.otherChargesGL}
                                    </label>
                                    <label className="switchtoggle">
                                      <input
                                        type="checkbox"
                                        name="isOtherChargesGL"
                                        onChange={(e) => {
                                          this.toggleChangeIsOtherChargesGL(
                                            e,
                                            i
                                          );
                                        }}
                                        checked={ProductGL.isOtherChargesGL}
                                        disabled={
                                          this.state.isOtherChargesGLlist[i]
                                        }
                                      />
                                      <div
                                        className="checkerslider round"
                                        style={{
                                          backgroundColor:
                                            this.state
                                              .isOtherChargesGLclasslist[i],
                                        }}
                                      ></div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-xl-5 reduce">
                          <div className="d-flex flex-row mt-3 ">
                            <div className="p-2 md-trial">
                              <div className="form-group">
                                <label className="labelglrowgch">Product</label>
                                {this.state.data.map((ProductGL, i) => (
                                  <div key={i}>
                                    <label
                                      className="form-control checkerprogl loanlabelcheck"
                                      name="prodName"
                                      id="field1"
                                      disabled
                                    >
                                      {" "}
                                      {ProductGL.prodName}{" "}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="p-2 progl2">
                              <div className="form-group">
                                <div className="form-group aligncenter inputgraygl">
                                  <label
                                    type="text"
                                    className="form-control formpprofilegl"
                                  >
                                    {this.state.data[0].losspoolGLRequired}{" "}
                                  </label>
                                  <label className="switchtoggle">
                                    <input
                                      type="checkbox"
                                      name="losspoolGLRequired"
                                      checked={
                                        this.state.productglrequired[0]
                                          .losspoolGLRequired
                                      }
                                      onChange={this.toggleChange.bind(this)}
                                    />
                                    <div className="checkerslider round"></div>
                                  </label>
                                </div>
                                <label className="labelgl">GL Required </label>
                                <label
                                  className="form-control formprulesch loanlabelmak7 secondsec"
                                  name=""
                                  id="field1"
                                  disabled
                                >
                                  {" "}
                                  Loss Pool GL
                                </label>
                              </div>
                              <div className="form-group">
                                {this.state.data.map((ProductGL, i) => (
                                  <div
                                    key={i}
                                    className="form-group aligncenter inputgrayfield"
                                    style={{
                                      backgroundColor:
                                        this.state.isLosspoolGLfieldclasslist[
                                        i
                                        ],
                                    }}
                                  >
                                    <label
                                      type="text"
                                      className="form-control formpprofilefield"
                                      name="interestglsa"
                                      onChange={this.handleChange.bind(this)}
                                      disabled
                                    >
                                      {" "}
                                      {ProductGL.losspoolGL}{" "}
                                    </label>
                                    <label className="switchtoggle">
                                      <input
                                        type="checkbox"
                                        name="isLosspoolGLsa"
                                        onChange={(e) => {
                                          this.toggleChangeIsLosspoolGL(e, i);
                                        }}
                                        checked={ProductGL.isLosspoolGL}
                                        disabled={
                                          this.state.isLosspoolGLlist[i]
                                        }
                                      />
                                      <div
                                        className="checkerslider round"
                                        style={{
                                          backgroundColor:
                                            this.state.isLosspoolGLclasslist[i],
                                        }}
                                      ></div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="p-2 progl2">
                              <div className="form-group">
                                <div className="form-group aligncenter inputgraygl">
                                  <label
                                    type="text"
                                    className="form-control formpprofilegl"
                                  >
                                    {this.state.data[0].cliGLRequired}{" "}
                                  </label>
                                  <label className="switchtoggle">
                                    <input
                                      type="checkbox"
                                      name="cliGLRequired"
                                      checked={
                                        this.state.productglrequired[0]
                                          .cliGLRequired
                                      }
                                      onChange={this.toggleChange.bind(this)}
                                    />
                                    <div className="checkerslider round"></div>
                                  </label>
                                </div>
                                <label className="labelgl">GL Required </label>
                                <label
                                  className="form-control formprulesch loanlabelmak7 secondsec"
                                  name=""
                                  id="field1"
                                  disabled
                                >
                                  {" "}
                                  CLI GL
                                </label>
                              </div>
                              <div className="form-group">
                                {this.state.data.map((ProductGL, i) => (
                                  <div
                                    key={i}
                                    className="form-group aligncenter inputgrayfield"
                                    style={{
                                      backgroundColor:
                                        this.state.isCliGLfieldclasslist[i],
                                    }}
                                  >
                                    <label
                                      type="text"
                                      className="form-control formpprofilefield"
                                      name="interestglsa"
                                      onChange={this.handleChange.bind(this)}
                                      disabled
                                    >
                                      {" "}
                                      {ProductGL.cliGL}{" "}
                                    </label>
                                    <label className="switchtoggle">
                                      <input
                                        type="checkbox"
                                        name="isCliGLsa"
                                        onChange={(e) => {
                                          this.toggleChangeIsCliGL(e, i);
                                        }}
                                        checked={ProductGL.isCliGL}
                                        disabled={this.state.isCliGLlist[i]}
                                      />
                                      <div
                                        className="checkerslider round"
                                        style={{
                                          backgroundColor:
                                            this.state.isCliGLclasslist[i],
                                        }}
                                      ></div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="p-2 progl2">
                              <div className="form-group">
                                <div className="form-group aligncenter inputgraygl">
                                  <label
                                    type="text"
                                    className="form-control formpprofilegl"
                                  >
                                    {
                                      this.state.data[0]
                                        .assetInsuranceGLRequired
                                    }{" "}
                                  </label>
                                  <label className="switchtoggle">
                                    <input
                                      type="checkbox"
                                      name="assetInsuranceGLRequired"
                                      checked={
                                        this.state.productglrequired[0]
                                          .assetInsuranceGLRequired
                                      }
                                      onChange={this.toggleChange.bind(this)}
                                    />
                                    <div className="checkerslider round"></div>
                                  </label>
                                </div>
                                <label className="labelgl">GL Required </label>
                                <label
                                  className="form-control formprulesch loanlabelmak7 secondsec"
                                  name=""
                                  id="field1"
                                  disabled
                                >
                                  {" "}
                                  Asset Insurance GL
                                </label>
                              </div>
                              <div className="form-group">
                                {this.state.data.map((ProductGL, i) => (
                                  <div
                                    key={i}
                                    className="form-group aligncenter inputgrayfield"
                                    style={{
                                      backgroundColor:
                                        this.state
                                          .isAssetInsurnceGLfieldclasslist[i],
                                    }}
                                  >
                                    <label
                                      type="text"
                                      className="form-control formpprofilefield"
                                      name="interestglsa"
                                      onChange={this.handleChange.bind(this)}
                                      disabled
                                    >
                                      {" "}
                                      {ProductGL.assetInsurnceGL}{" "}
                                    </label>
                                    <label className="switchtoggle">
                                      <input
                                        type="checkbox"
                                        name="isAssetInsurnceGLsa"
                                        onChange={(e) => {
                                          this.toggleChangeIsAssetInsurnceGL(
                                            e,
                                            i
                                          );
                                        }}
                                        checked={ProductGL.isAssetInsurnceGL}
                                        disabled={
                                          this.state.isAssetInsurnceGLlist[i]
                                        }
                                      />
                                      <div
                                        className="checkerslider round"
                                        style={{
                                          backgroundColor:
                                            this.state
                                              .isAssetInsurnceGLclasslist[i],
                                        }}
                                      ></div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="p-2 progl2">
                              <div className="form-group">
                                <label className="incomeglchecker"></label>
                                <label
                                  className="form-control formprulesch loanlabelmak7 secondsec incomegl"
                                  name=""
                                  id="field1"
                                  disabled
                                >
                                  {" "}
                                  PayLink Income GL
                                </label>
                              </div>
                              <div className="form-group">
                                {this.state.data.map((ProductGL, i) => (
                                  <div
                                    key={i}
                                    className="form-group aligncenter inputgrayfield"
                                    style={{
                                      backgroundColor:
                                        this.state
                                          .isPayLinkIncomeGLfieldclasslist[i],
                                    }}
                                  >
                                    <label
                                      type="text"
                                      className="form-control formpprofilefield"
                                      name="interestglsa"
                                      onChange={this.handleChange.bind(this)}
                                      disabled
                                    >
                                      {" "}
                                      {ProductGL.payLinkIncomeGL}
                                    </label>
                                    <label className="switchtoggle">
                                      <input
                                        type="checkbox"
                                        name="isPayLinkIncomeGLsa"
                                        onChange={(e) => {
                                          this.toggleChangeIsPayLinkIncomeGL(
                                            e,
                                            i
                                          );
                                        }}
                                        checked={ProductGL.isPayLinkIncomeGL}
                                        disabled={
                                          this.state.isPayLinkIncomeGLlist[i]
                                        }
                                      />
                                      <div
                                        className="checkerslider round"
                                        style={{
                                          backgroundColor:
                                            this.state
                                              .isPayLinkIncomeGLclasslist[i],
                                        }}
                                      ></div>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4 formpgl">
                        {this.state.loading ? (
                          <div>
                            <Loader />
                            <div className="formbox">
                              <div className="form-group ">
                                <label>Type Your User PIN </label>

                                <input
                                  type="password"
                                  className="form-control cardinputfield"
                                  name="pin"
                                  onChange={this.handleChange.bind(this)}
                                  placeholder="xxxxx "
                                />
                                <div className="sign">
                                  <input
                                    type="submit"
                                    className="btn btn-warning cardbutton"
                                    style={{
                                      backgroundColor: this.state.buttoncolor,
                                    }}
                                    onClick={this.handleSubmit}
                                    value="Submit changes for approval"
                                    disabled={isDisabledSubmitButton}
                                  />
                                </div>
                              </div>
                              <ForgotPin />
                            </div>
                          </div>
                        ) : (
                          <div className="formbox">
                            <div className="form-group ">
                              <label>Type Your User PIN </label>

                              <input
                                type="password"
                                className="form-control cardinputfield"
                                name="pin"
                                onChange={this.handleChange.bind(this)}
                                placeholder="xxxxx "
                              />
                              <div className="sign">
                                <input
                                  type="submit"
                                  className="btn btn-warning cardbutton"
                                  style={{
                                    backgroundColor: this.state.buttoncolor,
                                  }}
                                  onClick={this.handleSubmit}
                                  value="Submit changes for approval"
                                  disabled={isDisabledSubmitButton}
                                />
                              </div>
                            </div>
                            <ForgotPin />
                          </div>
                        )}

                        <div className="errorMsgpin">
                          {this.state.errors.pin}
                        </div>
                        {(() => {
                          switch (this.state.message) {
                            case "SUCCESS":
                              return (
                                <p className="submittedpin">
                                  Submitted Succesfully
                                </p>
                              );
                            default:
                              return <div></div>;
                          }
                        })()}
                        {(() => {
                          switch (this.state.message) {
                            case "UNSUCCESS":
                              return (
                                <div className="errorMsgpin">
                                  {this.state.errorMsg}
                                </div>
                              );
                            default:
                              return <p></p>;
                          }
                        })()}



                        <div className="gridbutton2 ">
                          <div className="form-group card1">
                            <label className="cardtext">Approved </label>
                            <br />
                            <img
                              className="toogle-img"
                              src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle1.png?alt=media&token=a825c329-274e-4a1c-831d-554cd94b2c88"
                              alt="Approved"
                            />
                          </div>
                          <div className="form-group card1">
                            <label className="cardtext">Rejected </label>
                            <br />
                            <img
                              className="toogle-img"
                              src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle2.png?alt=media&token=2a0da633-8a16-4e7b-9140-71afbb43e86e"
                              alt="Rejected"
                            />
                          </div>

                          <div className="form-group  card1">
                            <label className="cardtext">
                              No changes requested for approval{" "}
                            </label>
                            <br />
                            <div className="img-toole">
                              <img
                                className="toogle-img2"
                                src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle3.png?alt=media&token=0fe3a799-1b79-4d79-87bb-68f4459f2b70"
                                alt="No changes requested for approval"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4"></div>
                    </div>

                    <div className="Editable2">
                      <input
                        type="submit"
                        className="approval-button"
                        value="Changes requested for approval"
                      />
                      <input
                        type="submit"
                        className=" noteditable-button2"
                        value="Not Editable"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      );
    } else {
      return <p>Please Login AS AdminChecker</p>;
    }
  }
}
let isDisabledSubmitButton = false;
export default CheckerProductGL;
