import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.webp";
import mockup1 from "../../../assets/images/homebannerb.webp";
// import mockup2 from "../../../assets/images/mockup2.svg";
import "./style.css";
const Main = () => {
  return (
    <>
      <div className="row home-hero home-hero-section">
        <div className="col-6 home-hero-left">
          <p className="home-hero-p">
            Single app to manage all your Personal & Business finances
          </p>
          <span>
            Simply download Montra app to set-up your Montra Personal Account to
            access Marketplaces for multi-party payments & Lending Products.
          </span>
          <div className="app-download">
            <img src={appstore} alt="appstore" className="appstore-image" width="600" height="400" />
            <img src={playstore} alt="playstore" className="playstore-image" width="600" height="400" />
          </div>
        </div>
        <link rel="preload" href={mockup1} as="image" />
        <div className="col-6 mockups">
          <img
            src={mockup1}
            alt="mockup1"
            width="600"
            height="400"
            srcSet={`${mockup1} 600w`}
            sizes="(max-width: 600px) 100vw, (min-width: 601px) 50vw"
            fetchpriority="high"
          />
          {/* <div className="col-6 mockups">
          <img src={mockup1} alt="mockup1" width="600" height="400" /> */}
        </div>
      </div>
    </>
  );
};

export default Main;
