import React from "react";
// import { Form, FormControl } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// import { Navbar } from "react-bootstrap";
// import { Nav } from "react-bootstrap";
// import { NavDropdown } from "react-bootstrap";
// import { Button } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const Sidebarview = (props) => {
  console.log("Props path:", props.path);

  const getClassName = ({ isActive }) =>
    isActive ? "userlink active" : "userlink";

  return (
    <>
      {(() => {
        if ("LoanRequest" === props.path) {
          return (
            <div className="link-container">
              <NavLink
                to="/LoanRequest"
                className={getClassName}
                activeClassName="active-link"
              >
                Loan Requests
              </NavLink>
              <NavLink
                to="/LoanApproved"
                className={getClassName}
                activeClassName="active-link"
              >
                Loan Approved
              </NavLink>
              <NavLink
                to="/LoanRejects"
                className={getClassName}
                activeClassName="active-link"
              >
                Loan Rejects
              </NavLink>
            </div>
          );
        } else if ("LoanApproved" === props.path) {
          return (
            <div className="link-container">
              <NavLink
                to="/LoanRequest"
                className={getClassName}
                activeClassName="active-link"
              >
                Loan Requests
              </NavLink>
              <NavLink
                to="/LoanApproved"
                className={getClassName}
                activeClassName="active-link"
              >
                Loan Approved
              </NavLink>
              <NavLink
                to="/LoanRejects"
                className={getClassName}
                activeClassName="active-link"
              >
                Loan Rejects
              </NavLink>
            </div>
          );
        } else {
          return (
            <div className="link-container">
              <NavLink
                to="/LoanRequest"
                className={getClassName}
                activeClassName="active-link"
              >
                Loan Requests
              </NavLink>
              <NavLink
                to="/LoanApproved"
                className={getClassName}
                activeClassName="active-link"
              >
                Loan Approved
              </NavLink>
              <NavLink
                to="/LoanRejects"
                className={getClassName}
                activeClassName="active-link"
              >
                Loan Rejects
              </NavLink>
            </div>
          );
        }
      })()}
    </>
  );
};

export default Sidebarview;

// <div>
//   <Navbar className="navbar navbar-expand-lg navbar-light bg-dark nav-header montra-sidebar" bg="light" expand="lg">

//     <Navbar.Toggle aria-controls="basic-navbar-nav" />
//     <Navbar.Collapse id="basic-navbar-nav">
//       <Nav className="">
//         {(() => {
//           if ("LoanRequest" === props.path) {
//             return (<div>

//               <Navbar.Collapse id="basic-navbar-nav">
//                 <Nav className="">
//                   <Nav.Link to="/ViewDetails"  className="userlink">  Loan Requests</Nav.Link>
//                   <Nav.Link to="/LoanApproved" className="userlink">Loan Approved</Nav.Link>
//                   <Nav.Link to="/LoanRejects" className="userlink">Loan Rejects </Nav.Link>
//                 </Nav>
//               </Navbar.Collapse>
//             </div>
//             )
//           } else if ("LoanApproved" === props.path) {
//             return (<div>

//               <Navbar.Collapse id="basic-navbar-nav">
//                 <Nav className="">
//                   <Nav.Link to="/LoanRequest"  className="userlink">  Loan Requests</Nav.Link>
//                   <Nav.Link to="/ViewDetails"  className="userlink">Loan Approved</Nav.Link>
//                   <Nav.Link to="/LoanRejects" className="userlink">Loan Rejects </Nav.Link>
//                 </Nav>
//               </Navbar.Collapse>
//             </div>)
//           }
//           else {
//             return (<div>

//               <Navbar.Collapse id="basic-navbar-nav">
//                 <Nav className="">
//                   <Nav.Link to="/LoanRequest" className="userlink">  Loan Requests</Nav.Link>
//                   <Nav.Link to="/LoanApproved" className="userlink">Loan Approved</Nav.Link>
//                   <Nav.Link to="/ViewDetails"  className="userlink">Loan Rejects </Nav.Link>
//                 </Nav>
//               </Navbar.Collapse></div>)
//           }
//         })()}
//       </Nav>
//     </Navbar.Collapse>
//   </Navbar>
//   </div>
