import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import MontraAccount from "../../../assets/images/uday/personal-icons/payments/wallet.svg";
import LinkedAccounts from "../../../assets/images/uday/personal-icons/payments/linkedAccount.svg";
import LinkedCards from "../../../assets/images/uday/personal-icons/payments/linkCards.svg";
import Installments from "../../../assets/images/uday/personal-icons/payments/installments.svg";
import paymentimg3 from "../../../assets/images/paymentimg3.svg";

const steps = [
  { title: "Pay Using", image: paymentimg3, className: "paymentimg3" },
  { image: MontraAccount, description: "Montra Account" },
  { image: LinkedAccounts, description: "Linked Bank Accounts" },
  { image: LinkedCards, description: "Linked Cards" },
  { image: Installments, description: "Installments" },
];

const PaymentProcessContainer = () => {
  return (
    <div className="payment-process-container">
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="personal-payment-topdescription"
      />
    </div>
  );
};

export default PaymentProcessContainer;
