import React from "react";
import businessbanking1 from "../../../assets/images/businessbanking1.webp";
import businessbanking2 from "../../../assets/images/businessbanking2.svg";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import BankingProcessContainer from "./BankingProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="business-banking-hero business-banking">
        <div className="row">
          <div className="col-md-9 business-banking-content-col responsivebusiness-col">
            <div className="herocontent">
              <h1>
                Open a free Business Account{" "}
                <span>for all your Business Finances</span>
              </h1>

              <p>
                Open a free Montra Business account that helps small businesses
                stay on top of their finances. You can accept payments through a
                variety of payment acceptance methods and collect them in your
                Montra Business Account.
              </p>
              {/* <img
                className="business-banking-img businessbankimg"
                src={businessbanking2}
                alt="businessbanking2"
              /> */}
              <BankingProcessContainer />
              <div className="app-download business-banking-app-download">
                <img src={appstore} alt="appstore" />
                <img src={playstore} alt="playstore" />
              </div>
            </div>
          </div>
          <div className="col-md-3 business-banking-image-col responsivebusiness-col">
            <div className="bankingimg">
              <img
                src={businessbanking1}
                alt="Business Bank"
                className="businessbankimghero"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
