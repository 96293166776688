import React from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";

import Header2 from "./Header2";
import FooterAdmin from "../Footer/FooterAdmin";
import CommonHeader from "../header/CommonHeader";

const ViewDetailsReject = () => {
  return (
    <div>
      <CommonHeader />

      <div id="wrapper">
        <Sidebar />
        <section id="content-wrapper">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Loan Approved</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                View Details
              </li>
            </ol>
          </nav>
          <div class="row">
            <div class="col-12 viewdiv">
              <div className="container-fluid">
                <div className="d-flex flex-row mt-3 ">
                  <div className="p-2 loanview">
                    <p className="viewheader">ABOUT CUSTOMER </p>

                    <form className="formview1">
                      <div className="form-group">
                        <label className="lablename">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">BVN</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">PayLink ID</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>
                    </form>
                  </div>

                  <div className="p-2 loanview">
                    <p className="viewheader">ABOUT EMPLOYER / BUSINESS </p>

                    <form className="formview2">
                      <div className="form-group">
                        <label className="lablename">Customer Type</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">
                          Employer’s / Business Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">Company Category</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">
                          Working with this employer / Business(Years)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>
                    </form>
                  </div>

                  <div className="p-2 loanview">
                    <p className="viewheader">ABOUT CUSTOMER’S BANK </p>

                    <form className="formview3">
                      <div className="form-group">
                        <label className="lablename">Bank Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">Bank Account Number</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">Bank Customer ID</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>
                    </form>
                  </div>

                  <div className="p-2 loanviewbig">
                    <p className="viewheader">ABOUT CUSTOMER’S BANK </p>
                    <div className="formview4">
                      <form className="formflex">
                        <div className="d-flex flex-row secform">
                          <div className="p-2 childview">
                            <div className="form-group">
                              <label className="lablename">
                                Credit Bureau Name
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />

                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <br />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview">
                            <div className="form-group">
                              <label className="lablename">
                                Total Loan Outstanding Amount
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />

                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <br />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview">
                            <div className="form-group">
                              <label className="lable4thsec">
                                Existing Debt Burden
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />

                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <br />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview">
                            <div className="form-group">
                              <label className="lable4thsec">
                                Due Amount in X days
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />

                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <br />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview">
                            <div className="form-group">
                              <label className="lable4thsec">
                                Due Amount in 30 DPD
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />

                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <br />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview">
                            <div className="form-group">
                              <label className="lable4thsec">
                                Due Amount in 60 DPD
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />

                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <br />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview">
                            <div className="form-group">
                              <label className="lable4thsec">
                                Due Amount in 90 DPD
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />

                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <br />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview">
                            <div className="form-group">
                              <label className="lablename2">Bureau Score</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />

                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                              <br />
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </form>

                      <p className="bottomtext">Aggregated Position</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 viewdiv">
              <div className="container-fluid">
                <div className="d-flex flex-row">
                  <div className="p-2 loanviewpart2">
                    <p className="viewheader">INCOME DETAILS</p>

                    <form className="formview6">
                      <div className="form-group">
                        <label className="lablename">
                          Average Salary / Income
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">
                          Last Salary / inflow Credited in salary account ( in
                          months)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>
                    </form>
                  </div>

                  <div className="p-2 loanviewbig2">
                    <p className="viewheader">LOAN UNDER-WRITING DETAILS </p>
                    <div className="formview7">
                      <form className="formflex">
                        <div className="d-flex flex-row secform">
                          <div className="p-2 childview2">
                            <div className="form-group">
                              <label className="lablename">Min. Age</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">DB allowed %</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">Min Loan Amt.</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview2">
                            <div className="form-group">
                              <label className="lablename">Max Age</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                DB allowed Amt.
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">Max Loan Amt.</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview2">
                            <div className="form-group">
                              <label className="lablename">
                                Customer’s Age
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                Amt. Eligibility
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                Amt. requested
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 checkboxv">
                            <div className="form-group">
                              <label className="lablename3">Eligibility</label>
                              <div className="">
                                <div className="round">
                                  <input type="checkbox" id="checkbox" />
                                  <label for="checkbox"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="p-2 loanbig">
                    <p className="viewheader">
                      MULTIPLIERS APPLIED TO INTEREST RATE & TENORS{" "}
                    </p>
                    <div className="formview5">
                      <form className="formflex">
                        <div className="d-flex flex-row secform">
                          <div className="p-2 grid3">
                            <div className="form-group">
                              <label className="lablenamegrid3">
                                Base Tenor
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablenamegrid1">
                                Base Interest Rate
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablenamegrid4">
                                Delinquency Status – Interest Rate Mult.
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 grid3">
                            <div className="form-group">
                              <label className="lablename">
                                Avg. Salary–Tenor Mult.
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                Avg. Salary–Interest Rate Mult.
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablenamegrid4">
                                Bureau Score – Interest Rate Mult.
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview2">
                            <div className="form-group">
                              <label className="lablenamegrid3">
                                Final Tenor (Months)
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                Company Category – Interest Mult.
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">
                                No. of years with current employer Int. Rate
                                Mult.
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 grid3">
                            <div className="form-group">
                              <label className="lablenamegrid">
                                No. of years in Emp. Int.Rate Mult.
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablenamegrid2">
                                Approved Interest Rate
                              </label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="p-2 loanviewbig5">
                    <p className="viewheader">APPROVAL REQUEST SUMMARY </p>
                    <div className="formview9">
                      <form className="formflex">
                        <div className="form-group lastgrid">
                          <label className="lablename">Loan Product</label>
                          <input
                            type="text"
                            className="form-control flexinput"
                            name="lendingcompanyname"
                            placeholder=""
                          />
                        </div>
                        <div className="d-flex flex-row secform">
                          <div className="p-2 childview3">
                            <div className="form-group">
                              <label className="lablename">Loan Amount</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">Loan Tenor</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="p-2 childview3">
                            <div className="form-group">
                              <label className="lablename">EMI</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>

                            <div className="form-group">
                              <label className="lablename">Interest Rate</label>
                              <input
                                type="text"
                                className="form-control flexinput"
                                name="lendingcompanyname"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 viewdiv">
              <div className="container-fluid">
                <div className="d-flex flex-row">
                  <div className="p-2 viewlrequest1">
                    <p className="viewheader">PAYLINK APPROVAL STATUS</p>

                    <form className="gridview3">
                      <div className="form-group">
                        <label className="lablename">Loan Request Date</label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">
                          PayLink Decision Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="lendingcompanyname"
                          placeholder=""
                        />
                      </div>

                      <div className="form-group">
                        <label className="lablename">PayLink Decision</label>
                        <Link to="" className="btn btn-primary Approved">
                          {" "}
                          Approved{" "}
                        </Link>
                      </div>
                    </form>
                  </div>

                  <div className="p-2 viewlrequest2">
                    <p className="viewheader">BUREAU REPRTS - ATTACHMENTS </p>
                    <div className="gridview4">
                      <form className="formflex">
                        <div className="d-flex flex-row secform2">
                          <div className="p-2 viewgridrequest">
                            <div className="form-group">
                              <span>
                                {" "}
                                <i class="far fa-file-pdf"></i>{" "}
                              </span>
                              <p className="lablename">Credit Bureau Name</p>
                            </div>
                          </div>

                          <div className="p-2 viewgridrequest">
                            <div className="form-group">
                              <span>
                                {" "}
                                <i class="far fa-file-pdf"></i>{" "}
                              </span>
                              <p className="lablename">Credit Bureau Name</p>
                            </div>
                          </div>

                          <div className="p-2 viewgridrequest">
                            <div className="form-group">
                              <span>
                                {" "}
                                <i class="far fa-file-pdf"></i>{" "}
                              </span>
                              <p className="lablename">Credit Bureau Name</p>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="p-2 viewlrequest3">
                    <p className="viewheader">APPROVER’S DECISION </p>
                    <div className="gridview5">
                      <form className="formflex">
                        <div className="form-group toggleview">
                          <label className="switchloan ">
                            <input type="checkbox" id="togBtn" />
                            <div className="sliderview round"></div>
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="p-2  viewlrequest4">
                    <div className="collectioncardchecker">
                      <form method="post" name="userRegistrationForm">
                        <div className="form-group ">
                          <label htmlFor="first">Type Your User PIN </label>
                          <input
                            type="password"
                            className="form-control cardinputfield"
                            name="lifeinsurance"
                            placeholder="xxxxx "
                          />
                          <div className="sign">
                            <input
                              type="submit"
                              className="btn btn-warning cardbuttonchecker"
                              value="Submit changes for approval"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FooterAdmin />
    </div>
  );
};

export default ViewDetailsReject;
