import React, { useCallback, useEffect, useState } from "react";
import BusinessOverPGgif from "../../../assets/images/uday/gifs/business-overview-pg-gif.gif";
import debounce from "lodash.debounce";
import PGProcessContainer from "../../Home/Business/PGProcessContainer";
import PGSKE1 from "../../../assets/images/uday/montra-PG/savedcardskeletonicon.svg";
import PGSKE2 from "../../../assets/images/uday/montra-PG/qrscanskeletonicon.svg";
import PGSKE3 from "../../../assets/images/uday/montra-PG/montrappskeleton.svg";
import PGSKE4 from "../../../assets/images/uday/montra-PG/ussdskeletonicon.svg";
import montrPgSkeleton from "../../../assets/images/uday/montra-PG/payment_gateway.webp";
// import montrPgSkeleton from "../../../assets/images/uday/New-Images/image/payment_gateway.webp";
import {
  Animation10,
  Animation11,
  Animation8,
  Animation9,
} from "../../Home/Business/_animations";
import "../../Home/Business/style.css";
const PaymentGateway = ({ businessPgColor, businessPG }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isAnimationPaused, setIsAnimationPaused] = useState(false);
  const [anim3, setAnim3] = useState(1);
  const isMobile = windowWidth < 1024;
  const shopMobile = windowWidth <= 1024;

  const desktopWidth = windowWidth >= 1600;

  const handleAnimMouseEnter = () => {
    setIsAnimationPaused(true);
    console.log("animation paused");
  };

  const handleAnimMouseLeave = () => {
    setIsAnimationPaused(false);
    console.log("animation started");
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationPaused) {
        setAnim3((prev) => {
          console.log("Anim2 loaded", prev === 2);
          return prev === 4 ? 1 : prev + 1;
        });
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isAnimationPaused]);

  return (
    <div className="business-pg home-business-body">
      <div className="business-pg-content">
        <div className="row nowrap montrapg-wrapper business-pg-wrapper">
          <div className="col duo-col">
            <div className="home-business-left home-business-left-pg">
              <div className="left-4 left">
                <p className="business-pg-heading">
                  Have an existing website or Mobile App?
                </p>
                <h1 className="home-montra-pg-h1 business-pg-heading">
                  Power your business with{" "}
                  <span className="home-montra-pg-h1-span business-pg-heading">
                    Montra Payment Gateway
                  </span>
                </h1>
                <p className="business-pg-heading">
                  Simply integrate Montra Payment Gateway with your website or
                  Mobile App for your customers to make payments using their :
                </p>
                <ul className="business-list">
                  <li className="busineslist">Debit & Credit Cards</li>
                  <li className="busineslist">
                    Bank Accounts by scanning QR code using their Mobile Banking
                    Apps
                  </li>
                  <li className="busineslist">
                    Montra App where they can make payments using Montra account
                    and even using Installments
                  </li>
                  <li className="busineslist">
                    USSD to make payment using USSD app
                  </li>
                </ul>
                <a href="/Business/more/Gateway">
                  <button className="business-pg-explore-btn">
                    Explore Montra Payment Gateway{" "}
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </a>
                <div>
                  {/* <img
                    className="direction-img"
                    src={businessanimg4}
                    alt=""
                  /> */}
                  <PGProcessContainer
                    businessBgColor={businessPgColor}
                    businessPG={businessPG}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col PaymentGatewayAnimations duo-col">
            {!isMobile ? (
              <React.Fragment className="normal-layout">
                <div
                  className={
                    desktopWidth
                      ? "mt40 pg-animation-items"
                      : "mt24 pg-animation-items"
                  }
                >
                  <div className="pg-animation-groups">
                    <div className="pg-animation">
                      <img src={PGSKE1} alt="New and Saved Cards" />
                      <p className="pganim-text">New and Saved Cards</p>
                    </div>
                    <div className="pg-animation">
                      <img src={PGSKE2} alt="QR SCAN" />
                      <p className="pganim-text">QR SCAN</p>
                    </div>
                  </div>
                  <div className="pg-animation-groups">
                    <div className="pg-animation">
                      <img
                        src={PGSKE3}
                        alt="Montra App"
                        className="montrapp-ussd"
                      />
                      <p className="pganim-text">Montra App</p>
                    </div>
                    <div className="pg-animation">
                      <img src={PGSKE4} alt="USSD" className="ussd-montrapp " />
                      <p className="pganim-text">USSD</p>
                    </div>
                  </div>
                </div>
                <div className="pg-animation-rightimage business-pg-animation-right-image">
                  <div className="pg-animation">
                    <img
                      src={montrPgSkeleton}
                      alt="QR SCAN"
                      className="pg-right-image"
                    />
                    <p className="pganim-text">QR SCAN</p>
                  </div>
                </div>
                <div>
                  {anim3 === 1 && (
                    <Animation8
                      onMouseEnter={handleAnimMouseEnter}
                      onMouseLeave={handleAnimMouseLeave}
                      desktopWidth={desktopWidth}
                    />
                  )}
                  {anim3 === 2 && (
                    <Animation9
                      onMouseEnter={handleAnimMouseEnter}
                      onMouseLeave={handleAnimMouseLeave}
                      desktopWidth={desktopWidth}
                    />
                  )}
                  {anim3 === 3 && (
                    <Animation10
                      onMouseEnter={handleAnimMouseEnter}
                      onMouseLeave={handleAnimMouseLeave}
                      desktopWidth={desktopWidth}
                    />
                  )}
                  {anim3 === 4 && (
                    <Animation11
                      onMouseEnter={handleAnimMouseEnter}
                      onMouseLeave={handleAnimMouseLeave}
                      desktopWidth={desktopWidth}
                    />
                  )}
                </div>
              </React.Fragment>
            ) : (
              <div className="gif-layout">
                <img
                  src={BusinessOverPGgif}
                  alt="business-section gif"
                  className="business-section1-gif"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentGateway;
