import React from "react";

function LoanRepayment() {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.9685 19.7756L3.68751 17.0122L5.82743 19.2775"
        stroke="#20336B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.67883 17.9434C4.57086 23.5965 9.40795 27.7832 15.1305 27.8553C20.8528 27.9275 25.794 23.8641 26.8284 18.2355"
        stroke="#20336B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.0315 11.7112L26.3125 14.4746L24.1726 12.2093"
        stroke="#20336B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.3212 13.5434C25.4291 7.89033 20.5921 3.70363 14.8695 3.63149C9.14718 3.5593 4.20597 7.6227 3.17163 13.2514"
        stroke="#20336B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.2925 17.5397H17.5419V16.8187H18.2925C18.4052 16.8187 18.4965 16.7274 18.4965 16.6139C18.4965 16.5013 18.4052 16.4099 18.2925 16.4099H17.5419V15.4096C17.5419 15.2634 17.5129 15.1561 17.4566 15.0914C17.4026 15.0289 17.331 15 17.2328 15C17.1384 15 17.0706 15.0289 17.0173 15.0906C16.961 15.1561 16.9328 15.2634 16.9328 15.4096V16.4099H15.5214L14.9207 15.5025C14.8728 15.4256 14.8233 15.3487 14.7746 15.2725C14.7319 15.2071 14.6908 15.153 14.652 15.1134C14.6177 15.0784 14.5789 15.051 14.5325 15.0297C14.4891 15.0099 14.4343 15 14.3688 15C14.2858 15 14.2105 15.0228 14.1381 15.0693C14.0673 15.1157 14.0178 15.1728 13.9874 15.2444C13.9607 15.3121 13.947 15.4164 13.947 15.5504V16.4099H13.204C13.0914 16.4099 13 16.5013 13 16.6147C13 16.7274 13.0914 16.8187 13.204 16.8187H13.947V17.5397H13.204C13.0914 17.5397 13 17.631 13 17.7437C13 17.8564 13.0914 17.9477 13.204 17.9477H13.947V19.0904C13.947 19.232 13.9767 19.3378 14.0354 19.4056C14.0917 19.4695 14.1633 19.4992 14.2592 19.4992C14.3528 19.4992 14.4236 19.4695 14.4815 19.4048C14.5416 19.3394 14.5721 19.2328 14.5721 19.0904V17.9477H15.8495L16.54 19.0074C16.5887 19.0782 16.6374 19.1483 16.6877 19.2176C16.7318 19.28 16.7806 19.3348 16.8323 19.3805C16.8788 19.4223 16.9283 19.452 16.98 19.4711C17.0326 19.4901 17.095 19.5 17.1635 19.5C17.3508 19.5 17.5419 19.4421 17.5419 19.0181V17.9477H18.2925C18.4052 17.9477 18.4965 17.8564 18.4965 17.7437C18.4965 17.631 18.4052 17.5397 18.2925 17.5397ZM16.9328 16.8187V17.5397H16.2675L15.7909 16.8187H16.9328ZM14.5721 15.9897L14.8461 16.4099H14.5721V15.9897ZM14.5721 17.5397V16.8187H15.1126L15.5831 17.5397H14.5721ZM16.9328 18.5461L16.537 17.9477H16.9328V18.5461Z"
        fill="#24BAF2"
      />
      <path
        d="M15.5007 22.9259C18.5331 22.9259 21 20.5853 21 17.7082C21 15.4995 19.4996 13.277 17.1777 12.0465C17.1169 12.0144 17.0922 11.9424 17.1195 11.879L17.7653 10.383C17.9394 9.98021 17.8382 9.51322 17.514 9.22113C17.2263 8.96289 16.8153 8.92861 16.4902 9.13693L16.3088 9.25368C16.2541 9.2884 16.1964 9.28883 16.1426 9.25411L15.9559 9.13476C15.6747 8.95508 15.3253 8.95508 15.0449 9.13476L14.8574 9.25411C14.8036 9.2884 14.7463 9.2884 14.6934 9.25455L14.5094 9.13606C14.1852 8.92817 13.7737 8.96202 13.486 9.22069C13.1618 9.51278 13.0611 9.97978 13.2347 10.3825L13.8809 11.8794C13.9083 11.9419 13.8831 12.0136 13.8223 12.0457C11.5004 13.2765 10 15.4987 10 17.7073C10 20.5853 12.4678 22.9259 15.5007 22.9259ZM13.8718 10.1078C13.815 9.97631 13.8458 9.83091 13.9499 9.73673C13.9807 9.70939 14.0168 9.69507 14.0528 9.69507C14.0806 9.69507 14.1083 9.70331 14.134 9.71981L14.3193 9.83916C14.6014 10.0184 14.9508 10.018 15.2307 9.83873L15.4182 9.71937C15.4716 9.68509 15.5284 9.68509 15.5822 9.71937L15.7689 9.83873C16.0492 10.018 16.3986 10.0184 16.6825 9.83786L16.8652 9.72024C16.9251 9.68161 16.9958 9.68812 17.0496 9.7363C17.1542 9.83048 17.185 9.97631 17.1282 10.1074L16.4824 11.603C16.4403 11.6998 16.4186 11.8 16.4142 11.899C16.1873 11.9476 15.9373 11.9732 15.6764 11.9732C15.3106 11.9732 14.9508 11.9207 14.6634 11.8261C14.6344 11.8165 14.6049 11.8113 14.5758 11.8096C14.5658 11.7397 14.5467 11.6707 14.5176 11.6034L13.8718 10.1078ZM14.1474 12.6594C14.2406 12.6099 14.3232 12.5428 14.3909 12.4619C14.4082 12.471 14.426 12.4792 14.4456 12.4858C14.8062 12.6051 15.232 12.668 15.6764 12.668C16.0349 12.668 16.3808 12.6264 16.6911 12.5474C16.7393 12.5899 16.7927 12.6281 16.8522 12.6598C18.9502 13.7717 20.3056 15.7534 20.3056 17.7086C20.3056 20.2029 18.1499 22.2323 15.5002 22.2323C12.8497 22.2323 10.6935 20.2029 10.6935 17.7086C10.694 15.753 12.0494 13.7713 14.1474 12.6594Z"
        fill="#20336B"
        stroke="#20336B"
        stroke-width="0.3"
      />
    </svg>
  );
}

export default LoanRepayment;
