import React from 'react'
import Navbar from "../../Navbar/Company";
import Footer from '../../Footer/Solfooter'
import './style.css';
import Body from './Body';
import Hero from './Hero';

const Main = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Body />
      <div className="storyfooter">
        <Footer />
      </div>

    </>
  )
}

export default Main