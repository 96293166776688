import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Navbar/Main";
import { HashLink as Link } from "react-router-hash-link";
import { NavLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import duologo from "../../assets/images/duologo.svg";
import "./solution.css";
import duoapp from "../../assets/images/duoapp.svg";
import dwp from "../../assets/images/dwp.svg";
import co from "../../assets/images/co.svg";
import act from "../../assets/images/act.svg";
import dyn from "../../assets/images/dyn.svg";
import col from "../../assets/images/col.svg";

const Solution = () => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const [isMoreActive, setMoreActive] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [disableLink, setDisableLink] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const timeoutIdRef = useRef(null);
  const location = useLocation();

  const handleButtonClick = () => {
    setSubMenuVisible(!isSubMenuVisible);
    setMoreActive(!isSubMenuVisible);
  };

  const handleSubMenuClick = (e) => {
    e.stopPropagation();
    setMoreActive(true);
    setSubMenuVisible(false);
  };

  const handleMouseLeave = () => {
    timeoutIdRef.current = setTimeout(() => {
      setSubMenuVisible(false);
      // setMoreActive(false);
    }, 1000);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth > 920) {
        setSubMenuVisible(false);
        setMoreActive(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    clearTimeout(timeoutIdRef.current);
    setSubMenuVisible(true);
  };

  useEffect(() => {
    setSubMenuVisible(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    const subLinks = [
      "/Solutions/Duo/Duoapp",
      "/Solutions/Duo/Dwp",
      "/Solutions/Duo/Customeronboarding",
      "/Solutions/Duo/Activation",
      "/Solutions/Duo/Dlm",
      "/Solutions/Duo/Collection",
    ];
    if (subLinks.includes(location.pathname)) {
      setMoreActive(true);
      setDisableLink(true);
    } else {
      setMoreActive(false);
      setDisableLink(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div className="product-nav">
        <Navbar />
      </div>
      {screenWidth > 920 ? (
        <div className="second-navbar navbar solution-navbar ">
          <div className="solutions-logo">
            <img src={duologo} alt="duologo" />
          </div>

          <div className="nav-link sol-nav-link">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  to="/Solutions/Duo/overview"
                  className="nav-link solution"
                  activeClassName="sol-active"
                >
                  Overview
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Solutions/Duo/Dts"
                  className="nav-link solution"
                  activeClassName="sol-active"
                >
                  DTS
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/Solutions/Duo/Dcp"
                  className="nav-link solution"
                  activeClassName="sol-active"
                >
                  DCP
                </NavLink>
              </li>

              <li className="nav-item">
                <button
                  onClick={handleButtonClick}
                  aria-current="false"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  activeClassName="active"
                  end
                  // style={{ cursor: disableLink ? "not-allowed" : "pointer" }}
                  className={`nav-link more supportmore ${
                    isMoreActive ? "active" : " "
                  } `}
                >
                  DUO Support
                </button>

                {isSubMenuVisible && (
                  <div
                    className="submenu sol-nav-link"
                    onClick={handleSubMenuClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="solution-menu-menu">
                      <NavLink
                        to="/Solutions/Duo/Duoapp"
                        className="nav-link solution"
                        activeClassName="active"
                      >
                        <div className="solution solution-menu  supply-solution-menu">
                          <div>
                            <img src={duoapp} alt="DUO App" />
                          </div>
                          <div>
                            <h6>DUO App</h6>
                          </div>
                        </div>
                      </NavLink>

                      <NavLink
                        to="/Solutions/Duo/Dwp"
                        className="nav-link solution"
                        activeClassName="active"
                      >
                        <div className="solution solution-menu  supply-solution-menu">
                          <div>
                            <img src={dwp} alt="Duo Web Portal" />
                          </div>
                          <div>
                            <h6>Duo Web Portal</h6>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink
                        to="/Solutions/Duo/Customeronboarding"
                        className="nav-link solution"
                        activeClassName="active"
                      >
                        <div className="solution solution-menu  supply-solution-menu">
                          <div>
                            <img src={co} alt="Customer Onboarding" />
                          </div>
                          <div>
                            <h6>Customer Onboarding</h6>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink
                        to="/Solutions/Duo/Activation"
                        className="nav-link solution"
                        activeClassName="active"
                      >
                        <div className="solution solution-menu  supply-solution-menu">
                          <div>
                            <img src={act} alt="Activation" />
                          </div>
                          <div>
                            <h6>Activation</h6>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink
                        to="/Solutions/Duo/Dlm"
                        className="nav-link solution"
                        activeClassName="active"
                      >
                        <div className="solution solution-menu  supply-solution-menu">
                          <div>
                            <img src={dyn} alt="Dynamic Limit Management" />
                          </div>
                          <div>
                            <h6>Dynamic Limit Management</h6>
                          </div>
                        </div>
                      </NavLink>
                      <NavLink
                        to="/Solutions/Duo/Collection"
                        className="nav-link solution"
                        activeClassName="active"
                      >
                        <div className="solution solution-menu  supply-solution-menu">
                          <div>
                            <img src={col} alt="Collections" />
                          </div>
                          <div>
                            <h6>Collections</h6>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                    {/* Add more submenu items as needed */}
                  </div>
                )}
              </li>

              <li className="nav-item">
                <NavLink
                  to="/Solutions/Duo/Implementation"
                  className="nav-link solution"
                  activeClassName="sol-active"
                >
                  DUO Implementation
                </NavLink>
              </li>
              <NavLink
                to="https://www.duopay.montra.org/PartnerLogin"
                target="_blank"
              >
                <li className="nav-item">
                  <button className="sol-button">
                    Partner Sign in <i className="bi bi-chevron-right"></i>
                  </button>
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
      ) : (
        <div className="horizontal-navbar">
          <div className="second-navbar navbar solution-navbar aaaa">
            <div className="flexdiv-parent">
              <div className=" flexdiv-menu">
                <div className="flexdiv-menu-items solutions-flexdiv-menu-items">
                  <div className="duo-solutions-logo">
                    <img src={duologo} alt="duologo" />
                  </div>
                </div>
              </div>
              <div className="horizontal-flow">
                <div className="horizontal-flow-track">
                  <div className="nav-link sol-nav-link horizontal-flow-items">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <NavLink
                          to="/Solutions/Duo/overview"
                          className="nav-link solution"
                          activeClassName="sol-active"
                        >
                          Overview
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Solutions/Duo/Dts"
                          className="nav-link solution"
                          activeClassName="sol-active"
                        >
                          DTS
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Solutions/Duo/Dcp"
                          className="nav-link solution"
                          activeClassName="sol-active"
                        >
                          DCP
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          to="/Solutions/Duo/Duoapp"
                          className="nav-link solution"
                          activeClassName="active"
                        >
                          DUO App
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Solutions/Duo/Dwp"
                          className="nav-link solution"
                          activeClassName="active"
                        >
                          Duo Web Portal
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Solutions/Duo/Customeronboarding"
                          className="nav-link solution"
                          activeClassName="active"
                        >
                          Customer Onboarding
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Solutions/Duo/Activation"
                          className="nav-link solution"
                          activeClassName="active"
                        >
                          Activation
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Solutions/Duo/Dlm"
                          className="nav-link solution"
                          activeClassName="active"
                        >
                          Dynamic Limit Management
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/Solutions/Duo/Collection"
                          className="nav-link solution"
                          activeClassName="active"
                        >
                          Collections
                        </NavLink>
                      </li>

                      <NavLink
                        to="https://www.duopay.montra.org/PartnerLogin"
                        target="_blank"
                      >
                        <li className="nav-item">
                          <button className="sol-button">
                            Partner Sign in{" "}
                            <i className="bi bi-chevron-right"></i>
                          </button>
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Solution;
