import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import AcceptPayments from "../../../assets/images/uday/Business-icons/tap-pay/AcceptPayments.svg";
import CashWithdrawal from "../../../assets/images/uday/Business-icons/tap-pay/cashWithdrawal.svg";
import tapPay2 from "../../../assets/images/tap-pay-2.svg";

const steps = [
  { title: "Tap & Pay", image: tapPay2 },
  { image: AcceptPayments, description: "Accept Payments" },
  { image: CashWithdrawal, description: "Cash Withdrawal Service" },
];

const TapPayProcessContainer = () => {
  return (
    <div className="tappay-process-container">
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="tappay-topdescription"
        downDescriptionClass="tapPay-down-description-font"
      />
    </div>
  );
};

export default TapPayProcessContainer;
