import "./style.css";
// import tapPay from "../../../assets/images/tap-pay.svg";
import tapPay from "../../../assets/images/uday/New-Images/Tap & Pay/Tap & Pay/Property 1=Variant4.svg";
import tapPayCardOnMobile from "../../../assets/images/uday/New-Images/Tap & Pay/Tap & Pay/Property 1=Variant5.svg";
// import tapPay2 from "../../../assets/images/tap-pay-2.svg";
import tapPayVar1 from "../../../assets/images/tap-pay-var1.webp";
// import tapPayVar2 from "../../../assets/images/tap-pay-var2.svg";
import tapPayVar2 from "../../../assets/images/uday/New-Images/Tap & Pay/Tap & Pay/Property 1=Variant6.svg";
import loan1 from "../../../assets/images/tappay.svg";
import TapPayProcessContainer from "./TapPayProcessContainer";

const Hero = () => {
  return (
    <div className="business-agency business-tappay">
      <div className="row business-tappay-row">
        <div className="col-md-8 business-tappay-content-col responsivebusiness-col">
          <div className="tap-sm-btn">
            <img
              src={loan1}
              alt="dlm-sm-btn-image"
              className="dlm-sm-btn-image"
            />
            <p>Tap & Pay</p>
          </div>
          <h1 className="tapaccept">
            Accept in-person payments with only an Android device
          </h1>
          <p>
            Give your customers the ability to accept contactless payments in
            person using your Montra App, directly on NFC enabled Android
            devices. With Tap to Pay solution, you can accept contactless
            payments from any physical cards—no extra hardware required.
          </p>
          {/* <div> */}
          {/* <img src={tapPay2} alt="tap and pay diagram" /> */}
          <TapPayProcessContainer />
          {/* </div> */}
          <button className="cta-blue tappaybutton">Contact Sales</button>
        </div>
        <div className="col-md-4 tapayanimations responsivebusiness-col">
          <div className="mobile-animation">
            {/* <img src={tapPay} alt="tap and pay" /> */}
            <img src={tapPay} alt="tap and pay" />
            <img src={tapPayCardOnMobile} alt="tap and pay" />
            {/* <img src={tapPayVar1} alt="tap and pay" /> */}
            <img src={tapPayVar2} alt="tap and pay" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
