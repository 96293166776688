import React from "react";

function PG1() {
  return (
    <svg
      width="68"
      height="118"
      viewBox="0 0 68 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="yu-path"
        pathLength="1"
        d="M0 1H23C29 1 36 7.12948 36 15.621C36 24.1125 36 71.0686 36 99.5C36 107.631 42.5 117 52.5 117C61.3 117 66.5 117 68 117"
        stroke="url(#paint0_radial_4816_4976)"
        stroke-width="2"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4816_4976"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(63.5 12.5947) rotate(-92.0907) scale(72.6758 274.892)"
        >
          <stop stop-color="#17D92A" />
          <stop offset="1" stop-color="#FFBB08" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default PG1;
