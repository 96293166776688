import React from 'react'
import Shop from '../components/Personal/Shop/Main'

const ShopRoute = () => {
  return (
   <>
   <Shop />
   </>
  )
}

export default ShopRoute