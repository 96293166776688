import React from "react";
import ProcessSteps from "../../../../utilities/ProcessSteps";
import verified1 from "../../../../assets/images/verified1.svg";
import softTokenSolution from "../../../../assets/images/uday/verified-icons/setupToken.svg";
import Security from "../../../../assets/images/uday/verified-icons/security.svg";
import BackupSupport from "../../../../assets/images/uday/verified-icons/BackupSupport.svg";
import MultipleDevices from "../../../../assets/images/uday/verified-icons/multipleDevices.svg";

const steps = [
  { title: "Soft Token Solution", className: "auth-app-img", image: verified1 },
  { image: softTokenSolution, description: "Set-up Soft Token" },
  { image: Security, description: "Security" },
  { image: BackupSupport, description: "Back-up Support" },
  { image: MultipleDevices, description: "Multiple Devices" },
];

const AuthProcessContainer = ({ hometopDescription }) => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#2988E2"
        circleBorderColor="#2988E2"
        textColor="#0A2540"
        topDescriptionClass={hometopDescription}
      />
    </div>
  );
};

export default AuthProcessContainer;
