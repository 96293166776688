import React from "react";

function MobileOrContact() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="16"
      // height="32"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 16 32"
    >
      <path
        fill="#4ABCEB"
        fillRule="evenodd"
        d="M8.093 13.19a2.334 2.334 0 110-4.667 2.334 2.334 0 010 4.668zm0-3.995a1.661 1.661 0 100 3.322 1.661 1.661 0 000-3.322zm3.726 8.995H4.368l-.337-.336a4.063 4.063 0 016.093-3.519 4.063 4.063 0 012.031 3.519l-.336.337zm-7.099-.672h6.747a3.39 3.39 0 00-6.747 0z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.848 1c.693 0 1.254.561 1.254 1.254v22.492c0 .693-.561 1.254-1.254 1.254H2.152a1.254 1.254 0 01-1.254-1.254V2.254C.898 1.56 1.46 1 2.152 1h11.696z"
      ></path>
      <path
        stroke="#20336B"
        strokeLinecap="square"
        d="M1.523 5.44H14.06M2.152 21.111H14.69"
      ></path>
      <path
        fill="#20336B"
        d="M10.302 2.682a.627.627 0 110 1.253H5.287a.627.627 0 110-1.253h5.015z"
      ></path>
      <g filter="url(#filter0_d_3285_7814)">
        <path
          stroke="#20336B"
          strokeWidth="0.6"
          d="M7.168 23.619a1.254 1.254 0 112.507 0 1.254 1.254 0 01-2.507 0z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_3285_7814"
          width="11.109"
          height="11.107"
          x="2.867"
          y="20.064"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.392157 0 0 0 0 0.392157 0 0 0 0 0.392157 0 0 0 0.498039 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3285_7814"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3285_7814"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default MobileOrContact;
