import React from "react";
import appstore from "../../../assets/images/appstore.png";
import playstore from "../../../assets/images/playstore.png";
import personalhero1 from "../../../assets/images/personalhero1.png";
import personalhero2 from "../../../assets/images/personalhero2.png";
// import paymentimg3 from "../../../assets/images/paymentimg3.svg";
import paymentimg4 from "../../../assets/images/uday/paymentimg4.webp";
// import paymentimg4 from "../../../assets/images/paymentimg4.svg";

import "./payment.css";
import PaymentProcessContainer from "./PaymentProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="personal-hero payment-hero">
        <div className="row">
          <div className="col payment-hero-left">
            <h1 className="payment-hero-h1">
              Secure way of accessing{" "}
              <span className="payment-hero-h1-span">world of Payments</span>
            </h1>
            <p className="payment-hero-p">
              Secured with 2FA through your Montra Soft Token, you can now pay
              with a variety of Payment methods using Montra Accounts, Bank
              Accounts & Cards you have linked on Montra App and Installments by
              borrowing loans from our Lending Partners.
            </p>

            {/* <img className="paymentimg3" src={paymentimg3} /> */}
            <PaymentProcessContainer />
            <p className="payment-hero-p simplyqr">
              Simply scan the QR codes of our Merchants app or Tap & Pay on our
              Merchants app with your Debit & Credit Cards or use your Debit &
              Credit cards on POS issued by Montra to our merchants.
            </p>
            <div className="app-download">
              <img src={appstore} alt="appstore" />
              <img src={playstore} alt="playstore" />
            </div>
          </div>
          <div className="col payment-img payment-image-col">
            <img src={paymentimg4} alt="paymentimg4" className="paymentimg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
