import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Main";
import { NavLink, useLocation } from "react-router-dom";
import "./company.css";
import "../Company/Home/style.css";

const Company = () => {
  const [showOverViewImage, setshowOverViewImage] = useState(false);
  const location = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location?.pathname == "/Company/Overview") {
      setshowOverViewImage(true);
    }
  }, []);

  return (
    <>
      <div className="product-nav ">
        <Navbar />
      </div>
      {screenWidth > 920 ? (
        <>
          {showOverViewImage && (
            <div className="overview-image-container ">
              <div className="money-trans">
                <div className="money-trans-1">
                  <p className="money-trans-p1">Mon</p>
                  <p className="money-trans-p2">tra</p>
                </div>
                <div className="money-trans-2">
                  <p>ey</p>
                  <p>nsfer</p>
                  {/* <h6>made easy</h6> */}
                </div>
              </div>
            </div>
          )}
          <div className="nav-link  company-nav ">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item company-nav-link">
                <NavLink
                  to="/Company/Overview"
                  className="nav-link"
                  activeClassName="com-active"
                >
                  Overview
                </NavLink>
              </li>
              <li className="nav-item company-nav-link">
                <NavLink
                  to="/Company/Ourstory"
                  className="nav-link"
                  activeClassName="com-active"
                >
                  Our Story
                </NavLink>
              </li>
              {/* <li className="nav-item company-nav-link">
            <NavLink to="/" className="nav-link" activeClassName="com-active">
              Working at Montra
            </NavLink>
          </li> */}
              {/* <li className="nav-item company-nav-link">
            <NavLink to="/" className="nav-link" activeClassName="com-active">
              Contact Us
            </NavLink>
          </li> */}
            </ul>
          </div>
        </>
      ) : (
        <div className="horizontal-navbar company-horizontal-navbar">
          <div className="second-navbar navbar verified-navbar">
            <div className="flexdiv-parent">
              <div className=" flexdiv-menu">
                <div className="flexdiv-menu-items">
                  <div className="money-trans">
                    <div className="money-trans-1">
                      <p>Mon</p>
                      <p>tra</p>
                    </div>
                    <div className="money-trans-2">
                      <p>ey</p>
                      <p>nsfer</p>
                      {/* <h6>made easy</h6> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="horizontal-flow">
                <div className="horizontal-flow-track">
                  <div className="nav-link company-nav  horizontal-flow-items">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item company-nav-link">
                        <NavLink
                          to="/Company/Overview"
                          className="nav-link"
                          activeClassName="com-active"
                        >
                          Overview
                        </NavLink>
                      </li>
                      <li className="nav-item company-nav-link">
                        <NavLink
                          to="/Company/Ourstory"
                          className="nav-link"
                          activeClassName="com-active"
                        >
                          Our Story
                        </NavLink>
                      </li>
                      {/* <li className="nav-item company-nav-link">
            <NavLink to="/" className="nav-link" activeClassName="com-active">
              Working at Montra
            </NavLink>
          </li> */}
                      {/* <li className="nav-item company-nav-link">
            <NavLink to="/" className="nav-link" activeClassName="com-active">
              Contact Us
            </NavLink>
          </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Company;
