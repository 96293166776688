import React from 'react';
import Sidebar from '../Sidebar';
import CheckerManageUserPart1 from './CheckerManageUserPart1';
import CheckerManageUserPart2 from './CheckerManageUserPart2';
import Header2 from '../CheckerHeader';
import axios from 'axios';
import ForgotPin from "../../ForgotPin/ForgotPin";
import Loader from '../Loader';



/**
 * This CheckerManageUser component is render manage user(parent where we call both normal users and credit-uw users) for checker section.
 * <p>There are two type of users will be there :</p>
 * <li>Credit-uw users.</li>
 * <li>Normal users.</li>
 * This allows you to verify the changes made by maker,checker can either approve or reject the changes.
 * Once the changes are done cheecker allowed to submit the changes by entering their user pin which was setup during sign-up.
 * @Author : Thrilok Kandra
 * @version :1.0
 */
class CheckerManageUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserNamelist: [],
      isEmaillist: [],
      isUserTypelist: [],
      isDesignationlist: [],
      isMobilelist: [],
      isUserNameclasslist: [],
      isEmailclasslist: [],
      isUserTypeclasslist: [],
      isDesignationclasslist: [],
      isMobileclasslist: [],
      isUserNamefieldclasslist: [],
      isEmailfieldclasslist: [],
      isUserTypefieldclasslist: [],
      isDesignationfieldclasslist: [],
      isMobilefieldclasslist: [],
      isUserNamecredituwlist: [],
      isEmailcredituwlist: [],
      isUserTypecredituwlist: [],
      isDesignationcredituwlist: [],
      isMobilecredituwlist: [],
      isUserNamecredituwclasslist: [],
      isEmailcredituwclasslist: [],
      isUserTypecredituwclasslist: [],
      isDesignationcredituwclasslist: [],
      isMobilecredituwclasslist: [],
      isUserNamefieldcredituwclasslist: [],
      isEmailfieldcredituwclasslist: [],
      isUserTypefieldcredituwclasslist: [],
      isDesignationfieldcredituwclasslist: [],
      isMobilefieldcredituwclasslist: [],
      isAssignedlist: [[], []],
      isAssignedclasslist: [[], []],
      isAssignedfieldclasslist: [[], []],
      isRemovedclasslist: [],
      isRemovedlist: [],
      isRemovedfieldclasslist: [],
      isRemovedcredituwclasslist: [],
      isRemovedcredituwlist: [],
      isRemovedfieldcredituwclasslist: [],
      isActiveclasslist: [],
      isActivelist: [],
      isActivefieldclasslist: [],
      isActivecredituwclasslist: [],
      isActivecredituwlist: [],
      isActivefieldcredituwclasslist: [],
      isRemovedyesorno: [],
      isRemovedyesornocredituw: [],
      dataarraycredituw:
        [{
          isActive: '', isDesignation: '', isEmail: '', isMobile: '', isRemoved: '', isUserName: '', isUserType: '',
          officialEmail: '', designation: '', userType: '', mobile: '', active: '', userName: '', prodsAssignedForUWRules: [
            {
              companyId: '',
              email: '',
              id: '',
              isAssgined: '',
              lastUpdaDateTime: '',
              prodAssigned: '',
              productName: '',
              requestId: '',
              status: ''
            }]
        }
        ],
      dataarray: [{
        email: '', designation: '', userType: '', mobile: '', prodsAssignedForUWRules: [], active: '', userName: '', isUserName: '', isEmail: '', isUserType: '', isDesignation: '', isMobile: '', isActive: '', isRemoved: ''
      }],
      status: '',
      isDisabled: false,
      message: '',
      backerrors: [],
      errorMsg: '',
      errors: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  /**
   *
   * @param {Event} e captures the value for pin on onChange event
   */
  handleChange(e) {
    let dataarray = this.state.dataarray;
    dataarray[e.target.name] = e.target.value;
    this.setState({
      dataarray: dataarray
    })

  }
  /**
   * This will validate the pin.
   * No Parameters for this method.
   */
  handleSubmitvalidateForm() {
    let errors = this.state.errors;
    let formIsValid = true;
    if (this.state.dataarray.pin == '' || this.state.dataarray.pin == null || this.state.dataarray.pin.length != 4) {
      formIsValid = false;
      errors['pin'] = '*Please enter 4 digit pin.';
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }
  /**
   *
   * @param {Event} e Submits data to server on click of submit in manage user checker section
   */
  handleSubmit = (e) => {
    isDisabledSubmitButton = true;
    e.preventDefault();
    if (this.handleSubmitvalidateForm()) {
      this.setState({ loading: true });
      this.setState({
        errors: []
      });

      // Execute all the necessary setOnAndOffChange functions
      this.setOnAndOffChangeisUserName();
      this.setOnAndOffChangeisDesignation();
      this.setOnAndOffChangeisEmail();
      this.setOnAndOffChangeisUserType();
      this.setOnAndOffChangeisMobile();
      this.setOnAndOffChangeactive();
      this.setOnAndOffChangeRemove();
      this.setOnAndOffChangecredituwactive();
      this.setOnAndOffChangecredituwRemoved();
      this.setOnAndOffChangecredituwisDesignation();
      this.setOnAndOffChangecredituwisEmail();
      this.setOnAndOffChangecredituwisMobile();
      this.setOnAndOffChangecredituwisUserName();
      this.setOnAndOffChangecredituwisUserType();
      this.setOnAndOffChangeprodassignedforuw();


      // let data = [...this.state.dataarray, ...this.state.dataarraycredituw];
      // data = data.filter(item => item.isEditable);

      // Combine both arrays and filter for editable items
      let data = [...this.state.dataarray, ...this.state.dataarraycredituw];
      data = data.filter(item => item.isEditable) || [];

      console.log("editabledata", data)
      let url = 'api/user/updateusers';
      let params = {
        email: localStorage.getItem("email"),
        pin: this.state.dataarray.pin
      };
      axios.post(url, data, {
        params: params,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        }
      })
        .then((response) => {
          this.setState({ loading: false });
          const contents = response.data;
          if (response.status === 200 && contents.message === 'SUCCESS') {
            isDisabledSubmitButton = true;
            this.setState({
              isDisabled: true,
              status: contents.status,
              message: contents.message
            });
            this.setState({
              isActivefieldclasslist: this.state.isActivefieldclasslist.map(() => '#e9ecef'),
              isActiveclasslist: this.state.isActiveclasslist.map(() => '#bcbcbb'),
              isUserNamefieldclasslist: this.state.isUserNamefieldclasslist.map(() => '#e9ecef'),
              isUserNameclasslist: this.state.isUserNameclasslist.map(() => '#bcbcbb'),
              isDesignationfieldclasslist: this.state.isDesignationfieldclasslist.map(() => '#e9ecef'),
              isDesignationclasslist: this.state.isDesignationclasslist.map(() => '#bcbcbb'),
              isUserTypefieldclasslist: this.state.isUserTypefieldclasslist.map(() => '#e9ecef'),
              isUserTypeclasslist: this.state.isUserTypeclasslist.map(() => '#bcbcbb'),
              isMobilefieldclasslist: this.state.isMobilefieldclasslist.map(() => '#e9ecef'),
              isMobileclasslist: this.state.isMobileclasslist.map(() => '#bcbcbb'),
              isRemovedfieldclasslist: this.state.isRemovedfieldclasslist.map(() => '#e9ecef'),
              isRemovedclasslist: this.state.isRemovedclasslist.map(() => '#bcbcbb'),
              isEmailfieldclasslist: this.state.isEmailfieldclasslist.map(() => '#e9ecef'),
              isEmailclasslist: this.state.isEmailclasslist.map(() => '#bcbcbb'),

              isUserNamefieldcredituwclasslist: this.state.isUserNamefieldcredituwclasslist.map(() => '#e9ecef'),
              isUserNamecredituwclasslist: this.state.isUserNamecredituwclasslist.map(() => '#bcbcbb'),
              isDesignationfieldcredituwclasslist: this.state.isDesignationfieldcredituwclasslist.map(() => '#e9ecef'),
              isDesignationcredituwclasslist: this.state.isDesignationcredituwclasslist.map(() => '#bcbcbb'),
              isEmailfieldcredituwclasslist: this.state.isEmailfieldcredituwclasslist.map(() => '#e9ecef'),
              isEmailcredituwclasslist: this.state.isEmailcredituwclasslist.map(() => '#bcbcbb'),
              isMobilefieldcredituwclasslist: this.state.isMobilefieldcredituwclasslist.map(() => '#e9ecef'),
              isMobilecredituwclasslist: this.state.isMobilecredituwclasslist.map(() => '#bcbcbb'),
              isUserTypefieldcredituwclasslist: this.state.isUserTypefieldcredituwclasslist.map(() => '#e9ecef'),
              isUserTypecredituwclasslist: this.state.isUserTypecredituwclasslist.map(() => '#bcbcbb'),
              isActivefieldcredituwclasslist: this.state.isActivefieldcredituwclasslist.map(() => '#e9ecef'),
              isActivecredituwclasslist: this.state.isActivecredituwclasslist.map(() => '#bcbcbb'),
              isRemovedfieldcredituwclasslist: this.state.isRemovedfieldcredituwclasslist.map(() => '#e9ecef'),
              isRemovedcredituwclasslist: this.state.isRemovedcredituwclasslist.map(() => '#bcbcbb'),
              isAssignedfieldclasslist: this.state.isAssignedfieldclasslist.map(() => '#e9ecef'),
              isAssignedclasslist: this.state.isAssignedclasslist.map(() => '#bcbcbb'),
            });
          } else {
            isDisabledSubmitButton = false;
            this.setState({
              dataarray: data, // Keep original data
              backerrors: contents.errors,
              status: contents.status,
              message: contents.message
            });
            // Execute subsequent change functions
            this.onSubmitChangeCredituwIsEmail();
            this.onSubmitChangeCredituwisActive();
            this.onSubmitChangeCredituwisRemoved();
            this.onSubmitChangeCredituwisDesignation();
            this.onSubmitChangeCredituwisMobile();
            this.onSubmitChangeCredituwisUserType();
            this.onSubmitChangeCreditUwisUserName();
            this.onSubmitChangeIsEmail();
            this.onSubmitChangeisDesignation();
            this.onSubmitChangeisUserName();
            this.onSubmitChangeisUserType();
            this.onSubmitChangeisMobile();
            this.onSubmitChangeisActive();
            this.onSubmitChangeisRemoved();
            this.onSubmitChangeCredituwProdAssigned();
            // Handle backend errors
            this.setState({
              errorMsg: contents.errors.map(key => key.message).join(', ')
            });
          }
        })
        .catch((error) => {
          console.error("Submission error:", error);
          alert("Failed to submit data. Please try again later.");
        });
    }
  };



  setNforNonEditable() {
    this.state.dataarray.map(manageUser => {
      manageUser.isUserName = "N";
      manageUser.isEmail = "N";
      manageUser.isUserType = "N";
      manageUser.isDesignation = "N";
      manageUser.isMobile = "N";
      manageUser.isActive = "N";
      manageUser.isRemoved = "N";
    })
    this.state.dataarraycredituw.map(manageUser => {
      manageUser.isUserName = "N";
      manageUser.isEmail = "N";
      manageUser.isUserType = "N";
      manageUser.isDesignation = "N";
      manageUser.isMobile = "N";
      manageUser.isActive = "N";
      manageUser.isRemoved = "N";
      manageUser.prodsAssignedForUWRules.map(prodsAssignedForUWRules => {
        prodsAssignedForUWRules.isAssgined = "N";
      })
    })
  }
  /**
   * Request will sent to server and update the into data array.
   */
  componentDidMount() {
    this.setState({ loading: true });
    let url = 'api/user/users';
    let params = {
      email: localStorage.getItem("email")
    };
    axios.get(url, {
      params: params,
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
        // 'Access-Control-Allow-Origin': '*',
      }
    })
      .then(response => {
        const contents = response.data;
        console.log('API Response:', contents); // Debugging: log the API response
        this.setState({ loading: false });
        if (response.status === 200 && contents.message === 'SUCCESS') {
          this.OnAndOffChangecredituwarray(contents.data.CreditUWUsers.length);
          this.setState({
            dataarray: contents.data.RegularUsers,
            dataarraycredituw: contents.data.CreditUWUsers,
            status: contents.status,
            editable: contents.data.editable,
          }, () => {
            // if (!this.state.editable) {
            //   this.setNforNonEditable();
            // }

            this.onAndOffChangeIsUserName();
            this.onAndOffChangeIsDesignation();
            this.onAndOffChangeIsEmail();
            this.onAndOffChangeIsMobile();
            this.onAndOffChangeIsUserType();
            this.onAndOffChangecredituwIsUserName();
            this.onAndOffChangecredituwIsDesignation();
            this.onAndOffChangecredituwIsEmail();
            this.onAndOffChangecredituwIsMobile();
            this.onAndOffChangecredituwIsUserType();
            this.onAndOffChangeactive();
            this.onAndOffChangecredituwisActive();
            this.onAndOffChangecredituwisRemoved();
            this.onAndOffChangeisRemoved();
            this.onAndOffChangeprodassignedforuw();
          });
          // var isAnyChanges = false;
          // contents.data.RegularUsers.map(item => {
          //   if (item.isEditable) {
          //     isAnyChanges = true;
          //   }
          // })
          // if (!isAnyChanges) {
          //   isDisabledSubmitButton = true;
          // }
        } else {
          this.setState({
            backerrors: contents.errors,
            status: contents.status,
            message: contents.message
          });
          this.state.backerrors.forEach(key => {
            this.setState({
              errorMsg: key.message
            });
          });
        }
      })
      .catch(error => {
        console.error('Fetch error:', error); // Debugging: log the error
        this.setState({
          errorMsg: 'Failed to fetch data. Please try again later.'
        });
      });
  }



  /**
   * @typedef {data} data
   * @property {string} userName indicates username
   * @property {String} email  indicates email of user
   * @property {string} userType indicates role of user
   * @property {String} mobile indicates mobile number of user
   * @property {String} designation indicates designation of user
   */

  /**
   * Captures the boolean value for(other than credit-uw users) active toggle button
   *
   * @param {Event} e  event to call this method
   * @param {data[]} data index of data array
   */
  toggleChangeActive(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].isActive = !this.state.dataarray[i].isActive
    if (this.state.dataarray[i].isActive) {
      this.state.isActiveclasslist[i] = "#2ab934"
    } else {
      this.state.isActiveclasslist[i] = "#6c757d"
    }
    this.setState({
      dataarray
    });
  }
  /**
   *Captures the boolean value for(credit-uw users) active toggle button
   * @param {Event} e  event to call this method.
   * @param {data[]} data index of data array.
   */
  toggleChangecredituwActive(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].isActive = !this.state.dataarraycredituw[i].isActive
    if (this.state.dataarraycredituw[i].isActive) {
      this.state.isActivecredituwclasslist[i] = "#2ab934"
    } else {
      this.state.isActivecredituwclasslist[i] = "#6c757d"
    }
    this.setState({
      dataarraycredituw
    });
  }
  /**
  *Captures the boolean value for(credit-uw users) removal toggle button
  * @param {Event} e  event to call this method.
  * @param {data[]} data index of data array.
   */

  toggleChangecredituwremoval(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].isRemoved = !this.state.dataarraycredituw[i].isRemoved
    if (this.state.dataarraycredituw[i].isRemoved) {
      this.state.isRemovedcredituwclasslist[i] = "#2ab934"
    } else {
      this.state.isRemovedcredituwclasslist[i] = "#6c757d"
    }
    this.setState({
      dataarraycredituw
    });
  }
  /**
   *Captures the boolean value for(other than credit-uw users) removal toggle button
   * @param {Event} e  event to call this method.
   * @param {data[]} data index of data array.
   */
  toggleChangeremoval(e, i) {
    let dataarray = this.state.dataarray;
    this.state.dataarray[i].isRemoved = !this.state.dataarray[i].isRemoved
    if (this.state.dataarray[i].isRemoved) {
      this.state.isRemovedclasslist[i] = "#2ab934"
    } else {
      this.state.isRemovedclasslist[i] = "#6c757d"
    }
    this.setState({
      dataarray
    });
  }
  /**
   *Captures the boolean value for(credit-uw users) username toggle button
   * @param {Event} e  event to call this method.
   * @param {data[]} data index of data array.
   */
  toggleChangecredituwIsUserName(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isUserNamecredituwclasslist = this.state.isUserNamecredituwclasslist;
    this.state.dataarraycredituw[i].isUserName = !this.state.dataarraycredituw[i].isUserName;
    if (true == this.state.dataarraycredituw[i].isUserName) {
      this.state.isUserNamecredituwclasslist[i] = "#2ab934";
    } else {
      this.state.isUserNamecredituwclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarraycredituw,
      isUserNamecredituwclasslist
    });
  }
  /**
     *Captures the boolean value for(credit-uw users) designation toggle button
     * @param {Event} e  event to call this method.
     * @param {data[]} data index of data array.
     */
  toggleChangecredituwIsDesignation(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isDesignationcredituwclasslist = this.state.isDesignationcredituwclasslist;
    this.state.dataarraycredituw[i].isDesignation = !this.state.dataarraycredituw[i].isDesignation;
    if (true == this.state.dataarraycredituw[i].isDesignation) {
      this.state.isDesignationcredituwclasslist[i] = "#2ab934";
    } else {
      this.state.isDesignationcredituwclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarraycredituw,
      isDesignationcredituwclasslist
    });
  }
  /**
   *Captures the boolean value for(credit-uw users) usertype toggle button
   * @param {Event} e  event to call this method.
   * @param {data[]} data index of data array.
   */
  toggleChangecredituwIsUserType(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isUserTypecredituwclasslist = this.state.isUserTypecredituwclasslist;
    this.state.dataarraycredituw[i].isUserType = !this.state.dataarraycredituw[i].isUserType;
    if (true == this.state.dataarraycredituw[i].isUserType) {
      this.state.isUserTypecredituwclasslist[i] = "#2ab934";
    } else {
      this.state.isUserTypecredituwclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarraycredituw,
      isUserTypecredituwclasslist
    });
  }
  /**
   *Captures the boolean value for(credit-uw users) email toggle button
   * @param {Event} e  event to call this method.
   * @param {data[]} data index of data array.
   */
  toggleChangecredituwIsEmail(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isEmailcredituwclasslist = this.state.isEmailcredituwclasslist;
    this.state.dataarraycredituw[i].isEmail = !this.state.dataarraycredituw[i].isEmail;
    if (true == this.state.dataarraycredituw[i].isEmail) {
      this.state.isEmailcredituwclasslist[i] = "#2ab934";
    } else {
      this.state.isEmailcredituwclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarraycredituw,
      isEmailcredituwclasslist
    });
  }
  /**
   *Captures the boolean value for(credit-uw users) mobile toggle button
   * @param {Event} e  event to call this method.
   * @param {data[]} data index of data array.
   */
  toggleChangecredituwIsMobile(e, i) {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isMobilecredituwclasslist = this.state.isMobilecredituwclasslist;
    this.state.dataarraycredituw[i].isMobile = !this.state.dataarraycredituw[i].isMobile;
    if (true == this.state.dataarraycredituw[i].isMobile) {
      this.state.isMobilecredituwclasslist[i] = "#2ab934";
    } else {
      this.state.isMobilecredituwclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarraycredituw,
      isMobilecredituwclasslist
    });
  }
  /**
     *Captures the boolean value for(other than credit-uw users) username toggle button
     * @param {Event} e  event to call this method.
     * @param {data[]} data index of data array.
     */
  toggleChangeIsUserName(e, i) {
    let dataarray = this.state.dataarray;
    let isUserNameclasslist = this.state.isUserNameclasslist;
    this.state.dataarray[i].isUserName = !this.state.dataarray[i].isUserName;
    if (true == this.state.dataarray[i].isUserName) {
      this.state.isUserNameclasslist[i] = "#2ab934";
    } else {
      this.state.isUserNameclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarray,
      isUserNameclasslist
    });
  }
  /**
       *Captures the boolean value for(other than credit-uw users) designation toggle button
       * @param {Event} e  event to call this method.
       * @param {data[]} data index of data array.
       */
  toggleChangeIsDesignation(e, i) {
    let dataarray = this.state.dataarray;
    let isDesignationclasslist = this.state.isDesignationclasslist;
    this.state.dataarray[i].isDesignation = !this.state.dataarray[i].isDesignation;
    if (true == this.state.dataarray[i].isDesignation) {
      this.state.isDesignationclasslist[i] = "#2ab934";
    } else {
      this.state.isDesignationclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarray,
      isDesignationclasslist
    });
  }
  /**
     *Captures the boolean value for(other than credit-uw users) userType toggle button
     * @param {Event} e  event to call this method.
     * @param {data[]} data index of data array.
     */
  toggleChangeIsUserType(e, i) {
    let dataarray = this.state.dataarray;
    let isUserTypeclasslist = this.state.isUserTypeclasslist;
    this.state.dataarray[i].isUserType = !this.state.dataarray[i].isUserType;
    if (true == this.state.dataarray[i].isUserType) {
      this.state.isUserTypeclasslist[i] = "#2ab934";
    } else {
      this.state.isUserTypeclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarray,
      isUserTypeclasslist
    });
  }
  /**
     *Captures the boolean value for(other than credit-uw users) email toggle button
     * @param {Event} e  event to call this method.
     * @param {data[]} data index of data array.
     */
  toggleChangeIsEmail(e, i) {
    let dataarray = this.state.dataarray;
    let isEmailclasslist = this.state.isEmailclasslist;
    this.state.dataarray[i].isEmail = !this.state.dataarray[i].isEmail;
    if (true == this.state.dataarray[i].isEmail) {
      this.state.isEmailclasslist[i] = "#2ab934";
    } else {
      this.state.isEmailclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarray,
      isEmailclasslist
    });
  }
  /**
     *Captures the boolean value for(other than credit-uw users) mobile toggle button
     * @param {Event} e  event to call this method.
     * @param {data[]} data index of data array.
     */
  toggleChangeIsMobile(e, i) {
    let dataarray = this.state.dataarray;
    let isMobileclasslist = this.state.isMobileclasslist;
    this.state.dataarray[i].isMobile = !this.state.dataarray[i].isMobile;
    if (true == this.state.dataarray[i].isMobile) {
      this.state.isMobileclasslist[i] = "#2ab934";
    } else {
      this.state.isMobileclasslist[i] = "#6c757d";
    }
    this.setState({
      dataarray,
      isMobileclasslist
    });
  }
  /**
   * Sets the boolean value for(credit-uw users) username toggle button,disable buttons and background color
  */
  setOnAndOffChangecredituwisUserName() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isUserNamelist[i]) {
        if (true == manageUser.isUserName) {
          manageUser.isUserName = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isUserName = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isUserName = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  /**
     * Sets the boolean value for(credit-uw users) mobile toggle button,disable buttons and background color
    */
  setOnAndOffChangecredituwisMobile() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isMobilecredituwlist[i]) {
        if (true == manageUser.isMobile) {
          manageUser.isMobile = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isMobile = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isMobile = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  /**
   * Sets the boolean value for(credit-uw users) email toggle button,disable buttons and background color
  */
  setOnAndOffChangecredituwisEmail() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isEmailcredituwlist[i]) {
        if (true == manageUser.isEmail) {
          manageUser.isEmail = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isEmail = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isEmail = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  /**
   * Sets the boolean value for(credit-uw users) userType toggle button,disable buttons and background color
  */
  setOnAndOffChangecredituwisUserType() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isUserTypecredituwlist[i]) {
        if (true == manageUser.isUserType) {
          manageUser.isUserType = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isUserType = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isUserType = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  /**
   * Sets the boolean value for(credit-uw users) designation toggle button,disable buttons and background color
  */
  setOnAndOffChangecredituwisDesignation() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isDesignationcredituwlist[i]) {
        if (true == manageUser.isDesignation) {
          manageUser.isDesignation = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isDesignation = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isDesignation = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) active toggle button,disable buttons and background color
    */
  setOnAndOffChangeactive() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((manageUser, i) => {
      if (false == this.state.isActivelist[i]) {
        if (manageUser.isActive) {
          manageUser.isActive = "A"
          this.setState({
            dataarray
          });
        } else {
          manageUser.isActive = "R"
          this.setState({
            dataarray
          });
        }
      }
      else {
        manageUser.isActive = "N"
        this.setState({
          dataarray
        });
      }
    })
  }
  /**
   * Sets the boolean value for(other than credit-uw users) remove toggle button,disable buttons and background color
  */
  setOnAndOffChangeRemove() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((manageUser, i) => {
      if (false == this.state.isRemovedlist[i]) {
        if (manageUser.isRemoved) {
          manageUser.isRemoved = "A"
          this.setState({
            dataarray
          });
        } else {
          manageUser.isRemoved = "R"
          this.setState({
            dataarray
          });
        }
      }
      else {
        manageUser.isRemoved = "N"
        this.setState({
          dataarray
        });
      }
    })
  }
  /**
   * Sets the boolean value for(credit-uw users) active toggle button,disable buttons and background color
  */
  setOnAndOffChangecredituwactive() {
    let dataarray = this.state.dataarray;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isActivecredituwlist[i]) {
        if (manageUser.isActive) {
          manageUser.isActive = "A"
          this.setState({
            dataarray
          });
        } else {
          manageUser.isActive = "R"
          this.setState({
            dataarray
          });
        }
      } else {
        manageUser.isActive = "N"
        this.setState({
          dataarray
        });
      }
    })
  }
  /**
   * Sets the boolean value for(credit-uw users) removal toggle button,disable buttons and background color
  */
  setOnAndOffChangecredituwRemoved() {
    let dataarray = this.state.dataarray;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (!this.state.isRemovedcredituwlist[i]) {
        if (manageUser.isRemoved) {
          manageUser.isRemoved = "A"
          this.setState({
            dataarray
          });
        } else {
          manageUser.isRemoved = "R"
          this.setState({
            dataarray
          });
        }
      } else {
        manageUser.isRemoved = "N"
        this.setState({
          dataarray
        });
      }
    })
  }
  /**
    * Sets the boolean value for(other than credit-uw users) email toggle button,disable buttons and background color after submitting data to server
   */
  onSubmitChangeIsEmail() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isEmailcredituwlist = this.state.isEmailcredituwlist;
    let isEmailcredituwclasslist = this.state.isEmailcredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isEmail) {
        manageUser.isEmail = true
        this.state.isEmailcredituwlist[i] = true
        this.state.isEmailcredituwclasslist[i] = "#bcbcbb"
        this.setState({
          dataarraycredituw,
          isEmailcredituwlist,
          isEmailcredituwclasslist
        });
      } else {
        if ("A" == manageUser.isEmail) {
          manageUser.isEmail = true
          this.state.isEmailcredituwlist[i] = false
          this.state.isEmailcredituwclasslist[i] = "#2ab934"
          this.setState({
            dataarraycredituw,
            isEmailcredituwlist,
            isEmailcredituwclasslist
          });
        }
        else {
          manageUser.isEmail = false
          this.state.isEmailcredituwlist[i] = false
          this.state.isEmailcredituwclasslist[i] = "#6c757d"
          this.setState({
            dataarraycredituw,
            isEmailcredituwlist,
            isEmailcredituwclasslist
          });
        }
      }

    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) active toggle button,disable buttons and background color after submitting data to server
    */
  onSubmitChangeisActive() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isActivecredituwlist = this.state.isActivecredituwlist;
    let isActivecredituwclasslist = this.state.isActivecredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isActive) {
        manageUser.isActive = true
        this.state.isActivecredituwlist[i] = true
        this.state.isActivecredituwclasslist[i] = "#bcbcbb"
        this.setState({
          dataarraycredituw,
          isActivecredituwlist,
          isActivecredituwclasslist
        });
      } else {
        if ("A" == manageUser.isActive) {
          manageUser.isActive = true
          this.state.isActivecredituwlist[i] = false
          this.state.isActivecredituwclasslist[i] = "#2ab934"
          this.setState({
            dataarraycredituw,
            isActivecredituwlist,
            isActivecredituwclasslist
          });
        }
        else {
          manageUser.isActive = false
          this.state.isActivecredituwlist[i] = false
          this.state.isActivecredituwclasslist[i] = "#6c757d"
          this.setState({
            dataarraycredituw,
            isActivecredituwlist,
            isActivecredituwclasslist
          });
        }
      }
    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) active toggle button,disable buttons and background color
    */
  onAndOffChangeactive() {
    let dataarray = this.state.dataarray;
    let isActivelist = this.state.isActivelist;
    let isActiveclasslist = this.state.isActiveclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isActive) {
        manageUser.isActive = true
        this.state.isActivelist[i] = true
        this.state.isActiveclasslist[i] = "#bcbcbb"
        this.state.isActivefieldclasslist[i] = "#e9ecef"
        this.setState({
          dataarray,
          isActivelist,
          isActiveclasslist
        });
      } else {
        manageUser.isActive = true
        this.state.isActivelist[i] = false
        this.state.isActiveclasslist[i] = "#2ab934"
        this.state.isActivefieldclasslist[i] = "#bce394"
        this.setState({
          dataarray,
          isActivelist,
          isActiveclasslist
        });
      }
    })
  }
  /**
     * Sets the boolean value for(credit-uw users) email toggle button,disable buttons and background color after submitting data to server
    */
  onSubmitChangeCredituwIsEmail() {
    let dataarray = this.state.dataarray;
    let isEmaillist = this.state.isEmaillist;
    let isEmailclasslist = this.state.isEmailclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isEmail) {
        manageUser.isEmail = true
        this.state.isEmaillist[i] = true
        this.state.isEmailclasslist[i] = "#bcbcbb"
        this.setState({
          dataarray,
          isEmaillist,
          isEmailclasslist
        });
      } else {
        if ("A" == manageUser.isEmail) {
          manageUser.isEmail = true
          this.state.isEmaillist[i] = false
          this.state.isEmailclasslist[i] = "#2ab934"
          this.setState({
            dataarray,
            isEmaillist,
            isEmailclasslist
          });
        }
        else {
          manageUser.isEmail = false
          this.state.isEmaillist[i] = false
          this.state.isEmailclasslist[i] = "#6c757d"
          this.setState({
            dataarray,
            isEmaillist,
            isEmailclasslist
          });
        }
      }

    })
  }
  /**
   * Sets the boolean value for(credit-uw users) mobile toggle button,disable buttons and background color after submitting data to server
  */
  onSubmitChangeCredituwisMobile() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isMobilecredituwlist = this.state.isMobilecredituwlist;
    let isMobilecredituwclasslist = this.state.isMobilecredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isMobile) {
        manageUser.isMobile = true
        this.state.isMobilecredituwlist[i] = true
        this.state.isMobilecredituwclasslist[i] = "#bcbcbb"
        this.setState({
          dataarraycredituw,
          isMobilecredituwlist,
          isMobilecredituwclasslist
        });
      } else {
        if ("A" == manageUser.isMobile) {
          manageUser.isMobile = true
          this.state.isMobilecredituwlist[i] = false
          this.state.isMobilecredituwclasslist[i] = "#2ab934"
          this.setState({
            dataarraycredituw,
            isMobilecredituwlist,
            isMobilecredituwclasslist
          });
        }
        else {
          manageUser.isMobile = false
          this.state.isMobilecredituwlist[i] = false
          this.state.isMobilecredituwclasslist[i] = "#6c757d"
          this.setState({
            dataarraycredituw,
            isMobilecredituwlist,
            isMobilecredituwclasslist
          });
        }
      }
    })
  }
  /**
     * Sets the boolean value for(credit-uw users) userType toggle button,disable buttons and background color after submitting data to server
    */
  onSubmitChangeCredituwisUserType() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isUserTypecredituwlist = this.state.isUserTypecredituwlist;
    let isUserTypecredituwclasslist = this.state.isUserTypecredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isUserType) {
        manageUser.isUserType = true
        this.state.isUserTypecredituwlist[i] = true
        this.state.isUserTypecredituwclasslist[i] = "#bcbcbb"
        this.setState({
          dataarraycredituw,
          isUserTypecredituwlist,
          isUserTypecredituwclasslist
        });
      } else {
        if ("A" == manageUser.isUserType) {
          manageUser.isUserType = true
          this.state.isUserTypecredituwlist[i] = false
          this.state.isUserTypecredituwclasslist[i] = "#2ab934"
          this.setState({
            dataarraycredituw,
            isUserTypecredituwlist,
            isUserTypecredituwclasslist
          });
        }
        else {
          manageUser.isUserType = false
          this.state.isUserTypecredituwlist[i] = false
          this.state.isUserTypecredituwclasslist[i] = "#6c757d"
          this.setState({
            dataarraycredituw,
            isUserTypecredituwlist,
            isUserTypecredituwclasslist
          });
        }
      }
    })
  }
  /**
     * Sets the boolean value for(credit-uw users) active toggle button,disable buttons and background color after submitting data to server
    */
  onSubmitChangeCredituwisActive() {
    let dataarray = this.state.dataarray;
    let isActivelist = this.state.isActivelist;
    let isActiveclasslist = this.state.isActiveclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isActive) {
        manageUser.isActive = true
        this.state.isActivelist[i] = true
        this.state.isActiveclasslist[i] = "#bcbcbb"
        this.setState({
          dataarray,
          isActivelist,
          isActiveclasslist
        });
      } else {
        if ("A" == manageUser.isActive) {
          manageUser.isActive = true
          this.state.isActivelist[i] = false
          this.state.isActiveclasslist[i] = "#2ab934"
          this.setState({
            dataarray,
            isActivelist,
            isActiveclasslist
          });
        }
        else {
          manageUser.isActive = false
          this.state.isActivelist[i] = false
          this.state.isActiveclasslist[i] = "#6c757d"
          this.setState({
            dataarray,
            isActivelist,
            isActiveclasslist
          });
        }
      }

    })
  }
  /**
   * Sets the boolean value for(other than credit-uw users) remove toggle button,disable buttons and background color after submitting data to server
  */
  onSubmitChangeisRemoved() {
    let dataarray = this.state.dataarray;
    let isRemovedlist = this.state.isRemovedlist;
    let isRemovedclasslist = this.state.isRemovedclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isRemoved) {
        manageUser.isRemoved = true
        this.state.isRemovedlist[i] = true
        this.state.isRemovedclasslist[i] = "#bcbcbb"
        this.setState({
          dataarray,
          isRemovedlist,
          isRemovedclasslist
        });
      } else {
        if ("A" == manageUser.isRemoved) {
          manageUser.isRemoved = true
          this.state.isRemovedlist[i] = false
          this.state.isRemovedclasslist[i] = "#2ab934"
          this.setState({
            dataarray,
            isRemovedlist,
            isRemovedclasslist
          });
        }
        else {
          manageUser.isRemoved = false
          this.state.isRemovedlist[i] = false
          this.state.isRemovedclasslist[i] = "#6c757d"
          this.setState({
            dataarray,
            isRemovedlist,
            isRemovedclasslist
          });
        }
      }

    })
  }
  /**
   * Sets the boolean value for(credit-uw users) remove toggle button,disable buttons and background color after submitting data to server
  */
  onSubmitChangeCredituwisRemoved() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isRemovedcredituwlist = this.state.isRemovedcredituwlist;
    let isRemovedcredituwclasslist = this.state.isRemovedcredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isRemoved) {
        manageUser.isRemoved = true
        this.state.isRemovedcredituwlist[i] = true
        this.state.isRemovedcredituwclasslist[i] = "#bcbcbb"
        this.setState({
          dataarraycredituw,
          isRemovedcredituwlist,
          isRemovedcredituwclasslist
        });
      } else {
        if ("A" == manageUser.isRemoved) {
          manageUser.isRemoved = true
          this.state.isRemovedcredituwlist[i] = false
          this.state.isRemovedcredituwclasslist[i] = "#2ab934"
          this.setState({
            dataarraycredituw,
            isRemovedcredituwlist,
            isRemovedcredituwclasslist
          });
        }
        else {
          manageUser.isRemoved = false
          this.state.isRemovedcredituwlist[i] = false
          this.state.isRemovedcredituwclasslist[i] = "#6c757d"
          this.setState({
            dataarraycredituw,
            isRemovedcredituwlist,
            isRemovedcredituwclasslist
          });
        }
      }
    })
  }
  /**
     * Sets the boolean value for (credit-uw users) designation toggle button,disable buttons and background color after submitting data to server
    */
  onSubmitChangeCredituwisDesignation() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isDesignationcredituwlist = this.state.isDesignationcredituwlist;
    let isDesignationcredituwclasslist = this.state.isDesignationcredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isDesignation) {
        manageUser.isDesignation = true
        this.state.isDesignationcredituwlist[i] = true
        this.state.isDesignationcredituwclasslist[i] = "#bcbcbb"
        this.setState({
          dataarraycredituw,
          isDesignationcredituwlist,
          isDesignationcredituwclasslist
        });
      } else {
        if ("A" == manageUser.isDesignation) {
          manageUser.isDesignation = true
          this.state.isDesignationcredituwlist[i] = false
          this.state.isDesignationcredituwclasslist[i] = "#2ab934"
          this.setState({
            dataarraycredituw,
            isDesignationcredituwlist,
            isDesignationcredituwclasslist
          });
        }
        else {
          manageUser.isDesignation = false
          this.state.isDesignationcredituwlist[i] = false
          this.state.isDesignationcredituwclasslist[i] = "#6c757d"
          this.setState({
            dataarraycredituw,
            isDesignationcredituwlist,
            isDesignationcredituwclasslist
          });
        }
      }
    })
  }
  /**
   * Sets the boolean value for(credit-uw users) username toggle button,disable buttons and background color after submitting data to server
  */
  onSubmitChangeCreditUwisUserName() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isUserNamecredituwlist = this.state.isUserNamecredituwlist;
    let isUserNamecredituwclasslist = this.state.isUserNamecredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isUserName) {
        manageUser.isUserName = true
        this.state.isUserNamecredituwlist[i] = true
        this.state.isUserNamecredituwclasslist[i] = "#bcbcbb"
        this.setState({
          dataarraycredituw,
          isUserNamecredituwlist,
          isUserNamecredituwclasslist
        });
      } else {
        if ("A" == manageUser.isUserName) {
          manageUser.isUserName = true
          this.state.isUserNamecredituwlist[i] = false
          this.state.isUserNamecredituwclasslist[i] = "#2ab934"
          this.setState({
            dataarraycredituw,
            isUserNamecredituwlist,
            isUserNamecredituwclasslist
          });
        }
        else {
          manageUser.isUserName = false
          this.state.isUserNamecredituwlist[i] = false
          this.state.isUserNamecredituwclasslist[i] = "#6c757d"
          this.setState({
            dataarraycredituw,
            isUserNamecredituwlist,
            isUserNamecredituwclasslist
          });
        }
      }
    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) username toggle button,disable buttons and background color after submitting data to server
    */
  onSubmitChangeisUserName() {
    let dataarray = this.state.dataarray;
    let isUserNamelist = this.state.isUserNamelist;
    let isUserNameclasslist = this.state.isUserNameclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isUserName) {
        manageUser.isUserName = true
        this.state.isUserNamelist[i] = true
        this.state.isUserNameclasslist[i] = "#bcbcbb"
        this.setState({
          dataarray,
          isUserNamelist,
          isUserNameclasslist
        });
      } else {
        if ("A" == manageUser.isUserName) {
          manageUser.isUserName = true
          this.state.isUserNamelist[i] = false
          this.state.isUserNameclasslist[i] = "#2ab934"
          this.setState({
            dataarray,
            isUserNamelist,
            isUserNameclasslist
          });
        }
        else {
          manageUser.isUserName = false
          this.state.isUserNamelist[i] = false
          this.state.isUserNameclasslist[i] = "#6c757d"
          this.setState({
            dataarray,
            isUserNamelist,
            isUserNameclasslist
          });
        }
      }

    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) usertype toggle button,disable buttons and background color after submitting data to server
    */
  onSubmitChangeisUserType() {
    let dataarray = this.state.dataarray;
    let isUserTypelist = this.state.isUserTypelist;
    let isUserTypeclasslist = this.state.isUserTypeclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isUserType) {
        manageUser.isUserType = true
        this.state.isUserTypelist[i] = true
        this.state.isUserTypeclasslist[i] = "#bcbcbb"
        this.setState({
          dataarray,
          isUserTypelist,
          isUserTypeclasslist
        });
      } else {
        if ("A" == manageUser.isUserType) {
          manageUser.isUserType = true
          this.state.isUserTypelist[i] = false
          this.state.isUserTypeclasslist[i] = "#2ab934"
          this.setState({
            dataarray,
            isUserTypelist,
            isUserTypeclasslist
          });
        }
        else {
          manageUser.isUserType = false
          this.state.isUserTypelist[i] = false
          this.state.isUserTypeclasslist[i] = "#6c757d"
          this.setState({
            dataarray,
            isUserTypelist,
            isUserTypeclasslist
          });
        }
      }

    })
  }

  /**
     * Sets the boolean value for(other than credit-uw users) username toggle button,disable buttons and background color.
    */
  onAndOffChangeIsUserName() {
    let dataarray = this.state.dataarray;
    let isUserNamelist = this.state.isUserNamelist;
    let isUserNameclasslist = this.state.isUserNameclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isUserName) {
        manageUser.isUserName = true
        this.state.isUserNamelist[i] = true
        this.state.isUserNameclasslist[i] = "#bcbcbb"
        this.state.isUserNamefieldclasslist[i] = "#e9ecef"
        this.setState({
          dataarray,
          isUserNamelist,
          isUserNameclasslist
        });
      } else {
        manageUser.isUserName = true
        this.state.isUserNamelist[i] = false
        this.state.isUserNameclasslist[i] = "#2ab934"
        this.state.isUserNamefieldclasslist[i] = "#bce394"
        this.setState({
          dataarray,
          isUserNamelist,
          isUserNameclasslist
        });
      }


    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) designation toggle button,disable buttons and background color after submitting data to server
    */
  onSubmitChangeisDesignation() {
    let dataarray = this.state.dataarray;
    let isDesignationlist = this.state.isDesignationlist;
    let isDesignationclasslist = this.state.isDesignationclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isDesignation) {
        manageUser.isDesignation = true
        this.state.isDesignationlist[i] = true
        this.state.isDesignationclasslist[i] = "#bcbcbb"
        this.setState({
          dataarray,
          isDesignationlist,
          isDesignationclasslist
        });
      } else {
        if ("A" == manageUser.isDesignation) {
          manageUser.isDesignation = true
          this.state.isDesignationlist[i] = false
          this.state.isDesignationclasslist[i] = "#2ab934"
          this.setState({
            dataarray,
            isDesignationlist,
            isDesignationclasslist
          });
        }
        else {
          manageUser.isDesignation = false
          this.state.isDesignationlist[i] = false
          this.state.isDesignationclasslist[i] = "#6c757d"
          this.setState({
            dataarray,
            isDesignationlist,
            isDesignationclasslist
          });
        }
      }

    })
  }
  /**
   * Sets the boolean value for(other than credit-uw users) mobile toggle button,disable buttons and background color after submitting data to server
  */
  onSubmitChangeisMobile() {
    let dataarray = this.state.dataarray;
    let isMobilelist = this.state.isMobilelist;
    let isMobileclasslist = this.state.isMobileclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isMobile) {
        manageUser.isMobile = true
        this.state.isMobilelist[i] = true
        this.state.isMobileclasslist[i] = "#bcbcbb"
        this.setState({
          dataarray,
          isMobilelist,
          isMobileclasslist
        });
      } else {
        if ("A" == manageUser.isMobile) {
          manageUser.isMobile = true
          this.state.isMobilelist[i] = false
          this.state.isMobileclasslist[i] = "#2ab934"
          this.setState({
            dataarray,
            isMobilelist,
            isMobileclasslist
          });
        }
        else {
          manageUser.isMobile = false
          this.state.isMobilelist[i] = false
          this.state.isMobileclasslist[i] = "#6c757d"
          this.setState({
            dataarray,
            isMobilelist,
            isMobileclasslist
          });
        }
      }

    })
  }

  /**
     * Sets the boolean value for(other than credit-uw users) designation toggle button,disable buttons and background color.
    */
  onAndOffChangeIsDesignation() {
    let dataarray = this.state.dataarray;
    let isDesignationlist = this.state.isDesignationlist;
    let isDesignationclasslist = this.state.isDesignationclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isDesignation) {
        manageUser.isDesignation = true
        this.state.isDesignationlist[i] = true
        this.state.isDesignationclasslist[i] = "#bcbcbb"
        this.state.isDesignationfieldclasslist[i] = "#e9ecef"
        this.setState({
          dataarray,
          isDesignationlist,
          isDesignationclasslist
        });
      } else {
        manageUser.isDesignation = true
        this.state.isDesignationlist[i] = false
        this.state.isDesignationclasslist[i] = "#2ab934"
        this.state.isDesignationfieldclasslist[i] = "#bce394"
        this.setState({
          dataarray,
          isDesignationlist,
          isDesignationclasslist
        });
      }

    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) email toggle button,disable buttons and background color.
    */
  onAndOffChangeIsEmail() {
    let dataarray = this.state.dataarray;
    let isEmaillist = this.state.isEmaillist;
    let isEmailclasslist = this.state.isEmailclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isEmail) {
        manageUser.isEmail = true
        this.state.isEmaillist[i] = true
        this.state.isEmailclasslist[i] = "#bcbcbb"
        this.state.isEmailfieldclasslist[i] = "#e9ecef"
        this.setState({
          dataarray,
          isEmaillist,
          isEmailclasslist
        });
      } else {
        manageUser.isEmail = true
        this.state.isEmaillist[i] = false
        this.state.isEmailclasslist[i] = "#2ab934"
        this.state.isEmailfieldclasslist[i] = "#bce394"
        this.setState({
          dataarray,
          isEmaillist,
          isEmailclasslist
        });
      }

    })
  }

  /**
     * Sets the boolean value for(other than credit-uw users) usertype toggle button,disable buttons and background color after submitting data to server
    */
  onAndOffChangeIsUserType() {
    let dataarray = this.state.dataarray;
    let isUserTypelist = this.state.isUserTypelist;
    let isUserTypeclasslist = this.state.isUserTypeclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isUserType) {
        manageUser.isUserType = true
        this.state.isUserTypelist[i] = true
        this.state.isUserTypeclasslist[i] = "#bcbcbb"
        this.state.isUserTypefieldclasslist[i] = "#e9ecef"
        this.setState({
          dataarray,
          isUserTypelist,
          isUserTypeclasslist
        });
      } else {
        manageUser.isUserType = true
        this.state.isUserTypelist[i] = false
        this.state.isUserTypeclasslist[i] = "#2ab934"
        this.state.isUserTypefieldclasslist[i] = "#bce394"
        this.setState({
          dataarray,
          isUserTypelist,
          isUserTypeclasslist
        });
      }

    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) mobile toggle button,disable buttons and background color.
    */
  onAndOffChangeIsMobile() {
    let dataarray = this.state.dataarray;
    let isMobilelist = this.state.isMobilelist;
    let isMobileclasslist = this.state.isMobileclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isMobile) {
        manageUser.isMobile = true
        this.state.isMobilelist[i] = true
        this.state.isMobileclasslist[i] = "#bcbcbb"
        this.state.isMobilefieldclasslist[i] = "#e9ecef"

        this.setState({
          dataarray,
          isMobilelist,
          isMobileclasslist
        });
      } else {
        manageUser.isMobile = true
        this.state.isMobilelist[i] = false
        this.state.isMobileclasslist[i] = "#2ab934"
        this.state.isMobilefieldclasslist[i] = "#bce394"
        this.setState({
          dataarray,
          isMobilelist,
          isMobileclasslist
        });
      }

    })
  }
  /**
   * Sets the boolean value for(other than credit-uw users) remove toggle button,disable buttons and background color.
  */
  onAndOffChangeisRemoved() {
    let dataarray = this.state.dataarray;
    let isRemovedlist = this.state.isRemovedlist;
    let isRemovedclasslist = this.state.isRemovedclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isRemoved) {
        this.state.isRemovedyesorno[i] = "NO"
        this.state.isRemovedlist[i] = true
        this.state.isRemovedclasslist[i] = "#bcbcbb"
        this.state.isRemovedfieldclasslist[i] = "#e9ecef"
        this.setState({
          dataarray,
          isRemovedlist,
          isRemovedclasslist
        });
      } else {
        this.state.isRemovedyesorno[i] = "YES"
        this.state.isRemovedlist[i] = false
        this.state.isRemovedclasslist[i] = "#2ab934"
        this.state.isRemovedfieldclasslist[i] = "#bce394"
        this.setState({
          dataarray,
          isRemovedlist,
          isRemovedclasslist
        });
      }


    })
  }

  /**
     * Sets the boolean value for(credit-uw users) remove toggle button,disable buttons and background color.
    */
  onAndOffChangecredituwisRemoved() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isRemovedcredituwlist = this.state.isRemovedcredituwlist;
    let isRemovedcredituwclasslist = this.state.isRemovedcredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isRemoved) {
        this.state.isRemovedyesornocredituw[i] = "NO"
        this.state.isRemovedcredituwlist[i] = true
        this.state.isRemovedcredituwclasslist[i] = "#bcbcbb"
        this.state.isRemovedfieldcredituwclasslist[i] = "#e9ecef"
        this.setState({
          dataarraycredituw,
          isRemovedcredituwlist,
          isRemovedcredituwclasslist
        });
      } else {
        this.state.isRemovedyesornocredituw[i] = "YES"
        this.state.isRemovedcredituwlist[i] = false
        this.state.isRemovedcredituwclasslist[i] = "#2ab934"
        this.state.isRemovedfieldcredituwclasslist[i] = "#bce394"
        this.setState({
          dataarraycredituw,
          isRemovedcredituwlist,
          isRemovedcredituwclasslist
        });
      }

    })
  }
  /**
     * Sets the boolean value for(credit-uw users) active toggle button,disable buttons and background color.
    */
  onAndOffChangecredituwisActive() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isActivecredituwlist = this.state.isActivecredituwlist;
    let isActivecredituwclasslist = this.state.isActivecredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isActive) {
        manageUser.isActive = true
        this.state.isActivecredituwlist[i] = true
        this.state.isActivecredituwclasslist[i] = "#bcbcbb"
        this.state.isActivefieldcredituwclasslist[i] = "#e9ecef"
        this.setState({
          dataarraycredituw,
          isActivecredituwlist,
          isActivecredituwclasslist
        });
      } else {
        manageUser.isActive = true
        this.state.isActivecredituwlist[i] = false
        this.state.isActivecredituwclasslist[i] = "#2ab934"
        this.state.isActivefieldcredituwclasslist[i] = "#bce394"
        this.setState({
          dataarraycredituw,
          isActivecredituwlist,
          isActivecredituwclasslist
        });
      }

    })
  }

  /**
     * Sets the boolean value for(other than credit-uw users) username toggle button,disable buttons and background color.
    */

  onAndOffChangeIsUserName() {
    let dataarray = this.state.dataarray;
    let isUserNamelist = this.state.isUserNamelist;
    let isUserNameclasslist = this.state.isUserNameclasslist;
    this.state.dataarray.map((manageUser, i) => {
      if ("N" == manageUser.isUserName) {
        manageUser.isUserName = true
        this.state.isUserNamelist[i] = true
        this.state.isUserNameclasslist[i] = "#bcbcbb"
        this.state.isUserNamefieldclasslist[i] = "#e9ecef"
        this.setState({
          dataarray,
          isUserNamelist,
          isUserNameclasslist
        });
      } else {
        manageUser.isUserName = true
        this.state.isUserNamelist[i] = false
        this.state.isUserNameclasslist[i] = "#2ab934"
        this.state.isUserNamefieldclasslist[i] = "#bce394"
        this.setState({
          dataarray,
          isUserNamelist,
          isUserNameclasslist
        });
      }

    })
  }
  /**
   * Sets the boolean value for(credit-uw users) designation toggle button,disable buttons and background color.
  */
  onAndOffChangecredituwIsDesignation() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isDesignationcredituwlist = this.state.isDesignationcredituwlist;
    let isDesignationcredituwclasslist = this.state.isDesignationcredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isDesignation) {
        manageUser.isDesignation = true
        this.state.isDesignationcredituwlist[i] = true
        this.state.isDesignationcredituwclasslist[i] = "#bcbcbb"
        this.state.isDesignationfieldcredituwclasslist[i] = "#e9ecef"
        this.setState({
          dataarraycredituw,
          isDesignationcredituwlist,
          isDesignationcredituwclasslist
        });
      } else {
        manageUser.isDesignation = true
        this.state.isDesignationcredituwlist[i] = false
        this.state.isDesignationcredituwclasslist[i] = "#2ab934"
        this.state.isDesignationfieldcredituwclasslist[i] = "#bce394"
        this.setState({
          dataarraycredituw,
          isDesignationcredituwlist,
          isDesignationcredituwclasslist
        });
      }

    })
  }
  /**
     * Sets the boolean value for(credit-uw users) email toggle button,disable buttons and background color.
    */
  onAndOffChangecredituwIsEmail() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isEmailcredituwlist = this.state.isEmailcredituwlist;
    let isEmailcredituwclasslist = this.state.isEmailcredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isEmail) {
        manageUser.isEmail = true
        this.state.isEmailcredituwlist[i] = true
        this.state.isEmailcredituwclasslist[i] = "#bcbcbb"
        this.state.isEmailfieldcredituwclasslist[i] = "#e9ecef"
        this.setState({
          dataarraycredituw,
          isEmailcredituwlist,
          isEmailcredituwclasslist
        });
      } else {
        manageUser.isEmail = true
        this.state.isEmailcredituwlist[i] = false
        this.state.isEmailcredituwclasslist[i] = "#2ab934"
        this.state.isEmailfieldcredituwclasslist[i] = "#bce394"
        this.setState({
          dataarraycredituw,
          isEmailcredituwlist,
          isEmailcredituwclasslist
        });
      }

    })
  }

  /**
     * Sets the boolean value for(credit-uw users) usertype toggle button,disable buttons and background color.
    */
  onAndOffChangecredituwIsUserType() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isUserTypecredituwlist = this.state.isUserTypecredituwlist;
    let isUserTypecredituwclasslist = this.state.isUserTypecredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isUserType) {
        manageUser.isUserType = true
        this.state.isUserTypecredituwlist[i] = true
        this.state.isUserTypecredituwclasslist[i] = "#bcbcbb"
        this.state.isUserTypefieldcredituwclasslist[i] = "#e9ecef"
        this.setState({
          dataarraycredituw,
          isUserTypecredituwlist,
          isUserTypecredituwclasslist
        });
      } else {
        manageUser.isUserType = true
        this.state.isUserTypecredituwlist[i] = false
        this.state.isUserTypecredituwclasslist[i] = "#2ab934"
        this.state.isUserTypefieldcredituwclasslist[i] = "#bce394"
        this.setState({
          dataarraycredituw,
          isUserTypecredituwlist,
          isUserTypecredituwclasslist
        });
      }

    })
  }
  /**
   * Sets the boolean value for(credit-uw users) mobile toggle button,disable buttons and background color.
  */
  onAndOffChangecredituwIsMobile() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isMobilecredituwlist = this.state.isMobilecredituwlist;
    let isMobilecredituwclasslist = this.state.isMobilecredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isMobile) {
        manageUser.isMobile = true
        this.state.isMobilecredituwlist[i] = true
        this.state.isMobilecredituwclasslist[i] = "#bcbcbb"
        this.state.isMobilefieldcredituwclasslist[i] = "#e9ecef"

        this.setState({
          dataarraycredituw,
          isMobilecredituwlist,
          isMobilecredituwclasslist
        });
      } else {
        manageUser.isMobile = true
        this.state.isMobilecredituwlist[i] = false
        this.state.isMobilecredituwclasslist[i] = "#2ab934"
        this.state.isMobilefieldcredituwclasslist[i] = "#bce394"
        this.setState({
          dataarraycredituw,
          isMobilecredituwlist,
          isMobilecredituwclasslist
        });
      }

    })
  }
  /**
     * Sets the boolean value for(credit-uw users) username toggle button,disable buttons and background color.
    */
  onAndOffChangecredituwIsUserName() {
    let dataarraycredituw = this.state.dataarraycredituw;
    let isUserNamecredituwlist = this.state.isUserNamecredituwlist;
    let isUserNamecredituwclasslist = this.state.isUserNamecredituwclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if ("N" == manageUser.isUserName) {
        manageUser.isUserName = true
        this.state.isUserNamecredituwlist[i] = true
        this.state.isUserNamecredituwclasslist[i] = "#bcbcbb"
        this.state.isUserNamefieldcredituwclasslist[i] = "#e9ecef"
        this.setState({
          dataarraycredituw,
          isUserNamecredituwlist,
          isUserNamecredituwclasslist
        });
      } else {
        manageUser.isUserName = true
        this.state.isUserNamecredituwlist[i] = false
        this.state.isUserNamecredituwclasslist[i] = "#2ab934"
        this.state.isUserNamefieldcredituwclasslist[i] = "#bce394"
        this.setState({
          dataarraycredituw,
          isUserNamecredituwlist,
          isUserNamecredituwclasslist
        });
      }

    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) username toggle button,disable buttons and background color
    */
  setOnAndOffChangeisUserName() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((manageUser, i) => {
      if (false == this.state.isUserNamelist[i]) {
        if (true == manageUser.isUserName) {
          manageUser.isUserName = "A"
          this.setState({
            dataarray
          });
        } else {
          manageUser.isUserName = "R"
          this.setState({
            dataarray
          });
        }
      }
      else {
        manageUser.isUserName = "N"
        this.setState({
          dataarray
        });
      }
    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) email toggle button,disable buttons and background color
    */
  setOnAndOffChangeisEmail() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((manageUser, i) => {
      if (false == this.state.isEmaillist[i]) {
        if (true == manageUser.isEmail) {
          manageUser.isEmail = "A"
          this.setState({
            dataarray
          });
        } else {
          manageUser.isEmail = "R"
          this.setState({
            dataarray
          });
        }
      }
      else {
        manageUser.isEmail = "N"
        this.setState({
          dataarray
        });
      }
    })
  }
  /**
     * Sets the boolean value for(other than credit-uw users) usertype toggle button,disable buttons and background color
    */
  setOnAndOffChangeisUserType() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((manageUser, i) => {
      if (false == this.state.isUserTypelist[i]) {
        if (true == manageUser.isUserType) {
          manageUser.isUserType = "A"
          this.setState({
            dataarray
          });
        } else {
          manageUser.isUserType = "R"
          this.setState({
            dataarray
          });
        }
      }
      else {
        manageUser.isUserType = "N"
        this.setState({
          dataarray
        });
      }
    })
  }
  /**
   * Sets the boolean value for(other than credit-uw users) designation toggle button,disable buttons and background color
  */
  setOnAndOffChangeisDesignation() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((manageUser, i) => {
      if (false == this.state.isDesignationlist[i]) {
        if (true == manageUser.isDesignation) {
          manageUser.isDesignation = "A"
          this.setState({
            dataarray
          });
        } else {
          manageUser.isDesignation = "R"
          this.setState({
            dataarray
          });
        }
      }
      else {
        manageUser.isDesignation = "N"
        this.setState({
          dataarray
        });
      }
    })
  }
  /**
   * Sets the boolean value for(other than credit-uw users) mobile toggle button,disable buttons and background color
  */
  setOnAndOffChangeisMobile() {
    let dataarray = this.state.dataarray;
    this.state.dataarray.map((manageUser, i) => {
      if (false == this.state.isMobilelist[i]) {
        if (true == manageUser.isMobile) {
          manageUser.isMobile = "A"
          this.setState({
            dataarray
          });
        } else {
          manageUser.isMobile = "R"
          this.setState({
            dataarray
          });
        }
      }
      else {
        manageUser.isMobile = "N"
        this.setState({
          dataarray
        });
      }
    })
  }
  /**
   * Sets the boolean value for(credit-uw users) username toggle button,disable buttons and background color
  */
  setOnAndOffChangecredituwisUserName() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isUserNamecredituwlist[i]) {
        if (true == manageUser.isUserName) {
          manageUser.isUserName = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isUserName = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isUserName = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  /**
     * Sets the boolean value for(credit-uw users) email toggle button,disable buttons and background color
    */
  setOnAndOffChangecredituwisEmail() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isEmailcredituwlist[i]) {
        if (true == manageUser.isEmail) {
          manageUser.isEmail = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isEmail = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isEmail = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  /**
       * Sets the boolean value for(credit-uw users) usertype toggle button,disable buttons and background color
      */
  setOnAndOffChangecredituwisUserType() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isUserTypecredituwlist[i]) {
        if (true == manageUser.isUserType) {
          manageUser.isUserType = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isUserType = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isUserType = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  /**
     * Sets the boolean value for(credit-uw users) designation toggle button,disable buttons and background color
    */
  setOnAndOffChangecredituwisDesignation() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isDesignationcredituwlist[i]) {
        if (true == manageUser.isDesignation) {
          manageUser.isDesignation = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isDesignation = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isDesignation = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  /**
     * Sets the boolean value for(credit-uw users) mobile toggle button,disable buttons and background color
    */
  setOnAndOffChangecredituwisMobile() {
    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw.map((manageUser, i) => {
      if (false == this.state.isMobilecredituwlist[i]) {
        if (true == manageUser.isMobile) {
          manageUser.isMobile = "A"
          this.setState({
            dataarraycredituw
          });
        } else {
          manageUser.isMobile = "R"
          this.setState({
            dataarraycredituw
          });
        }
      }
      else {
        manageUser.isMobile = "N"
        this.setState({
          dataarraycredituw
        });
      }
    })
  }
  OnAndOffChangecredituwarray(n) {
    let isAssignedclasslist = this.state.isAssignedclasslist;
    let isAssignedfieldclasslist = this.state.isAssignedfieldclasslist;
    let isAssignedlist = this.state.isAssignedlist;
    for (let i = 0; i < n; i++) {
      isAssignedclasslist.push([]);
      isAssignedlist.push([]);
      isAssignedfieldclasslist.push([]);
      this.setState({
        isAssignedclasslist,
        isAssignedfieldclasslist,
        isAssignedlist
      });
    }

  }
  /**
     * Sets the boolean value for(credit-uw users) products assined toggle button,disable buttons and background color
    */
  // setOnAndOffChangeprodassignedforuw() {
  //   let dataarraycredituw = this.state.dataarraycredituw;
  //   this.state.dataarraycredituw.map((manageUser, i) => {
  //     manageUser.prodsAssignedForUWRules.map((prodsAssignedForUWRules, i) => {
  //       if (false == this.state.isAssignedlist[i]) {
  //         if (true == prodsAssignedForUWRules.isAssgined) {
  //           prodsAssignedForUWRules.isAssgined = "A"
  //           this.setState({
  //             dataarraycredituw
  //           });
  //         } else {
  //           prodsAssignedForUWRules.isAssgined = "R"
  //           this.setState({
  //             dataarraycredituw
  //           });
  //         }
  //       }
  //       else {
  //         prodsAssignedForUWRules.isAssgined = "N"
  //         this.setState({
  //           dataarraycredituw
  //         });
  //       }
  //     })
  //   })
  // }



  setOnAndOffChangeprodassignedforuw() {
    // Create a copy of the current data array to avoid direct mutations
    let updatedDataArray = this.state.dataarraycredituw.map(manageUser => {
      // Create a copy of prodsAssignedForUWRules for each manageUser
      const updatedProdsAssignedForUWRules = manageUser.prodsAssignedForUWRules.map((prod, j) => {
        if (this.state.isAssignedlist[j] === false) {
          // Assign "A" or "R" based on the current value of isAssgined
          return {
            ...prod,
            isAssgined: prod.isAssgined ? "A" : "R",
          };
        } else {
          // Set isAssgined to "N"
          return {
            ...prod,
            isAssgined: "N",
          };
        }
      });

      // Return the updated manageUser with modified prodsAssignedForUWRules
      return {
        ...manageUser,
        prodsAssignedForUWRules: updatedProdsAssignedForUWRules,
      };
    });

    // Update the state with the modified data array
    this.setState({ dataarraycredituw: updatedDataArray });
  }




  /**
     * Sets the boolean value for(credit-uw users) products assinged toggle button,disable buttons and background color after submitting data to server
    */
  onSubmitChangeCredituwProdAssigned() {
    let dataarray = this.state.dataarray;
    let dataarraycredituw = this.state.dataarraycredituw;
    let isAssignedlist = this.state.isAssignedlist;
    let isAssignedclasslist = this.state.isAssignedclasslist;
    this.state.dataarraycredituw.map((manageUser, i) => {
      manageUser.prodsAssignedForUWRules.map((prodsAssignedForUWRules, j) => {
        if ("N" == prodsAssignedForUWRules.isAssgined) {
          prodsAssignedForUWRules.isAssigned = true
          this.state.isAssignedlist[i][j] = true
          this.state.isAssignedclasslist[i][j] = "#bcbcbb"
          this.state.isAssignedfieldclasslist[i][j] = "#e9ecef"
          this.setState({
            dataarraycredituw,
          });

        } else if ("A" == prodsAssignedForUWRules.isAssgined) {
          prodsAssignedForUWRules.isAssigned = true
          this.state.isAssignedlist[i][j] = false
          this.state.isAssignedclasslist[i][j] = "#2ab934"
          this.state.isAssignedfieldclasslist[i][j] = "#bce394"
          this.setState({
            dataarraycredituw,
          })
        }
        else {
          prodsAssignedForUWRules.isAssigned = false
          this.state.isAssignedlist[i][j] = false
          this.state.isAssignedclasslist[i][j] = "#6c757d"
          this.state.isAssignedfieldclasslist[i][j] = "#bce394"
          this.setState({
            dataarraycredituw,
          });
        }
      })
    })
  }
  /**
     * Sets the boolean value for(credit-uw users) product assigned toggle button,disable buttons and background color
    */
  onAndOffChangeprodassignedforuw() {
    let dataarray = this.state.dataarray;
    this.state.dataarraycredituw.map((manageUser, i) => {
      manageUser.prodsAssignedForUWRules.map((prodsAssignedForUWRules, j) => {
        if ("N" == prodsAssignedForUWRules.isAssgined) {
          prodsAssignedForUWRules.isAssigned = true
          this.state.isAssignedlist[i][j] = true
          this.state.isAssignedclasslist[i][j] = "#bcbcbb"
          this.state.isAssignedfieldclasslist[i][j] = "#e9ecef"
          this.setState({
            dataarray,
          });

        } else if ("A" == prodsAssignedForUWRules.isAssgined) {
          prodsAssignedForUWRules.isAssigned = true
          this.state.isAssignedlist[i][j] = false
          this.state.isAssignedclasslist[i][j] = "#2ab934"
          this.state.isAssignedfieldclasslist[i][j] = "#bce394"
          this.setState({
            dataarray,
          })
        }
        else {
          prodsAssignedForUWRules.isAssigned = false
          this.state.isAssignedlist[i][j] = false
          this.state.isAssignedclasslist[i][j] = "#6c757d"
          this.state.isAssignedfieldclasslist[i][j] = "#bce394"
          this.setState({
            dataarray,
          });
        }
      })
    })
  }
  /**
     * captures the boolean value for(credit-uw users) product assigned toggle button based on event
    */
  toggleChangeprodassignedforuw(e, i, j) {

    let dataarraycredituw = this.state.dataarraycredituw;
    this.state.dataarraycredituw[i].prodsAssignedForUWRules[j].isAssigned = !this.state.dataarraycredituw[i].prodsAssignedForUWRules[j].isAssigned;
    if (true == this.state.dataarraycredituw[i].prodsAssignedForUWRules[j].isAssigned) {
      this.state.isAssignedclasslist[i][j] = "#2ab934";
    } else {
      this.state.isAssignedclasslist[i][j] = "#6c757d";
    }
    // if ("" == this.state.dataarraycredituw.prodsAssignedForUWRules[i].ac) {
    this.setState({
      dataarraycredituw: dataarraycredituw
    });
  }
  render() {
    if (localStorage.getItem("isLoggedIn") && "CHECKER" == localStorage.getItem("role")) {
      return (

        <div>
          <Header2 />
          <div id="wrapper">
            <Sidebar />
            <div id="maincontent" className="content">
              <section>
                <div className="backloan">
                  <div className="container-fluid adminproduct">
                    <p className="manageheader">MANAGE USER</p>
                    <p className="usertextch">Users other than Credit-UW</p>

                    <div className="row">
                      <div className="col-md-12 reducepaddingfirst">

                        <CheckerManageUserPart1 isRemovedyesorno={this.state.isRemovedyesorno} isActivelist={this.state.isActivelist} isActivefieldclasslist={this.state.isActivefieldclasslist} isRemovedlist={this.state.isRemovedlist} isRemovedfieldclasslist={this.state.isRemovedfieldclasslist} isActiveclasslist={this.state.isActiveclasslist} isRemovedclasslist={this.state.isRemovedclasslist} isUserNamelist={this.state.isUserNamelist} isEmaillist={this.state.isEmaillist} isUserTypelist={this.state.isUserTypelist} isDesignationlist={this.state.isDesignationlist} isMobilelist={this.state.isMobilelist}
                          isUserNameclasslist={this.state.isUserNameclasslist} isEmailclasslist={this.state.isEmailclasslist} isUserTypeclasslist={this.state.isUserTypeclasslist} isDesignationclasslist={this.state.isDesignationclasslist} isMobileclasslist={this.state.isMobileclasslist}
                          isUserNamefieldclasslist={this.state.isUserNamefieldclasslist} isEmailfieldclasslist={this.state.isEmailfieldclasslist} isUserTypefieldclasslist={this.state.isUserTypefieldclasslist} isDesignationfieldclasslist={this.state.isDesignationfieldclasslist} isMobilefieldclasslist={this.state.isMobilefieldclasslist}
                          toggleChangeremoval={(e, i) => { this.toggleChangeremoval(e, i) }} toggleChangeActive={(e, i) => this.toggleChangeActive(e, i)} handleChange={(e) => this.handleChange(e)} toggleChangeIsUserName={(e, i) => this.toggleChangeIsUserName(e, i)} toggleChangeIsDesignation={(e, i) => this.toggleChangeIsDesignation(e, i)} toggleChangeIsUserType={(e, i) => this.toggleChangeIsUserType(e, i)} toggleChangeIsEmail={(e, i) => this.toggleChangeIsEmail(e, i)} toggleChangeIsMobile={(e, i) => this.toggleChangeIsMobile(e, i)} data={this.state.dataarray} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 reducepaddingfirst">
                        <p className="usertextch creditsuw">Credit-UW Users</p>
                        <CheckerManageUserPart2 isRemovedyesornocredituw={this.state.isRemovedyesornocredituw} isActivecredituwlist={this.state.isActivecredituwlist} isActivefieldcredituwclasslist={this.state.isActivefieldcredituwclasslist} isRemovedcredituwlist={this.state.isRemovedcredituwlist} isRemovedfieldcredituwclasslist={this.state.isRemovedfieldcredituwclasslist} isActivecredituwclasslist={this.state.isActivecredituwclasslist} isRemovedcredituwclasslist={this.state.isRemovedcredituwclasslist}
                          isUserNamecredituwlist={this.state.isUserNamecredituwlist} isEmailcredituwlist={this.state.isEmailcredituwlist} isUserTypecredituwlist={this.state.isUserTypecredituwlist} isDesignationcredituwlist={this.state.isDesignationcredituwlist} isMobilecredituwlist={this.state.isMobilecredituwlist}
                          isUserNamecredituwclasslist={this.state.isUserNamecredituwclasslist} isEmailcredituwclasslist={this.state.isEmailcredituwclasslist} isUserTypecredituwclasslist={this.state.isUserTypecredituwclasslist} isDesignationcredituwclasslist={this.state.isDesignationcredituwclasslist} isMobilecredituwclasslist={this.state.isMobilecredituwclasslist}
                          isUserNamefieldcredituwclasslist={this.state.isUserNamefieldcredituwclasslist} isEmailfieldcredituwclasslist={this.state.isEmailfieldcredituwclasslist} isUserTypefieldcredituwclasslist={this.state.isUserTypefieldcredituwclasslist} isDesignationfieldcredituwclasslist={this.state.isDesignationfieldcredituwclasslist} isMobilefieldcredituwclasslist={this.state.isMobilefieldcredituwclasslist}
                          toggleChangecredituwIsUserName={(e, i) => this.toggleChangecredituwIsUserName(e, i)} toggleChangecredituwIsDesignation={(e, i) => this.toggleChangecredituwIsDesignation(e, i)} toggleChangecredituwIsUserType={(e, i) => this.toggleChangecredituwIsUserType(e, i)} toggleChangecredituwIsEmail={(e, i) => this.toggleChangecredituwIsEmail(e, i)} toggleChangecredituwIsMobile={(e, i) => this.toggleChangecredituwIsMobile(e, i)}
                          toggleChangecredituwremoval={(e, i) => { this.toggleChangecredituwremoval(e, i) }} toggleChangecredituwActive={(e, i) => this.toggleChangecredituwActive(e, i)} data={this.state.dataarraycredituw} isAssignedlist={this.state.isAssignedlist} isAssignedclasslist={this.state.isAssignedclasslist} isAssignedfieldclasslist={this.state.isAssignedfieldclasslist} toggleChangeprodassignedforuw={(e, i, j) => { this.toggleChangeprodassignedforuw(e, i, j) }} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                      </div>
                      <div className="col-md-4 formpgl">
                        {this.state.loading ? (
                          <div>
                            <Loader />
                            <div className="formboxchecker">
                              <form method="post" name="" >
                                <div className="form-group ">
                                  <label>Type Your User PIN </label>

                                  <input type="password" className="form-control cardinputfield" name="pin" value={this.state.dataarray.message} onChange={this.handleChange.bind(this)} placeholder="xxxxx " />
                                  <div className="sign">
                                    <input type="submit" className="btn btn-warning cardbutton" style={{ backgroundColor: this.state.buttoncolor }} onClick={this.handleSubmit} value="Submit changes for approval" disabled={isDisabledSubmitButton} />

                                  </div>
                                </div>
                              </form>
                              <ForgotPin />
                            </div>
                          </div>
                        ) : (
                          <div className="formboxchecker">
                            <form method="post" name="" >
                              <div className="form-group ">
                                <label>Type Your User PIN </label>

                                <input type="password" className="form-control cardinputfield" name="pin" value={this.state.dataarray.message} onChange={this.handleChange.bind(this)} placeholder="xxxxx " />
                                <div className="sign">
                                  <input type="submit" className="btn btn-warning cardbutton" style={{ backgroundColor: this.state.buttoncolor }} onClick={this.handleSubmit} value="Submit changes for approval" disabled={isDisabledSubmitButton} />

                                </div>
                              </div>
                            </form>
                            <ForgotPin />
                          </div>
                        )};
                        <div className="errorMsgpin">{this.state.errors.pin}</div>
                        {(() => {
                          switch (this.state.message) {
                            case "SUCCESS": return <p className="submittedpin">Submitted Succesfully</p>
                            default: return <div></div>
                          }
                        })()}
                        {(() => {
                          switch (this.state.message) {
                            case "UNSUCCESS": return <div className="errorMsgpin"> {this.state.errorMsg}</div>;
                            default: return <div></div>
                          }
                        })()}

                        <div className="gridbutton2">
                          <div className="form-group card1">
                            <label className="cardtext">Approved </label><br />
                            <img className="toogle-img"
                              src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle1.png?alt=media&token=a825c329-274e-4a1c-831d-554cd94b2c88"
                              alt="Approved" />
                          </div>
                          <div className="form-group card1">
                            <label className="cardtext">Rejected </label><br />
                            <img className="toogle-img"
                              src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle2.png?alt=media&token=2a0da633-8a16-4e7b-9140-71afbb43e86e"
                              alt="Rejected" />
                          </div>

                          <div className="form-group  card1">
                            <label className="cardtext">No changes requested for approval </label><br />
                            <div className="img-toole">
                              <img className="toogle-img2"
                                src="https://firebasestorage.googleapis.com/v0/b/paylinkwp.appspot.com/o/paylinkwp%2Ftoogle3.png?alt=media&token=0fe3a799-1b79-4d79-87bb-68f4459f2b70"
                                alt="No changes requested for approval" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                      </div>

                    </div>
                    <div className="tabbutton">
                      <input type="submit" className="approval-button" value="Changes requested for approval" disabled />
                      <input type="submit" className=" noteditable-button2" value="Not Editable" disabled />

                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )
    } else {
      return (<p>Please Login AS AdminChecker</p>)
    }

  }
}
let isDisabledSubmitButton = false;
export default CheckerManageUser;
