export default function UssdIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#20336B" />
      <rect x="4" y="2" width="16" height="10" rx="2" fill="white" />
      <path
        d="M10.016 4.44L9.896 5.52L10.988 5.214L11.084 5.952L10.088 6.024L10.742 6.894L10.076 7.248L9.62 6.33L9.218 7.242L8.528 6.894L9.176 6.024L8.186 5.946L8.3 5.214L9.368 5.52L9.248 4.44H10.016ZM14.5575 6.528L14.4195 7.206H15.1755V7.812H14.3055L14.0775 9H13.4355L13.6635 7.812H13.0935L12.8715 9H12.2415L12.4575 7.812H11.7615V7.206H12.5715L12.7095 6.528H11.9715V5.916H12.8175L13.0455 4.722H13.6875L13.4595 5.916H14.0415L14.2695 4.722H14.8995L14.6715 5.916H15.3735V6.528H14.5575ZM13.2075 7.206H13.7835L13.9215 6.528H13.3455L13.2075 7.206Z"
        fill="#4ABCEB"
      />
      <path
        d="M6.088 20.06C5.026 20.06 4.312 19.466 4.312 18.278V15.692H5.2V18.248C5.2 18.974 5.554 19.286 6.088 19.286C6.628 19.286 6.982 18.974 6.982 18.248V15.692H7.864V18.278C7.864 19.466 7.15 20.06 6.088 20.06ZM8.48373 19.214L9.08973 18.626C9.40773 19.076 9.86973 19.328 10.3437 19.328C10.7937 19.328 11.1237 19.13 11.1237 18.764C11.1237 18.368 10.7157 18.314 10.0677 18.17C9.42573 18.02 8.72973 17.816 8.72973 16.964C8.72973 16.142 9.43773 15.626 10.3317 15.626C11.0757 15.626 11.6577 15.956 11.9577 16.388L11.3757 16.958C11.1237 16.604 10.7877 16.364 10.3077 16.364C9.88773 16.364 9.59973 16.568 9.59973 16.88C9.59973 17.216 9.91173 17.282 10.4757 17.408C11.1597 17.558 11.9997 17.732 11.9997 18.674C11.9997 19.544 11.2317 20.066 10.3137 20.066C9.58773 20.066 8.83173 19.736 8.48373 19.214ZM12.4648 19.214L13.0708 18.626C13.3888 19.076 13.8508 19.328 14.3248 19.328C14.7748 19.328 15.1048 19.13 15.1048 18.764C15.1048 18.368 14.6968 18.314 14.0488 18.17C13.4068 18.02 12.7108 17.816 12.7108 16.964C12.7108 16.142 13.4188 15.626 14.3128 15.626C15.0568 15.626 15.6388 15.956 15.9388 16.388L15.3568 16.958C15.1048 16.604 14.7688 16.364 14.2888 16.364C13.8688 16.364 13.5808 16.568 13.5808 16.88C13.5808 17.216 13.8928 17.282 14.4568 17.408C15.1408 17.558 15.9808 17.732 15.9808 18.674C15.9808 19.544 15.2128 20.066 14.2948 20.066C13.5688 20.066 12.8128 19.736 12.4648 19.214ZM16.831 20V15.692H18.313C19.669 15.692 20.497 16.532 20.497 17.846C20.497 19.16 19.669 20 18.313 20H16.831ZM17.695 19.244H18.313C19.147 19.244 19.627 18.692 19.627 17.846C19.627 16.994 19.147 16.448 18.307 16.448H17.695V19.244Z"
        fill="white"
      />
    </svg>
  );
}
