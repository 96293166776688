import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import Card from "../../../assets/images/uday/personal-icons/Home-icons/Business/paymentGateway/montraCard-blue.svg";
import QRScan from "../../../assets/images/uday/personal-icons/Home-icons/Business/section1/qrscan-blue.svg";
import MontraApp from "../../../assets/images/uday/personal-icons/Home-icons/Business/paymentGateway/montraapp-blue.svg";
import USSD from "../../../assets/images/uday/personal-icons/Home-icons/Business/paymentGateway/ussd-blue.svg";
import businessanimg4 from "../../../assets/images/businessimg4.svg";
import pgImage from "../../../assets/images/gateway-hero-2.svg";

const PGProcessContainer = React.memo(({ businessBgColor, businessPG }) => {
  const primaryImage = businessPG ? pgImage : businessanimg4;

  const businessColor = "#20336b";
  const homeBusinessColor = "#FFFFFF";

  const arrowColor =
    businessPG && businessBgColor ? businessColor : homeBusinessColor;

  const steps = [
    {
      title: "Payment Methods Available",
      image: primaryImage,
      className: "direction-img",
    },
    { image: Card, description: "New or Saved Cards" },
    { image: QRScan, description: "QR Scan" },
    { image: MontraApp, description: "Montra App" },
    { image: USSD, description: "USSD" },
  ];

  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor={arrowColor}
        circleBorderColor={arrowColor}
        textColor={arrowColor}
        circelBg={homeBusinessColor}
        // topDescriptionClass="homepersonal-payment-topdescription"
        topDescriptionClass="homebusiness-store-topdescription"
      />
    </div>
  );
});

export default PGProcessContainer;
