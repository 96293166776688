import React, { useEffect, useState } from "react";
import Animation from "../Animation/Main";
import paymentCollectionImage1 from "../../../../assets/images/uday/New-Images/image/supply-chain.svg";
import paymentCollectionImage2 from "../../../../assets/images/uday/supplychain/cap9.svg";
import paymentCollectionImage3 from "../../../../assets/images/uday/supplychain/buy-inventory.webp";
import collectionGif from "../../../../assets/images/uday/gifs/payment.gif";
import "./style.css";

const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 768;
  return (
    <div className="sup-payment-hero collection-hero">
      <div className="row supplychain-row">
        <div className="col">
          <h1>
            Send Invoices to your Distributors{" "}
            <span className="andfont">&</span> Outlets under Direct Coverage
            <span> from your Handheld :</span>
          </h1>

          <div className="capabilities capabipadding">
            <div className="sup-point subparagraph">
              <div className="square-point"></div>
              <div>
                <p>
                  <span>Get paid instantly</span> using buyer’s Montra Account
                  or Linked Bank Accounts on Montra App.
                </p>
              </div>
            </div>
            <div className="sup-point subparagraph">
              <div className="square-point"></div>
              <div>
                <p>
                  <span>Collect & track payments</span> for Credit based
                  Invoices.
                </p>
              </div>
            </div>
            <div className="sup-point subparagraph">
              <div className="square-point"></div>
              <div>
                <p>
                  <span>Access Payment Dashboards</span> to get a full view of
                  all paid invoices & due and overdue payments.
                </p>
              </div>
            </div>
          </div>
          <button>Contact Sales</button>
        </div>
        {!isMobile ? (
          <>
            <div className="col right-payment-col">
              <div className="left-image-payment">
                <div className="left-image-top">
                  <img
                    src={paymentCollectionImage1}
                    alt="paymentCollectionImage1"
                  />
                </div>
                <div className="left-image-bottom">
                  <img
                    src={paymentCollectionImage2}
                    alt="paymentCollectionImage2"
                  />
                </div>
              </div>

              <div className="animation-class">
                <Animation index={1} />
              </div>
              <div className="center-image-payment">
                <div className="center-image-bottom">
                  <img
                    src={paymentCollectionImage3}
                    alt="paymentCollectionImage3"
                    className="paymentCollectionImage3"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="gif-layout supplychain-gif-layout">
            <img
              src={collectionGif}
              alt="business-section gif"
              className="business-section1-gif"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Hero;
