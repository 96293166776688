import React from 'react';
import UserHeader from "./userHeader";
import MakerHeader from "../Maker/Sidebar";
// import ContactHeader from "./IssuerPartner/Contactcenter/ContactHeader";
// import SalesHeader from "./IssuerPartner/Sales/SalesHeader";
// import SettlementHeader from "./IssuerPartner/Settlement/SettlementHeader";
// import Operations from "./IssuerPartner/Operations/OperationHeader";
// import BusinessHeaderIssuer from "./IssuerPartner/Business/BusinessHeader";
// import FinanceHeader from "./IssuerPartner/Finance/FinanceHeader";
// import BusinessHeader from "./DebitProcessor/Business/BusinessHeader";
// import SettlementHeaderdebit from "./DebitProcessor/Settlement/SettlementHeader";

/**
 * Render field to enter otp.
 * <p>Captures the otp enterer during login and sends it to server for verification.</p>
 * @Author : Seema Kumari
 * @version :1.0
 */


class Otp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      isActive: false,
      status: '',
      message: '',
      resendOtpSuccessMessage: ''

    }

  };

  render() {
    return (
      <div>
        <UserHeader />
        {(() => {
          if (localStorage.getItem("role") == "MAKER") {
            return (
              <MakerHeader />
            )
          } else if (localStorage.getItem("role") == "CHECKER") {
            return (
              <MakerHeader />
            )
            // } else if (localStorage.getItem("role") == "SALES" ) {
            //   return (
            //     <SalesHeader />
            //   )
            // } else if (localStorage.getItem("role") == "SETTLEMENT" ) {
            //   return (
            //     <SettlementHeader />
            //   )
            // } else if (localStorage.getItem("role") == "OPERATION" ) {
            //   return (
            //     <Operations />
            //   )
            // } else if (localStorage.getItem("role") == "FINANCE" ) {
            //   return (
            //     <FinanceHeader />
            //   )
            // }
            // else if (localStorage.getItem("role") == "BUSINESS" ) {
            //   return (
            //     <BusinessHeaderIssuer />
            //   )
            // } else if (localStorage.getItem("role") == "BUSINESS" ) {
            //   return (
            //     <BusinessHeader />
            //   )
            // } else if (localStorage.getItem("role") == "SETTLEMENT" ) {
            //   return (
            //     <SettlementHeaderdebit />
            //   )
          }
        })()}
        <div className="partner-height">
          <div className="forgotpinchange">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                  <h5 className="headerpass pinotp">  Successfull ! </h5>
                  <p className="pinchangedsuccess">Your Pin has been changed Successfully !!</p>
                </div>

                <div className="col-md-4">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}




export default Otp;
