import { useState } from "react";
import dts1 from "../../../../assets/images/dts1.svg";
import dts2 from "../../../../assets/images/dts2.svg";
import dtsyoutube from "../../../../assets/images/dtsyoutube.svg";

const Body = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleImageClick = () => {
    setIsVideoPlaying(true);
  };
  return (
    <>
      <div className="dcp-body duoapp-body">
        <div className="row personalb duoapp-body-row">
          <div className="col-md-6 dts-frame duo-app-1">
            <div className="dts-frame-img">
              <img src={dts1} alt="DTS" />
            </div>
            <div className="dts-frame-header">
              <div class="lineparagrapduo">
                <h6>Advantage to Customers</h6>
              </div>
            </div>
            <div className="duo-lists dts-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Easy registration to Activate / Access Credit Card details
                    On white label DUO App of Issuer/ Bank.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Access all details related to Credit Limit, Available
                    balance, Minimum Repayment due amount & date, Payment
                    Status.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    DTS customers can access details of Virtual Credit Card for
                    making Online Payments.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    View up to 90 days Transaction history for spends made on
                    Credit Card.
                  </p>
                </div>
              </div>

              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>Make payments against the due amount instantly.</p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Make request for upgrading to DCP without changing the
                    Credit Card account.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 dts-frame duo-app">
            <div className="dts-frame-img">
              <img src={dts2} alt="Advantage to Issuers" />
            </div>
            <div className="dts-frame-header">
              <div class="lineparagrapduo">
                <h6>Advantage to Issuers</h6>
              </div>
            </div>
            <div className="duo-lists dts-list">
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Superior customer experience & reduced burden on Contact
                    Center with customers having easy & instant access to all
                    the information related to Credit Card.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Ease of collection of re-payments with Duo App sending
                    alerts to customers for minimum repayment amounts every
                    cycle for them to pay easily on Duo App.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Build-up usage with marketing alerts pushed to Duo App
                    targeted to customers based on the categories they actively
                    spend on Credit side.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Availability of Combo Statements for both Debit & Credit
                    accounts in every cycle.
                  </p>
                </div>
              </div>
              <div className="duo-list">
                <div className="duo-list-box"></div>
                <div>
                  <p>
                    Collection & recovery from Delinquent customers with Duo
                    sending rule-based alerts for delinquent customers to
                    pay-up.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row dts-body2 duoapp-body2 duobody-row">
        <div className="col">
          {/* <img src={dtsyoutube} alt="" /> */}
          <div className="dts-content">
            {/* Image */}
            {!isVideoPlaying && (
              <img
                src={dtsyoutube} // Replace with the actual path to your image
                alt="Description of the"
                style={{
                  width: "460px",
                  height: "299px",
                  // position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  cursor: "pointer", // Add this to show the image is clickable
                }}
                onClick={handleImageClick}
              />
            )}

            {/* YouTube Video */}
            {isVideoPlaying && (
              <iframe
                title="YouTube Video"
                width="463px"
                height="299px"
                src="//www.youtube.com/embed/ShGXe-Wo62M?rel=0&autoplay=1&mute=1" // Muted
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                style={{
                  position: "relative",
                  zIndex: 0,
                  borderRadius: "20px",
                }}
              />
            )}
          </div>
        </div>
        <div className="col duo-app-1-1">
          <div className="dts-content">
            <h6>How to use DUO app?</h6>
            <p>
              Know how to download & register on the DUO app introduced by your
              bank and Activate / Access your Virtual Credit Card details, make
              online payments using the virtual credit card, make repayments &
              send your request to upgrade to DUO Card Product (DCP) and much
              more.
            </p>
            <a
              href="https://www.youtube.com/embed/ShGXe-Wo62M?rel=0&autoplay=1&mute=1"
              target="_blank" rel="noreferrer"
            >
              <button>Watch Video</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
