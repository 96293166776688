import React from "react";
import "./style.css";
import heroImg from "../../../../assets/images/duo-imp-hero.svg";

const Hero = () => {
  return (
    <section className="imp-hero hero-row ">
      <div>
        <h1 className="hero-text">
          DUO Credit Card can be simply launched within two months of MOU
          execution
        </h1>
        <button>Contact Sales</button>
      </div>
      <img src={heroImg} alt="hero-pic" className="hero-pic" />
    </section>
  );
};

export default Hero;
