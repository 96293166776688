import React from "react";

function Icon({ path }) {
  return (
    <svg
      width="409"
      height="240"
      viewBox="0 0 409 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={path === 0 ? " " : "yu-path"}
        d="M1 237.937C2.20132 238.769 52.8903 238.284 78.0847 237.937H346.38C355.223 238.11 372.5 233.037 372.5 220.057C372.5 207.58 372.241 80.8451 372.408 17.6272C372.422 12.2075 375.5 1 386.924 1H409"
        stroke="url(#paint0_radial_5164_5434)"
        stroke-width="1.5"
        pathLength={path === 0 ? 0 : "1"}
      />
      <defs>
        <radialGradient
          id="paint0_radial_5164_5434"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(231.593 255.056) rotate(90) scale(254.056 230.593)"
        >
          <stop stop-color="#0CC2FF" />
          <stop offset="1" stop-color="#8CF2B2" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default Icon;

{
  /* <svg
xmlns="http://www.w3.org/2000/svg"
width="453"
height="285"
fill="none"
viewBox="0 0 453 285"
>
<path
  className="yu-path"
  stroke="url(#paint0_radial_3806_11596)"
  strokeWidth="2"
  d="M0 226h22c5.167-.167 15.4-3.8 15-17v-21.5c.5-3 3.8-9 13-9h139.5c3 .167 8.9 2.2 8.5 9V209c-.667 5.667 1.5 17 15.5 17H331"
  pathLength="1"
></path>
<path
  className="yu-path"
  stroke="url(#paint1_radial_3806_11596)"
  strokeWidth="2"
  d="M0 236.5h22c5.167.167 15.4 3.8 15 17V275c.5 3 3.8 9 13 9h139.5c3-.167 8.9-2.2 8.5-9v-21.5c-.667-5.667 1.5-17 15.5-17h107l8-5.5H424c9.333.333 28-2.8 28-18V0"
  pathLength="1"
></path>
<defs>
  <radialGradient
    id="paint0_radial_3806_11596"
    cx="0"
    cy="0"
    r="1"
    gradientTransform="matrix(0 26.25 -167.75 0 167.75 204.75)"
    gradientUnits="userSpaceOnUse"
  >
    <stop stopColor="#0CC2FF"></stop>
    <stop offset="1" stopColor="#8CF2B2"></stop>
  </radialGradient>
  <radialGradient
    id="paint1_radial_3806_11596"
    cx="0"
    cy="0"
    r="1"
    gradientTransform="matrix(0 -142 226 0 226 142)"
    gradientUnits="userSpaceOnUse"
  >
    <stop stopColor="#0CC2FF"></stop>
    <stop offset="1" stopColor="#8CF2B2"></stop>
  </radialGradient>
</defs>
</svg> */
}

//new
// <svg
//   width="454"
//   height="469"
//   viewBox="0 0 454 469"
//   fill="none"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <path
//     d="M453 58.5V390.5C453.167 396.333 449.2 407.9 432 407.5C414.8 407.1 329.167 407.333 288.5 407.5M288.5 407.5L240 422H218.5C209 422 199.5 430.6 199.5 439C199.5 447.4 199.5 454.5 199.5 457C199.5 460.5 194.9 467.9 184.5 467.5C174.1 467.1 57.1667 467.333 0 467.5M288.5 407.5L240 399.5H218.5C212.167 399.667 199.5 397.6 199.5 388C199.5 378.4 199.5 375.833 199.5 374.5C200.5 368.833 198.9 355.1 184.5 355.5C170.1 355.9 55.5 355.667 0 355.5"
//     stroke="url(#paint0_radial_5164_5287)"
//     stroke-width="1.5"
//     className="yu13-path-1"
//     pathLength="1"
//   />
//   <path
//     d="M0 238.511H175.75H351.5C358.167 236.844 371.5 230.411 371.5 218.011C371.5 205.611 371.5 78.511 371.5 16.511C371.5 11.1777 374.5 0.611043 386.5 1.01104C398.5 1.41104 405.833 1.17771 408 1.01104"
//     stroke="#4ABCEB"
//     stroke-width="1.5"
//     className="yu13-path-2"
//     pathLength="1"
//   />
//   <defs>
//     <radialGradient
//       id="paint0_radial_5164_5287"
//       cx="0"
//       cy="0"
//       r="1"
//       gradientUnits="userSpaceOnUse"
//       gradientTransform="translate(230.593 261.056) rotate(90) scale(254.056 230.593)"
//     >
//       <stop stop-color="#0CC2FF" />
//       <stop offset="1" stop-color="#8CF2B2" />
//     </radialGradient>
//   </defs>
// </svg>
// <svg
//   width="455"
//   height="463"
//   viewBox="0 0 455 463"
//   fill="none"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <path
//     className="yu-path"
//     d="M1 237.937C2.20132 238.769 52.8903 238.284 78.0847 237.937H346.38C355.223 238.11 372.5 233.037 372.5 220.057C372.5 207.58 372.241 80.8451 372.408 17.6272C372.422 12.2075 375.5 1 386.924 1H409H454V52.5V384.5C454.167 390.333 450.2 401.9 433 401.5C415.8 401.1 330.167 401.333 289.5 401.5M289.5 401.5L241 416H219.5C210 416 200.5 424.6 200.5 433C200.5 441.4 200.5 448.5 200.5 451C200.5 454.5 195.9 461.9 185.5 461.5C175.1 461.1 58.1667 461.333 1 461.5M289.5 401.5L241 393.5H219.5C213.167 393.667 200.5 391.6 200.5 382C200.5 372.4 200.5 369.833 200.5 368.5C201.5 362.833 199.9 349.1 185.5 349.5C171.1 349.9 56.5 349.667 1 349.5"
//     stroke="url(#paint0_radial_5164_6040)"
//     stroke-width="1.5"
//     pathLength="1"
//   />
//   <defs>
//     <radialGradient
//       id="paint0_radial_5164_6040"
//       cx="0"
//       cy="0"
//       r="1"
//       gradientUnits="userSpaceOnUse"
//       gradientTransform="translate(231.593 255.056) rotate(90) scale(254.056 230.593)"
//     >
//       <stop stop-color="#0CC2FF" />
//       <stop offset="1" stop-color="#8CF2B2" />
//     </radialGradient>
//   </defs>
// </svg>
