import React, { Component } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import Header2 from "./Header2";
import FooterAdmin from "../Footer/FooterAdmin";
import SalProcessorLender from "./SalProcessorLender";
import bsCustomFileInput from "bs-custom-file-input";

import "./SalaryStyle.css";
import CommonHeader from "../header/CommonHeader";
import { api } from "../../utilities/api";

/**
 SalaryDataLender component is render all data in Upload Salary data Lender for processing
 of Slary processor Lender section.
 * <li> User can Click on "Upload Salary data" section and click on "BROWSE" and selects the salary data file.</li>
 * <li> User can download Salary data file. </li>
 * <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>
 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>


 * @Author : Seema Kumari
 * @version :1.0
 */

class SalaryDataLender extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      files: null,
      status: "",
      message: "",
      backerrors: [],
      errorMsg: "",
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
  }

  /**
   * This method is used to fetch the details before the page is loaded by giving hit to the server
   */
  componentDidMount() {
    bsCustomFileInput.init();
  }
  /**
   *
   * @param {Event} e captures the value for submit the form.
   */
  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    this.fileUpload(this.state.files);
  }
  onChange(e) {
    this.setState({ files: e.target.files[0] });
  }

  /**
   *
   * @param {Event} e captures the value for submit the request to server and download the report.
   */
  downloadreport(e) {
    e.preventDefault();
    // const proxyurl = "https://cors-anywhere.herokuapp.com/";
    let url = "api/loanportfolio/downloadbureaureport";
    let params = {
      email: localStorage.getItem("email"),
      filename: "APPS_stacklabs.pdf",
    };

    api
      .get(url, { params: params, responseType: "blob" })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const downloadUrl = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "APPS_stacklabs.pdf";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          alert("Server error. Status: " + response.status);
        }
      })
      .catch((error) => {
        console.error("Download failed:", error);
        console.log("Can’t access " + url + " response. Blocked by browser?");
        alert("Failed to download the file. Check console for details.");
      });
  }
  /**
   *
   * @param {Event} file captures the value for upload the file
   */
  fileUpload(file) {
    let formData = new FormData();
    formData.append("file", file);

    let proxyurl = "https://cors-anywhere.herokuapp.com/";

    let url = "api/salaryprocess/uploadextfile";
    let params = { email: localStorage.getItem("email") };

    api
      .post(url, formData, { params: params })
      .then((response) => {
        if (response.status === 200 && response.data.message === "SUCCESS") {
          this.setState({
            status: response.data.status,
            message: response.data.message,
          });
        } else {
          this.setState({
            backerrors: response.data.errors || [],
            status: response.data.status,
            message: response.data.message,
          });
          const errorMessages = this.state.backerrors
            .map((key) => key.message)
            .join(", ");
          this.setState({ errorMsg: errorMessages });
        }
      })
      .catch((error) => {
        console.log("Can’t access " + url + " response. Blocked by browser?");
        console.error("Error uploading file:", error);
        alert("File upload failed. Please try again.");
      });
  }

  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "SALARY PROCESSOR-EXTERNAL" == localStorage.getItem("role")
    ) {
      return (
        <div>
          <CommonHeader />
          <div id="wrapper">
            <Sidebar />
            <div id="maincontent" className="content">
              <section id="content-wrapper salaryDataLender ">
                <div className="backloan">
                  <div className="container-fluid undercontainer">
                    <div className="row">
                      <div className="col-lg-12">
                        <form
                          className="uploadfile"
                          onSubmit={this.onFormSubmit}
                        >
                          <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="uploadlabel">
                                  Upload the file in correct format
                                </label>
                                <div className="custom-file">
                                  <input
                                    id="inputGroupFile01"
                                    type="file"
                                    className="custom-file-input"
                                    onChange={this.onChange}
                                    accept=".xlsx,.xls"
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="inputGroupFile01"
                                  >
                                    Choose file
                                  </label>
                                </div>
                              </div>
                              {(() => {
                                switch (this.state.message) {
                                  case "SUCCESS":
                                    return (
                                      <p className="filesalarysuccess">
                                        File Uploaded Sucessfully
                                      </p>
                                    );
                                  case "UNSUCCESS":
                                    return (
                                      <div className="errorMsg">
                                        {" "}
                                        <p>{this.state.errorMsg}</p>
                                      </div>
                                    );
                                  default:
                                    return <p></p>;
                                }
                              })()}
                            </div>
                            <div className="col-md-3"></div>
                          </div>

                          <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6 center-block">
                              <Link
                                to=""
                                className="btn btn-primary filebutton"
                                onClick={this.onFormSubmit}
                                type=""
                              >
                                {" "}
                                Upload{" "}
                              </Link>
                            </div>
                            <div className="col-md-3"></div>
                          </div>
                        </form>

                        <br />

                        <div className="table-responsive">
                          <table
                            summary="This table shows how to create responsive tables using Footable's extended functionality"
                            className="table table-bordered table-hover footable"
                          >
                            <caption className="caption-text text">
                              Correct sample format for Excel upload{" "}
                            </caption>
                            <thead>
                              <tr>
                                <th>Name Of Customer</th>
                                <th data-hide="phone,tablet">Customer ID</th>
                                <th data-hide="phone">Phone Number</th>
                                <th>BVN</th>
                                <th data-hide="phone">Account Number</th>
                                <th data-hide="phone">
                                  Credit Card Mirror Account Number
                                </th>
                                <th data-hide="phone">Debit Card PAN 1</th>
                                <th data-hide="phone">Debit Card PAN 2</th>
                                <th data-hide="phone">Debit Card PAN 3</th>
                                <th data-hide="phone">Processor</th>
                                <th data-hide="phone">Card Scheme</th>
                                <th data-hide="phone">Credit Limit</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="tabledata">
                                <td>Argentina</td>
                                <td>
                                  Spanish (official), English, Italian, German,
                                  French
                                </td>
                                <td>41,803,125</td>
                                <td>31.3</td>
                                <td>2,780,387</td>
                                <td>41,803,125</td>
                                <td>31.3</td>
                                <td>2,780,387</td>
                                <td>41,803,125</td>
                                <td>31.3</td>
                                <td>2,780,387</td>
                                <td>2,780,387</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="down-report">
                          <button
                            onClick={this.downloadreport}
                            className="report-down"
                          >
                            Download Reports
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          {/* <FooterAdmin /> */}
        </div>
      );
    } else {
      return <p>Please Login AS SALARY PROCESSOR-EXTERNAL</p>;
    }
  }
}

export default SalaryDataLender;
