import React from "react";

/**
 *   CheckerWrittingPart4 component is render upper part of underwritng rules(Average Salary - Interest Rate Multiplier) of bank for checker section.
 *   <p>Allows checker to approve or reject by binding </p>
 *  @Author : Thrilok Kandra
 *  @version :1.0
 */

class CheckerWrittingPart4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
    };
  }

  render() {
    console.log("props from checkwritting part4", this.props);
    return (
      <div>
        <form method="post" name="userRegistrationForm" className="formroot">
          <div className="unwrite">
            <div className="d-flex flex-row mt-3 ">
              <div className="p-2 headerbuttons">
                <div className="input-group Multiplier2">
                  <p className="text2part">
                    Multiplier <br /> Status
                  </p>
                  <div className="form-group aligncenter inputgraygl">
                    <label type="text" className="form-control formpprofilecuw">
                      {this.props.data[0]?.avgSalIntRateMultStatus}{" "}
                    </label>
                    <label className="switchtoggle">
                      <input
                        type="checkbox"
                        name="avgSalIntRateMultStatus"
                        checked={
                          this.props.underWritingRulesstatus[0]
                            ?.avgSalIntRateMultStatus
                        }
                        onChange={(e) => this.props.toggleChange(e)}
                      />
                      <div className="checkerslider round"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div className="p-2 headertext">
                <p className="textposition">
                  Average Salary - Interest Rate Multiplier
                </p>
                <p className="textlabel">Average Monthly Salary Bands</p>
              </div>
            </div>

            <div className="d-flex flex-row mt-3 padmar">
              <div className="p-2 underwritepart3">
                <div className="form-group">
                  <label className="labelpt-ch2 proch3">Product</label>
                  {this.props.data.map((underWritingRules, i) => (
                    <div key={i}>
                      <label
                        className="form-control loanlabelcheck"
                        name="paydayloan"
                        id="field1"
                        disabled
                      >
                        {underWritingRules.productName}{" "}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    {" "}
                    {"<"}200K{" "}
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isAvgSalIntRateMultLT200000fieldclasslist[
                            i
                          ],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalIntRateMultLT200000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalIntRateMultLT200000}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalIntRateMultLT200000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalIntRateMultLT200000(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalIntRateMultLT200000
                          }
                          disabled={
                            this.props.isAvgSalIntRateMultLT200000list[i]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isAvgSalIntRateMultLT200000classlist[
                                i
                              ],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    200K – 500K
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props
                            .isAvgSalIntRateMultBET200000n500000fieldclasslist[
                            i
                          ],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalIntRateMultBET200000n500000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {
                          underWritingRules.avgSalIntRateMultBET200000n500000
                        }{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isavgSalIntRateMultBET200000n500000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalIntRateMultBET200000n500000(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalIntRateMultBET200000n500000
                          }
                          disabled={
                            this.props.isAvgSalIntRateMultBET200000n500000list[
                              i
                            ]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props
                                .isAvgSalIntRateMultBET200000n500000classlist[
                                i
                              ],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    500K – 1M
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props
                            .isAvgSalIntRateMultBET500001n1000000fieldclasslist[
                            i
                          ],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalIntRateMultBET500001n1000000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalIntRateMultBET500001n1000000}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalIntRateMultBET500001n1000000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalIntRateMultBET500001n1000000(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalIntRateMultBET500001n1000000
                          }
                          disabled={
                            this.props.isAvgSalIntRateMultBET500001n1000000list[
                              i
                            ]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props
                                .isAvgSalIntRateMultBET500001n1000000classlist[
                                i
                              ],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    1M - 2M
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props
                            .isAvgSalIntRateMultBET1000001n2000000fieldclasslist[
                            i
                          ],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalIntRateMultBET1000001n2000000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalIntRateMultBET1000001n2000000}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalIntRateMultBET1000001n2000000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalIntRateMultBET1000001n2000000(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalIntRateMultBET1000001n2000000
                          }
                          disabled={
                            this.props
                              .isAvgSalIntRateMultBET1000001n2000000list[i]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props
                                .isAvgSalIntRateMultBET1000001n2000000classlist[
                                i
                              ],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    {" "}
                    {">"}2M{" "}
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isAvgSalIntRateMultGT2000000fieldclasslist[
                            i
                          ],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalIntRateMultGT2000000sa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalIntRateMultGT2000000}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalIntRateMultGT2000000"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalIntRateMultGT2000000(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalIntRateMultGT2000000
                          }
                          disabled={
                            this.props.isAvgSalIntRateMultGT2000000list[i]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isAvgSalIntRateMultGT2000000classlist[
                                i
                              ],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite3">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    Bank Staff
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isAvgSalIntRateMultBankstafffieldclasslist[
                            i
                          ],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="avgSalIntRateMultBankstaffsa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.avgSalIntRateMultBankstaff}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isAvgSalIntRateMultBankstaff"
                          onChange={(e) => {
                            this.props.toggleChangeIsAvgSalIntRateMultBankstaff(
                              e,
                              i
                            );
                          }}
                          checked={
                            underWritingRules.isAvgSalIntRateMultBankstaff
                          }
                          disabled={
                            this.props.isAvgSalIntRateMultBankstafflist[i]
                          }
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor:
                              this.props.isAvgSalIntRateMultBankstaffclasslist[
                                i
                              ],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CheckerWrittingPart4;
