/* React packages */
import React from "react";
import Sidebar from "../Sidebar";
import CollectionLoan from "./CollectionLoan";
import MakerHeader from "../MakerHeader";
import Tooltip from "@mui/material/Tooltip";
import NumberFormat from "react-number-format";
import ReactTooltip from "react-tooltip";
import "./productmix.css";
import axios from "axios";
import { IconButton } from "@mui/material";
import Info from "@mui/icons-material/Info";

/**
* Renders product-mix  section of bank for maker section.
* <p>Maker is allowed make the changes</p>
* <p>Maker can submit the changes.</p>
<br>
* <h6>Below Html elemensts are created to use for  UI</h6>
* <li> <b> Section :- </b>it is used for represents a standalone section</li>
* <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
* <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
* <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>

<br>
* <h6>Below Bootstrap class is use for ui responsive.</h6>
* <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
* <li> <b>row :-</b> This class to create a structure.</li>
* <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
* <li> <b>d-flex  :- </b> it is use for manage the layout, alignment, and sizing of grid columns, navigation, components, and more with a full suite of responsive flexbox utilities.</li>
* <li> <b> Toggle Button :- </b> user can use toggle button for change the state .</li>

@Author : Seema Kumari
@version :1.0
*/

class ProducrMix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldData: [],
      fields: {},
      fieldsvalid: false,
      productdisable: [],
      isFeeline1list: [],
      isActivelist: [],
      isFeeline1list: [],
      isFeeline1Freqlist: [],
      isFeeline2list: [],
      isFeeline2classlist: [],
      isFeeline2Freqlist: [],
      isInterestRateTypelist: [],
      isLossPoolContrPercentlist: [],
      isPenalChanrgesPercentlist: [],
      isLoanAmortizationTypelist: [],
      isSalriedSegmentlist: [],
      isSelfEmployedsegmentlist: [],
      isLoansToExternCustlist: [],
      isDisbursalInInternAcctlist: [],
      isDisbursalInExternAcctlist: [],
      isCollectionForInternLoanslist: [],
      isBaseInterestRatelist: [],
      isBaseTenorslist: [],
      data: [
        {
          prodName: "",
          code: "",
          activeProds: "",
          feeline1: "",
          feeline1Freq: "",
          feeline2: "",
          feeline2Freq: "",
          interestRateType: "",
          lossPoolContrPercent: "",
          penalChanrgesPercent: "",
          loanAmortizationType: "",
          salriedSegment: "",
          selfEmployedsegment: "",
          loansToExternCust: "",
          disbursalInInternAcct: "",
          disbursalInExternAcct: "",
          collectionForInternLoans: "",
          baseInterestRate: "",
          baseTenors: "",
        },
      ],
      status: "",
      isChecked: true,
      buttoncolor: "",
      backerrors: "",
      message: "",
      editable: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  setStatus = (statusres) => {
    this.setState({ status: statusres });
  };
  /**
   * This method is used to fetch the details before the page is loaded by giving hit to the server
   */
  componentDidMount() {
    const url = `api/product/productmix?email=${localStorage.getItem("email")}`;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        const contents = response.data;
        if (contents.status === 200 && contents.message === "SUCCESS") {
          let a = contents.data.ProductMix;
          let b = JSON.parse(JSON.stringify(a));
          this.setState(
            {
              oldData: b,
              data: a,
              status: contents.status,
              message: contents.message,
              editable: contents.data.editable,
            },
            () => {
              // if (this.state.editable) {
              //   // this.setBorderColor(); // Ensure this method is defined and works correctly
              //   this.setBackgroundColor();
              // } else {
              //   this.setNvalue(); // Ensure this method is defined and works correctly
              // }
              this.onAndOffChange();
              this.setNvalue();
            }
          );
        } else {
          this.setState(
            {
              backerrors: contents.errors || [],
              status: contents.status,
              message: contents.message,
              errorMsg: "", // Initialize errorMsg to an empty string
            },
            () => {
              this.state.backerrors.forEach((error) => {
                this.setState((prevState) => ({
                  errorMsg: `${prevState.errorMsg} ${error.message}`,
                }));
              });
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        alert("Server error");
      });
  }

  setBackgroundColor() {
    // Ensure backGroundColor is initialized as an array
    let dataarray = this.state.data; // Create a copy of dataarray
    let backgroundColor = [...(this.state.backGroundColor || [])]; // Create a copy of backGroundColor or initialize as an empty array
    dataarray.map((productgl, i) => {
      if (!productgl?.isEditable) {
        backgroundColor[i] = "#dadada"; // Set the background color at index i
      }
    });
    // Update state using setState
    this.setState({
      backgroundColor,
      dataarray,
    });
  }
  /**
   *Set border color for already sent request from maker.
   */
  setBorderColor() {
    let isDisbursalInExternAcctlist = this.state.isDisbursalInExternAcctlist;
    let isFeeline1list = this.state.isFeeline1list;
    let isActivelist = this.state.isActivelist;
    let isFeeline1Freqlist = this.state.isFeeline1Freqlist;
    let isFeeline2list = this.state.isFeeline2list;
    let isFeeline2classlist = this.state.isFeeline2classlist;
    let isFeeline2Freqlist = this.state.isFeeline2Freqlist;
    let isInterestRateTypelist = this.state.isInterestRateTypelist;
    let isLossPoolContrPercentlist = this.state.isLossPoolContrPercentlist;
    let isPenalChanrgesPercentlist = this.state.isPenalChanrgesPercentlist;
    let isLoanAmortizationTypelist = this.state.isLoanAmortizationTypelist;
    let isSalriedSegmentlist = this.state.isSalriedSegmentlist;
    let isSelfEmployedsegmentlist = this.state.isSelfEmployedsegmentlist;
    let isLoansToExternCustlist = this.state.isLoansToExternCustlist;
    let isDisbursalInInternAcctlist = this.state.isDisbursalInInternAcctlist;
    let isCollectionForInternLoanslist =
      this.state.isCollectionForInternLoanslist;
    let isBaseInterestRatelist = this.state.isBaseInterestRatelist;
    let isBaseTenorslist = this.state.isBaseTenorslist;
    this.state.data.map((productmix, i) => {
      if ("A" == productmix.isActiveProds) {
        this.state.isActivelist[i] = "#e90505";
      }
      if ("A" == productmix.isBaseInterestRate) {
        this.state.isBaseInterestRatelist[i] = "#e90505";
      }
      if ("A" == productmix.isBaseTenors) {
        this.state.isBaseTenorslist[i] = "#e90505";
      }
      if ("A" == productmix.isCollectionForInternLoans) {
        this.state.isCollectionForInternLoanslist[i] = "#e90505";
      }
      if ("A" == productmix.isDisbursalInExternAcct) {
        this.state.isDisbursalInExternAcctlist[i] = "#e90505";
      }
      if ("A" == productmix.isDisbursalInInternAcct) {
        this.state.isDisbursalInInternAcctlist[i] = "#e90505";
      }
      if ("A" == productmix.isFeeline1) {
        this.state.isFeeline1list[i] = "#e90505";
      }
      if ("A" == productmix.isFeeline1Freq) {
        this.state.isFeeline1Freqlist[i] = "#e90505";
      }
      if ("A" == productmix.isFeeline2) {
        this.state.isFeeline2list[i] = "#e90505";
      }
      if ("A" == productmix.isFeeline2Freq) {
        this.state.isFeeline2Freqlist[i] = "#e90505";
      }
      if ("A" == productmix.isInterestRateType) {
        this.state.isInterestRateTypelist[i] = "#e90505";
      }
      if ("A" == productmix.isLoanAmortizationType) {
        this.state.isLoanAmortizationTypelist[i] = "#e90505";
      }
      if ("A" == productmix.isLoansToExternCust) {
        this.state.isLoansToExternCustlist[i] = "#e90505";
      }
      if ("A" == productmix.isLossPoolContrPercent) {
        this.state.isLossPoolContrPercentlist[i] = "#e90505";
      }
      if ("A" == productmix.isPenalChanrgesPercent) {
        this.state.isPenalChanrgesPercentlist[i] = "#e90505";
      }
      if ("A" == productmix.isSalriedSegment) {
        this.state.isSalriedSegmentlist[i] = "#e90505";
      }
      if ("A" == productmix.isSelfEmployedsegment) {
        this.state.isSelfEmployedsegmentlist[i] = "#e90505";
      }
    });
    this.setState({
      isDisbursalInExternAcctlist,
      isFeeline1list,
      isActivelist,
      isFeeline1list,
      isFeeline1Freqlist,
      isFeeline2list,
      isFeeline2classlist,
      isFeeline2Freqlist,
      isInterestRateTypelist,
      isLossPoolContrPercentlist,
      isPenalChanrgesPercentlist,
      isLoanAmortizationTypelist,
      isSalriedSegmentlist,
      isSelfEmployedsegmentlist,
      isLoansToExternCustlist,
      isDisbursalInInternAcctlist,
      isCollectionForInternLoanslist,
      isBaseInterestRatelist,
      isBaseTenorslist,
    });
  }
  /**
   * For fresh editable request we set all to default values.
   */
  setNvalue() {
    this.state.data.map((productmix, i) => {
      productmix.isActiveProds = "N";
      productmix.isBaseInterestRate = "N";
      productmix.isBaseTenors = "N";
      productmix.isCollectionForInternLoans = "N";
      productmix.isDisbursalInExternAcct = "N";
      productmix.isDisbursalInInternAcct = "N";
      productmix.isFeeline1 = "N";
      productmix.isFeeline1Freq = "N";
      productmix.isFeeline2 = "N";
      productmix.isFeeline2Freq = "N";
      productmix.isInterestRateType = "N";
      productmix.isLoanAmortizationType = "N";
      productmix.isLoansToExternCust = "N";
      productmix.isLossPoolContrPercent = "N";
      productmix.isPenalChanrgesPercent = "N";
      productmix.isSalriedSegment = "N";
      productmix.isSelfEmployedsegment = "N";
    });
  }
  /**
   *captures the pin entred by user.
   * @param {Event} e event which calls this method.
   */
  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }
  /**
   * Set boolean values to active for "ON and OFF" values.
   */
  onAndOffChange() {
    let data = this.state.data;
    this.state.data.map((productmix, i) => {
      if ("ON" == productmix.activeProds || "on" == productmix.activeProds) {
        productmix.activeProds = true;
        this.state.productdisable[i] = false;
      } else {
        productmix.activeProds = false;
        this.state.productdisable[i] = true;
      }
      this.setState({
        data,
      });
    });
  }

  /**
   * @typedef {ProductMixdata} ProductMixdata
   * @property {string} feeline1 indicates feeline1 of loan product type
   * @property {String} feeline1Freq  indicates feeline1Freq of loan product type
   * @property {string} feeline2 indicates feeline2 of loan product type
   * @property {String} feeline2Freq indicates feeline2Freq of loan product type
   * @property {String} interestRateType indicates interestRateType of loan product type
   * @property {String} lossPoolContrPercent indicates lossPoolContrPercent of loan product type
   * @property {String} penalChanrgesPercent indicates penalChanrgesPercent of loan product type
   * @property {String} loanAmortizationType indicates loanAmortizationType of loan product type
   * @property {String} salriedSegment indicates salriedSegment of loan product type
   * @property {String} selfEmployedsegment indicates selfEmployedsegment of loan product type
   * @property {String} loansToExternCust indicates loansToExternCust of loan product type
   * @property {String} disbursalInInternAcct indicates disbursalInInternAcct of loan product type
   * @property {String} disbursalInExternAcct indicates disbursalInExternAcct of loan product type
   * @property {String} collectionForInternLoans indicates collectionForInternLoans of loan product type
   * @property {String} baseInterestRate indicates baseInterestRate of loan product type
   * @property {String} baseTenors indicates baseTenors of loan product type
   */
  /**
   * Captures the changes in data array on event(onChange) for active
   *
   * @param {Event} e Event on which the method is called.
   * @param {ProductMixdata} ProductMixdata data and index of data.
   */
  // toggleChange(e, i) {
  //   let data = this.state.data;
  //   this.state.data[i].activeProds = !this.state.data[i].activeProds;
  //   this.state.data[i].isActiveProds = "A";
  //   if (this.state.data[i].activeProds) {
  //     this.state.productdisable[i] = false;
  //   } else {
  //     this.state.productdisable[i] = true;
  //   }
  //   if ("" == this.state.data[i].activeProds) {
  //     this.setState({
  //       data,
  //     });
  //   } else {
  //     this.setState({
  //       data,
  //     });
  //   }
  // }

  toggleChange(e, i) {
    let data = [...this.state.data];
    let productdisable = [...this.state.productdisable];
    data[i].activeProds = !data[i].activeProds;
    data[i].isActiveProds = "N";
    if (data[i].activeProds) {
      productdisable[i] = false;
    } else {
      productdisable[i] = true;
    }
    if (
      this.state.oldData.length > i &&
      data[i].activeProds === (this.state.oldData[i].activeProds === "OFF")
    ) {
      data[i].isActiveProds = "A";
    }

    console.log("onofData", this.state.oldData[i].activeProds === "OFF");
    console.log("activeprods", data[i].activeProds);
    this.setState({
      data,
      productdisable,
    });
  }

  /**
   * Capture the change made by maker,stores into data object for feeline1.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangefeeline1(e, i) {
    let data = this.state.data;
    this.state.data[i].feeline1 = e.target.value;
    this.state.data[i].isFeeline1 = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].feeline1
    ) {
      data[i].isFeeline1 = "N";
    }
    if ("" == this.state.data[i].feeline1) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for feeline1freq.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangefeeline1Freq(e, i) {
    let data = this.state.data;
    this.state.data[i].feeline1Freq = e.target.value;
    this.state.data[i].isFeeline1Freq = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].feeline1Freq
    ) {
      data[i].isFeeline1Freq = "N";
    }
    if ("" == this.state.data[i].isFeeline1Freq) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for feeline2.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangefeeline2(e, i) {
    let data = this.state.data;
    this.state.data[i].feeline2 = e.target.value;
    this.state.data[i].isFeeline2 = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].feeline2
    ) {
      data[i].isFeeline2 = "N";
    }
    if ("" == this.state.data[i].feeline2) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for feeline2freq.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangefeeline2Freq(e, i) {
    let data = this.state.data;
    this.state.data[i].feeline2Freq = e.target.value;
    this.state.data[i].isFeeline2Freq = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].feeline2Freq
    ) {
      data[i].isFeeline2Freq = "N";
    }
    if ("" == this.state.data[i].feeline2Freq) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for interestratetype.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangeinterestRateType(e, i) {
    let data = this.state.data;
    this.state.data[i].interestRateType = e.target.value;
    this.state.data[i].isInterestRateType = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].interestRateType
    ) {
      data[i].isInterestRateType = "N";
    }
    if ("" == this.state.data[i].interestRateType) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for losspoolpercent.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangelossPoolContrPercent(e, i) {
    let data = this.state.data;
    this.state.data[i].lossPoolContrPercent = e.target.value;
    this.state.data[i].isLossPoolContrPercent = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].lossPoolContrPercent
    ) {
      data[i].isLossPoolContrPercent = "N";
    }
    if ("" == this.state.data[i].lossPoolContrPercent) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for penalchargespercent.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangepenalChanrgesPercent(e, i) {
    let data = this.state.data;
    this.state.data[i].penalChanrgesPercent = e.target.value;
    this.state.data[i].isPenalChanrgesPercent = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].penalChanrgesPercent
    ) {
      data[i].isPenalChanrgesPercent = "N";
    }
    if ("" == this.state.data[i].penalChanrgesPercent) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for loanamortizationtype.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangeloanAmortizationType(e, i) {
    let data = this.state.data;
    this.state.data[i].loanAmortizationType = e.target.value;
    this.state.data[i].isLoanAmortizationType = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].loanAmortizationType
    ) {
      data[i].isLoanAmortizationType = "N";
    }
    if ("" == this.state.data[i].loanAmortizationType) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for salariedsegment.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangesalriedSegment(e, i) {
    let data = this.state.data;
    this.state.data[i].salriedSegment = e.target.value;
    this.state.data[i].isSalriedSegment = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].salriedSegment
    ) {
      data[i].isSalriedSegment = "N";
    }
    if ("" == this.state.data[i].salriedSegment) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for selfemployedsegment.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangeselfEmployedsegment(e, i) {
    let data = this.state.data;
    this.state.data[i].selfEmployedsegment = e.target.value;
    this.state.data[i].isSelfEmployedsegment = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].selfEmployedsegment
    ) {
      data[i].isSelfEmployedsegment = "N";
    }
    if ("" == this.state.data[i].selfEmployedsegment) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for loantoexterncust.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangeloansToExternCust(e, i) {
    let data = this.state.data;
    this.state.data[i].loansToExternCust = e.target.value;
    this.state.data[i].isLoansToExternCust = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].loansToExternCust
    ) {
      data[i].isLoansToExternCust = "N";
    }
    if ("" == this.state.data[i].loansToExternCust) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for disbursalininternacct.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangedisbursalInInternAcct(e, i) {
    let data = this.state.data;
    this.state.data[i].disbursalInInternAcct = e.target.value;
    this.state.data[i].isDisbursalInInternAcct = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].disbursalInInternAcct
    ) {
      data[i].isDisbursalInInternAcct = "N";
    }
    if ("" == this.state.data[i].disbursalInInternAcct) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for disbursalinexternacct.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangedisbursalInExternAcct(e, i) {
    let data = this.state.data;
    this.state.data[i].disbursalInExternAcct = e.target.value;
    this.state.data[i].isDisbursalInExternAcct = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].disbursalInExternAcct
    ) {
      data[i].isDisbursalInExternAcct = "N";
    }
    if ("" == this.state.data[i].disbursalInExternAcct) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for collectionsforinternloans.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangecollectionForInternLoans(e, i) {
    let data = this.state.data;
    this.state.data[i].collectionForInternLoans = e.target.value;
    this.state.data[i].isCollectionForInternLoans = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].collectionForInternLoans
    ) {
      data[i].isCollectionForInternLoans = "N";
    }
    if ("" == this.state.data[i].collectionForInternLoans) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for baseinterestrate.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangebaseInterestRate(e, i) {
    let data = this.state.data;
    this.state.data[i].baseInterestRate = e.target.value;
    this.state.data[i].isBaseInterestRate = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].baseInterestRate
    ) {
      data[i].isBaseInterestRate = "N";
    }
    if ("" == this.state.data[i].baseInterestRate) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  /**
   * Capture the change made by maker,stores into data object for basetenors.
   *
   * @param {Event} e event captures the changed value.
   * @param {productGlData} productGlData  stores it into index productGlData
   */
  handleChangebaseTenors(e, i) {
    let data = this.state.data;
    this.state.data[i].baseTenors = e.target.value;
    this.state.data[i].isBaseTenors = "A";
    if (
      this.state.oldData.length > i &&
      e.target.value === this.state.oldData[i].baseTenors
    ) {
      data[i].isBaseTenors = "N";
    }
    if ("" == this.state.data[i].baseTenors) {
      this.setState({
        data,
        fieldsvalid: true,
      });
    } else {
      this.setState({
        data,
        fieldsvalid: false,
      });
    }
  }
  myFormat(num) {
    return num + "%";
  }
  render() {
    if (
      localStorage.getItem("isLoggedIn") &&
      "MAKER" == localStorage.getItem("role")
    ) {
      if (this.state.data.length > 0) {
        return (
          <div>
            <MakerHeader />
            <div id="wrapper">
              <Sidebar />
              <div id="maincontent" className="content">
                <section id="content-wrapper">
                  <div className="backloan">
                    <div className="container-fluid contenttext">
                      <p className="productmix">PRODUCT-MIX</p>
                      <form
                        method="post"
                        name="userRegistrationForm"
                        className="formroot"
                      >
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-xl-5 reduce">
                            <div className="d-flex flex-row mt-3">
                              <div className="p-2 productform">
                                <div className="form-group labelcolor-productmix">
                                  <label className="productcode">Product</label>
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <label
                                        className="form-control loanlabelmak2"
                                        name="prodName"
                                        id="field1"
                                        disabled
                                      >
                                        {" "}
                                        {productmix.prodName}{" "}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productp1">
                                <div className="form-group labelcolor-productmix">
                                  <label className="productcodemak">
                                    Code{" "}
                                  </label>
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <label
                                        className="form-control margn codadminpro"
                                        name="code"
                                        disabled
                                      >
                                        {" "}
                                        {productmix.code}{" "}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 producttoggle">
                                <div className="toolinfopro">
                                  <p className="productheadermak actducts ttip">
                                    Active Products{" "}
                                  </p>

                                  {/* Tooltip :- on hovering on icon its showing the more information, hear Tooltip icons used for sub header */}

                                  <Tooltip
                                    title="Products activated for Lending"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>

                                <div className="form-group togllemak">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i} className="switchheight ">
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          name="activeProds"
                                          onChange={(e) => {
                                            this.toggleChange(e, i);
                                          }}
                                          disabled={!productmix?.isEditable}
                                          checked={productmix.activeProds}
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput">
                                <div className="centerline">
                                  <div className="toolinfopro">
                                    <p className="productheadermak fee ttip">
                                      Fee – Line 1 %{" "}
                                    </p>

                                    <Tooltip
                                      title="% of Amount Disbursed"
                                      placement="top"
                                    >
                                      <IconButton>
                                        <Info fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="inner-addon right-addon">
                                        <NumberFormat
                                          className="form-control formproduct margn"
                                          name="feeline1"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangefeeline1(e, i);
                                          }}
                                          //  value={Number}
                                          value={productmix.feeline1}
                                          suffix={"%"}
                                          data-tip
                                          data-tip-disable={!this.state.editabl}
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state.isFeeline1list[i],
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput">
                                <div className="toolinfopro">
                                  <p className="productheadermak ttip">
                                    Fee – Line 1 Freq.{" "}
                                  </p>

                                  <Tooltip
                                    title="Origination OR Origination + Anniversary"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>

                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="input-group aligncenter inputblue margn">
                                        <select
                                          className="form-control formselect"
                                          name="interestRateType"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangefeeline1Freq(e, i);
                                          }}
                                          placeholder={productmix.feeline1Freq}
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state.isFeeline1Freqlist[i],
                                          }}
                                        >
                                          <option
                                            value={productmix.feeline1Freq}
                                          >
                                            {productmix.feeline1Freq}
                                          </option>
                                          <option value="Orig.">Orig.</option>;
                                          <option value="Orig+Ann">
                                            Orig+Ann
                                          </option>
                                          ;
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput">
                                <div className="toolinfopro">
                                  <p className="productheadermak fee ttip">
                                    Fee – Line 2 %{" "}
                                  </p>
                                  <Tooltip
                                    title="% of Amount Disbursed"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>

                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="inner-addon right-addon">
                                        <NumberFormat
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state.isFeeline2list[i],
                                          }}
                                          className="form-control formproduct margn"
                                          name="feeline2"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangefeeline2(e, i);
                                          }}
                                          //   value={Number}
                                          value={productmix.feeline2}
                                          suffix={"%"}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput">
                                <div className="toolinfopro">
                                  <p className="productheadermak ttip">
                                    Fee – Line 2 Freq{" "}
                                  </p>

                                  <Tooltip
                                    title="Origination OR Origination + Anniversary"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>

                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="input-group aligncenter inputblue margn">
                                        <select
                                          className="form-control formselect"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state.isFeeline2Freqlist[i],
                                          }}
                                          name="interestRateType"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangefeeline2Freq(e, i);
                                          }}
                                          placeholder={productmix.feeline2Freq}
                                        >
                                          <option
                                            value={productmix.feeline2Freq}
                                          >
                                            {productmix.feeline2Freq}
                                          </option>
                                          <option value="Orig.">Orig.</option>;
                                          <option value="Orig+Ann">
                                            Orig+Ann
                                          </option>
                                          ;
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-12 col-xl-4 reduce">
                            <div className="d-flex flex-row mt-3 ">
                              <div className="p-2 md-trial">
                                <div className="form-group">
                                  <label className="productcodech">
                                    Product{" "}
                                  </label>
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <label
                                        className="form-control loanlabelmak2"
                                        name="paydayloan"
                                        id="field1"
                                        disabled
                                      >
                                        {" "}
                                        {productmix.prodName}{" "}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 md-trial2">
                                <div className="form-group">
                                  <label className="pcode">Code </label>
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <label
                                        type="text"
                                        className="form-control margn codadminpro"
                                        name="code"
                                        disabled
                                      >
                                        {" "}
                                        {productmix.code}{" "}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput2nd">
                                <div className="toolinfopro">
                                  <p className="productheadermak mak3 ttip">
                                    Interest Rate Type{" "}
                                  </p>

                                  <Tooltip
                                    title="Flat OR Reducing"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>

                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="input-group aligncenter inputblue margn">
                                        <select
                                          className="form-control formselect"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state.isInterestRateTypelist[
                                                i
                                              ],
                                          }}
                                          name="interestRateType"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangeinterestRateType(
                                              e,
                                              i
                                            );
                                          }}
                                          placeholder={
                                            productmix.interestRateType
                                          }
                                        >
                                          <option
                                            value={productmix.interestRateType}
                                          >
                                            {productmix.interestRateType}
                                          </option>
                                          <option value="FLAT">FLAT</option>;
                                          <option value="RED.">RED.</option>;
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput2nd">
                                <div className="toolinfopro">
                                  <p className="productheadermak long-text ttip">
                                    % Loss Pool Contribution{" "}
                                  </p>

                                  <Tooltip
                                    title="% of Amount Disbursed"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="inner-addon right-addon">
                                        <NumberFormat
                                          className="form-control formproduct margn"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state
                                                .isLossPoolContrPercentlist[i],
                                          }}
                                          name="lossPoolContrPercent"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangelossPoolContrPercent(
                                              e,
                                              i
                                            );
                                          }}
                                          // value={Number}
                                          value={
                                            productmix.lossPoolContrPercent
                                          }
                                          suffix={"%"}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput2nd">
                                <div className="toolinfopro">
                                  <p className="productheadermak mak2 ttip">
                                    Penal Charges %{" "}
                                  </p>

                                  <Tooltip
                                    title=" % of delayed repayment amount"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>

                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="inner-addon right-addon">
                                        <NumberFormat
                                          className="form-control formproduct margn"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state
                                                .isPenalChanrgesPercentlist[i],
                                          }}
                                          name="penalChanrgesPercent"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangepenalChanrgesPercent(
                                              e,
                                              i
                                            );
                                          }}
                                          // value={Number}
                                          value={
                                            productmix.penalChanrgesPercent
                                          }
                                          suffix={"%"}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput2nd">
                                <div className="toolinfopro">
                                  <p className="productheadermak long-text">
                                    Type of Loan Amortization{" "}
                                  </p>

                                  <Tooltip
                                    title="Amortization methodology"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="input-group aligncenter inputblue margn">
                                        <select
                                          className="form-control formselect"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state
                                                .isLoanAmortizationTypelist[i],
                                          }}
                                          name="loanAmortizationType"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangeloanAmortizationType(
                                              e,
                                              i
                                            );
                                          }}
                                          placeholder={
                                            productmix.loanAmortizationType
                                          }
                                        >
                                          <option
                                            value={
                                              productmix.loanAmortizationType
                                            }
                                          >
                                            {productmix.loanAmortizationType}
                                          </option>
                                          <option value="EMI">EMI</option>
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput2nd">
                                <div className="toolinfopro">
                                  <p className="productheadermak mak ttip">
                                    Salaried Segment
                                  </p>
                                  <Tooltip
                                    title="Segment Covered"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>

                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="input-group aligncenter inputblue margn">
                                        <select
                                          className="form-control formselect"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state.isSalriedSegmentlist[
                                                i
                                              ],
                                          }}
                                          name="salriedSegmentpl"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangesalriedSegment(
                                              e,
                                              i
                                            );
                                          }}
                                          placeholder={
                                            productmix.salriedSegment
                                          }
                                        >
                                          <option
                                            value={productmix.salariedSegment}
                                          >
                                            {productmix.salariedSegment}
                                          </option>
                                          <option value="NO">NO</option>;
                                          <option value="YES">YES</option>;
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-12 col-xl-3 reduce">
                            <div className="d-flex flex-row mt-3 ">
                              <div className="p-2 row-trial1">
                                <div className="form-group">
                                  <label className="productcodechh">
                                    Product{" "}
                                  </label>
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <label
                                        className="form-control loanlabelmak2"
                                        name="paydayloan"
                                        id="field1"
                                        disabled
                                      >
                                        {productmix.prodName}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 row-trial2">
                                <div className="form-group">
                                  <label className="pcode pcode1">Code </label>
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <label
                                        type="text"
                                        className="form-control margn codadminpro"
                                        name="code"
                                        disabled
                                      >
                                        {" "}
                                        {productmix.code}{" "}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput3rd">
                                <div className="toolinfopro">
                                  <p className="productheader ttip">
                                    Self Employed Segment
                                  </p>

                                  <Tooltip
                                    title="Segment Covered"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="input-group aligncenter inputblue margn">
                                        <select
                                          className="form-control formselect"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state
                                                .isSelfEmployedsegmentlist[i],
                                          }}
                                          name="selfEmployedsegmentpl"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangeselfEmployedsegment(
                                              e,
                                              i
                                            );
                                          }}
                                          placeholder={
                                            productmix.selfEmployedsegment
                                          }
                                        >
                                          <option
                                            value={
                                              productmix.selfEmployedsegment
                                            }
                                          >
                                            {productmix.selfEmployedsegment}
                                          </option>
                                          <option value="NO">NO</option>;
                                          <option value="YES">YES</option>;
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="p-2 productinput3rd">
                                <div className="toolinfopro">
                                  <p className="productheader ttip">
                                    Loans to external customers
                                  </p>

                                  <Tooltip
                                    title="Lending to customers of other banks"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="input-group aligncenter inputblue margn">
                                        <select
                                          className="form-control formselect"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state
                                                .isLoansToExternCustlist[i],
                                          }}
                                          name="loansToExternCust"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangeloansToExternCust(
                                              e,
                                              i
                                            );
                                          }}
                                          placeholder={
                                            productmix.selfEmployedsegment
                                          }
                                        >
                                          <option
                                            value={productmix.loansToExternCust}
                                          >
                                            {productmix.loansToExternCust}
                                          </option>
                                          <option value="NO">NO</option>;
                                          <option value="YES">YES</option>;
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput3rd">
                                <div className="toolinfopro">
                                  <p className="productheader ttip">
                                    Disbursal in internal accoun
                                  </p>

                                  <Tooltip
                                    title="Disburse in account within the bank"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>

                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="input-group aligncenter inputblue margn">
                                        <select
                                          className="form-control formselect"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          style={{
                                            borderColor:
                                              this.state
                                                .isDisbursalInInternAcctlist[i],
                                          }}
                                          name="disbursalInInternAcct"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangedisbursalInInternAcct(
                                              e,
                                              i
                                            );
                                          }}
                                          placeholder={
                                            productmix.disbursalInInternAcct
                                          }
                                        >
                                          <option
                                            value={
                                              productmix.disbursalInInternAcct
                                            }
                                          >
                                            {productmix.disbursalInInternAcct}
                                          </option>
                                          <option value="NO">NO</option>;
                                          <option value="YES">YES</option>;
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="p-2 productinput3rd">
                                <div className="toolinfopro">
                                  <p className="productheader ttip">
                                    Disbursal in external account
                                  </p>

                                  <Tooltip
                                    title="Disburse in external bank account of
                                    customer"
                                    placement="top"
                                  >
                                    <IconButton>
                                      <Info fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div className="form-group">
                                  {this.state.data.map((productmix, i) => (
                                    <div key={i}>
                                      <div className="input-group aligncenter inputblue margn">
                                        <select
                                          className="form-control formselect"
                                          data-tip
                                          data-tip-disable={
                                            !this.state.editable
                                          }
                                          disabled={
                                            this.state.productdisable[i] ||
                                            !productmix?.isEditable
                                          }
                                          data-for="noneditable"
                                          name="disbursalInExternAcct"
                                          align="center"
                                          onChange={(e) => {
                                            this.handleChangedisbursalInExternAcct(
                                              e,
                                              i
                                            );
                                          }}
                                          style={{
                                            borderColor:
                                              this.state
                                                .isDisbursalInExternAcctlist[i],
                                          }}
                                          placeholder={
                                            productmix.disbursalInExternAcct
                                          }
                                        >
                                          <option
                                            value={
                                              productmix.disbursalInExternAcct
                                            }
                                          >
                                            {productmix.disbursalInExternAcct}
                                          </option>
                                          <option value="NO">NO</option>;
                                          <option value="YES">YES</option>;
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>

                      {/* user can recognise the fields/columns/rows functionality based on the label information */}

                      <div className="row">
                        <div className="col-md-12">
                          <CollectionLoan
                            productdisable={this.state.productdisable}
                            buttoncolor={this.state.buttoncolor}
                            fieldsvalid={this.state.fieldsvalid}
                            data={this.state.fields}
                            editable={this.state.editable}
                            loaddata={this.state.data}
                            oldData={this.state.oldData}
                            handlestatus={(status) => this.setStatus(status)}
                            handleChangebaseInterestRate={(e, i) =>
                              this.handleChangebaseInterestRate(e, i)
                            }
                            handleChangebaseTenors={(e, i) =>
                              this.handleChangebaseTenors(e, i)
                            }
                            handleChangecollectionForInternLoans={(e, i) =>
                              this.handleChangecollectionForInternLoans(e, i)
                            }
                          />
                          <div className="productbutton">
                            <input
                              type="submit"
                              className=" editable-button"
                              value="Editable"
                              disabled
                            />
                            <input
                              type="submit"
                              className=" noteditable-button"
                              value="Not Editable"
                              disabled
                            />
                            <input
                              type="submit"
                              className=" suggested-button"
                              value="Suggested but Editable"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        );
      } else {
        return <p>Please Wait ....</p>;
      }
    } else {
      return <p>Please Login</p>;
    }
  }
}
export default ProducrMix;
