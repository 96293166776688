import React from "react";

function PG4() {
  return (
    <svg
      width="88"
      height="155"
      viewBox="0 0 88 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 154H27C30.6667 154.167 38 151.7 38 140.5C38 129.3 38 51.5 38 14C38 7.5 44 1 52.5 1C61.3 1 86.5 1 88 1"
        stroke="url(#paint0_radial_4800_5293)"
        stroke-width="2"
        className="yu-path"
        pathLength="1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4800_5293"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(60.8487 207) rotate(90) scale(27.5 78.861)"
        >
          <stop stop-color="#B38DFF" />
          <stop offset="1" stop-color="#FD7FAE" />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default PG4;
