import React from "react";

import { Link } from "react-router-dom";

/**
 * CheckerWrittingPart2 component is render upper part of underwriting rules(Company Category – Interest Rate Multiplier) of bank for checker section.
 * <p>Allows checker to approve or reject by binding </p>
 *  @Author : Thrilok Kandra
 *  @version :1.0
 */

class CheckerWrittingPart2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
    };
  }

  render() {
    console.log("props from checkwritting part2", this.props);
    return (
      <div>
        <form method="post" name="userRegistrationForm" className="formroot">
          <div className="unwrite">
            <div className="d-flex flex-row mt-3 ">
              <div className="p-2 headerbuttons">
                <div className="input-group Multiplier">
                  <p className="text2part">
                    Multiplier <br /> Status
                  </p>
                  <div className="form-group aligncenter inputgraygl">
                    <label type="text" className="form-control formpprofilecuw">
                      {this.props.data[0]?.catMultiStatus}{" "}
                    </label>
                    <label className="switchtoggle">
                      <input
                        type="checkbox"
                        name="catMultiStatus"
                        checked={
                          this.props.underWritingRulesstatus[0]?.catMultiStatus
                        }
                        onChange={(e) => this.props.toggleChange(e)}
                      />
                      <div className="checkerslider round"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div className="p-2 headertextcheck">
                <p className="textposition">
                  Company Category – Interest Rate Multiplier
                </p>
                <p className="textlabel">Employer / Company Category</p>
              </div>
            </div>

            <div className="d-flex flex-row mt-3 mtflex">
              <div className="p-2 underwritepart">
                <div className="form-group">
                  <label className="labelpt-ch">Product</label>
                  {this.props.data.map((underWritingRules, i) => (
                    <div key={i}>
                      <label
                        type="text"
                        className="form-control loanlabelcheck"
                        name="paydayloan"
                        id="field1"
                        disabled
                      >
                        {" "}
                        {underWritingRules.productName}{" "}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    CAT – A
                  </label>
                </div>

                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isCategoryAfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="categoryAsa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.categoryA}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isCategoryA"
                          onChange={(e) => {
                            this.props.toggleChangeIsCategoryA(e, i);
                          }}
                          checked={underWritingRules.isCategoryA}
                          disabled={this.props.isCategoryAlist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor: this.props.isCategoryAclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    CAT – B
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isCategoryBfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="categoryBsa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.categoryB}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isCategoryB"
                          onChange={(e) => {
                            this.props.toggleChangeIsCategoryB(e, i);
                          }}
                          checked={underWritingRules.isCategoryB}
                          disabled={this.props.isCategoryBlist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor: this.props.isCategoryBclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    CAT – C
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isCategoryCfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="categoryCsa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {underWritingRules.categoryC}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isCategoryC"
                          onChange={(e) => {
                            this.props.toggleChangeIsCategoryC(e, i);
                          }}
                          checked={underWritingRules.isCategoryC}
                          disabled={this.props.isCategoryClist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor: this.props.isCategoryCclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    CAT – D
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isCategoryDfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="categoryDsa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.categoryD}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isCategoryD"
                          onChange={(e) => {
                            this.props.toggleChangeIsCategoryD(e, i);
                          }}
                          checked={underWritingRules.isCategoryD}
                          disabled={this.props.isCategoryDlist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor: this.props.isCategoryDclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="p-2 underwrite2">
                <div className="form-group">
                  <label className="labelpt-9"></label>
                  <label
                    className="form-control formprulesch loanlabelmak7"
                    name=""
                    id="field1"
                    disabled
                  >
                    CAT – E
                  </label>
                </div>
                <div className="form-group">
                  {this.props.data.map((underWritingRules, i) => (
                    <div
                      key={i}
                      className="form-group aligncenter inputgrayfield"
                      style={{
                        backgroundColor:
                          this.props.isCategoryEfieldclasslist[i],
                      }}
                    >
                      <label
                        type="text"
                        className="form-control formpprofilefield"
                        name="categoryEsa"
                        onChange={this.props.handleChange.bind(this)}
                        disabled
                      >
                        {" "}
                        {underWritingRules.categoryE}{" "}
                      </label>
                      <label className="switchtoggle">
                        <input
                          type="checkbox"
                          name="isCategoryE"
                          onChange={(e) => {
                            this.props.toggleChangeIsCategoryE(e, i);
                          }}
                          checked={underWritingRules.isCategoryE}
                          disabled={this.props.isCategoryElist[i]}
                        />
                        <div
                          className="checkerslider round"
                          style={{
                            backgroundColor: this.props.isCategoryEclasslist[i],
                          }}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CheckerWrittingPart2;
