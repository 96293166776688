import React from 'react';


const PrivacyPolicy = () => {

    return (
        <div className="container-fluid ">
            <div className="privacypl">
                <h5 className="header-tag">PRIVACY POLICY</h5>
                <p className="paragraph">Montra Technology Solutions Limited is a private limited liability company (“Montra”,
                    “Company”, “we”, “us” or “our”) licensed by the Central Bank of Nigeria to provide electronic payment systems
                    services and registered office at 20 Layi Yusuf Crescent, Lekki Phase 1, Lagos State. Montra offers a SoftToken
                    Mobile Application for Consumers. The Montra Privacy Policy (“Privacy Policy”) describes how we treat your
                    personal information as private and confidential, how we collect, use, store, share, and protect personal data
                    from our website, merchants, customers and vendors who engage with our services. It also applies to our website,
                    mobile applications and payment platforms ("Services") provided by Montra. </p>

                <p className="paragraph">Our Services are intended for and provided to Consumers. This Privacy Policy does not apply to
                    services that are
                    not owned or controlled by Montra, including third-party websites and the services of Montra Merchants. We
                    process personal data at the direction of and on behalf of Merchants. We do not control these third-party
                    websites and are not responsible for their privacy statements. Please consult such third parties’ privacy
                    statements.</p>

                <p className="paragraph">We are committed to protecting your personal in accordance with this Privacy Policy and in
                    compliance with the Nigeria Data Protection Regulation (NDPR) 2019. By using or accessing our Services, you
                    agree to the collection, use, and disclosure of your personal data as described in this Privacy Policy.
                </p>

                <p className="paragraph">We may need to update, modify or amend our Privacy Policy as our technology evolves and as
                    required by law. We reserve the right to post any revisions we make to our Privacy Policy on this page and such
                    revised policy becomes effective as at the time it is posted. We will notify you when any changes to our privacy
                    policy have been made. We also encourage you to check this page from time to time for updates to this policy.
                </p>
                <p className="paragraph">"Personal Data" means data relating to you that is directly or indirectly identifiable, having
                    regard to any characteristic, trait, attribute or any other feature of your identity or any combination of such
                    features with any other information. Personal Data does not mean information that is freely available or
                    accessible in public domain or any other law for the time being in force. Personal Data includes Sensitive
                    Personal Data, which relates to password, financial information such as bank account or credit card or debit
                    card or other payment instrument details, physical, physiological and mental health condition, sexual
                    orientation, medical records and history, biometrics or any other data categorised as sensitive under applicable
                    law.</p>

                <h5 className="header-tag">DATA/INFORMATION WE MAY COLLECT FROM YOU</h5>
                <p className="paragraph">The lawful basis of processing your Personal Information under the Nigeria Data Protection
                    Regulation (NDPR) 2019 are:</p>

                <ul className="paragraph">
                    <li className="list-text">Your Consent: Where you have agreed for us to process your personal data/information by
                        using our Services.</li>
                    <li className="list-text">Contractual Obligation: Our services are only provided to you when there is a contractual
                        obligation. Without your personal information we cannot provide the service to you.</li>
                    <li className="list-text">Legal Obligation: We are required to collect and store your personal information to
                        prevent fraud and money laundering as well as to ensure that we are fully compliant with all applicable
                        financial legislations. By Law, we are required to retain certain
                        information of an account opened with us and the data of customers beyond the date
                        when they cease to be our customers</li>
                </ul>

                <p className="paragraph">We receive and store any information you enter on our website, app or you give us in any
                    other way. When you create an account with us or log into an existing account; provide
                    information in your account; visit our website or download our app; order products/content
                    from us; use our payment services, access our platform or use our services; contact us for
                    customer care.</p>

                <p className="paragraph">We may need to collect , use, process, store, or transfer information about you and your
                    computer to allow you to use some of our services. The personal information may include but
                    not limited to:</p>
                <ul className="paragraph">
                    <li className="list-text">Identity data: Information such as: First name, middle name, last name, email, marital
                        status, title, date of birth and gender.</li>
                    <li className="list-text">Identification documents: Y our government-issued identity number, passport
                        photograph and other registration information.</li>
                    <li className="list-text">Contact data: contact and billing address email, telephone numbers and details of the
                        device you use.</li>
                    <li className="list-text">Financial and Payment data: bank account, Bank Verification Number (BVN),
                        Amount, financial account information, and other payment details.</li>
                    <li className="list-text">Transaction data: Payment information other details of products and Services you
                        have subscribed to.</li>
                    <li className="list-text">Technical data: your geo-location, internet protocol (IP) address, your login data,
                        browser type and version, length of visit on certain pages, device identifier, log-in
                        information , time zone setting and location, browser plug-in types and versions,
                        operating system and platform and other technology on the devices you use to access
                        this website.</li>
                    <li className="list-text">Profile data: Information placed on your personal profile (e.g., personal description or
                        passport photograph). Your username and password, your interests, preferences,
                        feedback and survey responses.</li>
                    <li className="list-text">Usage data: information about how you use our website, products and services.</li>
                    <li className="list-text">Marketing and communications data: records of your preferences in receiving
                        marketing materials from us and our third parties. Communication details provided
                        when you contact us.</li>
                </ul>
                <p className="paragraph">When you are asked to provide personal data, you may decline. And you may use web
                    browser or operating system controls to prevent certain types of automatic data collection.
                    But if you choose not to provide or allow information that is necessary for certain services or
                    features, those services or features may not be available or fully functional.</p>

                <h5 className="header-tag">HOW WE COLLECT YOUR PERSONAL INFORMATION</h5>
                <p className="paragraph">The Personal Information we have about you is directly made available to us when you:</p>
                <ul className="paragraph">
                    <li className="list-text">Direct Engagement: When you choose to use any of our services through our website,
                        mobile app, sign-up for an account, upload any documentation, send any
                        communication by email or phone call, fill-out any form, make an enquiry, or any
                        other communication received directly or indirectly from you.</li>
                    <li className="list-text">Automatically: As you browse our websites certain information relating to your
                        browsing patterns and technical data about the equipment you are using to access the
                        website is automatically collected using cookies, server logs and other similar
                        technologies. Please see our Cookie preference for further information.</li>
                    <li className="list-text">From third parties/public sources: We utilise third-party service providers to secure
                        information related to financial crime, fraud, sanctions and Politically Exposed
                        Persons. Also technical data may be obtained from analytics providers, advertising
                        networks, search information providers etc.</li>

                </ul>

                <h5 className="header-tag">HOW WE USE YOUR PERSONAL INFORMATION</h5>
                <p className="paragraph">We use the personal information we collect for the following purposes:</p>
                <ul className="paragraph">
                    <li className="list-text">Create and manage accounts you have with us.</li>
                    <li className="list-text">Verify your identity and administering our products and services.</li>
                    <li className="list-text">Managing our relationship with you.</li>
                    <li className="list-text">For Pricing, designing and reviewing our products and services</li>
                    <li className="list-text">Process your payment transactions including authorization, clearing, chargebacks etc.</li>
                    <li className="list-text">Identify, detect, prevent and manage risk against fraud and illegal activities using
                        various screening tools.</li>
                    <li className="list-text">Prevention of money laundering or terrorism financing activities.</li>
                    <li className="list-text">As required for compliance, regulatory and legal purposes such as Know Your
                        Customers and Enhanced Due Diligence, judicial process, law enforcement or as
                        required governmental agency.</li>
                    <li className="list-text">Resolve disputes that may arise, including investigations by law enforcement or
                        regulatory bodies.</li>
                    <li className="list-text">For improvement of our website, products or services, and other user experiences.</li>
                    <li className="list-text">For marketing content, newsletters and service updates with your consent.</li>
                    <li className="list-text">For updating, enhancing Maintain up-to-date records.</li>
                    <li className="list-text">For any other purpose that we disclose to you in the course of providing services.</li>
                </ul>
                <h5 className="header-tag">HOW WE SHARE YOUR PERSONAL INFORMATION</h5>
                <p className="paragraph">We may need to provide your information to third party service providers that maintain, administer or develop our website or mobile application. However, we do not sell, trade or rent personal data to anyone. In addition, we will not share or disclose your personal data with a third party without your consent except as necessary to provide the Services or as described in this Privacy Policy.</p>
                <p className="paragraph">We may share the information about you and your dealings with us, to the extent permitted by law, with the following:</p>
                <ul className="paragraph">
                    <li className="list-text">	Affiliated Businesses. </li>
                    <li className="list-text">	Strategic partners / Third-Party Service Providers.</li>
                    <li className="list-text">	Financial institutions, Financial Services and Payment Processing.</li>
                    <li className="list-text">	Agents, suppliers, subcontractors.</li>
                    <li className="list-text">	Companies providing analytics services.</li>
                    <li className="list-text">	External Auditors.</li>
                    <li className="list-text">	Employees of Montra;</li>
                    <li className="list-text"> Regulatory/Law enforcement bodies/Government Agencies or Court.</li>
                </ul>

                <p className="paragraph">We may provide aggregate statistics about our customers, sales, traffic patterns and related website information to reputable third-parties, but these statistics will include no personally identifiable information. We may also disclose information about you if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or users. </p>
                <p className="paragraph">Additionally, in the event of a reorganization, merger, or sale we may transfer any and all personal information we collect to the relevant third party. We will provide notice before personal information is transferred and becomes subject to a different privacy policy.</p>
                <h5 className="header-tag">GROUNDS OF PROCESSING PEROSNAL DATA</h5>

                <p className="paragraph">We will only process your data to third parties if one or more of the following applies:</p>
                <ul className="paragraph">
                    <li className="list-text">	we have Your Consent: In case you have expressly consented to your data being shared with specific third parties. The use of your Personal Information by an authorized third party is subject to the third party's privacy policy and we shall bear no liability for any breach which may arise from such authorization by you.</li>
                    <li className="list-text"> the processing is necessary for the performance of a contract to which you are a party or in order to take steps at your request prior to entering into a contract.</li>
                    <li className="list-text">	processing is necessary for compliance with a legal obligation to which Montra is subject.</li>
                    <li className="list-text">	processing is necessary in order to protect your vital information or of another natural person and</li>
                    <li className="list-text">	processing is necessary for the performance of a task carried out in the public interest or in exercise of official public mandate vested in Montra.</li>
                </ul>
                <h5 className="header-tag">HOW WE PROTECT YOUR INFORMATION</h5>

                <p className="paragraph">In line with International best practices, we are committed to managing and protecting your personal data using physical, technical, and administrative security measures to prevent the risks of loss, misuse, unauthorised access, modification, alteration or disclosure of information under our control.  </p>
                <p className="paragraph">Your personal information retained with us are secured because: </p>
                <ul className="paragraph">
                    <li className="list-text">	We use industry recommended security protocols to safeguard your personal data and we implement access control measures, security protocols and standards which include but are not limited to data encryption, firewalls technologies, and physical access controls technologies to ensure your card information is safe and secure in our servers. Our Security controls and processes are regularly updated to meet industry standards.</li>
                    <li className="list-text">	We train our employees to respect the confidentiality of any personal information retain by us and only grant access to personal data to employees who require it to fulfil their job responsibilities. We also use Role-based access control (RBAC) as an internal security measure, permission to access customers’ data is given to certain roles within the organization.</li>
                   
                </ul>
                <p className="paragraph">Where we have provided you (or where you have chosen) a password or security pin that grants you access to specific areas on our site, you are responsible for keeping this password confidential. We request that you do not share your password or security pin or other authentication details with anyone and also sure you sign off when using a shared computer. You may contact our Data Protection Officer (DPO) upon becoming aware of any breach of personal data or if your access credentials have been compromised, to enable us to take the necessary steps towards ensuring the security of your personal data or account.</p>
               
                <h5 className="header-tag">DATA RETENTION</h5>
                <p className="paragraph">  In line with regulatory compliance, we will only retain personal information for the following periods on our servers: 
                </p>
                <ul className="paragraph">
                    <li className="list-text">	For as long as is reasonably necessary in providing Services to you. </li>
                    <li className="list-text">	For the duration your account is active and we have your consent</li>
                    <li className="list-text">For the period needed to comply with our legal and statutory obligations</li>
                        </ul>
                        <p className="paragraph">Where you close your Account, your information is stored on our servers to the extent necessary to comply with regulatory obligations and for the purpose of fraud monitoring, detection and prevention. Where we retain your Personal Data, we do so in compliance with limitation periods under the applicable law. Storage of your data is also determined by legal, regulatory, administrative or operational requirements. </p>
                        <p className="paragraph">When we determine that personal data can no longer be retained (or where you request that we delete your data in accordance with your right to do so as contained in the Nigeria Data Protection Regulation (NDPR) 2019, we ensure that this data is securely deleted or destroyed.</p>

                        <h5 className="header-tag">RIGHTS</h5>
                <p className="paragraph">Individuals who have Personal Information retain by Us are entitled to reach out to exercise the following rights:</p>
                <ul className="paragraph">
                    <li className="list-text">Right to access your personal information retain by us. </li>
                    <li className="list-text">Right to correct or rectify any Personal Information. That is, the right to change inaccurate or incomplete information. </li>
                    <li className="list-text"> Right to remove yourself from our mailing list.</li>
                    <li className="list-text">Right to object to direct marketing. </li>
                    <li className="list-text">Restrict or object to processing of your personal data. </li>
                    <li className="list-text">Request that your personal data be made available to you in a common electronic format and/or request that such data be sent to a third party. </li>
                    <li className="list-text">Request that your information be erased. We might continue to retain such data if there are valid legal, regulatory or operational reasons. </li>
                    <li className="list-text">Right to revoke consent. </li>
                    <li className="list-text">Right to objection or request for restriction. </li>
                    <li className="list-text">Right to object to automated decision making and processing. </li>
                    <li className="list-text">Right to be informed about appropriate safeguards in place where data is transferred abroad. </li>
                    <li className="list-text">Right to request rectification and modification of Personal Information retained by us and  </li>
                    <li className="list-text">Right to request the movement of data to a third party; this is the right to the portability of data. </li>
                   </ul>
                   <p className="paragraph">If you would like to exercise any of the above rights, please:</p> 
                   <ul className="paragraph">
                    <li className="list-text">	put your request in writing and send it to us through your usual registered channel (e.g. registered e-mail).</li> 
                    <li className="list-text">specify the right you wish to exercise.</li>    
</ul>
<p className="paragraph">Your request will be reviewed and answered by our Data Protection Officer within a 30-day period. and if any further extension is required, we will communicate same through existing consented channels. However, please note that you may continue to receive existing communications for a transitional period whilst we update your preferences. </p>
<h5 className="header-tag">ACCURACY OF INFORMATION</h5>
                <p className="paragraph">Please keep Us informed if your personal data changes during your relationship with us. Keeping your account information accurate and up to date is very important. If you discover any inaccuracies in your personal information, please promptly update or notify us where applicable. Where necessary you may be required to provide documentary evidence. </p>

                <h5 className="header-tag">COOKIES</h5>
                <p className="paragraph">Cookies have many uses, such as identifying you as a user and memorizing your preferences to tailor your experiences while on our site– or to help us analyze our website traffic. Cookies are small text files stored on your computer or mobile devices whenever you visit a website.  </p>
                <p className="paragraph">Some browsers may automatically accept cookies while some can be modified to decline cookies or alert you when a website wants to place a cookie on your computer., If you prefer, you can go to your browser settings to learn how to delete or reject cookies. If you choose to delete or reject or disable cookies, this may impact your experience using our website and it may limit your ability to use our website. </p>
                <p className="paragraph">We use cookies and similar technologies on our website to help collect information and operate the site. We use cookies to remember Users and make your user experience easier; customise our services, content and advertising; help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and to promote trust and safety on our website.  </p>

                <h5 className="header-tag">MINOR</h5>
                <p className="paragraph">We do not sell products for purchase by children. Montra websites and applications are not directed at persons under the age of eighteen (18) and we do not collect any Personal Information knowingly or directly from minors who fall within this category. </p>

                <h5 className="header-tag">TRANSFER OF PERSONAL INFORMATION TO FOREIGN COUNTRY</h5>
                <p className="paragraph">We operate a global business. Our affiliates and service providers are located all around the world. We will ensure adequate measures are in place for security of your personal information when your information is transferred to any other countries. These countries may have data protection rules that are different from your country. When transferring data across borders, we take measures to comply with applicable data protection laws related to such transfer. We will also confirm whether the country is on the National Information Technology Development Agency (“NITDA”) White List of Countries with adequate data protection laws and ensure it is in accordance with the provisions of the Nigeria Data Protection Regulation. </p>
                
                <h5 className="header-tag">CHANGES TO THIS PRIVACY POLICY</h5>
                <p className="paragraph">Changes in this privacy policy might occur periodically. We reserve the right to update, amend or modify this Privacy Policy at any time as our technology evolves and as required by law. Please review this Privacy Policy periodically, and especially before you provide any information. We will provide notice or obtain consent online or via email regarding material changes to the way we use or share your personal information as may be required by law. This Privacy Policy was made effective on the date indicated.  </p>
                
                <h5 className="header-tag">POLICY VIOLATION</h5>
                <p className="paragraph">Any violation of this Privacy Policy should be brought to the attention of the Data Protection Officer for appropriate sanctioning and treatment. </p>

                <h5 className="header-tag">CONTACT DATA PROTECTION OFFICER (DPO)</h5>
                <p className="paragraph">If you have any questions relating to this Privacy Policy or would like to find out more about exercising your data protection rights, please reach out to our DPO via email at dpo@montra.com.</p>
                <p className="paragraph">For any further queries, our Data Protection Officer, may be reached at the following address:</p>
                <p className="paragraph">20 Layi Yusuf Crescent,</p>
                <p className="paragraph">Off Admiralty Way, </p>
                <p className="paragraph">Lekki Phase 1, </p>
                <p className="paragraph">Lagos, Nigeria.</p>
                <p className="paragraph">Last Updated Date: October, 2022. </p>

            </div>
        </div>
    )

}

export default PrivacyPolicy;