// import { createStore, applyMiddleware } from 'redux';
// import { thunkMiddleware } from 'redux-thunk';
// import { createLogger } from 'redux-logger';
// import rootReducer from '../_reducers';

// const loggerMiddleware = createLogger();

// export const store = createStore(
//     rootReducer,
//     applyMiddleware(
//         thunkMiddleware,
//         loggerMiddleware
//     )
// );

import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'; // Correct import for thunk
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunk, // Use thunk here
        loggerMiddleware
    )
);