import React from 'react';
import Home from '../components/Home/Main';

const HomeRoute = () => {
  return (
      <Home />
  );
};

export default HomeRoute;
