import React from "react";
import YU1 from "../../../assets/svg/yu1.jsx";
import YU2 from "../../../assets/svg/yu2.jsx";
import YU3 from "../../../assets/svg/yu3.jsx";
import YU4 from "../../../assets/svg/yu4.jsx";
import YU5 from "../../../assets/svg/yu5.jsx";
import YU6 from "../../../assets/svg/yu6.jsx";
import "./style.css";
import MontraCustomers from "../../../assets/svg/montra-customers.jsx";
import ScanAndPay from "../../../assets/svg/scan-and-pay.jsx";
import MontraAccount from "../../../assets/svg/montra-account.jsx";
import Installments from "../../../assets/svg/installments.jsx";
import LinkedCards from "../../../assets/svg/linked-cards.jsx";
import LinkedBanks from "../../../assets/svg/linked-banks.jsx";
import PaymentAuthorization from "../../../assets/svg/payment-authorization.jsx";
import MontraMerchants from "../../../assets/svg/montra-merchants.jsx";
import OtherCustomers from "../../../assets/svg/other-customers.jsx";
import BankTransfer from "../../../assets/svg/bank-transfer.jsx";
import MobileOrContact from "../../../assets/svg/mobile-or-contact.jsx";
import LendingPartners from "../../../assets/svg/lending-partners.jsx";
import LoanEligibilityComparison from "../../../assets/svg/loan-eligibility-comparison.jsx";
import LoanOfferAcceptance from "../../../assets/svg/loan-offer-acceptance.jsx";
import LoanAuthorization from "../../../assets/svg/loan-authorization.jsx";
import InstantLoanDisbursement from "../../../assets/svg/instant-loan-disbursement.jsx";
import LoanManagementSystem from "../../../assets/svg/loan-management-system.jsx";
import LoanStatement from "../../../assets/svg/loan-statement.jsx";
import LoanMgtService from "../../../assets/svg/loan-mgt-service.jsx";
import LoanRepayment from "../../../assets/svg/loan-repayment.jsx";
import YU4a from "../../../assets/svg/yu4a.jsx";

export const Animation1 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-g-1 yu-flex yu-absolute ${className}`}>
      <div
        className="yu-f-1 ske-b"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <MontraCustomers />
        <p className="anim-p">Montra Customers</p>
      </div>
      <div
        className="yu-f-2 ske-b"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <MobileOrContact />
        <p className="anim-p">Mobile or Contact</p>
      </div>
      <div className="yu-f-3 ske-b ml5">
        <MontraAccount />
        <p className="anim-p">Montra Account</p>
      </div>
      <div
        className="yu-f-4 ske-b ml-5"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <PaymentAuthorization />
        <p className="anim-p">Payout Authorization</p>
      </div>
      <div className="yu-f-5 ske-b">
        <MontraCustomers />
        <p className="anim-p">Montra Customers</p>
      </div>
      <div className="yu-absolute yu-mt-x z-back">
        <YU1 />
      </div>
    </div>
  );
};

export const Animation2 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-g-3 yu-flex yu-absolute ${className}`}>
      <div
        className="yu-f-1 ske-b"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <MontraCustomers />
        <p className="anim-p">Montra Customers</p>
      </div>
      <div
        className="yu-f-1 ske-b"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ScanAndPay />
        <p className="anim-p">Scan & Pay</p>
      </div>
      <div>
        <div className="yu-f-3 ske-b ml5">
          <MontraAccount />
          <p className="anim-p">Montra Account</p>
        </div>
        <div className="yu-f-3 ske-b ml5">
          <Installments />
          <p className="anim-p">Installments</p>
        </div>
        <div className="yu-f-3 ske-b ml5 mt30">
          <LinkedCards />
          <p className="anim-p">Linked Cards</p>
        </div>
        <div className="yu-f-3 ske-b ml5">
          <LinkedBanks />
          <p className="anim-p">Linked Banks</p>
        </div>
      </div>
      <div
        className="yu-f-4 ske-b ml-5"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <PaymentAuthorization />
        <p className="anim-p">Payout Authorization</p>
      </div>
      <div className="ske-b yu-f-1">
        <MontraMerchants />
        <p className="anim-p">Montra Merchants</p>
      </div>
      <div className="yu-absolute yu-mt-x-3 z-back">
        <YU3 />
      </div>
    </div>
  );
};

export const Animation3 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-g-2 yu-flex yu-absolute ${className}`}>
      <div className="yu-f-1 ske-b">
        <MontraCustomers />
        <p className="anim-p">Montra Customers</p>
      </div>
      <div
        className="yu-f-6 ske-b"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <BankTransfer />
        <p className="anim-p">Bank Transfer</p>
      </div>
      <div>
        <div className="yu-f-3 ske-b ml5">
          <MontraAccount />
          <p className="anim-p">Montra Account</p>
        </div>
        <div className="yu-f-7 ske-b ml5">
          <LinkedBanks />
          <p className="anim-p">Linked Banks</p>
        </div>
      </div>
      <div
        className="yu-f-4 ske-b ml-5"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <PaymentAuthorization />
        <p className="anim-p">Payout Authorization</p>
      </div>
      <div className="yu-f-6 ske-b">
        <OtherCustomers />
        <p className="anim-p">Other Customers</p>
      </div>
      <div className="yu-absolute yu-mt-x-2 z-back">
        <YU2 />
      </div>
    </div>
  );
};

export const Animation4 = ({ className, onMouseEnter, onMouseLeave, path }) => {
  return (
    <div className={`yu-flex yu-absolute ${className}`}>
      <div className="yu-mr-2">
        <div
          className="ske-b montracustomer"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <MontraCustomers />
          <p className="anim-p">Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div
          className="ske-b mt-30"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <LoanEligibilityComparison />
          <p className="anim-p">Loan Eligibility Comparison</p>
        </div>
      </div>
      <div className="yu-mr-2">
        {/* <div className="ske-b mt-30">
          <LoanOfferAcceptance />
          <p>Loan Offer Acceptance</p>
        </div> */}
        {/* className="ske-b mt115  ml-30" */}
        <div className="ske-b mt125">
          <LendingPartners />
          <p className="anim-p">Lending Partners</p>
        </div>
      </div>
      {/* <div className="yu-mr-2">
        <div className="ske-b mt-30">
          <LoanAuthorization />
          <p>Loan Authorization</p>
        </div>
      </div> */}
      {/* <div className="yu-mr-2">
        <div className="ske-b mt-30">
          <InstantLoanDisbursement />
          <p>Instant Loan Disbursement</p>
        </div>
        <div className="ske-b loanmanagement">
          <LoanManagementSystem />
          <p>Loan Mgt. System</p>
        </div>
      </div> */}
      <div className="yu-absolute mt55 ml45 z-back">
        <YU4 path={path} />
      </div>
    </div>
  );
};
export const Animation4a = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <>
      <Animation4 path={0} />
      <div className={`yu-flex yu-absolute ${className}`}>
        <div className="yu-mr-2">
          <div
            className="ske-b montracustomer"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <MontraCustomers />
            <p className="anim-p">Montra Customers</p>
          </div>
        </div>
        <div className="yu-mr-2">
          <div
            className="ske-b mt-30"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <LoanEligibilityComparison />
            <p className="anim-p">Loan Eligibility Comparison</p>
          </div>
        </div>
        <div className="yu-mr-2">
          <div className="ske-b mt-30">
            <LoanOfferAcceptance />
            <p className="anim-p">Loan Offer Acceptance</p>
          </div>
          {/* <div className="ske-b mt50 ml-30">
            <LendingPartners />
            <p className="anim-p">Lending Partners</p>
          </div> */}
        </div>
        <div className="yu-mr-2">
          <div className="ske-b mt-30">
            <LoanAuthorization />
            <p className="anim-p">Loan Authorization</p>
          </div>
        </div>
        <div className="yu-mr-2">
          <div className="ske-b mt-30">
            <InstantLoanDisbursement />
            <p className="anim-p">Instant Loan Disbursement</p>
          </div>
          <div className="ske-b loanmanagement">
            <LoanManagementSystem />
            <p className="anim-p">Loan Mgt. System</p>
          </div>
        </div>
        <div className="yu-absolute mt55 ml240 z-back">
          <YU4a />
        </div>
      </div>
    </>
  );
};

export const Animation5 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex yu-absolute ${className}`}>
      <div className="yu-mr-2">
        <div className="ske-b mt180 animation5-montra-customer">
          <MontraCustomers />
          <p className="anim-p">Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div
          className="ske-b  mt195"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <LoanStatement />
          <p className="anim-p">Loan Statement</p>
        </div>
      </div>
      <div className="yu-mr-2">
        {/* <div className="ske-b mt365">
          <LoanMgtService />
          <p>Loan Mgt. Service</p>
        </div> */}
        <div className="yu-mr-2">
          <div className="ske-b animation5-loanmanagement">
            <LoanManagementSystem />
            <p className="anim-p">Loan Mgt. System</p>
          </div>
        </div>
      </div>
      <div className="yu-absolute mt220 ml95 z-back">
        <YU5 />
      </div>
    </div>
  );
};

export const Animation6 = ({ className, onMouseEnter, onMouseLeave }) => {
  return (
    <div className={`yu-flex yu-absolute ${className}`}>
      <div className="yu-mr-2">
        <div className="anim6-image-content-positionchange ske-b animation6-montracustomer-top">
          <MontraCustomers />
          <p className="anim-p">Montra Customers</p>
        </div>
      </div>
      <div className="yu-mr-2">
        <div className="ske-b opacity-0"></div>
      </div>
      <div className="yu-mr-2">
        <div
          className="anim6-image-content-positionchange ske-b animation6-lendingpartner "
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <LendingPartners />
          <p className="anim-p">Lending Partners</p>
        </div>
        <div
          className="anim6-image-content-positionchange ske-b animation6-loanrepayment"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <LoanRepayment />
          <p className="anim-p">Loan Repayment</p>
        </div>
        {/* <div
          className="ske-b "
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <LoanMgtService />
          <p>Loan Mgt. Service</p>
        </div> */}
      </div>
      <div className="yu-mr-2">
        <div className="ske-b opacity-0"></div>
      </div>
      <div className="yu-mr-2">
        <div className="anim6-image-content-positionchange ske-b anim-6 ">
          <LoanManagementSystem />
          <p className="anim-p">Loan Mgt. System</p>
        </div>
      </div>
      <div className="yu-absolute mt205 ml87 z-back">
        <YU6 />
      </div>
    </div>
  );
};
