import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import Delivery from "../../../assets/images/uday/personal-icons/shop/sho1.svg";
import DineIn from "../../../assets/images/uday/personal-icons/shop/sho2.svg";
import HomeServices from "../../../assets/images/uday/personal-icons/shop/sho3.svg";
import Appointments from "../../../assets/images/uday/personal-icons/shop/sho4.svg";
import storehero2 from "../../../assets/images/storehero2.svg";

const steps = [
  {
    title: "Montra Store Merchants",
    image: storehero2,
    className: "store-hero-left-image",
  },
  { image: Delivery, description: "Delivery/ Takeout/ Rentals" },
  { image: DineIn, description: "Dine-in" },
  { image: HomeServices, description: "Home Services" },
  { image: Appointments, description: "Appointments" },
];

const StoreProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="business-store-topdescription"
      />
    </div>
  );
};

export default StoreProcessContainer;
