import React from "react";
import supplychainhero from "../../../../assets/images/supplychainhero.webp";
import "./style.css";
import SupplyProcessContainer from "./SupplyProcessContainer";

const Hero = () => {
  return (
    <>
      <div className="supplychain supplychain-page supplychain-overviewpage">
        <div className="row supplychain-row">
          <div className="col supplychain-page-1">
            <h3 className="supplychain-h3">
              Streamline B2B Payouts With Supply Chain & Payment Processing
              Solution
            </h3>
            <p className="supplychain-p">
              Unlock powerful supply chain payment capabilities on a fully
              digital platform that enables:
            </p>
            <ul>
              <li>Collection of B2B payments digitally.</li>
              <li>Collection of Credit based sales.</li>
              <li>Tracking of  Due & Collected payments.</li>
              <li>
                Launching & managing digital sales promotions for Primary,
                Secondary as well as Tertiary sales.
              </li>
              <li>
                Distribution analytics thereby  helping in assessing SKU level
                stock availability in the outlets under direct distribution as
                well as those where Montra App is being used for selling the
                SKUs to outlets under indirect distribution.
              </li>
            </ul>
            <SupplyProcessContainer />
            <button className="supplyoverview-hero-btn">
              Contact Sales
              <i class="bi bi-chevron-right"></i>
            </button>
          </div>
          <div className="col supplychain-right supplychain-page-2 right-payment-col">
            <img src={supplychainhero} alt="supplychainhero" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
