import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import vission from "../../../assets/images/vission.svg";
import mission from "../../../assets/images/mission.svg";
import m from "../../../assets/images/m.svg";
import o from "../../../assets/images/o.svg";
import n from "../../../assets/images/n.svg";
import t from "../../../assets/images/t.svg";
import r from "../../../assets/images/r.svg";
import a from "../../../assets/images/a.svg";

const Body = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const coreValues = [
    {
      letter: "M",
      title: "Make Things Easier",
      description:
        "Managing Finances can be complicated. That’s why we thrive on Innovation to make things easier for our customers by providing new & easier ways to Pay, Borrow, Save, Invest, & manage money",
    },
    {
      letter: "O",
      title: "Operational Excellence",
      description:
        "We strive for highest standards of quality & service delivery through consistent focus on improvements for achieving Operational Excellence",
    },
    {
      letter: "N",
      title: "Never Settle",
      description:
        "We continuously strive to improve & innovate by learning from both the things that we do well, and those needing improvement. We are ambitious & are forever looking to raise the bar",
    },
    {
      letter: "T",
      title: "Trustworthiness",
      description:
        "We are honest, reliable, transparent & committed to doing what’s best for our customers. We believe in saying only what we do",
    },
    {
      letter: "R",
      title: "Respect",
      description:
        "We value every employee, partner & customer we have. We treat each of them with dignity & professionalism to nurture an environment that’s transparent & ethical",
    },
    {
      letter: "A",
      title: "Accountability",
      description:
        "We accept full responsibility for our decisions, actions & their outcomes. We take ownership, focus on finding solutions &, achieving results towards getting things done",
    },
  ];

  const logos = [

    require("../../../assets/images/logo7.svg").default,
    require("../../../assets/images/logo3.svg").default,
    require("../../../assets/images/logo6.svg").default,
    require("../../../assets/images/logo8.svg").default,
    require("../../../assets/images/logo2.svg").default,
    require("../../../assets/images/logo5.svg").default,
    require("../../../assets/images/logo4.svg").default,
    require("../../../assets/images/logo1.svg").default,


  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  useEffect(() => {
    console.log("hi----", hoveredIndex);
  }, []);
  return (
    <>
      <div className="company-body">
        <div className="vission">
          <div>
            <img src={vission} alt="vission" className="vission-img" />
          </div>
          <div className="vission-text">
            <div>
              <h1>Our Vision</h1>
              <p>
                Offer innovative products and services in collaboration with
                other players in financial ecosystem, transforming lives &
                contributing to sustainable living in emerging economies
              </p>
            </div>
          </div>
        </div>
        <div className="mission">
          <div className="mission-text">
            <div>
              <h1>Our Mission</h1>
              <p>
                Introduce innovative products & solutions that promote Financial
                Inclusion across emerging economies towards the transition from
                cash to digital payments & making accessibility to Credit easy
                for everyone
              </p>
            </div>
          </div>
          <div className="mission-img">
            <img src={mission} alt="mission" />
          </div>
        </div>
        <div className="montra">
          <h1>Our Core Values</h1>
          <div className="montra-meaning">
            {coreValues.map((value, index) => (
              <div
                key={index}
                className="overview-service-body service service-m"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <div className={`service-icon  `}>
                  <h4 className={hoveredIndex === index ? "hovered" : ""}>
                    {value.letter}
                  </h4>
                </div>
                <div
                  className={`service-box montra-mmm ${hoveredIndex === index ? "hovered" : ""
                    }`}
                >
                  <div className="service-text montra-mm">
                    <div className="service-box-text montra-m">
                      <h6 className={hoveredIndex === index ? "hovered" : ""}>
                        {value.title}
                      </h6>
                      <div>
                        <p className={hoveredIndex === index ? "hovered" : ""}>
                          {value.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="sponsors">
        <h1>Our Partners</h1>
      </div>
      <div className="logo-carousel">
        <Slider {...settings}>
          {logos.map((logo, index) => (
            <div key={index} className="logo-item">
              <img src={logo} alt={`Logo ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Body;
