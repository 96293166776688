/* React packages */
import React from 'react';
import axios from 'axios';


/**
 * Renders  Moneylink comission in profile of maker section.
 * <p>These are non-editable.</p>
 * <p>These comission values provided by moneylink</p>
 <br>
 * <h6>Below Html elemensts are created to use for  UI</h6>
 * <li> <b> Section :- </b>it is used for represents a standalone section</li>
 * <li> <b>Form :-</b> This element represents a document section containing interactive controls for submitting information.</li>
 * <li> <b>input :-</b> This tag specifies an input field where the user can enter data.</li>
 * <li> <b>label :-</b> This tag is use for Screen reader users (will read out loud the label, when the user is focused on the element) </li>

 <br>
 * <h6>Below Bootstrap class is use for ui responsive.</h6>
 * <li> <b>container-fluid :- </b> This class to create a full width container, that will always span the entire width of the screen (width is always 100%):</li>
 * <li> <b>row :-</b> This class to create a structure.</li>
 * <li> <b>form-group :-</b> Form groups are used to wrap labels and form controls in a div to get optimum spacing between the label and the control.</li>
 * <li> <b>d-flex  :- </b> it is use for manage the layout, alignment, and sizing of grid columns, navigation, components, and more with a full suite of responsive flexbox utilities.</li>

 * @Author : Seema Kumari
 * @version :1.0
 */

class PaylinkComission extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      status: '',
      data: [],
      paylinkCommission: [
        { originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { originationComm: '', loanMgtComm: '', loanRepayComm: '', },
        { originationComm: '', loanMgtComm: '', loanRepayComm: '', }
      ],
      collectionBuckets: [{
        collXdays: '', coll30DPD: '', coll60DPD: '', coll90DPD: '', coll120DPD: '', collBET180n360DPD: '', collGT360DPD: '',
      }],
      insuranceCommissions: [
        { premCollectedPercent: '' }
      ]
    };
  }
  componentDidMount() {
    const url = `api/user/profile?email=${localStorage.getItem("email")}`;
    axios.get(url, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
      .then(response => {
        if (response.status === 200) {
          const contents = response.data;

          this.setState({
            data: contents,
            paylinkCommission: contents.data.Profile.paylinkCommission,
            collectionBuckets: contents.data.Profile.collectionBuckets,
            insuranceCommissions: contents.data.Profile.insuranceCommissions
          });
        } else {
          alert('Server error');
        }
      })
      .catch(error => {
        console.error('Server error:', error);
        alert('Server error');
      });
  }


  render() {
    return (
      <div>
        <div className="">
          {/* Title */}
          <p className="signinheader commheader">MONEYLINK COMMISSION</p>

          {/* Form -: in this form section included input text and label */}
          <form method="post" name="userRegistrationForm" className="formroot">
            <div className="d-flex flex-row mt-3">


              <div className="p-2 payl">
                <div className="form-group">
                  <label className="product profile-label">Product</label>
                  {this.state.paylinkCommission.map((paylinkCommission, i) => <div key={i}><label type="text" className="form-control loanlabelmak" id="field1" disabled > {paylinkCommission.productType} </label></div>)}
                </div>
              </div>
              <div className="p-2 payl">
                <div className="form-group">
                  <label className="product2 profile-label">Origination <br />Commission</label>
                  {this.state.paylinkCommission.map((paylinkCommission, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={paylinkCommission.originationComm + "%"} disabled /></div>)}
                </div>
              </div>
              <div className="p-2 payl">
                <div className="form-group">
                  <label className="productcomm profile-label">Loan Management <br />Commission</label>
                  {this.state.paylinkCommission.map((paylinkCommission, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={paylinkCommission.loanMgtComm + "%"} disabled /></div>)}
                </div>
              </div>


              <div className="p-2 payl">
                <div className="form-group">
                  <label htmlFor="first" className="product-re profile-label">Loan Repayment Collection Commission </label>
                  {this.state.paylinkCommission.map((paylinkCommission, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={paylinkCommission.loanRepayComm + "%"} disabled /></div>)}
                </div>
              </div>


            </div>
          </form>


          <p className="collectionheader" >Collection Buckets</p>
          <form method="post" name="userRegistrationForm" className="formroot">


            <div className="d-flex flex-row mt-3 collmak ">

              <div className="p-2 flexnowrap4">
                <label type="text" className="loanlabelmak3 ebcd" disabled ></label>
              </div>
              <div className="p-2 flexnowrap2">
                <label type="text" className="form-control loanlabelmak5" id="field2" placeholder="X Days" disabled >X Days </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" id="field2" placeholder="30 DPD" disabled > 30 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" id="field2" placeholder="60 DPD" disabled > 60 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" id="field2" placeholder="90 DPD" disabled > 90 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" id="field2" placeholder="120 DPD" disabled > 120 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" id="field2" placeholder="180-360 DPD" disabled > 180-360 DPD </label>
              </div>
              <div className="p-2 flexnowrap3">
                <label type="text" className="form-control loanlabelmak5" id="field2" placeholder="360 DPD" disabled > 360 DPD </label>
              </div>
            </div>




            <div className="d-flex flex-nowrap " >
              <div className="p-2 flexnowrapmak">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><label type="text" className="form-control loanlabelmak4" name="" id="field2" disabled> Collection Commission </label></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.collXdays + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.coll30DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.coll60DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.coll90DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.coll120DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.collBET180n360DPD + "%"} disabled /></div>)}
              </div>
              <div className="p-2 flexnowrap">
                {this.state.collectionBuckets.map((collectionBuckets, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={collectionBuckets.collGT360DPD + "%"} disabled /></div>)}
              </div>
            </div>

          </form>


          <form method="post" name="userRegistrationForm" className="formroot">


            <div className="d-flex flex-nowrap ">
              <div className="p-2 flexnowrapmaker">
                <label className="collpay"></label>
                {this.state.insuranceCommissions.map((insuranceCommissions, i) => <div key={i}><label className="form-control loanlabelmak5" name="" id="field2" disabled> Insurance Commission</label> </div>)}

              </div>

              <div className="p-2 flexnowrapmak2">
                <label className='profile-label'>% of premium collected</label>
                {this.state.insuranceCommissions.map((insuranceCommissions, i) => <div key={i}><input type="text" className="form-control comissionfield2" placeholder={insuranceCommissions.premCollectedPercent + "%"} disabled /></div>)}
              </div>






            </div>

          </form>





        </div>
      </div>


    );
  }

}


export default PaylinkComission;
