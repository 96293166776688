import React, { useEffect, useState } from "react";
import map from "../../../assets/images/map.png";
import countries from "../../../assets/images/map-countries.svg";
import MobileStoryImage from "../../../assets/images/uday/map-with-location.png";

const Body = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 768;

  return (
    <>
      <div className="our-story-map">
        <h1>Our Location</h1>
        {!isMobile ? (
          <div className="story-map">
            <img src={map} alt="Our Story Map" className="ourstorymap" />
            <img src={countries} alt="Our Story Map countries" />
          </div>
        ) : (
          <div className="mobile-story-map">
            <img
              src={MobileStoryImage}
              alt="our companies location in map"
              className="mobile-story-image"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Body;
