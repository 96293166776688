import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import InventoryManagement from "../../../assets/images/uday/Business-icons/overview/Inventory.svg";
import InvoicingPayment from "../../../assets/images/uday/Business-icons/overview/invoicing.svg";
import MontraStore from "../../../assets/images/uday/Business-icons/overview/store.svg";
import PaymentGateway from "../../../assets/images/uday/Business-icons/overview/paymentGateway.svg";
import businesshero2 from "../../../assets/images/businesshero2.svg";

const steps = [
  {
    title: "Business Management Solutions",
    image: businesshero2,
    className: "businesshero2-image",
  },
  { image: InventoryManagement, description: "Inventory Management" },
  { image: InvoicingPayment, description: "Invoicing & Payment Link" },
  { image: MontraStore, description: "Montra Store" },
  { image: PaymentGateway, description: "Payment Gateway" },
];

const BusinessOverviewProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="business-overview-topdescription "
      />
    </div>
  );
};

export default BusinessOverviewProcessContainer;
