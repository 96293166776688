import React from "react";
import ProcessSteps from "../../../utilities/ProcessSteps";

import AcceptPayments from "../../../assets/images/uday/Business-icons/Banking/AcceptPayments.svg";
import TrackCollections from "../../../assets/images/uday/Business-icons/Banking/trackCollection.svg";
import BankTransfer from "../../../assets/images/uday/Business-icons/Banking/bankTransfer.svg";
import BusinessLoans from "../../../assets/images/uday/Business-icons/Banking/businessLoans.svg";
import businessbanking2 from "../../../assets/images/businessbanking2.svg";

const steps = [
  {
    title: "Montra Business Account",
    image: businessbanking2,
    className: "business-banking-img businessbankimg",
  },
  { image: AcceptPayments, description: "Accept Payments" },
  { image: TrackCollections, description: "Track Collections" },
  { image: BankTransfer, description: "Easy Bank Transfer" },
  { image: BusinessLoans, description: "Business Loans" },
];

const BankingProcessContainer = () => {
  return (
    <div>
      <ProcessSteps
        steps={steps}
        arrowColor="#20336B"
        circleBorderColor="#20336B"
        textColor="#20336B"
        topDescriptionClass="business-overview-topdescription"
      />
    </div>
  );
};

export default BankingProcessContainer;
